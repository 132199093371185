import Button from '@/components/Button';
import ManualUploadFile from '@/components/ManualDataUploader/ManualUploadFile';
import PurchasePricesFileLink from '@/components/ManualDataUploader/PurchasePricesFileLink';
import MiniLoader from '@/components/MiniLoader/MiniLoader';
import { usePP } from '@/context/usePP';
import { FileStatusEnum } from '@/core/enums';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { APIEventsEnum, useSendEvent } from '../../hooks';
import SendIcon from '@/icons/sent.svg?react';
import UploadIcon from '@/icons/upload_arrow.svg?react';
import FileUploader from '@/components/FileUploader/FileUploader';
import { ACCEPTED_EXTS } from '@/components/ManualDataUploader/ManualUploader.helpers';

export default function UploadFileForm() {
  const { t } = useTranslation();

  const { sendEvent } = useSendEvent();

  const {
    PPFiles,
    onSubmitPP,
    fileToUploadPP,
    isUploadingPP,
    onSelectPPFile,
    deletePPFileToUpload,
  } = usePP();

  const showUploaded =
    fileToUploadPP || PPFiles.some((f) => f.status === FileStatusEnum.CREATED);

  const isProcessing =
    isUploadingPP || PPFiles.some((f) => f.status === FileStatusEnum.CREATED);

  return (
    <form
      onSubmit={onSubmitPP}
      className={classNames({
        'border-t border-gray-500 pt-4': showUploaded,
      })}
    >
      {showUploaded && (
        <>
          <div className='mb-2 font-bold'>{t('Uploaded files')}</div>
          <>
            {PPFiles.sort((a, b) => {
              if (!a.imported_at) return 1;
              if (!b.imported_at) return -1;
              return (
                new Date(a.imported_at).getTime() -
                new Date(b.imported_at).getTime()
              );
            })
              .filter((f) => f.status === FileStatusEnum.CREATED)
              .map((f) => {
                const parts = f.file.split('/');
                return (
                  <PurchasePricesFileLink
                    isError={f.status === FileStatusEnum.FAILED}
                    key={f.file}
                    className='FileLink--PurchasePrices mb-2 rounded-lg border px-4 py-2'
                    bucket={f.bucket}
                    path={f.file}
                    name={parts[parts.length - 1]}
                    isLoading={f.status === FileStatusEnum.CREATED}
                    description={
                      f.imported_at ? (
                        <div className='flex items-center text-sm text-gray-700'>
                          <span>{t('sent on')}&nbsp;</span>
                          <span>
                            {format(
                              new Date(f.imported_at),
                              'dd.MM.yyyy HH:mm'
                            )}
                          </span>
                        </div>
                      ) : undefined
                    }
                  />
                );
              })}
          </>
        </>
      )}
      {fileToUploadPP && (
        <ManualUploadFile
          onDeleteFile={deletePPFileToUpload}
          file={fileToUploadPP}
          disabled={false}
          className='mb-2 rounded-lg border px-4 py-2'
        />
      )}
      {fileToUploadPP ||
      PPFiles.some((f) => f.status === FileStatusEnum.CREATED) ? (
        <div className='pt-2'>
          <Button
            className='flex items-center !py-2 !pl-4 !pr-4'
            type='submit'
            disabled={isProcessing}
            onClick={() => {
              sendEvent(APIEventsEnum.click_upload_purchase_prices, {
                block: 'Upload purchase prices',
              });
            }}
          >
            {isProcessing ? (
              <MiniLoader className='mr-2' color='gray' />
            ) : (
              <SendIcon className='mr-2' />
            )}
            <span>{t('Send')}</span>
          </Button>
        </div>
      ) : (
        <div
          className={classNames('flex flex-wrap items-center gap-x-4', {
            'pt-2': fileToUploadPP,
          })}
        >
          <div>
            <FileUploader
              onSelect={onSelectPPFile}
              name='file'
              accept={ACCEPTED_EXTS}
              className='Button--outline btn flex cursor-pointer items-center py-2 pl-4 pr-6'
              multiple={false}
            >
              <UploadIcon className='mr-2' />
              <span>{t('Upload updated file')}</span>
            </FileUploader>
          </div>
          <div className='text-sm text-gray-700'>
            {t('File format: CSV, XLS, XLSX')}
          </div>
        </div>
      )}
    </form>
  );
}
