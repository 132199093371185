import { useTranslation } from 'react-i18next';
import CountryLabel from '@/components/CountryLabel/CountryLabel';
import { formatNumber } from '@/core/helpers';
import { NormalizedFCTransfer } from './SetupCheck';
import InfoIcon from '@/icons/info-outline.svg?react';
import Button from '@/components/Button';

interface Props {
  item: NormalizedFCTransfer;
  homeCountry: string | undefined;
  openModal: () => void;
}

export default function NewFCTransferItem({
  item,
  homeCountry,
  openModal,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className='mb-4 border-t border-gray-500 pt-2'>
      <CountryLabel country={item.country} className='mb-2' />
      <div className='flex w-full justify-between font-semibold'>
        <div>{t('Departures')}</div>
        <div>{formatNumber(item.departures.total)}</div>
      </div>
      <div className='mb-2'>
        {item.departures.countries.map((c) => (
          <div
            key={c}
            className='SetupCheck__FCTransferCountryCol mr-2 inline-block'
          >
            {t(c)}
          </div>
        ))}
      </div>
      <div className='flex w-full justify-between font-semibold'>
        <div>{t('Arrivals')}</div>
        <div>{formatNumber(item.arrivals.total)}</div>
      </div>
      <div className='mb-2'>
        {item.arrivals.countries.map((c) => (
          <div
            key={c}
            className='SetupCheck__FCTransferCountryCol mr-2 inline-block'
          >
            {t(c)}
          </div>
        ))}
      </div>
      {homeCountry === item.country && (
        <div>
          <span>{t('All')}</span>&nbsp;
          <Button styling='naked-blue' onClick={openModal}>
            <span>
              {formatNumber(item.arrivals.total + item.departures.total)}{' '}
            </span>
            <span>{t('transactions')}</span>
          </Button>
          &nbsp;
          <InfoIcon
            className='SetupCheck__info'
            style={{
              marginTop: -2,
            }}
          />
          &nbsp;
          <span>
            {t(
              'must be included into local *country* filing by tax advisor'
            ).replace('*country*', item.country)}
          </span>
        </div>
      )}
    </div>
  );
}
