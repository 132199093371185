import classNames from 'classnames';
import './Tabs.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  tabs: Tab[];
  onClickTab: (id: string) => void;
  activeTab: string;
}

export interface Tab {
  id: string;
  label: string;
}

export default function Tabs({ tabs, activeTab, onClickTab }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div className='Tabs__nav mx-0 mb-4 flex flex-wrap gap-x-6 pb-1'>
        {tabs.map((tab) => (
          <div className='flex items-center' key={tab.id}>
            <button
              className={classNames('Tabs__tab relative !pb-2', {
                'Tabs__tab--active': activeTab === tab.id,
              })}
              onClick={() => {
                onClickTab(tab.id);
              }}
            >
              <>{t(tab.label)}</>
            </button>
          </div>
        ))}
      </div>
    </>
  );
}
