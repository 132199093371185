import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatDate } from '@/core/helpers';
import Button, { BtnStyling } from '../Button';
import TipIcon from '@/icons/tip-green-circle-2.svg?react';

import './OpenTask.scss';
import { SellerFilingReportType } from '@/core/types';
import { TaskForEnum } from './OpenTasks.helpers';

interface Props {
  index: number;
  item: TodoItem;
  id: string;
  type: 'ongoing' | 'expired' | 'oss_self_service';
  disabled?: boolean;
  done: boolean;
  isOSSSelfService?: boolean;
}

export interface TodoItem {
  label?: string | JSX.Element;
  description: string | JSX.Element;
  date: Date;
  action?: string | (() => void) | JSX.Element; //delete ? after fixing r.209 OpenTasks
  btnLabel?: string;
  btnStyling?: BtnStyling;
  btnClassName?: string;
  id: string;
  disabled?: boolean;
  taskFor?: TaskForEnum;
  reportType?: SellerFilingReportType;
  done?: boolean;
}

export default function OpenTask({
  index,
  item,
  id,
  type,
  disabled,
  done,
  isOSSSelfService = false,
}: Props) {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={classNames('OpenTask px-4 py-2', {
        [`OpenTask--${type}`]: type !== 'oss_self_service',
      })}
    >
      <div className='flex'>
        {done ? (
          <TipIcon className='mr-2' />
        ) : (
          <div
            className={classNames(
              'OpenTask__circle mr-2 mt-px flex items-center justify-center rounded-full px-2 py-1 font-semibold',
              {
                'border border-gray-700 !bg-white !text-dark-green':
                  isOSSSelfService,
              }
            )}
            data-testid={`openTask-num-${id}`}
          >
            {index}
          </div>
        )}
        <div
          className={classNames('flex-grow', {
            'text-gray-700': done,
          })}
        >
          <div
            className={`grid gap-x-2 ${
              i18n.language === 'en'
                ? 'grid-cols-[2fr,180px]'
                : 'grid-cols-[2fr,215px]'
            }`}
          >
            <div>
              <div className='grid grid-cols-[2fr,120px] gap-x-2'>
                <div
                  className='font-semibold'
                  data-testid={`openTask-label-${id}`}
                >
                  {typeof item.label === 'string' ? t(item.label) : item.label}
                </div>
                {item.date && (
                  <div
                    data-testid={`openTask-date-${id}`}
                    className='font-semibold'
                  >
                    {t('by *date*').replace(
                      '*date*',
                      formatDate(item.date, 'E, d MMM')
                    )}
                  </div>
                )}
              </div>
              <div
                className='text-gray-700'
                data-testid={`openTask-description-${id}`}
              >
                {typeof item.description === 'string'
                  ? t(item.description)
                  : item.description}
              </div>
            </div>
            <div className='flex items-start justify-end'>
              {typeof item.action === 'string' ? (
                <Link
                  className={classNames(
                    'Button Button--sm OpenTask__btn inline-block !w-full text-center',
                    item.btnClassName || '',
                    {
                      'hover:!text-white':
                        item.btnStyling !== 'outline' &&
                        type !== 'expired' &&
                        !item.done &&
                        !disabled,
                      'Button--outline':
                        item.done || item.btnStyling || type === 'expired',
                      'Button--disabled cursor-default rounded-lg !border-gray-300 !bg-gray-70 font-semibold !text-gray-700 hover:bg-gray-70 hover:!text-gray-700':
                        disabled,
                    }
                  )}
                  onClick={(e) => {
                    if (disabled) {
                      e.preventDefault();
                    }
                  }}
                  to={item.action}
                  data-testid={`openTask-link-${id}`}
                >
                  {item.btnLabel && t(item.btnLabel)}
                </Link>
              ) : typeof item.action === 'function' ? (
                <Button
                  styling={
                    item.done
                      ? 'outline'
                      : item.btnStyling || type === 'expired'
                        ? 'outline'
                        : 'primary'
                  }
                  size='sm'
                  onClick={item.action}
                  data-testid={`openTask-btn-${id}`}
                  className={`OpenTask__btn !w-full shrink-0 ${
                    item.btnClassName || ''
                  }`}
                  disabled={disabled}
                >
                  {item.btnLabel && t(item.btnLabel)}
                </Button>
              ) : (
                item.action
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
