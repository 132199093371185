import classNames from 'classnames';
import { ChangeEventHandler } from 'react';
import './Switch.scss';

interface Props {
  checked: boolean;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  id?: string;
  name?: string;
  size?: 'md' | 'sm';
}

export default function Switch({
  checked,
  disabled,
  onChange,
  id,
  name,
  size,
}: Props) {
  return (
    <label
      data-testid={`${name}-label`}
      className={classNames('Switch', {
        'Switch--disabled': disabled,
        'Switch--sm': size === 'sm',
      })}
    >
      <input
        type='checkbox'
        id={id}
        name={name}
        data-testid={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <span className='Switch__slider' />
    </label>
  );
}
