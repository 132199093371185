import { HTMLAttributes, PropsWithChildren } from 'react';
import { stripSpaces } from '../core/helpers';

interface Props {
  className?: string;
  tel: string;
}

const TelLink = ({
  className = '',
  tel,
  children,
  ...rest
}: PropsWithChildren<Props & HTMLAttributes<HTMLAnchorElement>>) => (
  <a
    href={`tel:${stripSpaces(tel)}`}
    className={className}
    tabIndex={-1}
    {...rest}
  >
    {children || tel}
  </a>
);

export default TelLink;
