import { useMemo } from 'react';
import { normalizeDateFE } from '@/core/normalization';
import './SellerPastPeriodsFiling.scss';
import {
  FilingReportConfirmation,
  NormalizedSellerFilingReport,
} from '@/core/types';
import { isSameMonth, isSameYear } from 'date-fns';
import FolderIcon from '@/icons/folder-green-xl.svg?react';
import { useTranslation } from 'react-i18next';
import SellerPastPeriodsFilingItem from '@/components/SellerFiling/SellerPastPeriodsFilingItem';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SellerPastPeriodsFilingItemOld from '@/components/SellerFiling/SellerPastPeriodsFilingItemOld';
import useCountryFilters from '@/components/CountryFilters/useCountryFilters';
import CountryFilters from '@/components/CountryFilters/CountryFilters';

interface Props {
  reports: NormalizedSellerFilingReport[];
  onFolderBtnClick: (
    confirmations: FilingReportConfirmation[],
    date: Date,
    country: string
  ) => () => void;
  onClickAgenda: (r: NormalizedSellerFilingReport) => () => void;
  onAmountBtnClick: (r: NormalizedSellerFilingReport) => () => void;
}

export interface CompletedFilingItemData {
  month: Date;
  reports: NormalizedSellerFilingReport[];
  amounts: {
    [key: string]: number;
  };
}

export default function SellerPastPeriodsFiling({
  reports,
  onFolderBtnClick,
  onAmountBtnClick,
  onClickAgenda,
}: Props) {
  const { t } = useTranslation();
  const { vs5453CpFilingPeriodsItems } = useFlags();

  const { activeCountryFilters, countryFilters, onCountryFilterClick } =
    useCountryFilters(reports);

  const data = useMemo(() => {
    const reportsFilteredByCountries = reports.filter((r) =>
      !!activeCountryFilters.length
        ? r.type === 'oss'
          ? activeCountryFilters.includes('OSS')
          : activeCountryFilters.includes(r.country.short_name)
        : r
    );
    const dates = reportsFilteredByCountries
      .map((r) => {
        const d = normalizeDateFE(
          r.payment_instruction?.due_date || r.deadline_date
        );
        return d;
      })
      .filter((date, i, self) => {
        return (
          self.findIndex((d) => isSameMonth(d, date) && isSameYear(d, date)) ===
          i
        );
      })
      .sort((a, b) => {
        return b.getTime() - a.getTime();
      });

    return dates.map((d) => {
      const filteredReports = reportsFilteredByCountries.filter((r) => {
        const date = normalizeDateFE(
          r.payment_instruction?.due_date || r.deadline_date
        );
        return isSameMonth(d, date) && isSameYear(d, date);
      });

      const amounts = filteredReports.reduce(
        (
          acc: {
            [key: string]: number;
          },
          r
        ) => {
          if (r.payment_instruction?.amount.currency) {
            acc[r.payment_instruction.amount.currency] =
              (acc[r.payment_instruction.amount.currency] || 0) +
              (!r.payment_instruction?.cancelled
                ? r.payment_instruction.amount.amount
                : 0);
          }
          return acc;
        },
        {}
      );

      return {
        month: d,
        reports: filteredReports,
        amounts,
      };
    });
  }, [reports, activeCountryFilters]);

  return (
    <div>
      {!!countryFilters.length && (
        <CountryFilters
          onCountryFilterClick={onCountryFilterClick}
          countryFilters={countryFilters}
          activeCountryFilters={activeCountryFilters}
        />
      )}
      {data.length ? (
        <>
          {data
            .sort((a, b) => {
              return b.month.getTime() - a.month.getTime();
            })
            .map((d) => {
              const isExpandedCollapseNeeded = d.reports.some(
                (report) =>
                  (report.country?.short_name === 'ES' ||
                    report.country?.short_name === 'IT' ||
                    report.fiscal_rep_required) &&
                  report.payment_instruction &&
                  !!report.payment_instruction.amount.amount &&
                  !report.payment_instruction.is_null_report &&
                  !report.payment_instruction.payment_confirmation?.documents
                    .length
              );
              return vs5453CpFilingPeriodsItems ? (
                <SellerPastPeriodsFilingItem
                  initialOpened={
                    isExpandedCollapseNeeded || !!activeCountryFilters.length
                  }
                  onClickAgenda={onClickAgenda}
                  key={d.month.getTime()}
                  data={d}
                  onFolderBtnClick={onFolderBtnClick}
                  onAmountBtnClick={onAmountBtnClick}
                />
              ) : (
                <SellerPastPeriodsFilingItemOld
                  initialOpened={
                    isExpandedCollapseNeeded || !!activeCountryFilters.length
                  }
                  onClickAgenda={onClickAgenda}
                  key={d.month.getTime()}
                  data={d}
                  onFolderBtnClick={onFolderBtnClick}
                  onAmountBtnClick={onAmountBtnClick}
                />
              );
            })}
        </>
      ) : (
        <>
          <div className='mb-4 text-h4 font-extrabold'>{t('Past periods')}</div>
          <div
            className='flex flex-grow flex-col items-center justify-center'
            style={{
              minHeight: 500,
            }}
          >
            <FolderIcon />
            <div className='mb-12 whitespace-pre-line text-center font-extrabold'>
              {t("You don't have any filings for past periods")}
            </div>
          </div>
        </>
      )}
      <div className='whitespace-pre-line pt-2 text-center text-gray-700'>
        {t(
          "We don't have the archive for earlier periods. To get any numbers before March 2022,  please reach out to your manager."
        )}
      </div>
    </div>
  );
}
