import { PropsWithChildren } from 'react';
import { Badge } from '../Badge/Badge';
import { formatDate } from '@/core/helpers';
import { useTranslation } from 'react-i18next';

export const OSSSeSeTaskWrapper = ({
  children,
  date,
}: PropsWithChildren<{ date: Date }>) => {
  const { t } = useTranslation();
  return (
    <div className='bg-white pt-2 last:rounded-b-lg'>
      <div className='space-y-2'>
        <div className='px-4'>
          {date && (
            <Badge
              color='red'
              label={t('by *date*').replace(
                '*date*',
                String(formatDate(date, 'do MMM yyyy'))
              )}
            />
          )}
        </div>
        <div className='divide-y divide-gray-300'>{children}</div>
      </div>
    </div>
  );
};
