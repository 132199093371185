import { useCallback, useState } from 'react';
import { useModal } from '@/components/Modal/useModal';
import { NormalizedSellerFilingReport } from '@/core/types';
import { postSubmitReport } from '@/core/api';
import { APIEventsEnum, useAppSelector, useSendEvent } from '@/hooks';
import { useNPSModal } from '@/components/NPSModal/useNPSModal';
import { activeCompanySelector } from '@/core/store/user/userSlice';

export const useSubmitBOPModal = (
  block: string,
  onSuccess?: () => Promise<void>
) => {
  const { openModal } = useNPSModal();
  const { sendEvent } = useSendEvent();
  const activeCompany = useAppSelector(activeCompanySelector);
  const companyId = activeCompany?.company_id;

  const [reportToSubmit, setReportToSubmit] =
    useState<NormalizedSellerFilingReport>();

  const {
    isModalOpened: isSubmitBOPModalOpened,
    closeModal: closeSubmitBOPModal,
    openModal: openSubmitBOPModal,
  } = useModal();

  const submitReport = (setIsLoading: (v: boolean) => void) => async () => {
    if (!reportToSubmit || !companyId) return;
    setIsLoading(true);
    try {
      await postSubmitReport(reportToSubmit.id, companyId);
      if (onSuccess) await onSuccess();
      setIsLoading(false);
      const apiEvent = APIEventsEnum.oss_sese_marked_as_submitted;
      sendEvent(apiEvent, {
        block,
      });

      closeSubmitBOPModal();

      openModal(apiEvent);
    } catch {
      setIsLoading(false);
    }
  };

  return {
    reportToSubmit,
    openSubmitBOPModal: useCallback(
      (r: NormalizedSellerFilingReport) => () => {
        setReportToSubmit(r);
        openSubmitBOPModal();
      },
      // eslint-disable-next-line
      []
    ),
    isSubmitBOPModalOpened,
    closeSubmitBOPModal,
    submitReport,
  };
};
