import { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useModal } from '@/components/Modal/useModal';
import { postAgendaConfirm } from '@/core/api';
import { NormalizedSellerFilingReport } from '@/core/types';
import { APIEventsEnum, useAppSelector } from '../../../hooks';
import {
  activeCompanySelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';
import useNotification from '../../../hooks/useNotification';
import { useNPSModal } from '@/components/NPSModal/useNPSModal';

export default function useAgendaModal() {
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();
  const queryKey = useAppSelector(queryKeySelector);
  const activeCompany = useAppSelector(activeCompanySelector);
  const [isConfirmingAgenda, setIsConfirmingAgenda] = useState(false);
  const [reportToConfirmAgenda, setNormalizedSellerFilingReportAgenda] =
    useState<NormalizedSellerFilingReport | undefined>(undefined);

  const { openModal } = useNPSModal();

  const {
    isModalOpened: isAgendaModalOpened,
    closeModal: closeAgendaModal,
    openModal: openAgendaModal,
  } = useModal();

  const onClickConfirmAgenda =
    (report: NormalizedSellerFilingReport | undefined) => () => {
      if (!report) return;
      setIsConfirmingAgenda(true);
      postAgendaConfirm(report.id, activeCompany?.company_id)
        .then(() => {
          setIsConfirmingAgenda(false);
          queryClient.setQueryData(
            report.completed_filing
              ? ['SellerPastPeriodsFiling', queryKey]
              : ['SellerOngoingFiling', queryKey],
            (oldReports: NormalizedSellerFilingReport[] | undefined) => {
              return (oldReports || []).map((r) => {
                if (r.id === report.id) {
                  return {
                    ...r,
                    agenda_confirmed: true,
                  };
                }

                return r;
              });
            }
          );
          showNotification({
            text: 'Report submission confirmed',
            type: 'success',
          });
          closeAgendaModal();
          openModal(APIEventsEnum.report_submition_agenda);
        })
        .catch(() => {
          setIsConfirmingAgenda(false);
        });
    };

  const handleOpenAgendaModal = useCallback(
    (report: NormalizedSellerFilingReport) => () => {
      setNormalizedSellerFilingReportAgenda(report);
      openAgendaModal();
    },
    // eslint-disable-next-line
    []
  );

  return {
    isAgendaModalOpened,
    closeAgendaModal,
    handleOpenAgendaModal,
    onClickConfirmAgenda,
    isConfirmingAgenda,
    reportToConfirmAgenda,
  };
}
