import { useCallback, useState } from 'react';

export const usePolling = () => {
  const [pollings, setPollings] = useState<string[]>([]);

  const activatePolling = useCallback((id: string) => {
    setPollings((p) => [...p, id]);
  }, []);

  const deactivatePolling = useCallback((id: string) => {
    setPollings((p) => p.filter((pId) => pId !== id));
  }, []);

  const isPollingActive = useCallback(
    (id: string) => {
      return pollings.some((pId) => pId === id);
    },
    [pollings]
  );

  return { activatePolling, deactivatePolling, isPollingActive };
};
