import {
  formatDate,
  formatNumber,
  getBoundingAbsoluteRect,
} from '@/core/helpers';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import PaymentIsntructionIcon from '@/icons/payment-instruction.svg?react';
import DisregardedPaymentIsntructionIcon from '@/icons/regarded-instruction-icon.svg?react';
import FolderIcon from '@/icons/folder-outline.svg?react';
import AgendaIcon from '@/icons/agenda-circle-green.svg?react';
import AgendaConfirmedIcon from '@/icons/agenda-submitted.svg?react';
import { NormalizedSellerFilingReport } from '@/core/types';
import Tooltip from '../Tooltip/Tooltip';
import { normalizeDateFE } from '@/core/normalization';
import Button from '../Button';
import {
  isReportSubmitted,
  isShouldSubmitOSSReport,
} from '@/components/OpenTasks/OpenTasks.helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  reports: NormalizedSellerFilingReport[];
  onFolderBtnClick: () => void;
  onBtnClick: (r: NormalizedSellerFilingReport) => () => void;
  onClickAgenda: (r: NormalizedSellerFilingReport) => () => void;
}

export default function SellerFilingOngoingItemOld({
  reports,
  onFolderBtnClick,
  onBtnClick,
  onClickAgenda,
}: Props) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const { vs5151DisregardedInstruction } = useFlags();

  const recommendedPaymentDate = normalizeDateFE(
    reports[0].payment_instruction?.due_date || reports[0].deadline_date
  );
  recommendedPaymentDate.setDate(recommendedPaymentDate.getDate() - 2);

  const renderPeriod = () => {
    let formatStr = 'MMM yyyy';

    if (reports[0].period.type === 'year') {
      return (
        <>
          <span>{t('Year')}</span>&nbsp;
          <span>
            {formatDate(normalizeDateFE(reports[0].period.from_date), 'yyyy')}
          </span>
        </>
      );
    }

    if (
      reports[0].country.short_name === 'IT' &&
      reports[0].period.type !== 'quarter'
    ) {
      return (
        <>
          <span>
            {formatDate(
              normalizeDateFE(reports[0].period.from_date),
              formatStr
            )}
          </span>
          &nbsp;<span>{t('payment')}</span>
        </>
      );
    }

    if (reports[0].period.type === 'quarter') {
      formatStr = 'QQQ yyyy';
    }

    return (
      <>
        <span>
          {formatDate(normalizeDateFE(reports[0].period.from_date), formatStr)}
        </span>
      </>
    );
  };

  useEffect(() => {
    // #confirmation-12598

    if (
      location.hash?.includes('confirmation') &&
      reports.some(
        (r) => r.confirmation?.id === +location.hash.slice(1).split('-')[1]
      ) &&
      ref.current
    ) {
      onFolderBtnClick();
      const rect = getBoundingAbsoluteRect(ref.current);
      window.scrollTo(0, rect.top - 70);
      navigate(
        { pathname: location.pathname, hash: '', search: location.search },
        {
          replace: true,
          state: location.state,
        }
      );
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div
      className='border-b border-gray-500 last-of-type:border-none'
      ref={ref}
    >
      <div className='flex w-full items-start justify-between gap-x-4 p-4 pr-2'>
        <div className='flex w-[40%] flex-grow justify-between'>
          <div className='flex items-center gap-x-5'>
            <div className='w-[120px] pt-2 font-semibold'>
              {t('by *date*').replace(
                '*date*',
                formatDate(recommendedPaymentDate, 'do MMM')
              )}
            </div>
            <div className='pt-2'>{renderPeriod()}</div>
          </div>
        </div>
        <div className='flex w-[60%] items-start justify-end gap-x-4'>
          <div className='flex flex-col justify-start'>
            {reports.sort(sortReportsFn).map((item, index) => {
              const instruction = item?.payment_instruction;
              const isPaymentInstructionDisregarded =
                instruction?.cancelled && vs5151DisregardedInstruction;

              return (
                <div key={uuidv4()} className='flex justify-end gap-x-2'>
                  {instruction ? (
                    <>
                      {isPaymentInstructionDisregarded ? (
                        <div className='mt-2 flex items-center gap-x-6'>
                          <div>
                            {formatNumber(
                              instruction.amount.amount / 100,
                              {
                                currency: instruction.amount.currency,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                              undefined,
                              true
                            )}
                          </div>
                          <Button
                            styling='naked'
                            className={`group flex items-center !font-semibold ${
                              i18n.language === 'en'
                                ? '!w-[160px]'
                                : '!w-[170px]'
                            }`}
                            onClick={onBtnClick(item)}
                          >
                            <DisregardedPaymentIsntructionIcon className='mr-1 group-hover:[&>*:nth-child(2)]:fill-white group-hover:[&>*]:fill-green' />
                            <span>{t('Disregarded')}</span>
                          </Button>
                        </div>
                      ) : (
                        <div>
                          {instruction.confirmed ||
                          instruction.is_sepa ||
                          instruction.is_null_report ||
                          instruction.amount.amount === 0 ? (
                            <div className='flex items-center gap-x-6'>
                              <div className={`${!!index ? 'pt-2' : 'pt-0'}`}>
                                {formatNumber(
                                  instruction.amount.amount / 100,
                                  {
                                    currency: instruction.amount.currency,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  },
                                  undefined,
                                  true
                                )}
                              </div>
                              <Button
                                styling={!!index ? 'naked' : 'outline'}
                                className={`group !mb-0 flex items-center !font-semibold ${
                                  i18n.language === 'en'
                                    ? '!w-[160px]'
                                    : '!w-[170px]'
                                } ${
                                  !!index
                                    ? '!mt-2'
                                    : reports.length > 1
                                      ? 'mb-2'
                                      : 'mb-0'
                                }`}
                                onClick={onBtnClick(item)}
                              >
                                <PaymentIsntructionIcon
                                  className={`${
                                    !!index
                                      ? 'group-hover:[&>path]:fill-green'
                                      : ''
                                  } mr-1 shrink-0`}
                                />
                                <span>{t('Completed')}</span>
                              </Button>
                            </div>
                          ) : (
                            <div className='flex flex-wrap gap-x-2'>
                              <div className='flex items-start gap-x-6'>
                                <div className='pt-2'>
                                  {formatNumber(
                                    instruction.amount.amount / 100,
                                    {
                                      currency: instruction.amount.currency,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    },
                                    undefined,
                                    true
                                  )}
                                </div>
                                <div>
                                  <Button
                                    styling={!!index ? 'naked' : undefined}
                                    className={`${
                                      !!index
                                        ? '!mt-2'
                                        : reports.length > 1
                                          ? 'mb-2'
                                          : 'mb-0'
                                    } flex ${
                                      i18n.language === 'en'
                                        ? '!w-[160px]'
                                        : '!w-[170px]'
                                    } group items-center !font-semibold`}
                                    onClick={onBtnClick(item)}
                                    disabled={
                                      isShouldSubmitOSSReport(item) &&
                                      !isReportSubmitted(item)
                                    }
                                  >
                                    <PaymentIsntructionIcon
                                      className={`${
                                        !!index
                                          ? 'group-hover:[&>path]:fill-green'
                                          : '[&>path]:fill-white'
                                      } mr-1`}
                                    />
                                    <span>{t('Instruction')}</span>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <Button
                      styling='outline'
                      className={`flex items-center ${
                        i18n.language === 'en' ? '!w-[160px]' : '!w-[170px]'
                      }`}
                      disabled
                    >
                      <PaymentIsntructionIcon className='me-1' />
                      <span>{t('No payment')}</span>
                    </Button>
                  )}
                </div>
              );
            })}
            {reports[0].country.short_name === 'DE' &&
              reports[0].type === 'local' &&
              !!reports[0].payment_instruction && (
                <div className='mt-4 flex justify-end'>
                  <Button
                    styling='outline'
                    className={`${
                      i18n.language === 'en' ? '!w-[160px]' : '!w-[170px]'
                    }`}
                    onClick={onClickAgenda(reports[0])}
                  >
                    {reports[0].agenda_confirmed ? (
                      <div className='flex justify-start gap-x-1'>
                        <AgendaConfirmedIcon />
                        <span>{t('Submitted')}</span>
                      </div>
                    ) : (
                      <div className='flex justify-start gap-x-1'>
                        <AgendaIcon />
                        <span>{t('Submit')}</span>
                      </div>
                    )}
                  </Button>
                </div>
              )}
          </div>
          {!!reports.some((r) => r.confirmation?.documents.length > 0) ? (
            <button
              className='Button--naked flex h-10 w-10 shrink-0 items-center justify-center disabled:bg-transparent hover:disabled:bg-transparent'
              onClick={onFolderBtnClick}
            >
              <FolderIcon />
            </button>
          ) : (
            <Tooltip
              content={t('No files for this period')}
              delay={500}
              position='bottom'
            >
              <Button
                styling='naked'
                className='flex h-10 w-10 disabled:bg-transparent hover:disabled:bg-transparent'
                disabled
              >
                <FolderIcon className='m-auto' />
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
}

const sortReportsFn = (
  a: NormalizedSellerFilingReport,
  b: NormalizedSellerFilingReport
) => {
  if (!a.payment_instruction?.cancelled || !b.payment_instruction?.cancelled) {
    const dateA = a.payment_instruction?.cancelled
      ? 0
      : new Date(
          a.payment_instruction?.sent_at ||
            a.payment_instruction?.created_at ||
            a.deadline_date
        ).getTime();

    const dateB = b.payment_instruction?.cancelled
      ? 0
      : new Date(
          b.payment_instruction?.sent_at ||
            b.payment_instruction?.created_at ||
            a.deadline_date
        ).getTime();

    return dateB - dateA;
  }

  return (
    new Date(
      b.payment_instruction?.sent_at || b.payment_instruction?.created_at
    ).getTime() -
    new Date(
      a.payment_instruction?.sent_at || a.payment_instruction?.created_at
    ).getTime()
  );
};
