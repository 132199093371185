import classNames from 'classnames';
import { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAnswer,
  getClientFCTransfers,
  getClientThresholdByCountries,
  getClientWarehouses,
  getCrossThreshold,
  patchAnswer,
} from '@/core/api';
import { useAppSelector, useMedia } from '../../../hooks';
import PageLayout from '../../../wrappers/PageLayout';
import ConsultationTile from '@/components/ConsultationTile/ConsultationTile';
import Footer from '@/components/Footer/Footer';
import { Loader } from '@/components/Loader/Loader';
import './SetupCheck.scss';
import SetupCheckQuestion from './SetupCheckQuestion';
import SetupCheckThreshold from './SetupCheckThreshold';
import SetupCheckWarehouses from './SetupCheckWarehouses';
import CountryLabel from '@/components/CountryLabel/CountryLabel';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import ChevronDownIcon from '@/icons/chevron-circle-down.svg?react';
import {
  ClientWarehouse,
  CountryThreshold,
  CrossedOss,
  FCTransfer,
} from '@/core/types';
import { TableGrid } from '@/components/TableGrid/TableGrid';
import LinkIcon from '@/icons/link.svg?react';
import { subMonths } from 'date-fns';
import { formatDate, formatNumber, pushEventToDataLayer } from '@/core/helpers';
import FCTransfersRow from './FCTransfersRow';
import NewFCTransferItem from './NewFCTransferItem';
import { useModal } from '@/components/Modal/useModal';
import FCTransfersModal from './FCTransfersModal';
import Switch from '@/components/Switch/Switch';
import { GTMEvents } from '@/core/constants';
import MiniLoader from '@/components/MiniLoader/MiniLoader';
import Button from '@/components/Button';
import {
  activeCompanySelector,
  userSelector,
} from '@/core/store/user/userSlice';
import { useBookMeetingModal } from '@/components/BookMeetingModal/useBookMeetingModal';

const PROBABLY_AFFECTED_THRESHOLD_MAX = 11000;
const PROBABLY_AFFECTED_THRESHOLD_MIN = 9000;

export default function SetupCheck() {
  const user = useAppSelector(userSelector);
  const activeCompany = useAppSelector(activeCompanySelector);
  const homeCountry = user?.Country;

  const { openModal } = useBookMeetingModal();
  const { t } = useTranslation();

  const isTablet = useMedia(['(min-width: 992px)'], [true], false);
  const [sidebarElement, setSidebarElement] = useState<HTMLDivElement | null>(
    null
  );
  const [isSidebarInView, setIsSidebarInView] = useState(false);
  const [hasOss, setHasOss] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFCTransfers, setIsLoadingFCTransfers] = useState(true);
  const [vatIds, setVATIds] = useState<string[]>(
    homeCountry ? [homeCountry] : []
  );
  const [thresholdByCountry, setThresholdByCountry] =
    useState<CountryThreshold[]>();
  const [warehouses, setWarehouses] = useState<ClientWarehouse[]>();

  const [FCTransfers, setFCTransfers] = useState<FCTransfer[]>([]);
  const [crossThreshold, setCrossThreshold] = useState<CrossedOss>();
  const {
    isModalOpened: isFCTransfersModalOpened,
    closeModal: closeFCTransfersModal,
    openModal: openFCTranfersModal,
  } = useModal();

  useIntersectionObserver(sidebarElement, setIsSidebarInView, {
    threshold: 0.5,
  });

  useEffect(() => {
    Promise.allSettled([
      Promise.all([
        getClientThresholdByCountries(),
        getClientWarehouses(),
        getCrossThreshold(),
      ]),
      getAnswer('use_oss'),
      getAnswer('vat_ids'),
    ])
      .then(([dataRes, ossRes, VATIdsRes]) => {
        if ('value' in ossRes && typeof ossRes.value === 'boolean')
          setHasOss(ossRes.value);

        if (
          'value' in VATIdsRes &&
          typeof VATIdsRes.value === 'string' &&
          VATIdsRes.value !== ''
        ) {
          setVATIds(JSON.parse(VATIdsRes.value));
        }

        if ('value' in dataRes) {
          const [thresholdByCountryRes, warehousesRes, crossThresholdRes] =
            dataRes.value;
          setThresholdByCountry(thresholdByCountryRes);
          setWarehouses(warehousesRes);
          setCrossThreshold(crossThresholdRes);
        } else {
          throw dataRes.reason;
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });

    getClientFCTransfers()
      .then((FCTransfersRes) => {
        setFCTransfers(FCTransfersRes);
      })
      .catch(console.error)
      .finally(() => setIsLoadingFCTransfers(false));
    // eslint-disable-next-line
  }, []);

  const warehousesData = (warehouses || []).sort((a, b) => {
    return b.country === homeCountry ? 1 : a.country === homeCountry ? -1 : 0;
  });

  const threshold = useMemo(
    () =>
      Math.round(
        (thresholdByCountry || []).reduce(
          (acc: number, item: CountryThreshold) => {
            return acc + item.threshold.value;
          },
          0
        )
      ),
    [thresholdByCountry]
  );

  const normalizedFCTransfers: NormalizedFCTransfer[] = useMemo(() => {
    const items = FCTransfers || [];

    const countries = [
      ...(FCTransfers || []).map((item) => item.departure_country),
      ...items.map((item) => item.arrival_country),
    ].filter((value, index, self) => {
      return self.findIndex((v) => v === value) === index;
    });

    return countries.reduce((acc: NormalizedFCTransfer[], country) => {
      const arrivals = items.filter(
        (item) => item.departure_country === country
      );
      const departures = items.filter(
        (item) => item.arrival_country === country
      );

      acc.push({
        country,
        departures: {
          countries: departures
            .filter((value, index, self) => {
              return (
                self.findIndex(
                  (v) => v.departure_country === value.departure_country
                ) === index
              );
            })
            .map((item) => item.departure_country),
          total: departures.reduce((acc, item) => {
            return acc + item.number;
          }, 0),
        },
        arrivals: {
          countries: arrivals
            .filter((value, index, self) => {
              return (
                self.findIndex(
                  (v) => v.arrival_country === value.arrival_country
                ) === index
              );
            })
            .map((item) => item.arrival_country),
          total: arrivals.reduce((acc, item) => {
            return acc + item.number;
          }, 0),
        },
      });

      return acc.sort((a, b) => {
        if (b.country === homeCountry) {
          return 1;
        }
        if (a.country === homeCountry) {
          return -1;
        }
        return 0;
      });
    }, []);
  }, [FCTransfers, homeCountry]);

  const homeCountryItem = normalizedFCTransfers?.find(
    (c) => c.country === homeCountry
  );

  const onAnswerOssQuestion: (v: boolean) => MouseEventHandler = (v) => () => {
    setHasOss(v);
    patchAnswer('use_oss', v).catch(console.error);
  };

  const onScrollBtnClick = () => {
    if (sidebarElement) {
      window.scrollBy(0, sidebarElement.getBoundingClientRect().top - 56);
    }
  };

  const isOssAffected = threshold > PROBABLY_AFFECTED_THRESHOLD_MAX;
  const isOssProbablyAffected =
    threshold >= PROBABLY_AFFECTED_THRESHOLD_MIN &&
    threshold <= PROBABLY_AFFECTED_THRESHOLD_MAX;
  const ossQuestionText = 'Have you already registered for OSS?';
  const showOssQuestion = isOssAffected || isOssProbablyAffected;

  const isWarehousesAffected = !!warehouses?.filter(
    (w) => w.country !== user?.Country
  ).length;

  const hasVATIdsInEachCountry =
    warehouses && vatIds.length === warehouses.length;

  const isOssRequired =
    hasOss === false && (isOssAffected || isOssProbablyAffected);

  const isVatIdRequired = !hasVATIdsInEachCountry && isWarehousesAffected;

  const vatIdQuestionText = 'Do you have VAT IDs in each of those countries?';

  const isScrollBlockVisible = !isTablet && !isSidebarInView;

  const isSetupCompilant =
    (!isOssAffected ||
      !isOssProbablyAffected ||
      ((isOssProbablyAffected || isOssAffected) && hasOss)) &&
    (!isWarehousesAffected || (isWarehousesAffected && hasVATIdsInEachCountry));

  const ossRecommendations = [
    ...(isOssAffected && hasOss === false
      ? [
          {
            title: 'Register for OSS',
            text: 'So far you have reached the threshold in 2021 One-Stop-Shop registration is required for you',
          },
        ]
      : []),
    {
      title: 'OSS filing',
      text: 'On the quarterly basis',
    },
  ];

  const warehousesCountriesLabels = warehousesData.map((c) => t(c.country));

  const vatIdRecommendations = [
    ...(isWarehousesAffected && !hasVATIdsInEachCountry
      ? [
          {
            title: t('Register VAT IDs'),
            text: `${t('for')} ${warehousesData
              .filter((w) => !vatIds.includes(w.country))
              .map((w) => t(w.country))
              .join(', ')}`,
          },
        ]
      : []),
    ...(isWarehousesAffected
      ? [
          {
            title: t('Recurring local filing'),
            text: `${t('for')} ${warehousesCountriesLabels.join(', ')}`,
          },
        ]
      : []),
    ...(isWarehousesAffected && !hasVATIdsInEachCountry
      ? [
          {
            title: t('Prepare past dates reports'),
            text: t(
              'If you haven’t provided local reports to countries yet'
            ).replace(
              '{countries}',
              `${warehousesCountriesLabels.slice(0, -1).join(', ')} ${t(
                'and'
              )} ${warehousesCountriesLabels.slice(-1).join('')}`
            ),
          },
        ]
      : []),
  ];

  let thresholdTitle = (
    <div className='font-extrabold'>{t('You are safe')}</div>
  );

  if (isOssProbablyAffected) {
    thresholdTitle = (
      <div className='font-extrabold'>{t('Double check required')}</div>
    );
  }
  if (isOssAffected) {
    thresholdTitle = (
      <div className='text-center font-extrabold'>
        {crossThreshold?.crossed_oss_threshold_label && (
          <div>
            {t('€10,000 threshold is reached in *quarter*.').replace(
              '*quarter*',
              crossThreshold?.crossed_oss_threshold_label
            )}
          </div>
        )}
        <div>{t('OSS required')}</div>
      </div>
    );
  }

  const onClickVATIdSwitch = (c: string) => () => {
    let newVatIds = undefined;
    if (vatIds.includes(c)) {
      newVatIds = vatIds.filter((country) => country !== c);
    } else {
      newVatIds = [...vatIds, c];
    }
    setVATIds(newVatIds);
    patchAnswer('vat_ids', JSON.stringify(newVatIds)).catch(console.error);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <PageLayout
        data-testid='setup-check'
        rightColumn={
          <div className='SetupCheck__right'>
            {(showOssQuestion || isWarehousesAffected) && (
              <div
                className={classNames('SetupCheck__sidebar mb-4 px-4 py-6', {
                  'SetupCheck__sidebar--active':
                    isOssRequired || isVatIdRequired,
                })}
                ref={(node) => setSidebarElement(node)}
              >
                {showOssQuestion && (
                  <>
                    <SetupCheckQuestion
                      text={ossQuestionText}
                      onClickYes={onAnswerOssQuestion(true)}
                      onClickNo={onAnswerOssQuestion(false)}
                      answer={hasOss}
                      data-testid='oss-question'
                    />
                    {isOssProbablyAffected && hasOss === false && (
                      <div className='mt-4 pt-1'>
                        <div
                          className='font-extrabold'
                          data-testid='provide_invoice_books'
                        >
                          <span>1.</span>{' '}
                          <span>{t('Provide invoice books')}</span>
                        </div>
                        <div className='mb-2'>
                          {t(
                            'Perhaps you have alraedy reached the threshold. Our calculations about 95% precise. To be sure, please provide your invoice books and our manager will provide check manually'
                          )}
                        </div>
                        <Button
                          onClick={() => openModal()}
                          className='SetupCheck__ctaBtn mb-2 mr-4'
                        >
                          {t('Book free consultation')}
                        </Button>
                      </div>
                    )}
                    {hasOss !== undefined && (
                      <div className='mt-6'>
                        <div className='mb-2 font-extrabold'>
                          {hasOss === true ? (
                            <>
                              <span>{t('You are safe')}.</span>
                              &nbsp;
                              <span>{t('What you have to do')}:</span>
                            </>
                          ) : (
                            <>
                              <div>
                                {t(
                                  'You have obligations in all countries where you sell'
                                )}
                                .
                              </div>
                              <div>{t('What you have to do')}:</div>
                            </>
                          )}
                        </div>
                        <ol className='pl-4' data-testid='oss-recommendations'>
                          {ossRecommendations.map((r) => (
                            <li className='mb-4 font-extrabold' key={r.title}>
                              <div>{t(r.title)}</div>
                              <div className='!font-normal'>{t(r.text)}</div>
                            </li>
                          ))}
                        </ol>
                      </div>
                    )}
                  </>
                )}
                {isWarehousesAffected && (
                  <>
                    {showOssQuestion && (
                      <div className='SetupCheck__separator -mx-4 mb-2 mt-6' />
                    )}
                    <div className='SetupCheckQuestion__text mb-2 font-extrabold'>
                      {t(vatIdQuestionText)}
                    </div>
                    {warehouses?.map((w) => (
                      <div
                        className='mb-4 flex items-center justify-between'
                        key={w.country}
                      >
                        <CountryLabel country={w.country} className='mr-2' />
                        <Switch
                          checked={vatIds.includes(w.country)}
                          onChange={onClickVATIdSwitch(w.country)}
                        />
                      </div>
                    ))}
                    {hasVATIdsInEachCountry && (
                      <div
                        className='mb-4 font-extrabold'
                        data-testid='obligations-list'
                      >
                        <span>{t('Your setup is compliant')}</span>{' '}
                        <span className='whitespace-nowrap'>
                          {t('Obligations you have')}
                        </span>
                      </div>
                    )}
                    <ol
                      className={classNames('pl-4', {
                        'mb-n3': isWarehousesAffected,
                      })}
                      start={
                        hasOss !== undefined
                          ? ossRecommendations.length + 1
                          : undefined
                      }
                      data-testid='warehouses-recommendations'
                    >
                      {vatIdRecommendations.map((r) => (
                        <li className='mb-4 font-extrabold' key={r.title}>
                          <div>{r.title}</div>
                          {r.text && (
                            <div className='!font-normal'>{r.text}</div>
                          )}
                        </li>
                      ))}
                    </ol>
                  </>
                )}
              </div>
            )}
            <ConsultationTile className='bg-green px-4 pb-6 pt-4' />
          </div>
        }
        className='SetupCheck'
      >
        <div className='row-cols-1 row-cols-sm-auto mb-4 flex flex-wrap items-center gap-x-6 sm:justify-between'>
          <div className=''>
            <div
              className='SetupCheck__title font-black '
              data-testid='setup-check-company'
            >
              <span>{t('VAT setup')}:</span>{' '}
              <span>{activeCompany?.name || user?.CompanyName}</span>
            </div>
          </div>
          <div className=''>
            <div>
              <span>{formatDate(new Date(2021, 0, 1), 'MMM yyyy')} —</span>{' '}
              <span>{formatDate(subMonths(new Date(), 1), 'MMM yyyy')}</span>
            </div>
          </div>
        </div>
        <div className='flex flex-col'>
          <SetupCheckThreshold
            title={thresholdTitle}
            thresholdByCountry={(thresholdByCountry || []).sort(
              (a, b) => b.threshold.value - a.threshold.value
            )}
            threshold={threshold}
            onAnswerOssQuestion={onAnswerOssQuestion}
            questionText={ossQuestionText}
            hasOss={hasOss}
            showQuestion={showOssQuestion}
            className='mb-4'
            crossedOssThresholdLabel={
              crossThreshold?.crossed_oss_threshold_label
            }
          />
          <div className='SetupCheck__card mb-4 flex flex-col justify-between px-4 py-4 lg:px-6'>
            <SetupCheckWarehouses
              warehouses={warehousesData}
              onClickVATIdSwitch={onClickVATIdSwitch}
              vatIds={vatIds}
            />

            {isLoadingFCTransfers ? (
              <MiniLoader className='py-4' size='lg' />
            ) : (
              !!normalizedFCTransfers?.length && (
                <div className='mt-4 border-t border-gray-500 pt-4'>
                  <div className='SetupCheck__title--sm  mb-1 font-extrabold'>
                    {t('Transactions between Amazon warehouses (FC_Transfer)')}
                  </div>
                  <div className='mb-2'>
                    {t(
                      'For such transactions Amazon automatically applies zero VAT rate.'
                    )}
                  </div>
                  <div className='mb-2'>
                    {t(
                      'However, you can only apply the zero VAT rate only if you have a VAT ID for both departure country and arrival country. Otherwise, you will have to pay tax on each item moved based on the purchase price at the applicable VAT rate.'
                    )}
                  </div>
                  <a
                    className='SetupCheckThreshold__link mb-4 inline-block'
                    href='https://taxation-customs.ec.europa.eu/vat-invoicing-rules_en'
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => {
                      pushEventToDataLayer(
                        GTMEvents.setup_check_fc_transfers_learn_more_click
                      );
                    }}
                  >
                    <span className='mr-2'>{t('Learn more')}</span>
                    <LinkIcon className='mb-1' />
                  </a>
                  <>
                    <div className='hidden lg:block'>
                      <TableGrid
                        renderCustomRow={(
                          r,
                          columns,
                          gridTemplateColumns,
                          i
                        ) => (
                          <FCTransfersRow
                            row={r}
                            columns={columns}
                            gridTemplateColumns={gridTemplateColumns}
                            i={i}
                            openModal={openFCTranfersModal}
                            homeCountry={homeCountry}
                            key={i}
                          />
                        )}
                        className='TableGrid--FCTransfers mb-4 pb-1 lg:mb-0'
                        columns={[
                          {
                            label: 'Country',
                            field: 'country',
                            formatter: ({ country }) => (
                              <CountryLabel country={country} />
                            ),
                            maxWidth: '160px',
                          },
                          {
                            label: 'Departures',
                            field: 'departures',
                            formatter: ({ departures }) => (
                              <div className='flex'>
                                <div className='mr-2 font-semibold'>
                                  <div
                                    style={{
                                      minWidth: 52,
                                    }}
                                  >
                                    {formatNumber(departures.total)}
                                  </div>
                                </div>
                                <div className='flex flex-wrap'>
                                  {departures.countries.map((c) => (
                                    <div
                                      key={c}
                                      className='SetupCheck__FCTransferCountryCol mr-2'
                                    >
                                      {t(c)}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ),
                          },
                          {
                            label: 'Arrivals',
                            field: 'arrivals',
                            formatter: ({ arrivals }) => (
                              <div className='flex'>
                                <div className='mr-2 font-semibold'>
                                  <div
                                    style={{
                                      minWidth: 52,
                                    }}
                                  >
                                    {formatNumber(arrivals.total)}
                                  </div>
                                </div>
                                <div className='flex flex-wrap'>
                                  {arrivals.countries.map((c) => (
                                    <div
                                      key={c}
                                      className='SetupCheck__FCTransferCountryCol mr-2'
                                    >
                                      {t(c)}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ),
                          },
                        ]}
                        data={normalizedFCTransfers}
                      />
                    </div>
                    <div className='lg:hidden'>
                      <div className='mb-2 font-semibold'>{t('Country')}</div>
                      {normalizedFCTransfers?.map((f, i) => (
                        <NewFCTransferItem
                          item={f}
                          key={i}
                          homeCountry={homeCountry}
                          openModal={openFCTranfersModal}
                        />
                      ))}
                    </div>
                  </>
                </div>
              )
            )}
          </div>
        </div>
      </PageLayout>
      {isScrollBlockVisible && !isSetupCompilant && (
        <div className='SetupCheck__scrollBlock fixed bottom-0 w-full'>
          {isVatIdRequired || isOssRequired ? (
            <div className='mx-auto flex w-full max-w-[1328px] justify-between'>
              <div>
                <div className='SetupCheck__title--sm mb-2 font-extrabold'>
                  {isOssProbablyAffected && !isVatIdRequired
                    ? t('Your setup might be not compliant')
                    : t('Your setup is not compliant')}
                </div>
                <div className='SetupCheck__text'>
                  {t('See Recommendations')}
                </div>
              </div>
              <Button styling='naked' onClick={onScrollBtnClick}>
                <ChevronDownIcon />
              </Button>
            </div>
          ) : (
            <div className='SetupCheck__title--sm font-extrabold'>
              {t('Answer the questions to see the summary and recommendations')}
            </div>
          )}
        </div>
      )}
      <Footer />
      <FCTransfersModal
        closeModal={closeFCTransfersModal}
        isModalOpened={isFCTransfersModalOpened}
        transactions={
          homeCountryItem
            ? homeCountryItem?.arrivals.total +
              homeCountryItem?.departures.total
            : 0
        }
      />
    </>
  );
}

export interface NormalizedFCTransfer {
  country: string;
  departures: {
    countries: string[];
    total: number;
  };
  arrivals: {
    countries: string[];
    total: number;
  };
}
