import { useAppDispatch } from './useReduxToolkit';
import {
  Notification,
  NotificationStateType,
  addNotification,
  removeNotification,
} from '../core/store/notificationsSlice';

export default function useNotification() {
  const dispatch = useAppDispatch();

  const showNotification = (
    input: Pick<Notification, 'type' | 'text'> & {
      id?: string | number | undefined;
      state?: NotificationStateType | undefined;
      interval?: number | undefined;
    }
  ) => {
    dispatch(addNotification(input));
  };

  const deleteNotification = (input: string | number) => {
    dispatch(removeNotification(input));
  };

  return { showNotification, deleteNotification };
}
