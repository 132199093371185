import './Select.scss';
import { SelectOption } from '@/core/types';
import {
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { useOnClickOutside, useDebounce, useSearch } from '../../hooks';
import PolygonIcon from '@/icons/chevron-right.svg?react';
import PolygonSmIcon from '@/icons/select-chevron.svg?react';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
import { SearchInput } from '../SearchInput/SearchInput';

interface Props {
  options: SelectOption[];
  value?: number | string;
  onChange: (v: string | number) => void;
  onBlur?: () => void;
  placeholder?: string;
  className?: string;
  label?: string | null;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
  isOpenAbove?: boolean;
  name?: string;
  size?: 'lg' | 'sm' | 'md';
  search?: boolean;
}

const getActiveOption = (value: string | number, options: SelectOption[]) =>
  options.find((option) => value === option.id);

export const Select = ({
  options,
  value,
  placeholder = 'Please select',
  onChange,
  onBlur,
  className = '',
  name,
  label,
  error,
  touched,
  disabled = false,
  isOpenAbove,
  size = 'md',
  search,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const { found, onSearchChange, searchString } = useSearch<SelectOption>(
    options,
    filterFn
  );

  const dropdownRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<any>(null);
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpened, setIsOpened] = useState(false);
  const checkedOption = value ? getActiveOption(value, options) : undefined;
  const debouncedIsOpened = useDebounce(isOpened, 250);
  const isDropdownOpened = isOpened || debouncedIsOpened;
  const [activeIndex, setActiveIndex] = useState(
    options.findIndex((o) => o.id === value)
  );

  const content = checkedOption
    ? checkedOption.content || t(checkedOption.name)
    : t(placeholder);

  const onClickOuside = () => {
    if (!isOpened) return;
    setIsOpened(false);
    onBlur && onBlur();
  };

  useOnClickOutside(ref, onClickOuside);

  const onClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (disabled) return;
    setIsOpened(!isOpened);
  };

  const handleBlur = () => {
    if (disabled) return;
    if (onBlur) onBlur();
  };

  const onSelect = (optionId: string | number) => () => {
    setIsOpened(false);
    onChange(optionId);
  };

  useEffect(() => {
    if (isOpened) {
      inputRef.current?.focus();
    }
  }, [isOpened]);

  useEffect(() => {
    if (isOpened && activeIndex !== -1 && menuRef.current) {
      menuRef.current.el.focus();
      const contentWrapper = menuRef.current.getContentElement();
      const targetEl = contentWrapper.children[activeIndex];
      const targetRect = targetEl?.getBoundingClientRect();
      const containerRect = menuRef.current.el
        .querySelector('.simplebar-content-wrapper')
        .getBoundingClientRect();

      if (
        targetRect &&
        containerRect &&
        activeIndex <= options.length - 1 &&
        (targetRect.bottom > containerRect.bottom ||
          targetRect.top < containerRect.top)
      ) {
        targetEl.scrollIntoView();
      }
    }

    if (!isOpened) {
      setActiveIndex(-1);
    }
  }, [isOpened, activeIndex, options]);

  const onKeyDown: KeyboardEventHandler<HTMLButtonElement> = (event) => {
    switch (event.key) {
      case ' ':
      case 'Enter':
        if (isOpened && activeIndex !== -1 && options[activeIndex].id) {
          onSelect(options[activeIndex].id)();
          break;
        }
        return;
      case 'Escape':
        if (isOpened) setIsOpened(false);
        break;
      case 'ArrowUp':
        if (isOpened) {
          if (activeIndex > 0) {
            setActiveIndex((s) => s - 1);
          } else {
            setActiveIndex(options.length - 1);
          }
        }
        break;
      case 'ArrowDown':
        if (isOpened) {
          if (activeIndex !== -1 && activeIndex < options.length - 1) {
            setActiveIndex((s) => s + 1);
          } else {
            setActiveIndex(0);
          }
        }
        break;

      default:
        return;
    }
    event.preventDefault();
  };

  return (
    <div
      className={classNames('Select z-10', className, {
        'Select--lg': size === 'lg',
        'Select--sm': size === 'sm',
      })}
    >
      {!!label && <div className='Select__label'>{t(label)}</div>}
      <button
        data-name={name}
        data-value={value}
        data-testid={name}
        ref={ref}
        type='button'
        className={classNames(
          'Select__input flex items-center justify-between',
          {
            'Select__input--active': isOpened,
            'Select__input--disabled': disabled,
            'Select__input--invalid': touched && error,
          }
        )}
        onClick={onClick}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
      >
        <span
          className={classNames({
            Select__value: value,
            Select__placeholder: !value,
          })}
        >
          {content}
        </span>
        {size === 'lg' ? (
          <PolygonIcon className='Select__arrow' />
        ) : (
          <PolygonSmIcon className='Select__arrow' />
        )}
        {isDropdownOpened && (
          <div
            className={classNames('Select__dropdown', {
              fadeIn: isOpened,
              fadeOut: !isOpened,
              'Select__dropdown--up': isOpenAbove,
            })}
            ref={dropdownRef}
          >
            {search && (
              <div onClick={(e) => e.stopPropagation()} className='p-1'>
                <SearchInput
                  ref={inputRef}
                  name='search'
                  onChange={onSearchChange}
                  value={searchString}
                  placeholder={t('Search')}
                />
              </div>
            )}
            <SimpleBar
              style={{ maxHeight: 220 }}
              ref={menuRef}
              autoHide={false}
              className='Select__scrollbar'
            >
              {(search ? found || [] : options).map((o, i) => (
                <div
                  style={{ position: 'relative', zIndex: 1000 }}
                  className={classNames('Select__dropdownItem text-start', {
                    'Select__dropdownItem--active': activeIndex === i,
                  })}
                  onClick={onSelect(o.id)}
                  key={o.id}
                >
                  {o.content || t(o.name)}
                </div>
              ))}
            </SimpleBar>
          </div>
        )}
      </button>
      {touched && error && (
        <div className='Select__error' data-testid={`${name}-error`}>
          {t(error)}
        </div>
      )}
    </div>
  );
};

const filterFn = (str: string) => (c: SelectOption) => {
  if (typeof c.name !== 'string') return true;
  return c.name.toLowerCase().includes(str.toLowerCase().trim());
};
