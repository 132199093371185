import { useTranslation } from 'react-i18next';
import Modal from '@/components/Modal/Modal';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
}

export default function RetroactiveFilingModal({
  isModalOpened,
  closeModal,
}: Props) {
  const { t } = useTranslation();
  return (
    <Modal isOpened={isModalOpened} close={closeModal} title={t('Information')}>
      <div className='mx-4 px-4 pb-6 pt-4'>
        <div className='Text font-extrabold'>
          {t('Retroactive filing').replace('filing', 'Filing')}
        </div>
        <div className='mb-4'>
          {t(
            "Retroactive filings (filings for past periods that weren't submitted on time) are possible, but the conditions vary on the country and the specific situation. Our managers will discuss your situation in details with tax authorities in each country to find the best possible solution for you"
          )}
        </div>
      </div>
    </Modal>
  );
}
