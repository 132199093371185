import { createContext, PropsWithChildren } from 'react';
import { CountryThreshold } from '../pages/Seller/SellerSetupCheck';

export const ThresholdContext = createContext<{
  threshold: number;
  thresholdByCountries: CountryThreshold[] | undefined;
}>({
  threshold: 0,
  thresholdByCountries: undefined,
});

export function ThresholdProvider({
  children,
  threshold,
  thresholdByCountries,
}: PropsWithChildren<{
  threshold: number;
  thresholdByCountries: CountryThreshold[] | undefined;
}>) {
  return (
    <ThresholdContext.Provider value={{ threshold, thresholdByCountries }}>
      {children}
    </ThresholdContext.Provider>
  );
}
