import { useTranslation } from 'react-i18next';
import AlertIcon from '@/icons/alert-red.svg?react';
import ArrowRightIcon from '@/icons/chevron-right.svg?react';
import CloseIcon from '@/icons/close.svg?react';
import titleImage from '@/images/new-process-instruction-heading.png';
import Collapse from '@/components/Collapse/Collapse';
import { links } from '../../pages/Seller/SellerDocuments/instructionsData.helpers';
import { useSellerInstructionWidget } from './useSellerInstructionWidget';

export default function SellerInstructionWidget() {
  const { t, i18n } = useTranslation();

  const { isExpanded, toggleIsExpanded } = useSellerInstructionWidget();

  return (
    <div className='fixed bottom-1 right-2 z-30 w-[400px] rounded-2xl drop-shadow-2xl'>
      <Collapse
        isOpened={isExpanded}
        onTriggerClick={toggleIsExpanded}
        className='flex flex-col-reverse items-end gap-y-2'
        triggerRenderer={(isOpened, toggleCollapse) => (
          <div className='cursor-pointer'>
            {isOpened ? (
              <button
                className='flex h-11 w-11 items-center justify-center rounded-2xl border bg-white hover:border-gray-500'
                onClick={toggleCollapse}
                data-testid='seller-instruction-widget-expanded'
              >
                <CloseIcon className='h-3 w-3' />
              </button>
            ) : (
              <button
                onClick={toggleCollapse}
                data-testid='seller-instruction-widget-closed'
                className='flex w-[400px] items-center justify-between rounded-2xl bg-green py-1.5 pl-3 pr-2 transition-transform'
              >
                <div className='text-start font-extrabold text-white'>
                  {t('How to work with the new interface')}
                </div>
                <div className='flex h-8 w-8 items-center justify-center rounded-xl bg-green-300'>
                  <AlertIcon className='[&>path]:fill-white' />
                </div>
              </button>
            )}
          </div>
        )}
        contentRenderer={(toggleCollapse) => (
          <div>
            <div className='relative w-full overflow-hidden rounded-t-2xl bg-green pl-4'>
              <div
                className={`Title--sm py-3 font-extrabold text-white ${
                  i18n.language === 'en' ? 'max-w-[176px]' : 'max-w-[500px]'
                }`}
              >
                {t('How to work with the new interface')}
              </div>
              <img
                src={titleImage}
                alt='new-process-title-image'
                className='absolute right-0 top-0 w-[104px]'
              />
            </div>
            <div className='rounded-b-2xl border bg-white p-4'>
              <div className='pb-4'>
                {t(
                  'We have updated our processes and the interface to work with your data'
                )}
              </div>
              <div className='flex flex-col gap-y-2 '>
                {links.map((l, i) => (
                  <a
                    className='Button--outline inline-flex w-full items-center justify-between px-3 py-2 !text-blue-500'
                    href={`/data-files/how-to-work${l.href}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    key={l.href}
                    onClick={toggleCollapse}
                    data-testid={`instruction-link-${i}`}
                  >
                    <span>{t(l.title)}</span>
                    <ArrowRightIcon className='[&>g>path]:fill-blue-500' />
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
}
