import classNames from 'classnames';
import { useState } from 'react';
import ArrowRight from '@/icons/chevron-right-lg.svg?react';
import './Table.scss';

export type SortOrderType = 'asc' | 'desc';

interface Props<T> {
  columns: TableColumn<T>[];
  data: T[];
  initialSortBy?: keyof (T & { id: string | number });
  sortFn?: (sortBy: any, order: SortOrderType) => (a: T, b: T) => number;
  initialSortOrder?: SortOrderType;
  className?: string;
  sortExternalFn?: (c: any, dir: 'asc' | 'desc' | undefined) => void;
  renderCustomRow?: (r: T, columns: TableColumn<T>[], i: number) => JSX.Element;
}

export interface TableColumn<T> {
  field: keyof T;
  label: string | JSX.Element;
  renderer: (v: T, i?: number) => string | number | null | JSX.Element;
  sortable?: boolean;
  size?: string;
  empty?: boolean;
  btn?: JSX.Element;
  className?: string;
  cellClassName?: string;
}

export const Table = <T,>({
  columns,
  data,
  initialSortBy,
  className = '',
  initialSortOrder,
  sortFn = (field: keyof T, order: SortOrderType) => (a: T, b: T) =>
    sortFunction(order)(a[field], b[field]),
  sortExternalFn,
  renderCustomRow,
  ...rest
}: Props<T>) => {
  const [sortOrder, setSortOrder] = useState<SortOrderType | undefined>(
    initialSortOrder
  );
  const [sortBy, setSortBy] = useState<keyof T | undefined>(
    initialSortBy as keyof T
  );

  const onSortColumn = (field: keyof T) => () => {
    if (sortOrder === 'desc' && sortBy === field) {
      sortExternalFn?.(undefined, undefined);
      setSortOrder(undefined);
      return setSortBy(undefined);
    }

    if (sortBy !== field || !sortBy) {
      sortExternalFn?.(field, 'asc');
      setSortBy(field);
      return setSortOrder('asc');
    }

    sortExternalFn?.(field, sortOrder === 'asc' ? 'desc' : 'asc');
    setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    setSortBy(field);
  };

  return (
    <div className={`Table ${className}`} {...rest}>
      <table className='Table__table'>
        <thead className='Table__header'>
          <tr>
            {columns.map((c) => {
              return (
                <th
                  key={c.field as string}
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  <div
                    className={classNames(
                      'Table__cell flex items-center justify-start',
                      c.className,
                      {
                        'Table__cell--sortable': c.sortable,
                        'Table__cell--sortable--asc':
                          sortBy === c.field && sortOrder === 'asc',
                        'Table__cell--sortable--desc':
                          sortBy === c.field && sortOrder === 'desc',
                      }
                    )}
                    data-testid={`${String(c.label)}-header-cell`}
                  >
                    <div className='flex items-center'>
                      <div>{c.empty ? '' : c.label}</div>
                      {c.btn && (
                        <div className='flex items-center justify-center'>
                          {c.btn}
                        </div>
                      )}
                    </div>
                    {c.sortable && (
                      <button
                        onClick={c.sortable ? onSortColumn(c.field) : undefined}
                        className='mx-1 flex items-center justify-center rounded-none bg-transparent p-0'
                        data-testid={`${String(c.label)}-sort-btn`}
                      >
                        <ArrowRight className='Table__arrow' />
                      </button>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {(sortOrder && sortBy && !sortExternalFn
            ? [...data].sort(sortFn(sortBy as string, sortOrder))
            : data
          ).map((row, i) =>
            renderCustomRow ? (
              renderCustomRow(row, columns, i)
            ) : (
              <tr
                className='Table__row'
                key={(row as T & { id: string | number })?.id || i}
                data-testid={`row-${
                  (row as T & { id: string | number })?.id || i
                }`}
                data-index={i}
              >
                {columns.map((c) => (
                  <td
                    key={c.field as string}
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                    className={c.cellClassName}
                  >
                    <div
                      className='Table__cell'
                      data-testid={`row-${i}-${String(c.label)}`}
                    >
                      {c.renderer(row, i)}
                    </div>
                  </td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

const sortFunction = (order: SortOrderType) => (a: any, b: any) => {
  if (typeof a === 'string' && typeof b === 'string') {
    const statusA = (a as unknown as string).toUpperCase();
    const statusB = (b as unknown as string).toUpperCase();

    if (statusA < statusB) {
      return order === 'asc' ? -1 : 1;
    }
    if (statusA > statusB) {
      return order === 'asc' ? 1 : -1;
    }
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return order === 'asc' ? a - b : b - a;
  }

  if (typeof a === 'boolean' && typeof b === 'boolean') {
    if (b === true && a === false) {
      return order === 'desc' ? -1 : 1;
    }
    if (a === true && b === false) {
      return order === 'desc' ? 1 : -1;
    }
  }

  return 0;
};
