import classNames from 'classnames';

interface Props {
  className?: string;
  color?: SpinnerColor;
}

export type SpinnerColor = 'dark' | 'white';

export const Spinner = ({ className = '', color, ...rest }: Props) => {
  return (
    <span
      {...rest}
      className={classNames(
        'infinite box-border inline-block h-4 w-4 shrink-0 animate-spin rounded-full border border-dark border-b-transparent',
        className,
        {
          'border-white': color === 'white',
        }
      )}
    />
  );
};
