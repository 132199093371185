import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks';
import { activeCompanySelector } from '@/core/store/user/userSlice';
import CountryLabel from '../CountryLabel/CountryLabel';
import { formatDate } from '@/core/helpers';
import { normalizeDateFE } from '@/core/normalization';
import CopyIcon from '@/icons/copy.svg?react';
import copy from 'copy-to-clipboard';
import useNotification from '../../hooks/useNotification';
import { VATId } from '@/core/types';
import classNames from 'classnames';

interface Props {
  className?: string;
  registeredVATIds?: VATId[];
  position?: 'row' | 'column';
}

export default function OSSDetailsBlock({
  className = '',
  registeredVATIds,
  position = 'row',
}: Props) {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const activeCompany = useAppSelector(activeCompanySelector);
  const registrationDate =
    activeCompany?.ClientOSSDetails?.RegistrationDate ||
    activeCompany?.ClientOSSDetails?.OssEffectiveStartDate;

  const onClickCopy = (text: string) => () => {
    copy(text.slice(2));
    showNotification({
      type: 'success',
      text: 'VAT ID is copied',
    });
  };

  return (
    <div
      className={classNames('mt-2 rounded-lg bg-white', className, {
        'mt-4 flex flex-col items-center space-y-0.5 border-2 border-white xl:mt-0':
          position === 'column',
      })}
    >
      <div
        className={classNames('px-6 pb-6 pt-2', {
          'w-full rounded-t-lg bg-gray-300 bg-opacity-[55%]':
            position === 'column',
        })}
      >
        <h4
          className={classNames('Text mb-2 font-extrabold', {
            'Title--sm': position === 'column',
          })}
        >
          {t('OSS details')}
        </h4>
        <div
          className={classNames({
            'grid grid-cols-[minmax(100px,0.94fr),minmax(120px,0.9fr),minmax(90px,0.8fr),minmax(120px,1fr)] gap-x-4':
              position === 'row',
            'flex w-full flex-col items-center justify-between space-y-2':
              position === 'column',
          })}
        >
          <div
            className={classNames({
              'flex w-full justify-between gap-x-2': position === 'column',
            })}
          >
            <div
              className={classNames('shrink-0', {
                'text-gray-700': position === 'row',
              })}
            >
              {t('Registered in')}
            </div>
            <CountryLabel
              country={activeCompany?.ClientOSSDetails?.RegistrationCountry!}
              className={classNames({ 'justify-end': position === 'column' })}
              data-testid='oss_country'
            />
          </div>
          <div
            className={classNames({
              'flex w-full justify-between gap-x-2': position === 'column',
            })}
          >
            <div
              className={classNames({ 'text-gray-700': position === 'row' })}
            >
              {t('VAT ID')}
            </div>
            <div className='flex items-center gap-x-2'>
              <span data-testid='oss_vat_number'>
                {activeCompany?.ClientOSSDetails?.VATIdNumber}
              </span>
              {activeCompany?.ClientOSSDetails?.VATIdNumber && (
                <button
                  onClick={onClickCopy(
                    activeCompany?.ClientOSSDetails?.VATIdNumber
                  )}
                >
                  <CopyIcon className='h-4 w-4' />
                </button>
              )}
            </div>
          </div>
          <div
            className={classNames({
              'flex w-full justify-between gap-x-2': position === 'column',
            })}
          >
            <div
              className={classNames('shrink-0', {
                'text-gray-700': position === 'row',
              })}
            >
              {t('Registration date')}
            </div>
            <div data-testid='oss_reg_date'>
              {registrationDate &&
                formatDate(normalizeDateFE(registrationDate), 'dd.MM.yyyy')}
            </div>
          </div>
          <div
            className={classNames({
              'flex w-full items-start justify-between gap-x-2':
                position === 'column',
            })}
          >
            <div
              className={classNames('shrink-0', {
                'text-gray-700': position === 'row',
              })}
            >
              {t('Filing period')}
            </div>
            <div
              className={classNames({
                'text-end': position === 'column',
              })}
              data-testid='oss_filing_period'
            >
              {!activeCompany?.ClientOSSDetails?.FilingPeriodTo && t('since')}{' '}
              {activeCompany?.ClientOSSDetails?.FilingPeriodFrom &&
                formatDate(
                  normalizeDateFE(
                    activeCompany?.ClientOSSDetails?.FilingPeriodFrom
                  ),
                  'MMMM yyyy'
                )}
              {activeCompany?.ClientOSSDetails?.FilingPeriodTo &&
                `- ${formatDate(
                  normalizeDateFE(
                    activeCompany?.ClientOSSDetails?.FilingPeriodTo
                  ),
                  'MMMM yyyy'
                )}`}
            </div>
          </div>
        </div>
      </div>
      {!!registeredVATIds?.length && (
        <div
          className={classNames('space-y-2 px-4 pb-6 pt-2', {
            'w-full rounded-b-lg bg-gray-300 bg-opacity-[55%]':
              position === 'column',
          })}
        >
          <h4
            className={classNames('Text mb-2 font-extrabold', {
              'Title--sm': position === 'column',
            })}
          >
            {t('VAT details')}
          </h4>
          <div>{t('For countries where you use warehouses')}</div>
          {position === 'row' && (
            <div className='mt-2 grid grid-cols-[minmax(100px,0.94fr),minmax(120px,0.9fr),minmax(90px,0.8fr),minmax(120px,1fr)] gap-x-4'>
              <div className='text-gray-700'>{t('Country')}</div>
              <div className='text-gray-700'>{t('VAT ID')}</div>
              <div />
              <div />
            </div>
          )}
          {registeredVATIds.map((v) => (
            <div
              className={classNames({
                'mt-2 grid grid-cols-[minmax(100px,0.94fr),minmax(120px,0.9fr),minmax(90px,0.8fr),minmax(120px,1fr)] gap-x-4 font-semibold':
                  position === 'row',
                'flex items-center justify-between': position === 'column',
              })}
              key={v.Country.id}
            >
              <CountryLabel
                country={v.Country.short_name}
                className={classNames({
                  'flex-grow truncate': position === 'column',
                })}
              />

              <div className='flex items-center gap-x-2'>
                {v.vat_number}{' '}
                {v.vat_number && (
                  <button onClick={onClickCopy(v.vat_number)}>
                    <CopyIcon className='h-4 w-4' />
                  </button>
                )}
              </div>
              <div />
              <div />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
