import WhiteGreenWrapper from '../../../wrappers/WhiteGreenWrapper';
import { getRetinaImgSrc } from '@/core/helpers';
import { useTranslation } from 'react-i18next';
import MiscImg from '@/images/misc.svg';
import ShieldIcon from '@/icons/shield.svg?react';
import './AmazonConnectionConsultation.scss';
import Button from '@/components/Button';
import { useBookMeetingModal } from '@/components/BookMeetingModal/useBookMeetingModal';

interface Props {
  onClickBtn: () => void;
}

export default function AmazonConnectionConsultation({ onClickBtn }: Props) {
  const { openModal } = useBookMeetingModal();
  const { t, i18n } = useTranslation();
  return (
    <WhiteGreenWrapper
      data-testid='amazon-connection-consultation'
      rightSideContent={
        <div className='pt-6'>
          <div className='text-center'>
            <img
              src={'/images/daniel-amazon-connect.png'}
              srcSet={getRetinaImgSrc('/images/daniel-amazon-connect-2x.png')}
              alt='AmazonConnectImage'
              className='AmazonConnection__image mx-auto'
            />
          </div>
          <div className='mb-2 mt-6 text-center text-h4 font-extrabold text-white'>
            Daniel Michael
          </div>
          <div
            className='mx-auto mb-6 text-center'
            style={{
              maxWidth: i18n.language === 'en' ? 350 : 474,
            }}
          >
            {t(
              'Will help you to get data from the invoice system, process it and give an interpretation'
            )}
          </div>
          <img
            src={MiscImg}
            alt=''
            className='AmazonConnectionConsultation__misc mx-auto'
          />
        </div>
      }
      className='WhiteGreenWrapper--AmazonConnection'
    >
      <div
        style={{
          maxWidth: i18n.language === 'en' ? 506 : 552,
        }}
      >
        <div className='Title mb-4 font-black'>
          {t('Book a free consultation with our manager')}
        </div>
        <div className='Text'>
          {t(
            "Unfortunately, we can't check your non-Amazon sales automatically, but we can do it manually."
          )}
        </div>
        <div className='Text mb-4'>
          {t(
            'Book a free consultation and our manager will explain how to provide your data for the setup check.'
          )}
        </div>
        {[
          'Select your time',
          'Get an instruction on data preparation',
          'Our manager reviews your data',
          'Manager interprets the results',
        ].map((p) => (
          <div className='Text mb-2 flex lg:mb-4' key={p}>
            <ShieldIcon className='mr-2 flex-shrink-0 lg:mt-1' />
            <div className='lg:whitespace-pre-line'>{t(p)}</div>
          </div>
        ))}
        <div className='flex gap-x-6  pt-4'>
          <div className=' mb-4'>
            <Button className='Text !px-6' onClick={() => openModal()}>
              {t('Book a consultation')}
            </Button>
          </div>
          <div className=' mb-4 flex items-center'>
            <Button
              styling='naked'
              className='Text sm:ml-6'
              onClick={onClickBtn}
            >
              {t('connect_amazon_btn_text')}
            </Button>
          </div>
        </div>
      </div>
    </WhiteGreenWrapper>
  );
}
