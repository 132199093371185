import { useTranslation } from 'react-i18next';
import './ConsultationTile.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getRetinaImgSrc } from '@/core/helpers';
import Button from '../Button';
import { useBookMeetingModal } from '../BookMeetingModal/useBookMeetingModal';

interface Props {
  text?: string;
  className?: string;
}

export default function ConsultationTile({
  text = 'Book a free consultation with our manager to discuss all the questions you have and get advices',
  className = '',
}: Props) {
  const { t } = useTranslation();
  const { openModal } = useBookMeetingModal();

  return (
    <div className={`ConsultationTile text-white ${className}`}>
      <div className='ConsultationTile__text mb-4'>{t(text)}</div>
      <div className='flex items-center'>
        <Button
          onClick={() => openModal()}
          className='ConsultationTile__btn mr-4'
          styling='dark'
        >
          {t('Book free consultation')}
        </Button>
        <LazyLoadImage
          src={'/images/daniel.png'}
          srcSet={getRetinaImgSrc('/images/daniel-2x.png')}
          alt='Daniel'
          wrapperClassName='flex items-center justify-center'
          className='ConsultationTile__managerImg'
        />
      </div>
    </div>
  );
}
