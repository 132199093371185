import IntegrationsConnectButton from './IntegrationsConnectButton';
import { useTranslation } from 'react-i18next';
import ConnectIcon from '@/icons/connect.svg?react';
import InvalidIntegrationIcon from '@/icons/integration-invalid.svg?react';
import { format } from 'date-fns';
import { BillbeeData, EasybillData } from '@/core/types';
import { Badge } from '../Badge/Badge';
import Tooltip from '../Tooltip/Tooltip';

interface Props {
  onClickConnect: () => void;
  iconSrc: string;
  name: string;
  label: string;
  data: EasybillData | BillbeeData;
}

const isBillbeeData = (
  data: EasybillData | BillbeeData
): data is BillbeeData => {
  return 'is_active' in data;
};

export const IntegrationWidget = ({
  onClickConnect,
  iconSrc,
  name,
  label,
  data,
}: Props) => {
  const { t } = useTranslation();

  const isValid = data?.is_valid;

  const invalidDate = (name: string) => {
    if (name === 'easybill' && 'invalid_date' in data) {
      return data.invalid_date;
    }

    if (name === 'billbee' && 'invalid_at' in data) {
      return data.invalid_at;
    }

    return '';
  };

  const connectedDate = (name: string) => {
    if (name === 'easybill' && 'create_date' in data) {
      return data.create_date;
    }

    if (name === 'billbee' && 'connected_at' in data) {
      return data.connected_at;
    }

    return '';
  };

  const isActive = (name: string) => {
    if (name === 'easybill') {
      return (
        data.is_valid ||
        ('invalid_date' in data && data.invalid_date) ||
        ('create_date' in data && data.create_date)
      );
    }

    if (name === 'billbee' && isBillbeeData(data)) {
      return data.is_active;
    }

    return false;
  };

  const renderContent = () => {
    if (isValid && connectedDate(name)) {
      return (
        <Tooltip
          content={`Connected ${format(
            new Date(connectedDate(name)),
            'dd.MM.yyyy'
          )}`}
        >
          <Badge
            color='green'
            className='flex items-center !px-2'
            testId={`${name}-valid`}
            label={
              <>
                <ConnectIcon className='Integrations__validIcon mr-1 inline-block !h-4 !w-4' />
                <span>{t('Active')}</span>
              </>
            }
          />
        </Tooltip>
      );
    }
    if (isValid && !connectedDate(name)) {
      return (
        <Badge
          color='green'
          className='flex items-center !px-2'
          data-testid={`${name}-valid`}
          label={
            <>
              <ConnectIcon className='Integrations__validIcon mr-1 inline-block !h-4 !w-4 items-center' />
              <span>{t('Active')}</span>
            </>
          }
        />
      );
    }
    if (!isValid && invalidDate(name)) {
      return (
        <Tooltip
          content={`${t('Since')} ${format(
            new Date(invalidDate(name)),
            'dd.MM.yyyy'
          )}`}
        >
          <Badge
            color='red'
            className='flex items-center !px-2'
            testId={`${name}-not-valid`}
            label={
              <>
                <InvalidIntegrationIcon className='Integrations__invalidIcon mr-1 inline-block !h-4 !w-4' />
                <span>{t('Non-valid')}</span>
              </>
            }
          />
        </Tooltip>
      );
    }
    if (!isValid && !isActive(name)) {
      return (
        <IntegrationsConnectButton
          testId={`${name}-not-active`}
          onClick={onClickConnect}
        />
      );
    }
    return (
      <Badge
        color='red'
        className='flex items-center !px-2'
        testId={`${name}-not-valid`}
        label={
          <>
            <InvalidIntegrationIcon className='Integrations__invalidIcon mr-1 inline-block !h-4 !w-4 items-center' />
            <span>{t('Non-valid')}</span>
          </>
        }
      />
    );
  };

  return (
    <>
      <div
        className='flex items-center justify-between font-bold'
        data-testid={`integrations-${name}`}
      >
        <div className='flex items-center'>
          <img
            src={iconSrc}
            alt={name}
            style={{
              width: 40,
              height: 40,
            }}
            className='mr-2 flex-shrink-0'
          />
          <span>{label}</span>
        </div>
        <div>{renderContent()}</div>
      </div>
      {!isValid && isActive(name) && name !== 'billbee' && (
        <IntegrationsConnectButton
          testId={`${name}-update-btn`}
          onClick={onClickConnect}
          className='mt-2 !w-full'
        >
          <span>{t('Update')}</span>
        </IntegrationsConnectButton>
      )}
    </>
  );
};
