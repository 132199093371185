import { useAppSelector } from '../../hooks';
import Notification from './Notification';
import './Notifications.scss';

export default function Notifications() {
  const notifications = useAppSelector((s) => s.notifications.list);
  return (
    <div className='Notifications fixed'>
      <div>
        {notifications.map((n) => (
          <Notification key={n.id} data={n} />
        ))}
      </div>
    </div>
  );
}
