import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useState, useRef, useEffect, useMemo } from 'react';
import { useModal } from '../components/Modal/useModal';
import BackIcon from '@/icons/arrow-left.svg?react';
import DownloadIcon from '@/icons/download.svg?react';
import { useTranslation } from 'react-i18next';
import {
  getDatev,
  getDatevLedger,
  getDatevLedgerAggregated,
  getTypeformSubmissions,
} from '../core/api';
import { useQuery } from '@tanstack/react-query';
import { useAppSelector, APIEventsEnum, useSendEvent } from '../hooks';
import {
  activeCompanySelector,
  userSelector,
} from '../core/store/user/userSlice';
import { formatDate, formatNumber } from '../core/helpers';
import { Table } from '../components/Table/Table';
import {
  DatevTransaction,
  DatevPaging,
  DatevAggregatedItem,
} from '../core/types';
import { normalizeDateFE } from '../core/normalization';
import Pagination from '../components/Pagination/Pagination';
import Button from '../components/Button';
import { Loader } from '../components/Loader/Loader';
import FeatureUnderDevelopmentModal from '../components/FeatureUnderDevelopmentModal';
import { RolesEnum, AuthedRoutesEnum } from '../core/enums';
import {
  DatevEntryTypesMapping,
  FiltersLabels,
} from '../components/Datev/Datev.helpers';
import InfoIcon from '@/icons/info-outline.svg?react';
import DatevQuizModal, { FF } from '../components/DatevQuizModal';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function DatevTransactionsOverview() {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const currentPage = +(params.get('page') || 1);
  const currentFilter = params.get('entry_type');
  const qsCompanyIdParam = params.get('company_id');
  const activeCompany = useAppSelector(activeCompanySelector);
  const companyId = qsCompanyIdParam || activeCompany?.company_id;

  const { year, month } = useParams<{
    year: string;
    month: string;
  }>();

  const user = useAppSelector(userSelector);
  const { vs4306DatevQuiz } = useFlags();

  const { data, isLoading: isLoadingTypeformSubmissions } = useQuery({
    queryKey: ['UserTypeformSubmissions', user?.Id],
    queryFn: () => getTypeformSubmissions(user!.Id),
  });

  const canSubmitTypeform = !(data || []).find((s) => s.feature_flag === FF);

  const { sendEvent } = useSendEvent();

  const dateString = formatDate(new Date(+year!, +month! - 1), 'MMM yyyy');
  const [transactions, setTransactions] = useState<DatevTransaction[]>([]);
  const [paging, setPaging] = useState<DatevPaging | undefined>();

  const { data: aggregated, isLoading: isLoadingAggregated } = useQuery({
    queryKey: ['SellerDatevAggregated', user?.Id, companyId],
    queryFn: () => getDatevLedgerAggregated(companyId, +year!, +month!),
  });

  const { data: files, isLoading: isLoadingFiles } = useQuery({
    queryKey: ['SellerDatev', user!.Id, companyId],
    queryFn: () => getDatev(companyId),
  });

  const requestedEnitityTypes = useMemo(
    () =>
      (files || [])
        .filter((r) => {
          const date = normalizeDateFE(r.service_date);
          return (
            date.getMonth() + 1 === +month! && date.getFullYear() === +year!
          );
        })
        .map((f) => DatevEntryTypesMapping[f.option]),
    [files, month, year]
  );

  const isExplanationShown =
    currentFilter && !requestedEnitityTypes.includes(+currentFilter);

  const totalSum = (aggregated || []).reduce(
    (sum: number, item: DatevAggregatedItem) => sum + item.count,
    0
  );
  const entryTypeCounts: { [key: string]: number } = (aggregated || []).reduce(
    (counts: { [key: string]: number }, item: DatevAggregatedItem) => {
      counts[item.entry_type.toString()] =
        (counts[item.entry_type.toString()] || 0) + item.count;
      return counts;
    },
    {}
  );

  useEffect(() => {
    setIsLoading(true);
    getDatevLedger(
      companyId,
      +year!,
      +month!,
      currentPage,
      !!currentFilter ? +currentFilter : undefined
    )
      .then((data) => {
        setTransactions(data?.data || []);
        setPaging(data?.paging);
      })
      .catch(() => {
        setTransactions([]);
        setPaging(undefined);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, [currentFilter, currentPage]);

  const onPageClick = (n: number) => {
    n === 1 ? params.delete('page') : params.set('page', String(n));
    navigate(
      { pathname: location.pathname, search: params.toString() },
      { replace: true }
    );
  };

  const handleClickTab = (v?: number) => {
    if (
      (v === undefined && !currentFilter) ||
      (currentFilter && v === +currentFilter)
    ) {
      return;
    }

    v === undefined
      ? params.delete('entry_type')
      : params.set('entry_type', String(v));
    params.set('page', '1');
    navigate(
      { pathname: location.pathname, search: params.toString() },
      { replace: true }
    );
  };

  const { isModalOpened, closeModal, openModal } = useModal();

  function onClick() {
    if (vs4306DatevQuiz && !canSubmitTypeform) return;
    openModal();
    sendEvent(APIEventsEnum.click_datev_fake_door, {
      block: 'Datev File Export',
    });
  }

  if (isLoadingAggregated || isLoadingFiles) return <Loader />;
  if (!transactions) return null;

  return (
    <div className='flex w-full flex-grow flex-col rounded-lg bg-gray-70 px-4 py-4 lg:px-6 lg:py-6 xl:px-14 2xl:py-8'>
      {(isLoading || isLoadingTypeformSubmissions) && <Loader />}
      <div className='mx-auto flex w-full flex-grow flex-col overflow-hidden rounded-lg bg-white px-6 pt-2'>
        <div className='flex w-full justify-between'>
          <Link
            className='group mb-2 flex w-max items-center text-sm font-semibold transition-colors'
            to={{
              pathname: AuthedRoutesEnum.Datev,
            }}
          >
            <BackIcon className='[&>g>path]:transition-[fill] group-hover:[&>g>path]:fill-green' />
            <span>{t('Back')}</span>
          </Link>
          <Button
            onClick={onClick}
            disabled={vs4306DatevQuiz && !canSubmitTypeform}
          >
            <DownloadIcon />
            <span className='ml-2'>{t('Export DATEV')}</span>
          </Button>
        </div>
        <div className='pb-4 text-h3 font-black'>
          {i18n.language === 'en'
            ? `${dateString} ${t('transactions')}`
            : `${t('transactions')} ${dateString}`}
        </div>
        <div className='flex flex-grow flex-col'>
          <div className='flex flex-wrap items-center gap-2 pb-4'>
            <Button
              size='sm'
              styling={!currentFilter ? undefined : 'outline'}
              onClick={() => handleClickTab()}
            >
              <span className='pr-1'>{t('Alle')}</span>
              <span>{totalSum}</span>
            </Button>
            {Object.entries(FiltersLabels).map(
              ([key, value]) =>
                !!entryTypeCounts[key] && (
                  <Button
                    key={key}
                    size='sm'
                    styling={
                      currentFilter && +currentFilter === +key
                        ? undefined
                        : 'outline'
                    }
                    onClick={() => handleClickTab(+key)}
                  >
                    <span className='pr-1'>{t(value)}</span>
                    <span>{entryTypeCounts[key]}</span>
                  </Button>
                )
            )}
          </div>
          {isExplanationShown && (
            <div className='mb-4 flex items-start font-semibold'>
              <InfoIcon className='mr-1 [&>path]:fill-dark' />
              {t(
                "This specific report isn't requested by you from countX, but you can overview contained transactions"
              )}
            </div>
          )}
          <div
            className='MiniScrollbar relative mx-auto w-full flex-grow overflow-auto'
            ref={ref}
          >
            <Table<DatevTransaction>
              className='absolute left-0 top-0 mx-auto h-full w-fit'
              columns={[
                {
                  label: t('STEUERSCHL'),
                  field: 'tax_code',
                  sortable: true,
                  className: '!pr-1',
                  renderer: (row) => {
                    return (
                      <div className='flex h-full items-center justify-end'>
                        {row.tax_code}
                      </div>
                    );
                  },
                },
                {
                  label: t('GEGENKONTO'),
                  field: 'counter_account',
                  sortable: true,
                  className: '!pr-1',
                  renderer: (row) => (
                    <div className='flex h-full items-center justify-end'>
                      {row.counter_account}
                    </div>
                  ),
                },
                {
                  label: `${t('BELEG')} 1`,
                  field: 'invoice_nr1',
                  sortable: true,
                  className: 'justify-start',
                  renderer: (row) => {
                    return (
                      <div className='flex h-full items-center'>
                        {row.invoice_nr1}
                      </div>
                    );
                  },
                },
                {
                  label: `${t('BELEG')} 2`,
                  field: 'invoice_nr2',
                  sortable: true,
                  renderer: (row) => {
                    return (
                      <div className='flex h-full items-center'>
                        {row.invoice_nr2}
                      </div>
                    );
                  },
                },
                {
                  label: t('DATUM'),
                  field: 'date',
                  renderer: (row) => {
                    return (
                      <div className='flex h-full items-center'>
                        {formatDate(normalizeDateFE(row.date), 'dd.MM.yyyy')}
                      </div>
                    );
                  },
                },
                {
                  label: t('KONTO'),
                  field: 'account',
                  sortable: true,
                  className: '!pr-1',
                  renderer: (row) => (
                    <div className='flex h-full items-center justify-end truncate font-semibold'>
                      {row.account}
                    </div>
                  ),
                },
                {
                  label: `${t('UMSATZ IN')} EURO`,
                  field: 'revenue_in_euro',
                  sortable: true,
                  className: '!pr-1',
                  renderer: (row) => {
                    return (
                      <div className='flex h-full items-center justify-end'>
                        {row.revenue_in_euro &&
                          formatNumber(row.revenue_in_euro, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </div>
                    );
                  },
                },
                {
                  label: `${t('KOST')} 1`,
                  field: 'cost1',
                  sortable: true,
                  renderer: (row) => (
                    <div className='flex h-full items-center'>{row.cost1}</div>
                  ),
                },
                {
                  label: `${t('KOST')} 2`,
                  field: 'cost2',
                  sortable: true,
                  renderer: (row) => (
                    <div className='flex h-full items-center'>{row.cost2}</div>
                  ),
                },
                {
                  label: `${t('SKONTO IN')} DM`,
                  field: 'skonto_in_dm',
                  sortable: true,
                  className: '!pr-1',
                  renderer: (row) => {
                    return (
                      <div className='flex h-full items-center justify-end'>
                        {row.skonto_in_dm &&
                          formatNumber(row.skonto_in_dm, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </div>
                    );
                  },
                },
                {
                  label: t('BUCHUNGSTEXT'),
                  field: 'booking_text',
                  renderer: (row) => (
                    <div className='flex h-full items-center'>
                      {row.booking_text}
                    </div>
                  ),
                },
                {
                  label: t('UMSATZSTEUER-ID'),
                  field: 'vat_id',
                  sortable: true,
                  renderer: (row) => {
                    return (
                      <div className='flex h-full items-center'>
                        {row.vat_id}
                      </div>
                    );
                  },
                },
                {
                  label: t('PROZ'),
                  field: 'percent',
                  sortable: true,
                  className: '!pr-1',
                  renderer: (row) => {
                    return (
                      <div className='flex h-full items-center justify-end'>
                        {row.percent}
                      </div>
                    );
                  },
                },
                {
                  label: `${t('UMSATZ IN')} DM`,
                  field: 'revenue_in_dm',
                  sortable: true,
                  className: '!pr-1',
                  renderer: (row) => {
                    return (
                      <div className='flex h-full items-center justify-end'>
                        {row.revenue_in_dm &&
                          formatNumber(row.revenue_in_dm, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </div>
                    );
                  },
                },
                {
                  label: `${t('SKONTO IN')} EURO`,
                  field: 'skonto_in_euro',
                  sortable: true,
                  className: '!pr-1',
                  renderer: (row) => {
                    return (
                      <div className='flex h-full items-center justify-end'>
                        {row.skonto_in_euro &&
                          formatNumber(row.skonto_in_euro, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </div>
                    );
                  },
                },
                {
                  label: t('FREMDBELEGNR'),
                  field: 'external_document_nr',
                  sortable: true,
                  renderer: (row) => {
                    return (
                      <div className='flex h-full items-center'>
                        {row.external_document_nr}
                      </div>
                    );
                  },
                },
                {
                  label: t('ZUSATZART'),
                  field: 'additional_type',
                  renderer: (row) => (
                    <div className='flex h-full w-full items-center'>
                      {row.additional_type}
                    </div>
                  ),
                },
                {
                  label: t('ZUSATZINHALT'),
                  field: 'additional_content',
                  renderer: (row) => (
                    <div className='flex h-full items-center'>
                      {row.additional_content}
                    </div>
                  ),
                },
                {
                  label: `${t('SKONTO MANUELL IN')} EURO`,
                  field: 'manual_skonto_in_euro',
                  sortable: true,
                  className: '!pr-1',
                  renderer: (row) => {
                    return (
                      <div className='flex h-full items-center justify-end'>
                        {row.manual_skonto_in_euro &&
                          formatNumber(row.manual_skonto_in_euro, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </div>
                    );
                  },
                },
                {
                  label: `${t('SKONTO MANUELL IN')} DM`,
                  field: 'manual_skonto_in_dm',
                  sortable: true,
                  className: '!pr-1',
                  renderer: (row) => {
                    return (
                      <div className='flex h-full items-center justify-end'>
                        {row.manual_skonto_in_dm &&
                          formatNumber(row.manual_skonto_in_dm, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </div>
                    );
                  },
                },
                {
                  label: `${t('SKONTO MANUELL IN')} FW`,
                  field: 'manual_skonto_in_fw',
                  sortable: true,
                  className: '!pr-1',
                  renderer: (row) => {
                    return (
                      <div className='flex h-full items-center justify-end'>
                        {row.manual_skonto_in_fw &&
                          formatNumber(row.manual_skonto_in_fw, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </div>
                    );
                  },
                },
              ]}
              data={transactions && transactions.length ? transactions : []}
            />
          </div>
          {paging && paging?.total_pages > 1 && (
            <div
              style={{
                minHeight: 48,
              }}
              className='flex items-center justify-center'
            >
              <Pagination
                current={currentPage}
                pages={paging.total_pages}
                onPageClick={onPageClick}
              />
            </div>
          )}
        </div>
      </div>
      {vs4306DatevQuiz ? (
        <DatevQuizModal isModalOpened={isModalOpened} closeModal={closeModal} />
      ) : (
        <FeatureUnderDevelopmentModal
          closeModal={closeModal}
          isModalOpened={isModalOpened}
          content={
            user?.Role === RolesEnum.SELLER
              ? {
                  heading:
                    'Combined DATEV file is under development. Speak to our team and get an early access',
                  description:
                    'We will discuss what setup is proper for you and your Tax Advisor',
                  hint: 'Up to 30 mins',
                }
              : undefined
          }
        />
      )}
    </div>
  );
}
