import classNames from 'classnames';
import { ButtonHTMLAttributes, PropsWithChildren } from 'react';

export type BtnStyling =
  | 'white'
  | 'primary'
  | 'outline'
  | 'dark'
  | 'naked'
  | 'naked-blue'
  | 'red'
  | 'gray'
  | 'naked-green';

interface Props {
  className?: string;
  styling?: BtnStyling;
  size?: 'sm' | 'lg';
}

export default function Button({
  className = '',
  styling = 'primary',
  children,
  size,
  ...rest
}: PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement> & Props>) {
  const getClassName = () => {
    switch (styling as BtnStyling) {
      case 'red':
        return 'Button--red';
      case 'naked-green':
        return 'Button--naked--green';
      case 'naked-blue':
        return 'Button--naked--blue';
      case 'naked':
        return 'Button--naked';
      case 'outline':
        return 'Button--outline';
      case 'dark':
        return 'Button--dark';
      case 'white':
        return 'Button--white';
      case 'gray':
        return 'Button--gray';
      default:
        return 'Button';
    }
  };

  return (
    <button
      className={classNames(getClassName(), className, {
        [`Button--${size}`]: !!size,
      })}
      {...rest}
    >
      {children}
    </button>
  );
}
