import classNames from 'classnames';
import Arrow from '@/icons/select-chevron.svg?react';
import { formatDate } from '@/core/helpers';
import { ProformaInvoice } from '@/core/types';
import { useTranslation } from 'react-i18next';
import { ProformaInvoiceTile } from './ProformaInvoiceTile';
import ZIP from '@/icons/file/zip-green.svg?react';
import { getTypeformSubmissions, postTypeformSubmission } from '@/core/api';
import { PopupButton } from '@typeform/embed-react';
import { userSelector } from '@/core/store/user/userSlice';
import { useAppSelector } from '@/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { ControlledCollapse } from '../Collapse/ControlledCollapse';

export const FF = 'vs-5515-proforma-fakedoor-cp';

interface Props {
  initialOpened?: boolean;
  date: Date;
  invoices: { [key: string]: ProformaInvoice[] };
  canSubmitTypeform?: boolean;
}

export const ProformaInvoicesCollapse = ({
  initialOpened,
  date,
  invoices,
  canSubmitTypeform,
}: Props) => {
  const { t, i18n } = useTranslation();
  const user = useAppSelector(userSelector);
  const queryClient = useQueryClient();

  return (
    <ControlledCollapse
      initialOpened={initialOpened}
      triggerRenderer={(isOpened, toggleCollapse) => (
        <div
          className={`flex cursor-pointer items-start ${
            isOpened ? 'rounded-t-lg ' : 'rounded-lg'
          } border py-2 pl-4 pr-2`}
          onClick={toggleCollapse}
        >
          <div className='SellerPastPeriodsFiling__top flex-shrink-0 font-extrabold'>
            {formatDate(date, 'MMMM yyyy')}
          </div>
          <div className='flex flex-grow items-start justify-end'>
            {canSubmitTypeform && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <PopupButton
                  id={i18n.language === 'en' ? 'tnZQAVZj' : 'r8v7b8Y6'}
                  size={80}
                  hidden={{
                    email: user?.Email || '',
                    user_id: String(user?.Id) || '',
                  }}
                  className='group mr-2 flex items-center gap-x-2 font-semibold text-green transition-colors hover:text-dark'
                  onSubmit={async (event) => {
                    document.body.style.overflow = '';
                    postTypeformSubmission({
                      response_id: event.responseId,
                      form_id: event.formId,
                      feature_flag: FF,
                    })
                      .then(() => {
                        return getTypeformSubmissions(user?.Id);
                      })
                      .then((res) => {
                        queryClient.setQueryData(
                          ['UserTypeformSubmissions', user?.Id],
                          res || []
                        );
                      })
                      .catch(console.error);
                  }}
                >
                  <ZIP className='group-hover:[&>g>path:nth-child(1)]:fill-dark [&>g>path]:transition-colors' />
                  <span>{t('Download as ZIP')}</span>
                </PopupButton>
              </div>
            )}
            <div className='flex'>
              {Object.values(invoices).length}
              &nbsp;
              <span>{t('files')}</span>
            </div>
            <Arrow
              className={classNames('SellerPastPeriodsFiling__arrow', {
                'SellerPastPeriodsFiling__arrow--active': isOpened,
              })}
            />
          </div>
        </div>
      )}
      contentRenderer={() => (
        <div className='divide-y rounded-b-lg border-b border-l border-r'>
          {Object.entries(invoices)
            .sort(([key1], [key2]) => key1.localeCompare(key2))
            .map(([k, v]) => {
              return <ProformaInvoiceTile invoices={v} key={k} />;
            })}
        </div>
      )}
    />
  );
};
