import { AuthedRoutesEnum } from '@/core/enums';
import { formatDate } from '@/core/helpers';
import { normalizeDateFE } from '@/core/normalization';
import {
  CurrentReporterEnum,
  NormalizedSellerFilingReport,
} from '@/core/types';
import {
  differenceInCalendarMonths,
  isSameMonth,
  startOfQuarter,
} from 'date-fns';

export function createInvoiceBookLink(
  periodFrom: string,
  companyId: string,
  isOss = false,
  prevRoute = AuthedRoutesEnum.OSSFiles
) {
  const searchParams = new URLSearchParams();
  const date = normalizeDateFE(periodFrom);
  searchParams.set('year', String(date.getFullYear()));
  searchParams.set('value', formatDate(date, 'Q'));
  searchParams.set('type', 'quarter');
  searchParams.set('company_id', companyId);
  searchParams.set('flow', CurrentReporterEnum.ossSelfService);
  if (isOss) {
    searchParams.set('is_oss', '1');
  }
  return {
    to: {
      pathname: AuthedRoutesEnum.InvoiceBook,
      search: searchParams.toString(),
    },
    state: { prevRoute },
  };
}

export const getCurrentOSSSeSeReport = (
  reports: NormalizedSellerFilingReport[]
) => {
  const ossReports = reports
    .filter((r) => r.type === 'oss')
    .sort(
      (a, b) =>
        new Date(b.deadline_date).getTime() -
        new Date(a.deadline_date).getTime()
    );

  const firstMonthOfQuarter = startOfQuarter(new Date());
  if (isSameMonth(new Date(), firstMonthOfQuarter)) {
    return ossReports.filter(
      (r) =>
        differenceInCalendarMonths(
          firstMonthOfQuarter,
          normalizeDateFE(r.period.from_date)
        ) > 0
    )[0];
  } else {
    return ossReports[0];
  }
};
