import { useTranslation } from 'react-i18next';
import {
  Country,
  FilingFrequencyType,
  PaymentFrequencyType,
} from '@/core/types';
import CountryLabel from '../CountryLabel/CountryLabel';
import { normalizeDateFE } from '@/core/normalization';
import { capitalize, getFilingPeriodStr } from '@/core/helpers';
import Tooltip from '../Tooltip/Tooltip';

interface Props {
  country?: Country;
  filing_period_from: string | undefined;
  filing_period_to?: string | undefined;
  vat_number: string | undefined;
  reporting_frequency: FilingFrequencyType;
  payment_frequency?: PaymentFrequencyType;
}

const FREQUENCY_LABELS = {
  monthly: 'Mo.',
  quarterly: 'Qtr.',
  yearly: 'Year.',
};

export default function SellerReportingCountriesItem({
  country,
  filing_period_from,
  filing_period_to,
  vat_number,
  reporting_frequency,
  payment_frequency,
}: Props) {
  const { t } = useTranslation();
  const normalizedFilingPeriodFrom = filing_period_from
    ? normalizeDateFE(filing_period_from)
    : undefined;
  const normalizedFilingPeriodTo = filing_period_to
    ? normalizeDateFE(filing_period_to)
    : undefined;
  return (
    <div className='flex w-full flex-col'>
      <div className='flex items-center justify-start gap-x-2'>
        <CountryLabel
          country={country?.short_name || 'EU'}
          short
          className='font-semibold'
        />
        <span className='text-gray-700'>{vat_number}</span>
      </div>
      <div className='flex w-full justify-between'>
        <Tooltip content={<div className='text-sm'>{t('Filing period')}</div>}>
          <div>
            {!normalizedFilingPeriodTo && t('Since')}{' '}
            {getFilingPeriodStr({
              normalizedFilingPeriodFrom,
              normalizedFilingPeriodTo,
              reporting_frequency,
            })}
          </div>
        </Tooltip>
        <Tooltip
          content={
            <div className='text-sm'>
              {t(`${capitalize(reporting_frequency)} filing frequency`)}
            </div>
          }
        >
          <div className='max-w-[180px] whitespace-pre-line text-end'>
            {payment_frequency && payment_frequency !== reporting_frequency
              ? t('payment *payment_frequency* / filing *reporting_frequency*')
                  .replace(
                    '*payment_frequency*',
                    t(FREQUENCY_LABELS[payment_frequency])
                  )
                  .replace(
                    '*reporting_frequency*',
                    t(FREQUENCY_LABELS[reporting_frequency])
                  )
              : t(FREQUENCY_LABELS[reporting_frequency])}
          </div>
        </Tooltip>
      </div>
    </div>
  );
}
