export const TEL = '+49 30 6293155 0';
export const EMAIL = 'vat@countx.com';
export const EMAIL_SALES = 'sales@countx.com';
export const IMPRESSUM_LINK_EN = 'https://www.countx.com/en/imprint';
export const IMPRESSUM_LINK_DE = 'https://www.countx.com/impressum';
export const PRIVACY_POLICY_LINK_EN = 'https://www.countx.com/en/privacy';
export const PRIVACY_POLICY_LINK_DE = 'https://www.countx.com/datenschutz';
export const FACEBOOK_LINK = 'https://www.facebook.com/CountX-102944645356304';
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/countx';
export const IsProduction = import.meta.env.VITE_APP_ENV === 'prod';
export const ACCESS_TOKEN_KEY = `access_token_${
  import.meta.env.VITE_APP_ENV || 'local'
}` as const;
export const REFRESH_TOKEN_KEY = `refresh_token_${
  import.meta.env.VITE_APP_ENV || 'local'
}` as const;

export const ID_TOKEN_KEY = `id_token_${
  import.meta.env.MODE === 'development'
    ? 'local'
    : import.meta.env.VITE_APP_ENV || 'local'
}` as const;

export const CALENDLY_EVENTS = {
  event_scheduled: 'calendly.event_scheduled',
};

export const COOKIE = {
  language: 'language',
  visitor_uuid: 'visitor_uuid',
  CookieConsent: 'CookieConsent',
  cookiePolicyConsentTimestamp: 'cookiePolicyConsentTimestamp',
};

export const GTMEvents = {
  calendly_booked_datev_page: 'calendly_booked_datev_page',
  calendly_booked_register_page: 'calendly_booked_register_page',
  calendly_booked_setup_check_page: 'calendly_booked_setup_check_page',
  click_calendly_datev_page: 'click_calendly_datev_page',
  click_beratung_register_page: 'click_beratung_register_page',
  click_beratung_setup_check_page: 'click_beratung_setup_check_page',
  setup_check_register_success: 'setup_check_register_success',
  setup_check_verification_success: 'setup_check_verification_success',
  setup_check_click_about_oss: 'setup_check_click_about_oss',
  setup_check_click_invoice_adjustments:
    'setup_check_click_invoice_adjustments',
  setup_check_click_penalties_link: 'setup_check_click_penalties_link',
  setup_check_click_warehouses_learn_more:
    'setup_check_click_warehouses_learn_more',
  setup_check_click_fc_transfers_learn_more:
    'setup_check_click_fc_transfers_learn_more',
  setup_check_click_retroactive_filing: 'setup_check_click_retroactive_filing',
  setup_check_fc_transfers_transactions_click:
    'setup_check_fc_transfers_transactions_click',
  setup_check_fc_transfers_learn_more_click:
    'setup_check_fc_transfers_learn_more_click',
  setup_check_warehouses_learn_more_click:
    'setup_check_warehouses_learn_more_click',
};
export const API_URL = import.meta.env.VITE_APP_API_URL;
export const GTM_CONTAINER_ID = 'GTM-TJCG9W9';
export const HOTJAR_ID = '3266516';

export const GTM_SCRIPT_INNER_HTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_CONTAINER_ID}');`;

export const HOTJAR_SCRIPT_INNER_HTML = `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:${HOTJAR_ID},hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

export const HUBSPOT_SCRIPT_SRC = '//js-eu1.hs-scripts.com/25241350.js';

export enum PagesEnum {
  international_sales = 'international-sales',
  amazon_fba = 'amazon-fba',
  amazon_fba_pan_eu = 'amazon-fba-pan-eu',
  deliver_to_gb = 'deliver-to-gb',
  warehouse_in_eu = 'warehouse-in-eu',
  free_setup_check = 'free-setup-check',
  vat_id_registration = 'vat-id-registration',
  vat_reports = 'vat-reports',
  personal_advice = 'personal-advice',
  customer_portal = 'customer-portal',
  oss = 'oss',
  ioss = 'ioss',
  integrations = 'integrations',
  blog = 'blog',
  about_us = 'about-us',
  impressum = 'impressum',
  eng = 'eng',
  index = 'index',
  privacy = 'privacy',
  price_calculator = 'price-calculator',
  post = 'post',
  contact = 'contact',
  for_tax_advisors = 'for-tax-advisors',
  partners = 'partners',
  become_tester_service = 'lp-become-tester-service',
  become_tester_interview = 'lp-become-tester-interview',
  setup_check = 'setup-check',
  get_quote = 'get-quote',
}

export const routesMapEN = {
  [PagesEnum.index]: '/eng',
  [PagesEnum.international_sales]: '/en/packages/international-sales',
  [PagesEnum.amazon_fba]: '/en/packages/amazon-fba',
  [PagesEnum.amazon_fba_pan_eu]: '/en/packages/amazon-fba-pan-eu',
  [PagesEnum.deliver_to_gb]: '/en/packages/deliver-to-gb',
  [PagesEnum.warehouse_in_eu]: '/en/packages/warehouse-in-eu',
  [PagesEnum.free_setup_check]: '/en/services/free-setup-check',
  [PagesEnum.vat_id_registration]: '/en/services/vat-id-registration',
  [PagesEnum.vat_reports]: '/en/services/vat-reports',
  [PagesEnum.personal_advice]: '/en/services/personal-advice',
  [PagesEnum.customer_portal]: '/en/services/customer-portal',
  [PagesEnum.oss]: '/en/services/one-stop-shop',
  [PagesEnum.ioss]: '/en/services/import-one-stop-shop',
  [PagesEnum.integrations]: '/en/integrations',
  [PagesEnum.about_us]: '/en/about-us',
  [PagesEnum.impressum]: '/en/imprint',
  [PagesEnum.privacy]: '/en/privacy',
  [PagesEnum.price_calculator]: '/en/price-calculator',
  [PagesEnum.contact]: '/en/contact',
  [PagesEnum.for_tax_advisors]: '/en/for-tax-advisors',
  [PagesEnum.partners]: '/en/partners',
  [PagesEnum.get_quote]: '/en/get-quote',
  [PagesEnum.become_tester_service]: '/en/lp/become-tester-service',
  [PagesEnum.become_tester_interview]: '/en/lp/become-tester-interview',
  [PagesEnum.blog]: '/blog',
};

export const routesMapDE = {
  [PagesEnum.index]: '/',
  [PagesEnum.international_sales]: '/usecase/internationale-eu-verkaufe',
  [PagesEnum.amazon_fba]: '/usecase/amazon-fba-mitteleuropa',
  [PagesEnum.amazon_fba_pan_eu]: '/usecase/amazon-fba-pan-eu',
  [PagesEnum.deliver_to_gb]: '/usecase/lieferung-nach-grossbritannien',
  [PagesEnum.warehouse_in_eu]: '/usecase/warenlager-in-der-eu',
  [PagesEnum.free_setup_check]: '/service/kostenloser-setup-check',
  [PagesEnum.vat_id_registration]: '/service/umsatzsteuer-id-registrierung',
  [PagesEnum.vat_reports]: '/service/umsatzsteuer-meldungen',
  [PagesEnum.personal_advice]: '/service/personliche-beratung',
  [PagesEnum.customer_portal]: '/service/customer-portal',
  [PagesEnum.oss]: '/service/one-stop-shop',
  [PagesEnum.ioss]: '/service/import-one-stop-shop',
  [PagesEnum.integrations]: '/schnittstellen',
  [PagesEnum.blog]: '/blog',
  [PagesEnum.post]: '/post/:slug',
  [PagesEnum.about_us]: '/uber-uns',
  [PagesEnum.impressum]: '/impressum',
  [PagesEnum.privacy]: '/datenschutz',
  [PagesEnum.price_calculator]: '/price-calculator',
  [PagesEnum.contact]: '/kontakt',
  [PagesEnum.for_tax_advisors]: '/steuerberater',
  [PagesEnum.partners]: '/partner',
  [PagesEnum.get_quote]: '/get-quote',
  [PagesEnum.become_tester_service]: '/lp/become-tester-service',
  [PagesEnum.become_tester_interview]: '/lp/become-tester-interview',
};

export const INITIAL_LD_KEY = 'websiteUserKey';

export const CURRENCY_SYMBOLS: { [key: string]: string } = {
  CZK: 'Kč',
  PLN: 'zł',
  EUR: '€',
  SEK: 'kr',
  GBP: '£',
};
