import { DependencyList, useEffect } from 'react';
import { WS_MESSAGE_EVENT_KEY, WsMessage, isWsMessage } from './ws-messages';

export const useWSMessageHandler = (
  handler: (msg: WsMessage) => void,
  deps: DependencyList
) => {
  useEffect(() => {
    const listener = (e: CustomEvent) => {
      if (!e.detail || !isWsMessage(e.detail)) return;
      handler(e.detail);
    };
    document.addEventListener(WS_MESSAGE_EVENT_KEY as any, listener);
    return () => {
      document.removeEventListener(WS_MESSAGE_EVENT_KEY as any, listener);
    };
    // eslint-disable-next-line
  }, deps);
};
