import Header from '@/components/Header/Header';
import { useAppSelector } from '../../hooks';
import {
  activeCompanySelector,
  userSelector,
} from '@/core/store/user/userSlice';
import { UserFlagEnum } from '@/core/types';
import Button from '@/components/Button';
import { useTranslation } from 'react-i18next';
import { useMedia } from '../../hooks';
import classNames from 'classnames';
import { useBookMeetingModal } from '@/components/BookMeetingModal/useBookMeetingModal';

export default function OSSSelfService() {
  const { openModal } = useBookMeetingModal();
  const { t } = useTranslation();
  const isMobile = useMedia(['(max-height: 844px)'], [true], false);
  const user = useAppSelector(userSelector);
  const activeCompany = useAppSelector(activeCompanySelector);

  return (
    <div className='flex'>
      <div className='flex-grow'>
        <div className='flex flex-col'>
          <div
            className={classNames('flex min-h-screen flex-col bg-gray-300', {
              '!bg-white': user?.Flag === UserFlagEnum.oss_self_service,
            })}
          >
            <Header />
            <div
              className={classNames(
                'flex flex-grow flex-col items-center px-4'
              )}
            >
              <div
                className={classNames(
                  'mb-2 text-center text-h4 font-extrabold xl:text-h3',
                  {
                    'pt-40': !isMobile,
                    'pt-14': isMobile,
                  }
                )}
              >
                {t('Please book the onboarding call')}
              </div>
              <div className='mb-4 max-w-[600px] text-center xl:text-h4'>
                {t(
                  "You'll embark on the onboarding journey with your personal manager, who will assist you in setting up and provide guidance on submitting OSS reports."
                )}
              </div>
              <Button
                size='lg'
                className='xl:!py[15px] xl:!px-[23px] xl:!text-h4'
                onClick={() =>
                  openModal(
                    activeCompany?.Clerk?.HubspotMeetingLink
                      ? activeCompany.Clerk.HubspotMeetingLink + '?embed=true'
                      : undefined
                  )
                }
              >
                {t('Book now')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
