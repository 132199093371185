import { createSlice } from '@reduxjs/toolkit';

interface UserState {
  isRegistered: boolean;
}

const initialState: UserState = {
  isRegistered: false,
};

export const ossSlice = createSlice({
  name: 'oss',
  initialState,
  reducers: {
    register: (state) => {
      state.isRegistered = true;
    },
  },
});

export const { register } = ossSlice.actions;
export default ossSlice.reducer;
