import { createSlice } from '@reduxjs/toolkit';
import { RolesEnum } from '../../enums';
import { CurrentReporterEnum, UserCompany, UserFlagEnum } from '../../types';
import { RootState } from '..';

export interface User {
  Id: string;
  Email: string;
  Name: string;
  FamilyName: string;
  CompanyName: string;
  AmazonCognitoId: string;
  Role: RolesEnum;
  Language: string;
  Country?: string;
  EmailVerifiedAt: string;
  NewsletterAgreement: boolean;
  LicenseAgreement: {
    DataProtectionAccepted: boolean;
    DataProtectionAcceptedAt: string;
    DataProtectionVersion: string;
    IpAddress: string;
    ToSAccepted: true;
    ToSAcceptedAt: string;
    ToSVersion: string;
    UserAgent: string;
  };
  TestingOptIn?: boolean | null;
  CompanyUuid: string;
  ManualUploadNeeded: boolean;
  IsAmazonSeller: boolean | null;
  DATEVExportNeeded: boolean;
  Flag?: UserFlagEnum;
  ErpName: string;
  LastNpsSubmission?: string;
  CreatedAt: string;
  CanSubmitNpsDate?: string;
}

interface UserState {
  user: undefined | User;
  companies: UserCompany[];
  activeCompany: UserCompany | undefined;
}

const initialState: UserState = {
  user: undefined,
  companies: [],
  activeCompany: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (
      state,
      action: {
        payload: undefined | User;
      }
    ) => {
      state.user = action.payload;
    },
    setTestingOptIn: (state) => {
      if (state.user) {
        state.user.TestingOptIn = true;
      }
    },
    clearUserState: (state) => {
      state.user = undefined;
      state.companies = [];
      state.activeCompany = undefined;
    },
    setUserCompanies: (
      state,
      action: {
        payload: UserCompany[];
      }
    ) => {
      state.companies = action.payload;
    },
    setActiveCompany: (
      state,
      action: {
        payload: UserCompany | undefined;
      }
    ) => {
      state.activeCompany = action.payload;
    },
  },
});

export const userSelector = (s: RootState) => s.user.user;
export const activeCompanySelector = (s: RootState) => s.user.activeCompany;
export const companiesSelector = (s: RootState) => s.user.companies;
export const isOSSSelfServiceSelector = (s: RootState) => {
  const isOSSSese =
    s.user.activeCompany?.ClientOSSDetails?.CurrentReporter &&
    s.user.activeCompany.ClientOSSDetails.CurrentReporter ===
      CurrentReporterEnum.ossSelfService;

  if (s.user.user?.Role === RolesEnum.TAX_ADVISOR) {
    return (
      isOSSSese &&
      s.user.activeCompany?.ClientOSSDetails?.SubmissionBy ===
        RolesEnum.TAX_ADVISOR
    );
  }

  return isOSSSese;
};

export const queryKeySelector = (s: RootState) => {
  return [s.user.user?.Id, s.user.activeCompany?.company_id]
    .filter((v) => !!v)
    .join('_');
};

export const {
  setUser,
  setTestingOptIn,
  clearUserState,
  setActiveCompany,
  setUserCompanies,
} = userSlice.actions;
export default userSlice.reducer;
