import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, formatNumber } from '@/core/helpers';
import { Link } from 'react-router-dom';
import { Badge } from '../Badge/Badge';
import FolderIcon from '@/icons/folder-grey-xl.svg?react';
import XLSXIcon from '@/icons/file/xlsx.svg?react';
import EU from '@/icons/countries/EU.svg?react';
import LinkIcon from '@/icons/link.svg?react';
import {
  SellerFilingReportFile,
  NormalizedSellerFilingReport,
} from '@/core/types';
import { normalizeDateFE } from '@/core/normalization';
import { lastDayOfMonth, subDays } from 'date-fns';
import {
  isReportPaid,
  isReportPrepared,
  isReportSubmitted,
} from '../OpenTasks/OpenTasks.helpers';
import { OSSSeSeWidgetLink } from './OSSSelfServiceWidget';
import classNames from 'classnames';
import Button from '../Button';

interface Props {
  link?: OSSSeSeWidgetLink;
  report?: NormalizedSellerFilingReport;
  onClickDownload?: ((file: SellerFilingReportFile) => void) | undefined;
}

export const OSSSeSeReportFiling = ({
  link,
  report,
  onClickDownload,
  children,
}: PropsWithChildren<Props>) => {
  const { t, i18n } = useTranslation();

  const fileReadinessDate = report?.deadline_date
    ? normalizeDateFE(report.deadline_date)
    : undefined;

  const file = report?.files.find((f) => f.option === 'oss-csv-bop');

  const deadlineDate = report?.deadline_date
    ? normalizeDateFE(report.deadline_date)
    : undefined;
  if (deadlineDate) {
    deadlineDate.setDate(subDays(lastDayOfMonth(deadlineDate), 2).getDate());
  }

  const isOSSReportReady = report ? isReportPrepared(report) : false;
  const isPaid = report ? isReportPaid(report) : false;
  const isSubmitted = report ? isReportSubmitted(report) : false;

  return (
    <div
      className={`space-y-2 !px-4 ${
        isOSSReportReady || isSubmitted ? 'pb-4' : ''
      }`}
    >
      <div className='flex justify-between '>
        <div>
          <div className='mb-2 font-semibold'>
            <Badge
              color={`${isOSSReportReady || isSubmitted ? 'green' : 'gray'}`}
              label={t(
                `${
                  isOSSReportReady
                    ? 'Ready'
                    : isSubmitted || isPaid
                      ? 'Submitted'
                      : 'To be generated'
                }`
              )}
            />
            &nbsp;
            {isPaid && <Badge color='green' label={t('Paid')} />}
            {deadlineDate && !isPaid && (
              <Badge
                color={`${isOSSReportReady || isSubmitted ? 'red' : 'gray'}`}
                label={t('Payment before *date*').replace(
                  '*date*',
                  formatDate(deadlineDate, 'do MMM')
                )}
              />
            )}
          </div>
          <div className='mb-2 flex items-center gap-x-1 text-h3 font-black'>
            <EU />
            <span>
              {report
                ? t('OSS report for *period*').replace(
                    '*period*',
                    String(report?.period.label)
                  )
                : t('OSS report')}
            </span>
          </div>
          <div className='max-w-[450px] font-semibold'>
            {isOSSReportReady || isSubmitted ? (
              <>
                {report?.payment_instruction && (
                  <div>
                    {t('*amount* VAT declared.').replace(
                      '*amount*',
                      formatNumber(
                        report.payment_instruction.amount.amount / 100,
                        {
                          currency: report.payment_instruction.amount.currency,
                        }
                      )
                    )}
                  </div>
                )}
                <span>
                  {t('More details are available in the Invoice book')}
                </span>
              </>
            ) : (
              <>
                <span>
                  {t(
                    'OSS report will be generated once all the data is received and processed by countX.'
                  )}
                </span>{' '}
                {fileReadinessDate && (
                  <span>
                    {t('You will receive the email once the report is ready')}
                  </span>
                )}
              </>
            )}
          </div>
          {link && (
            <Link
              className={classNames(
                'Button--outline Button--lg mt-2 flex max-w-[190px] items-center justify-center gap-x-1',
                {
                  '!max-w-[300px]': i18n.language === 'de',
                }
              )}
              to={link.to}
              state={link.state}
            >
              {link.icon || <LinkIcon />}
              <span>{t(link.label || 'Open Invoice Book')}</span>
            </Link>
          )}
        </div>
        {link && onClickDownload && file ? (
          <Button
            onClick={() => onClickDownload(file)}
            className='group flex h-[192px] !w-[200px] flex-col items-center justify-between gap-y-4 !border-2 !border-gray-300 !bg-gray-70 transition-colors hover:!border-green'
          >
            <XLSXIcon className='w-full !flex-grow' />
            <span className='text-dark group-hover:text-green'>
              {t('Download report')}
            </span>
          </Button>
        ) : (
          <div className='flex h-[192px] w-[200px] flex-col items-center justify-center rounded-lg border border-gray-300 !bg-gray-70'>
            <FolderIcon />
            <span className='max-w-[144px] text-center font-semibold text-gray-700'>
              {t('No report generated yet')}
            </span>
          </div>
        )}
      </div>

      {children}
    </div>
  );
};
