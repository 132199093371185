import classNames from 'classnames';
import { PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useDebounce } from '../../hooks';
import { lockScroll } from '@/core/helpers';

interface Props {
  isOpened: boolean;
  className?: string;
  name?: string;
}

const ModalNonClosable = ({
  isOpened,
  children,
  className = '',
  name,
}: PropsWithChildren<Props>) => {
  const debouncedIsOpened = useDebounce(isOpened, 250);
  const isShown = isOpened || debouncedIsOpened;

  useEffect(() => {
    return () => {
      lockScroll(false);
    };
  }, []);

  if (!isShown) {
    return null;
  }

  return createPortal(
    <div
      className={classNames(
        'animation-forwards Modal__box fixed bottom-0 right-0 z-30 mb-6 mr-8 flex !max-w-[704px] flex-col overflow-hidden shadow-custom',
        className
      )}
      data-testid={name ? `${name}-modal` : undefined}
    >
      <div className='Scrollbar Modal__inner'>{children}</div>
    </div>,
    document.querySelector('#modals')!
  );
};

export default ModalNonClosable;
