import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  titleClassName?: string;
  disclaimer?: {
    title: string;
    text: string;
  }[];
}

const initialDisclaimer = [
  {
    title: 'Disclaimer',
    text: 'Please ensure that the values here match those on your invoice. By changing them here on the client portal, you confirm their accuracy and compliance with your invoice.',
  },
  {
    title: 'Note',
    text: 'If the values on your invoice are incorrect, you must create a credit note and issue a new corrective invoice. The credit note and corrective invoice must be issued with the current date, not a past date.',
  },
];

export function SellerDocumentsDisclaimer({
  className = '',
  disclaimer = initialDisclaimer,
  titleClassName = '',
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={`space-y-1 rounded-lg px-3 py-2 ${className}`}>
      {disclaimer.map((item) => (
        <div>
          <span className={`font-bold ${titleClassName}`}>
            {t(item.title)}:
          </span>
          <span>{t(item.text)}</span>
        </div>
      ))}
    </div>
  );
}
