import PaymentIsntructionIcon from '@/icons/payment-instruction.svg?react';
import PaymentIsntructionPaidIcon from '@/icons/instruction-paid.svg?react';
import PaymentIsntructionConfirmedIcon from '@/icons/attachment.svg?react';
import FolderIcon from '@/icons/folder-outline.svg?react';
import AgendaIcon from '@/icons/agenda-circle.svg?react';
import ZeroAmountIcon from '@/icons/zero-icon.svg?react';
import DisregardedInstructionIcon from '@/icons/regarded-instruction-icon.svg?react';
import DisregardedZeroInstructionIcon from '@/icons/instruction-null-regarded.svg?react';
import DisregardedPaidInstructionIcon from '@/icons/instruction-confirmed-regarded.svg?react';
import DisregardedConfirmedInstructionIcon from '@/icons/instruction-confirmed-attached-regarded.svg?react';
import SubdirectoryIcon from '@/icons/subdirectory.svg?react';
import Tooltip from '../Tooltip/Tooltip';
import Button from '../Button';
import AgendaSubmittedIcon from '@/icons/agenda-submitted.svg?react';
import {
  CountryReportGroup,
  PeriodTypeReportGroup,
} from '@/pages/Seller/SellerFiling/SellerFiling.helpers';
import {
  formatDate,
  formatNumber,
  getBoundingAbsoluteRect,
} from '@/core/helpers';
import {
  FilingReportConfirmation,
  NormalizedSellerFilingReport,
} from '@/core/types';
import { t } from 'i18next';
import { normalizeDateFE } from '@/core/normalization';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  group: PeriodTypeReportGroup;
  countryGroup: CountryReportGroup;
  onFolderBtnClick: (
    confirmations: FilingReportConfirmation[],
    date: Date,
    country: string
  ) => () => void;
  onAmountBtnClick: (r: NormalizedSellerFilingReport) => () => void;
  onClickAgenda: (r: NormalizedSellerFilingReport) => () => void;
  month: Date;
}

export const SellerFilingPastPeriodsGroupOld = ({
  group,
  countryGroup,
  onFolderBtnClick,
  onAmountBtnClick,
  onClickAgenda,
  month,
}: Props) => {
  const { vs5151DisregardedInstruction } = useFlags();
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  const renderPeriod = (report: NormalizedSellerFilingReport) => {
    let formatStr = 'MMM yyyy';

    if (report.period.type === 'year') {
      return (
        <>
          <span>{t('Year')}&nbsp;</span>
          <span>
            {formatDate(normalizeDateFE(report.period.from_date), 'yyyy')}
          </span>
        </>
      );
    }

    if (
      report.country.short_name === 'IT' &&
      report.period.type !== 'quarter'
    ) {
      return (
        <>
          <span>
            {formatDate(
              normalizeDateFE(report.period.from_date),
              report.period.type === 'month' ? 'MMM yyyy' : formatStr
            )}
          </span>
          &nbsp;
          <span>{t('payment')}</span>
        </>
      );
    }

    if (report.period.type === 'quarter') {
      formatStr = 'QQQ yyyy';
    }

    return formatDate(normalizeDateFE(report.period.from_date), formatStr);
  };

  const reports = group.reports;
  const confirmations = useMemo(
    () =>
      reports
        .map((v) => v.confirmation)
        .filter((v) => !!v)
        .filter((v, i, self) => self.findIndex((s) => s.id === v.id) === i),
    [reports]
  );

  useEffect(() => {
    if (
      location.hash?.includes('confirmation') &&
      confirmations.some(
        (c) => c.id === +location.hash.slice(1).split('-')[1]
      ) &&
      ref.current
    ) {
      onFolderBtnClick(
        confirmations,
        month,
        countryGroup.type === 'oss' ? 'OSS' : countryGroup.country
      )();
      const rect = getBoundingAbsoluteRect(ref.current);
      window.scrollTo(0, rect.top - 70);

      navigate(
        { pathname: location.pathname, hash: '', search: location.search },
        {
          replace: true,
          state: location.state,
        }
      );
    }
    // eslint-disable-next-line
  }, [confirmations]);

  return (
    <div
      key={group.periodType}
      className='grid grid-cols-[minmax(140px,1.4fr),minmax(240px,2.4fr),minmax(80px,0.8fr)]'
      ref={ref}
    >
      <div>{renderPeriod(group.reports[0])}</div>
      <div className='flex flex-col gap-y-2'>
        {group.reports.map((report, index) => {
          const isZeroAmount = report.payment_instruction
            ? report.payment_instruction.is_null_report ||
              report.payment_instruction.amount.amount <= 0
            : false;

          const isPaymentInstructionDisregarded =
            report.payment_instruction?.cancelled &&
            vs5151DisregardedInstruction;

          return (
            <div className={`${index === 1 ? 'mt-1' : ''}`}>
              <div className='flex gap-x-2'>
                {!!index && <SubdirectoryIcon />}
                {report.payment_instruction ? (
                  <Tooltip
                    delay={500}
                    content={
                      <>
                        {!!report.payment_instruction?.payment_confirmation &&
                        !!report.payment_instruction?.payment_confirmation
                          .documents.length
                          ? t('Payment confirmed')
                          : isZeroAmount
                            ? t('No payment')
                            : report.payment_instruction?.confirmed ||
                                report.payment_instruction?.is_sepa
                              ? t('Marked as paid')
                              : t('Not marked as paid')}
                      </>
                    }
                  >
                    <Button
                      styling='naked'
                      className='SellerPastPeriodsFiling__btn group flex items-center'
                      onClick={onAmountBtnClick(report)}
                    >
                      {!!report.payment_instruction?.payment_confirmation &&
                      !!report.payment_instruction.payment_confirmation
                        .documents.length ? (
                        isPaymentInstructionDisregarded ? (
                          <DisregardedConfirmedInstructionIcon className='mr-2 group-hover:[&>*:nth-child(2)]:fill-white' />
                        ) : (
                          <PaymentIsntructionConfirmedIcon className='SellerPastPeriodsFiling__icon mr-2 group-hover:[&>path:last-child]:fill-white [&>path:not(:last-child)]:fill-green' />
                        )
                      ) : isZeroAmount ? (
                        isPaymentInstructionDisregarded ? (
                          <DisregardedZeroInstructionIcon className='mr-2 group-hover:[&>path:nth-child(2)]:fill-white ' />
                        ) : (
                          <ZeroAmountIcon className='SellerPastPeriodsFiling__icon mr-2 group-hover:[&>path:last-child]:fill-white [&>path:not(:last-child)]:fill-green' />
                        )
                      ) : report.payment_instruction?.confirmed ||
                        report.payment_instruction?.is_sepa ? (
                        isPaymentInstructionDisregarded ? (
                          <DisregardedPaidInstructionIcon className='SellerPastPeriodsFiling__icon mr-2' />
                        ) : (
                          <PaymentIsntructionPaidIcon className='SellerPastPeriodsFiling__icon mr-2 [&>path]:fill-green' />
                        )
                      ) : isPaymentInstructionDisregarded ? (
                        <DisregardedInstructionIcon className='mr-2 group-hover:[&>*:nth-child(3)]:fill-green' />
                      ) : (
                        <PaymentIsntructionIcon className='SellerPastPeriodsFiling__icon mr-2' />
                      )}
                      {formatNumber(
                        report.payment_instruction.amount.amount / 100,
                        {
                          currency: report.payment_instruction.amount.currency,
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                        undefined,
                        true
                      )}{' '}
                      {isPaymentInstructionDisregarded
                        ? t('Disregarded').toLowerCase()
                        : report.payment_instruction.confirmed ||
                            report.payment_instruction.is_sepa
                          ? t('Completed').toLowerCase()
                          : ''}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    styling='naked'
                    className='SellerPastPeriodsFiling__btn flex items-center'
                    disabled={!report.payment_instruction}
                  >
                    <PaymentIsntructionIcon className='SellerPastPeriodsFiling__icon mr-2' />
                    <span>{t('No instruction')}</span>
                  </Button>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className='flex items-center justify-between'>
        <Tooltip
          delay={500}
          content={
            confirmations.length > 0 ? t('Open') : t('No files for this period')
          }
        >
          <Button
            styling='naked'
            className='SellerPastPeriodsFiling__btn flex items-center'
            onClick={
              confirmations.length > 0
                ? onFolderBtnClick(
                    confirmations,
                    month,
                    countryGroup.type === 'oss' ? 'OSS' : countryGroup.country
                  )
                : undefined
            }
            disabled={!confirmations.length}
          >
            <FolderIcon className='SellerPastPeriodsFiling__icon mr-1' />
          </Button>
        </Tooltip>
        {group.reports[0].country.short_name === 'DE' &&
          group.reports[0].type === 'local' &&
          !!group.reports[0].payment_instruction && (
            <div className='ml-4'>
              <Button
                styling='naked'
                className='Modal__btn !h-6 !w-6'
                onClick={onClickAgenda(group.reports[0])}
              >
                {group.reports[0].agenda_confirmed ? (
                  <AgendaSubmittedIcon />
                ) : (
                  <AgendaIcon />
                )}
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};
