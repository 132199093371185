import { FormikErrors, useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '@/components/Input/Input';
import {
  getAmazonConnectCredentials,
  getUser,
  getUserCompanies,
} from '@/core/api';
import { confirmSignUp, login } from '@/core/auth';
import { isCodeValid, setTokensToLocalStorage } from '@/core/helpers';
import { useAuth } from '../../hooks';
import { Loader } from '@/components/Loader/Loader';
import Button from '@/components/Button';
import { useNavigate } from 'react-router-dom';

interface FormValues {
  code: string;
}

interface Props {
  email: string;
  password: string;
}

export default function RegisterUserVerificationForm({
  email,
  password,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { authUser } = useAuth();

  const formik = useFormik<FormValues>({
    validateOnBlur: true,
    initialValues: {
      code: '',
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.code) {
        errors.code = 'Required';
      }
      if (values.code !== '' && !isCodeValid(values.code)) {
        errors.code = 'Verification code is not valid';
      }

      return errors;
    },
    onSubmit: ({ code }) => {
      setIsLoading(true);
      if (error) setError('');
      confirmSignUp({
        email,
        confirmationCode: code,
      })
        .then(() => login({ email, password }))
        .then((res) => {
          setTokensToLocalStorage(
            res.AuthenticationResult!.AccessToken!,
            res.AuthenticationResult!.RefreshToken!,
            res.AuthenticationResult!.IdToken!
          );

          return Promise.all([
            getUser(),
            getAmazonConnectCredentials(),
            getUserCompanies(),
          ]);
        })
        .then((responses) => {
          authUser(responses);
          setIsLoading(false);
          navigate('/');
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.name === 'CodeMismatchException') {
            setError('Invalid verification code provided, please try again.');
          } else {
            setError('Something went wrong, please try again');
          }
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {isLoading && <Loader />}
      <div className='Text text-center'>
        <div className='Title mb-2 font-black'>{t('Verify your email')}</div>
        <div
          data-testid='code-text'
          dangerouslySetInnerHTML={{
            __html: t('Please enter the 6 digits we sent you to').replace(
              '*email*',
              `<span className='whitespace-nowrap'>${email}</span>`
            ),
          }}
        />
      </div>
      <div className='RegisterUser__codeBlock mx-auto mt-4 flex flex-col items-center justify-center lg:mt-6'>
        <Input
          name='code'
          id='code'
          type='text'
          wrapperClassName='RegisterUser__codeInput'
          placeholder='******'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.code}
          error={formik.errors.code}
          touched={formik.touched.code}
        />
        <Button className='Button--lg RegisterForm__btn !w-full' type='submit'>
          {t('Verify email')}
        </Button>
      </div>
      {error && (
        <div className='RegisterForm__error mt-2 text-center'>{t(error)}</div>
      )}
    </form>
  );
}
