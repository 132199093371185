import { useEffect, useState } from 'react';
import { ACCESS_TOKEN_KEY, INITIAL_LD_KEY } from './core/constants';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
  useAuth,
  useScrollTopOnRouteChange,
} from './hooks';
import {
  getAmazonConnectCredentials,
  getUser,
  getUserCompanies,
} from './core/api';
import {
  activeCompanySelector,
  userSelector,
} from './core/store/user/userSlice';
import { UserFlagEnum } from './core/types';
import { Navigate, Route, RouteProps, Routes } from 'react-router-dom';
import { Loader } from './components/Loader/Loader';
import { PublicRoutesEnum, RolesEnum } from './core/enums';
import Notifications from './components/Notifications/Notifications';
import BookMeetingModal from './components/BookMeetingModal/BookMeetingModal';
import CreatePassword from './pages/CreatePassword/CreatePassword';
import DataProcessingAgreement from './pages/DataProcessingAgreement/DataProcessingAgreement';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Login from './pages/Login/Login';
import OSSSelfService from './pages/OSS/OSSSelfService';
import Page404 from './pages/Page404/Page404';
import RegisterUser from './pages/RegisterUser/RegisterUser';
import TermsOfService from './pages/TermsOfService/TermsOfService';
import Register from './pages/Register/Register';

import TaxAdvisorRoutes from './pages/TaxAdvisor/TaxAdvisorRoutes';
import SellerRoutes from './pages/Seller/SellerRoutes';
import SetupCheckRoutes from './pages/SetupCheck/SetupCheckRoutes';

// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const docsRoutes = [
  {
    element: <TermsOfService />,
    path: PublicRoutesEnum.TermsOfService,
  },
  {
    element: <DataProcessingAgreement />,
    path: PublicRoutesEnum.DataProcessingAgreement,
  },
];

const userRoutes: {
  [key: string]: RouteProps[];
} = {
  [RolesEnum.SELLER]: [
    {
      element: <SellerRoutes />,
      path: '/*',
    },
  ],
  [RolesEnum.SETUP_CHECK]: [
    {
      element: <SetupCheckRoutes />,
      path: '/*',
    },
  ],
  [RolesEnum.TAX_ADVISOR]: [
    {
      element: <TaxAdvisorRoutes />,
      path: '/*',
    },
  ],
  [RolesEnum.OSS]: [
    {
      element: <OSSSelfService />,
      path: '/*',
    },
  ],
  [RolesEnum.AGGREGATOR]: [],
};

const App = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { authUser } = useAuth();
  const user = useAppSelector(userSelector);
  const activeCompany = useAppSelector(activeCompanySelector);

  const ldClient = useLDClient();

  const publicRoutes = [
    {
      element: <Login />,
      path: '/',
    },
    {
      element: <ForgotPassword />,
      path: PublicRoutesEnum.ForgotPassword,
    },
    {
      element: <CreatePassword />,
      path: PublicRoutesEnum.CreatePassword,
    },
    {
      element: <RegisterUser role={RolesEnum.SELLER} />,
      path: PublicRoutesEnum.RegisterCustomer,
    },
    {
      element: <RegisterUser role={RolesEnum.TAX_ADVISOR} />,
      path: PublicRoutesEnum.RegisterTaxAdvisor,
    },
    {
      element: <Register />,
      path: PublicRoutesEnum.Register,
    },
    {
      element: (
        <Register role={RolesEnum.OSS} flag={UserFlagEnum.oss_self_service} />
      ),
      path: PublicRoutesEnum.RegisterOSS,
    },
    {
      element: <Navigate to='/' />,
      path: '*',
    },
  ];

  useEffect(() => {
    if (import.meta.env.VITE_APP_ENV !== 'test') {
      if (user) {
        ldClient?.identify({
          kind: 'user',
          key: user.Email,
          company_id: activeCompany?.company_id || user.CompanyUuid,
          company_name: activeCompany?.name || user.CompanyName,
        });
      } else {
        ldClient?.identify({
          key: INITIAL_LD_KEY,
        });
      }
    }
    // eslint-disable-next-line
  }, [user, activeCompany]);

  useScrollTopOnRouteChange();

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
      Promise.all([
        getUser(),
        getAmazonConnectCredentials(),
        getUserCompanies(),
      ])
        .then((responses) => {
          authUser(responses);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [authUser, dispatch]);

  useEffect(() => {
    if (
      import.meta.env.VITE_APP_ENV &&
      import.meta.env.VITE_APP_ENV !== 'prod'
    ) {
      const favicon = document.getElementById('favicon')!;
      const appleFavicon = document.getElementById('apple-favicon')!;
      if (favicon) {
        (favicon as HTMLLinkElement).href = '/logo192-env.png';
      }
      if (appleFavicon) {
        (appleFavicon as HTMLLinkElement).href = '/logo192-env.png';
      }
    }
  }, []);

  useEffect(() => {
    if (
      import.meta.env.VITE_APP_ENV !== 'prod' &&
      import.meta.env.VITE_APP_ENV !== 'test'
    ) {
      const head = document.head;

      const script = document.createElement('script');
      script.setAttribute('id', 'CookieBot');
      script.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
      script.setAttribute('data-cbid', '5ba2f28f-df61-4e9f-bc9f-e78e9f8d2bb0');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('async', 'true');
      script.setAttribute('data-culture', i18n.language.toUpperCase());
      head.insertBefore(script, head.firstChild);
    }
    // eslint-disable-next-line
  }, []);

  const routes = [
    {
      element: <Page404 />,
      path: PublicRoutesEnum.Page404,
    },
    ...docsRoutes,
    ...(user ? userRoutes[user.Role] : publicRoutes),
  ];

  if (isLoading) return <Loader />;

  return (
    <>
      <Routes>
        {routes.map((r) => (
          <Route element={r.element} path={r.path} key={r.path} />
        ))}
        <Route path='*' element={<Navigate to={PublicRoutesEnum.Page404} />} />
      </Routes>
      <Notifications />
      <BookMeetingModal />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </>
  );
};

export default App;
