import ManualInvoiceIcon from '@/icons/manual-invoice-item.svg?react';
import EasybillInvoiceIcon from '@/icons/easybill-invoice-item.svg?react';
import JtlInvoiceIcon from '@/icons/jtl-invoice-item.svg?react';
import AmazonInvoiceIcon from '@/icons/amazon-invoice-item.svg?react';
import BillbeeInvoiceIcon from '@/icons/billbee-invoice-item.svg?react';
import { Source } from '../core/types';

const ICONS: {
  [key: string]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
} = {
  amazon: AmazonInvoiceIcon,
  easybill: EasybillInvoiceIcon,
  manual: ManualInvoiceIcon,
  billbee: BillbeeInvoiceIcon,
  jtl: JtlInvoiceIcon,
};

interface Props {
  source: Source;
  className?: string;
}

export const SourceIcon = ({ source, className = '' }: Props) => {
  const Icon = ICONS[source] || null;
  if (!Icon) return null;
  return <Icon className={className} />;
};
