import { useTranslation } from 'react-i18next';
import { ClientWarehouse } from '@/core/types';
import './SetupCheckWarehouses.scss';
import SetupCheckWarehousesSection from './SetupCheckWarehousesSection';
import LinkIcon from '@/icons/link.svg?react';
import { pushEventToDataLayer } from '@/core/helpers';
import { GTMEvents } from '@/core/constants';

interface Props {
  warehouses: ClientWarehouse[];
  vatIds: string[];
  onClickVATIdSwitch: (c: string) => () => void;
}

export default function SetupCheckWarehouses({
  warehouses,
  vatIds,
  onClickVATIdSwitch,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div className='SetupCheck__title--sm  font-extrabold lg:mb-2'>
        {t('Warehouses')}
      </div>
      <div className='SetupCheck__text' style={{ maxWidth: 720 }}>
        {!!warehouses.length ? (
          <div className='mb-2' data-testid='warehouses-text'>
            {t(
              'You’re required to have VAT ID in each of those countries and provide recurring local VAT reports'
            )}
            <span className='hidden lg:inline'>
              <br />
            </span>
            {t('If sold less than 20 tell it means they were retours')}
          </div>
        ) : (
          <div className='mb-2' data-testid='no-warehouses'>
            {t(
              'No warehouses were identified. If you use your warehouses, not Amazon FBA, you require to have VAT ID in each of the countries where you store'
            )}
          </div>
        )}
        <a
          className='SetupCheckThreshold__link mb-4 inline-block'
          href='https://taxation-customs.ec.europa.eu/vat-invoicing-rules_en'
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => {
            pushEventToDataLayer(
              GTMEvents.setup_check_warehouses_learn_more_click
            );
          }}
        >
          <span className='mr-2'>{t('Learn more')}</span>
          <LinkIcon className='mb-1' />
        </a>
      </div>
      <SetupCheckWarehousesSection
        items={warehouses}
        vatIds={vatIds}
        onClickVATIdSwitch={onClickVATIdSwitch}
      />
    </>
  );
}
