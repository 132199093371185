import { ToastType } from '@/core/enums';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useState,
} from 'react';

interface ToastContextType {
  toast: (v: IToast) => void;
  deleteToast: (id: number) => () => void;
  setToasts: Dispatch<SetStateAction<IToast[]>>;
  toasts: IToast[];
}

export const ToastContext = createContext<ToastContextType>(
  {} as ToastContextType
);

export interface IToast {
  id: number;
  context: any;
  company_id: string;
  type: ToastType;
}

export const ToastProvider = ({ children }: PropsWithChildren) => {
  const [toasts, setToasts] = useState<IToast[]>([]);

  const toast = (v: IToast) => {
    setToasts((prev) => [v, ...prev]);
  };

  const deleteToast = (id: number) => () => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ toast, deleteToast, setToasts, toasts }}>
      {children}
    </ToastContext.Provider>
  );
};
