import { useEffect, useState } from 'react';
import Header from '@/components/Header/Header';
import RegisterUserForm from './RegisterUserForm';
import RegisterUserVerificationForm from './RegisterUserVerificationForm';
import { useLocation, useNavigate } from 'react-router-dom';
import './RegisterUser.scss';
import { PublicRoutesEnum, RolesEnum } from '@/core/enums';
import { getClientForAmazonConnect } from '@/core/api';
import { Loader } from '@/components/Loader/Loader';
import { useAppDispatch } from '../../hooks';
import classNames from 'classnames';
import { DEFAULT_ERROR } from '@/components/Notifications/Notification';
import { useTranslation } from 'react-i18next';

interface Props {
  initialVerification?: boolean;
  initialEmail?: string;
  initialPassword?: string;
  role: RolesEnum.TAX_ADVISOR | RolesEnum.SELLER;
}

export default function RegisterUser({
  initialVerification = false,
  initialEmail = '',
  initialPassword = '',
  role,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const state = params.get('state');
  const [isLoading, setIsLoading] = useState(!initialVerification);
  const [password, setPassword] = useState(initialPassword);
  const [email, setEmail] = useState(initialEmail);
  const [isVerification, setIsVerification] = useState(initialVerification);
  const [isError, setIsError] = useState(false);

  const onRegisterSubmit = (email: string, password: string) => {
    setEmail(email);
    setPassword(password);
    setIsVerification(true);
  };

  useEffect(() => {
    if (state) {
      getClientForAmazonConnect(state)
        .then(() => {
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setIsError(true);
          if (e.response?.status === 400) {
            navigate(PublicRoutesEnum.Page404);
          }
        });
    }
    // eslint-disable-next-line
  }, [dispatch, state]);

  useEffect(() => {
    if (!isVerification && !state) {
      navigate(PublicRoutesEnum.Page404);
    }
    // eslint-disable-next-line
  }, [state, isVerification]);

  if (!isVerification && !state) {
    return null;
  }

  if (isLoading) return <Loader />;
  if (isError) return <div className='p-4'>{t(DEFAULT_ERROR)}</div>;

  return (
    <div className='RegisterUser flex flex-col'>
      <Header />
      <div
        className={classNames('flex flex-grow justify-center px-4', {
          'items-start': isVerification,
          'items-center': !isVerification,
        })}
      >
        <div
          className={classNames('RegisterUser__card', {
            RegisterUser__verification: isVerification,
          })}
        >
          {isVerification ? (
            <RegisterUserVerificationForm email={email} password={password} />
          ) : (
            <RegisterUserForm
              role={role}
              onSubmit={onRegisterSubmit}
              uuid={state || ''}
            />
          )}
        </div>
      </div>
    </div>
  );
}
