import { useTranslation } from 'react-i18next';
import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import { ChangeEventHandler, useEffect, useState } from 'react';
import Switch from '@/components/Switch/Switch';
import { ErrorsMappingDE, ErrorsMappingEN } from './SellerDocuments.helpers';
import { ErrorDescriptionType } from '@/core/types';
import { useSearchParams } from 'react-router-dom';
import { FilterType } from './SellerDocuments';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  healthchecksCount: number | undefined;
  healthchecksList: ErrorDescriptionType[];
  plausibilityErrorsCount: number | undefined;
  plausibilityErrorsList: ErrorDescriptionType[];
  onApply: (state: SpecificFilterState) => void;
}
export type SpecificFilterState = {
  error: Record<string, boolean>;
  warning: Record<string, boolean>;
};

export function SpecificErrorSelectModal({
  isModalOpened,
  closeModal,
  healthchecksCount,
  healthchecksList,
  plausibilityErrorsCount,
  plausibilityErrorsList,
  onApply,
}: Props) {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [codeFilters, setCodeFilters] = useState<SpecificFilterState>({
    error: {},
    warning: {},
  });

  useEffect(() => {
    if (isModalOpened) {
      const filters = searchParams.get('filter')?.split(',') || [];
      const errorCodes = searchParams.getAll('error_code') || [];
      const warningCodes = searchParams.getAll('warning_code') || [];
      setCodeFilters({
        error: healthchecksList.reduce((acc: Record<string, boolean>, item) => {
          acc[item.code] =
            (filters.includes('error') &&
              !healthchecksList.some((v) => errorCodes.includes(v.code))) ||
            searchParams.getAll('error_code').includes(item.code);
          return acc;
        }, {}),
        warning: plausibilityErrorsList.reduce(
          (acc: Record<string, boolean>, item) => {
            acc[item.code] =
              (filters.includes('warning') &&
                !plausibilityErrorsList.some((v) =>
                  warningCodes.includes(v.code)
                )) ||
              searchParams.getAll('warning_code').includes(item.code);
            return acc;
          },
          {}
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpened]);

  const toggleAll: (v: FilterType) => ChangeEventHandler<HTMLInputElement> =
    (v: FilterType) => (e) => {
      setCodeFilters((prevState) => {
        return {
          ...prevState,
          [v]: {
            ...Object.keys(prevState[v as keyof SpecificFilterState]).reduce(
              (acc: Record<string, boolean>, code) => {
                acc[code] = e.target.checked;
                return acc;
              },
              {}
            ),
          },
        };
      });
    };

  const toggleCodeFilter: (
    code: string,
    filter: FilterType
  ) => ChangeEventHandler<HTMLInputElement> =
    (code: string, filter: FilterType) => (e) => {
      const key = filter as keyof SpecificFilterState;
      setCodeFilters((prevState) => {
        return {
          ...prevState,
          [key]: {
            ...prevState[filter as keyof SpecificFilterState],
            [code]: e.target.checked,
          },
        };
      });
    };

  const ErrorsMapping =
    i18n.language === 'en' ? ErrorsMappingEN : ErrorsMappingDE;
  return (
    <ConfirmModal
      isModalOpened={isModalOpened}
      closeModal={closeModal}
      title={t('Filter by problems')}
      confirm={() => onApply(codeFilters)}
      isDisabled={!healthchecksCount && !plausibilityErrorsCount}
      confirmBtnText='Apply filter'
    >
      <>
        <div className='Title--sm py-2 font-black'>
          {t('Show invoices that contain')}
        </div>
        {!!healthchecksCount && !!healthchecksList.length && (
          <div>
            <div className='flex items-center justify-between'>
              <h5 className='font-semibold'>
                {t('Error types')}: {healthchecksList.length}
              </h5>
              <Switch
                checked={Object.values(codeFilters.error).every(
                  (v) => v === true
                )}
                onChange={toggleAll('error')}
                size='sm'
              />
            </div>
            <ul className='space-y-2 pt-2'>
              {healthchecksList.map((hce) => {
                return (
                  <li
                    className='flex items-start justify-between rounded-lg border p-2'
                    key={hce.code}
                  >
                    <div className=''>
                      <div className='text-xs font-semibold'>
                        {t(
                          hce.count > 1
                            ? '*number* documents affected'
                            : '*number* document affected'
                        )
                          .replace('*number*', String(hce.count))
                          .toUpperCase()}
                      </div>
                      <div>{ErrorsMapping[hce.code]}</div>
                    </div>
                    <div>
                      <Switch
                        size='sm'
                        checked={codeFilters.error[hce.code] === true}
                        onChange={toggleCodeFilter(hce.code, 'error')}
                        name={`switch-error-${hce.code}`}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {!!plausibilityErrorsCount && !!plausibilityErrorsList.length && (
          <div className='pb-2'>
            <div className='flex items-center justify-between pt-4 '>
              <h5 className='font-semibold'>
                {t('Notice types')}: {plausibilityErrorsList.length}
              </h5>
              <Switch
                checked={Object.values(codeFilters.warning).every(
                  (v) => v === true
                )}
                onChange={toggleAll('warning')}
                size='sm'
              />
            </div>
            <ul className='space-y-2 pt-2'>
              {plausibilityErrorsList.map((pe) => {
                return (
                  <li
                    className='flex items-start justify-between rounded-lg border p-2'
                    key={pe.code}
                  >
                    <div className=''>
                      <div className='text-xs font-semibold'>
                        {t(
                          pe.count > 1
                            ? '*number* documents affected'
                            : '*number* document affected'
                        )
                          .replace('*number*', String(pe.count))
                          .toUpperCase()}
                      </div>
                      <div>{ErrorsMapping[pe.code]}</div>
                    </div>
                    <div>
                      <Switch
                        size='sm'
                        checked={codeFilters.warning[pe.code] === true}
                        onChange={toggleCodeFilter(pe.code, 'warning')}
                        name={`switch-warning-${pe.code}`}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </>
    </ConfirmModal>
  );
}
