import IndicatorIcon from '@/icons/indicator.svg?react';
import './SellerThresholdIndicator.scss';

interface Props {
  amount: number;
}

const getPosition = (amount: number) => {
  const percent = amount >= 0 ? Math.round(amount / 333.33) : 0;

  if (percent > 100) {
    return `calc(100% - 5px)`;
  }

  if (percent <= 0) {
    return '-5px';
  }

  return `${percent}%`;
};

export default function SellerThresholdIndicator({ amount }: Props) {
  return (
    <div className='SellerThresholdIndicator relative mt-4 w-full'>
      <div
        className='SellerThresholdIndicator__point absolute'
        style={{
          left: getPosition(amount),
        }}
      >
        <IndicatorIcon />
      </div>
    </div>
  );
}
