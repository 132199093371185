import axios, { AxiosResponse, CanceledError, isAxiosError } from 'axios';

import {
  ClientWarehouse,
  RegisterInput,
  CountryThreshold,
  ClientWarehouseBE,
  AmazonConnectCredentialsModel,
  AmazonDataImportStatusModel,
  RegisterUserInput,
  PublicClient,
  QuestionType,
  Answer,
  AmazonIntegration,
  VATId,
  FCTransfer,
  CrossedOss,
  UploadedFile,
  Payment,
  Report,
  UserCompany,
  StopMailingType,
  SellerFilingReport,
  EasybillData,
  DatevAggregatedItem,
  PostTypeformSubmissionInput,
  TypeformSubmission,
  InvoiceBookResponse,
  Country,
  AgrregatedDataType,
  DataUnit,
  InvoiceDocumentsResponse,
  GetEvaluationInput,
  PostEvaluationInput,
  Evaluation,
  NormalizedEvaluation,
  ProformaInvoice,
  DataUnitStatsItem,
  ClientFeedback,
  JTLCredentials,
  CreateJTLCredentialsDTO,
  BillbeeData,
  InvoiceDocumentSuggestionsType,
  InvoiceDocument,
} from './types';
import { clearUserState, User } from './store/user/userSlice';
import store from './store';
import { isTokenInvalid, removeTokens } from './helpers';
import { refreshAccessToken } from './auth';
import {
  ACCESS_TOKEN_KEY,
  API_URL,
  ID_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from './constants';
import {
  normalizeClientWarehousesFE,
  normalizeCountryThresholdFE,
  normalizeEvaluation,
  normalizeFilingReport,
} from './normalization';
import { PostClientAmazonConnectInput } from '../pages/Seller/SellerAmazonConnect/SellerAmazonConnect';
import {
  setAmazonDataImportStatus,
  setHasNewAmazonCredentials,
} from './store/amazon/amazonSlice';
import { PurchasePricesUploadedFile } from '../pages/PurchasePrices/PurchasePricesUploader';
import { DatevFile } from '../components/Datev/DatevItem';
import { Event } from '../hooks';
import { addNotification } from './store/notificationsSlice';
import { DEFAULT_ERROR } from '@/components/Notifications/Notification';
import { PatchInvoiceDocument } from '@/pages/Seller/SellerDocuments/EditInvoiceModal';

const client = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const refreshTokens = async () => {
  try {
    const res = await refreshAccessToken(
      localStorage.getItem(REFRESH_TOKEN_KEY) || ''
    );
    localStorage.setItem(
      ACCESS_TOKEN_KEY,
      res.AuthenticationResult!.AccessToken!
    );
    localStorage.setItem(ID_TOKEN_KEY, res.AuthenticationResult!.IdToken!);
  } catch (error) {
    store.dispatch(clearUserState());
    store.dispatch(setAmazonDataImportStatus(undefined));
    store.dispatch(setHasNewAmazonCredentials(false));
    removeTokens();
  }
};

client.interceptors.request.use(
  async function (config) {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) || '';

    if (!accessToken) {
      return config;
    }

    if (isTokenInvalid(accessToken)) {
      await refreshTokens();
    }

    config.headers.set({
      ...config.headers,
      Authorization: `Bearer ${localStorage.getItem(ID_TOKEN_KEY)}`,
    });

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err) {
    if (
      isAxiosError(err) &&
      !(err instanceof CanceledError) &&
      err.response?.status !== 404
    ) {
      store.dispatch(
        addNotification({
          text: DEFAULT_ERROR,
          type: 'error',
        })
      );
    }

    return Promise.reject(err);
  }
);

export const getUser = async () => {
  const res = await client.get<User>('/get-user-data');
  return res.data;
};

export const registerSetupCheck = async (data: RegisterInput) => {
  const res = await client.post('/register', data);
  return res.data;
};
export const registerSeller = async (data: RegisterUserInput) => {
  const res = await client.post('/user-register', data);
  return res.data;
};

export async function postEvent(id: string, event: Event) {
  return client.post(`/visitors/${id}/events`, event);
}

export const getClientThresholdByCountries = async (
  company_id?: string
): Promise<CountryThreshold[]> => {
  const res = await client.get<CountryThreshold[]>(
    '/client/threshold/countries',
    {
      params: {
        company_id,
      },
    }
  );

  return (res.data || [])
    .sort((a, b) => b.threshold.value - a.threshold.value)
    .map(normalizeCountryThresholdFE);
};

export const getClientWarehouses = async (
  company_id?: string
): Promise<ClientWarehouse[]> => {
  const res = await client.get<ClientWarehouseBE[]>('/client/warehouses', {
    params: {
      company_id,
    },
  });
  return (res.data || [])
    .sort((a, b) => b.count_of_sold_items - a.count_of_sold_items)
    .map(normalizeClientWarehousesFE);
};

export const getAmazonConnectCredentials = async (): Promise<
  AmazonConnectCredentialsModel[]
> => {
  const res = await client.get('/client/amazon-connect');
  return res.data;
};

export const getAmazonDataImportStatus = async (
  controller?: AbortController
): Promise<AmazonDataImportStatusModel> => {
  const res = await client.get('/client/amazon-data-status', {
    signal: controller?.signal,
  });
  return res.data;
};

export const postClientAmazonConnect = async ({
  company_id,
  seller_id,
  oauth_code,
}: PostClientAmazonConnectInput) => {
  const res = await client.post(
    '/client/amazon-connect',
    {
      seller_id,
      oauth_code,
    },
    {
      params: {
        company_id,
      },
    }
  );
  return res.data;
};

export const getClientForAmazonConnect = async (uuid: string) => {
  const res = await client.get<PublicClient>(`/client-data/${uuid}`);
  return res.data;
};

export const postTriggerAmazonDataImport = async () => {
  const res = await client.post('/client/amazon-import');
  return res.data;
};

export const patchUser = async (data: Partial<User>) => {
  const res = await client.patch('/user', data);
  return res.data;
};

export const patchAnswer = async (
  key: QuestionType,
  answer: boolean | string
) => {
  const res = await client.patch<Answer>(`/answer/${key}`, {
    answer,
  });
  return res.data;
};

export const getAnswer = async (key: QuestionType) => {
  const res = await client.get<boolean | string>(`/answer/${key}`);
  return res.data;
};

export const postFeedback = async (feedback: ClientFeedback) => {
  const res = await client.post<ClientFeedback>(
    '/answer/rate-with-comment',
    feedback
  );
  return res.data;
};

export const getIntegrations = async (company_id?: string) => {
  const res = await client.get<AmazonIntegration[]>('/client/seller-accounts', {
    params: {
      company_id,
    },
  });
  return res.data;
};

export const getInProgressClientVATIds = async (company_id?: string) => {
  const res = await client.get<VATId[]>('/client/vat-ids/in-progress', {
    params: {
      company_id,
    },
  });
  return res.data;
};
export const getRegisteredVATIds = async (company_id?: string) => {
  const res = await client.get<VATId[]>('/client/vat-ids/registered', {
    params: {
      company_id,
    },
  });
  return res.data;
};
export const getClientReports = async (company_id?: string) => {
  const res = await client.get<Report[]>('/client/reports', {
    params: {
      company_id,
    },
  });
  return res.data;
};
export const getPayments90days = async (company_id?: string) => {
  const res = await client.get<Payment[]>('/client/payments', {
    params: {
      company_id,
    },
  });
  return res.data;
};
export const getYearPayments = async (company_id?: string) => {
  const res = await client.get<Payment[]>('/client/payments/year', {
    params: {
      company_id,
    },
  });
  return res.data;
};

export const postUserTestingOpt = async () => {
  const res = await client.post('/user/testing-opt-in');
  return res.data;
};

export const getClientFCTransfers = async (company_id?: string) => {
  const res = await client.get<FCTransfer[]>('/client/amazon-fc-transfers', {
    params: {
      company_id,
    },
  });
  return res.data;
};
export const getCrossThreshold = async (company_id?: string) => {
  const res = await client.get<CrossedOss>(
    '/client/threshold/oss-crossed-quarter',
    {
      params: {
        company_id,
      },
    }
  );
  return res.data;
};
export const getUploadedDocumentsFiles = async (
  company_id: string,
  controller?: AbortController
) => {
  const res = await client.get<UploadedFile[]>('/client/uploaded-files', {
    signal: controller?.signal,
    params: {
      company_id,
    },
  });
  return res.data;
};

export const getUploadedDocumentFileUrl = async (
  id: number,
  company_id?: string
) => {
  const res = await client.post<string>(
    `/client/uploaded-files/${id}/download`,
    undefined,
    {
      params: {
        company_id,
      },
    }
  );

  return res.data;
};

export const getDataUnits = async (
  company_id: string,
  controller?: AbortController
) => {
  const res = await client.get<DataUnit[]>('/client/data-units', {
    signal: controller?.signal,
    params: {
      company_id,
    },
  });
  return res.data.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );
};

export const getDataUnitsStats = async (company_id?: string) => {
  const res = await client.get<DataUnitStatsItem[]>(
    '/client/data-units/stats',
    {
      params: {
        company_id,
      },
    }
  );
  return res.data;
};

export const getUserCompanies = async () => {
  const res = await client.get<UserCompany[]>('/user/get-companies');
  return res.data;
};

export const getPurchasePricesCount = async (
  company_id: string,
  controller?: AbortController,
  lastMonthOnly?: boolean
) => {
  const res = await client.get<number>('/client/purchase-prices/count', {
    signal: controller?.signal,
    params: {
      company_id,
      lastMonthOnly: lastMonthOnly ? Number(lastMonthOnly) : undefined,
    },
  });
  return res.data;
};

export const getPurchasePricesUploadedFiles = async (
  company_id: string,
  controller?: AbortController
) => {
  const res = await client.get<PurchasePricesUploadedFile[]>(
    '/client/purchase-prices/files',
    {
      signal: controller?.signal,
      params: {
        company_id,
      },
    }
  );
  return res.data;
};

export const postPurchasePricesTemplate = async (
  company_id?: string,
  lastMonthOnly?: boolean
) => {
  const res = await client.post(
    '/client/purchase-prices/generate-template',
    undefined,
    {
      params: {
        company_id,
        lastMonthOnly: lastMonthOnly ? Number(lastMonthOnly) : undefined,
      },
    }
  );
  return res.data;
};

export const postStopMailing = async (
  type: StopMailingType,
  company_id?: string
) => {
  const res = await client.post(
    `/client/mailing-stop-list/${type}`,
    undefined,
    {
      params: {
        company_id,
      },
    }
  );
  return res.data;
};

export const getStopMailing = async (
  type: StopMailingType,
  company_id?: string
) => {
  const res = await client.get(`/client/mailing-stop-list/${type}`, {
    params: {
      company_id,
    },
  });
  return res.data;
};

export const getOngoingFiling = async (company_id?: string) => {
  const res = await client.get<SellerFilingReport[]>(
    '/client/reports/ongoing',
    {
      params: {
        company_id,
      },
    }
  );
  return res.data.map(normalizeFilingReport(false));
};

export const getCompletedFiling = async (company_id?: string) => {
  const res = await client.get<SellerFilingReport[]>(
    '/client/reports/completed',
    {
      params: {
        company_id,
      },
    }
  );
  return res.data.map(normalizeFilingReport(true));
};
export const generateConfirmationFileLink = async (
  file_id: number,
  company_id?: string
) => {
  const res = await client.post<string>(
    `/client/reports/confirmation/${file_id}/download`,
    undefined,
    {
      params: {
        company_id,
      },
    }
  );
  return res.data;
};
export const generatePaymentInstructionFileLink = async (
  payment_instruction_id: number,
  company_id?: string
) => {
  const res = await client.post<string>(
    `/client/reports/payment-instruction/${payment_instruction_id}/download`,
    undefined,
    {
      params: {
        company_id,
      },
    }
  );
  return res.data;
};

export const postConfirmPayment = async (id: number, company_id?: string) => {
  const res = await client.post(`/client/payments/${id}/confirm`, undefined, {
    params: {
      company_id,
    },
  });
  return res.data;
};

export const postAgendaConfirm = async (
  report_id: number,
  company_id?: string
) => {
  const res = await client.post(
    `/client/reports/${report_id}/agenda-confirm`,
    undefined,
    {
      params: {
        company_id,
      },
    }
  );
  return res.data;
};

export const getEasybillTokenStatus = async (company_id?: string) => {
  const res = await client.get<EasybillData>('/client/easybill', {
    params: {
      company_id,
    },
  });
  return res.data;
};

export const postEasybillToken = async (Token: string, company_id?: string) => {
  const res = await client.post(
    '/client/easybill/save-token',
    { Token },
    {
      params: {
        company_id,
      },
    }
  );
  return res.data;
};
export const postBillbeeCredentials = async (
  username: string,
  password: string,
  company_id?: string
) => {
  const res = await client.post(
    '/client/billbee/save-token',
    { username, password },
    {
      params: {
        company_id,
      },
    }
  );
  return res.data;
};
export const getBillbee = async (company_id?: string) => {
  const res = await client.get<BillbeeData>('/client/billbee', {
    params: {
      company_id,
    },
  });
  return res.data;
};

export const getDatev = async (company_id?: string) => {
  const res = await client.get<DatevFile[]>('/client/datev/search', {
    params: {
      company_id,
    },
  });
  return res.data;
};

export const getReportResultFileLink = async (
  id: number,
  company_id?: string
) => {
  const res = await client.post(`/client/datev/download/${id}`, '', {
    params: {
      company_id,
    },
  });
  return res.data;
};

export const getDatevLedgerAggregated = async (
  company_id: string | undefined,
  year?: number,
  month?: number
) => {
  const res = await client.get<DatevAggregatedItem[]>(
    '/client/datev/ledger/aggregated',
    {
      params: {
        company_id,
        year,
        month,
      },
    }
  );
  return res.data;
};

export const getDatevLedger = async (
  company_id: string | undefined,
  year: number,
  month: number,
  page: number,
  entry_type?: number
) => {
  const res = await client.get('/client/datev/ledger', {
    params: {
      company_id,
      year,
      month,
      page,
      entry_type,
    },
  });
  return res.data;
};

export const postNpsFeedback = async (score: number, comment: string = '') => {
  const res = await client.post('/nps', { score, comment });
  return res.data;
};

export const postNpsAskMeLAter = async () => {
  const res = await client.post('/nps/ask-me-later', {});
  return res.data;
};

export const postTypeformSubmission = async (
  data: PostTypeformSubmissionInput
) => {
  const res = await client.post('/typeform-submissions', data);
  return res.data;
};

export const getTypeformSubmissions = async (user_id?: string) => {
  const res = await client.get<TypeformSubmission[]>('/typeform-submissions', {
    params: {
      user_id,
    },
  });
  return res.data;
};

export const postSubmitReport = async (
  reportId: number,
  company_id: string
) => {
  const res = await client.post(
    `/client/reports/${reportId}/submit`,
    undefined,
    {
      params: {
        company_id,
      },
    }
  );
  return res.data;
};

export const getInvoiceBook = async (
  company_id: string | undefined,
  type: 'month' | 'quarter',
  year: number,
  value: number,
  is_oss: number,
  page: number,
  vat_country_id: number | undefined
) => {
  const res = await client.get<InvoiceBookResponse>(
    '/client/invoice-book/items',
    {
      params: {
        company_id,
        year,
        [type]: value,
        is_oss,
        page,
        vat_country_id,
      },
    }
  );
  return res.data;
};

export const getCountries = async () => {
  const res = await client.get<Country[]>('/countries/search');
  return res.data;
};

export const getAggregatedDataOSSReport = async (
  report_id: number,
  company_id: string
) => {
  const res = await client.get<AgrregatedDataType[]>(
    `/client/reports/aggregated-info/${report_id}`,
    {
      params: {
        company_id,
      },
    }
  );

  return res.data;
};

export const getListOfErrorsLink = async (id: number, company_id: string) => {
  const res = await client.post<string>(
    `/client/uploaded-documents-file-errors-message/${id}/download`,
    undefined,
    {
      params: {
        company_id,
      },
    }
  );

  return res.data;
};

export const getInvoiceDocuments = async (
  company_id: string | undefined,
  year: number,
  month: number,
  filter: string | undefined,
  error_code: string[],
  warning_code: string[],
  page: number,
  controller: AbortController
) => {
  const res = await client.get<InvoiceDocumentsResponse>(
    '/client/invoice-documents',
    {
      params: {
        company_id,
        year,
        page,
        month,
        filter,
        error_code,
        warning_code,
      },
      signal: controller.signal,
      paramsSerializer: {
        indexes: null,
      },
    }
  );
  return res.data;
};

export const patchInvoiceDocuments = async (
  id: string,
  input: PatchInvoiceDocument
) => {
  const res = await client.patch(`/client/invoice-documents/${id}`, input);
  return res.data;
};

export const getInvoiceDocumentSuggestions = async (id: string) => {
  const res = await client.get<InvoiceDocumentSuggestionsType[]>(
    `/client/invoice-documents/${id}/suggestions`
  );
  return res.data;
};

export const postInvoiceDocumentSuggestionsCode = async (
  id: string,
  code: number
) => {
  const res = await client.post<string>(
    `/client/invoice-documents/${id}/suggestions/${code}/apply`
  );
  return res.data;
};

export const getInvoiceDocumentSuggestedValues = async (
  id: string,
  code: number
) => {
  const res = await client.get<InvoiceDocument>(
    `/client/invoice-documents/${id}/suggestions/${code}/values`
  );
  return res.data;
};

export const getInvoiceDocumentsErrors = async (
  company_id: string | undefined,
  year: number,
  month: number
) => {
  const res = await client.get<number>('/client/invoice-documents/errors', {
    params: {
      company_id,
      year,
      month,
    },
  });
  return res.data;
};

export const getInvoiceDocumentsWarnings = async (
  company_id: string | undefined,
  year: number,
  month: number
) => {
  const res = await client.get<number>('/client/invoice-documents/warnings', {
    params: {
      company_id,
      year,
      month,
    },
  });
  return res.data;
};

export const postEvaluation = async (
  company_id: string | undefined,
  input: PostEvaluationInput
) => {
  const res = await client.post<PostEvaluationInput, AxiosResponse<Evaluation>>(
    '/client/invoice-book',
    input,
    {
      params: {
        company_id,
      },
    }
  );
  return normalizeEvaluation(res.data);
};

export const getEvaluationURL = async (
  company_id: string | undefined,
  id: number
) => {
  const res = await client.post<string>(
    `/client/invoice-book/${id}/download`,
    undefined,
    {
      params: {
        company_id,
      },
    }
  );
  return res.data;
};

export const getEvaluation = async (
  company_id: string | undefined,
  params: GetEvaluationInput
): Promise<NormalizedEvaluation | null> => {
  const res = await client.get<Evaluation>('/client/invoice-book', {
    params: {
      company_id,
      ...params,
    },
  });

  if (res.status === 204) {
    return null;
  }

  return normalizeEvaluation(res.data);
};

export const getProformaInvoices = async (company_id: string | undefined) => {
  const res = await client.get<ProformaInvoice[]>('/client/proforma-invoices', {
    params: {
      company_id,
    },
  });
  return res.data;
};

export const postDownloadProformaInvoice = async (
  id: number,
  company_id: string | undefined
) => {
  const res = await client.post<string>(
    `/client/proforma-invoices/${id}/download`,
    {
      params: {
        company_id,
      },
    }
  );
  return res.data;
};

export const postDownloadDataUnitsErrors = async (
  from: string,
  to: string,
  filter: string,
  lang: string,
  company_id: string | undefined
) => {
  const res = await client.post<string>(
    '/client/correction/download-errors',
    undefined,
    {
      params: {
        from,
        to,
        filter,
        lang,
        company_id,
      },
    }
  );
  return res.data;
};

export const postDiscardFile = async (id: number, company_id?: string) => {
  const res = await client.post<string>(
    `/client/uploaded-files/${id}/discard`,
    undefined,
    {
      params: {
        company_id,
      },
    }
  );

  return res.data;
};

export const getJTLCredentials = async (company_id: string | undefined) => {
  const res = await client.get<JTLCredentials>('/jtl/credentials', {
    params: {
      company_id,
    },
  });
  return res.data;
};

export const postJTLCredentials = async (company_id: string | undefined) => {
  const res = await client.post<
    undefined,
    AxiosResponse<CreateJTLCredentialsDTO>
  >('/jtl/credentials', undefined, {
    params: {
      company_id,
    },
  });
  return res.data;
};

export const regenerateJTLCredentials = async (
  company_id: string | undefined
) => {
  const res = await client.post<
    undefined,
    AxiosResponse<CreateJTLCredentialsDTO>
  >('/jtl/credentials/regenerate', undefined, {
    params: {
      company_id,
    },
  });
  return res.data;
};
