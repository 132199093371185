import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import './TableGrid.scss';

interface Props<T> {
  columns: TableGridColumn<T>[];
  data: T[];
  className?: string;
  headerClassName?: string;
  renderCustomRow?: (
    r: T,
    columns: TableGridColumn<T>[],
    gridTemplateColumns: string,
    i: number
  ) => JSX.Element;
}

export interface TableGridColumn<T> {
  field: keyof T;
  label: string;
  sortable?: boolean;
  formatter?: (v: T) => string | number | null | JSX.Element;
  minWidth?: number;
  maxWidth?: string;
}

export const TableGrid = <T,>({
  columns,
  data,
  className = '',
  headerClassName = '',
  renderCustomRow,
  ...rest
}: Props<T & { id?: string | number }> & HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();

  const gridTemplateColumns = columns
    .map((c) => `minmax(${c.minWidth || 64}px, ${c.maxWidth || '1fr'})`)
    .join(' ');

  return (
    <div className={`TableGrid ${className}`} {...rest}>
      <div
        className={`TableGrid__header grid p-2 ${headerClassName}`}
        style={{ gridTemplateColumns }}
      >
        {columns.map((c) => (
          <div key={c.label} className='TableGrid__cell'>
            {t(c.label)}
          </div>
        ))}
      </div>
      {data.map((row, i) =>
        renderCustomRow ? (
          renderCustomRow(row, columns, gridTemplateColumns, i)
        ) : (
          <div
            className={`TableGrid__row grid p-2`}
            key={row.id || i}
            style={{ gridTemplateColumns }}
          >
            {columns.map((c) => (
              <div key={c.label} className='TableGrid__cell'>
                <>{c.formatter ? c.formatter(row) : row[c.field]}</>
              </div>
            ))}
          </div>
        )
      )}
    </div>
  );
};
