import { useTranslation } from 'react-i18next';
import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import { postUserTestingOpt } from '@/core/api';
import { setTestingOptIn } from '@/core/store/user/userSlice';
import { useAppDispatch } from '../../hooks';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  title?: string;
  confirmBtnText?: string;
}

export default function ConfirmDemoModal({
  isModalOpened,
  closeModal,
  title = 'Would you like to have a short call?',
}: Props) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const onClickConfirmDemo = (setIsLoading: (v: boolean) => void) => {
    setIsLoading(true);

    postUserTestingOpt()
      .then(() => {
        dispatch(setTestingOptIn());
        closeModal();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <ConfirmModal
      isModalOpened={isModalOpened}
      closeModal={closeModal}
      confirm={onClickConfirmDemo}
      confirmBtnText='Request demo'
    >
      <>
        <div className='Text mb-2 font-black'>{t(title)}</div>
        <div className='mb-4'>
          {t(
            'You would be able to test the demo version and share your wishes and expectations with our team.'
          )}
        </div>
        <div className='mb-4'>{t('45 min • Basic English')}</div>
        <div
          className='mb-6'
          dangerouslySetInnerHTML={{
            __html: t('Or you can write your ideas at %email%').replace(
              '%email%',
              "<a href='mailto:product@countx.com' class='text-blue-500 hover:text-blue-600 transition-colors'>product@countx.com</a>"
            ),
          }}
        />
      </>
    </ConfirmModal>
  );
}
