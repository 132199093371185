import { createContext, PropsWithChildren } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getIntegrations } from '@/core/api';
import { AmazonIntegration } from '@/core/types';
import { useAppSelector } from '../../hooks';
import {
  activeCompanySelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';

export const IntegrationsContext = createContext<{
  refetchIntegrations: () => void;
  isLoading: boolean;
  integrations: AmazonIntegration[] | undefined;
}>({
  refetchIntegrations: () => {},
  isLoading: false,
  integrations: [],
});

export function IntegrationsProvider({ children }: PropsWithChildren<{}>) {
  const queryClient = useQueryClient();
  const activeCompany = useAppSelector(activeCompanySelector);
  const queryKey = useAppSelector(queryKeySelector);

  const { data, isLoading } = useQuery({
    queryKey: ['SellerIntegrations', queryKey],
    queryFn: () => getIntegrations(activeCompany?.company_id),
  });

  const refetchIntegrations = () => {
    queryClient.invalidateQueries({
      queryKey: ['SellerIntegrations', queryKey],
    });
  };

  return (
    <IntegrationsContext.Provider
      value={{
        refetchIntegrations,
        isLoading,
        integrations: data || [],
      }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
}
