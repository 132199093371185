import { createContext, PropsWithChildren } from 'react';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import {
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import {
  getPurchasePricesCount,
  getPurchasePricesUploadedFiles,
} from '../core/api';
import { sleep } from '../core/helpers';
import { getS3Client, PURCHASE_PRICES_BUCKET_NAME } from '../core/s3';
import {
  APIEventsEnum,
  useAppSelector,
  usePolling,
  useSendEvent,
} from '../hooks';
import { PurchasePricesUploadedFile } from '../pages/PurchasePrices/PurchasePricesUploader';
import * as Sentry from '@sentry/browser';
import {
  activeCompanySelector,
  queryKeySelector,
  userSelector,
} from '../core/store/user/userSlice';
import useNotification from '../hooks/useNotification';
import { useNPSModal } from '../components/NPSModal/useNPSModal';
import { FileStatusEnum } from '../core/enums';

const processingPricesNotificationId = 'processing-pp';

const usePurchasePrices = () => {
  const { isPollingActive, deactivatePolling, activatePolling } = usePolling();
  const controller = useRef(new AbortController());
  const { t } = useTranslation();
  const activeCompany = useAppSelector(activeCompanySelector);
  const user = useAppSelector(userSelector);
  const { showNotification, deleteNotification } = useNotification();
  const { sendEvent } = useSendEvent();
  const { openModal } = useNPSModal();

  const [isUploading, setIsUploading] = useState<{
    [key: string]: boolean;
  }>({});
  const [filesToUpload, setFilesToUpload] = useState<{
    [key: string]: File | undefined;
  }>({});
  const [uploadedFile, setUploadedFile] =
    useState<PurchasePricesUploadedFile>();

  const queryClient = useQueryClient();
  const queryKey = useAppSelector(queryKeySelector);

  const [countLastMonth, countAllPeriods, uploadedFiles] = useQueries({
    queries: [
      {
        queryKey: ['SellerPurchasePricesCountLastMonthOnly', queryKey],
        queryFn: () =>
          getPurchasePricesCount(
            activeCompany!.company_id,
            controller.current,
            true
          ),
        retry: false,
      },
      {
        queryKey: ['SellerPurchasePricesCountAllPeriods', queryKey],
        queryFn: () =>
          getPurchasePricesCount(activeCompany!.company_id, controller.current),
        retry: false,
      },
      {
        queryKey: ['SellerPurchasePricesFiles', queryKey],
        queryFn: () =>
          getPurchasePricesUploadedFiles(
            activeCompany!.company_id,
            controller.current
          ),
        retry: false,
      },
    ],
  });

  const isLoading =
    countLastMonth.isLoading ||
    countAllPeriods.isLoading ||
    uploadedFiles.isLoading;

  const filesData = uploadedFiles.data;
  const companyId = activeCompany?.company_id;
  const onFinishPollingFiles = useCallback(
    async (purchasePricesFiles: PurchasePricesUploadedFile[]) => {
      Promise.all([
        getPurchasePricesCount(companyId!, controller.current, true),
        getPurchasePricesCount(companyId!, controller.current),
      ])
        .then(([countLastMonth, countAllPeriods]) => {
          const newestFile = purchasePricesFiles.sort(
            (a, b) =>
              new Date(b.imported_at).getTime() -
              new Date(a.imported_at).getTime()
          )?.[0];
          if (newestFile?.status === FileStatusEnum.FAILED) {
            sendEvent(APIEventsEnum.purchase_prices_uploaded_unorrectly, {
              block: 'Purchase prices uncorrect downloading',
            });
          }
          if (newestFile?.status === FileStatusEnum.IMPORTED) {
            const apiEvent = APIEventsEnum.purchase_prices_uploaded_correctly;
            sendEvent(apiEvent, {
              block: 'Purchase prices correct downloading',
            });
            openModal(apiEvent);
          }
          deactivatePolling(companyId!);
          queryClient.setQueryData(
            ['SellerPurchasePricesCountLastMonthOnly', queryKey],
            countLastMonth
          );
          queryClient.setQueryData(
            ['SellerPurchasePricesCountAllPeriods', queryKey],
            countAllPeriods
          );
          queryClient.setQueryData(
            ['SellerPurchasePricesFiles', queryKey],
            purchasePricesFiles
          );
          setUploadedFile(undefined);

          deleteNotification(processingPricesNotificationId);

          if (!!purchasePricesFiles.length && countLastMonth) {
            showNotification({
              type: 'error',
              text: t('*count* purchase prices still missing').replace(
                '*count*',
                String(countLastMonth)
              ),
              interval: 10000,
            });
          }
        })
        .catch(console.error);
    },
    // eslint-disable-next-line
    [queryKey, queryClient, user, companyId, openModal]
  );

  useEffect(() => {
    if (
      activeCompany &&
      filesData !== undefined &&
      !!filesData.length &&
      filesData.some((f) => f.status === 0) &&
      !isPollingActive(activeCompany.company_id)
    ) {
      activatePolling(activeCompany.company_id);
      pollFiles(
        activeCompany.company_id,
        controller.current,
        onFinishPollingFiles
      );
    }
    // eslint-disable-next-line
  }, [activeCompany, filesData, onFinishPollingFiles]);

  useEffect(() => {
    const abortController = controller.current;
    return () => {
      abortController.abort();
    };
  }, []);

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    if (!activeCompany || !filesToUpload[activeCompany.company_id]) return;
    setIsUploading((prev) => ({ ...prev, [activeCompany.company_id]: true }));
    try {
      const s3Client = await getS3Client();
      await s3Client.send(
        new PutObjectCommand({
          Bucket: PURCHASE_PRICES_BUCKET_NAME,
          Key: `${activeCompany?.company_id}/${filesToUpload[
            activeCompany.company_id
          ]?.name}`,
          Body: filesToUpload[activeCompany.company_id],
          Metadata: {
            userId: user!.Id,
          },
        })
      );
      s3Client.destroy();

      setUploadedFile({
        bucket: '',
        file: '/' + filesToUpload[activeCompany.company_id]?.name,
        id: 0,
        imported_at: '',
        rows_number: 0,
        size: 0,
        status: 0,
        updated_products_number: 0,
        validation_errors: [],
      });

      showNotification({
        type: 'success',
        text: 'Data file sent',
      });
      setIsUploading((prev) => ({
        ...prev,
        [activeCompany.company_id]: false,
      }));

      showNotification({
        type: 'loading',
        text: 'Processing purchase price file',
        state: 'open',
        id: processingPricesNotificationId,
      });
      setTimeout(async () => {
        try {
          const files = await getPurchasePricesUploadedFiles(
            activeCompany.company_id,
            controller.current
          );

          if (files.some((f) => f.status === 0)) {
            queryClient.setQueryData(
              ['SellerPurchasePricesFiles', queryKey],
              files
            );

            setUploadedFile(undefined);
          } else {
            onFinishPollingFiles(files);
          }
        } catch (error) {
          console.error(error);
        }
      }, 5000);
      deleteFileToUpload();
    } catch (error) {
      if ((error as any).message) {
        Sentry.captureMessage((error as any).message);
      } else if ((error as any).name) {
        Sentry.captureMessage((error as any).name);
      } else {
        Sentry.captureException(error);
      }
      showNotification({
        text: t('Error has occurred while uploading file *filename*').replace(
          '*filename*',
          filesToUpload?.[activeCompany.company_id]?.name || ''
        ),
        type: 'error',
      });

      setIsUploading((prev) => ({
        ...prev,
        [activeCompany.company_id]: false,
      }));
    }
  };

  const onSubmitPrevPeriod: FormEventHandler = async (e) => {
    e.preventDefault();
    if (!activeCompany || !filesToUpload[activeCompany.company_id]) return;
    setIsUploading((prev) => ({ ...prev, [activeCompany.company_id]: true }));

    try {
      const s3Client = await getS3Client();
      await s3Client.send(
        new PutObjectCommand({
          Bucket: PURCHASE_PRICES_BUCKET_NAME,
          Key: `${activeCompany?.company_id}/${filesToUpload[
            activeCompany.company_id
          ]?.name}`,
          Body: filesToUpload[activeCompany.company_id],
          Metadata: {
            userId: user!.Id,
          },
        })
      );
      s3Client.destroy();
      showNotification({
        type: 'success',
        text: 'Data file sent',
      });
      deleteFileToUpload();
    } catch (error) {
      if ((error as any).message) {
        Sentry.captureMessage((error as any).message);
      } else if ((error as any).name) {
        Sentry.captureMessage((error as any).name);
      } else {
        Sentry.captureException(error);
      }
      showNotification({
        text: t('Error has occurred while uploading file *filename*').replace(
          '*filename*',
          filesToUpload?.[activeCompany.company_id]?.name || ''
        ),
        type: 'error',
      });
    }
    setIsUploading((prev) => ({ ...prev, [activeCompany.company_id]: false }));
  };

  const deleteFileToUpload = () => {
    if (!activeCompany) return;
    setFilesToUpload((prev) => ({
      ...prev,
      [activeCompany.company_id]: undefined,
    }));
  };

  const onSelect = (files: File[] | null) => {
    if (!activeCompany || !files) return;

    const f = files[0];
    const blob = f.slice(0, f.size, f.type);
    const nameParts = f.name.split('.');
    setFilesToUpload((prev) => ({
      ...prev,
      [activeCompany.company_id]: new File(
        [blob],
        `${nameParts.slice(0, -1).join('.')}_${Date.now()}.${
          nameParts[nameParts.length - 1]
        }`,
        { type: f.type }
      ),
    }));
  };

  return {
    uploadedFiles: [
      ...(uploadedFiles.data || []),
      ...(uploadedFile ? [uploadedFile] : []),
    ],
    onSubmit,
    isUploading: activeCompany
      ? isUploading[activeCompany.company_id] || false
      : false,
    fileToUpload: activeCompany
      ? filesToUpload?.[activeCompany.company_id]
      : undefined,
    onSelect,
    countLastMonth: countLastMonth.data,
    countAllPeriods: countAllPeriods.data,
    isLoading,
    deleteFileToUpload,
    onSubmitPrevPeriod,
  };
};

const pollFiles = async (
  company_id: string,
  controller: AbortController,
  cb: (res: PurchasePricesUploadedFile[]) => void,
  count: number = 1
) => {
  try {
    const res = await getPurchasePricesUploadedFiles(company_id, controller);

    if (res.some((f) => f.status === FileStatusEnum.CREATED)) {
      await sleep(5000);
      pollFiles(company_id, controller, cb);
    } else {
      cb(res);
    }
  } catch {
    if (count < 3) {
      await sleep(5000);
      try {
        pollFiles(company_id, controller, cb, ++count);
      } catch (err) {
        console.error(err);
      }
    }
  }
};

export const PPContext = createContext<{
  PPFiles: PurchasePricesUploadedFile[];
  onSubmitPP: React.FormEventHandler;
  fileToUploadPP: File | undefined;
  onSelectPPFile: (files: File[] | null) => void;
  countLastMonthPP: number | undefined;
  countAllPeriodsPP: number | undefined;
  isLoadingPP: boolean;
  deletePPFileToUpload: () => void;
  onSubmitPPPrevPeriod: React.FormEventHandler;
  isUploadingPP: boolean;
}>({
  PPFiles: [],
  onSubmitPP: () => {},
  fileToUploadPP: undefined,
  deletePPFileToUpload: () => {},
  onSubmitPPPrevPeriod: () => {},
  onSelectPPFile: () => {},
  isUploadingPP: false,
  isLoadingPP: false,
  countLastMonthPP: undefined,
  countAllPeriodsPP: undefined,
});

export function PPProvider({ children }: PropsWithChildren<{}>) {
  const {
    uploadedFiles: PPFiles,
    onSubmit: onSubmitPP,
    isUploading: isUploadingPP,
    fileToUpload: fileToUploadPP,
    onSelect: onSelectPPFile,
    countLastMonth: countLastMonthPP,
    countAllPeriods: countAllPeriodsPP,
    isLoading: isLoadingPP,
    deleteFileToUpload: deletePPFileToUpload,
    onSubmitPrevPeriod: onSubmitPPPrevPeriod,
  } = usePurchasePrices();

  return (
    <PPContext.Provider
      value={{
        PPFiles,
        onSubmitPP,
        isUploadingPP,
        fileToUploadPP,
        onSelectPPFile,
        countLastMonthPP,
        countAllPeriodsPP,
        isLoadingPP,
        deletePPFileToUpload,
        onSubmitPPPrevPeriod,
      }}
    >
      {children}
    </PPContext.Provider>
  );
}
