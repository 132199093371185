import { useState } from 'react';
import { lockScroll } from '@/core/helpers';

export const useModal = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const closeModal = () => {
    setIsModalOpened(false);
    lockScroll(false);
    document.body.style.overflow = '';
  };

  const openModal = () => {
    setIsModalOpened(true);
    lockScroll(true);
  };

  return {
    isModalOpened,
    closeModal,
    openModal,
  };
};
