import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import StarIcon from '@/icons/star.svg?react';

export default function MerryChristmasBanner() {
  const { t, i18n } = useTranslation();
  const { vs3424MerryChristmas } = useFlags();

  if (!vs3424MerryChristmas) return null;

  return (
    <div
      className='mb-4 flex flex-col items-center justify-center px-4 py-4 text-center text-white'
      style={{
        background: '#E53935',
        borderRadius: 8,
        border: '2px solid #F55F5B',
      }}
    >
      <StarIcon />
      <div
        className='my-2 whitespace-pre-line text-h4 font-extrabold'
        style={{
          lineHeight: '24px',
        }}
      >
        {t('Merry Christmas and Happy New Year')}
      </div>
      <div className='mb-4'>
        {t(
          'We would be happy to hear your thoughts about our collaboration in *currentYear* to do better in *newYear*.'
        )
          .replace('*currentYear*', new Date().getFullYear().toString())
          .replace('*newYear*', (new Date().getFullYear() + 1).toString())}
      </div>
      <a
        className='Button--white'
        target='_blank'
        rel='noopener noreferrer'
        href={
          i18n.language === 'en'
            ? 'https://survey-eu1.hsforms.com/1TKsj8ygPQfinIdUZrg7r2gf10cm'
            : 'https://survey-eu1.hsforms.com/16qEUuN5FSFa_Zrtvyoo8ZAf10cm'
        }
      >
        {t("Let's go")}
      </a>
    </div>
  );
}
