import { useTranslation } from 'react-i18next';
import Button from '../Button';
import { useEffect, useState } from 'react';
import MiniLoader from '../MiniLoader/MiniLoader';
import { getUser, postNpsAskMeLAter, postNpsFeedback } from '@/core/api';
import { APIEventsEnum, useAppDispatch, useSendEvent } from '../../hooks';
import { setUser } from '@/core/store/user/userSlice';
import { useNPSModal } from './useNPSModal';
import ModalNonClosable from '../Modal/ModalNonClosable';
import classNames from 'classnames';
import * as Sentry from '@sentry/browser';
import useNotification from '@/hooks/useNotification';
import CloseIcon from '@/icons/close-icon.svg?react';

export function NPSModal() {
  const { t } = useTranslation();
  const [score, setScore] = useState<number>();
  const [askMeLaterOn, setAskMeLaterOn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { sendEvent } = useSendEvent();
  const { showNotification } = useNotification();

  const { isModalOpened, closeModal, event } = useNPSModal();

  useEffect(() => {
    if (isModalOpened) {
      sendEvent(APIEventsEnum.view_cta, {
        block: 'NPSModal',
        event,
      });
      setScore(undefined);
      setAskMeLaterOn(false);
    }
    // eslint-disable-next-line
  }, [isModalOpened]);

  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    if (score === undefined && !askMeLaterOn) return;

    setIsLoading(true);
    (score !== undefined ? postNpsFeedback(score) : postNpsAskMeLAter())
      .then(() => {
        return getUser();
      })
      .then((res) => {
        dispatch(setUser(res));
        closeModal();
      })
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err);
        showNotification({
          text: 'Something went wrong, please try again',
          type: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ModalNonClosable isOpened={isModalOpened}>
      <div className='p-6'>
        <Button
          styling='naked'
          onClick={closeModal}
          type='button'
          className='absolute right-1 top-1'
        >
          <CloseIcon />
        </Button>
        <h2 className={classNames('fw-800 Title--sm mb-2')}>
          {t('How likely are you to recommend countX to others?')}
        </h2>
        <div>
          {t(
            'This modal will disappear for 90 days after you share your feedback'
          )}
        </div>
        <div>
          <div className='flex flex-wrap items-center justify-between gap-x-1 py-2'>
            <div className='flex items-center gap-x-1'>
              {[...Array(11)].map((_, i) => (
                <div
                  key={i}
                  className={`flex h-12 w-12 cursor-pointer items-center justify-center rounded-2xl border text-center font-bold leading-none transition-colors hover:border-green hover:bg-green hover:text-white ${
                    score === i
                      ? 'border-green bg-green text-white'
                      : 'border-gray-500 bg-white text-dark'
                  }`}
                  onClick={() => {
                    setScore(i);
                    setAskMeLaterOn(false);
                  }}
                >
                  {i}
                </div>
              ))}
            </div>
            <div
              className={`flex h-12 cursor-pointer items-center justify-center rounded-2xl border px-2 text-center text-sm transition-colors hover:border-green hover:bg-green hover:text-white ${
                askMeLaterOn
                  ? 'border-green bg-green text-white'
                  : 'border-gray-500 bg-white text-dark'
              }`}
              onClick={() => {
                setAskMeLaterOn((prevState) => !prevState);
                setScore(undefined);
              }}
            >
              {t('Not now')}
            </div>
          </div>
          <div
            className='flex justify-between text-sm text-gray-700'
            style={{ width: 'calc(100% - 88px)' }}
          >
            <span>{t('Not likely at all')}</span>
            <span>{t('Extremely likely')}</span>
          </div>
        </div>

        {(score !== undefined || askMeLaterOn) && (
          <Button
            disabled={isLoading}
            className='mt-4 flex !w-full items-center justify-center'
            onClick={handleSubmit}
          >
            {isLoading && <MiniLoader className='mr-2' color='gray' />}
            <span>
              {t(`${askMeLaterOn ? 'Ask me in one month' : 'Submit'}`)}
            </span>
          </Button>
        )}
      </div>
    </ModalNonClosable>
  );
}
