import { useTranslation } from 'react-i18next';
import ConnectIcon from '@/icons/connect.svg?react';
import Button from '../Button';
import { PropsWithChildren } from 'react';

interface Props {
  onClick: () => void;
  className?: string;
  testId: string;
}

export default function IntegrationsConnectButton({
  onClick,
  className = '',
  testId,
  children,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  return (
    <Button
      styling='outline'
      onClick={onClick}
      data-testid={testId}
      className={`flex items-center justify-center ${className} !h-8 !w-[118px] !px-0 !py-1`}
    >
      {children ? (
        children
      ) : (
        <>
          <ConnectIcon className='mr-1' />
          <span>{t('Connect')}</span>
        </>
      )}
    </Button>
  );
}
