import { UploadedFile } from '@/core/types';
import CancelIcon from '@/icons/cancel.svg?react';
import { downloadFile } from '@/core/helpers';
import FileIcon from '../FileIcon';
import { getUploadedDocumentFileUrl } from '@/core/api';

interface Props {
  file: UploadedFile;
  companyId?: string;
}

export default function DownloadFileLink({ file, companyId }: Props) {
  const handleDownload = async () => {
    const parts = `${file.bucket}/${file.path}`.split('/');

    try {
      const url = await getUploadedDocumentFileUrl(file.id, companyId);
      downloadFile(url, parts[parts.length - 1]);
    } catch (error) {
      console.error(error);
    }
  };

  const hasErrors = !!file.errors?.length;

  const fileExtension = file.name.split('.').slice(-1)[0]!.toLowerCase();

  return (
    <>
      <div className='group flex w-full items-start justify-between gap-x-6'>
        <div className='flex w-full'>
          <div className='relative mr-2 rounded-full'>
            <FileIcon
              styling='circled'
              ext={fileExtension}
              className={`h-10 w-10 ${
                hasErrors ? '[&>g>path:first-child]:fill-gray-600' : ''
              }`}
            />
            {hasErrors && (
              <CancelIcon className='absolute right-0 top-0 !h-4 !w-4 [&>g>path]:fill-red-500' />
            )}
          </div>
          <div className='flex flex-col items-start justify-center'>
            <div
              className='z-10 grid cursor-pointer bg-white font-semibold transition-colors hover:text-green'
              onClick={handleDownload}
            >
              <div className='truncate hover:overflow-visible '>
                {file.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
