import { Loader } from '@/components/Loader/Loader';
import { getProformaInvoices, getTypeformSubmissions } from '@/core/api';
import { normalizeDateFE } from '@/core/normalization';
import {
  activeCompanySelector,
  queryKeySelector,
  userSelector,
} from '@/core/store/user/userSlice';
import { ProformaInvoice } from '@/core/types';
import { useAppSelector } from '@/hooks';
import { useQuery } from '@tanstack/react-query';
import { isSameMonth, isSameYear } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyStateTile } from '@/components/EmptyStateTile';
import {
  FF,
  ProformaInvoicesCollapse,
} from '@/components/ProformaInvoices/ProformaInvoicesCollapse';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PageLayout from '@/wrappers/PageLayout';
import FeedbackWidget from '@/components/FeedbackWidget/FeedbackWidget';

export const ProformaInvoices = () => {
  const { t } = useTranslation();
  const user = useAppSelector(userSelector);
  const queryKey = useAppSelector(queryKeySelector);
  const { vs5515ProformaFakedoorCp } = useFlags();

  const activeCompany = useAppSelector(activeCompanySelector);

  const { data: invoices, isLoading: isLoadingInvoices } = useQuery({
    queryKey: ['SellerProformaInvoices', queryKey],
    queryFn: () => getProformaInvoices(activeCompany?.company_id),
  });

  const { data: typeformSubmissions, isLoading: isLoadingTypeformSubmissions } =
    useQuery({
      queryKey: ['UserTypeformSubmissions', user?.Id],
      queryFn: () => getTypeformSubmissions(user!.Id),
    });

  const canSubmitTypeform =
    vs5515ProformaFakedoorCp &&
    !(typeformSubmissions || []).find((s) => s.feature_flag === FF);

  const groupedInvoices = useMemo(() => {
    const dates = (invoices || [])
      .map((inv) => normalizeDateFE(inv.document_date))
      .filter((v, i, self) => {
        return (
          self.findIndex((d) => isSameMonth(d, v) && isSameYear(d, v)) === i
        );
      })
      .sort((a, b) => b.getTime() - a.getTime());

    return dates.map((date) => {
      return {
        date,
        invoices: (invoices || [])
          .filter((inv) => {
            const docDate = normalizeDateFE(inv.document_date);
            return isSameMonth(date, docDate) && isSameYear(date, docDate);
          })
          .reduce(
            (
              acc: {
                [key: string]: ProformaInvoice[];
              },
              inv
            ) => {
              const key = `${t(inv.departure_country.short_name)}-${t(
                inv.arrival_country.short_name
              )}`;
              if (!acc[key]) {
                acc[key] = [];
              }
              acc[key].push(inv);
              return acc;
            },
            {}
          ),
      };
    });
  }, [invoices, t]);

  if (isLoadingInvoices || isLoadingTypeformSubmissions) return <Loader />;

  const renderInvoices = () => {
    if (!invoices?.length) {
      return (
        <EmptyStateTile
          text='No proforma invoices are available yet'
          title='No data imported yet'
        />
      );
    }

    return (
      <div className='space-y-2'>
        {groupedInvoices.map((v, i) => (
          <ProformaInvoicesCollapse
            initialOpened={i === 0}
            invoices={v.invoices}
            key={v.date.toISOString()}
            date={v.date}
            canSubmitTypeform={canSubmitTypeform}
          />
        ))}
      </div>
    );
  };

  return (
    <PageLayout
      className='flex-grow'
      rightColumn={
        <div style={{ maxWidth: 328 }} className='pt-4 lg:ml-auto lg:pt-0'>
          <FeedbackWidget testId='FeedbackWidget' />
        </div>
      }
    >
      <div className='mx-auto flex w-full max-w-[874px] flex-grow flex-col rounded-lg bg-white px-6 py-4 pb-6'>
        <div className='Text--30 mb-4 font-black'>{t('Proforma invoices')}</div>
        {renderInvoices()}
      </div>
    </PageLayout>
  );
};
