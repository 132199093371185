import { useState } from 'react';
import MailboxIcon from '@/icons/mailbox.svg?react';
import './Register.scss';
import WhiteGreenWrapper from '../../wrappers/WhiteGreenWrapper';
import RegisterForm, { RegisterStepType } from './RegisterForm';
import Header from '@/components/Header/Header';
import CreateAccountIconEN from '@/images/create_account_en.svg?react';
import CreateAccountIconDE from '@/images/create_account_de.svg?react';
import CreateOSSIconEN from '@/images/create-oss-en.svg?react';
import CreateOSSIconDE from '@/images/create-oss-de.svg?react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getRetinaImgSrc } from '@/core/helpers';
import { UserFlagEnum } from '@/core/types';
import { RolesEnum } from '@/core/enums';

interface Props {
  initialStep?: RegisterStepType;
  initialEmail?: string;
  initialPassword?: string;
  role?: RolesEnum;
  flag?: UserFlagEnum;
}

export default function Register({
  initialStep,
  initialEmail,
  initialPassword,
  role,
  flag,
}: Props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isAmazonFBACalculator = Boolean(
    searchParams.get('amazon-fba-calculator')
  );

  const { i18n, t } = useTranslation();
  const [isMailboxIconShown, setIsMailboxIconShown] = useState(
    initialStep === 'verify_email'
  );
  const onStepChange = () => {
    setIsMailboxIconShown(true);
  };

  const renderRight = () => {
    if (isAmazonFBACalculator) {
      return (
        <div className='Register__imageContainer'>
          <LazyLoadImage
            src={
              i18n.language === 'en'
                ? '/images/create_amazon_fba_calc.png'
                : '/images/create_amazon_fba_calc-de.png'
            }
            srcSet={getRetinaImgSrc(
              i18n.language === 'en'
                ? '/images/create_amazon_fba_calc-2x.png'
                : '/images/create_amazon_fba_calc-de-2x.png'
            )}
            alt='Daniel'
            wrapperClassName='flex items-center justify-content-center'
          />
        </div>
      );
    }

    if (role === RolesEnum.OSS) {
      return (
        <div className='Register__imageContainer'>
          {i18n.language === 'en' ? <CreateOSSIconEN /> : <CreateOSSIconDE />}
        </div>
      );
    }

    return (
      <>
        <div
          className='Text mb-4 pb-2 text-left'
          dangerouslySetInnerHTML={{
            __html: t(
              'Get a detailed overview of your cross-border sales, storage, and get recommendations for a compliant VAT setup'
            )!,
          }}
        />
        <div className='Register__imageContainer'>
          {i18n.language === 'en' ? (
            <CreateAccountIconEN />
          ) : (
            <CreateAccountIconDE />
          )}
        </div>
      </>
    );
  };

  return (
    <div className='flex min-h-screen flex-col'>
      <Header />
      <WhiteGreenWrapper
        className='WhiteGreenWrapper--Register'
        rightSideContent={
          isMailboxIconShown ? (
            <div className='flex items-center justify-center'>
              <MailboxIcon className='Register__mailboxImage mx-auto my-12 w-full pb-4 pt-12' />
            </div>
          ) : (
            <div>{renderRight()}</div>
          )
        }
      >
        <RegisterForm
          onStepChange={onStepChange}
          initialStep={initialStep}
          initialEmail={initialEmail}
          initialPassword={initialPassword}
          role={role}
          flag={
            flag
              ? flag
              : isAmazonFBACalculator
                ? UserFlagEnum.amazon_fba_calculator
                : undefined
          }
        />
      </WhiteGreenWrapper>
    </div>
  );
}
