import { useState } from 'react';

export const useTabs = <T>(initialTab: T) => {
  const [activeTab, setActiveTab] = useState<T>(initialTab);

  const onClickTab = (id: T) => () => {
    setActiveTab(id);
  };

  return { activeTab, onClickTab };
};
