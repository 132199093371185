export const DatevEntryTypesMapping: {
  [key: string]: number;
} = {
  datev_trns: 6,
  datev_shipment: 7,
  datev_commingling: 8,
  datev_trns_local: 10,
  datev_trns_netto: 11,
  datev_trns_vat: 12,
};

export const FiltersLabels: {
  [key: number]: string;
} = {
  6: 'Umsätze',
  7: 'Warenverbringungen',
  8: 'Commingling Transaktionen',
  10: 'Lokale Umsätze',
  11: 'OSS Netto Umsätze',
  12: 'OSS VAT Datei',
};
