import {
  endOfMonth,
  format,
  isSameMonth,
  isSameQuarter,
  startOfMonth,
} from 'date-fns';
import { getUTCDate } from './helpers';
import {
  ClientWarehouse,
  ClientWarehouseBE,
  CountryThreshold,
  Evaluation,
  NormalizedEvaluation,
  PeriodType,
  SellerFilingReport,
  NormalizedSellerFilingReport,
} from './types';

export const normalizeCountryThresholdFE = (item: CountryThreshold) => ({
  ...item,
  threshold: {
    ...item.threshold,
    value: item.threshold.value / 100,
  },
});
export const normalizeClientWarehousesFE = (
  item: ClientWarehouseBE
): ClientWarehouse => ({
  ...item,
  active_from: new Date(item.active_from),
});
export const normalizeDateBE = (date: Date) =>
  format(date, "yyyy-MM-dd'T'HH:mm:ss+00:00");

export const normalizeDateFE = (iso: string) => {
  return getUTCDate(new Date(iso));
};

export const normalizeFilingReport =
  (completed_filing: boolean) =>
  (r: SellerFilingReport): NormalizedSellerFilingReport => {
    return {
      ...r,
      completed_filing,
    };
  };

const getEvaluationDate = (v: string) => {
  return new Date(v.split('.').reverse().join('-'));
};

export const normalizeEvaluation = (e: Evaluation): NormalizedEvaluation => {
  const parts = e.period.split('-').map((v) => v.trim());

  const startDate = startOfMonth(getEvaluationDate(parts[0]));
  const endDate = endOfMonth(getEvaluationDate(parts[1]));

  let type: PeriodType = 'month';
  if (isSameMonth(startDate, endDate)) {
    type = 'month';
  } else if (isSameQuarter(startDate, endDate)) {
    type = 'quarter';
  }

  return {
    ...e,
    startDate,
    endDate,
    type,
  };
};
