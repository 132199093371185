import FeatureUnderDevelopmentModal from './FeatureUnderDevelopmentModal';
import { PopupButton } from '@typeform/embed-react';
import { useAppSelector } from '../hooks';
import { userSelector } from '../core/store/user/userSlice';
import { useTranslation } from 'react-i18next';
import { getTypeformSubmissions, postTypeformSubmission } from '../core/api';
import { useQueryClient } from '@tanstack/react-query';
import QuizIcon from '@/icons/quiz.svg?react';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
}

export const FF = 'vs-4306-datev-quiz';

export default function DatevQuizModal({ isModalOpened, closeModal }: Props) {
  const user = useAppSelector(userSelector);
  const { t, i18n } = useTranslation();

  const queryClient = useQueryClient();

  return (
    <FeatureUnderDevelopmentModal
      className='!mx-auto max-w-[450px]'
      icon={<QuizIcon className='-mb-4' />}
      content={{
        heading:
          'This feature is under development. Take a quiz to launch it faster',
        description:
          'This will help us to better address your needs by sharing your expectations and requirements.',
        hint: '2 questions • 1 min',
      }}
      closeModal={closeModal}
      isModalOpened={isModalOpened}
      btn={
        <PopupButton
          id={i18n.language === 'en' ? 'BdPJJiKn' : 'b6s3iOyx'}
          size={80}
          hidden={{
            email: user?.Email || '',
            user_id: String(user?.Id) || '',
          }}
          onSubmit={(event) => {
            postTypeformSubmission({
              response_id: event.responseId,
              form_id: event.formId,
              feature_flag: FF,
            })
              .then(() => {
                return getTypeformSubmissions(user?.Id);
              })
              .then((res) => {
                queryClient.setQueryData(
                  ['UserTypeformSubmissions', user?.Id],
                  res || []
                );
                closeModal();
              })
              .catch(console.error);
          }}
          onClose={closeModal}
          className='Button'
        >
          {t("Let's go")}
        </PopupButton>
      }
    />
  );
}
