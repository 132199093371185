import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import Button from '../Button';
import { usePaymentOverdueModal } from '../PaymentInstructionModal/usePaymentOverdueModal';

export default function PaymentOverdueModal() {
  const { t } = useTranslation();
  const { isModalOpened, closeModal } = usePaymentOverdueModal();

  return (
    <Modal
      isOpened={isModalOpened}
      close={closeModal}
      title=''
      name='PaymentOverdue'
    >
      <div className='px-8 pb-6 pt-4'>
        <h2 className='mb-4 text-h3 font-black'>
          {t(
            'Your account has been limited due to outstanding service payments'
          )}
        </h2>
        <div className='mb-6 max-w-[480px]'>
          <p className='pb-3'>
            {t(
              'Please make the outstanding payment(s) immediately and contact your account manager at'
            )}{' '}
            <a
              href={`mailto:vat@countx.com?subject=${t(
                'payment_overdue_problems'
              )}`}
              className='underline'
            >
              vat@countx.com
            </a>{' '}
            <span>{t('as soon as possible')}</span>
          </p>
          <p>
            {t(
              'Otherwise, we will not be able to prepare your monthly VAT filing, which may result in further fees and penalties'
            )}
          </p>
        </div>

        <div className='mt-2 flex flex-wrap gap-x-2'>
          <div>
            <Button
              className='flex !w-[178px] items-center justify-center'
              type='submit'
              data-testid='submit-note-modal-btn'
              onClick={closeModal}
            >
              <span>{t('I understand')}</span>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
