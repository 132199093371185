import { normalizeDateFE } from '@/core/normalization';
import { SellerFilingReportStatusEnum } from '@/core/enums';
import { NormalizedSellerFilingReport } from '@/core/types';
import { subDays } from 'date-fns';

export const hasBOP = (r: NormalizedSellerFilingReport) =>
  r.files?.some((f) => f.option === 'oss-csv-bop');

export const isShouldSubmitOSSReport = (r: NormalizedSellerFilingReport) => {
  return (
    r.type === 'oss' &&
    !r.payment_instruction?.cancelled &&
    hasBOP(r) &&
    r.status !== SellerFilingReportStatusEnum.Submitted &&
    r.status !== SellerFilingReportStatusEnum.Paid
  );
};

export const isReportPrepared = (report: NormalizedSellerFilingReport) =>
  hasBOP(report) &&
  !!report.payment_instruction &&
  !report.payment_instruction?.cancelled &&
  [
    SellerFilingReportStatusEnum.Prepared,
    SellerFilingReportStatusEnum.SentToAdvisor,
    SellerFilingReportStatusEnum.InstructionSent,
    SellerFilingReportStatusEnum.ConfirmationSent,
  ].includes(report.status);

export const isReportSubmitted = (report: NormalizedSellerFilingReport) =>
  hasBOP(report) &&
  !!report.payment_instruction &&
  report.status === SellerFilingReportStatusEnum.Submitted;

export const isReportPaid = (report: NormalizedSellerFilingReport) =>
  hasBOP(report) &&
  (report.payment_instruction?.confirmed ||
    report.status === SellerFilingReportStatusEnum.Paid);

export enum TaskForEnum {
  countx = 'countx',
  client = 'client',
  taxAdvisor = 'taxAdvisor',
}

export function createOSSSeSeDueDate(
  r: NormalizedSellerFilingReport,
  subDaysCount = 2
) {
  const dueDate = normalizeDateFE(
    r.payment_instruction?.due_date || r.deadline_date
  );

  return subDays(dueDate, subDaysCount);
}
