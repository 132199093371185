import AmazonIcon from '@/icons/amazon-circle.svg?react';

import AmazonIntegrations from './AmazonIntegrations';
import IntegrationsConnectButton from '../IntegrationsConnectButton';
import { AmazonIntegration } from '@/core/types';
import { useTranslation } from 'react-i18next';
import { AuthedRoutesEnum } from '@/core/enums';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  onClickUpdate: (id: string | undefined) => () => void;
  integrations: AmazonIntegration[] | undefined;
}

export const AmazonIntegrationWidget = ({
  onClickUpdate,
  integrations,
}: Props) => {
  const { t } = useTranslation();
  const { vs5973CpIntegrationsPage } = useFlags();
  return (
    <>
      <div className='flex items-center justify-between'>
        <div
          className='flex items-center font-bold'
          data-testid='integrations-amazon'
        >
          <AmazonIcon className='mr-2 flex-shrink-0' />
          <span>Amazon</span>
        </div>
        <IntegrationsConnectButton
          testId='amazon-integration-connect-btn'
          onClick={onClickUpdate(undefined)}
        />
      </div>
      {vs5973CpIntegrationsPage &&
        location.pathname === AuthedRoutesEnum.Integrations && (
          <div className='pt-2 font-semibold'>
            {t('Connected seller accounts')}
          </div>
        )}
      {integrations && (
        <AmazonIntegrations
          items={integrations}
          onClickUpdate={onClickUpdate}
        />
      )}
    </>
  );
};
