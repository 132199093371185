import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import './BillbeeTokenModal.scss';
import BillbeeIcon from '@/icons/billbee.svg';
import LinkIcon from '@/icons/link.svg?react';
import Input from '../Input/Input';
import { FormikErrors, useFormik } from 'formik';
import Button from '../Button';
import { useEffect, useState } from 'react';
import MiniLoader from '../MiniLoader/MiniLoader';
import { getBillbee, postBillbeeCredentials } from '@/core/api';
import { useAppSelector } from '../../hooks';
import {
  activeCompanySelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useNotification from '../../hooks/useNotification';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  isTokenValid?: boolean;
}

interface FormValues {
  username: string;
  password: string;
}

export default function BillbeeTokenModal({
  isModalOpened,
  closeModal,
  isTokenValid,
}: Props) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const activeCompany = useAppSelector(activeCompanySelector);
  const [isError, setIsError] = useState(false);
  const { showNotification } = useNotification();
  const queryKey = useAppSelector(queryKeySelector);

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      username: '',
      password: '',
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.username) {
        errors.username = t('Required')!;
      }
      if (!values.password) {
        errors.password = t('Required')!;
      }

      return errors;
    },
    onSubmit: async (values) => {
      setIsError(false);
      setIsLoading(true);
      try {
        await postBillbeeCredentials(
          values.username,
          values.password,
          activeCompany?.company_id
        );

        showNotification({
          type: 'success',
          text: 'Billbee connected',
        });

        try {
          const res = await getBillbee(activeCompany?.company_id);
          queryClient.setQueryData(['SellerBillbee', queryKey], res);
          closeModal();
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        if ((err as AxiosError).response?.status === 400) {
          setIsError(true);
        }
      }
    },
  });

  useEffect(() => {
    if (isModalOpened) {
      formik.resetForm();
      setIsError(false);
    }
    // eslint-disable-next-line
  }, [isModalOpened]);

  return (
    <Modal
      isOpened={isModalOpened}
      close={closeModal}
      title={t('Billbee integration')}
      name='token-modal'
      isLoading={isLoading}
    >
      <div className='mx-4 px-4 pb-6 pt-4'>
        <div className='mb-4 flex items-center justify-between'>
          <div
            className='flex w-full items-center text-h4 font-extrabold'
            data-testid='integrations-billbee'
          >
            <img
              src={BillbeeIcon}
              alt='billbee'
              style={{
                width: 40,
                height: 40,
              }}
              className='mr-2 flex-shrink-0'
            />
            <span>{t('Billbee integration')}</span>
          </div>
          <div className='flex w-full justify-end'>
            <a
              className='Button--outline inline-flex items-center'
              href='https://vat.countx.com/knowledge/billbee-mit-countx-verbinden'
              target='_blank'
              rel='noopener noreferrer'
            >
              <LinkIcon />
              <span>{t('Open instruction')}</span>
            </a>
          </div>
        </div>
        <div>
          {isError && (
            <div
              className='EasybillTokenModal__badge Text--red mb-4 p-2 text-center'
              data-testid='EasybillTokenModal-badge'
            >
              {t('Username or password is wrong')}
            </div>
          )}
        </div>
        <form onSubmit={formik.handleSubmit}>
          {!isTokenValid && (
            <div className='EasybillTokenModal__badge Text--red mb-4 p-2 text-center'>
              {t('Invalid Billbee API key')}
            </div>
          )}
          <Input
            name='username'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
            error={formik.errors.username}
            touched={formik.touched.username}
            wrapperClassName='Input--BillbeeTokenModal'
            disabled={isLoading}
            placeholder={t('Billbee username')!}
          />
          <Input
            name='password'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.errors.password}
            touched={formik.touched.password}
            wrapperClassName='Input--BillbeeTokenModal'
            disabled={isLoading}
            placeholder={t('Api password')!}
            type='password'
          />
          <div className='flex flex-wrap gap-x-2'>
            <div>
              <Button
                type='submit'
                disabled={isLoading}
                className='flex items-center'
              >
                {isLoading && <MiniLoader className='mr-2' color='gray' />}
                <span>{t('Connect')}</span>
              </Button>
            </div>
            <div>
              <Button
                styling='outline'
                onClick={closeModal}
                type='button'
                disabled={isLoading}
              >
                {t('Cancel')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
