import { useEffect, useState } from 'react';

export function useMedia(
  queries: string[],
  values: boolean[] | number[] | string[],
  defaultValue: boolean | number | string
) {
  const mediaQueryLists = queries.map((q) => window.matchMedia(q));
  const getValue = () => {
    const index = mediaQueryLists.findIndex((mql) => mql?.matches);
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  };
  const [value, setValue] = useState(getValue);
  useEffect(() => {
    const handler = () => setValue(getValue);
    mediaQueryLists.forEach((mql) => mql?.addListener(handler));
    return () => mediaQueryLists.forEach((mql) => mql?.removeListener(handler));
    // eslint-disable-next-line
  }, []);
  return value;
}
