import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks';
import {
  activeCompanySelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';
import { getCompletedFiling, getOngoingFiling } from '@/core/api';
import { useMemo } from 'react';
import Shimmer from '@/components/Shimmer/Shimmer';
import TaxAdvisorTasks from '@/components/TaxAdvisorTasks';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { AuthedRoutesEnum } from '@/core/enums';
import { OSSSeSeReportPreview } from '@/components/OSSSelfServiceWidget/OSSSeSeReportPreview';
import {
  isReportPaid,
  isReportPrepared,
  isReportSubmitted,
} from '@/components/OpenTasks/OpenTasks.helpers';
import { getCurrentOSSSeSeReport } from '../OSSFiles.helpers';

export const TaxAdvisorDashboard = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const activeCompany = useAppSelector(activeCompanySelector);
  const queryKey = useAppSelector(queryKeySelector);

  const [ongoingFiling, completedFiling] = useQueries({
    queries: [
      {
        queryKey: ['SellerOngoingFiling', queryKey],
        queryFn: () => getOngoingFiling(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerPastPeriodsFiling', queryKey],
        queryFn: () => getCompletedFiling(activeCompany?.company_id),
      },
    ],
  });

  const ongoingFilingData = ongoingFiling.data;
  const completedFilingData = completedFiling.data;

  const currentOSSReport = useMemo(() => {
    return getCurrentOSSSeSeReport([
      ...(ongoingFilingData || []),
      ...(completedFilingData || []),
    ]);
  }, [ongoingFilingData, completedFilingData]);

  const onSubmitReport = async () => {
    const [ongoingRes, completedRes] = await Promise.all([
      getOngoingFiling(activeCompany?.company_id),
      getCompletedFiling(activeCompany?.company_id),
    ]);
    queryClient.setQueryData(['SellerOngoingFiling', queryKey], ongoingRes);
    queryClient.setQueryData(
      ['SellerPastPeriodsFiling', queryKey],
      completedRes
    );
  };

  return (
    <div className='flex-grow bg-gray-300 px-4 py-8 lg:px-6 2xl:px-14'>
      <div className='mx-auto w-full max-w-[1328px]'>
        <div className='mx-auto max-w-[712px]'>
          <div className='Text--30 mb-4 font-black'>{t('Global overview')}</div>
          {ongoingFiling.isLoading ? (
            <div>
              <Shimmer className='SellerDashboard__loading mb-6' />
            </div>
          ) : (
            currentOSSReport && (
              <OSSSeSeReportPreview
                report={currentOSSReport}
                link={{ to: AuthedRoutesEnum.OSSFiles }}
              />
            )
          )}
          {ongoingFiling.isLoading || completedFiling.isLoading ? (
            <div>
              <Shimmer className='SellerDashboard__loading mb-6' />
            </div>
          ) : (
            <TaxAdvisorTasks
              isOSSSelfService={true}
              OSSReports={[
                ...(ongoingFilingData || []),
                ...(completedFilingData || []),
              ].filter(
                (r) =>
                  r.type === 'oss' &&
                  !r.payment_instruction?.cancelled &&
                  (isReportPrepared(r) ||
                    isReportSubmitted(r) ||
                    isReportPaid(r))
              )}
              onSubmitReport={onSubmitReport}
            />
          )}
        </div>
      </div>
    </div>
  );
};
