import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '../Button';
import { TodoOSSSeSeItem } from '../OSSSeSeSteps/OSSSeSeSteps';
import ShevronLeftIcon from '@/icons/chevron-right.svg?react';
import { ControlledCollapse } from '../Collapse/ControlledCollapse';

interface OSSSeSeOpenTaskProps {
  task: TodoOSSSeSeItem;
  step: number;
}

export const OSSSeSeOpenTask = ({ task, step }: OSSSeSeOpenTaskProps) => {
  const { t, i18n } = useTranslation();

  const title = (
    <div className='flex'>
      <div
        className={classNames(
          'mr-2 flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border !bg-white font-semibold !text-dark-green',
          { '!text-gray-700': task.inactive }
        )}
      >
        {step}
      </div>
      <div className='flex flex-grow flex-col'>
        <div
          className={classNames('font-semibold', {
            '!text-gray-700': task.inactive,
          })}
        >
          {t(task.label)}
        </div>
        <div className='text-gray-700'>
          {typeof task.description === 'string'
            ? t(task.description)
            : task.description}
        </div>
      </div>
    </div>
  );

  return (
    <div className='px-4 py-2'>
      {task.collapseContent ? (
        <ControlledCollapse
          triggerRenderer={(isOpened, toggleCollapse) => (
            <div
              className='flex cursor-pointer items-start justify-between'
              onClick={toggleCollapse}
            >
              {title}
              <div className='flex gap-x-1'>
                <div className='font-semibold'>Details</div>
                <button
                  className={classNames('flex transition-transform', {
                    'rotate-90': isOpened,
                  })}
                >
                  <ShevronLeftIcon className='m-auto' />
                </button>
              </div>
            </div>
          )}
          contentRenderer={() => (
            <div className='pt-4'>{task.collapseContent}</div>
          )}
        />
      ) : (
        <div className='flex justify-between gap-x-4'>
          {title}
          {task.btn && (
            <div
              className={`flex-shrink-0 text-end ${
                i18n.language === 'de' ? 'min-w-[215px]' : 'min-w-[180px]'
              }`}
            >
              {typeof task.btn.action === 'string' ? (
                <Link
                  className={classNames(
                    'Button Button--sm OpenTask__btn flex !w-full items-center gap-x-2 text-center',
                    task.btn.className || '',
                    {
                      'hover:!text-white':
                        task.btn.styling !== 'outline' && !task.btn?.disabled,
                      'Button--outline': task.btn.styling,
                      'Button--disabled cursor-default rounded-lg !border-gray-300 !bg-gray-70 font-semibold !text-gray-700 hover:bg-gray-70 hover:!text-gray-700':
                        task.btn?.disabled,
                    }
                  )}
                  onClick={(e) => {
                    if (task.btn?.disabled) {
                      e.preventDefault();
                    }
                  }}
                  to={task.btn.action}
                  target='_blank'
                >
                  {task.btn.icon}
                  <span>{task.btn.label && t(task.btn.label)}</span>
                </Link>
              ) : typeof task.btn.action === 'function' ? (
                <Button
                  styling={task.btn.styling ? task.btn.styling : 'primary'}
                  size='sm'
                  onClick={task.btn.action}
                  disabled={task.btn?.disabled}
                  className={`${
                    i18n.language === 'de' ? 'min-w-[215px]' : 'min-w-[180px]'
                  } ${task.btn.className || ''}`}
                >
                  {task.btn.icon}
                  <span>{task.btn.label && t(task.btn.label)}</span>
                </Button>
              ) : (
                task.btn.action
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
