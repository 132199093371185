import { useEffect } from 'react';
import { loadScript } from '@/core/helpers';
import Modal from '../Modal/Modal';
import './BookMeetingModal.scss';
import { useBookMeetingModal } from './useBookMeetingModal';

export default function BookMeetingModal() {
  const { isModalOpened, closeModal, link } = useBookMeetingModal();

  useEffect(() => {
    if (!isModalOpened) {
      const script = document.querySelector('script#meeting');
      if (script) {
        script.parentNode?.removeChild(script);
      }
    } else {
      const body = document.querySelector('body');
      loadScript(
        {
          src: 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js',
          id: 'meeting',
        },
        body
      );
    }
  }, [isModalOpened]);

  return (
    <Modal
      isOpened={isModalOpened}
      close={closeModal}
      title=''
      name='note'
      className='BookMeetingModal'
    >
      <div className='meetings-iframe-container' data-src={link} />
    </Modal>
  );
}
