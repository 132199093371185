import { AnimationEventHandler, useRef } from 'react';
import ErrorIcon from '@/icons/warning.svg?react';
import TipIcon from '@/icons/tip.svg?react';
import './Notification.scss';
import {
  removeNotification,
  Notification as INotification,
  hideNotification,
} from '@/core/store/notificationsSlice';
import { useAppDispatch } from '../../hooks';
import classNames from 'classnames';
import MiniLoader from '../MiniLoader/MiniLoader';
import { useTranslation } from 'react-i18next';
export const DEFAULT_ERROR = 'Something went wrong, please try again';

interface Props {
  data: INotification;
}

const INTERVAL = 3000;

const Icons = {
  success: <TipIcon className='mr-2' />,
  loading: (
    <MiniLoader className='MiniLoader--Notification mr-2' color='white' />
  ),

  error: <ErrorIcon className='Notification__icon mr-2' />,
};

export default function Notification({ data }: Props) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const onAnimationEnd: AnimationEventHandler = (e) => {
    const notificationsBlock = ref.current?.parentElement;
    if (e.animationName === 'fadeIn' && data.state === 'auto-hide') {
      setTimeout(() => {
        dispatch(hideNotification({ id: data.id }));
      }, data.interval || INTERVAL);
    }
    if (e.animationName === 'fadeOut') {
      if (notificationsBlock) {
        notificationsBlock.style.transition = '';
        notificationsBlock.style.transform = `translate3d(0, 0, 0)`;
      }
      dispatch(removeNotification(data.id));
    }
  };

  const onAnimationStart: AnimationEventHandler = (e) => {
    if (e.animationName === 'fadeOut') {
      const notificationsBlock = ref.current?.parentElement;
      if (notificationsBlock) {
        notificationsBlock.style.transition = 'transform 0.5s ease-out';
        notificationsBlock.style.transform = `translate3d(0, ${
          ref.current.offsetHeight + 32
        }px, 0)`;
      }
    }
  };

  return (
    <div
      className={classNames(
        'Notification animation-forwards mb-4 flex items-start break-words px-2 py-1',
        {
          fadeIn: data.state === 'auto-hide' || data.state === 'open',
          fadeOut: data.state === 'hide',
          'Notification--error': data.type === 'error',
        }
      )}
      ref={ref}
      onAnimationEnd={onAnimationEnd}
      onAnimationStart={onAnimationStart}
    >
      {Icons[data.type]}
      <span className='break-words'>
        {data.type === 'error' && !data.text ? t(DEFAULT_ERROR) : t(data.text)}
      </span>
    </div>
  );
}
