import { formatDate, getDateFormate } from '@/core/helpers';
import CountryLabel from '@/components/CountryLabel/CountryLabel';
import { useTranslation } from 'react-i18next';
import { ReportPeriod } from '@/core/types';
import { normalizeDateFE } from '@/core/normalization';
import classNames from 'classnames';

interface CalendarPoint {
  date: Date;
  countries: {
    name: string;
    period: ReportPeriod;
  }[];
}

export interface CalendarItem {
  items: CalendarPoint[];
  month: Date;
}

interface Props {
  data: CalendarItem;
  className?: string;
}

export default function SellerCalendar({
  data,
  className = '',
  ...rest
}: Props) {
  const { t, i18n } = useTranslation();

  const testId = ({ ...rest } as any)['data-testid'];

  return (
    <div className={`${className}`} {...rest}>
      <div
        className='grid grid-cols-2 font-semibold'
        data-testid={testId ? `${testId}-vat-calendar-month` : undefined}
      >
        <span>{formatDate(data.month, 'MMMM yyyy')}</span>
        <span>{t('Report period')}</span>
      </div>
      <div>
        {data.items
          .sort((a, b) => a.date.getTime() - b.date.getTime())
          .map((p, i) => (
            <div className='space-y-2 py-2' key={i}>
              {p.countries.map((item, n) => (
                <div className='grid grid-cols-2' key={n}>
                  <div className='flex items-start'>
                    <div
                      className={classNames(' shrink-0', {
                        'w-[40px]': i18n.language === 'en',
                        'w-[30px]': i18n.language === 'de',
                      })}
                      data-testid={
                        n === 0 && testId
                          ? `${testId}-vat-calendar-item-${i + 1}-date`
                          : undefined
                      }
                    >
                      {n === 0 && t(`${formatDate(p.date, 'do')}`)}
                    </div>
                    <CountryLabel
                      short
                      country={item.name}
                      data-testid={
                        testId
                          ? `${testId}-vat-calendar-item-${i + 1}-country-${
                              n + 1
                            }`
                          : undefined
                      }
                    />
                  </div>
                  <div className='flex items-center'>
                    {formatDate(
                      normalizeDateFE(item.period.from_date),
                      getDateFormate(item.period.type)
                    )}{' '}
                    {item.name === 'IT' &&
                      item.period.type !== 'quarter' &&
                      t('payment')}{' '}
                    {item.period.type === 'year' && t('Annual report')}
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
}
