import { useTranslation } from 'react-i18next';
import { formatDate } from '@/core/helpers';
import Button from '../Button';
import DatevItem, { DatevFile } from './DatevItem';
import { PropsWithChildren, useMemo } from 'react';
import { differenceInCalendarMonths, isSameMonth, isSameYear } from 'date-fns';
import FolderIcon from '@/icons/folder-green-xl.svg?react';
import OverviewIcon from '@/icons/assesment.svg?react';
import { useAppSelector, useTabs } from '../../hooks';
import './Datev.scss';
import { normalizeDateFE } from '@/core/normalization';
import { AuthedRoutesEnum } from '@/core/enums';
import { Link, useLocation } from 'react-router-dom';
import { DatevAggregatedItem } from '@/core/types';
import {
  activeCompanySelector,
  userSelector,
} from '@/core/store/user/userSlice';
import DatevSettingsBlock, { FF } from './DatevSettingsBlock';
import { DatevEntryTypesMapping } from './Datev.helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from '@tanstack/react-query';
import { getTypeformSubmissions } from '@/core/api';
import { Loader } from '../Loader/Loader';

export interface Props {
  files: DatevFile[];
  titleClassName?: string;
  companyId: string;
  displaySinceISO?: string;
  aggregatedDatev?: DatevAggregatedItem[];
  className?: string;
}

export default function Datev({
  files,
  titleClassName = '',
  children,
  companyId,
  displaySinceISO,
  aggregatedDatev,
  className,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const location = useLocation();
  const activeCompany = useAppSelector(activeCompanySelector);
  const user = useAppSelector(userSelector);

  const { vs5524CpDatevSettingsFakedoor } = useFlags();

  const { data: typeformSubmissions, isLoading: isLoadingTypeformSubmissions } =
    useQuery({
      queryKey: ['UserTypeformSubmissions', user?.Id],
      queryFn: () => getTypeformSubmissions(user!.Id),
    });

  const canSubmitTypeform =
    vs5524CpDatevSettingsFakedoor &&
    !(typeformSubmissions || []).find((s) => s.feature_flag === FF);

  const BEDates = useMemo(() => {
    return (files || [])
      .map((r) => {
        const d = normalizeDateFE(r.service_date);
        return d;
      })
      .filter((date, i, self) => {
        return (
          self.findIndex((d) => isSameMonth(d, date) && isSameYear(d, date)) ===
          i
        );
      })
      .sort((a, b) => {
        return b.getTime() - a.getTime();
      });
  }, [files]);

  const years = (BEDates || [])
    .filter((date, i, self) => {
      return self.findIndex((d) => isSameYear(d, date)) === i;
    })
    .map((d) => d.getFullYear());

  const dates = useMemo(() => {
    return years
      .map((v) =>
        Array(12)
          .fill(v)
          .map((v, i) => new Date(v, i))
      )
      .flat()
      .filter((d) => {
        const currentYear = new Date().getFullYear();
        const targetYear = +d.getFullYear();

        return (
          !!displaySinceISO &&
          differenceInCalendarMonths(d, normalizeDateFE(displaySinceISO)) >=
            0 &&
          isSameYear(targetYear, currentYear) &&
          differenceInCalendarMonths(new Date(), d) > 0
        );
      })
      .sort((a, b) => {
        return b.getTime() - a.getTime();
      });
  }, [years, displaySinceISO]);

  const tabs = ['All years', ...years];

  const { activeTab, onClickTab } = useTabs(tabs[0]);

  const data = useMemo(() => {
    return dates.map((d) => {
      const counts = (aggregatedDatev || [])
        .filter(
          (ad) => ad.month === d.getMonth() + 1 && ad.year === d.getFullYear()
        )
        .reduce(
          (
            acc: {
              [key: number]: number;
            },
            item
          ) => {
            acc[item.entry_type] = item.count;

            return acc;
          },
          {}
        );

      const items = (files || []).filter((r) => {
        const date = normalizeDateFE(r.service_date);
        return isSameMonth(d, date) && isSameYear(d, date);
      });

      return {
        month: d,
        items,
        counts,
      };
    });
  }, [dates, files, aggregatedDatev]);

  if (!data.length)
    return (
      <div
        className={`mx-auto flex h-full w-full max-w-[874px] flex-grow flex-col rounded-lg bg-white p-4 ${className}`}
      >
        {children}
        <div className={`text-h3 font-black ${titleClassName}`}>
          {t('DATEV files')}
        </div>
        <div className='flex flex-grow flex-col items-center justify-center'>
          <FolderIcon />
          <div className='my-2 text-h4 font-extrabold'>{t('No files yet')}</div>
          <div className='whitespace-pre-line text-center'>
            {t(
              'Once we generate the first files, they will be available here.'
            )}
          </div>
        </div>
      </div>
    );

  const params = new URLSearchParams(location.search);
  params.set('company_id', companyId || activeCompany!.company_id);

  if (isLoadingTypeformSubmissions) return <Loader />;

  return (
    <div
      className={`mx-auto flex w-full max-w-[874px] flex-grow flex-col rounded-lg bg-white p-4 ${className}`}
    >
      {children}
      <div className='flex items-start justify-between'>
        <div className={`text-h3 font-black ${titleClassName}`}>
          {t('DATEV files')}
        </div>
        {tabs.length > 2 && (
          <div className='flex items-center gap-2'>
            {tabs.map((v: string | number) => (
              <Button
                key={v}
                size='sm'
                styling={activeTab !== v ? 'outline' : undefined}
                onClick={onClickTab(v)}
              >
                {typeof v === 'string' ? t(v) : v}
              </Button>
            ))}
          </div>
        )}
      </div>
      <DatevSettingsBlock canSubmitTypeform={canSubmitTypeform} />
      <div>
        {data
          .filter((d) => {
            if (activeTab === 'All years') return true;
            return d.month.getFullYear() === activeTab;
          })
          .map((d, i) => {
            if (!!d.items.length) {
              return (
                <div className='Datev__item' key={i}>
                  <div className='Datev__grid grid gap-12 pt-2'>
                    <div className='font-extrabold'>
                      {formatDate(d.month, 'MMMM yyyy')}
                    </div>
                    <div className='text-end text-gray-700'>
                      {t('Transactions')}
                    </div>
                    <div className='text-gray-700'>{t('Generated')}</div>
                    <div />
                  </div>
                  {d.items.map((f) => {
                    return (
                      <DatevItem
                        file={f}
                        key={f.id}
                        companyId={companyId}
                        count={d.counts[DatevEntryTypesMapping[f.option]]}
                        period={d.month}
                      />
                    );
                  })}
                  {!!Object.keys(d.counts).length && (
                    <Link
                      className='Button--outline mt-2 block w-full text-center'
                      to={{
                        pathname: AuthedRoutesEnum.DatevOverview.replace(
                          ':year',
                          String(d.month.getFullYear())
                        ).replace(':month', String(d.month.getMonth() + 1)),
                        search: params.toString(),
                      }}
                    >
                      <OverviewIcon className='pr-2' />
                      <span>{t('Transactions overview')}</span>
                    </Link>
                  )}
                </div>
              );
            }

            return (
              <div className='Datev__item' key={i}>
                <div className='mb-3 pt-2 font-extrabold'>
                  {formatDate(d.month, 'MMMM yyyy')}
                </div>
                <div className='flex items-center gap-x-2'>
                  <FolderIcon className='h-8 w-8' />
                  <span className='font-semibold'>
                    {t('No files for this period')}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
