import classNames from 'classnames';
import PolygonIcon from '@/icons/select-chevron.svg?react';
import ArrowLeft from '@/icons/chevron-left-lg.svg?react';
import ArrowRight from '@/icons/chevron-right-lg.svg?react';
import './PeriodsDropdown.scss';
import Dropdown from '../Dropdown/Dropdown';
import { formatDate } from '@/core/helpers';
import { PeriodTypeEnum } from '@/core/types';
import { BtnStyling } from '../Button';

export interface Period {
  id: string;
  value: number;
  type: PeriodTypeEnum;
  year: number;
}

interface Props {
  className?: string;
  disabled?: boolean;
  selectedYear: number;
  name: string;
  onClickPrev: () => void;
  onClickNext: () => void;
  periods: Period[];
  period: Period | undefined;
  onChange: (p: Period) => void;
  styling?: BtnStyling;
  controls?: boolean;
}

const renderLabel = (p: Period, withYear = false, withFullMonth = true) => {
  const label =
    p.type === PeriodTypeEnum.month
      ? formatDate(
          new Date(p.year, p.value, 1),
          `${withFullMonth ? 'MMMM' : 'MMM'}`
        )
      : `Q${p.value}`;

  return withYear ? `${label} ${p.year}` : label;
};

export function PeriodsDropdown({
  className = '',
  disabled,
  name,
  selectedYear,
  onClickPrev,
  onClickNext,
  onChange,
  periods,
  period,
  controls = true,
  styling,
}: Props) {
  return (
    <Dropdown
      data-testid={name}
      className={`PeriodsDropdown ${className}`}
      isDisabled={disabled}
      trigger={
        <>
          <span
            className={classNames('font-semibold', {
              Select__value: !!period,
              'Title--sm !font-extrabold': styling === 'naked',
            })}
          >
            {period ? renderLabel(period, true) : 'period'}
          </span>
          <PolygonIcon className='PeriodsDropdown__arrow ml-2' />
        </>
      }
      content={
        <>
          <div
            className='PeriodsDropdown__header flex items-center justify-between py-1 pl-4 pr-2'
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <span className='periods-year'>{selectedYear}</span>
            {controls && (
              <div className='flex items-center'>
                <button
                  onClick={onClickPrev}
                  className='PeriodsDropdown__btn flex items-center justify-center'
                  data-testid='periods-btn-next'
                >
                  <ArrowLeft />
                </button>
                <button
                  onClick={onClickNext}
                  className='PeriodsDropdown__btn flex items-center justify-center'
                  data-testid='periods-btn-prev'
                >
                  <ArrowRight />
                </button>
              </div>
            )}
          </div>
          <div className='MiniScrollbar max-h-[400px] overflow-auto'>
            {periods
              .filter((p) => p.year === selectedYear)
              .sort((a, b) => {
                const aValue =
                  a.type === PeriodTypeEnum.quarter
                    ? a.value * 3 + 1
                    : a.value + 1;
                const bValue =
                  b.type === PeriodTypeEnum.quarter
                    ? b.value * 3 + 1
                    : b.value + 1;
                return bValue - aValue;
              })
              .map((p, i) => (
                <div
                  key={`${p.type}-${p.value}`}
                  data-testid={`${p.type}-${p.value}`}
                  style={{ position: 'relative', zIndex: 1000 }}
                  className={classNames(
                    'PeriodsDropdown__dropdownItem text-start',
                    {
                      'PeriodsDropdown__dropdownItem--active':
                        period && period.id === p.id,
                      'border-t border-gray-500':
                        p.type === PeriodTypeEnum.quarter && i !== 0,
                    }
                  )}
                  onClick={() => onChange(p)}
                >
                  {renderLabel(p)}
                </div>
              ))}
          </div>
        </>
      }
      styling={styling}
    />
  );
}
