import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InvoiceDocument, PagingInfo, PeriodTypeEnum } from '@/core/types';
import Pagination from '@/components/Pagination/Pagination';
import { Loader } from '@/components/Loader/Loader';
import { normalizeDateFE } from '@/core/normalization';
import FolderIcon from '@/icons/folder-green-xl.svg?react';
import FilterIcon from '@/icons/filter.svg?react';
import classNames from 'classnames';
import { Table } from '@/components/Table/Table';
import {
  capitalize,
  downloadFile,
  formatDate,
  formatNumber,
} from '@/core/helpers';
import { APIEventsEnum, useAppSelector, useSendEvent } from '../../../hooks';
import {
  activeCompanySelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';
import {
  getDataUnitsStats,
  getInvoiceDocuments,
  patchInvoiceDocuments,
  postDownloadDataUnitsErrors,
  postInvoiceDocumentSuggestionsCode,
} from '@/core/api';
import { usePeriods } from '../../InvoiceBook/usePeriods';
import {
  Period,
  PeriodsDropdown,
} from '@/components/PeriodsDropdown/PeriodsDropdown';
import { SourceTile } from './SourceTile';
import Button from '@/components/Button';
import UploadIcon from '@/icons/upload_arrow.svg?react';
import TipIcon from '@/icons/checkmark-circle.svg?react';
import { SourceIcon } from '@/components/SourceIcon';
import Tooltip from '@/components/Tooltip/Tooltip';
import CrossIcon from '@/icons/red-cross.svg?react';
import {
  ERRORS_TO_RESOLVE,
  ErrorsMappingDE,
  ErrorsMappingEN,
} from './SellerDocuments.helpers';
import FileUploader from '@/components/FileUploader/FileUploader';
import MiniLoader from '@/components/MiniLoader/MiniLoader';
import { useMU } from '../../../context/useMU';
import { ACCEPTED_EXTS } from '@/components/ManualDataUploader/ManualUploader.helpers';
import InfoIcon from '@/icons/info-outline.svg?react';
import AlertIcon from '@/icons/alert-orange-circle.svg?react';
import { useModal } from '@/components/Modal/useModal';
import ExportFileConfirmModal from './ExportFileConfirmModal';
import { format, isSameMonth, lastDayOfMonth } from 'date-fns';
import SellerInstructionWidget from '@/components/SellerInstructionWidget/SellerInstructionWidget';
import { CanceledError } from 'axios';
import {
  SpecificErrorSelectModal,
  SpecificFilterState,
} from './SpecificErrorSelectModal';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import EditInvoiceModal, { PatchInvoiceDocument } from './EditInvoiceModal';
import Hint from '@/components/Tooltip/Hint';
import useNotification from '@/hooks/useNotification';
import { useQueryClient } from '@tanstack/react-query';
import { ResolvingErrorOptionsModal } from './ResolvingErrorOptionsModal';
import { Badge } from '@/components/Badge/Badge';
import { SellerDocumentsDisclaimer } from '@/components/SellerDocumentsDisclaimer/SellerDocumentsDisclaimer';

export type FilterType = 'error' | 'warning' | 'all';

const EDITABLE_CELL_HINT_KEY = 'editable_cell_hint_shown';

export const SellerDocuments = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const queryKey = useAppSelector(queryKeySelector);

  const abortControllerRef = useRef(new AbortController());
  const activeCompany = useAppSelector(activeCompanySelector);
  const ref = useRef<HTMLDivElement>(null);

  const { showNotification } = useNotification();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = +(searchParams.get('page') || 1);
  const year = searchParams.get('year');
  const month = searchParams.get('month');
  const companyId = searchParams.get('company_id') || activeCompany?.company_id;
  const filter = searchParams.get('filter') || undefined;

  const { vs5882CpEditableCellsInDocuments, vs6030CpResolveSuggestionsModal } =
    useFlags();

  const search = location.search;
  const errorCode = useMemo(() => {
    const qs = new URLSearchParams(search);
    return qs.getAll('error_code') || [];
  }, [search]);
  const warningCode = useMemo(() => {
    const qs = new URLSearchParams(search);
    return qs.getAll('warning_code') || [];
  }, [search]);

  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [paging, setPaging] = useState<PagingInfo | undefined>();
  const [items, setItems] = useState<InvoiceDocument[] | undefined>();
  const { sendEvent } = useSendEvent();

  const {
    dataUnits,
    sendFilesOnSelect,
    isUploadingFiles,
    uploadedFiles,
    dataUnitsStats,
    isLoadingDataUntisStats,
    isLoadingUploadedFiles,
    isRefetchingDataUnitsStats,
  } = useMU();

  const [isExportingFile, setIsExportingFile] = useState(false);

  const [activeRowId, setActiveRowId] = useState<number | undefined>();

  const invoicesMap = useMemo(() => {
    return (items || []).reduce(
      (acc, item) => {
        acc[item.id] = item;
        return acc;
      },
      {} as Record<number, InvoiceDocument>
    );
  }, [items]);

  const activeRow = activeRowId ? invoicesMap[activeRowId] : undefined;

  const { isModalOpened, closeModal, openModal } = useModal();
  const {
    isModalOpened: isSpecificErrorSelectModalOpened,
    closeModal: closeSpecificErrorSelectModal,
    openModal: openSpecificErrorSelectModal,
  } = useModal();

  const {
    isModalOpened: isEditInvoiceModalOpened,
    closeModal: closeEditInvoiceModal,
    openModal: openEditInvoiceModal,
  } = useModal();

  const {
    isModalOpened: isResolvingErrorsOptionsModalOpened,
    closeModal: closeResolvingErrorsOptionsModal,
    openModal: openResolvingErrorsOptionsModal,
  } = useModal();

  const closeEditableCellHint = () => {
    setIsEditableCellHintOpened(false);
    localStorage.setItem(EDITABLE_CELL_HINT_KEY, JSON.stringify(true));
  };

  const onClickEditableCell = (id: number) => () => {
    setActiveRowId(id);
    openEditInvoiceModal();
    closeEditableCellHint();
  };

  const onClickResolveButton = (id: number) => () => {
    setActiveRowId(id);
    openResolvingErrorsOptionsModal();
  };

  const isEditable = (row: InvoiceDocument) => {
    return (
      vs5882CpEditableCellsInDocuments &&
      !row.turnover_id &&
      !!row.errors.length
    );
  };

  const { periods: allPeriods, years } = usePeriods(2022, false);

  const periods = useMemo(() => {
    return allPeriods.filter((p) => p.type === PeriodTypeEnum.month);
  }, [allPeriods]);

  const [selectedYear, setSelectedYear] = useState<number>(
    year ? +year : new Date().getFullYear()
  );
  const [selectedPeriod, setSelectedPeriod] = useState<Period | undefined>(
    year && month
      ? periods.find((p) => p.year === +year && p.value === +month)
      : undefined
  );

  const [isEditableCellHintOpened, setIsEditableCellHintOpened] =
    useState<boolean>(() => {
      const v = localStorage.getItem(EDITABLE_CELL_HINT_KEY);
      return v !== null ? !JSON.parse(v) : true;
    });

  const { t, i18n } = useTranslation();

  const onPageClick = (n: number) => {
    n === 1 ? searchParams.delete('page') : searchParams.set('page', String(n));
    setSearchParams(searchParams, {
      replace: true,
    });
  };

  const currStats = useMemo(() => {
    return dataUnitsStats?.find((s) =>
      isSameMonth(
        normalizeDateFE(s.date),
        new Date(selectedPeriod!.year, selectedPeriod!.value, 1)
      )
    );
  }, [selectedPeriod, dataUnitsStats]);

  useEffect(() => {
    let newFilter = filter?.split(',') || [];
    if (newFilter.length > 0 && currStats?.errors_count === 0) {
      newFilter = newFilter.filter((v) => v !== 'error');
      searchParams.delete('error_code');
    }

    if (newFilter.length > 0 && currStats?.warnings_count === 0) {
      newFilter = newFilter.filter((v) => v !== 'warning');
      searchParams.delete('warning_code');
    }

    const newFilterStr = newFilter.join(',');
    if (newFilter.length > 0) {
      searchParams.set('filter', newFilterStr);
    } else {
      searchParams.delete('filter');
    }

    if (filter !== newFilterStr) {
      searchParams.delete('page');
    }

    setSearchParams(searchParams);
    // eslint-disable-next-line
  }, [currStats]);

  const isProcessingFiles = useMemo(() => {
    return (
      isUploadingFiles ||
      uploadedFiles.filter((f) => !!f.update_progress).length > 0
    );
  }, [isUploadingFiles, uploadedFiles]);

  useEffect(() => {
    if (companyId && selectedPeriod) {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      setIsLoadingInvoices(true);
      getInvoiceDocuments(
        companyId,
        selectedPeriod.year,
        selectedPeriod.value + 1,
        filter,
        errorCode,
        warningCode,
        currentPage,
        abortControllerRef.current
      )
        .then((res) => {
          setItems(res.data);
          setPaging(res.paging);
          setIsLoadingInvoices(false);
        })
        .catch((e) => {
          if (!(e instanceof CanceledError)) {
            setItems(undefined);
            setPaging(undefined);
            setIsLoadingInvoices(false);
          }
        });
    }
    // eslint-disable-next-line
  }, [
    companyId,
    currentPage,
    selectedPeriod,
    filter,
    isProcessingFiles,
    errorCode,
    warningCode,
  ]);

  useEffect(() => {
    sendEvent(APIEventsEnum.view_document_tab, {
      block: 'customer opened documents tab',
    });
    // eslint-disable-next-line
  }, []);

  const handleClickFilter = (v: FilterType) => {
    if (advancedFilterBtnActive) {
      searchParams.delete('filter');
      searchParams.delete('error_code');
      searchParams.delete('warning_code');
    }
    let currentFilters = searchParams.get('filter')?.split(',') || [];
    if (currentFilters.includes(v)) {
      currentFilters = currentFilters.filter((filter) => filter !== v);
    } else {
      currentFilters = [v];
      if (v === 'all') {
        searchParams.delete('filter');
      }
    }

    searchParams.delete('page');

    if (currentFilters.filter((f) => f !== 'all').length > 0) {
      searchParams.set('filter', currentFilters.join(','));
    } else {
      searchParams.delete('filter');
    }

    setSearchParams(searchParams);
  };

  const tableLoader = isProcessingFiles ? (
    <div className='absolute z-10 h-full w-full bg-white/30 backdrop-blur-sm'></div>
  ) : (
    (isLoadingInvoices || isRefetchingDataUnitsStats) &&
    !isProcessingFiles && <Loader className='!absolute !z-10' />
  );

  const renderTable = () => {
    if (!items && isLoadingInvoices)
      return (
        <div className='flex flex-grow flex-col'>
          <Loader className='!static w-full grow' />
        </div>
      );

    if (items === undefined) return null;

    if (items === null || items.length === 0)
      return (
        <div className='relative flex flex-grow flex-col overflow-hidden pb-1 pt-2'>
          {tableLoader}
          <div className='mx-auto flex max-w-[570px] grow flex-col items-center justify-center '>
            <FolderIcon />
            <div className='my-2 text-h4 font-extrabold'>
              {t('No data found')}
            </div>
            <div className='flex flex-col whitespace-pre-line text-center'>
              <span>
                {t(`We haven't found data for the selected period or country.`)}
              </span>
              <span>
                {t(
                  'Please select another period or country or make sure that the data has already been provided for the selected one.'
                )}
              </span>
            </div>
          </div>
        </div>
      );

    const ErrorsMapping =
      i18n.language === 'en' ? ErrorsMappingEN : ErrorsMappingDE;

    const firstInvoiceWithErrorId = items.find(
      (i) => i.errors.length > 0 && !i.turnover_id
    )?.id;

    return (
      <div className='relative flex flex-grow flex-col overflow-hidden pb-1 pt-2'>
        {tableLoader}
        <div
          className='MiniScrollbar relative mx-auto w-full flex-grow overflow-auto'
          ref={ref}
        >
          <Table<InvoiceDocument>
            className={classNames(
              'Table--SellerDocuments absolute left-0 top-0 mx-auto h-full w-fit min-w-full px-14'
            )}
            columns={[
              {
                label: t('Included into filing'),
                field: 'errors',
                cellClassName: 'rounded-s-lg border-s border-s-white',
                renderer: (row) => {
                  const hasCriticalErrors = row.errors.some(
                    (e) => e.severity === 'ERROR'
                  );

                  const criticalErrors = row.errors.filter(
                    (e) => e.severity === 'ERROR'
                  );

                  const warnings = row.errors.filter(
                    (e) => e.severity === 'WARNING'
                  );

                  const isResolveButtonShown = row.errors.some((e) =>
                    ERRORS_TO_RESOLVE.includes(e.code)
                  );

                  const sortedErrors = row.errors.sort((a, b) => {
                    if (a.severity === 'ERROR' && b.severity === 'WARNING') {
                      return -1;
                    }
                    if (a.severity === 'WARNING' && b.severity === 'ERROR') {
                      return 1;
                    }
                    return 0;
                  });

                  return (
                    <div
                      className={classNames(
                        'flex h-full max-w-md items-center',
                        {
                          'w-[480px]':
                            !!row.errors.length && i18n.language === 'en',
                          'w-[430px]':
                            !!row.errors.length && i18n.language === 'de',
                        }
                      )}
                    >
                      {!row.errors.length ? (
                        <div className='flex items-center gap-x-1 font-semibold text-dark'>
                          <TipIcon className='shrink-0' />
                          <span>{t('Yes')}</span>
                        </div>
                      ) : (
                        <Tooltip
                          content={
                            <div className='max-w-[274px] divide-y divide-dark'>
                              <div className='flex gap-x-2 pb-2 font-semibold'>
                                {!!criticalErrors.length ? (
                                  <>
                                    <div className='grow text-red-500'>
                                      {t(
                                        'This invoice will not be included in the filing'
                                      )}
                                    </div>
                                    <CrossIcon className='h-4 w-4 shrink-0' />
                                  </>
                                ) : !!warnings.length ? (
                                  <>
                                    <div className='grow'>
                                      {t(
                                        'This invoice will be included in the filing'
                                      )}
                                    </div>
                                    <TipIcon className='h-4 w-4 shrink-0 [&>circle]:fill-green' />
                                  </>
                                ) : null}
                              </div>
                              {!!criticalErrors.length && (
                                <div>
                                  <div className='pt-1 font-semibold'>
                                    {t('Errors')}
                                  </div>
                                  <ol className='ml-4 list-decimal space-y-2 divide-y pb-2 '>
                                    {criticalErrors.map((e, i) => (
                                      <li
                                        className='whitespace-pre-line pt-2'
                                        key={i}
                                      >
                                        {ErrorsMapping[e.code]}
                                      </li>
                                    ))}
                                  </ol>
                                </div>
                              )}
                              {!!warnings.length && (
                                <div>
                                  <div className='pt-1 font-semibold'>
                                    {t('Notices')}
                                  </div>
                                  <ol className='ml-4 list-decimal space-y-2 divide-y pb-2 '>
                                    {warnings.map((e, i) => (
                                      <li
                                        className='whitespace-pre-line pt-2'
                                        key={i}
                                      >
                                        {ErrorsMapping[e.code]}
                                      </li>
                                    ))}
                                  </ol>
                                </div>
                              )}
                            </div>
                          }
                          styling='white'
                          className='!whitespace-normal'
                          position='top-right'
                        >
                          <div
                            className={classNames(
                              '-mt-px flex items-center gap-x-2 rounded-lg px-2 font-semibold text-white transition-colors hover:border-dark'
                            )}
                          >
                            {hasCriticalErrors ? (
                              <div className='flex min-w-[42px] items-center gap-x-1 font-semibold text-red-500'>
                                <CrossIcon className='h-3 w-3 shrink-0' />
                                <span>{t('No')}</span>
                              </div>
                            ) : (
                              <div className='flex items-center gap-x-1 font-semibold text-dark'>
                                <TipIcon className='shrink-0' />
                                <span>{t('Yes')}</span>
                              </div>
                            )}
                            <div
                              className={classNames(
                                'grid grid-cols-[minmax(0,2fr),60px] px-2'
                              )}
                            >
                              <span
                                className={classNames('truncate text-dark', {
                                  'text-orange-500':
                                    !hasCriticalErrors && !isResolveButtonShown,
                                  'text-red-500':
                                    hasCriticalErrors && !isResolveButtonShown,
                                })}
                              >
                                {sortedErrors
                                  .map((e) => ErrorsMapping[e.code])
                                  .join(', ')}
                              </span>
                              {isResolveButtonShown &&
                                vs6030CpResolveSuggestionsModal && (
                                  <Button
                                    styling='naked'
                                    className='!font-semibold !text-blue-500'
                                    onClick={onClickResolveButton(row.id)}
                                    disabled={
                                      row.id === undefined || row.id === null
                                    }
                                    data-testid={`resolve-button-${row.id}`}
                                  >
                                    {t('Resolve')}
                                  </Button>
                                )}
                            </div>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  );
                },
              },
              {
                label: (
                  <div className='flex items-center gap-x-1'>
                    <div>{t('Report')}</div>
                    <Tooltip
                      delay={250}
                      content={t(
                        'The report to which the document will be submitted'
                      )}
                    >
                      <InfoIcon className='w-[16px] [&>path]:fill-gray-700' />
                    </Tooltip>
                  </div>
                ),
                field: 'vat_country',
                renderer: (row) => {
                  return (
                    <div className='flex h-full items-center gap-x-2'>
                      {typeof row.vat_country === 'string'
                        ? row.vat_country
                        : row.vat_country?.short_name}
                      {row.is_oss && (
                        <Tooltip
                          content={t('Will be included into OSS report')}
                          styling='white'
                        >
                          <div className='rounded-full bg-[#63B7961F] px-[10px] py-1'>
                            {t('OSS')}
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  );
                },
              },
              {
                label: t('Source'),
                field: 'source_type',
                renderer: (row) => (
                  <div className='flex h-full items-center gap-x-1.5 truncate'>
                    {row.source_type && (
                      <>
                        <SourceIcon source={row.source_type} />
                        <span>{capitalize(row.source_type)}</span>
                      </>
                    )}
                  </div>
                ),
              },
              {
                label: (
                  <div className='flex items-center gap-x-1'>
                    <div>{t('Type')}</div>
                    <Tooltip
                      className='!max-w-[385px] !whitespace-normal'
                      delay={250}
                      content={
                        <div className='space-y-4'>
                          <div>
                            <div>{t('Invoice')}:</div>
                            <div>
                              {t(
                                'A document requesting payment for goods or services provided.'
                              )}
                            </div>
                          </div>
                          <div>
                            <div>{t('Storno')}:</div>
                            <div>
                              {t('A cancellation of a transaction or invoice.')}
                            </div>
                          </div>
                          <div>
                            <div>{t('Storno-credit')}:</div>
                            <div>
                              {t(
                                'A credit issued due to the cancellation of a previous invoice.'
                              )}
                            </div>
                          </div>
                          <div>
                            <div>{t('Credit')}:</div>
                            <div>
                              {t(
                                'A document issued to reduce the amount owed by a customer, often due to returns or overpayments.'
                              )}
                            </div>
                          </div>
                        </div>
                      }
                    >
                      <InfoIcon className='w-[16px] [&>path]:fill-gray-700' />
                    </Tooltip>
                  </div>
                ),
                field: 'type',
                renderer: (row) => (
                  <div className='flex h-full items-center gap-x-2'>
                    {row.type}
                  </div>
                ),
              },
              {
                label: t('Departure country'),
                field: 'departure_country',
                renderer: (row) => (
                  <div className='flex h-full items-center'>
                    {isEditable(row) ? (
                      <Button
                        styling='outline'
                        onClick={onClickEditableCell(row.id)}
                        className='min-h-[34px] min-w-[36px] !bg-[#FFFFFF70] !px-2 !py-1 !font-normal'
                      >
                        {typeof row.departure_country === 'string'
                          ? row.departure_country
                          : row.departure_country?.short_name}
                      </Button>
                    ) : (
                      <span className='px-2 py-1'>
                        {typeof row.departure_country === 'string'
                          ? row.departure_country
                          : row.departure_country?.short_name}
                      </span>
                    )}
                  </div>
                ),
              },
              {
                label: t('Arrival country'),
                field: 'arrival_country',
                renderer: (row) => (
                  <div className='flex h-full items-center'>
                    {isEditable(row) ? (
                      <Button
                        styling='outline'
                        onClick={onClickEditableCell(row.id)}
                        className='min-h-[34px] min-w-[36px] !bg-[#FFFFFF70] !px-2 !py-1 !font-normal'
                      >
                        {typeof row.arrival_country === 'string'
                          ? row.arrival_country
                          : row.arrival_country?.short_name}
                      </Button>
                    ) : (
                      <span className='px-2 py-1'>
                        {typeof row.arrival_country === 'string'
                          ? row.arrival_country
                          : row.arrival_country?.short_name}
                      </span>
                    )}
                  </div>
                ),
              },
              {
                className: 'justify-end',
                label: t('VAT rate'),
                field: 'vat_rate',
                renderer: (row) => (
                  <div className='flex h-full items-center justify-end'>
                    {isEditable(row) ? (
                      <Button
                        styling='outline'
                        onClick={onClickEditableCell(row.id)}
                        className='min-h-[34px] min-w-[48px] !bg-[#FFFFFF70] !px-2 !py-1 text-end !font-normal'
                      >
                        {row.vat_rate}
                        {row.vat_rate !== undefined && '%'}
                      </Button>
                    ) : (
                      <span className='px-2 py-1'>
                        {row.vat_rate}
                        {row.vat_rate !== undefined && '%'}
                      </span>
                    )}
                  </div>
                ),
              },
              {
                className: 'justify-end',
                label: t('VAT amount'),
                field: 'vat_amount',
                renderer: (row) => {
                  const btn = (
                    <Button
                      styling='outline'
                      onClick={onClickEditableCell(row.id)}
                      className='min-h-[34px] min-w-[50px] !bg-[#FFFFFF70] !px-2 !py-1 text-end !font-normal'
                    >
                      {row.vat_amount !== undefined &&
                        formatNumber(row.vat_amount / 100, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </Button>
                  );

                  return (
                    <div className='flex h-full items-center justify-end'>
                      {isEditable(row) ? (
                        vs5882CpEditableCellsInDocuments &&
                        row.id === firstInvoiceWithErrorId ? (
                          <Hint
                            isOpened={
                              isEditableCellHintOpened &&
                              !(
                                isLoadingDataUntisStats &&
                                isLoadingUploadedFiles
                              )
                            }
                            content={
                              <div className='w-[285px] px-3 py-4 text-start text-dark'>
                                <div className='text-sm font-semibold text-green'>
                                  {t('New function').toUpperCase()}
                                </div>
                                <h4 className='whitespace-normal pb-1 text-h4 font-extrabold leading-[26px]'>
                                  {t('Click to correct invoices')}
                                </h4>
                                <div className='whitespace-pre-line pb-4 text-base tracking-tight'>
                                  {t(
                                    'Now you can edit invoice parameters directly in the interface by clicking on the highlighted editable values.'
                                  )}
                                </div>
                                <Button
                                  styling='outline'
                                  className='!w-full'
                                  onClick={closeEditableCellHint}
                                >
                                  {t('Got it')}
                                </Button>
                              </div>
                            }
                            styling='white'
                            className='!z-20 !rounded-2xl'
                          >
                            {btn}
                          </Hint>
                        ) : (
                          btn
                        )
                      ) : (
                        <span className='px-2 py-1'>
                          {row.vat_amount !== undefined &&
                            formatNumber(row.vat_amount / 100, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </span>
                      )}
                    </div>
                  );
                },
              },
              {
                className: '!justify-end',
                label: t('Netto'),
                field: 'net_amount',
                renderer: (row) => (
                  <div className='flex h-full items-center justify-end'>
                    {isEditable(row) ? (
                      <Button
                        styling='outline'
                        onClick={onClickEditableCell(row.id)}
                        className='min-h-[34px] min-w-[60px] !bg-[#FFFFFF70] !px-2 !py-1 text-end !font-normal'
                      >
                        {row.net_amount !== undefined &&
                          formatNumber(row.net_amount / 100, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Button>
                    ) : (
                      <span className='px-2 py-1'>
                        {row.net_amount !== undefined &&
                          formatNumber(row.net_amount / 100, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </span>
                    )}
                  </div>
                ),
              },
              {
                className: 'justify-end',
                label: t('Brutto'),
                field: 'brutto_amount',
                renderer: (row) => {
                  return (
                    <div className='flex h-full items-center justify-end'>
                      {vs5882CpEditableCellsInDocuments &&
                      !!row.errors.length &&
                      !row.turnover_id ? (
                        <Button
                          styling='outline'
                          onClick={onClickEditableCell(row.id)}
                          className='min-h-[34px] min-w-[60px] !bg-[#FFFFFF70] !px-2 !py-1 text-end !font-normal'
                        >
                          {row.brutto_amount !== undefined &&
                            formatNumber(row.brutto_amount / 100, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </Button>
                      ) : (
                        <span className='px-2 py-1'>
                          {row.brutto_amount !== undefined &&
                            formatNumber(row.brutto_amount / 100, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </span>
                      )}
                    </div>
                  );
                },
              },
              {
                label: t('Currency'),
                field: 'currency',
                renderer: (row) => (
                  <div className='flex h-full items-center'>{row.currency}</div>
                ),
              },
              {
                label: t('Document date'),
                field: 'document_date',
                renderer: (row) => (
                  <div className='flex h-full items-center truncate'>
                    {row.document_date &&
                      formatDate(
                        normalizeDateFE(row.document_date),
                        'dd.MM.yyyy'
                      )}
                  </div>
                ),
              },
              {
                label: t('Channel'),
                field: 'channel',
                renderer: (row) => (
                  <div className='flex h-full min-w-[80px] items-center'>
                    {row.channel}
                  </div>
                ),
              },
              {
                label: t('Buyer VAT number'),
                field: 'b2b_buyer_vat_number',
                renderer: (row) => (
                  <div className='flex h-full items-center truncate'>
                    {isEditable(row) ? (
                      !!row.b2b_buyer_vat_number ? (
                        <Button
                          styling='outline'
                          onClick={onClickEditableCell(row.id)}
                          className='!bg-[#FFFFFF70] !px-2 !py-1 !font-normal'
                        >
                          {row.b2b_buyer_vat_number}
                        </Button>
                      ) : null
                    ) : (
                      <span className='px-2 py-1'>
                        {row.b2b_buyer_vat_number}
                      </span>
                    )}
                  </div>
                ),
              },
              {
                label: t('Invoice number'),
                field: 'invoice_number',
                cellClassName: 'rounded-e-lg border-e border-e-white',
                renderer: (row) => (
                  <div className='flex h-full items-center truncate'>
                    {row.invoice_number}
                  </div>
                ),
              },
            ]}
            data={items}
            renderCustomRow={(row, columns, i) => {
              const hasErrors = row.errors.some((e) => e.severity === 'ERROR');
              const hasWarnings = row.errors.some(
                (e) => e.severity === 'WARNING'
              );

              return (
                <tr
                  className='Table__row group'
                  key={row.id}
                  data-testid={`row-${row.id}`}
                  data-index={i}
                >
                  {columns.map((c) => (
                    <td
                      key={c.field}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                      className={classNames(c.cellClassName, {
                        'border-y border-y-white bg-[#fc11111a] group-hover:border-red-500 group-hover:!bg-[#fc11111a]':
                          hasErrors,
                        'border-y border-y-white bg-[#FFEFE0] group-hover:border-orange-500 group-hover:!bg-[#FFEFE0]':
                          !hasErrors && hasWarnings,
                        'border-y border-y-white': !hasErrors && !hasWarnings,
                      })}
                    >
                      <div
                        className='Table__cell'
                        data-testid={`row-${i}-${String(c.field)}`}
                      >
                        {c.renderer(row, i)}
                      </div>
                    </td>
                  ))}
                </tr>
              );
            }}
          />
        </div>
        <div
          style={{
            minHeight: 48,
          }}
          className='flex items-center justify-center'
        >
          {paging && paging?.total_pages > 1 ? (
            <Pagination
              current={currentPage}
              pages={paging.total_pages}
              onPageClick={onPageClick}
            />
          ) : null}
        </div>
        <SellerInstructionWidget />
      </div>
    );
  };

  const onClickPrev = () => {
    setSelectedYear((prev) => {
      const index = years.indexOf(prev!);
      if (index === 0) {
        return years[years.length - 1];
      }
      return years[index - 1];
    });
  };

  const onClickNext = () => {
    setSelectedYear((prev) => {
      const index = years.indexOf(prev!);
      if (index === years.length - 1) {
        return years[0];
      } else {
        return years[index + 1];
      }
    });
  };

  const currentUnit = dataUnits?.find((u) => {
    if (!month || !year) return false;
    const date = normalizeDateFE(u.date);
    return date.getFullYear() === +year && date.getMonth() === +month;
  });

  const language = i18n.language;
  const dateFrom = currentUnit?.date
    ? format(new Date(currentUnit?.date), 'yyyy-MM-dd')
    : null;
  const dateTo = dateFrom
    ? format(lastDayOfMonth(new Date(dateFrom)), 'yyyy-MM-dd')
    : null;

  const handleGenerate = async (
    setIsLoadingInvoices: (v: boolean) => void,
    filter: string
  ) => {
    if (!dateFrom || !dateTo || !language) return;
    setIsLoadingInvoices(true);
    setIsExportingFile(true);
    sendEvent(APIEventsEnum.download_errors_file_form_documents_tab, {
      block:
        'customer clicked download errors file button on the Documents tab',
    });
    postDownloadDataUnitsErrors(dateFrom, dateTo, filter, language, companyId)
      .then((res) => {
        downloadFile(res, res.split('/').slice(-1)[0]);
        setIsLoadingInvoices(false);
        setIsExportingFile(false);
        closeModal();
      })
      .catch(() => {
        setIsLoadingInvoices(false);
        setIsExportingFile(false);
      });
  };
  const advancedFilterBtnActive =
    (filter && filter?.split(',').length > 1) ||
    !!errorCode.length ||
    !!warningCode.length;

  const handleApplyFilter = (s: SpecificFilterState) => {
    const qs = new URLSearchParams(search);
    qs.delete('filter');
    qs.delete('warning_code');
    qs.delete('error_code');
    qs.delete('page');

    const errorsCodesArr = Object.entries(s.error);
    const warningsCodesArr = Object.entries(s.warning);

    const hasErrorsCodes = Object.values(s.error).some((v) => v === true);
    const hasWarningsCodes = Object.values(s.warning).some((v) => v === true);
    const isAllErrosFilterActive = Object.values(s.error).every(
      (v) => v === true
    );
    const isAllWarningsFilterActive = Object.values(s.warning).every(
      (v) => v === true
    );

    let filters = qs.get('filter')?.split(',') || [];
    if (hasErrorsCodes) {
      filters.push('error');
    } else {
      filters = filters.filter((f) => f !== 'error');
    }
    if (hasWarningsCodes) {
      filters.push('warning');
    } else {
      filters = filters.filter((f) => f !== 'warning');
    }

    let warningCodes: string[] = [];
    let errorCodes: string[] = [];
    if (!isAllErrosFilterActive) {
      errorCodes = [
        ...errorCodes,
        ...errorsCodesArr.filter(([, v]) => v).map(([k]) => k),
      ];
    }

    if (!isAllWarningsFilterActive) {
      warningCodes = [
        ...warningCodes,
        ...warningsCodesArr.filter(([, v]) => v).map(([k]) => k),
      ];
    }

    if (filters.length > 0) {
      qs.set('filter', filters.join(','));
    }

    if (errorCodes.length > 0) {
      errorCodes.forEach((v) => {
        qs.append('error_code', v);
      });
    }

    if (warningCodes.length > 0) {
      warningCodes.forEach((v) => {
        qs.append('warning_code', v);
      });
    }

    setSearchParams(qs, {
      replace: true,
      state: location.state,
    });
    closeSpecificErrorSelectModal();
  };

  if (isLoadingDataUntisStats || isLoadingUploadedFiles) return <Loader />;

  return (
    <div className='flex w-full max-w-[1990px] flex-grow flex-col '>
      <div className='flex flex-grow flex-col rounded bg-white'>
        <div className='border-b px-14 py-4'>
          <div className='space-y-2'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-x-1'>
                <span className='Title--sm font-extrabold'>
                  {t('Documents')}
                </span>
                {selectedPeriod && (
                  <>
                    <span className='Title--sm font-extrabold'>{t('for')}</span>
                    <PeriodsDropdown
                      name='invoice-book-periods-select'
                      period={selectedPeriod}
                      selectedYear={selectedYear}
                      onClickNext={onClickNext}
                      onClickPrev={onClickPrev}
                      periods={periods}
                      styling='naked'
                      onChange={(p: Period) => {
                        setSelectedPeriod(p);
                        searchParams.set('year', String(p.year));
                        searchParams.set('month', String(p.value));
                        searchParams.delete('page');
                        setSearchParams(searchParams);
                      }}
                      disabled={isLoadingInvoices}
                    />
                  </>
                )}
              </div>
              <div className='flex items-start'>
                {currentUnit?.items.length && (
                  <div
                    className={classNames('flex items-center gap-x-2', {
                      'opacity-60': isProcessingFiles,
                    })}
                  >
                    {currentUnit?.items.map((item) => (
                      <SourceTile data={item} key={item.id} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          {currStats &&
          (currStats.errors_count > 0 || currStats.warnings_count > 0) ? (
            <>
              <div
                className={classNames(
                  'mt-4 flex flex-nowrap items-start justify-between gap-x-4 rounded-2xl border px-[14px] py-[6px]',
                  {
                    'bg-red-100': !!currStats?.errors_count,
                  }
                )}
              >
                <div className='flex gap-x-1'>
                  <AlertIcon className='h-6 w-6 shrink-0' />
                  <div>
                    {currStats?.errors_count ? (
                      <>
                        <p className='font-bold'>
                          {t(
                            'This file has errors that need to be resolved before proceeding'
                          )}
                        </p>
                        <p>
                          {t('Export them and correct')}{' '}
                          {t(
                            'Upload the file with the corrected documents back'
                          )}
                        </p>
                      </>
                    ) : (
                      <>
                        <p className='font-bold'>
                          {t(
                            'This file has non-critical issues that nice-to-fix'
                          )}
                        </p>
                        <p>
                          {t('You can export them and correct')}{' '}
                          {t(
                            'Upload the file with the corrected documents back'
                          )}
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <div className='flex shrink-0 items-start gap-x-2'>
                  <Button
                    onClick={openModal}
                    className='flex shrink-0 items-center gap-x-2 !py-1'
                  >
                    {isExportingFile && <MiniLoader className='h-6 w-6' />}
                    <span>
                      {t(
                        isExportingFile
                          ? 'Exporting file'
                          : !!currStats?.errors_count
                            ? 'Download errors'
                            : 'Download notices'
                      )}
                    </span>
                  </Button>
                  <div className='ml-auto'>
                    {isProcessingFiles ? (
                      <Button
                        styling='outline'
                        className='flex shrink-0 items-center gap-x-2 !py-1'
                      >
                        <MiniLoader className='h-6 w-6' />
                        <span>{t('Upload corrections')}</span>
                      </Button>
                    ) : (
                      <FileUploader
                        onSelect={(e) => {
                          sendEvent(
                            APIEventsEnum.files_uploaded_from_document_tab,
                            {
                              block:
                                'customer uploaded files from the Documents tab',
                            }
                          );
                          sendFilesOnSelect(e);
                        }}
                        name='manual-upload-file'
                        accept={ACCEPTED_EXTS}
                        className='Button--outline flex shrink-0 cursor-pointer items-center gap-x-2 !py-1'
                      >
                        <UploadIcon />
                        <span>{t('Upload corrections')}</span>
                      </FileUploader>
                    )}
                  </div>
                </div>
              </div>
              <div className='mt-4 flex items-center justify-between'>
                <div className='flex flex-shrink-0 items-center gap-x-2'>
                  {[
                    {
                      value: currStats?.documents_count,
                      label: 'All documents: *num*',
                      onClick: () => handleClickFilter('all'),
                      id: 'all',
                    },
                    {
                      value: currStats?.errors_count,
                      label: 'Errors: *num*',
                      onClick: () => handleClickFilter('error'),
                      className: 'bg-red-500',
                      id: 'error',
                    },
                    {
                      value: currStats?.warnings_count,
                      label: 'Notices: *num*',
                      onClick: () => handleClickFilter('warning'),
                      className: 'bg-orange-500',
                      id: 'warning',
                    },
                  ].map((f) => {
                    if (!f.value) {
                      return null;
                    }

                    return (
                      <React.Fragment key={f.id}>
                        <Button
                          size='sm'
                          className={classNames(
                            'Button--outline flex cursor-pointer items-center gap-x-1 !rounded-full border !px-3',
                            {
                              '!border-dark !bg-dark !text-white':
                                !advancedFilterBtnActive &&
                                ((f.id && filter?.includes(f.id)) ||
                                  (f.id === 'all' && !filter)),
                            }
                          )}
                          onClick={f.onClick}
                          data-testid={`filter-${f.id}`}
                        >
                          {(f.id === 'error' || f.id === 'warning') && (
                            <div
                              className={`h-4 w-4 rounded-full ${f.className}`}
                            />
                          )}
                          {t(f.label).replace('*num*', formatNumber(f.value))}
                        </Button>
                        {f.id === 'all' && <div className='h-6 border-r'></div>}
                      </React.Fragment>
                    );
                  })}
                  <Button
                    size='sm'
                    data-testid='advanced-filter-btn'
                    className={classNames(
                      'Button--outline flex cursor-pointer items-center gap-x-1 !rounded-full border !px-3',
                      {
                        '!border-dark !bg-dark !text-white':
                          advancedFilterBtnActive,
                      }
                    )}
                    onClick={() => openSpecificErrorSelectModal()}
                  >
                    <FilterIcon
                      className={`${
                        advancedFilterBtnActive ? '[&>path]:fill-white' : ''
                      }`}
                    />
                    <span>{t('Advanced filter')}</span>
                    {advancedFilterBtnActive && paging?.total_rows && (
                      <span>: {paging.total_rows}</span>
                    )}
                  </Button>
                </div>
                <Tooltip
                  content={
                    <SellerDocumentsDisclaimer
                      className={`${
                        i18n.language === 'de'
                          ? 'min-w-[371px]'
                          : 'min-w-[316px]'
                      }`}
                      titleClassName='block'
                    />
                  }
                  styling='white'
                  className='!whitespace-normal !p-0'
                  wrapperClassName='block'
                  position='bottom'
                >
                  <div className='flex min-w-[277px] justify-end'>
                    <Badge
                      label={
                        <div className='flex items-center gap-x-2'>
                          <span>{t('Disclaimer and important note')}</span>
                          <InfoIcon className='h-5 w-5 shrink-0 [&>path]:fill-dark' />
                        </div>
                      }
                      className='cursor-pointer bg-gray-400'
                    />
                  </div>
                </Tooltip>
              </div>
            </>
          ) : null}
        </div>
        {renderTable()}
      </div>
      <ExportFileConfirmModal
        isModalOpened={isModalOpened}
        closeModal={closeModal}
        healthchecks={currStats?.errors_count}
        plausibilityErrors={currStats?.warnings_count}
        onGenerate={handleGenerate}
      />
      <SpecificErrorSelectModal
        isModalOpened={isSpecificErrorSelectModalOpened}
        closeModal={closeSpecificErrorSelectModal}
        onApply={handleApplyFilter}
        healthchecksCount={currStats?.errors_count}
        healthchecksList={currStats?.errors || []}
        plausibilityErrorsCount={currStats?.warnings_count}
        plausibilityErrorsList={currStats?.warnings || []}
      />
      <EditInvoiceModal
        isModalOpened={isEditInvoiceModalOpened}
        closeModal={closeEditInvoiceModal}
        row={activeRow}
        onSubmit={(
          setIsLoading: (v: boolean) => void,
          data: PatchInvoiceDocument
        ) => {
          setIsLoading(true);
          if (companyId && selectedPeriod) {
            patchInvoiceDocuments(String(activeRowId), data)
              .then(() =>
                Promise.all([
                  getInvoiceDocuments(
                    companyId,
                    selectedPeriod.year,
                    selectedPeriod.value + 1,
                    filter,
                    errorCode,
                    warningCode,
                    currentPage,
                    abortControllerRef.current
                  ),
                  getDataUnitsStats(companyId),
                ])
              )
              .then(([invoicesRes, duStatsRes]) => {
                setItems(invoicesRes.data);
                setPaging(invoicesRes.paging);
                queryClient.setQueryData(
                  ['SellerDataUnitsStats', queryKey],
                  duStatsRes
                );
                setIsLoading(false);
                closeEditInvoiceModal();

                showNotification({
                  type: 'success',
                  text: t('The document has been updated'),
                });
              })
              .catch(() => {
                setIsLoading(false);
              });
          } else {
            setIsLoading(false);
          }
        }}
      />
      <ResolvingErrorOptionsModal
        isModalOpened={isResolvingErrorsOptionsModalOpened}
        closeModal={closeResolvingErrorsOptionsModal}
        activeRow={activeRow!}
        onConfirm={(
          setIsLoading: (v: boolean) => void,
          id: string,
          code: number,
          onClickCloseModal: () => void
        ) => {
          setIsLoading(true);
          if (companyId && selectedPeriod) {
            postInvoiceDocumentSuggestionsCode(id, code)
              .then(() =>
                Promise.all([
                  getInvoiceDocuments(
                    companyId,
                    selectedPeriod.year,
                    selectedPeriod.value + 1,
                    filter,
                    errorCode,
                    warningCode,
                    currentPage,
                    abortControllerRef.current
                  ),
                  getDataUnitsStats(companyId),
                ])
              )
              .then(([invoicesRes, duStatsRes]) => {
                setItems(invoicesRes.data);
                setPaging(invoicesRes.paging);
                queryClient.setQueryData(
                  ['SellerDataUnitsStats', queryKey],
                  duStatsRes
                );
                setIsLoading(false);
                onClickCloseModal();
              })
              .catch(() => {
                setIsLoading(false);
              });
          } else {
            setIsLoading(false);
          }
        }}
      />
    </div>
  );
};
