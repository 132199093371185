import ButtonToggle from '@/components/ButtonToggle';
import PaymentIsntructionIcon from '@/icons/payment-instruction.svg?react';
import PaymentIsntructionConfirmedIcon from '@/icons/check.svg?react';
import AgendaIcon from '@/icons/agenda-white.svg?react';
import DisregardedInstructionIcon from '@/icons/disregarded-icon.svg?react';
import AgendaConfirmedIcon from '@/icons/agenda-white-submitted.svg?react';
import PaymentIsntructionMarkedAsPaidIcon from '@/icons/plus-circle.svg?react';
import NoInstructionIcon from '@/icons/no-instruction.svg?react';
import { NormalizedSellerFilingReport } from '@/core/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import Button from '@/components/Button';

interface Props {
  className: string;
  report: NormalizedSellerFilingReport;
  disabled?: boolean;
  showAgendaBtn?: boolean;
  onClick: () => void;
}

export interface BtnContext {
  label: string;
  icon: JSX.Element;
  className?: string;
  hoverToggle: boolean;
  disabled: boolean;
}

const getBtnContext = (
  report: NormalizedSellerFilingReport,
  disabled: boolean,
  showAgendaBtn: boolean,
  isFlagvs5151DisregardedInstructionOn: boolean
) => {
  const isZeroAmount = report.payment_instruction
    ? report.payment_instruction.is_null_report ||
      report.payment_instruction.amount.amount <= 0
    : false;

  const isPaymentInstructionDisregarded =
    report.payment_instruction?.cancelled &&
    isFlagvs5151DisregardedInstructionOn;

  const isInstructionWithAgenda =
    report.country.short_name === 'DE' &&
    report.type === 'local' &&
    report.payment_instruction;

  if (isPaymentInstructionDisregarded && !showAgendaBtn) {
    return {
      label: 'Disregarded',
      icon: <DisregardedInstructionIcon className='[&>g>path]:fill-gray-700' />,
      className:
        'font-semibold rounded-lg py-[7px] px-4 !bg-gray-400 !text-gray-700 !border-gray-400 hover:!border-gray-400',
      hoverToggle: true,
      disabled: false,
    };
  } else if (isInstructionWithAgenda && showAgendaBtn) {
    return report.agenda_confirmed
      ? {
          label: 'Submitted',
          icon: <AgendaConfirmedIcon />,
          className:
            'font-semibold rounded-lg py-[7px] px-4 !bg-gray-400 !text-gray-700 !border-gray-400 hover:!border-gray-400',
          hoverToggle: true,
          disabled: false,
        }
      : {
          label: 'Submit',
          icon: <AgendaIcon />,
          className: '!w-full flex items-center justify-between',
          hoverToggle: false,
          disabled: false,
        };
  } else if (
    (!!report.payment_instruction?.payment_confirmation &&
      !!report.payment_instruction?.payment_confirmation.documents.length) ||
    report.payment_instruction?.is_sepa ||
    report.payment_instruction?.is_null_report ||
    isZeroAmount
  ) {
    return {
      label: 'Completed',
      icon: (
        <PaymentIsntructionConfirmedIcon className='h-6 w-6 [&>path]:fill-gray-700' />
      ),
      className:
        'font-semibold rounded-lg py-[7px] px-4 !bg-gray-400 !text-gray-700 !border-gray-400 hover:!border-gray-400',
      hoverToggle: true,
      disabled: false,
    };
  } else if (report.payment_instruction?.confirmed) {
    return {
      label: 'Confirmation',
      icon: (
        <PaymentIsntructionMarkedAsPaidIcon className='h-6 w-6 [&>path]:fill-dark' />
      ),
      className:
        '!w-full flex items-center justify-between font-semibold rounded-lg py-[7px] px-4 !bg-white !text-dark border !border-gray-500 hover:!border-dark',
      hoverToggle: false,
      disabled: false,
    };
  } else if (report.payment_instruction) {
    return {
      label: 'Instructions',
      icon: <PaymentIsntructionIcon className='[&>path]:fill-white' />,
      className: '!w-full flex items-center justify-between',
      hoverToggle: false,
      disabled,
    };
  }

  return {
    label: 'Instructions',
    icon: <NoInstructionIcon />,
    className:
      '!w-full flex items-center justify-between font-semibold rounded-lg py-[7px] px-4 !bg-gray-400 !text-gray-700 border-gray-400 hover:border-gray-400',
    hoverToggle: false,
    disabled: true,
  };
};

export default function PaymentInstructionBtn({
  className = '',
  report,
  disabled = false,
  showAgendaBtn = false,
  ...rest
}: Props) {
  const { vs5151DisregardedInstruction } = useFlags();
  const { t } = useTranslation();

  const btnContext = getBtnContext(
    report,
    disabled,
    showAgendaBtn,
    vs5151DisregardedInstruction
  );

  return (
    <div className={className}>
      {btnContext.hoverToggle ? (
        <ButtonToggle
          context={btnContext}
          topElementClassName='rounded-lg !bg-green-100 !text-green'
          {...rest}
        >
          {t('Open')}
        </ButtonToggle>
      ) : (
        <Button
          className={btnContext.className}
          disabled={btnContext.disabled}
          {...rest}
        >
          <span>{t(btnContext.label)}</span>
          {btnContext.icon}
        </Button>
      )}
    </div>
  );
}
