import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import './AmazonTokenModal.scss';
import AmazonLogo from '@/icons/amazon.svg?react';
import { useAppSelector } from '../../hooks';
import { STATE_CONSTANT } from '../../pages/Seller/SellerDashboard';
import {
  activeCompanySelector,
  userSelector,
} from '@/core/store/user/userSlice';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  sellerId?: string;
}

export default function AmazonTokenModal({
  isModalOpened,
  closeModal,
  sellerId,
}: Props) {
  const user = useAppSelector(userSelector);
  const activeCompany = useAppSelector(activeCompanySelector);
  const { t } = useTranslation();

  const button = (
    <a
      data-testid='token-modal-btn'
      className='Text AmazonTokenModal__btn mb-6 mt-4 flex w-full items-stretch overflow-hidden p-0 no-underline'
      href={`https://sellercentral-europe.amazon.com/apps/authorize/consent?application_id=${
        import.meta.env.VITE_APP_ID
      }&state=${user?.Id}.${activeCompany?.company_id}${STATE_CONSTANT}&version=beta`}
    >
      <div className='AmazonTokenModal__icon flex items-center justify-center'>
        <AmazonLogo />
      </div>
      <div
        className='flex flex-grow items-center justify-center px-4'
        id='amazon-connect'
      >
        {t('connect_amazon_btn')}
      </div>
    </a>
  );

  return (
    <Modal
      isOpened={isModalOpened}
      close={closeModal}
      title={sellerId ? t('Update token') : t('Connect token')}
      name='token-modal'
    >
      <div className='mx-4 px-4 pb-6 pt-4'>
        <div
          style={{
            maxWidth: 504,
          }}
        >
          {sellerId ? (
            <>
              <div
                className='text-h4 font-extrabold'
                data-testid='token-modal-title'
              >
                {t('Update token')}
              </div>
              <div data-testid='token-modal-text'>
                {t(
                  'To Update *seller_id* Merchant token please login to related Amazon Seller Account.'
                ).replace('*seller_id*', sellerId)}
              </div>
            </>
          ) : (
            <>
              <div
                className='text-h4 font-extrabold'
                data-testid='token-modal-title'
              >
                {t('Connect new token')}
              </div>
              <div data-testid='token-modal-text'>
                {t(
                  'To connect token please login to related Amazon Seller Account.'
                )}
              </div>
            </>
          )}
          {button}
        </div>
      </div>
    </Modal>
  );
}
