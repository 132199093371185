import { createContext, useState, ReactNode, useEffect } from 'react';

interface SellerInstructionWidgetContextType {
  isExpanded: boolean;
  toggleIsExpanded: () => void;
}

export const SellerInstructionWidgetContextContext =
  createContext<SellerInstructionWidgetContextType>(
    {} as SellerInstructionWidgetContextType
  );

export function SellerInstructionWidgetProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [isExpanded, setIsExpanded] = useState<boolean>(() => {
    const storedState = localStorage.getItem('isInstructionExpanded');
    return storedState !== null ? JSON.parse(storedState) : true;
  });

  const toggleIsExpanded = () => {
    setIsExpanded((p) => !p);
  };

  useEffect(() => {
    localStorage.setItem('isInstructionExpanded', JSON.stringify(isExpanded));
  }, [isExpanded]);

  return (
    <SellerInstructionWidgetContextContext.Provider
      value={{
        toggleIsExpanded,
        isExpanded,
      }}
    >
      {children}
    </SellerInstructionWidgetContextContext.Provider>
  );
}
