import Switch from '@/components/Switch/Switch';
import { Select } from '@/components/Select/Select';
import { useTranslation } from 'react-i18next';
import { NormalizedEvaluation, PeriodTypeEnum } from '@/core/types';
import { formatDate, formatNumber } from '@/core/helpers';
import { PagingInfo, SelectOption, TurnoverItem } from '@/core/types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Period,
  PeriodsDropdown,
} from '@/components/PeriodsDropdown/PeriodsDropdown';
import { getEvaluation, getInvoiceBook, postEvaluation } from '@/core/api';
import DownloadIcon from '@/icons/download.svg?react';
import { usePeriods } from './usePeriods';
import { useAppSelector } from '@/hooks';
import { userSelector } from '@/core/store/user/userSlice';
import {
  endOfMonth,
  endOfQuarter,
  format,
  startOfMonth,
  startOfQuarter,
} from 'date-fns';
import { Loader } from '@/components/Loader/Loader';
import MiniLoader from '@/components/MiniLoader/MiniLoader';
import { EvaluatiionStatusEnum, ToastType } from '@/core/enums';
import { useWSMessageHandler } from '@/core/ws/useWSMessageHandler';
import { isInvoiceBookGeneratedMessage } from '@/core/ws/ws-messages';
import { normalizeEvaluation } from '@/core/normalization';
import Button from '@/components/Button';
import { useToast } from '@/context/useToast';
import { isCurrentPeriodEvaluation } from './InvoiceBook.helpers';
import useNotification from '@/hooks/useNotification';
import { useDownloadInvoiceBook } from './useDownloadInvoiceBook';
import TopProgressLoader from '@/components/TopProgressLoader';

interface Props {
  paging: PagingInfo | undefined;
  isLoading: boolean;
  selectCountryOptions: SelectOption[];
  setItems: Dispatch<SetStateAction<TurnoverItem[] | undefined>>;
  setPaging: Dispatch<SetStateAction<PagingInfo | undefined>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const InvoiceBookHeader = ({
  paging,
  isLoading,
  selectCountryOptions,
  setItems,
  setPaging,
  setIsLoading,
}: Props) => {
  const { showNotification } = useNotification();
  const user = useAppSelector(userSelector);
  const { toast } = useToast();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { onClickDownload } = useDownloadInvoiceBook();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = +(searchParams.get('page') || 1);
  const year = searchParams.get('year');
  const value = searchParams.get('value');
  const type = searchParams.get('type');
  const isOSSTransactionsOnly = searchParams.get('is_oss');
  const companyId = searchParams.get('company_id');
  const country = searchParams.get('country');

  const [isExporting, setIsExporting] = useState(false);
  const [isLoadingEvaluation, setIsLoadingEvaluation] = useState(false);

  const [selectedYear, setSelectedYear] = useState<number>(
    year ? +year : new Date().getFullYear()
  );

  const { periods, years } = usePeriods();

  const [selectedPeriod, setSelectedPeriod] = useState<Period | undefined>(
    value && type && year
      ? periods.find(
          (p) => p.type === type && p.value === +value && p.year === +year
        )
      : undefined
  );
  const [evaluation, setEvaluation] = useState<NormalizedEvaluation>();

  const onClickPrev = () => {
    setSelectedYear((prev) => {
      const index = years.indexOf(prev!);
      if (index === 0) {
        return years[years.length - 1];
      }
      return years[index - 1];
    });
  };

  const onClickNext = () => {
    setSelectedYear((prev) => {
      const index = years.indexOf(prev!);
      if (index === years.length - 1) {
        return years[0];
      } else {
        return years[index + 1];
      }
    });
  };

  useEffect(() => {
    if (companyId && selectedPeriod) {
      setIsLoading(true);
      getInvoiceBook(
        companyId,
        selectedPeriod.type,
        selectedPeriod.year,
        selectedPeriod.type === 'month'
          ? selectedPeriod.value + 1
          : selectedPeriod.value,
        isOSSTransactionsOnly ? 1 : 0,
        currentPage,
        country ? +country : undefined
      )
        .then((res) => {
          setPaging(res.paging);
          setItems(res.data);
        })
        .catch(() => {
          setPaging(undefined);
          setItems([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [companyId, selectedPeriod, isOSSTransactionsOnly, country, currentPage]);

  useEffect(
    () => {
      if (!selectedPeriod || !companyId) {
        navigate(
          { pathname: '/', search: '' },
          {
            replace: true,
          }
        );
      }
    },
    // eslint-disable-next-line
    []
  );

  const onClickExport = () => {
    if (!companyId || !selectedPeriod) return;
    setIsExporting(true);
    postEvaluation(companyId, {
      start_date: format(
        selectedPeriod.type === PeriodTypeEnum.quarter
          ? startOfQuarter(
              new Date(selectedPeriod.year, selectedPeriod.value * 3 - 3, 1)
            )
          : startOfMonth(
              new Date(selectedPeriod.year, selectedPeriod.value, 1)
            ),
        'yyyy-MM-dd'
      ),
      end_date: format(
        selectedPeriod.type === PeriodTypeEnum.quarter
          ? endOfQuarter(
              new Date(selectedPeriod.year, selectedPeriod.value * 3 - 3, 1)
            )
          : endOfMonth(new Date(selectedPeriod.year, selectedPeriod.value, 1)),
        'yyyy-MM-dd'
      ),
      external_user_uuid: user?.AmazonCognitoId!,
    })
      .then((res) => {
        if (!res) {
          return setEvaluation(undefined);
        }

        if (
          isCurrentPeriodEvaluation(res, selectedPeriod) &&
          res.status !== EvaluatiionStatusEnum.Error
        ) {
          setEvaluation(res);
        }

        const id = Date.now();
        toast({
          id,
          company_id: companyId!,
          type: ToastType.evaluation,
          context: res,
        });
      })
      .catch((err) => {
        console.error(err);
        setEvaluation(undefined);
        showNotification({
          text: 'Invoice book has not been generated',
          type: 'error',
        });
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  useWSMessageHandler(
    (msg) => {
      if (
        isInvoiceBookGeneratedMessage(msg) &&
        msg.data.company_id === companyId &&
        selectedPeriod
      ) {
        const newEvaluation = normalizeEvaluation(msg.data);
        if (isCurrentPeriodEvaluation(newEvaluation, selectedPeriod)) {
          setEvaluation(newEvaluation);
        }
      }
    },
    [companyId, selectedPeriod]
  );

  useEffect(() => {
    if (selectedPeriod && companyId) {
      setIsLoadingEvaluation(true);
      getEvaluation(companyId!, {
        start_date: format(
          selectedPeriod.type === PeriodTypeEnum.quarter
            ? startOfQuarter(
                new Date(selectedPeriod.year, selectedPeriod.value * 3 - 3, 1)
              )
            : startOfMonth(
                new Date(selectedPeriod.year, selectedPeriod.value, 1)
              ),
          'yyyy-MM-dd'
        ),
        end_date: format(
          selectedPeriod.type === PeriodTypeEnum.quarter
            ? endOfQuarter(
                new Date(selectedPeriod.year, selectedPeriod.value * 3 - 3, 1)
              )
            : endOfMonth(
                new Date(selectedPeriod.year, selectedPeriod.value, 1)
              ),
          'yyyy-MM-dd'
        ),
      })
        .then((res) => {
          setEvaluation(res || undefined);
        })
        .catch((err) => {
          setEvaluation(undefined);
          console.error(err);
        })
        .finally(() => {
          setIsLoadingEvaluation(false);
        });
    }
    // eslint-disable-next-line
  }, [companyId, selectedPeriod]);

  return (
    <div className={`${!!paging?.total_rows && 'border-b'} relative px-6 py-4`}>
      {isLoadingEvaluation && <Loader />}
      <div className='flex flex-wrap items-center justify-start gap-x-6'>
        {isExporting ||
        evaluation?.status === EvaluatiionStatusEnum.Requested ||
        evaluation?.status === EvaluatiionStatusEnum.Generating ? (
          <TopProgressLoader isLoading={true} />
        ) : null}
        <div className={`flex gap-x-2 text-h3 font-extrabold`}>
          {t('Invoice book')}
        </div>
        <div className='z-10 flex items-center gap-x-2'>
          <Select
            search
            placeholder='All countries'
            options={
              !!selectCountryOptions.length
                ? [
                    {
                      id: -1,
                      name: t('All countries'),
                    },
                    ...selectCountryOptions,
                  ]
                : []
            }
            value={country ? +country : undefined}
            onChange={(v) => {
              if (v === -1) {
                searchParams.delete('country');
              } else {
                searchParams.set('country', String(v));
              }
              searchParams.delete('page');
              setSearchParams(searchParams);
            }}
            className='Select--InvoiceBook !min-w-[144px] !pb-0'
            disabled={isLoading}
          />
          <PeriodsDropdown
            name='invoice-book-periods-select'
            period={selectedPeriod}
            selectedYear={selectedYear}
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
            periods={periods}
            onChange={(p: Period) => {
              searchParams.delete('page');
              searchParams.set('year', String(p.year));
              searchParams.set('type', String(p.type));
              searchParams.set('value', String(p.value));
              setSearchParams(searchParams);
              setSelectedPeriod(p);
            }}
            disabled={isLoading}
          />
          {!!paging?.total_oss_rows && (
            <div className='ml-2 flex items-center gap-x-2'>
              <span>{t('OSS transactions only')}</span>
              <Switch
                checked={!!isOSSTransactionsOnly}
                onChange={(e) => {
                  if (e.target.checked) {
                    searchParams.set('is_oss', '1');
                  } else {
                    searchParams.delete('is_oss');
                  }
                  searchParams.delete('page');
                  setSearchParams(searchParams);
                }}
                size='sm'
              />
            </div>
          )}
        </div>
      </div>
      <div className='mt-6 flex flex-wrap items-center justify-between gap-x-4 gap-y-2'>
        <div className='flex flex-wrap justify-end gap-x-4'>
          {!isLoading && (
            <>
              {!!paging?.total_rows && (
                <div className='flex items-center'>
                  <span className='text-gray-700'>{t('Transactions')}</span>
                  &nbsp;
                  <span>{formatNumber(paging.total_rows)}</span>
                </div>
              )}
              {!!paging?.total_oss_rows && (
                <div className='flex items-center'>
                  <span className='text-gray-700'>{t('OSS transactions')}</span>
                  &nbsp;
                  <span>{formatNumber(paging?.total_oss_rows)}</span>
                </div>
              )}
              {!!paging?.total_net_amount && (
                <div className='flex items-center'>
                  <span className='text-gray-700'>{t('Netto')}</span>
                  &nbsp;
                  <span>
                    {formatNumber(paging.total_net_amount / 100, {
                      currency: 'EUR',
                    })}
                  </span>
                </div>
              )}
              {!!paging?.total_brutto_amount && (
                <div className='flex items-center'>
                  <span className='text-gray-700'>{t('Brutto')}</span>
                  &nbsp;
                  <span>
                    {formatNumber(paging.total_brutto_amount / 100, {
                      currency: 'EUR',
                    })}
                  </span>
                </div>
              )}
              {!!paging?.total_vat_amount && (
                <div className='flex items-center'>
                  <span className='text-gray-700'>{t('VAT amount')}</span>
                  &nbsp;
                  <span>
                    {formatNumber(paging.total_vat_amount / 100, {
                      currency: 'EUR',
                    })}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        {evaluation?.status === EvaluatiionStatusEnum.Done ? (
          <Button
            className='flex items-center'
            styling='outline'
            onClick={() =>
              onClickDownload(
                evaluation,
                'Download XLSX was clicked on the header of the invoice book page'
              )
            }
          >
            <DownloadIcon className='h-4 w-4 [&>path]:fill-dark' />
            &nbsp;
            <span> {t('Invoice book for')}</span>
            &nbsp;
            <span>
              {evaluation.type === 'month'
                ? formatDate(evaluation.startDate, 'MMMM yyyy')
                : formatDate(evaluation.startDate, 'QQQ yyyy')}
            </span>
          </Button>
        ) : !!paging?.total_rows ? (
          <Button
            className='flex items-center'
            styling='outline'
            onClick={onClickExport}
            disabled={
              isExporting ||
              evaluation?.status === EvaluatiionStatusEnum.Requested ||
              evaluation?.status === EvaluatiionStatusEnum.Generating
            }
            data-testid={
              isExporting ||
              evaluation?.status === EvaluatiionStatusEnum.Requested ||
              evaluation?.status === EvaluatiionStatusEnum.Generating
                ? 'generating-invoice-book'
                : 'export-invoice-book'
            }
          >
            {isExporting ||
            evaluation?.status === EvaluatiionStatusEnum.Requested ||
            evaluation?.status === EvaluatiionStatusEnum.Generating ? (
              <MiniLoader />
            ) : (
              <DownloadIcon className='h-4 w-4 [&>path]:fill-dark' />
            )}
            &nbsp;
            <span>
              {t(
                isExporting ||
                  evaluation?.status === EvaluatiionStatusEnum.Requested ||
                  evaluation?.status === EvaluatiionStatusEnum.Generating
                  ? 'Generating file'
                  : 'Export as XLSX'
              )}
            </span>
          </Button>
        ) : null}
      </div>
    </div>
  );
};
