interface LinkType {
  title: string;
  href: string;
}

export const links: LinkType[] = [
  {
    title: 'About new processes',
    href: '#about-new-processes',
  },
  {
    title: 'Why it is needed to fix the invoices',
    href: '#why-is-needed-to-fix',
  },
  {
    title: 'Types of errors',
    href: '#types-of-errors',
  },
  {
    title: 'How to correct invoices',
    href: '#how-to-correct',
  },
];
