import { useTranslation } from 'react-i18next';
import { formatDate } from '@/core/helpers';
import { normalizeDateFE } from '@/core/normalization';
import { AmazonIntegration } from '@/core/types';
import LinkIcon from '@/icons/link.svg?react';
import ConnectIcon from '@/icons/connect.svg?react';
import InvalidIntegrationIcon from '@/icons/integration-invalid.svg?react';
import Button from '../../Button';
import { Badge } from '@/components/Badge/Badge';
import Tooltip from '@/components/Tooltip/Tooltip';
import classNames from 'classnames';

interface Props {
  item: AmazonIntegration;
  onClickUpdate: () => void;
}

export default function AmazonIntegrationsItem({ item, onClickUpdate }: Props) {
  const { t } = useTranslation();
  return (
    <div
      className={classNames('AmazonIntegrationsItem gx-0 mb-2', {
        'border-t border-gray-500 pt-2': !item.invalid,
      })}
      data-testid={`amazon-account-${item.seller_id}-${
        item.invalid ? 'invalid' : 'valid'
      }`}
    >
      <div className='flex w-full items-center justify-between'>
        <div className='self-center'>
          <a
            href={`https://www.amazon.de/sp?_encoding=UTF&language=en&seller=${item.seller_id}`}
            target='_blank'
            rel='noopener noreferrer'
            className='Integrations__link inline-flex items-center no-underline'
            data-testid={`amazon-link-${item.seller_id}`}
          >
            {item.seller_id}
            <LinkIcon className='ml-1' />
          </a>
        </div>
        <div className='flex justify-end'>
          {item.has_api_refresh_token && !item.invalid ? (
            item.api_refresh_token_date ? (
              <Tooltip
                content={`Connected ${formatDate(
                  normalizeDateFE(item.api_refresh_token_date),
                  'dd.MM.yyyy'
                )}`}
              >
                <Badge
                  color='green'
                  className='flex items-center !px-2'
                  testId='amazon-valid'
                  label={
                    <>
                      <ConnectIcon className='Integrations__validIcon mr-1 inline-block !h-4 !w-4 items-center' />
                      <span>{t('Active')}</span>
                    </>
                  }
                />
              </Tooltip>
            ) : (
              <Badge
                color='green'
                className='!px-2'
                testId='amazon-valid'
                label={
                  <>
                    <ConnectIcon className='Integrations__validIcon mr-1 inline-block !h-4 !w-4 items-center' />
                    <span>{t('Active')}</span>
                  </>
                }
              />
            )
          ) : (
            <Badge
              color='red'
              className='flex items-center !px-2'
              testId='amazon-not-valid'
              label={
                <>
                  <InvalidIntegrationIcon className='Integrations__invalidIcon mr-1 inline-block !h-4 !w-4 items-center' />
                  <span>{t('Non-valid')}</span>
                </>
              }
            />
          )}
        </div>
      </div>
      {item.invalid && (
        <Button
          styling='outline'
          className='!mt-2 !w-full !px-4'
          onClick={onClickUpdate}
          data-testid={`amazon-token-btn-${item.seller_id}`}
          size='sm'
        >
          {t('Update')}
        </Button>
      )}
    </div>
  );
}
