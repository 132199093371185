import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import './EasybillTokenModal.scss';
import EasybillIcon from '@/icons/easybill.svg';
import Input from '../Input/Input';
import { FormikErrors, useFormik } from 'formik';
import Button from '../Button';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { getEasybillTokenStatus, postEasybillToken } from '@/core/api';
import { useAppSelector } from '../../hooks';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import MiniLoader from '../MiniLoader/MiniLoader';
import {
  activeCompanySelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';
import useNotification from '../../hooks/useNotification';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  initialIsTokenValid: boolean;
}

interface FormValues {
  token: string;
}

export default function EasybillTokenModal({
  isModalOpened,
  closeModal,
  initialIsTokenValid,
}: Props) {
  const [isLoading, setIsLoading] = useState(initialIsTokenValid);
  const [isTokenValid, setIsTokenValid] = useState(initialIsTokenValid);
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const queryKey = useAppSelector(queryKeySelector);
  const activeCompany = useAppSelector(activeCompanySelector);
  const queryClient = useQueryClient();

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      token: '',
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.token) {
        errors.token = t('Required')!;
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        setIsTokenValid(true);
        await postEasybillToken(values.token, activeCompany?.company_id);

        showNotification({
          type: 'success',
          text: 'Easybill connected',
        });

        try {
          const res = await getEasybillTokenStatus(activeCompany?.company_id);
          queryClient.setQueryData(['SellerEasybill', queryKey], res);
          closeModal();
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        if ((err as AxiosError).response?.status === 400) {
          setIsTokenValid(false);
        } else {
          setIsLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    if (isModalOpened) {
      formik.resetForm();
      setIsTokenValid(initialIsTokenValid);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [isModalOpened]);

  return (
    <Modal
      isOpened={isModalOpened}
      close={closeModal}
      title='easybill API key'
      name='easybill'
      isLoading={isLoading}
    >
      <div className='mx-4 px-4 pb-6 pt-4'>
        <div
          className='mb-4 flex items-center text-h4 font-extrabold'
          data-testid='integrations-easybill'
        >
          <img
            src={EasybillIcon}
            alt='easybill'
            style={{
              width: 40,
              height: 40,
            }}
            className='mr-2 flex-shrink-0'
          />
          <span>easybill API key</span>
        </div>
        <form onSubmit={formik.handleSubmit}>
          {!isTokenValid && (
            <div
              className='EasybillTokenModal__badge Text--red mb-4 p-2 text-center'
              data-testid='EasybillTokenModal-badge'
            >
              {t('Invalid easybill API key')}
            </div>
          )}
          <Input
            name='token'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.token}
            error={
              !formik.errors.token
                ? isTokenValid
                  ? ''
                  : 'error'
                : formik.errors.token
            }
            touched={!isTokenValid || formik.touched.token}
            wrapperClassName={classNames('Input--EasybillTokenModal', {
              'Input--EasybillTokenModal--hiddenError':
                !isTokenValid && !formik.errors.token,
            })}
          />
          <div className='flex flex-wrap gap-x-2'>
            <div>
              <Button
                type='submit'
                disabled={isLoading}
                className='flex items-center'
                data-testid='EasybillTokenModal-submit-btn'
              >
                {isLoading && <MiniLoader className='mr-2' color='gray' />}
                <span>{t('Connect')}</span>
              </Button>
            </div>
            <div>
              <Button
                styling='outline'
                onClick={closeModal}
                type='button'
                data-testid='EasybillTokenModal-cancel-btn'
              >
                {t('Cancel')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
