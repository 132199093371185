import { UploadedFile } from '@/core/types';
import { useTranslation } from 'react-i18next';
import { TableGrid, TableGridColumn } from '../TableGrid/TableGrid';
import { format } from 'date-fns';
import Tooltip from '../Tooltip/Tooltip';
import TipIcon from '@/icons/tip-green-circle.svg?react';
import ErrorIcon from '@/icons/file-error.svg?react';
import DownloadFileLink from './DownloadFileLink';
import Button from '../Button';
import { v4 as uuidv4 } from 'uuid';
import { activeCompanySelector } from '@/core/store/user/userSlice';
import { useAppSelector } from '@/hooks';
import { Spinner } from '../Spinner';
import BucketIcon from '@/icons/bucket.svg?react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ControlledCollapse } from '../Collapse/ControlledCollapse';

interface Props {
  uploadedFiles: UploadedFile[];
  onClickDeleteFile: (id: number) => void;
}

export const SellerManualFiles = ({
  uploadedFiles,
  onClickDeleteFile,
}: Props) => {
  const { vs5539DiscardFileCp } = useFlags();
  const { t } = useTranslation();
  const activeCompany = useAppSelector(activeCompanySelector);

  const sortedUploadedFiles = uploadedFiles.sort((a, b) =>
    b.imported_at.localeCompare(a.imported_at)
  );

  const columns: TableGridColumn<UploadedFile>[] = [
    {
      label: 'File name',
      field: 'name',
      formatter: (f) => (
        <div className='flex h-full items-center py-2 pr-4 font-semibold'>
          <DownloadFileLink
            file={f}
            key={f.name}
            companyId={activeCompany?.company_id}
          />
        </div>
      ),
      maxWidth: '5.1fr',
    },
    {
      label: 'Uploaded',
      field: 'imported_at',
      formatter: ({ imported_at }) => (
        <div className='flex h-full items-center pr-2'>
          {format(new Date(imported_at), 'dd.MM.yyyy HH:mm')}
        </div>
      ),
      maxWidth: '2.5fr',
    },
    {
      label: 'Date range',
      field: 'period_from',
      formatter: ({ period_from = null, period_to = null }) => (
        <div className='flex h-full flex-wrap items-center'>
          {!period_from && !period_to ? (
            <div>{t('Not recognized')}</div>
          ) : (
            <>
              <div>
                {period_from
                  ? format(new Date(period_from), 'dd.MM.yyyy')
                  : t('Not recognized')}
              </div>
              &mdash;
              <div>
                {period_to
                  ? format(new Date(period_to), 'dd.MM.yyyy')
                  : t('Not recognized')}
              </div>
            </>
          )}
        </div>
      ),
      maxWidth: '3.2fr',
    },
    {
      label: '',
      field: 'status',
      formatter: (f) => {
        if (f.update_progress) {
          return (
            <div className='flex h-full items-center'>
              <Spinner />
            </div>
          );
        }

        let content = (
          <Tooltip content={t('File is accepted')}>
            <TipIcon className='h-6 w-6' />
          </Tooltip>
        );

        if (!f.update_progress && !!f.errors?.length) {
          content = (
            <Tooltip content={t('File not accepted')}>
              <ErrorIcon className='h-6 w-6' />
            </Tooltip>
          );
        }

        return (
          <div className='flex h-full items-center justify-end gap-x-1'>
            {vs5539DiscardFileCp &&
              f.uploaded_by_client &&
              !f.documents_in_turnover_number && (
                <button
                  className='Modal__btn Modal__closeBtn flex items-center justify-center'
                  onClick={() => {
                    onClickDeleteFile(f.id);
                  }}
                >
                  <BucketIcon />
                </button>
              )}
            {content}
          </div>
        );
      },
      minWidth: 24,
      maxWidth: vs5539DiscardFileCp ? '56px' : '24px',
    },
  ];

  return (
    <div className='rounded-lg bg-white px-4 pb-6'>
      <TableGrid
        className='!mx-0 text-gray-700 [&>*:last-child]:border-none'
        headerClassName='border-b border-gray-500 !font-normal !pb-1 [&>div]:!px-0 !mx-0 !px-0'
        columns={columns}
        data={sortedUploadedFiles}
        renderCustomRow={(r, columns, gridTemplateColumns) => {
          return (
            <div className='border-b border-gray-500 text-dark' key={uuidv4()}>
              <div
                className={`TableGrid__row grid gap-x-1`}
                style={{ gridTemplateColumns }}
              >
                {columns.map((c) => (
                  <div key={c.label}>
                    {c.formatter ? c.formatter(r) : c.field}
                  </div>
                ))}
              </div>
              {!!r.errors?.length && (
                <div className='pl-12'>
                  <div className='Text--dark -mt-4 font-semibold'>
                    {t('File not accepted')}
                  </div>
                  <ControlledCollapse
                    triggerRenderer={(isOpened, toggleCollapse) => (
                      <div className='flex flex-col items-start justify-between pb-2'>
                        {!isOpened && (
                          <Button
                            styling='naked-blue'
                            className='!font-semibold'
                            onClick={toggleCollapse}
                          >
                            {t('Show details')}
                          </Button>
                        )}
                      </div>
                    )}
                    contentRenderer={(toggleCollapse) => (
                      <div className='pb-2'>
                        <div className='max-w-[95%] pr-2 text-dark'>
                          {(r.errors || []).map((err, i) => (
                            <div key={i}>{err}</div>
                          ))}
                        </div>
                        <Button
                          styling='naked-blue'
                          className='!font-semibold'
                          onClick={toggleCollapse}
                        >
                          {t('Hide details')}
                        </Button>
                      </div>
                    )}
                  />
                </div>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};
