import Header from '@/components/Header/Header';
import RegisterUserVerificationForm from './RegisterUserVerificationForm';
import './RegisterUser.scss';

interface Props {
  email: string;
  password: string;
}

export default function RegisterVerification({ email, password }: Props) {
  return (
    <div className='RegisterUser flex flex-col'>
      <Header />
      <div className='flex flex-grow items-start justify-center px-4'>
        <div className='RegisterUser__card RegisterUser__verification'>
          <RegisterUserVerificationForm email={email} password={password} />
        </div>
      </div>
    </div>
  );
}
