import { useTranslation } from 'react-i18next';
import SellerReportingCountriesItem from './SellerReportingCountriesItem';
import {
  FilingFrequencyType,
  PaymentFrequencyType,
  UserCompany,
  VATId,
} from '@/core/types';

interface Props {
  reportingCountriesData: VATId[];
  activeCompany: UserCompany | undefined;
}

export default function SellerReportingCountriesSidebar({
  reportingCountriesData,
  activeCompany,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className='mb-4 ml-auto mt-4 flex w-full flex-col items-center justify-center rounded-lg border-2 border-white bg-white bg-opacity-50 p-4 xl:mt-0'>
      <h2 className='Title--sm w-full pb-2 text-start font-extrabold'>
        {t('Reporting countries')}
      </h2>
      <div className='flex w-full flex-col gap-y-2'>
        {reportingCountriesData
          .sort((a, b) =>
            a.Country.short_name.localeCompare(b.Country.short_name)
          )
          .map((item, i) => (
            <SellerReportingCountriesItem
              key={i}
              country={item.Country}
              filing_period_from={item.filing_period_from}
              filing_period_to={item.filing_period_to}
              reporting_frequency={
                item.reporting_frequency as FilingFrequencyType
              }
              vat_number={item.vat_number}
              payment_frequency={item.payment_frequency as PaymentFrequencyType}
            />
          ))}
        {activeCompany?.ClientOSSDetails && (
          <SellerReportingCountriesItem
            key={Date.now()}
            filing_period_from={
              activeCompany?.ClientOSSDetails?.FilingPeriodFrom
            }
            filing_period_to={activeCompany?.ClientOSSDetails?.FilingPeriodTo}
            reporting_frequency={'quarterly'}
            vat_number={activeCompany?.ClientOSSDetails?.VATIdNumber}
          />
        )}
      </div>
    </div>
  );
}
