import { useAppSelector } from '../../hooks';
import {
  activeCompanySelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useModal } from '../Modal/useModal';
import useNotification from '../../hooks/useNotification';
import Button from '../Button';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { postStopMailing } from '@/core/api';
import { formatDate } from '@/core/helpers';
import { subMonths } from 'date-fns';

interface Props {
  manualUploadMailingStopped: boolean;
  hasUploadedFile: boolean;
}

export const StopMUBlock = ({
  manualUploadMailingStopped,
  hasUploadedFile,
}: Props) => {
  const { t } = useTranslation();
  const activeCompany = useAppSelector(activeCompanySelector);

  const queryClient = useQueryClient();
  const queryKey = useAppSelector(queryKeySelector);
  const { isModalOpened, closeModal, openModal } = useModal();
  const { showNotification } = useNotification();

  if (!activeCompany?.ManualUploadNeeded) {
    return (
      <div>
        {t(
          'You can upload corrected invoices or turnover data for past periods, if requested.'
        )}
      </div>
    );
  }

  return (
    <>
      {!manualUploadMailingStopped && !hasUploadedFile && (
        <>
          <div>
            <span className='pb-1'>
              {t("If you don't have manual turnover data in this month")}
              &nbsp;
            </span>
            <Button
              onClick={openModal}
              styling='naked-blue'
              className='!font-semibold'
            >
              {t('click here')}
            </Button>
          </div>
          <ConfirmModal
            isModalOpened={isModalOpened}
            closeModal={closeModal}
            confirm={(setIsLoading) => {
              setIsLoading(true);
              postStopMailing('manual-upload-needed', activeCompany?.company_id)
                .then(() => {
                  queryClient.invalidateQueries({
                    queryKey: ['SellerStopManualUploadMailing', queryKey],
                  });
                  setIsLoading(false);
                  closeModal();
                  showNotification({
                    text: 'No manual turnover data confirmed',
                    type: 'success',
                  });
                })
                .catch(() => {
                  setIsLoading(false);
                });
            }}
            confirmBtnText='Yes, I confirm'
            title='Confirm action'
          >
            <>
              <div className='Text mb-2 font-black'>
                {t(
                  'Please confirm that there is no manual turnover data for *month*'
                ).replace(
                  '*month*',
                  formatDate(subMonths(new Date(), 1), 'MMMM yyyy')
                )}
              </div>
              <div className='mb-4'>
                {t(
                  'In this case we will submit zero VAT return and you will not receive any email reminders in the current month.'
                )}
              </div>
            </>
          </ConfirmModal>
        </>
      )}
      {manualUploadMailingStopped && (
        <div className='flex flex-col gap-y-1'>
          {t('You have confirmed that there is no manual turnover data.')}
        </div>
      )}
    </>
  );
};
