import { formatNumber } from '@/core/helpers';
import './ThresholdIndicator.scss';

export default function ThresholdIndicator() {
  return (
    <div className='ThresholdIndicator relative mt-6 w-full'>
      <div className='ThresholdIndicator__text absolute'>
        {formatNumber(10000)}+
      </div>
      <div className='ThresholdIndicator__point absolute' />
    </div>
  );
}
