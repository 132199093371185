import { useTranslation } from 'react-i18next';
import Modal from '@/components/Modal/Modal';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
}

export default function AdjustmentsModal({ isModalOpened, closeModal }: Props) {
  const { t } = useTranslation();
  return (
    <Modal isOpened={isModalOpened} close={closeModal} title={t('Information')}>
      <div className='mx-4 px-4 pb-6 pt-4'>
        <div className='Text font-extrabold'>{t('Invoice adjustments')}</div>
        <div className='mb-4'>
          {t(
            'Since the date of crossing the €10,000 threshold the invoices should contain VAT rate of arrival country for all B2C-sales (not departure country, like it was before crossing the threshold)'
          )}
        </div>
        <div>
          <span>{t('More on')}</span>&nbsp;
          <a
            href='https://vat-one-stop-shop.ec.europa.eu/one-stop-shop/declare-and-pay-oss_en'
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue-500 transition-colors hover:text-blue-600'
          >
            {t('European Commission')}
          </a>
        </div>
      </div>
    </Modal>
  );
}
