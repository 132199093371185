import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Lang } from '@/core/types';
import { AuthedRoutesEnum } from '@/core/enums';
import BackIcon from '@/icons/arrow-left.svg?react';

import './OSSFilesMarkdown.scss';

const getOSSInstructionTab = async (lang: Lang, FILE_VERSION: string) => {
  let res = undefined;
  if (lang === 'de') {
    res = await axios.get(
      `${AuthedRoutesEnum.OSSInstruction}/${FILE_VERSION}-de.md`
    );
  } else {
    res = await axios.get(
      `${AuthedRoutesEnum.OSSInstruction}/${FILE_VERSION}.md`
    );
  }
  return res.data;
};

const CORRECT_TAB_KEY = 'correct_errors';

interface Props {
  className?: string;
  backLink?: string;
}

export default function OSSInstruction({ className = '', backLink }: Props) {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [content, setContent] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabParam = searchParams.get('tab');

  if (location.state?.isArchive) {
    searchParams.set('archive', '1');
  }

  const isCorrectErrorsTab =
    (tabParam && tabParam === CORRECT_TAB_KEY) || false;

  useEffect(() => {
    setContent('');
    const FILE_VERSION = isCorrectErrorsTab ? 'v20230720-2' : 'v20230720';
    getOSSInstructionTab(language as Lang, FILE_VERSION).then((res) =>
      setContent(res)
    );
  }, [language, isCorrectErrorsTab]);

  return (
    <div
      className={`flex w-full flex-grow flex-col rounded-lg px-4 py-4 lg:px-6 lg:py-6 xl:px-14 2xl:py-8 ${className}`}
    >
      <div className='mx-auto flex w-full max-w-[712px] flex-grow flex-col overflow-hidden rounded-lg bg-white px-6 py-4'>
        <Link
          className='group mb-2 flex items-center text-sm font-semibold transition hover:text-green'
          to={{
            pathname:
              backLink ||
              (location.state?.prevRoute ? location.state.prevRoute : '/'),
            search: searchParams.toString(),
          }}
        >
          <BackIcon className='transition-all [&>path]:transition-[fill] group-hover:[&>path]:fill-green' />
          <div>{t('Back')}</div>
        </Link>
        <h3 className='Title mb-3.5 font-black'>
          {t('How to submit OSS report.')}
        </h3>
        <div>
          <ReactMarkdown
            children={content}
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
            className='OSSMarkdown'
          />
        </div>
      </div>
    </div>
  );
}
