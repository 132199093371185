import { useTranslation } from 'react-i18next';
import './AmazonConnection.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import WhiteGreenWrapper from '../../../wrappers/WhiteGreenWrapper';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  getAmazonDataImportStatus,
  getAmazonConnectCredentials,
  postClientAmazonConnect,
  postTriggerAmazonDataImport,
  patchUser,
  getUserCompanies,
} from '@/core/api';
import {
  setAmazonDataImportStatus,
  setHasNewAmazonCredentials,
} from '@/core/store/amazon/amazonSlice';
import classNames from 'classnames';
import { Loader } from '@/components/Loader/Loader';
import { sleep } from '@/core/helpers';
import ShieldIcon from '@/icons/shield.svg?react';
import AmazonLogo from '@/icons/amazon.svg?react';
import AmazonConnectImage from '@/icons/amazon-connect.svg';
import AmazonImportedImage from '@/icons/amazon-imported.svg';
import AmazonConnectImageError from '@/icons/amazon-connect-error.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import Progress from '@/components/Progress/Progress';
import { AmazonDataImportStatusModel, UserFlagEnum } from '@/core/types';
import MiniLoader from '@/components/MiniLoader/MiniLoader';
import AmazonConnectionConsultation from './AmazonConnectionConsultation';
import {
  setActiveCompany,
  setUserCompanies,
  userSelector,
} from '@/core/store/user/userSlice';
import Button from '@/components/Button';

export interface AmazonConnectionFormValues {
  seller_id: string;
  mws_auth_token: string;
}

export interface AmazonConnectionFormValues {
  seller_id: string;
  mws_auth_token: string;
}

export default function AmazonConnection() {
  const [isAmazonSeller, setIsAmazonSeller] = useState(true);
  const navigate = useNavigate();
  const user = useAppSelector(userSelector);
  const hasNewCredentials = useAppSelector((s) => s.amazon.hasNewCredentials);
  const controller = useRef(new AbortController());
  const locaton = useLocation();
  const params = new URLSearchParams(locaton.search);
  const state = params.get('state');
  const spapi_oauth_code = params.get('spapi_oauth_code');
  const selling_partner_id = params.get('selling_partner_id');

  const amazonDataImportStatus = useAppSelector(
    (s) => s.amazon.dataImportStatus
  );
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(
    amazonDataImportStatus === 'in-process'
  );
  const [isDataImported, setIsDataImported] = useState(
    amazonDataImportStatus === 'loaded'
  );
  const [isError, setIsError] = useState(false);
  const [progressData, setProgressData] =
    useState<AmazonDataImportStatusModel>();

  const email = useAppSelector((state) => state.user.user?.Email);

  const dispatch = useAppDispatch();

  const onCheckNowClick = () => {
    dispatch(setAmazonDataImportStatus('loaded'));
  };

  const onFinishDataImport = useCallback(() => {
    setIsProcessing(false);
    setIsDataImported(true);
  }, []);

  const isAmazonFBACalculator =
    user?.Flag === UserFlagEnum.amazon_fba_calculator;

  useEffect(() => {
    return () => {
      // eslint-disable-next-line
      controller.current.abort();
    };
  }, []);

  const updateImportData = (data: AmazonDataImportStatusModel) =>
    setProgressData(data);

  useEffect(() => {
    if (hasNewCredentials && !isAmazonFBACalculator) {
      setIsLoading(true);
      getAmazonDataImportStatus()
        .then(async (res) => {
          updateImportData(res);
          setIsProcessing(true);
          setIsLoading(false);
          if (res.status === 'not-started') {
            await postTriggerAmazonDataImport();
          }
          pollAmazonStatus(
            onFinishDataImport,
            updateImportData,
            controller.current
          );
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [onFinishDataImport]);

  useEffect(() => {
    if (
      state &&
      state.replace('setup-check', '') === user?.Id &&
      spapi_oauth_code &&
      selling_partner_id
    ) {
      if (isAmazonFBACalculator) {
        (async () => {
          try {
            setIsLoading(true);
            await postClientAmazonConnect({
              seller_id: selling_partner_id,
              oauth_code: spapi_oauth_code,
            });
            setIsLoading(false);
            dispatch(setHasNewAmazonCredentials(true));
            navigate('/', {
              replace: true,
            });
          } catch {
            setIsError(true);
            setIsLoading(false);
            navigate('/', {
              replace: true,
            });
          }
        })();
      } else {
        (async () => {
          try {
            setIsLoading(true);
            await postClientAmazonConnect({
              seller_id: selling_partner_id,
              oauth_code: spapi_oauth_code,
            });
            const credsRes = await getAmazonConnectCredentials();
            navigate('/', {
              replace: true,
            });
            if (!!credsRes.length) {
              dispatch(setHasNewAmazonCredentials(true));
              setIsLoading(false);

              try {
                setIsProcessing(true);
                await postTriggerAmazonDataImport();
                pollAmazonStatus(
                  onFinishDataImport,
                  updateImportData,
                  controller.current
                );
                const companies = await getUserCompanies();
                dispatch(setUserCompanies(companies));
                dispatch(setActiveCompany(companies[0]));
              } catch {
                console.error('Import error, please try again');
              }
            } else {
              throw new Error('not connected');
            }
          } catch {
            setIsError(true);
            setIsLoading(false);
            navigate('/', {
              replace: true,
            });
          }
        })();
      }
    }
    // eslint-disable-next-line
  }, [state, spapi_oauth_code, selling_partner_id]);

  const progressPercent = getProgressPercent(
    progressData?.total,
    progressData?.processed
  );

  const renderRightColumnContent = () => {
    if (isDataImported) {
      return (
        <div className='flex flex-col items-center justify-center pt-1'>
          <img
            src={AmazonImportedImage}
            alt='AmazonImportedImage'
            className='AmazonConnection__image mx-auto'
          />
          <div className='Title mt-6 text-center font-black'>
            {t('Imported')}
          </div>
          <div className='mb-6 text-center'>
            {t('We also have sent your results to *email*').replace(
              '*email*',
              email || ''
            )}
          </div>
          <Progress
            max={1}
            value={1}
            className='Progress--AmazonConnection'
            getProgressBarColor={() => {
              return '#FFE609';
            }}
          />
        </div>
      );
    }
    if (isProcessing) {
      return (
        <div>
          <img
            src={AmazonConnectImage}
            alt='AmazonConnectImage'
            className='AmazonConnection__image mx-auto'
          />
          <div
            className='Title mb-2 mt-4 pt-4 text-center font-black'
            data-testid='time'
          >
            {isNaN(progressPercent) || progressPercent < 0.5 ? (
              <span>t('Starting processing')</span>
            ) : (
              <span>
                <span>{Math.round(progressPercent)}%</span>{' '}
                <span>{t('processed')}</span>
              </span>
            )}
          </div>
          <div className='mb-6 text-center'>
            {t(
              'It might take a few minutes. We will notify to *email* once it is done'
            ).replace('*email*', email || '')}
          </div>
          <div className='flex justify-center'>
            <Progress
              max={progressData?.total || 100}
              value={
                isNaN(progressPercent) || progressPercent < 0.5
                  ? 0
                  : progressData?.processed || 0
              }
              className='Progress--AmazonConnection'
              getProgressBarColor={() => {
                return '#FFE609';
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className='pt-1 text-center'>
          {isError ? (
            <img
              src={AmazonConnectImageError}
              alt='AmazonConnectImageError'
              className='AmazonConnection__image mx-auto'
            />
          ) : (
            <img
              src={AmazonConnectImage}
              alt='AmazonConnectImage'
              className='AmazonConnection__image mx-auto'
            />
          )}
        </div>
        {isAmazonFBACalculator ? (
          <div className='Title mb-2 mt-4 pt-4 text-center font-black text-white'>
            {t('Approx. *minutes* min').replace('*minutes*', '5')}
          </div>
        ) : (
          <>
            <div className='Title mb-2 mt-4 pt-4 text-center font-black text-white'>
              {t('Approx. *minutes* min').replace('*minutes*', '15')}
            </div>
            <div className='mb-4 text-center'>
              {t('We will notify to *email* once it is done').replace(
                '*email*',
                email || ''
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const connectedBadge = (
    <div className='Text AmazonConnection__authorizeBtn AmazonConnection__authorizeBtn--connected mb-12 flex w-full items-stretch overflow-hidden p-0 no-underline'>
      <div
        className={`AmazonConnection__authorizeIcon flex items-center justify-center`}
      >
        <AmazonLogo />
      </div>
      <div
        className='flex flex-grow items-center justify-center px-4'
        data-testid='amazon-status-badge'
      >
        {isProcessing ? (
          <>
            <span>{t('Importing data')}</span>
            <MiniLoader
              className='MiniLoader--Notification ml-2 lg:ml-6'
              color='white'
            />
          </>
        ) : (
          <span>{t('Amazon connected')}</span>
        )}
      </div>
    </div>
  );

  const renderContent = () => {
    if (isDataImported) {
      return (
        <>
          <div className='hidden flex-grow lg:block'>
            <div className='Title AmazonConnection__mw400 mb-2 font-black'>
              {t('Amazon is connected')}
            </div>
            <div className='Text'>
              {t('Your data is processed and fully imported')}
            </div>
            <div className='Text mb-4'>
              {t('Now you can check your VAT setup')}
            </div>
            <Button className='AmazonConnection__btn' onClick={onCheckNowClick}>
              {t('Show my setup')}
            </Button>
          </div>
          <div className='flex flex-grow flex-col justify-between lg:hidden'>
            <div className='flex flex-col items-center'>
              <img
                src={AmazonImportedImage}
                alt='AmazonImportedImage'
                className='AmazonConnection__image mx-auto'
              />
              <div className='mb-2 mt-4 pt-4 text-center'>
                {t('Step *n* of 3').replace('*n*', '3')}
              </div>
              <div className='Title AmazonConnection__mw400 mb-2 text-center font-black'>
                {t('Amazon is connected')}
              </div>
              <div className='Text mb-4 text-center'>
                <span>
                  {t('Your data is processed and fully imported')}&nbsp;
                </span>
                <span>{i18n.language === 'en' && <br />}</span>
                <span>{t('Now you can check your VAT setup')}</span>
              </div>
              <Button
                className='AmazonConnection__btn mb-4 w-auto'
                onClick={onCheckNowClick}
              >
                {t('Show my setup')}
              </Button>
            </div>
            <div className='mb-1 pb-4'>
              <div className='text-center text-h4 font-bold'>
                {t('Imported')}
              </div>
              <div className='mb-6 text-center'>
                {t('We also have sent your results to *email*').replace(
                  '*email*',
                  email || ''
                )}
              </div>
              <div className='flex justify-center'>
                <Progress
                  max={1}
                  value={1}
                  className='Progress--AmazonConnection'
                  getProgressBarColor={() => {
                    return '#FFE609';
                  }}
                />
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        {isProcessing && (
          <>
            <div className='flex flex-grow flex-col justify-between lg:hidden'>
              <div>
                <img
                  src={AmazonConnectImage}
                  alt='AmazonConnectImage'
                  className='AmazonConnection__image mx-auto'
                />
                <div className='mb-2 mt-4 pt-4 text-center'>
                  {t('Step *n* of 3').replace('*n*', '2')}
                </div>
                <div className='Title mb-2 text-center font-black'>
                  {t('Connect Amazon')}
                </div>
                <div
                  className='Text mb-4 text-center'
                  style={{
                    maxWidth: 506,
                  }}
                >
                  {t(
                    'Login to your Amazon account and select your main seller center. Once your data is connected we will import your data'
                  )}
                </div>
                {[
                  'Your data is 100% protected',
                  'All data is anonymized',
                  'There is no access to your Seller account',
                  'Only relevant data is processed',
                ].map((p) => (
                  <div
                    className='Text AmazonConnection__point mx-auto mb-2 flex'
                    key={p}
                  >
                    <ShieldIcon className='mr-2 flex-shrink-0' />
                    <div className='lg:whitespace-pre-line'>{t(p)}</div>
                  </div>
                ))}
                <div className='pt-2'>{connectedBadge}</div>
              </div>
              <div className='mb-1 pb-4'>
                <div
                  className='mb-2 text-center text-h4 font-black'
                  data-testid='time'
                >
                  {isNaN(progressPercent) || progressPercent < 0.5 ? (
                    <span>{t('Starting processing')}</span>
                  ) : (
                    <span>
                      {Math.round(progressPercent)}% {t('processed')}
                    </span>
                  )}
                </div>
                <div className='mb-6 text-center'>
                  {t(
                    'It might take a few minutes. We will notify to *email* once it is done'
                  ).replace('*email*', email || '')}
                </div>
                <div className='flex justify-center'>
                  <Progress
                    max={progressData?.total || 100}
                    value={
                      isNaN(progressPercent) || progressPercent < 0.5
                        ? 0
                        : progressData?.processed || 0
                    }
                    className='Progress--AmazonConnection'
                    getProgressBarColor={() => {
                      return '#FFE609';
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <div
          className={classNames({
            'hidden lg:block': isProcessing,
            'flex flex-grow flex-col justify-between': !isProcessing,
          })}
        >
          <div className='AmazonConnection__content mx-auto lg:mx-0'>
            <div className='mb-4 flex justify-center pb-4 lg:hidden'>
              {isError ? (
                <img
                  src={AmazonConnectImageError}
                  alt='AmazonConnectImageError'
                  className='AmazonConnection__image mx-auto'
                />
              ) : (
                <img
                  src={AmazonConnectImage}
                  alt='AmazonConnectImage'
                  className='AmazonConnection__image mx-auto'
                />
              )}
            </div>
            <div className='mb-2 text-center lg:hidden'>
              {t('Step *n* of 3').replace('*n*', '1')}
            </div>
            <div className='Title mb-2 font-black'>{t('Connect Amazon')}</div>
            <div
              className='Text mb-4'
              style={{
                maxWidth: 506,
              }}
            >
              {t(
                'Login to your Amazon account and select your main seller center. Once your data is connected we will import your data'
              )}
            </div>
            {[
              'Your data is 100% protected',
              'All data is anonymized',
              'There is no access to your Seller account',
              'Only relevant data is processed',
            ].map((p) => (
              <div
                className='Text AmazonConnection__point mx-auto mb-4 flex'
                key={p}
              >
                <ShieldIcon className='mr-2 flex-shrink-0' />
                <div className='-mt-1 lg:whitespace-pre-line'>{t(p)}</div>
              </div>
            ))}
            <div className='pt-4'>
              {hasNewCredentials ? (
                <div className='AmazonConnection__mw506 text-center'>
                  {connectedBadge}
                </div>
              ) : (
                <div className='AmazonConnection__mw506 text-center'>
                  <a
                    className={classNames(
                      'Text AmazonConnection__authorizeBtn align-items-stretch mb-6 flex w-full overflow-hidden p-0 no-underline',
                      {
                        'AmazonConnection__authorizeBtn--error': isError,
                      }
                    )}
                    href={`https://sellercentral-europe.amazon.com/apps/authorize/consent?application_id=${
                      import.meta.env.VITE_APP_ID
                    }&state=${user?.Id}setup-check&version=beta`}
                  >
                    <div
                      className={`AmazonConnection__authorizeIcon flex items-center justify-center`}
                    >
                      <AmazonLogo />
                    </div>
                    <div className='flex flex-grow items-center justify-center px-4'>
                      {t(
                        isError
                          ? 'Connection error. Try again'
                          : 'connect_amazon_btn'
                      )}
                    </div>
                  </a>
                  {!isAmazonFBACalculator &&
                    !isDataImported &&
                    !isProcessing && (
                      <Button
                        styling='naked'
                        className='Text lg:mt-2'
                        onClick={() => {
                          patchUser({
                            IsAmazonSeller: false,
                          }).catch(console.error);
                          setIsAmazonSeller(false);
                        }}
                      >
                        {t('I am not an Amazon Seller')}
                      </Button>
                    )}
                </div>
              )}
            </div>
          </div>
          <div className='lg:hidden'>
            {isAmazonFBACalculator ? (
              <div className='mb-2 mt-3 pt-3 text-center text-h4 font-black'>
                {t('Approx. *minutes* min').replace('*minutes*', '5')}
              </div>
            ) : (
              <>
                <div className='mb-2 mt-3 pt-3 text-center text-h4 font-black'>
                  {t('Approx. *minutes* min').replace('*minutes*', '15')}
                </div>
                <div className='mb-4 text-center'>
                  {t('We will notify to *email* once it is done').replace(
                    '*email*',
                    email || ''
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  if (isLoading) return <Loader />;

  if (!isAmazonSeller) {
    return (
      <AmazonConnectionConsultation
        onClickBtn={() => {
          setIsAmazonSeller(true);
        }}
      />
    );
  }

  return (
    <WhiteGreenWrapper
      data-testid='amazon-connection'
      rightSideContent={renderRightColumnContent()}
      className='WhiteGreenWrapper--AmazonConnection'
    >
      <div className='flex flex-grow flex-col justify-between pb-6'>
        {renderContent()}
      </div>
    </WhiteGreenWrapper>
  );
}

const pollAmazonStatus = async (
  onFinish: () => void,
  onUpdate: ((data: AmazonDataImportStatusModel) => void) | undefined,
  controller: AbortController,
  count: number = 1
) => {
  try {
    const res = await getAmazonDataImportStatus(controller);

    if (onUpdate) onUpdate(res || undefined);

    if (res.status === 'loaded') {
      onFinish();
    } else {
      await sleep(1500);
      await pollAmazonStatus(onFinish, onUpdate, controller);
    }
  } catch {
    if (count < 3) {
      await sleep(1500);
      try {
        await pollAmazonStatus(onFinish, onUpdate, controller, ++count);
      } catch (err) {
        console.error(err);
      }
    }
  }
};

const getProgressPercent = (total: number = 100, current: number = 0) => {
  return (current / total) * 100;
};
