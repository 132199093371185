import { downloadFile, formatDate, formatNumber } from '@/core/helpers';
import DownloadIcon from '@/icons/download.svg?react';
import FileIcon from '../FileIcon';
import { APIEventsEnum, useSendEvent } from '../../hooks';
import { getReportResultFileLink } from '@/core/api';
import { useTranslation } from 'react-i18next';
import { AuthedRoutesEnum } from '@/core/enums';
import { Link, useLocation } from 'react-router-dom';
import { DatevEntryTypesMapping } from './Datev.helpers';
import Tooltip from '../Tooltip/Tooltip';
import { useNPSModal } from '../NPSModal/useNPSModal';

export interface DatevFile {
  created_at: string;
  file: string;
  id: number;
  option: string;
  service_date: string;
  status: number;
}

interface Props {
  file: DatevFile;
  companyId: string;
  count?: number;
  period?: Date;
}

const DatevFileMapping: {
  [key: string]: string;
} = {
  datev_trns: 'Umsätze',
  datev_trns_local: 'Lokale Umsätze',
  datev_trns_netto: 'OSS Netto Umsätze',
  datev_trns_vat: 'OSS VAT Datei',
  datev_shipment: 'Warenverbringungen',
  datev_commingling: 'Commingling Transaktionen',
};

export default function DatevItem({ file, companyId, count, period }: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const { openModal } = useNPSModal();
  const { sendEvent } = useSendEvent();

  const onClickDownload = () => {
    const apiEvent = APIEventsEnum.download_datev_file;

    sendEvent(apiEvent, { file: file.file });
    getReportResultFileLink(file.id, companyId)
      .then((link) => {
        downloadFile(link, file.file);
        openModal(apiEvent);
      })
      .catch(console.error);
  };

  const params = new URLSearchParams(location.search);
  params.set('company_id', companyId);
  params.set('entry_type', String(DatevEntryTypesMapping[file.option]));
  params.set('page', '1');

  if (!file.file) {
    return (
      <div className='flex items-center py-2'>
        <FileIcon ext='empty' className='h-8 w-8' />
        <div className='max-w-full truncate pl-2 font-semibold text-gray-700'>
          {DatevFileMapping[file.option] ||
            file.file.split('/').slice(-1)[0].split('.').slice(0, -1).join('.')}
          : {t('no data')}
        </div>
      </div>
    );
  }

  return (
    <div className='Datev__grid grid gap-12 py-2'>
      <div className='flex items-center truncate'>
        <div className='group inline-flex truncate' onClick={onClickDownload}>
          <button>
            <FileIcon
              ext={file.file.split('.').slice(-1)[0]}
              className='h-8 w-8'
            />
          </button>
          <button className='max-w-full truncate pl-2 font-semibold transition-colors group-hover:text-green'>
            {DatevFileMapping[file.option] ||
              file.file
                .split('/')
                .slice(-1)[0]
                .split('.')
                .slice(0, -1)
                .join('.')}
          </button>
        </div>
      </div>
      {period && (
        <Link
          className='fw-600 flex cursor-pointer items-center justify-end text-blue-500 hover:text-blue-600'
          to={{
            pathname: AuthedRoutesEnum.DatevOverview.replace(
              ':year',
              String(period.getFullYear())
            ).replace(':month', String(period.getMonth() + 1)),
            search: params.toString(),
          }}
        >
          <span>{count !== undefined && formatNumber(count)}</span>
        </Link>
      )}
      <div className='flex items-center'>
        {formatDate(new Date(file.created_at), 'dd.MM.yyyy HH:mm')}
      </div>
      <div className='flex items-center'>
        <Tooltip delay={750} content={t('Download')}>
          <button
            className='flex h-8 w-8 items-center justify-center rounded-full bg-gray-300 transition-colors duration-300 hover:bg-gray-500'
            onClick={onClickDownload}
          >
            <DownloadIcon className='SellerManualUploadInstructionBlock__download m-auto' />
          </button>
        </Tooltip>
      </div>
    </div>
  );
}
