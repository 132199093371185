import { useTranslation } from 'react-i18next';
import {
  formatNumber,
  pushEventToDataLayer,
  stripSpaces,
} from '@/core/helpers';
import { CountryThreshold } from '@/core/types';
import CountryLabel from '@/components/CountryLabel/CountryLabel';
import classNames from 'classnames';
import useLinkToMainSite from '../../../hooks/useLinkToMainSite';
import { MouseEventHandler, useMemo } from 'react';
import ThresholdIndicator from './ThresholdIndicator';
import './SetupCheckThreshold.scss';
import SetupCheckQuestion from './SetupCheckQuestion';
import LinkIcon from '@/icons/link.svg?react';
import { useModal } from '@/components/Modal/useModal';
import AdjustmentsModal from './AdjustmentsModal';
import { GTMEvents, PagesEnum } from '@/core/constants';
import RetroactiveFilingModal from './RetroactiveFilingModal';
import Button from '@/components/Button';

interface Props {
  threshold: number;
  thresholdByCountry: CountryThreshold[];
  className?: string;
  onAnswerOssQuestion: (v: boolean) => MouseEventHandler;
  questionText: string;
  hasOss: boolean | undefined;
  showQuestion: boolean;
  title: JSX.Element;
  crossedOssThresholdLabel?: string;
}

export default function SetupCheckThreshold({
  threshold,
  thresholdByCountry,
  className = '',
  onAnswerOssQuestion,
  questionText,
  hasOss,
  showQuestion,
  title,
  crossedOssThresholdLabel,
}: Props) {
  const {
    isModalOpened: isAdjustmentModalOpened,
    closeModal: closeAdjustmentModal,
    openModal: openAdjustmentModal,
  } = useModal();

  const {
    isModalOpened: isRetroactiveFilingModalOpened,
    closeModal: closeRetroactiveFilingModal,
    openModal: openRetroactiveFilingModal,
  } = useModal();

  const { t } = useTranslation();
  const { getLink } = useLinkToMainSite();

  const isAffected = threshold >= 9500;
  const otherCountriesThreshold = useMemo(
    () =>
      thresholdByCountry
        .slice(5)
        .reduce((acc, item) => acc + item.threshold.value, 0),
    [thresholdByCountry]
  );

  return (
    <div
      className={`SetupCheck__card flex flex-col justify-between ${className}`}
    >
      <div className='px-4 lg:px-6'>
        <div className='flex gap-x-6 lg:gap-x-12'>
          <div className='SetupCheckThreshold__left w-full pl-4 pr-4 pt-4 lg:flex lg:w-1/2 lg:py-6'>
            <div className='mb-4 flex flex-grow flex-col justify-between lg:mb-0'>
              <div>
                <div className='font-extrabold lg:mb-2'>
                  {t('EU-Wide threshold')}
                </div>
                <div className='SetupCheck__text mb-2'>
                  {t(
                    'Since the 1st of July 2021 a single EU-wide threshold replaces national thresholds. After reaching this threshold, seller have to register for OSS and provide a single filing once per quarter'
                  )}
                </div>
                <a
                  className='SetupCheck__link inline-block lg:mb-12'
                  href={getLink(PagesEnum.oss)}
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() => {
                    pushEventToDataLayer(GTMEvents.setup_check_click_about_oss);
                  }}
                >
                  {t('About OSS')} ⟶
                </a>
              </div>
              {showQuestion && (
                <SetupCheckQuestion
                  answer={hasOss}
                  className='hidden lg:mb-1 lg:block'
                  text={questionText}
                  onClickYes={onAnswerOssQuestion(true)}
                  onClickNo={onAnswerOssQuestion(false)}
                />
              )}
            </div>
            <div className='SetupCheck__separator -mx-4 lg:hidden' />
          </div>
          <div className='w-full pb-6 pl-4 pr-4 pt-4 lg:w-1/2 lg:pt-6'>
            <div className='flex flex-col items-center'>
              {title}
              <div
                className={classNames('SetupCheck__threshold mb-lg-0 mb-2', {
                  'SetupCheck__threshold--affected': isAffected,
                })}
                data-testid='threshold'
              >
                ≈
                {stripSpaces(
                  formatNumber(threshold, {
                    currency: 'EUR',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                )}
              </div>
              <ThresholdIndicator />
            </div>
            <div className='mb-2 mt-4 font-extrabold'>
              {t('Top-selling countries')}
            </div>
            {thresholdByCountry.slice(0, 5).map((item, i) => (
              <div
                className='mb-2 flex flex-wrap justify-between gap-x-6'
                key={i}
              >
                <div className=''>
                  <CountryLabel country={item.country} />
                </div>
                <div className=''>
                  ≈
                  {item.threshold?.value &&
                    stripSpaces(
                      formatNumber(Math.round(item.threshold?.value), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                        currency: 'EUR',
                      })
                    )}
                </div>
              </div>
            ))}
            <div className='mb-2 mt-4 flex items-center justify-between'>
              <div className='font-extrabold'>{t('Other countries')}</div>
              <div data-testid='other-countries-threshold'>
                ≈
                {stripSpaces(
                  formatNumber(Math.round(otherCountriesThreshold), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    currency: 'EUR',
                  })
                )}
              </div>
            </div>
            <div className='mb-n2 flex flex-wrap gap-x-4'>
              {thresholdByCountry.slice(5).map((item, i) => (
                <div className=' mb-2' key={i}>
                  <CountryLabel country={item.country} />
                </div>
              ))}
            </div>
            {threshold > 11000 && crossedOssThresholdLabel && (
              <div className='mt-4'>
                <div className='mb-2 font-extrabold'>
                  {t('Since *quarter* required').replace(
                    '*quarter*',
                    crossedOssThresholdLabel
                  )}
                  :
                </div>
                <div className='mb-4 flex-wrap gap-x-6 lg:flex'>
                  <div className='mb-2 flex items-start lg:w-1/2'>
                    ✓&nbsp;<div>{t('Registration for OSS')}</div>
                  </div>
                  <div className='mb-2 flex items-start lg:w-1/2'>
                    <span>✓</span>&nbsp;
                    <Button
                      styling='naked-blue'
                      className='text-start'
                      onClick={() => {
                        openRetroactiveFilingModal();
                        pushEventToDataLayer(
                          GTMEvents.setup_check_click_retroactive_filing
                        );
                      }}
                    >
                      {t('Retroactive filing')}
                    </Button>
                  </div>
                  <div className=' mb-2 flex items-start'>
                    ✓&nbsp;
                    <Button
                      styling='naked-blue'
                      className='text-start'
                      onClick={() => {
                        pushEventToDataLayer(
                          GTMEvents.setup_check_click_invoice_adjustments
                        );
                        openAdjustmentModal();
                      }}
                    >
                      {t('Invoice adjustments')}
                    </Button>
                  </div>
                </div>
                <div>
                  <a
                    className='SetupCheckThreshold__link inline-block'
                    href='https://vat-one-stop-shop.ec.europa.eu/one-stop-shop/declare-and-pay-oss_en#paragraph_98'
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => {
                      pushEventToDataLayer(
                        GTMEvents.setup_check_click_penalties_link
                      );
                    }}
                  >
                    <span className='mr-2'>
                      {t('Penalties are possible depending on the  situation')}
                    </span>
                    <LinkIcon className='mb-1' />
                  </a>
                </div>
              </div>
            )}
            {showQuestion && (
              <div className='lg:hidden'>
                <div className='SetupCheck__separator -mx-4 mb-2 mt-4 lg:hidden' />
                <SetupCheckQuestion
                  answer={hasOss}
                  text={questionText}
                  onClickYes={onAnswerOssQuestion(true)}
                  onClickNo={onAnswerOssQuestion(false)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <AdjustmentsModal
        isModalOpened={isAdjustmentModalOpened}
        closeModal={closeAdjustmentModal}
      />
      <RetroactiveFilingModal
        closeModal={closeRetroactiveFilingModal}
        isModalOpened={isRetroactiveFilingModalOpened}
      />
    </div>
  );
}
