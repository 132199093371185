import { useTranslation } from 'react-i18next';
import BookIcon from '@/icons/book.svg?react';
import LinkIcon from '@/icons/link.svg?react';
import DownloadIcon from '@/icons/download.svg?react';
import './SellerManualUploadInstructionBlock.scss';
import { downloadFile } from '@/core/helpers';
import classNames from 'classnames';
import Button from '../Button';
import { APIEventsEnum, useSendEvent } from '../../hooks';

export default function SellerManualUploadInstructionBlock() {
  const { sendEvent } = useSendEvent();
  const { t, i18n } = useTranslation();

  return (
    <div className='mb-4 ml-auto flex flex-col items-center justify-center rounded-lg border-2 border-white bg-white bg-opacity-50 px-2 pb-2 pt-6'>
      <BookIcon />
      <div
        className='my-4 text-center text-h4 font-extrabold'
        dangerouslySetInnerHTML={{
          __html: t('How to prepare your Manual Turnover Data')!,
        }}
      />
      <div
        className={classNames(
          'SellerManualUploadInstructionBlock__container flex flex-col',
          {
            'SellerManualUploadInstructionBlock__container--de':
              i18n.language === 'de',
          }
        )}
      >
        <a
          className='Button--outline mb-2 inline-flex items-center !px-2 !py-1'
          href={
            i18n.language === 'de'
              ? '/Manual_data_preparation_DE.pdf'
              : '/Manual_data_preparation_ENG.pdf'
          }
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => {
            sendEvent(APIEventsEnum.download_manual_upload_instruction, {
              block: 'Download Manual Upload Instruction',
            });
          }}
        >
          <LinkIcon className='SellerManualUploadInstructionBlock__icon mr-2' />
          <span>{t('Open instruction')}</span>
        </a>
        <Button
          styling='outline'
          className='mb-4 flex items-center !px-2 !py-1'
          onClick={() => {
            sendEvent(APIEventsEnum.download_manual_upload_template, {
              block: 'Download Manual Upload Template',
            });
            downloadFile(
              i18n.language === 'en'
                ? '/countX-Turnover-Import-Template_Example.xlsx'
                : '/countX-Turnover-Import-Vorlage_Beispiel.xlsx',
              i18n.language === 'en'
                ? 'countX-Turnover-Import-Template_Example.xlsx'
                : 'countX-Turnover-Import-Vorlage_Beispiel.xlsx'
            );
          }}
        >
          <DownloadIcon className='SellerManualUploadInstructionBlock__download mr-2' />
          <span>{t('Download template')}</span>
        </Button>
      </div>
    </div>
  );
}
