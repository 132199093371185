import XLS from '@/icons/file/xls.svg?react';
import XLSX from '@/icons/file/xlsx.svg?react';
import ZIP from '@/icons/file/zip.svg?react';
import PDF from '@/icons/file/pdf.svg?react';
import CSV from '@/icons/file/csv.svg?react';
import BOP from '@/icons/file/bop.svg?react';
import UnknownIcon from '@/icons/file/unknown-file.svg?react';
import EmptyIcon from '@/icons/file/empty.svg?react';
import AmazonIcon from '@/icons/amazon-valid.svg?react';
import CircledPDFIcon from '@/icons/pdf.svg?react';
import CircledXLSIcon from '@/icons/xls.svg?react';
import CircledCSVIcon from '@/icons/csv.svg?react';

const FILE_ICONS: {
  [key: string]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
} = {
  amazon: AmazonIcon,
  xls: XLS,
  xlsx: XLSX,
  zip: ZIP,
  rar: ZIP,
  pdf: PDF,
  csv: CSV,
  bop: BOP,
  empty: EmptyIcon,
};

const CIRCLED_FILE_ICONS: {
  [key: string]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
} = {
  xls: CircledXLSIcon,
  xlsx: CircledXLSIcon,
  pdf: CircledPDFIcon,
  csv: CircledCSVIcon,
};

interface Props {
  ext: string;
  className?: string;
  styling?: 'default' | 'circled';
}

export default function FileIcon({ ext, className = '', styling }: Props) {
  const Icon =
    (styling === 'circled'
      ? CIRCLED_FILE_ICONS[ext] || FILE_ICONS[ext]
      : FILE_ICONS[ext]) || UnknownIcon;
  return <Icon className={`${className}`} />;
}
