import classNames from 'classnames';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { ChangeEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@/components/Checkbox/Checkbox';
import Input from '@/components/Input/Input';
import {
  PASSWORD_VALIDATION_PARAMETERS,
  isEmail,
  isPasswordValid,
  passwordValidators,
} from '@/core/helpers';
import { Loader } from '@/components/Loader/Loader';
import { registerSeller } from '@/core/api';
import { Lang } from '@/core/types';
import { TERMS_OF_SERVICE_VERSION } from '../TermsOfService/TermsOfService';
import { PublicRoutesEnum, RolesEnum } from '@/core/enums';
import { DATA_PROTECTION_VERSION } from '../DataProcessingAgreement/DataProcessingAgreement';
import Button from '@/components/Button';

interface FormValues {
  email: string;
  name: string;
  family_name: string;
  password: string;
  consent: boolean;
  newsletter_agreement: boolean;
}

interface Props {
  onSubmit: (email: string, password: string) => void;
  uuid: string;
  role: RolesEnum;
}

export default function RegisterUserForm({ onSubmit, uuid, role }: Props) {
  const { t, i18n } = useTranslation();

  const [usedEmails, setUsedEmails] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const formik = useFormik<FormValues>({
    validateOnBlur: true,
    initialValues: {
      email: '',
      name: '',
      family_name: '',
      password: '',
      consent: false,
      newsletter_agreement: false,
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.email) {
        errors.email = 'Required';
      }
      if (values.email !== '' && !isEmail(values.email)) {
        errors.email = `Email is not valid`;
      }
      if (values.email !== '' && usedEmails.includes(values.email)) {
        errors.email = `This email already exists. Try to login`;
      }
      if (!values.name) {
        errors.name = 'Required';
      }

      if (!values.family_name) {
        errors.family_name = 'Required';
      }

      if (values.consent === false) {
        errors.consent = 'Required';
      }
      if (values.password === '' || !isPasswordValid(values.password)) {
        errors.password = JSON.stringify(
          PASSWORD_VALIDATION_PARAMETERS.reduce((acc: string[], item) => {
            if (
              passwordValidators[item] &&
              !passwordValidators[item](values.password)
            ) {
              acc.push(item);
            }
            return acc;
          }, [])
        );
      }

      return errors;
    },
    onSubmit: ({
      email,
      password,
      name,
      family_name,
      newsletter_agreement,
      consent,
    }) => {
      setIsLoading(true);
      if (error) setError('');
      registerSeller({
        email,
        password,
        name,
        family_name,
        newsletter_agreement,
        tos_accepted: consent,
        tos_version: TERMS_OF_SERVICE_VERSION,
        data_protection_accepted: consent,
        data_protection_version: DATA_PROTECTION_VERSION,
        language: i18n.language as Lang,
        client_uuid: uuid,
        role,
      })
        .then(() => {
          setIsLoading(false);
          onSubmit(email, password);
        })
        .catch((err) => {
          const message = err.response?.data?.msg;
          if (
            message &&
            message === 'UsernameExistsException: User already exists'
          ) {
            formik.setFieldError(
              'email',
              'This email already exists. Try to login'
            );
            setUsedEmails((emails) => [...emails, email]);
          } else if (message && message.includes('InvalidPasswordException')) {
            setError(message.replace('InvalidPasswordException:', '').trim());
          } else {
            setError('Something went wrong, please try again');
          }
          setIsLoading(false);
        });
    },
  });

  const passwordErrors = (formik.errors.password || '').slice();

  const onChangeField: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (error) setError('');

    if (e.target.name === 'password') {
      formik.setFieldTouched(e.target.name, true, true);
    }

    formik.setFieldValue(
      e.target.name,
      e.target.type === 'checkbox'
        ? e.target.checked
        : e.target.name === 'code'
          ? e.target.value.trim()
          : e.target.value,
      formik.touched[e.target.name as keyof FormikTouched<FormValues>]
    );
  };
  const isConsentInvalid = formik.touched.consent && !!formik.errors.consent;

  return (
    <form onSubmit={formik.handleSubmit}>
      {isLoading && <Loader />}
      <div className='Text text-center'>
        <div className='Title mb-2 font-black'>{t('Create account')}</div>
        <div className='mx-auto mb-2 lg:whitespace-pre-line'>
          {t('To get access to Client Portal')}
        </div>
      </div>
      <Input
        wrapperClassName='Input--RegisterForm'
        label='Email'
        name='email'
        id='email'
        onChange={onChangeField}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={formik.errors.email}
        touched={formik.touched.email}
      />
      <div className='flex gap-x-6'>
        <div>
          <Input
            wrapperClassName='Input--RegisterForm'
            tabIndex={0}
            label='First Name'
            name='name'
            id='name'
            onChange={onChangeField}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.errors.name}
            touched={formik.touched.name}
          />
        </div>
        <div>
          <Input
            wrapperClassName='Input--RegisterForm'
            label='Last Name'
            name='family_name'
            id='family_name'
            onChange={onChangeField}
            onBlur={formik.handleBlur}
            value={formik.values.family_name}
            error={formik.errors.family_name}
            touched={formik.touched.family_name}
          />
        </div>
      </div>
      <Input
        label='Password'
        name='password'
        id='password'
        type='password'
        wrapperClassName='RegisterForm__password Input--RegisterForm'
        onChange={onChangeField}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        error={formik.errors.password}
        touched={formik.touched.password}
      />
      <div className='RegisterForm__passwordRequirements -mt-2 flex flex-wrap gap-x-1'>
        {PASSWORD_VALIDATION_PARAMETERS.map((item: any, i) => (
          <div key={item}>
            <div
              className={classNames('mb-1 whitespace-nowrap', {
                'RegisterForm__badge rounded':
                  formik.touched.password && passwordErrors.includes(item),
                'Text--green':
                  formik.touched.password && !passwordErrors.includes(item),
              })}
              data-testid={`password-validation-${item
                .toLowerCase()
                .replace(/\s/g, '_')}`}
            >
              {t(item)}
              {i === 0 ? (
                ':'
              ) : i !== PASSWORD_VALIDATION_PARAMETERS.length - 1 ? (
                <span className='text-dark'>,</span>
              ) : (
                ''
              )}
            </div>
          </div>
        ))}
      </div>
      <div className='relative mr-1 mt-4 pb-4 pt-1'>
        <Checkbox
          name='consent'
          id='consent'
          onChange={onChangeField}
          onBlur={formik.handleBlur}
          checked={formik.values.consent}
          isError={isConsentInvalid}
          labelId='consent_area'
          className='RegisterForm__checkbox'
        >
          <div>
            <span>{t('I agree to')} </span>
            <a
              href={PublicRoutesEnum.TermsOfService}
              className='RegisterForm__link whitespace-nowrap'
              target='_blank'
              rel='noopener noreferrer'
              tabIndex={-1}
            >
              {t('Terms of Service')}
            </a>{' '}
            <span>{t('and')} </span>
            <a
              href={PublicRoutesEnum.DataProcessingAgreement}
              className='RegisterForm__link'
              target='_blank'
              rel='noopener noreferrer'
              tabIndex={-1}
            >
              {t('Data Processing Agreement')}
            </a>
            <span>{i18n.language === 'de' ? ' zu' : ''}</span>
          </div>
        </Checkbox>
        {isConsentInvalid && (
          <div
            className='RegisterForm__error absolute'
            style={{
              bottom: 0,
              left: 0,
            }}
            data-testid='consent-error'
          >
            {t(formik.errors.consent || '')}
          </div>
        )}
      </div>
      <div className='relative pb-4'>
        <Checkbox
          name='newsletter_agreement'
          id='newsletter_agreement'
          onChange={onChangeField}
          checked={formik.values.newsletter_agreement}
          labelId='newsletter_agreement_area'
          className='RegisterForm__checkbox'
        >
          {t('I want to receive news and updates from countX')}
        </Checkbox>
      </div>
      <Button
        className='Button--lg RegisterForm__btn my-2 !w-full'
        type='submit'
      >
        {t('create_account_btn_text')}
      </Button>
      {error && (
        <div className='RegisterForm__error mt-2 text-center'>{t(error)}</div>
      )}
    </form>
  );
}
