import gsap from 'gsap';
import { useEffect, useRef } from 'react';

export const DotsLoader = () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const dots = ref.current?.querySelectorAll('.dot');
    const tl = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.75,
    });

    dots?.forEach((dot, i) => {
      tl.fromTo(
        dot,
        { opacity: 0 },
        { opacity: 1, duration: 0.25, delay: i * 0.15, ease: 'power1.inOut' }
      );
    });
  }, []);

  return (
    <div className='text-h3' ref={ref}>
      <span className='dot'>.</span>
      <span className='dot'>.</span>
      <span className='dot'>.</span>
    </div>
  );
};
