import PageLayout from '../../wrappers/PageLayout';
import { useTranslation } from 'react-i18next';
import SellerManualUploadInstructionBlock from '@/components/SellerManualUploadInstructionBlock/SellerManualUploadInstructionBlock';
import { Loader } from '@/components/Loader/Loader';
import LabelSwitch from '@/components/LabelSwitch/LabelSwitch';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SellerDataUnit } from '@/components/SellerDataUnit/SellerDataUnit';
import { APIEventsEnum, useAppSelector, useSendEvent } from '../../hooks';
import {
  activeCompanySelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getDataUnits,
  getDataUnitsStats,
  getStopMailing,
  getUploadedDocumentsFiles,
  postDiscardFile,
} from '@/core/api';
import { SellerManualFiles } from '@/components/SellerManualFiles/SellerManualFiles';
import FileUploader from '@/components/FileUploader/FileUploader';
import Button from '@/components/Button';
import UploadIcon from '@/icons/upload_arrow.svg?react';
import ManualUploadFile from '@/components/ManualDataUploader/ManualUploadFile';
import SendIcon from '@/icons/sent.svg?react';
import { EmptyStateTile } from '@/components/EmptyStateTile';
import { ACCEPTED_EXTS } from '@/components/ManualDataUploader/ManualUploader.helpers';
import { useMU } from '../../context/useMU';
import { useEffect, useMemo, useState } from 'react';
import { DataUnitStatsItem } from '@/core/types';
import SellerInstructionWidget from '@/components/SellerInstructionWidget/SellerInstructionWidget';
import { useModal } from '@/components/Modal/useModal';
import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import useNotification from '@/hooks/useNotification';
import FeedbackWidget from '@/components/FeedbackWidget/FeedbackWidget';

type SwitchLabelType = 'All imports' | 'Manually uploaded';

export const SellerTurnoverNew = () => {
  const { sendEvent } = useSendEvent();

  const [fileIdForDeleting, setFileIdForDeleting] = useState<number>();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const activeCompany = useAppSelector(activeCompanySelector);
  const queryKey = useAppSelector(queryKeySelector);
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();

  const filesTabParam = searchParams.get('files');
  const isFilesTab = filesTabParam === '1';

  const { t, i18n } = useTranslation();
  const { isLoadingUploadedFiles, isLoadingDataUnits, dataUnits } = useMU();

  const manualUploadMailingStopped = useQuery({
    queryKey: ['SellerStopManualUploadMailing', queryKey],
    queryFn: () =>
      getStopMailing('manual-upload-needed', activeCompany?.company_id),
  });
  const {
    onSubmitFiles,
    onSelectFiles,
    onDeleteFileToUpload,
    uploadedFiles,
    filesToUpload,
    isUploadingFiles,
    dataUnitsStats,
    isLoadingDataUntisStats,
  } = useMU();

  const {
    isModalOpened: isConfirmDeleteFileModalOpened,
    closeModal: onCloseConfirmDeleteFileModal,
    openModal: openConfirmDeleteFileModal,
  } = useModal();

  useEffect(() => {
    sendEvent(APIEventsEnum.view_new_turnover_section, {
      block: 'customer opened the new turnover section',
    });
    // eslint-disable-next-line
  }, []);

  const onClickDeleteFile = (id: number) => {
    setFileIdForDeleting(id);
    openConfirmDeleteFileModal();
  };

  const DUStats = useMemo(() => {
    return dataUnitsStats?.reduce(
      (acc: { [key: number]: DataUnitStatsItem }, s: DataUnitStatsItem) => {
        acc[s.data_unit_id] = s;
        return acc;
      },
      {}
    );
  }, [dataUnitsStats]);

  const renderContent = () => {
    if (
      isLoadingUploadedFiles ||
      isLoadingDataUnits ||
      manualUploadMailingStopped.isLoading
    )
      return null;

    if (
      (!isFilesTab && !dataUnits?.length) ||
      (isFilesTab && !uploadedFiles?.length)
    ) {
      return (
        <EmptyStateTile
          text="Wait until documents will be imported from the data sources or upload the files manually if you don't have integrations"
          title='No data imported yet'
        />
      );
    }

    if (isFilesTab) {
      return (
        <SellerManualFiles
          uploadedFiles={uploadedFiles}
          onClickDeleteFile={onClickDeleteFile}
        />
      );
    }

    return (
      <>
        {(dataUnits || []).map((u) => (
          <SellerDataUnit
            onClickDeleteFile={onClickDeleteFile}
            key={u.id}
            unit={u}
            manualUploadMailingStopped={manualUploadMailingStopped.data}
            stats={DUStats?.[u.id]}
          />
        ))}
      </>
    );
  };

  const isProcessingFiles =
    isUploadingFiles ||
    uploadedFiles.filter((f) => !!f.update_progress).length > 0;

  return (
    <PageLayout
      className='flex-grow'
      rightColumn={
        <div style={{ maxWidth: 328 }} className='pt-4 lg:ml-auto lg:pt-0'>
          <FeedbackWidget testId='FeedbackWidget' />
          <SellerManualUploadInstructionBlock />
          <SellerInstructionWidget />
        </div>
      }
    >
      <div>
        {(isLoadingUploadedFiles ||
          isLoadingDataUnits ||
          manualUploadMailingStopped.isLoading ||
          isLoadingDataUntisStats) && <Loader />}
        <div className='mb-6 space-y-4'>
          <div className='rounded-lg bg-white pb-4 pl-4 pr-7 pt-2'>
            <div className='mb-2'>
              <div className='flex items-center justify-between'>
                <div className='text-h4 font-extrabold'>
                  {t('Upload turnover data')}
                </div>
                <div className='flex items-center gap-x-4'>
                  {isProcessingFiles ? (
                    <Button
                      disabled
                      className='Button flex !w-[140px] cursor-pointer items-center !pl-3'
                    >
                      <UploadIcon className='mr-2 [&>path]:fill-white' />
                      <span>{t('Upload file')}</span>
                    </Button>
                  ) : (
                    <FileUploader
                      onSelect={onSelectFiles}
                      name='manual-upload-file'
                      accept={ACCEPTED_EXTS}
                      className='Button flex !w-[140px] cursor-pointer items-center !pl-3'
                      multiple={false}
                    >
                      <UploadIcon className='mr-2 [&>path]:fill-white' />
                      <span>{t('Upload file')}</span>
                    </FileUploader>
                  )}
                </div>
              </div>
              <div>
                <p>
                  {t(
                    'Please upload turnover data files or files with corrections.'
                  )}
                </p>
                <p>
                  {t(
                    'Your file will be processed and made available within the designated period.'
                  )}
                </p>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                sendEvent(
                  APIEventsEnum.files_uploaded_from_new_turnover_section,
                  {
                    block:
                      'customer uploaded files from the new turnover section',
                  }
                );
                onSubmitFiles(e);
              }}
            >
              {!!filesToUpload.length && (
                <div className='pt-2'>
                  {filesToUpload.map((f) => (
                    <ManualUploadFile
                      className='mb-2 flex-grow'
                      onDeleteFile={onDeleteFileToUpload(f.name)}
                      file={f}
                      disabled={false}
                      key={f.name}
                      isUploading={isUploadingFiles}
                    />
                  ))}
                </div>
              )}
              {!!filesToUpload.length && !isUploadingFiles && (
                <div>
                  <Button
                    className='mt-4 flex items-center !py-1 !pl-4 !pr-6'
                    type='submit'
                    disabled={isUploadingFiles}
                    data-testid='manual-upload-submit'
                  >
                    <SendIcon className='mr-2' />
                    <span>{t('Submit file')}</span>
                  </Button>
                </div>
              )}
            </form>
          </div>
        </div>
        <div className='mb-2 flex items-center justify-between'>
          <span className='text-h4 font-black'>{t('Turnover data')}</span>
          <LabelSwitch<SwitchLabelType>
            activeItem={filesTabParam ? 'Manually uploaded' : 'All imports'}
            onClick={(v) => () => {
              if (v === 'All imports') {
                searchParams.delete('files');
              } else {
                searchParams.set('files', '1');
              }
              setSearchParams(searchParams, {
                state: location.state,
              });
            }}
            className={`font-semibold ${
              i18n.language === 'de' ? '!w-[355px]' : '!w-[320px]'
            }`}
            items={[
              { value: 'All imports', label: t('All imports') },
              { value: 'Manually uploaded', label: t('Manually uploaded') },
            ]}
          />
        </div>
        <div className='space-y-4'>{renderContent()}</div>
      </div>
      <ConfirmModal
        danger
        isModalOpened={isConfirmDeleteFileModalOpened}
        closeModal={onCloseConfirmDeleteFileModal}
        confirm={(setIsLoading) => {
          if (!fileIdForDeleting || !activeCompany) return;
          setIsLoading(true);
          postDiscardFile(fileIdForDeleting, activeCompany?.company_id)
            .then(() =>
              Promise.all([
                getDataUnits(activeCompany.company_id),
                getUploadedDocumentsFiles(activeCompany.company_id),
                getDataUnitsStats(activeCompany.company_id),
              ])
            )
            .then(([DURes, filesRes, DUStatsRes]) => {
              queryClient.setQueryData(['SellerDataUnits', queryKey], DURes);
              queryClient.setQueryData(
                ['SellerManualUploadFiles', queryKey],
                filesRes
              );
              queryClient.setQueryData(
                ['SellerDataUnitsStats', queryKey],
                DUStatsRes
              );
              setIsLoading(false);
              onCloseConfirmDeleteFileModal();
              setFileIdForDeleting(undefined);
              showNotification({
                text: 'The file has been deleted',
                type: 'success',
              });
            })
            .catch(() => {
              setIsLoading(false);
            });
        }}
        confirmBtnText='Confirm and delete'
        title='Deleting file'
      >
        <div className='mb-2 text-h4 font-extrabold'>
          {t('Confirm deleting this file')}
        </div>
        <div>
          {t('It will no longer be available for processing by countX')}
        </div>
      </ConfirmModal>
    </PageLayout>
  );
};
