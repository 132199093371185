import { useTranslation } from 'react-i18next';
import { NormalizedSellerFilingReport } from '../core/types';
import Modal from './Modal/Modal';
import Button from './Button';
import { useState } from 'react';
import MiniLoader from './MiniLoader/MiniLoader';
import { useAppSelector } from '../hooks';
import { activeCompanySelector } from '../core/store/user/userSlice';
import { RolesEnum } from '../core/enums';

interface Props {
  report: NormalizedSellerFilingReport | undefined;
  isModalOpened: boolean;
  closeModal: () => void;
  onSubmit: (setIsLoading: (v: boolean) => void) => () => void;
}

export default function SubmitBOPModal({
  report,
  isModalOpened,
  closeModal,
  onSubmit,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const activeCompany = useAppSelector(activeCompanySelector);
  const submissionBy = activeCompany?.ClientOSSDetails?.SubmissionBy;

  if (!report) return null;

  return (
    <Modal
      isOpened={isModalOpened}
      close={closeModal}
      title={t('Mark as submitted')}
      isLoading={isLoading}
      name='submit-bop-modal'
    >
      <div className='px-8 pb-6 pt-2'>
        <div className='mb-2 text-h4 font-extrabold'>
          {t('Mark the BOP file as submitted')}
        </div>
        <div className='mb-4'>
          {t(
            `${
              submissionBy === RolesEnum.SELLER
                ? 'When the file is submitted, you will be allowed to make a payment.'
                : 'Once you mark the file as submitted, the client will be notified and able to make a payment'
            }`
          )}
        </div>
        <div className='flex items-center gap-x-2'>
          <Button
            data-testid='submit-bop-modal-confirm-btn'
            onClick={onSubmit(setIsLoading)}
            disabled={isLoading}
            className='flex items-center'
          >
            <span>{t('Mark as submitted')}</span>
            {isLoading && (
              <MiniLoader className='ml-2' color='gray' size='sm' />
            )}
          </Button>
          <Button
            data-testid='submit-bop-modal-cancel-btn'
            onClick={closeModal}
            disabled={isLoading}
            styling='outline'
          >
            {t('Cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
