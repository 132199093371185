import { useTranslation } from 'react-i18next';
import JTLIcon from '@/icons/jtl.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import { AuthedRoutesEnum, JTLCredentialsStatusEnum } from '@/core/enums';
import AppIcon from '@/icons/app-icon.svg?react';
import { CreateJTLCredentialsDTO, JTLCredentials } from '@/core/types';
import { formatDate } from '@/core/helpers';
import { normalizeDateFE } from '@/core/normalization';
import InvalidIntegrationIcon from '@/icons/integration-invalid.svg?react';
import ConnectIcon from '@/icons/connect.svg?react';
import Button from '../Button';
import classNames from 'classnames';

interface Props {
  jtlCredentials?: JTLCredentials | CreateJTLCredentialsDTO;
  onClickConnect: () => void;
  prevRoute?: string;
}

export const JTLWidget = ({
  jtlCredentials,
  onClickConnect,
  prevRoute,
}: Props) => {
  const { t } = useTranslation();
  const { vs5973CpIntegrationsPage, vs6007CpJtlIntegrationLinking } =
    useFlags();

  const renderStatus = () => {
    if (!jtlCredentials) return null;

    switch (jtlCredentials?.status) {
      case JTLCredentialsStatusEnum.ConnectionEstablished:
        return <div className='mt-2'>{t('Connection established')}</div>;
      case JTLCredentialsStatusEnum.ConnectionWithoutFiling:
        return (
          <div className='mt-2'>
            <div>{t('Connection established')}</div>
            {jtlCredentials.filing_from && (
              <div>
                {t("We'll start getting data on *date*.").replace(
                  '*date*',
                  formatDate(
                    normalizeDateFE(jtlCredentials.filing_from),
                    'do MMMM, yyyy'
                  )
                )}
              </div>
            )}
          </div>
        );
      case JTLCredentialsStatusEnum.ConnectionLost:
        return (
          <div className='mt-2 rounded-lg bg-red-500 bg-opacity-10 px-2 py-1 font-semibold text-red-500'>
            {t('Connection lost. Please check the JTL connector for details.')}
          </div>
        );
      case JTLCredentialsStatusEnum.WaitingForSync:
        return (
          <div className='mt-2'>
            <span>{t('Connection established')}. </span>
            <span>
              {t('Synchronization status will be confirmed within 24 hours.')}
            </span>
          </div>
        );
      default:
        return (
          <div className='mt-2 rounded-lg bg-orange-200 px-2 py-1 font-semibold'>
            {t(
              'Credentials generated, but connection is not established. Please complete the connection setup.'
            )}
          </div>
        );
    }
  };
  return (
    <>
      <div
        className='flex items-center justify-between font-bold'
        data-testid='integrations-jtl'
      >
        <div className='flex items-center'>
          <img
            src={JTLIcon}
            alt='jtl'
            style={{
              width: 40,
              height: 40,
            }}
            className='mr-2 flex-shrink-0'
          />
          <span>JTL</span>
        </div>
        {vs6007CpJtlIntegrationLinking ? (
          <Link
            to={AuthedRoutesEnum.JtlInstruction}
            state={{ prevRoute }}
            className='Button--outline flex !w-[118px] items-center justify-center gap-x-1 !px-2 !py-1 hover:text-white'
          >
            {jtlCredentials?.status ===
            JTLCredentialsStatusEnum.ConnectionLost ? (
              <InvalidIntegrationIcon className='h-4 w-4' />
            ) : (
              <ConnectIcon />
            )}
            <span>{t('Connect')}</span>
          </Link>
        ) : (
          <Button
            styling='outline'
            onClick={onClickConnect}
            data-testid='jtl-btn'
            className={classNames(
              'flex !h-8 !w-[118px] items-center justify-center gap-x-1 !px-0 !py-1 ',
              {
                '!text-red-500':
                  jtlCredentials?.status ===
                  JTLCredentialsStatusEnum.ConnectionLost,
              }
            )}
          >
            {jtlCredentials?.status ===
            JTLCredentialsStatusEnum.ConnectionLost ? (
              <InvalidIntegrationIcon className='h-4 w-4' />
            ) : (
              <ConnectIcon />
            )}
            <span>{t('Connect')}</span>
          </Button>
        )}
      </div>
      {jtlCredentials ? (
        renderStatus()
      ) : vs5973CpIntegrationsPage &&
        location.pathname === AuthedRoutesEnum.Integrations ? (
        <div className='pb-2 pt-4'>
          {t(
            'Start using the application that enables countX to receive your data from JTL seamlessly. Any new invoices will be automatically added to countX as soon as they appear in the JTL system. We also perform checksum validations to ensure your data is always up-to-date.'
          )}
        </div>
      ) : null}
      {vs5973CpIntegrationsPage &&
      location.pathname === AuthedRoutesEnum.Integrations ? (
        <Link
          to={AuthedRoutesEnum.JtlInstruction}
          state={{ prevRoute }}
          className='Button !my-2 flex max-w-[183px] items-center justify-center gap-x-1 !px-2 !py-1 hover:text-white'
        >
          <AppIcon className='[&>g>path]:fill-white' />
          <span className='font-semibold'>{t('App & instructions')}</span>
        </Link>
      ) : null}
    </>
  );
};
