import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import './Shimmer.scss';

export default function Shimmer({
  children,
  type = 'light',
  maxWidth,
  width,
  className = '',
}: PropsWithChildren<{
  type?: 'light' | 'dark';
  maxWidth?: number;
  width?: number;
  className?: string;
}>) {
  return (
    <div
      className={`Shimmer relative overflow-hidden ${className}`}
      style={{ maxWidth, width: width || '100%' }}
    >
      {children}
      <div className='Shimmer__wrapper absolute h-full w-full'>
        <div
          className={classNames('Shimmer__element h-full w-1/2', {
            'Shimmer_element--dark': type === 'dark',
          })}
        />
      </div>
    </div>
  );
}
