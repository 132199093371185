import classNames from 'classnames';
import './LabelSwitch.scss';

interface Props<T> {
  onClick: (v: T) => () => void;
  activeItem: T;
  items: { value: T; label: string }[];
  className?: string;
}

export default function LabelSwitch<T>({
  onClick,
  activeItem,
  items,
  className = '',
}: Props<T>) {
  return (
    <div className={`LabelSwitch ${className}`}>
      <div className='LabelSwitch__row relative flex flex-wrap'>
        <div
          className={classNames(
            'LabelSwitch__indicator absolute h-full w-1/2',
            {
              'LabelSwitch__indicator--left': activeItem === items[0].value,
              'LabelSwitch__indicator--right': activeItem === items[1].value,
            }
          )}
        />
        {items.map((item) => (
          <div key={item.label} className='w-1/2'>
            <button
              className='LabelSwitch__btn relative block w-full py-1'
              onClick={onClick(item.value)}
              disabled={activeItem === item.value}
              type='button'
            >
              {item.label}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
