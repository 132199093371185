import { useTranslation } from 'react-i18next';
import { Country, InvoiceDocument, SelectOption } from '@/core/types';
import { capitalize, formatDate } from '@/core/helpers';
import { normalizeDateFE } from '@/core/normalization';
import CloseIcon from '@/icons/close-circle.svg?react';
import AlertIcon from '@/icons/alert-orange.svg?react';
import { Badge } from '@/components/Badge/Badge';
import { useEffect, useMemo, useState } from 'react';
import Tooltip from '@/components/Tooltip/Tooltip';
import {
  calculateValues,
  ErrorsMappingDE,
  ErrorsMappingEN,
} from './SellerDocuments.helpers';
import { FormikErrors, useFormik } from 'formik';
import classNames from 'classnames';
import Button from '@/components/Button';
import { Select } from '@/components/Select/Select';
import countries from '@/core/data/homeland_countries.json';
import { CURRENCY_SYMBOLS } from '@/core/constants';
import Modal from '@/components/Modal/Modal';
import MiniLoader from '@/components/MiniLoader/MiniLoader';
import { CurrencyInput } from '@/components/CurrencyInput/CurrencyInput';
import Input from '@/components/Input/Input';
import { getFloat } from '@/components/CurrencyInput/CurrencyInput.helpers';
import { SellerDocumentsDisclaimer } from '@/components/SellerDocumentsDisclaimer/SellerDocumentsDisclaimer';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  title?: string;
  confirmBtnText?: string;
  row: InvoiceDocument | undefined;
  onSubmit: (
    setIsLoading: (boolean: any) => void,
    data: PatchInvoiceDocument
  ) => void;
}

interface FormValues {
  b2b_buyer_vat_number: string;
  brutto_amount: string;
  net_amount: string;
  vat_amount: string;
  vat_rate: string;
  arrival_country: string | Country | undefined;
  departure_country: string | Country | undefined;
}

export interface PatchInvoiceDocument {
  b2b_buyer_vat_number: string;
  netto_amount_for_vat_rate: number;
  brutto_amount_for_vat_rate: number;
  vat_amount_for_vat_rate: number;
  vat_rate: number;
  arrival_country?: string;
  departure_country?: string;
}

interface ErrorsCount {
  warningCount: number;
  errorCount: number;
  errorCodes: string[];
  warningCodes: string[];
}

export default function EditInvoiceModal({
  isModalOpened,
  closeModal,
  row,
  onSubmit,
}: Props) {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const ErrorsMapping =
    i18n.language === 'en' ? ErrorsMappingEN : ErrorsMappingDE;

  const errors = row?.errors;
  const errorsCount: ErrorsCount | undefined = useMemo(
    () =>
      (errors || []).reduce<ErrorsCount>(
        (acc, err) => {
          if (err.severity === 'WARNING') {
            acc.warningCount++;
            acc.warningCodes.push(err.code);
          } else if (err.severity === 'ERROR') {
            acc.errorCount++;
            acc.errorCodes.push(err.code);
          }
          return acc;
        },
        { errorCount: 0, errorCodes: [], warningCount: 0, warningCodes: [] }
      ),
    [errors]
  );

  const countriesArray: SelectOption[] = (
    countries.filter((c) => c !== 'OTHER') as string[]
  )
    .map((c) => ({
      id: c,
      name: c.concat(`: ${t(c)}`),
    }))
    .sort((a, b) => t(a.id).localeCompare(t(b.id), i18n.language));

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      b2b_buyer_vat_number: row?.b2b_buyer_vat_number || '',
      brutto_amount:
        row?.brutto_amount !== undefined ? String(row.brutto_amount / 100) : '',
      net_amount:
        row?.net_amount !== undefined ? String(row.net_amount / 100) : '',
      vat_amount:
        row?.vat_amount !== undefined ? String(row.vat_amount / 100) : '',
      vat_rate: row?.vat_rate !== undefined ? String(row?.vat_rate) : '',
      arrival_country: row?.arrival_country,
      departure_country: row?.departure_country,
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.brutto_amount) {
        errors.brutto_amount = t('Required')!;
      }
      if (!values.net_amount) {
        errors.net_amount = t('Required')!;
      }
      if (!values.vat_amount) {
        errors.vat_amount = t('Required')!;
      }
      if (!values.vat_rate) {
        errors.vat_rate = t('Required')!;
      }
      if (!values.arrival_country) {
        errors.arrival_country = t('Required')!;
      }
      if (!values.departure_country) {
        errors.departure_country = t('Required')!;
      }

      return errors;
    },
    onSubmit: async (values: FormValues) => {
      const data: PatchInvoiceDocument = {
        b2b_buyer_vat_number: values.b2b_buyer_vat_number,
        brutto_amount_for_vat_rate: getFloat(values.brutto_amount),
        netto_amount_for_vat_rate: getFloat(values.net_amount),
        vat_amount_for_vat_rate: getFloat(values.vat_amount),
        vat_rate: getFloat(values.vat_rate),
        arrival_country: values.arrival_country as string,
        departure_country: values.departure_country as string,
      };
      onSubmit(setIsLoading, data);
    },
  });

  const onCountryChange =
    (field: 'departure_country' | 'arrival_country') =>
    (id: string | number) => {
      formik.setFieldValue(field, id, true);
    };

  const onCountrySelectBlur =
    (field: 'departure_country' | 'arrival_country') => () => {
      formik.setFieldTouched(field, true, true);
    };

  useEffect(() => {
    if (isModalOpened) {
      formik.resetForm();
    }
    // eslint-disable-next-line
  }, [isModalOpened]);

  const renderTooltipContent = (
    <div className='max-w-[294px] divide-y divide-dark'>
      {!!errorsCount?.errorCount && (
        <div>
          <div className='pt-1 font-semibold'>{t('Errors')}</div>
          <ol className='ml-4 list-decimal space-y-2 divide-y pb-2 '>
            {errorsCount.errorCodes.map((e, i) => {
              return (
                <li className='whitespace-pre-line pt-2' key={i}>
                  {ErrorsMapping[e]}
                </li>
              );
            })}
          </ol>
        </div>
      )}
      {!!errorsCount?.warningCount && (
        <div>
          <div className='pt-1 font-semibold'>{t('Notices')}</div>
          <ol className='ml-4 list-decimal space-y-2 divide-y pb-2 '>
            {errorsCount.warningCodes.map((e, i) => (
              <li className='whitespace-pre-line pt-2' key={i}>
                {ErrorsMapping[e]}
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );

  const initialNetto = +(row?.net_amount || 0);
  const initialVatAmount = +(row?.vat_amount || 0);
  const brutto = getFloat(formik.values.brutto_amount || '') * 100;
  const vatRate = getFloat(formik.values.vat_rate);
  const {
    bruttoAmountSuggested,
    isBruttoNeededCorrection,
    isNettoNeededCorrection,
    isVatNeededCorrection,
    vatAmountSuggested,
    nettoAmountSuggested,
  } = useMemo(
    () => calculateValues(initialNetto, brutto, initialVatAmount, vatRate),
    [initialNetto, initialVatAmount, vatRate, brutto]
  );

  const onChangeField: (
    v: keyof FormValues
  ) => (k: string | undefined) => void = (field: keyof FormValues) => (v) => {
    const value = v || '';
    setTimeout(() => {
      formik.setFieldValue(field, value, true);
    }, 0);
  };
  const isBtnDisabled =
    !row ||
    (((!row.b2b_buyer_vat_number &&
      formik.values.b2b_buyer_vat_number === '') ||
      row.b2b_buyer_vat_number === formik.values.b2b_buyer_vat_number) &&
      ((row.brutto_amount === undefined &&
        formik.values.brutto_amount === '') ||
        row.brutto_amount ===
          Math.round(getFloat(formik.values.brutto_amount) * 100)) &&
      ((row.net_amount === undefined && formik.values.net_amount === '') ||
        row.net_amount ===
          Math.round(getFloat(formik.values.net_amount) * 100)) &&
      ((row.vat_amount === undefined && formik.values.vat_amount === '') ||
        row.vat_amount ===
          Math.round(getFloat(formik.values.vat_amount) * 100)) &&
      ((row.vat_rate === undefined && formik.values.vat_rate === '') ||
        row.vat_rate === Math.round(getFloat(formik.values.vat_rate))) &&
      row.arrival_country === formik.values.arrival_country &&
      row.departure_country === formik.values.departure_country);

  return (
    <Modal
      isOpened={isModalOpened}
      close={closeModal}
      title={t('Document')}
      isLoading={isLoading}
      data-testid={'document-confirm-modal'}
    >
      <div className='mx-4 px-4 pb-6 pt-2'>
        <div className='flex items-center justify-between'>
          <div className='text-h4 font-bold'>
            {!!row?.invoice_number ? row.invoice_number : t('Document')}
          </div>
          <Tooltip
            content={renderTooltipContent}
            styling='white'
            className='!whitespace-normal'
            wrapperClassName='block'
            position='bottom'
          >
            <div className='flex min-w-[300px] justify-end'>
              <Badge
                label={
                  <div className='flex items-center gap-x-2'>
                    <span>
                      {!!errorsCount?.errorCount ? (
                        !!errorsCount?.warningCount ? (
                          <span>
                            {t('Contains errors')}: {errorsCount.errorCount},
                            {t(capitalize('notices'))}:{' '}
                            {errorsCount.warningCount}
                          </span>
                        ) : (
                          <span>
                            {t('Contains errors')}: {errorsCount.errorCount}
                          </span>
                        )
                      ) : errorsCount?.warningCount ? (
                        <span>
                          {t('Contains notices')}: {errorsCount?.warningCount}
                        </span>
                      ) : null}
                    </span>
                    <AlertIcon className='h-4 w-4' />
                  </div>
                }
                color={`${errorsCount?.errorCount ? 'red' : 'orange'}`}
                className='cursor-pointer !py-0'
              />
            </div>
          </Tooltip>
        </div>
        <div>
          {row?.type ? (
            <span>{`${capitalize(row.type?.toLowerCase())} • `}</span>
          ) : null}
          {row?.document_date ? (
            <span>{`${formatDate(
              normalizeDateFE(row.document_date),
              'dd.MM.yyyy'
            )} • `}</span>
          ) : null}
          {!!row?.errors.length ? (
            <>
              <span className='mr-1'>{t('Not included to filing')}</span>
              <CloseIcon />
            </>
          ) : (
            <span>{t('Included to filing')}</span>
          )}
        </div>
        <SellerDocumentsDisclaimer className='mb-4 mt-2 bg-gray-400' />
        <div className='mb-4 flex gap-x-6'>
          <div className='w-1/3 font-semibold'>{t('Taxed at')}</div>
          <div className='flex w-2/3 flex-col'>
            <span className='font-semibold'>
              {row?.vat_country &&
                `${row.vat_country} • ${t(
                  row.vat_country as unknown as string
                )} • `}
              {row?.is_oss ? 'OSS' : 'Local'}
            </span>
          </div>
        </div>
        <div className='flex gap-x-6'>
          <div className='w-1/3 pt-2 font-semibold'>
            {t('Buyer VAT number')}
          </div>
          <div className='flex w-2/3 flex-col'>
            <Input
              name='b2b_buyer_vat_number'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.b2b_buyer_vat_number}
              error={formik.errors.b2b_buyer_vat_number}
              touched={formik.touched.b2b_buyer_vat_number}
              className='Input__input__small !border-gray-500 !px-3 !py-2'
              wrapperClassName={classNames('max-w-[200px]', {
                'text-red-500': formik.errors.b2b_buyer_vat_number,
              })}
            />
            {formik.values.b2b_buyer_vat_number && (
              <>
                <span>{t('Not a B2B transaction?')}</span>
                <span>
                  {t('Then')}{' '}
                  <Button
                    className='!p-0 !font-semibold !text-blue-500'
                    styling='naked'
                    onClick={() =>
                      formik.setFieldValue('b2b_buyer_vat_number', '')
                    }
                  >
                    {t('delete buyer VAT number')}
                  </Button>
                </span>
              </>
            )}
          </div>
        </div>
        <div className='mb-4 flex items-center gap-x-6'>
          <div className='w-1/3 font-semibold'>{t('Departure country')}</div>
          <div className='z-20 flex w-2/3 flex-col'>
            <Select
              name='country'
              value={formik.values.departure_country as string}
              placeholder='Select'
              options={countriesArray}
              onChange={onCountryChange('departure_country')}
              error={formik.errors.departure_country}
              touched={formik.touched.departure_country}
              onBlur={onCountrySelectBlur('departure_country')}
              className='Select--InvoiceBook !z-100 max-w-[200px] !pb-0'
              search
            />
          </div>
        </div>
        <div className='mb-4 flex items-center gap-x-6'>
          <div className='w-1/3 font-semibold'>{t('Arrival country')}</div>
          <div className='flex w-2/3 flex-col'>
            <Select
              name='country'
              value={formik.values.arrival_country as string}
              placeholder='Select'
              options={countriesArray}
              onChange={onCountryChange('arrival_country')}
              error={formik.errors.arrival_country}
              touched={formik.touched.arrival_country}
              onBlur={onCountrySelectBlur('arrival_country')}
              className='Select--InvoiceBook max-w-[200px] !pb-0'
              search
            />
          </div>
        </div>
        <div className='flex items-start gap-x-6'>
          <div className='w-1/3 pt-2 font-semibold'>{t('Brutto amount')}</div>
          <div className='w-2/3'>
            <div className='flex items-center gap-x-2'>
              <CurrencyInput
                name='brutto_amount'
                onChange={onChangeField('brutto_amount')}
                onBlur={formik.handleBlur}
                value={formik.values.brutto_amount}
                error={formik.errors.brutto_amount}
                touched={formik.touched.brutto_amount}
                className='Input__input__small !border-gray-500 !px-3 !py-2'
                wrapperClassName={classNames('max-w-[200px]', {
                  'text-red-500': formik.errors.brutto_amount,
                })}
              />
              <span className='font-semibold text-gray-700'>
                {row?.currency && CURRENCY_SYMBOLS[row?.currency]}
              </span>
            </div>
            {isBruttoNeededCorrection &&
              bruttoAmountSuggested / 100 !==
                getFloat(formik.values.brutto_amount) && (
                <div className='items flex max-w-[200px] items-center justify-between pb-1'>
                  <div className='text-base text-gray-700'>
                    {t('Suggested')}
                  </div>
                  <Button
                    className='!p-0 !font-semibold !text-blue-500 underline'
                    styling='naked'
                    onClick={() =>
                      formik.setFieldValue(
                        'brutto_amount',
                        String(bruttoAmountSuggested / 100)
                      )
                    }
                  >
                    {bruttoAmountSuggested / 100}
                  </Button>
                </div>
              )}
          </div>
        </div>
        <div className='flex items-start gap-x-6'>
          <div className='w-1/3 pt-2 font-semibold'>{t('Netto amount')}</div>
          <div className='w-2/3'>
            <div className='flex items-center gap-x-2'>
              <CurrencyInput
                name='net_amount'
                onChange={onChangeField('net_amount')}
                onBlur={formik.handleBlur}
                value={formik.values.net_amount}
                error={formik.errors.net_amount}
                touched={formik.touched.net_amount}
                className='Input__input__small !border-gray-500 !px-3 !py-2'
                wrapperClassName={classNames('max-w-[200px]', {
                  'text-red-500': formik.errors.net_amount,
                })}
              />
              <span className='font-semibold text-gray-700'>
                {row?.currency && CURRENCY_SYMBOLS[row?.currency]}
              </span>
            </div>
            {isNettoNeededCorrection &&
              nettoAmountSuggested / 100 !==
                getFloat(formik.values.net_amount) && (
                <div className='items flex max-w-[200px] items-center justify-between pb-1'>
                  <div className='text-base text-gray-700'>
                    {t('Suggested')}
                  </div>
                  <Button
                    className='!p-0 !font-semibold !text-blue-500 underline'
                    styling='naked'
                    onClick={() =>
                      formik.setFieldValue(
                        'net_amount',
                        String(nettoAmountSuggested / 100)
                      )
                    }
                  >
                    {nettoAmountSuggested / 100}
                  </Button>
                </div>
              )}
          </div>
        </div>
        <div className='flex items-start gap-x-6'>
          <div className='w-1/3 pt-2 font-semibold'>{t('VAT amount')}</div>
          <div className='w-2/3'>
            <div className='flex items-center gap-x-2'>
              <CurrencyInput
                name='vat_amount'
                onChange={onChangeField('vat_amount')}
                onBlur={formik.handleBlur}
                value={formik.values.vat_amount}
                error={formik.errors.vat_amount}
                touched={formik.touched.vat_amount}
                className='Input__input__small !border-gray-500 !px-3 !py-2'
                wrapperClassName={classNames('max-w-[200px]', {
                  'text-red-500': formik.errors.vat_amount,
                })}
              />
              <span className='font-semibold text-gray-700'>
                {row?.currency && CURRENCY_SYMBOLS[row?.currency]}
              </span>
            </div>
            {isVatNeededCorrection &&
              vatAmountSuggested / 100 !==
                getFloat(formik.values.vat_amount) && (
                <div className='items flex max-w-[200px] items-center justify-between pb-1'>
                  <div className='text-base text-gray-700'>
                    {t('Suggested')}
                  </div>
                  <Button
                    className='!p-0 !font-semibold !text-blue-500 underline'
                    styling='naked'
                    onClick={() =>
                      formik.setFieldValue(
                        'vat_amount',
                        String(vatAmountSuggested / 100)
                      )
                    }
                  >
                    {vatAmountSuggested / 100}
                  </Button>
                </div>
              )}
          </div>
        </div>
        <div className='mb-2 flex items-center gap-x-6'>
          <div className='w-1/3 font-semibold'>{t('VAT rate')}</div>
          <div className='flex w-2/3 items-center gap-x-2'>
            <CurrencyInput
              name='vat_rate'
              onChange={(v) => {
                const value = v || '';
                setTimeout(
                  () =>
                    formik.setFieldValue(
                      'vat_rate',
                      +(value || 0) > 100 ? '100' : value,
                      true
                    ),
                  0
                );
              }}
              onBlur={formik.handleBlur}
              value={formik.values.vat_rate}
              error={formik.errors.vat_rate}
              touched={formik.touched.vat_rate}
              className='Input__input__small !border-gray-500 !px-3 !py-2'
              wrapperClassName={classNames('max-w-[200px]', {
                'text-red-500': formik.errors.vat_rate,
              })}
            />
            <span className='font-semibold text-gray-700'>%</span>
          </div>
        </div>
        <div className='mt-2 flex flex-wrap gap-x-2'>
          <div>
            <Button
              className='flex items-center'
              disabled={isBtnDisabled || isLoading}
              onClick={() => formik.submitForm()}
            >
              {isLoading && <MiniLoader className='mr-2' color='gray' />}
              <span>{t('Apply changes')}</span>
            </Button>
          </div>
          <div>
            <Button styling='outline' onClick={closeModal} disabled={isLoading}>
              {t('Cancel')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
