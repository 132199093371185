import { useTranslation } from 'react-i18next';
import Attention from '@/icons/attention.svg?react';
import './Comment.scss';

interface Props {
  comment: string;
  className?: string;
}

export default function Comment({ comment, className = '' }: Props) {
  const { t } = useTranslation();
  return (
    <div className={`Comment px-4 pb-4 pt-2 ${className}`}>
      <div className='mb-2 flex items-center'>
        <Attention className='mr-2' />
        <div className='font-extrabold'>{t('Please note')}</div>
      </div>
      <div className='whitespace-pre-line'>{comment}</div>
    </div>
  );
}
