import {
  ChangeEventHandler,
  DragEventHandler,
  PropsWithChildren,
  useRef,
} from 'react';
import { AllOWED_FILE_EXTENSIONS } from './FileUploader.helpers';
interface Props {
  accept?: string[];
  name: string;
  onSelect: (files: File[] | null) => void;
  className?: string;
  multiple?: boolean;
}

export default function FileUploader({
  name,
  accept = AllOWED_FILE_EXTENSIONS,
  onSelect,
  className = '',
  multiple = true,
  children,
}: PropsWithChildren<Props>) {
  const ref = useRef<HTMLInputElement>(null);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onSelect(e.target.files ? Array.from(e.target.files) : null);
    (e.target as HTMLInputElement).value = '';
  };

  const preventDefaultDragEvents: DragEventHandler<HTMLLabelElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop: DragEventHandler<HTMLLabelElement> = (e) => {
    preventDefaultDragEvents(e);
    onSelect(e.dataTransfer.files ? Array.from(e.dataTransfer.files) : null);
    (e.target as HTMLInputElement).value = '';
  };

  return (
    <label
      className={className}
      onDrop={onDrop}
      onDragEnter={preventDefaultDragEvents}
      onDragLeave={preventDefaultDragEvents}
      onDragOver={preventDefaultDragEvents}
    >
      {children}
      <input
        name={name}
        type='file'
        onChange={onChange}
        className='hidden'
        accept={accept.map((str) => `.${str}`).join(', ')}
        ref={ref}
        multiple={multiple}
      />
    </label>
  );
}
