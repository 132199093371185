import { UploadedFile } from '@/core/types';
import TipIcon from '@/icons/tip-green-circle.svg?react';
import ErrorIcon from '@/icons/file-error.svg?react';
import SubdirectoryIcon from '@/icons/subdirectory.svg?react';
import DownloadIcon from '@/icons/download-file.svg?react';
import CancelIcon from '@/icons/cancel.svg?react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import MiniLoader from '../MiniLoader/MiniLoader';
import { downloadFile } from '@/core/helpers';
import Tooltip from '../Tooltip/Tooltip';
import Button from '../Button';
import { useAppSelector } from '../../hooks';
import { activeCompanySelector } from '@/core/store/user/userSlice';
import { getListOfErrorsLink, getUploadedDocumentFileUrl } from '@/core/api';
import FileIcon from '../FileIcon';
import { ManualUploadFileStatusEnum } from '@/core/enums';
import BucketIcon from '@/icons/bucket.svg?react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ControlledCollapse } from '../Collapse/ControlledCollapse';

interface Props {
  file: UploadedFile;
  collapsed?: boolean;
  isShownCheckmark?: boolean;
  errorListUploader?: JSX.Element;
  styling?: 'dataUnitTab' | 'manualFilesTab';
  onClickDelete: () => void;
}

export default function UploadedDocumentFileLink({
  file,
  collapsed = true,
  isShownCheckmark = false,
  errorListUploader,
  styling = 'dataUnitTab',
  onClickDelete,
}: Props) {
  const { vs5539DiscardFileCp } = useFlags();

  const activeCompany = useAppSelector(activeCompanySelector);
  const { t } = useTranslation();

  const hasErrors = !!file.errors?.length;
  const hasHCEErrors =
    !!file.errors_message && file.documents_with_error_number > 0;

  const handleDownload = async () => {
    const parts = `${file.bucket}/${file.path}`.split('/');

    try {
      const url = await getUploadedDocumentFileUrl(
        file.id,
        activeCompany?.company_id
      );
      downloadFile(url, parts[parts.length - 1]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadErrorList = async (id: number, filename: string) => {
    if (!activeCompany) return;
    getListOfErrorsLink(id, activeCompany.company_id)
      .then((res) => {
        downloadFile(res, filename);
      })
      .catch(console.error);
  };

  const errorsBlock = (
    <>
      <div className='Text--orange font-semibold'>{t('File not accepted')}</div>
      {(file.errors || []).map((err, i) => (
        <div className='mb-1' key={i}>
          {err}
        </div>
      ))}
    </>
  );

  const modifiedName = file.name
    .split('.')
    .map((v, i, self) => {
      if (i === self.length - 2) {
        return v.replace(/_\d+$/, '..');
      }
      return v;
    })
    .join('.');

  const fileExtension = file.name.split('.').slice(-1)[0]!.toLowerCase();

  const deleteBtn = vs5539DiscardFileCp &&
    file.uploaded_by_client &&
    !file.documents_in_turnover_number && (
      <button
        className='Modal__btn Modal__closeBtn flex items-center justify-center'
        onClick={onClickDelete}
      >
        <BucketIcon />
      </button>
    );

  if (hasErrors) {
    return (
      <div className={`${styling !== 'manualFilesTab' && 'py-2'}`}>
        <ControlledCollapse
          initialOpened={!collapsed}
          triggerRenderer={(isOpened, toggleCollapse) => (
            <div className='flex flex-col items-start justify-between'>
              <div className='ManualUploadFileLinkV3__info flex w-full flex-grow items-start justify-between'>
                <div className='flex w-full'>
                  <div className='ManualUploadFileLinkV3__icon relative mr-2 rounded-full'>
                    <FileIcon
                      styling='circled'
                      ext={fileExtension}
                      className='h-12 w-12 [&>g>path:first-child]:fill-gray-600'
                    />
                    <CancelIcon className='absolute right-0.5 top-0.5 !h-4 !w-4 [&>g>path]:fill-red-500' />
                  </div>
                  <div className='flex flex-col items-start justify-center'>
                    <div
                      className='grid min-w-0 cursor-pointer font-semibold transition-colors hover:text-green'
                      onClick={handleDownload}
                    >
                      <div className='truncate'>{modifiedName}</div>
                    </div>
                    {styling !== 'manualFilesTab' && (
                      <div className='flex items-center text-sm text-gray-700'>
                        {format(new Date(file.imported_at), 'dd.MM.yyyy HH:mm')}
                        {!file.uploaded_by_client &&
                          file.source === 'manual' &&
                          t('Uploaded by countX')}
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex items-center gap-x-1'>
                  {deleteBtn}
                  {styling !== 'manualFilesTab' && (
                    <Tooltip content={t('File not accepted')}>
                      <div>
                        <ErrorIcon className='h-6 w-6' />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>

              {!isOpened && (
                <Button
                  styling='naked-blue'
                  className='!pl-14 !font-semibold'
                  onClick={toggleCollapse}
                >
                  {t('Show details')}
                </Button>
              )}
            </div>
          )}
          contentRenderer={(toggleCollapse) => (
            <div className='pl-12'>
              <div
                className={`-mb-1 pb-2 pr-2 ${
                  styling === 'manualFilesTab' ? 'max-w-[100%]' : 'max-w-[70%]'
                }`}
              >
                {errorsBlock}
              </div>
              <Button
                styling='naked-blue'
                className='!font-semibold'
                onClick={toggleCollapse}
              >
                {t('Hide details')}
              </Button>
            </div>
          )}
        />
      </div>
    );
  }

  return (
    <>
      <div className='flex w-full items-start justify-between gap-x-6'>
        <div className='flex w-full'>
          <div className='ManualUploadFileLinkV3__icon relative mr-2 rounded-full'>
            <FileIcon
              styling='circled'
              ext={fileExtension}
              className='h-12 w-12'
            />
          </div>
          <div className='flex flex-col items-start justify-center'>
            <div
              className='grid min-w-0 cursor-pointer font-semibold transition-colors hover:text-green'
              onClick={handleDownload}
            >
              <div className='truncate'>{modifiedName}</div>
            </div>
            {styling !== 'manualFilesTab' && (
              <div className='flex items-center text-sm text-gray-700'>
                {format(new Date(file.imported_at), 'dd.MM.yyyy HH:mm')}
              </div>
            )}
          </div>
        </div>

        {styling === 'dataUnitTab' && isShownCheckmark && (
          <div className='flex items-center gap-x-1'>
            {file.status === ManualUploadFileStatusEnum.CREATED ? (
              <div className='pt-1'>
                <MiniLoader />
              </div>
            ) : (
              <>
                {deleteBtn}
                <Tooltip content={t('File is accepted')}>
                  <TipIcon className='h-6 w-6' />
                </Tooltip>
              </>
            )}
          </div>
        )}
      </div>
      {!activeCompany?.IsUseNewProcess &&
        hasHCEErrors &&
        styling === 'dataUnitTab' && (
          <div className='flex flex-col pl-3'>
            <div className='flex items-center gap-x-5 py-1'>
              <SubdirectoryIcon />
              <Button
                className='Button--outline flex cursor-pointer items-center gap-x-0.5 !py-0 !pl-3'
                onClick={() =>
                  handleDownloadErrorList(
                    file.errors_message!.id,
                    file.errors_message!.filename
                  )
                }
              >
                <DownloadIcon className='!w-[24px]' />
                <span>{t('Download error list')}</span>
              </Button>
              {errorListUploader}
            </div>
            <div className='px-8'>{file.errors_message!.message}</div>
          </div>
        )}
    </>
  );
}
