import classNames from 'classnames';
import { FocusEventHandler, PropsWithChildren } from 'react';
import CurrencyInputField from 'react-currency-input-field';

type Props = {
  id?: string;
  name: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  touched?: boolean;
  error?: string;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onChange: (v: string | undefined) => void;
  value: string | undefined;
  wrapperClassName?: string;
  labelClassName?: string;
  decimalScale?: number;
  errorClassName?: string;
};

export function CurrencyInput({
  id,
  name,
  className = '',
  disabled = false,
  placeholder,
  label,
  touched,
  error,
  onBlur,
  onFocus,
  value,
  onChange,
  children,
  wrapperClassName = '',
  labelClassName = '',
  decimalScale = 2,
  errorClassName = '',
}: PropsWithChildren<Props>) {
  return (
    <div className={`Input ${wrapperClassName}`}>
      {label && (
        <div
          className={`Input__label ${labelClassName}`}
          data-testid={`${name}-label`}
        >
          {label}
        </div>
      )}
      <div className='relative'>
        <CurrencyInputField
          inputMode='decimal'
          id={id}
          name={name}
          onValueChange={(value) => {
            onChange(value);
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={placeholder}
          disabled={disabled}
          className={classNames('Input__input', className, {
            'Input__input--invalid': touched && !!error,
          })}
          data-testid={name}
          decimalScale={decimalScale}
          decimalSeparator=','
          groupSeparator='.'
          value={value}
        />
        {children}
      </div>
      {touched && error && (
        <div
          className={`Input__error ${errorClassName}`}
          data-testid={`${name}-error`}
        >
          {error}
        </div>
      )}
    </div>
  );
}
