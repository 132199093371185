import Header from '@/components/Header/Header';
import { useAppSelector } from '../../hooks';
import {
  activeCompanySelector,
  companiesSelector,
} from '@/core/store/user/userSlice';
import { RolesEnum, AuthedRoutesEnum } from '@/core/enums';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import SellerDatevOverview from '../DatevTransactionsOverview';
import OSSInstruction from '../OSS/OSSInstruction';
import { Category, Sidebar } from '@/components/Sidebar/Sidebar';
import { NavLink } from 'react-router-dom';
import GridIcon from '@/icons/grid.svg?react';
import { useTranslation } from 'react-i18next';
import AggregatorDropdown from '@/components/AggregatorDropdown';
import DatevIcon from '@/icons/datev.svg?react';
import OSSFilesIcon from '@/icons/file.svg?react';
import SellerDatev from '../Seller/SellerDatev/SellerDatev';
import { TaxAdvisorDashboard } from './TaxAdvisorDashboard';
import { OSSFiles } from '../OSSFiles';
import { InvoiceBook } from '../InvoiceBook/InvoiceBook';
import InvoiceBookIcon from '@/icons/invoice-book.svg?react';
import { CurrentReporterEnum, PeriodTypeEnum } from '@/core/types';
import { subMonths } from 'date-fns';
import { NPSModalProvider } from '@/components/NPSModal/nps-context';
import { NPSModal } from '@/components/NPSModal/NPSModal';
import { ToastProvider } from '@/context/toast-context';
import { WebSocketProvider } from '@/core/ws/ws-context';
import { Toasts } from '@/components/Toasts';
import { FeedbackProvider } from '@/components/FeedbackWidget/feedback-context';

export default function TaxAdvisorRoutes() {
  const activeCompany = useAppSelector(activeCompanySelector);
  const [searchParams] = useSearchParams();
  const companies = useAppSelector(companiesSelector);
  const isOSSSelfService =
    activeCompany?.ClientOSSDetails?.CurrentReporter &&
    activeCompany.ClientOSSDetails.CurrentReporter ===
      CurrentReporterEnum.ossSelfService &&
    activeCompany.ClientOSSDetails.SubmissionBy === RolesEnum.TAX_ADVISOR;

  const location = useLocation();

  const isSeparateInvoiceBookPage =
    location.pathname === AuthedRoutesEnum.InvoiceBook &&
    searchParams.get('flow') === CurrentReporterEnum.ossSelfService;

  const { t } = useTranslation();

  const categories: Category[] = [
    {
      name: 'Files',
      links: [
        ...(isOSSSelfService
          ? [
              {
                label: 'OSS files',
                icon: <OSSFilesIcon />,
                to: AuthedRoutesEnum.OSSFiles,
              },
            ]
          : []),
        {
          label: 'Invoice book',
          icon: <InvoiceBookIcon />,
          to: (() => {
            const qp = new URLSearchParams(location.search);
            const date = new Date();
            qp.set('year', String(date.getFullYear()));
            qp.set('type', PeriodTypeEnum.month);
            qp.set('value', String(subMonths(date, 1).getMonth()));
            qp.set('company_id', activeCompany?.company_id || '');
            return {
              pathname: AuthedRoutesEnum.InvoiceBook,
              search: qp.toString(),
            };
          })(),
        },
        {
          label: 'DATEV',
          icon: <DatevIcon />,
          to: AuthedRoutesEnum.Datev,
        },
      ],
    },
  ];

  return (
    <WebSocketProvider>
      <NPSModalProvider>
        <FeedbackProvider>
          <ToastProvider>
            <div className='flex'>
              {!isSeparateInvoiceBookPage && (
                <Sidebar
                  categories={categories}
                  header={
                    <>
                      <div className='pt-2'>
                        {companies.length > 1 ? (
                          <AggregatorDropdown />
                        ) : (
                          <div className='mb-2 truncate font-bold'>
                            {activeCompany?.name}
                          </div>
                        )}
                      </div>
                      {isOSSSelfService && (
                        <NavLink
                          to='/'
                          className='Sidebar__link -mx-2 mb-2 flex items-center py-1 pl-2 no-underline'
                          data-testid='sidebar-link-Global overview'
                        >
                          <GridIcon />
                          <span className='ml-1'>{t('Global overview')}</span>
                        </NavLink>
                      )}
                    </>
                  }
                />
              )}
              <div className='flex-grow'>
                <div className='flex flex-col'>
                  <div className='flex min-h-screen flex-col'>
                    <Header
                      logo={false}
                      backBtn={isSeparateInvoiceBookPage}
                      backBtnLabel='Back'
                      label={
                        isSeparateInvoiceBookPage
                          ? t('Invoice book')
                          : undefined
                      }
                      backBtnPath={
                        isSeparateInvoiceBookPage
                          ? location.state && location.state.prevRoute
                            ? location.state.prevRoute
                            : AuthedRoutesEnum.OSSFiles
                          : undefined
                      }
                      isShownBtn={isSeparateInvoiceBookPage ? false : true}
                    />
                    <Routes>
                      <Route
                        path='/'
                        element={
                          isOSSSelfService ? (
                            <TaxAdvisorDashboard />
                          ) : (
                            <Navigate to={AuthedRoutesEnum.Datev} />
                          )
                        }
                      />
                      {isOSSSelfService && (
                        <Route
                          path={AuthedRoutesEnum.OSSFiles}
                          element={<OSSFiles />}
                        />
                      )}
                      <Route
                        path={AuthedRoutesEnum.Datev}
                        element={<SellerDatev />}
                      />
                      <Route
                        path={AuthedRoutesEnum.DatevOverview}
                        element={<SellerDatevOverview />}
                      />
                      <Route
                        path={AuthedRoutesEnum.OSSInstruction}
                        element={<OSSInstruction className='bg-gray-300' />}
                      />
                      <Route
                        path={AuthedRoutesEnum.InvoiceBook}
                        element={<InvoiceBook />}
                      />
                      <Route path='*' element={<Navigate to={'/'} />} />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
            <NPSModal />
            <Toasts />
          </ToastProvider>
        </FeedbackProvider>
      </NPSModalProvider>
    </WebSocketProvider>
  );
}
