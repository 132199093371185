import { DUSourceItem } from '@/core/types';
import { SourceIcon } from '@/components/SourceIcon';
import { normalizeDateFE } from '@/core/normalization';
import { formatDate } from '@/core/helpers';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface Props {
  data: DUSourceItem;
}

const LABELS = {
  manual: 'Manual',
  easybill: 'Easybill import',
  billbee: 'Billbee import',
  amazon: 'Amazon import',
};

export const SourceTile = ({ data }: Props) => {
  const { t } = useTranslation();
  return (
    <div className='relative'>
      <div
        className={classNames(
          'relative z-10 flex items-center gap-x-2 rounded-lg border bg-white py-0.5 pl-1 pr-2',
          {
            'py-[6px]': !data.files.length,
          }
        )}
      >
        <SourceIcon source={data.source_type} className='h-8 w-8' />
        <div className='flex gap-x-2'>
          <div>
            <div className='font-semibold leading-tight'>
              {t(LABELS[data.source_type])}
            </div>
            <div className='text-xs text-gray-700'>
              {!!data.files.length &&
                formatDate(
                  normalizeDateFE(
                    data.files.sort(
                      (a, b) =>
                        normalizeDateFE(b.imported_at).getTime() -
                        normalizeDateFE(a.imported_at).getTime()
                    )[0].imported_at
                  ),
                  'dd.MM.yyyy HH:mm'
                )}
            </div>
          </div>
          <span className='-mt-1 self-start font-semibold'>
            {data.files.length > 1 && data.files.length}
          </span>
        </div>
      </div>
      {data.files.length > 1 && (
        <div className='absolute -bottom-1 -right-1 h-full w-full rounded-lg border'></div>
      )}
    </div>
  );
};
