import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import 'array-flat-polyfill';
import 'simplebar-react/dist/simplebar.min.css';
import './index.css';
import './index.scss';
import store from './core/store';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { HelmetProvider } from 'react-helmet-async';
import './translations/i18n';
import { VisitorProvider } from './context/visitorContext';
import 'intersection-observer';
import App from './App';
import { HubspotBookMeetingModalWrapper } from './components/BookMeetingModal/bookMeetingModalContext';
import 'promise-polyfill/src/polyfill';

import * as Sentry from '@sentry/react';
import { INITIAL_LD_KEY } from './core/constants';
import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import 'polyfill-object.fromentries';
import { PaymentOverdueModalWrapper } from './components/PaymentInstructionModal/paymentOverdueModalContext';

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    environment: import.meta.env.VITE_APP_ENV,
    dsn: 'https://9bc9817c20a740ddb62106f4572808da@o506743.ingest.sentry.io/6691812',

    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      `https://${import.meta.env.VITE_APP_API_URL}`,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_APP_LAUNCH_DARKLY_CLIENT_ID || '',
    user: {
      key: INITIAL_LD_KEY,
    },
  });

  root.render(
    <LDProvider>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <HelmetProvider>
            <VisitorProvider>
              <HubspotBookMeetingModalWrapper>
                <PaymentOverdueModalWrapper>
                  <Router>
                    <App />
                  </Router>
                </PaymentOverdueModalWrapper>
              </HubspotBookMeetingModalWrapper>
            </VisitorProvider>
          </HelmetProvider>
        </Provider>
      </QueryClientProvider>
    </LDProvider>
  );
})();
