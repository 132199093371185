import {
  AgrregatedDataType,
  SellerFilingReportFile,
  NormalizedSellerFilingReport,
} from '@/core/types';
import { LinkProps } from 'react-router-dom';
import { PropsWithChildren, useEffect } from 'react';
import { OSSSeSeReportFiling } from './OSSSeSeReportFiling';
import { useAppSelector } from '../../hooks';
import { activeCompanySelector } from '@/core/store/user/userSlice';
import { FileGeneralDetailsTable } from './FileGeneralDetailsTable';
import { useQuery } from '@tanstack/react-query';
import { getAggregatedDataOSSReport, getInvoiceBook } from '@/core/api';
import { Loader } from '../Loader/Loader';

export interface OSSSeSeWidgetLink extends LinkProps {
  label?: string;
  icon?: JSX.Element;
}
interface Props {
  report: NormalizedSellerFilingReport;
  onClickDownload?: (file: SellerFilingReportFile) => void;
  link?: OSSSeSeWidgetLink;
  onLoadAggreagtedData?: (v: AgrregatedDataType[]) => void;
}

export const OSSSelfServiceWidget = ({
  report,
  onClickDownload,
  link,
  children,
  onLoadAggreagtedData,
}: PropsWithChildren<Props>) => {
  const activeCompany = useAppSelector(activeCompanySelector);
  const file = report?.files.find((f) => f.option === 'oss-csv-bop');
  const params = new URLSearchParams(String(link?.to.search));
  const year = params.get('year');
  const value = params.get('value');
  const { data: totaltransactionsData, isLoading: isLoadingTotalTransactions } =
    useQuery({
      queryKey: ['TotalTransactions', report.id],
      queryFn: () =>
        getInvoiceBook(
          activeCompany?.company_id!,
          'quarter',
          +year!,
          +value!,
          0,
          1,
          undefined
        ),
      enabled: Boolean(link?.to.search),
    });

  const { data: aggregatedData, isLoading: isLoadingAggregatedData } = useQuery(
    {
      queryKey: ['AggregatedDataOSSFile', report.id],
      queryFn: () =>
        getAggregatedDataOSSReport(report.id, activeCompany?.company_id!),
    }
  );

  useEffect(() => {
    if (aggregatedData && onLoadAggreagtedData) {
      onLoadAggreagtedData(aggregatedData);
    }
    // eslint-disable-next-line
  }, [aggregatedData]);

  return isLoadingAggregatedData || isLoadingTotalTransactions ? (
    <Loader />
  ) : (
    <div className='divide-y-2 divide-gray-300 rounded-lg bg-white pb-4 pt-2 '>
      <OSSSeSeReportFiling
        link={link}
        report={report}
        onClickDownload={onClickDownload}
      />
      {file &&
        link &&
        !!aggregatedData?.length &&
        !!totaltransactionsData?.paging.total_rows && (
          <FileGeneralDetailsTable
            aggregatedData={aggregatedData}
            report={report}
            totalTransactions={totaltransactionsData?.paging.total_rows}
          />
        )}
      {children}
    </div>
  );
};
