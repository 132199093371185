import { useTranslation } from 'react-i18next';
import { AuthedRoutesEnum } from '../core/enums';
import usePaymentInstructionModal from '../pages/Seller/SellerFiling/usePaymentInstructionModal';
import { NormalizedSellerFilingReport } from '../core/types';
import {
  differenceInCalendarMonths,
  differenceInCalendarQuarters,
} from 'date-fns';
import { useLayoutEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSubmitBOPModal } from '../pages/Seller/SellerFiling/useSubmitBOPModal';
import OpenTask, { TodoItem } from './OpenTasks/OpenTask';
import PaymentInstructionModal from './PaymentInstructionModal/PaymentInstructionModal';
import SubmitBOPModal from './SubmitBOPModal';
import Button from './Button';
import {
  TaskForEnum,
  createOSSSeSeDueDate,
  isReportPaid,
  isReportPrepared,
  isReportSubmitted,
} from './OpenTasks/OpenTasks.helpers';
import Flag from './CountryLabel/Flag';
import { normalizeDateFE } from '../core/normalization';

interface Props {
  OSSReports: NormalizedSellerFilingReport[];
  isOSSSelfService: boolean;
  onSubmitReport: () => Promise<void>;
}

export default function TaxAdvisorTasks({
  OSSReports,
  isOSSSelfService,
  onSubmitReport,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  const { t, i18n } = useTranslation();
  const [isExtended, setIsExtended] = useState(false);

  const {
    isPaymentInstructionModalOpened,
    closePaymentInstructionModal,
    reportToConfirmPayment,
    isConfirmingPayment,
    handleOpenPaymentInstructionModal,
  } = usePaymentInstructionModal();

  const {
    reportToSubmit,
    openSubmitBOPModal,
    isSubmitBOPModalOpened,
    closeSubmitBOPModal,
    submitReport,
  } = useSubmitBOPModal('TaxAdvisorTask', onSubmitReport);

  const todos: TodoItem[] = (() => {
    return OSSReports.flatMap((r) => {
      const recommendedDueDate = createOSSSeSeDueDate(r);
      const submissionDueDate = createOSSSeSeDueDate(r, 3);
      const isSubmitted = isReportSubmitted(r);
      const isPaid = isReportPaid(r);
      const isOSSReportReady = isReportPrepared(r);

      return [
        {
          label: (
            <div className='space-x-2'>
              <span>
                <Flag country='EU' />
              </span>
              <span>{'OSS'}:</span>
              <span>{t('Submit report')}</span>
            </div>
          ),
          description: t('We review it after submission') as string,
          date: submissionDueDate,
          action: AuthedRoutesEnum.OSSFiles,
          btnLabel: 'Go to report',
          id: `ta-submit-oss-report-${r.id}`,
          taskFor: TaskForEnum.taxAdvisor,
          reportType: r.type,
          done: isSubmitted,
          disabled: !isOSSReportReady,
        },
        {
          label: (
            <div className='space-x-2'>
              <Flag country='EU' />
              <span>{'OSS'}:</span>
              <span>{t('Mark OSS report as submitted')}</span>
            </div>
          ),
          description: t('We review it after submission'),
          date: submissionDueDate,
          action: openSubmitBOPModal(r),
          btnLabel: `${isSubmitted || isPaid ? 'Marked' : 'Mark as submitted'}`,
          disabled: isSubmitted || isPaid || !isOSSReportReady,
          id: `submit-oss-${r.id}`,
          taskFor: TaskForEnum.taxAdvisor,
          reportType: r.type,
          done: isSubmitted || isPaid,
        },
        {
          label: (
            <div className='space-x-2'>
              <Flag country='EU' />
              <span>{t('Check if the file has been accepted')}</span>
            </div>
          ),
          description: t('We review it after submission'),
          date: normalizeDateFE(r.deadline_date),
          id: `check-acception-oss-${r.id}`,
          taskFor: TaskForEnum.taxAdvisor,
          reportType: r.type,
        },
        {
          label: (
            <div className='flex flex-shrink-0 items-center gap-x-2'>
              {t('Client has to make a payment on their side.')}
            </div>
          ),
          description: (
            <>
              <span>
                {i18n.language === 'en' ? 'We will send the' : 'Wir werden die'}
              </span>
              &nbsp;
              <Button
                styling='naked-blue'
                className={`${
                  !r.payment_instruction
                    ? '!bg-white !font-semibold'
                    : '!font-semibold'
                }`}
                onClick={handleOpenPaymentInstructionModal(r)}
                disabled={!r.payment_instruction}
              >
                {t('payment instruction')}
              </Button>
              <span>{i18n.language === 'en' ? '.' : ' senden.'}</span>
            </>
          ),
          date: recommendedDueDate,
          id: `tax-advisor-submit-oss-report-${r.id}`,
          reportType: r.type,
          action: () => {},
          btnLabel: isPaid ? 'Done' : 'by client',
          disabled: true,
          done: isPaid,
          taskFor: TaskForEnum.client,
          btnClassName: `!rounded-full !bg-gray-400`,
        },
      ];
    });
  })();

  const ongoing = todos
    .filter((todo) => todo.taskFor === TaskForEnum.taxAdvisor && !todo.done)
    .filter((todo) =>
      isOSSSelfService && todo.reportType === 'oss'
        ? differenceInCalendarQuarters(new Date(), todo.date) <= 0
        : differenceInCalendarMonths(new Date(), todo.date) <= 0
    );

  const ongoingForClient = todos
    .filter((todo) => todo.taskFor === TaskForEnum.client && !todo.done)
    .filter((todo) =>
      isOSSSelfService && todo.reportType === 'oss'
        ? differenceInCalendarQuarters(new Date(), todo.date) <= 0
        : differenceInCalendarMonths(new Date(), todo.date) <= 0
    );

  const expired = todos
    .filter((todo) =>
      isOSSSelfService && todo.reportType === 'oss'
        ? differenceInCalendarQuarters(new Date(), todo.date) > 0 &&
          typeof todo.action === 'function' &&
          todo.taskFor === TaskForEnum.taxAdvisor &&
          !todo.done
        : differenceInCalendarMonths(new Date(), todo.date) > 0
    )
    .sort((a, b) => {
      return a.date.getTime() - b.date.getTime();
    });

  useLayoutEffect(() => {
    if (ref.current) {
      setMaxHeight(isExtended ? ref.current.offsetHeight : 0);
    }
  }, [isExtended]);

  return (
    <div className='OpenTasks mb-4'>
      {!!ongoing.length ? (
        <>
          <div className='mb-4'>
            <div
              className='mb-2 font-extrabold'
              data-testid='ongoing-tasks-header'
            >
              {t('Ongoing tasks')}&nbsp;<span>{ongoing.length}</span>
            </div>
            {ongoing
              .sort((a, b) => {
                return a.date.getTime() - b.date.getTime();
              })
              .map((item, i) => (
                <OpenTask
                  done={item.done || false}
                  item={item}
                  index={i + 1}
                  key={uuidv4()}
                  id={item.id}
                  type={isOSSSelfService ? 'oss_self_service' : 'ongoing'}
                  disabled={item.disabled}
                  isOSSSelfService={true}
                />
              ))}
          </div>
        </>
      ) : (
        <div
          className='OpenTasks__noTasks mb-4 px-4 pb-4 pt-2 text-white'
          data-testid='green-alert'
        >
          <div className='font-extrabold' data-testid='green-alert-title'>
            {t("You don't have any ongoing tasks")}
          </div>
          <div data-testid='green-alert-text'>
            {t('Once we need something from you, you would get an email')}
          </div>
        </div>
      )}
      {!!ongoingForClient.length && (
        <div className='mb-4'>
          <div
            className='mb-2 font-extrabold'
            data-testid='ongoing-tasks-header'
          >
            {t('Tasks by client')}&nbsp;
            <span>{ongoingForClient.length}</span>
          </div>
          {ongoingForClient
            .sort((a, b) => {
              return a.date.getTime() - b.date.getTime();
            })
            .map((item, i) => (
              <OpenTask
                done={item.done || false}
                item={item}
                index={i + 1}
                key={uuidv4()}
                id={item.id}
                type={isOSSSelfService ? 'oss_self_service' : 'ongoing'}
                disabled={item.disabled}
                isOSSSelfService={true}
              />
            ))}
        </div>
      )}
      {!!expired.length && (
        <>
          <div className='mb-4'>
            <div
              className='mb-2 font-extrabold'
              data-testid='expired-tasks-header'
            >
              {t('Expired tasks')}&nbsp;<span>{expired.length}</span>
            </div>
            <div>
              {expired.slice(0, 3).map((item, i) => (
                <OpenTask
                  done={item.done || false}
                  item={item}
                  index={i + 1}
                  key={uuidv4()}
                  id={item.id}
                  type='expired'
                  disabled={item.disabled}
                />
              ))}
              <div
                className='OpenTasks__collapse'
                style={{
                  maxHeight,
                }}
                data-testid='expired-tasks-collapse'
              >
                <div ref={ref}>
                  {expired.slice(3).map((item, i) => (
                    <OpenTask
                      done={item.done || false}
                      item={item}
                      index={i + 4}
                      key={uuidv4()}
                      id={item.id}
                      type='expired'
                      disabled={item.disabled}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          {expired.length > 3 && (
            <Button
              data-testid='expired-tasks-btn'
              className='mb-2 !w-full'
              styling='gray'
              onClick={() => setIsExtended((s) => !s)}
            >
              {isExtended
                ? t('Show less')
                : t('Show *count* more').replace(
                    '*count*',
                    String(expired.slice(3).length)
                  )}
            </Button>
          )}
        </>
      )}
      <PaymentInstructionModal
        report={reportToConfirmPayment}
        isModalOpened={isPaymentInstructionModalOpened}
        closeModal={closePaymentInstructionModal}
        isConfirming={isConfirmingPayment}
      />
      <SubmitBOPModal
        isModalOpened={isSubmitBOPModalOpened}
        closeModal={closeSubmitBOPModal}
        report={reportToSubmit}
        onSubmit={submitReport}
      />
    </div>
  );
}
