import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import Dropdown from './Dropdown/Dropdown';
import {
  activeCompanySelector,
  companiesSelector,
  setActiveCompany,
} from '../core/store/user/userSlice';
import { UserCompany } from '../core/types';
import { useAppDispatch, useAppSelector } from '../hooks';
import ArrowsIcon from '@/icons/arrows.svg?react';
import './AggregatorDropdown.scss';

interface Props {
  className?: string;
}

export default function AggregatorDropdown({ className = '' }: Props) {
  const dispatch = useAppDispatch();
  const companies = useAppSelector(companiesSelector);
  const activeCompany = useAppSelector(activeCompanySelector);
  const location = useLocation();
  const params = new URLSearchParams();

  const navigate = useNavigate();

  const onClick = (company: UserCompany) => () => {
    if (company.company_id === activeCompany?.company_id) return;
    params.set('company_id', company.company_id);
    navigate(
      { pathname: location.pathname, search: params.toString() },
      { replace: true }
    );
    dispatch(setActiveCompany(company));
  };

  return (
    <Dropdown
      data-testid='AggregatorDropdown'
      horizontalAlignment='left'
      className={`AggregatorDropdown -mx-2 mb-4 ${className}`}
      trigger={
        <>
          <span className='flex-grow truncate pr-1 text-start font-semibold'>
            {activeCompany?.name}
          </span>
          <ArrowsIcon className='flex-shrink-0' />
        </>
      }
      content={
        <>
          {companies.map((c) => (
            <button
              className={classNames(
                'AggregatorDropdown__menuItem block w-full px-4 py-2 text-start',
                {
                  'AggregatorDropdown__menuItem--active':
                    c.company_id === activeCompany?.company_id,
                }
              )}
              key={c.company_id}
              onClick={onClick(c)}
            >
              {c.name}
            </button>
          ))}
        </>
      }
    />
  );
}
