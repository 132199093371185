import Flag from './Flag';
import './CountryLabel.scss';
import { useTranslation } from 'react-i18next';
import { Link, To } from 'react-router-dom';
import Tooltip from '../Tooltip/Tooltip';

interface Props {
  country: string;
  className?: string;
  badgeText?: string;
  to?: To;
  short?: boolean;
}

const CountryLabel = ({
  country,
  className = '',
  badgeText,
  to,
  short,
  ...rest
}: Props) => {
  const { t } = useTranslation();

  const content = (
    <div className={`CountryLabel flex items-center ${className}`} {...rest}>
      <div className='flex flex-shrink-0 items-center'>
        <Flag country={country} className='mr-2' />
      </div>
      <div className='flex items-center truncate'>
        {short ? (
          <Tooltip content={country === 'EU' ? 'OSS' : t(country)}>
            <span>{country === 'EU' ? 'OSS' : country}</span>
          </Tooltip>
        ) : (
          <span className='truncate'>
            {country === 'EU' ? 'OSS' : t(country)}
          </span>
        )}

        {badgeText && <span className='CountryLabel__badge'>{badgeText}</span>}
      </div>
    </div>
  );

  if (to) {
    return (
      <Link
        to={to}
        className='flex items-center text-dark-green transition hover:text-green'
        state={{ hasPrevPath: true }}
      >
        {content}
      </Link>
    );
  }

  return content;
};

export default CountryLabel;
