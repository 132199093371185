const Page404 = () => {
  return (
    <div
      style={{
        fontFamily:
          '-apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Fira Sans", Avenir, "Helvetica Neue", "Lucida Grande", sans-serif',
      }}
      className='flex h-screen bg-white'
      data-testid='page-404'
    >
      <div className='m-auto flex'>
        <h1
          style={{
            display: 'inline-block',
            borderRight: '1px solid rgba(0, 0, 0,.3)',
            margin: 0,
            marginRight: 20,
            padding: '10px 23px 10px 0',
            fontSize: 24,
          }}
        >
          404
        </h1>
        <div
          style={{
            display: 'inline-block',
            textAlign: 'left',
            verticalAlign: 'middle',
          }}
        >
          <h2
            style={{
              fontSize: 14,
              fontWeight: 'normal',
              lineHeight: 'inherit',
              margin: 0,
              padding: 0,
            }}
          >
            This page could not be found.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Page404;
