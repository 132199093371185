import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import { PropsWithChildren, useState } from 'react';
import LabelSwitch from '../LabelSwitch/LabelSwitch';
import { formatDate, formatNumber } from '@/core/helpers';
import CountryLabel from '../CountryLabel/CountryLabel';
import { normalizeDateFE } from '@/core/normalization';
import { AgrregatedDataType, Country } from '@/core/types';
import classNames from 'classnames';

type SwitchLabelType = 'quarterly' | 'monthly';

type AggregatedDataQuarterlyType = {
  [key: string]: {
    country: {
      id: number;
      short_name: string;
      name: string;
    };
    transactions: number;
    gross: number;
    vat_amount: number;
  };
};

type AggregatedDataMonthlyType = {
  [period: string]: {
    month: string;
    transactions: number;
    gross: number;
    vat_amount: number;
    countries: {
      country: Country;
      transactions: number;
      gross: number;
      vat_amount: number;
    }[];
  };
};

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  aggregatedData: AgrregatedDataType[];
}

export default function OSSSeSeFileDetailsModal({
  isModalOpened,
  closeModal,
  aggregatedData,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] =
    useState<SwitchLabelType>('quarterly');

  const aggregatedDataQuarterly = Object.values(
    aggregatedData.reduce((acc: AggregatedDataQuarterlyType, item) => {
      const countryKey = item.country.short_name;
      if (!acc[countryKey]) {
        acc[countryKey] = {
          country: item.country,
          transactions: item.transactions_number,
          gross: item.gross_amount,
          vat_amount: item.vat_amount,
        };
      } else {
        acc[countryKey].transactions += item.transactions_number;
        acc[countryKey].gross += item.gross_amount;
        acc[countryKey].vat_amount += item.vat_amount;
      }

      return acc;
    }, {})
  );

  const aggregatedDataMonthly = Object.values(
    aggregatedData.reduce((acc: AggregatedDataMonthlyType, item) => {
      const period = formatDate(normalizeDateFE(item.date), 'MMMM yyyy');

      if (!acc[period]) {
        acc[period] = {
          month: period,
          transactions: 0,
          gross: 0,
          vat_amount: 0,
          countries: [],
        };
      }
      acc[period].transactions += item.transactions_number;
      acc[period].gross += item.gross_amount;
      acc[period].vat_amount += item.vat_amount;

      const country = acc[period].countries.reduce<{
        country: Country;
        transactions: number;
        gross: number;
        vat_amount: number;
      } | null>((countryAcc, countryData) => {
        if (countryData.country.id === item.country.id) {
          return {
            ...countryData,
            transactions: countryData.transactions + item.transactions_number,
            gross: countryData.gross + item.gross_amount,
            vat_amount: countryData.vat_amount + item.vat_amount,
          };
        }
        return countryAcc;
      }, null);

      if (!country) {
        acc[period].countries.push({
          country: item.country,
          transactions: item.transactions_number,
          gross: item.gross_amount,
          vat_amount: item.vat_amount,
        });
      }
      return acc;
    }, {})
  );

  return (
    <Modal
      isOpened={isModalOpened}
      close={closeModal}
      title={t('Declared countries')}
    >
      <div className='mx-4 px-4 pb-6 pt-2'>
        <div className='flex items-center justify-between'>
          <h4 className='Text font-extrabold'>{t('OSS turnover')}</h4>
          <LabelSwitch<SwitchLabelType>
            activeItem={selectedType}
            onClick={(v) => () => {
              setSelectedType(v);
            }}
            items={[
              { value: 'quarterly', label: t('Quarterly') },
              { value: 'monthly', label: t('Monthly') },
            ]}
          />
        </div>
        <div>
          {!!aggregatedData.length ? (
            <>
              <div className='flex gap-x-6 pt-2 text-end'>
                <div className='w-4/12 text-start text-gray-700'>
                  {t('Country')}
                </div>
                <div className='w-2/12 text-gray-700'>{t('Transactions')}</div>
                <div className='w-3/12 text-gray-700'>{t('Gross')}</div>
                <div className='w-3/12 text-gray-700'>{t('VAT declared')}</div>
              </div>
              <div
                className={classNames({
                  'space-y-4 pt-2': selectedType === 'quarterly',
                  'divide-y': selectedType !== 'quarterly',
                })}
              >
                {selectedType === 'quarterly'
                  ? aggregatedDataQuarterly
                      .sort((a, b) => b.vat_amount - a.vat_amount)
                      .map((c, i) => {
                        return (
                          <div className='flex text-end' key={i}>
                            <div className='w-4/12 text-start'>
                              <CountryLabel
                                country={c.country.short_name}
                                className='font-semibold'
                              />
                            </div>
                            <div className='w-2/12'>
                              {formatNumber(c.transactions, {
                                maximumFractionDigits: 0,
                              })}
                            </div>
                            <div className='w-3/12'>
                              {formatNumber(c.gross / 100, {
                                currency: 'EUR',
                                maximumFractionDigits: 2,
                              })}
                            </div>
                            <div className='w-3/12'>
                              {formatNumber(c.vat_amount / 100, {
                                currency: 'EUR',
                                maximumFractionDigits: 2,
                              })}
                            </div>
                          </div>
                        );
                      })
                  : aggregatedDataMonthly
                      .sort(
                        (a, b) =>
                          new Date(b.month).getTime() -
                          new Date(a.month).getTime()
                      )
                      .map((period, i) => {
                        return (
                          <div key={i}>
                            <div className='flex py-2 text-end font-bold'>
                              <div className='w-4/12 text-start'>
                                {period.month}
                              </div>
                              <div className='w-2/12'>
                                {formatNumber(period.transactions, {
                                  maximumFractionDigits: 0,
                                })}
                              </div>
                              <div className='w-3/12'>
                                {formatNumber(period.gross / 100, {
                                  currency: 'EUR',
                                  maximumFractionDigits: 2,
                                })}
                              </div>
                              <div className='w-3/12'>
                                {formatNumber(period.vat_amount / 100, {
                                  currency: 'EUR',
                                  maximumFractionDigits: 2,
                                })}
                              </div>
                            </div>
                            <div className='space-y-4 pb-2'>
                              {period.countries
                                .sort((a, b) => b.vat_amount - a.vat_amount)
                                .map((c, i) => {
                                  return (
                                    <div className='flex text-end' key={i}>
                                      <div className='w-4/12 text-start'>
                                        <CountryLabel
                                          country={c.country.short_name}
                                          className='font-semibold'
                                        />
                                      </div>
                                      <div className='w-2/12'>
                                        {formatNumber(c.transactions, {
                                          maximumFractionDigits: 0,
                                        })}
                                      </div>
                                      <div className='w-3/12'>
                                        {formatNumber(c.gross / 100, {
                                          currency: 'EUR',
                                          maximumFractionDigits: 2,
                                        })}
                                      </div>
                                      <div className='w-3/12'>
                                        {formatNumber(c.vat_amount / 100, {
                                          currency: 'EUR',
                                          maximumFractionDigits: 2,
                                        })}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
              </div>
            </>
          ) : (
            <>{t('No data')}</>
          )}
        </div>
      </div>
    </Modal>
  );
}
