import classNames from 'classnames';
import Flag from '../CountryLabel/Flag';
import { Filter } from './useCountryFilters';

interface Props {
  activeCountryFilters: string[];
  countryFilters: Filter<string>[];
  onCountryFilterClick: (v?: string | undefined) => () => void;
}

const CountryFilters = ({
  activeCountryFilters,
  countryFilters,
  onCountryFilterClick,
}: Props) => {
  return (
    <div className={'flex flex-wrap items-center gap-x-2'}>
      <button
        className={classNames(
          'Button--outline Button--pill mb-2 flex items-center !px-3 !py-1',
          {
            '!border-green !bg-green !font-bold !text-white':
              !activeCountryFilters.length,
          }
        )}
        onClick={onCountryFilterClick()}
      >
        All countries
      </button>
      {countryFilters.map((f) => (
        <button
          key={String(f.id)}
          onClick={onCountryFilterClick(f.id)}
          className={classNames(
            'Button--outline Button--pill mb-2 flex items-center !px-3 !py-1',
            {
              '!border-green !bg-green !font-bold !text-white':
                activeCountryFilters.includes(f.id),
            }
          )}
        >
          <Flag country={f.label === 'OSS' ? 'EU' : f.label} className='mr-2' />
          {f.label}
        </button>
      ))}
    </div>
  );
};

export default CountryFilters;
