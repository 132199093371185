import { useLocation } from 'react-router-dom';
import { useAppSelector } from '.';
import { postEvent } from '../core/api';
import { useVisitor } from '../context/useVisitor';

export enum APIEventsEnum {
  files_uploaded_from_document_tab = 'files_uploaded_from_document_tab',
  download_errors_file_form_documents_tab = 'download_errors_file_form_documents_tab',
  view_document_tab = 'view_document_tab',
  view_new_turnover_section = 'view_new_turnover_section',
  files_uploaded_from_new_turnover_section = 'files_uploaded_from_new_turnover_section',
  click_datev_schedule_interview = 'click_datev_schedule_interview',
  download_datev_file = 'download_datev_file',
  calendly_booked_website = 'calendly_booked_website',
  cookie_policy_accepted = 'cookie_policy_accepted',
  cookie_policy_declined = 'cookie_policy_declined',
  cookie_banner_view = 'cookie_banner_view',
  click_cta = 'click_cta',
  view_cta = 'view_cta',
  click_datev_fake_door = 'click_datev_fake_door',
  click_upload_purchase_prices = 'click_upload_purchase_prices',
  purchase_prices_uploaded_correctly = 'purchase_prices_uploaded_correctly',
  purchase_prices_uploaded_unorrectly = 'purchase_prices_uploaded_unorrectly',
  view_payment_instruction = 'view_payment_instruction',
  payment_instruction_marked_paid = 'payment_instruction_marked_paid',
  view_report_confirmation = 'view_report_confirmation',
  download_report_confirmation = 'download_report_confirmation',
  download_proforma_invoice = 'download_proforma_invoice',
  report_submition_agenda = 'report_submition_agenda',
  click_sidebar_link = 'click_sidebar_link',
  download_manual_upload_instruction = 'download_manual_upload_instruction',
  download_manual_upload_template = 'download_manual_upload_template',
  click_download_invoice_book = 'click_download_invoice_book',
  manual_upload_file_success = 'manual_upload_file_success',
  manual_upload_file_fail = 'manual_upload_file_fail',
  click_connect_amazon = 'click_connect_amazon',
  click_connect_easybill = 'click_connect_easybill',
  click_connect_billbee = 'click_connect_billbee',
  payment_overdue_client_login = 'payment_overdue_client_login',
  oss_sese_marked_as_submitted = 'oss_sese_marked_as_submitted',
}

export interface Event {
  application: string;
  type: APIEventsEnum;
  source?: string;
  block?: string;
  query_string?: string;
  metadata?: string;
  url?: string;
}

export const useSendEvent = () => {
  const { visitorId } = useVisitor();
  const userId = useAppSelector((s) => s.user.user?.AmazonCognitoId);
  const location = useLocation();

  return {
    sendEvent: (type: APIEventsEnum, metadata: { [key: string]: string }) => {
      if (window.hj) window.hj('event', type);
      postEvent(userId || visitorId, {
        application: 'customer-app',
        type,
        query_string: location.search,
        url: location.pathname,
        metadata: JSON.stringify(metadata),
      }).catch(console.log);
    },
  };
};
