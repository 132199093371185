import './Avatar.scss';
import { Participant } from './Participants';

interface Props {
  data: Participant;
  className?: string;
  backgroundColor: string;
  testId?: string;
}

export default function Avatar({
  data,
  className = '',
  backgroundColor,
  testId,
}: Props) {
  return (
    <div
      className={`Avatar flex items-center justify-center rounded-full uppercase text-white ${className}`}
      style={{
        backgroundColor,
      }}
      data-testid={testId}
    >
      {data.Name[0]}
      {data.FamilyName[0]}
    </div>
  );
}
