import { useTranslation } from 'react-i18next';
import { formatNumber } from '@/core/helpers';
import OSSSeSeFileDetailsModal from '../OSSSeSeFileDetailsModal/OSSSeSeFileDetailsModal';
import { useModal } from '../Modal/useModal';
import { AgrregatedDataType, NormalizedSellerFilingReport } from '@/core/types';
import { Link } from 'react-router-dom';
import { OSSSeSeWidgetLink } from './OSSSelfServiceWidget';
import { useAppSelector } from '../../hooks';
import { activeCompanySelector } from '@/core/store/user/userSlice';
import { useMemo } from 'react';
import { createInvoiceBookLink } from '@/pages/OSSFiles.helpers';

interface Props {
  aggregatedData: AgrregatedDataType[];
  report: NormalizedSellerFilingReport;
  totalTransactions: number;
}

export const FileGeneralDetailsTable = ({
  aggregatedData,
  report,
  totalTransactions,
}: Props) => {
  const { t } = useTranslation();
  const { isModalOpened, openModal, closeModal } = useModal();
  const activeCompany = useAppSelector(activeCompanySelector);

  const filteredAggregated = useMemo(
    () => aggregatedData.filter((c) => c.vat_amount >= 0),
    [aggregatedData]
  );

  const fileGeneralDetails = useMemo(
    () =>
      filteredAggregated.reduce(
        (
          acc: {
            gross_amount: number;
            oss_transactions_number: number;
            vat_amount: number;
            countries: number[];
          },
          item
        ) => {
          acc.gross_amount += item.gross_amount;
          acc.oss_transactions_number += item.transactions_number;
          acc.vat_amount += item.vat_amount;
          if (!acc.countries.includes(item.country.id)) {
            acc.countries.push(item.country.id);
          }

          return acc;
        },
        {
          gross_amount: 0,
          oss_transactions_number: 0,
          vat_amount: 0,
          countries: [],
        }
      ),
    [filteredAggregated]
  );

  const tilesData: Tile[] = [
    {
      title: 'Total transactions',
      link: createInvoiceBookLink(
        report.period.from_date,
        activeCompany!.company_id
      ),
      value: formatNumber(totalTransactions),
    },
    {
      title: 'OSS transactions',
      link: createInvoiceBookLink(
        report.period.from_date,
        activeCompany!.company_id,
        true
      ),
      value: formatNumber(fileGeneralDetails.oss_transactions_number),
    },
    {
      title: 'Declared countries',
      value: fileGeneralDetails?.countries.length,
      onClick: openModal,
    },
  ];

  return (
    <div className='!px-4 pt-2'>
      <div className='Text pb-2 font-extrabold'>{t('Numbers overview')}</div>
      <div className='flex-start flex gap-x-4'>
        {tilesData.map((tile) => (
          <OSSReportDetailsTile key={tile.title} tile={tile} />
        ))}
      </div>
      <OSSSeSeFileDetailsModal
        closeModal={closeModal}
        isModalOpened={isModalOpened}
        aggregatedData={filteredAggregated}
      />
    </div>
  );
};

interface Tile {
  title: string;
  value: string | number;
  onClick?: () => void;
  link?: OSSSeSeWidgetLink;
}

interface OSSReportDetailsTileProps {
  tile: Tile;
}

function OSSReportDetailsTile({ tile }: OSSReportDetailsTileProps) {
  const { t } = useTranslation();
  return (
    <>
      {tile.link ? (
        <Link
          to={tile.link.to}
          state={tile.link.state}
          className='w-[184px] rounded-lg border p-2 transition-colors hover:border-white hover:!text-dark hover:ring-2 hover:ring-dark hover:ring-offset-0'
        >
          <div className='font-semibold'>{tile.value}</div>
          <div>{t(tile.title)}</div>
          <div className='mt-1.5 block cursor-pointer font-semibold text-blue-500 transition-colors hover:text-blue-600'>
            {t('Show details')}
          </div>
        </Link>
      ) : (
        <div
          onClick={tile.onClick}
          className='w-[184px] cursor-pointer rounded-lg border p-2 transition-colors hover:border-white hover:!text-dark hover:ring-2 hover:ring-dark hover:ring-offset-0'
        >
          <div className='font-semibold'>{tile.value}</div>
          <div>{t(tile.title)}</div>
          <div className='mt-1.5 cursor-pointer font-semibold text-blue-500 transition-colors hover:text-blue-600'>
            {t('Show details')}
          </div>
        </div>
      )}
    </>
  );
}
