import { useTranslation } from 'react-i18next';

export default function PaymentOverdueBanner() {
  const { t } = useTranslation();

  return (
    <div className='z-10 -mb-2 w-full bg-gray-300 px-4 pt-4 lg:px-6 2xl:px-14'>
      <div
        className='flex w-full flex-col rounded-lg border border-red-500 px-5 py-3'
        style={{ background: 'rgba(252, 17, 17, 0.15)' }}
      >
        <h2 className='Text font-extrabold'>
          {t('Please make the service payment')}
        </h2>
        <p className='max-w-[750px]'>
          {t('Please make a payment and contact your account manager at')}{' '}
          <a
            href={`mailto:vat@countx.com?subject=${t(
              'payment_overdue_problems'
            )}`}
            className='font-semibold'
          >
            vat@countx.com
          </a>{' '}
          {t(
            "as soon as possible; otherwise, we won't be able to prepare your filing, which could lead to penalties and additional obligations."
          )}
        </p>
      </div>
    </div>
  );
}
