import { Evaluation } from '../types';

export const WS_MESSAGE_EVENT_KEY = 'ws-message';

export interface WsMessage {
  name: string;
  data: any;
}

export const isWsMessage = (v: WsMessage | any): v is WsMessage => {
  return v && 'name' in v && 'data' in v;
};

enum WSMessageTypesEnum {
  invoiceBookGenerated = 'invoice-book-generated',
  manaulUploadMessage = 'manual-upload-update',
}

export interface InvoiceBookGeneratedMessage {
  name: WSMessageTypesEnum.invoiceBookGenerated;
  data: Evaluation;
}

export const isInvoiceBookGeneratedMessage = (
  v: WsMessage | InvoiceBookGeneratedMessage
): v is InvoiceBookGeneratedMessage => {
  return v.name === WSMessageTypesEnum.invoiceBookGenerated;
};

export const isUploadedDocumentsFileProgressMessage = (
  v: WsMessage | ManualUploadMessage
): v is ManualUploadMessage => {
  return v.name === WSMessageTypesEnum.manaulUploadMessage;
};

export interface ManualUploadMessage {
  name: WSMessageTypesEnum.manaulUploadMessage;
  data: UploadedDocumentsFileProgressMessageData;
}

export interface UploadedDocumentsFileProgressMessageData {
  fileId: number;
  companyId: string;
  status: 'created' | 'finished';
  periodFrom: string;
  periodTo: string;
  type: string;
  isCorrectionValid: boolean;
  correctionErrors: string[];
  isValid: boolean;
}
