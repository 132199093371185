import {
  PASSWORD_VALIDATION_PARAMETERS,
  isCodeValid,
  isEmail,
  isPasswordValid,
  isPhone,
  passwordValidators,
  pushEventToDataLayer,
  setTokensToLocalStorage,
} from '@/core/helpers';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import Checkbox from '@/components/Checkbox/Checkbox';
import {
  getAmazonConnectCredentials,
  getUser,
  registerSetupCheck,
} from '@/core/api';
import { confirmSignUp, login } from '@/core/auth';
import { PublicRoutesEnum, RolesEnum } from '@/core/enums';
import { setUser } from '@/core/store/user/userSlice';
import Input from '@/components/Input/Input';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { ChangeEventHandler, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Loader } from '@/components/Loader/Loader';
import './RegisterForm.scss';
import { Lang, SelectOption, UserFlagEnum } from '@/core/types';
import { Select } from '@/components/Select/Select';
import countries from '@/core/data/homeland_countries.json';
import CountryLabel from '@/components/CountryLabel/CountryLabel';
import { setHasNewAmazonCredentials } from '@/core/store/amazon/amazonSlice';
import { TERMS_OF_SERVICE_VERSION } from '../TermsOfService/TermsOfService';
import { DATA_PROTECTION_VERSION } from '../DataProcessingAgreement/DataProcessingAgreement';
import { GTMEvents } from '@/core/constants';
import Button from '@/components/Button';

export type RegisterStepType = 'create_account' | 'verify_email';

interface FormValues {
  phone: string;
  email: string;
  name: string;
  lastname: string;
  company: string;
  country: string;
  password: string;
  code: string;
  consent: boolean;
  newsletter_agreement: boolean;
}
interface Props {
  onStepChange?: () => void;
  initialStep?: RegisterStepType;
  initialEmail?: string;
  initialPassword?: string;
  role?: RolesEnum;
  flag?: UserFlagEnum;
}

export default function RegisterForm({
  initialStep = 'create_account',
  onStepChange,
  initialEmail = '',
  initialPassword = '',
  role = RolesEnum.SETUP_CHECK,
  flag,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState<RegisterStepType>(initialStep);
  const { t, i18n } = useTranslation();

  const countriesArray: SelectOption[] = (countries as string[])
    .map((c) => ({
      id: c,
      name: t(c),
      content: <CountryLabel country={c} />,
    }))
    .sort((a, b) => {
      return a.name.localeCompare(b.name, i18n.language);
    })
    .sort((a, b) => {
      return b.name === t('OTHER') ? -1 : a.name === t('OTHER') ? 1 : 0;
    });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [usedEmails, setUsedEmails] = useState<string[]>([]);

  const formik = useFormik<FormValues>({
    validateOnBlur: true,
    initialValues: {
      email: initialEmail,
      name: '',
      country: 'DE',
      lastname: '',
      company: '',
      password: initialPassword,
      consent: false,
      newsletter_agreement: false,
      code: '',
      phone: '',
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (step === 'create_account') {
        if (!values.email) {
          errors.email = 'Required';
        }
        if (values.email !== '' && !isEmail(values.email)) {
          errors.email = `Email is not valid`;
        }
        if (values.email !== '' && usedEmails.includes(values.email)) {
          errors.email = `This email already exists. Try to login`;
        }
        if (!values.name) {
          errors.name = 'Required';
        }
        if (!values.country) {
          errors.country = 'Required';
        }
        if (!values.lastname) {
          errors.lastname = 'Required';
        }
        if (!values.company) {
          errors.company = 'Required';
        }
        if (values.consent === false) {
          errors.consent = 'Required';
        }

        if (values.phone && values.phone !== '+' && !isPhone(values.phone)) {
          errors.phone = 'Phone is not valid';
        }

        if (values.password === '' || !isPasswordValid(values.password)) {
          errors.password = JSON.stringify(
            PASSWORD_VALIDATION_PARAMETERS.reduce((acc: string[], item) => {
              if (
                passwordValidators[item] &&
                !passwordValidators[item](values.password)
              ) {
                acc.push(item);
              }
              return acc;
            }, [])
          );
        }
      }

      if (step === 'verify_email') {
        if (!values.code) {
          errors.code = 'Required';
        }
        if (values.code !== '' && !isCodeValid(values.code)) {
          errors.code = 'Verification code is not valid';
        }
      }

      return errors;
    },
    onSubmit: ({
      email,
      password,
      name,
      lastname,
      company,
      code,
      country,
      newsletter_agreement,
      consent,
      phone,
    }) => {
      setIsLoading(true);
      if (error) setError('');
      if (step === 'create_account') {
        return registerSetupCheck({
          email,
          name,
          family_name: lastname,
          country,
          company_name: company,
          password,
          language: i18n.language as Lang,
          role,
          tos_accepted: consent,
          tos_version: TERMS_OF_SERVICE_VERSION,
          data_protection_accepted: consent,
          data_protection_version: DATA_PROTECTION_VERSION,
          newsletter_agreement,
          phone: phone && phone !== '+' ? phone?.replace(/\s/g, '') : undefined,
          flag,
        })
          .then(() => {
            pushEventToDataLayer(GTMEvents.setup_check_register_success);
            setStep('verify_email');
            !!onStepChange && onStepChange();
          })
          .catch((err) => {
            const message = err.response?.data?.msg;

            if (
              message &&
              message ===
                '{"error": "UsernameExistsException: User already exists"}'
            ) {
              formik.setFieldError(
                'email',
                'This email already exists. Try to login'
              );
              setUsedEmails((emails) => [...emails, email]);
            } else if (
              message &&
              message.includes('InvalidPasswordException')
            ) {
              const errorMsg = JSON.parse(message).error;
              setError(
                errorMsg.replace('InvalidPasswordException:', '').trim()
              );
            } else {
              setError('Something went wrong, please try again');
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }

      confirmSignUp({
        email,
        confirmationCode: code,
      })
        .then(() => {
          pushEventToDataLayer(GTMEvents.setup_check_verification_success);
          return login({ email, password });
        })
        .then((res) => {
          setTokensToLocalStorage(
            res.AuthenticationResult!.AccessToken!,
            res.AuthenticationResult!.RefreshToken!,
            res.AuthenticationResult!.IdToken!
          );
          return Promise.all([getUser(), getAmazonConnectCredentials()]);
        })
        .then(([userRes, credsRes]) => {
          dispatch(setUser(userRes));
          if (!!credsRes.length) dispatch(setHasNewAmazonCredentials(true));
          setIsLoading(false);
          navigate('/');
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.name === 'CodeMismatchException') {
            setError('Invalid verification code provided, please try again.');
          } else {
            setError('Something went wrong, please try again');
          }
        });
    },
  });

  const isConsentInvalid = formik.touched.consent && !!formik.errors.consent;

  let title = t('Create an account');
  let text = (
    <span>
      {t('This allows you to access your data at any time in the future')}
    </span>
  );
  let btnLabel = t('create_account_btn_text');

  if (step === 'verify_email') {
    title = t('Verify your email');
    text = (
      <div
        style={{
          maxWidth: 504,
        }}
        data-testid='code-text'
        dangerouslySetInnerHTML={{
          __html: t('Please enter the 6 digits we sent you to').replace(
            '*email*',
            `<span className='whitespace-nowrap'>${formik.values.email}</span>`
          ),
        }}
      />
    );
    btnLabel = t('Verify');
  }
  const passwordErrors = (formik.errors.password || '').slice();

  const onCountryChange = (id: string | number) => {
    formik.setFieldValue('country', id, true);
  };

  const onCountrySelectBlur = () => {
    formik.setFieldTouched('country', true, true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const handlePhoneChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value.length === 0) {
      return formik.setFieldValue(
        'phone',
        e.target.value,
        formik.touched.phone
      );
    }

    formik.setFieldValue(
      'phone',
      `${!e.target.value.startsWith('+') ? '+' : ''}${e.target.value}`,
      formik.touched.phone
    );
  };

  const renderStep = () => {
    if (step === 'create_account') {
      return (
        <>
          <div className='flex gap-x-6'>
            <div>
              <Input
                wrapperClassName='Input--RegisterForm'
                tabIndex={0}
                label='First Name'
                name='name'
                id='name'
                onChange={onChangeField}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                error={formik.errors.name}
                touched={formik.touched.name}
              />
            </div>
            <div>
              <Input
                wrapperClassName='Input--RegisterForm'
                label='Last Name'
                name='lastname'
                id='lastname'
                onChange={onChangeField}
                onBlur={formik.handleBlur}
                value={formik.values.lastname}
                error={formik.errors.lastname}
                touched={formik.touched.lastname}
              />
            </div>
          </div>
          <div className='flex gap-x-6'>
            <div className='w-full lg:w-1/2'>
              <Input
                wrapperClassName='Input--RegisterForm'
                label='Company name'
                name='company'
                id='company'
                onChange={onChangeField}
                onBlur={formik.handleBlur}
                value={formik.values.company}
                error={formik.errors.company}
                touched={formik.touched.company}
              />
            </div>
            <div className='w-full lg:w-1/2'>
              <Select
                size='lg'
                name='country'
                label='Homeland'
                value={formik.values.country}
                placeholder='Select'
                options={countriesArray}
                onChange={onCountryChange}
                error={formik.errors.country}
                touched={formik.touched.country}
                onBlur={onCountrySelectBlur}
                className='Select--RegisterForm'
              />
            </div>
          </div>

          <Input
            wrapperClassName='Input--RegisterForm'
            label='Email'
            name='email'
            id='email'
            onChange={onChangeField}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.errors.email}
            touched={formik.touched.email}
          />
          <Input
            wrapperClassName='Input--RegisterForm'
            label='Phone (optional)'
            name='phone'
            id='phone'
            onChange={handlePhoneChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            error={formik.errors.phone}
            touched={formik.touched.phone}
          />
          <Input
            label='Password'
            name='password'
            id='password'
            type='password'
            wrapperClassName='RegisterForm__password Input--RegisterForm'
            onChange={onChangeField}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.errors.password}
            touched={formik.touched.password}
          />
          <div className='RegisterForm__passwordRequirements -mt-2 flex flex-wrap gap-x-1'>
            {PASSWORD_VALIDATION_PARAMETERS.map((item: any, i) => (
              <div key={item}>
                <div
                  className={classNames('mb-1 whitespace-nowrap', {
                    'RegisterForm__badge rounded':
                      formik.touched.password && passwordErrors.includes(item),
                    'Text--green':
                      formik.touched.password && !passwordErrors.includes(item),
                  })}
                  data-testid={`password-validation-${item
                    .toLowerCase()
                    .replace(/\s/g, '_')}`}
                >
                  <span>{t(item)}</span>
                  {i === 0 ? (
                    <span>:</span>
                  ) : i !== PASSWORD_VALIDATION_PARAMETERS.length - 1 ? (
                    <span className='text-dark'>,</span>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
          <div className='relative mt-1 pb-4'>
            <Checkbox
              name='consent'
              id='consent'
              onChange={onChangeField}
              onBlur={formik.handleBlur}
              checked={formik.values.consent}
              isError={isConsentInvalid}
              labelId='consent_area'
              className='RegisterForm__checkbox'
            >
              <div>
                <span>{t('I agree to')} </span>
                <a
                  href={PublicRoutesEnum.TermsOfService}
                  className='RegisterForm__link whitespace-nowrap'
                  target='_blank'
                  rel='noopener noreferrer'
                  tabIndex={-1}
                >
                  {t('Terms of Service')}
                </a>{' '}
                <span>{t('and')}</span>{' '}
                <a
                  href={PublicRoutesEnum.DataProcessingAgreement}
                  className='RegisterForm__link'
                  target='_blank'
                  rel='noopener noreferrer'
                  tabIndex={-1}
                >
                  {t('Data Processing Agreement')}
                </a>
                <span>{i18n.language === 'de' ? ' zu' : ''}</span>
              </div>
            </Checkbox>
            {isConsentInvalid && (
              <div
                className='RegisterForm__error absolute'
                style={{
                  bottom: 0,
                  left: 0,
                }}
                data-testid='consent-error'
              >
                {t(formik.errors.consent || '')}
              </div>
            )}
          </div>
          <div className='relative pb-4'>
            <Checkbox
              name='newsletter_agreement'
              id='newsletter_agreement'
              onChange={onChangeField}
              checked={formik.values.newsletter_agreement}
              labelId='newsletter_agreement_area'
              className='RegisterForm__checkbox'
            >
              {t('I want to receive news and updates from countX')}
            </Checkbox>
          </div>
        </>
      );
    }

    return (
      <Input
        label='Verification code'
        name='code'
        id='code'
        type='text'
        wrapperClassName='RegisterForm__codeInput Input--RegisterForm'
        onChange={onChangeField}
        onBlur={formik.handleBlur}
        value={formik.values.code}
        error={formik.errors.code}
        touched={formik.touched.code}
      />
    );
  };

  const onChangeField: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (error) setError('');

    if (e.target.name === 'password') {
      formik.setFieldTouched(e.target.name, true, true);
    }

    formik.setFieldValue(
      e.target.name,
      e.target.type === 'checkbox'
        ? e.target.checked
        : e.target.name === 'code'
          ? e.target.value.trim()
          : e.target.value,
      formik.touched[e.target.name as keyof FormikTouched<FormValues>]
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <h1 className='Title mb-2 font-black' data-testid='title'>
        {title}
      </h1>
      <div className='Text mb-2' data-testid='description'>
        <div className='mb-4'>{text}</div>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        className='RegisterForm__form'
        id='register-form'
      >
        {renderStep()}
        <div>
          <Button
            className={classNames('Button--lg RegisterForm__btn mt-2', {
              '!w-full': step !== 'create_account',
              'RegisterForm__btn--verify': step === 'verify_email',
            })}
            type='submit'
            data-testid='submit'
            disabled={isLoading}
          >
            {btnLabel}
          </Button>
          {error && (
            <div
              className={classNames('RegisterForm__error mt-2', {
                'text-center': step === 'create_account',
              })}
            >
              {t(error)}
            </div>
          )}
        </div>
      </form>
    </>
  );
}
