import { isSameMonth } from 'date-fns';
import { useMemo } from 'react';
import CalendarIcon from '@/icons/calendar.svg?react';
import './SellerCalendarCollapse.scss';
import { normalizeDateFE } from '@/core/normalization';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import { getUTCDate } from '@/core/helpers';
import SellerCalendar from '@/pages/Seller/SellerCalendar';
import { Report } from '@/core/types';
import { ControlledCollapse } from './Collapse/ControlledCollapse';

interface Props {
  reports: Report[];
}

export default function SellerCalendarCollapse({ reports }: Props) {
  const { t } = useTranslation();

  const data = useMemo(() => {
    const currentReports = reports.filter((r) => {
      const curDate = getUTCDate();
      const curYear = curDate.getFullYear();
      const curMonth = curDate.getMonth();

      const date = normalizeDateFE(r.deadline_date);
      const reportMonth = date.getMonth();
      const reportYear = date.getFullYear();

      return (
        reportMonth >= curMonth &&
        reportMonth <= curMonth + 2 &&
        reportYear === curYear
      );
    });

    const months = currentReports
      .map((r) => {
        const date = normalizeDateFE(r.deadline_date);
        const reportMonth = date.getMonth();
        return reportMonth;
      })
      .filter((item, i, self) => self.indexOf(item) === i)
      .sort((a, b) => a - b);

    const items = currentReports
      .map((r) => r.deadline_date)
      .filter((item, i, self) => self.indexOf(item) === i)
      .map((d) => {
        return {
          date: normalizeDateFE(d),
          countries: currentReports
            .filter((r) => r.deadline_date === d)
            .map((r) => {
              if (r.type === 'oss') return { name: 'EU', period: r.period };
              return { name: r.country.short_name, period: r.period };
            })
            .filter((item, i, self) => self.indexOf(item) === i),
        };
      });

    return months.map((m) => {
      const date = getUTCDate();
      date.setDate(1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      date.setMonth(m);

      return {
        month: date,
        items: items
          .filter((item) => isSameMonth(date, item.date))
          .sort((a, b) => a.date.getTime() - b.date.getTime()),
      };
    });
  }, [reports]);

  const today = getUTCDate();
  const curCalendar = data.find((c) => isSameMonth(today, c.month));

  if (!curCalendar) return null;

  return (
    <div className='w-full rounded-lg bg-white pb-4 pt-2'>
      <ControlledCollapse
        triggerRenderer={(isOpened, toggleCollapse) => (
          <div className='px-4'>
            <div>
              <div className='mb-2 flex items-center'>
                <CalendarIcon className='mr-2' />
                <div
                  className='Text font-bold'
                  data-testid='vat-calendar-title'
                >
                  {t('VAT calendar')}
                </div>
              </div>
              <div
                className='mb-2'
                dangerouslySetInnerHTML={{
                  __html: `<span>${t(
                    'We recommend to transfer VAT payment at least *hours*'
                  ).replace(
                    '*hours*',
                    `<span class='font-semibold'>${t(
                      '48h before due date.'
                    )}</span>`
                  )}</span>`,
                }}
              />

              {!isOpened && (
                <SellerCalendar
                  data={curCalendar}
                  data-testid='current'
                ></SellerCalendar>
              )}
            </div>

            {!isOpened && data.length > 1 && (
              <Button
                styling='naked-green'
                className='font-semibold'
                onClick={toggleCollapse}
              >
                {data.length < 3
                  ? t('Show next month')
                  : t('Show *n* next months').replace(
                      '*n*',
                      String(data.length - 1)
                    )}
              </Button>
            )}
          </div>
        )}
        contentRenderer={(toggleCollpase) => (
          <div className='px-4'>
            <div>
              {data.map((d) => (
                <SellerCalendar
                  data={d}
                  className='SellerCalendarCollapse__calendar mb-2'
                  key={d.month.getMonth()}
                />
              ))}
            </div>
            <Button
              styling='naked-green'
              className='-mt-2 font-semibold'
              onClick={toggleCollpase}
            >
              {t('Show current month')}
            </Button>
          </div>
        )}
      />
    </div>
  );
}
