import { useTranslation } from 'react-i18next';
import { formatDate } from '@/core/helpers';
import { normalizeDateFE } from '@/core/normalization';
import { NormalizedSellerFilingReport } from '@/core/types';
import Agenda from '@/icons/agenda.svg?react';
import Button from '../Button';
import Modal from '../Modal/Modal';
import TipIcon from '@/icons/tip.svg?react';
import './AgendaModal.scss';
import { useEffect, useState } from 'react';
import { APIEventsEnum, useSendEvent } from '../../hooks';
import MiniLoader from '../MiniLoader/MiniLoader';

interface Props {
  report: NormalizedSellerFilingReport | undefined;
  isModalOpened: boolean;
  closeModal: () => void;
  onClickConfirm: () => void;
  isConfirming: boolean;
}

export default function AgendaModal({
  report,
  isModalOpened,
  closeModal,
  onClickConfirm,
  isConfirming,
}: Props) {
  const { t } = useTranslation();
  const [confirmation, setConfirmation] = useState(false);
  const { sendEvent } = useSendEvent();

  useEffect(() => {
    if (isModalOpened) {
      setConfirmation(false);
    }
  }, [isModalOpened]);

  if (!report) return null;

  const renderPeriod = () => {
    let formatStr = 'MMMM yyyy';

    if (report.period.type === 'year') {
      formatStr = 'yyyy';
    }

    if (report.period.type === 'quarter') {
      formatStr = 'QQQ yyyy';
    }

    return formatDate(normalizeDateFE(report.period.from_date), formatStr);
  };

  return (
    <Modal
      isOpened={isModalOpened}
      close={closeModal}
      title='Agenda'
      isLoading={isConfirming}
      name='Agenda'
    >
      {confirmation ? (
        <div
          className='mb-4 flex flex-col items-center justify-center'
          style={{
            minHeight: 450,
          }}
        >
          <div className='mb-4 whitespace-pre-line text-center text-h4 font-extrabold'>
            {t('Confirm submission of German VAT report on Agenda portal')}
          </div>
          <Button
            data-testid='agenda-modal-confirm-btn'
            onClick={
              onClickConfirm
                ? () => {
                    sendEvent(APIEventsEnum.report_submition_agenda, {
                      block: 'Report submition via Agenda',
                    });
                    onClickConfirm();
                  }
                : undefined
            }
            disabled={isConfirming}
            className='flex items-center'
          >
            <span>{t('Confirm')}</span>
            {isConfirming && (
              <MiniLoader className='ml-2' color='gray' size='sm' />
            )}
          </Button>
        </div>
      ) : (
        <div className='mx-4 px-4 pb-6 pt-4'>
          <div className='mb-2 flex items-center'>
            <div className='text-h4 font-extrabold'>
              {report.period.type === 'year' && <span>{t('Year')}</span>}{' '}
              {renderPeriod()}
            </div>
            {report.agenda_confirmed && (
              <div className='Text--green ml-auto flex items-center font-semibold'>
                <TipIcon className='AgendaModal__icon' />
                &nbsp;
                <span>{t('Submitted')}</span>
              </div>
            )}
          </div>
          <div className='flex items-center'>
            <div className='mr-2 font-extrabold'>{t('Submission with')}</div>
            <Agenda />
          </div>
          <div className='mb-6 mt-4'>
            <ol className='AgendaModal__list'>
              <li>
                <span>{t('Click on')}</span>{' '}
                <a
                  href='https://agenda-unternehmens-portal.de'
                  rel='noreferrer'
                  target='_blank'
                  className='text-blue-500 transition-colors hover:text-blue-600'
                >
                  agenda-unternehmens-portal.de
                </a>
              </li>
              <li>
                {t(
                  'Enter your access data for the Agenda Unternehmens-Portal.'
                )}
              </li>
              <li>
                {t(
                  'Select "Auswertungen Online / Steuerberechnung" and the period "*period*".'
                ).replace('*period*', renderPeriod())}
              </li>
              <li>{t('Check the accuracy and completeness of the data.')}</li>
              <li>{t('Click on "Freizeichnung erteilen".')}</li>
              <li>{t('Then click on "Freizeichnung bestätigen".')}</li>
            </ol>
          </div>
          <div className='flex flex-wrap gap-x-2'>
            {!report.agenda_confirmed && (
              <div>
                <Button
                  onClick={() => {
                    setConfirmation(true);
                  }}
                  data-testid='agenda-modal-submit-btn'
                >
                  {t('Mark as submitted')}
                </Button>
              </div>
            )}
            <div>
              <Button styling='outline' onClick={closeModal}>
                {t('Close')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}
