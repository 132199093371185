import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import CountryLabel from '@/components/CountryLabel/CountryLabel';
import Switch from '@/components/Switch/Switch';
import { formatNumber } from '@/core/helpers';
import { ClientWarehouse } from '@/core/types';

interface Props {
  warehouse: ClientWarehouse;
  className?: string;
  vatIds: string[];
  onClickVATIdSwitch: (c: string) => () => void;
}

export default function SetupCheckWarehousesItem({
  warehouse,
  className = '',
  vatIds,
  onClickVATIdSwitch,
}: Props) {
  const { t } = useTranslation();
  const isActiveWholeYear =
    warehouse.active_from.getDate() === 1 &&
    warehouse.active_from.getMonth() === 0 &&
    warehouse.active_from.getFullYear() === 2021;
  return (
    <div className={`border-t border-gray-500 ${className} pt-2`}>
      <CountryLabel country={warehouse.country} className='mb-2' />
      <div className='mb-2 flex gap-x-6'>
        <div className=''>{t('Since')}</div>
        <div className=' text-end'>
          {isActiveWholeYear
            ? t('Whole year')
            : format(warehouse.active_from, 'dd.MM.yyyy')}
        </div>
      </div>
      <div className='mb-2 flex gap-x-6'>
        <div className=''>{t('Items sold')}</div>
        <div className=' text-end'>
          {formatNumber(warehouse.count_of_sold_items)}
        </div>
      </div>
      <div className='flex items-center gap-x-6'>
        <div className=''>{t('Registered VAT ID')}</div>
        <div className=' flex justify-end'>
          <Switch
            checked={vatIds.includes(warehouse.country)}
            onChange={onClickVATIdSwitch(warehouse.country)}
          />
        </div>
      </div>
    </div>
  );
}
