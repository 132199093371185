import classNames from 'classnames';
import { forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import './TooltipContent.scss';

export type Position =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top-right'
  | 'top-left'
  | 'bottom-right'
  | 'bottom-left';

interface Props {
  isOpened: boolean;
  content: string | JSX.Element | null;
  position: Position;
  x: number;
  className?: string;
  y: number;
  styling?: 'black' | 'white' | 'transparent';
  portal?: boolean;
}

const TooltipContent = forwardRef<HTMLDivElement, Props>(
  (
    {
      isOpened,
      content,
      position,
      x,
      y,
      className = '',
      styling = 'black',
      portal = true,
    },
    ref
  ) => {
    const v = (
      <CSSTransition
        in={isOpened}
        timeout={{
          enter: 250,
          exit: 250,
        }}
        nodeRef={ref}
        unmountOnExit
        classNames={{
          enter: 'fadeIn',
          exit: 'fadeOut',
        }}
      >
        <div
          className={classNames('TooltipContent absolute', className, {
            [`TooltipContent--${position}`]: !!position,
            '!bg-black': styling === 'black',
            '!bg-white !text-dark shadow-sm': styling === 'white',
            '!bg-none shadow-none': styling === 'transparent',
          })}
          ref={ref}
          style={{
            top: y,
            left: x,
          }}
        >
          {content}
          <div
            className={classNames('TooltipContent__arrow absolute', {
              'TooltipContent__arrow--black': styling === 'black',
              'TooltipContent__arrow--white': styling === 'white',
              'TooltipContent__arrow--transparent': styling === 'transparent',
            })}
          />
        </div>
      </CSSTransition>
    );
    if (!portal) {
      return v;
    }
    return createPortal(v, document.getElementById('tooltips')!);
  }
);

export default TooltipContent;
