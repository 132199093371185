import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import ossReducer from './oss/ossSlice';
import amazonReducer from './amazon/amazonSlice';
import notificationsSlice from './notificationsSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    oss: ossReducer,
    amazon: amazonReducer,
    notifications: notificationsSlice,
  },
  devTools: import.meta.env.MODE !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
