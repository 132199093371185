import { Link, LinkProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NormalizedSellerFilingReport } from '@/core/types';
import EU from '@/icons/countries/EU.svg?react';
import { Badge } from '../Badge/Badge';
import {
  isReportPaid,
  isReportPrepared,
  isReportSubmitted,
} from '../OpenTasks/OpenTasks.helpers';
import { useEffect } from 'react';
import classNames from 'classnames';

export interface OSSSeSeWidgetLink extends LinkProps {
  label?: string;
  icon?: JSX.Element;
}
interface Props {
  report: NormalizedSellerFilingReport;
  link?: OSSSeSeWidgetLink;
}

export const OSSSeSeReportPreview = ({ report, link }: Props) => {
  const { t, i18n } = useTranslation();
  const isOSSReportReady = report ? isReportPrepared(report) : false;
  const isPaid = report ? isReportPaid(report) : false;
  const isSubmitted = report ? isReportSubmitted(report) : false;

  useEffect(() => {
    if (window.hj) window.hj('event', 'oss_report_preview');
  }, []);

  return (
    <div className='mb-4 flex items-center justify-between space-y-0.5 rounded-lg border-2 border-white bg-white bg-opacity-[66%] px-4 py-2'>
      <div className='flex items-center gap-x-2 font-bold'>
        <EU />
        <span>
          {report
            ? t('OSS report for *period*').replace(
                '*period*',
                String(report.period?.label)
              )
            : t('OSS report')}
        </span>
      </div>
      <div>
        <div className='flex items-center gap-x-2 font-semibold'>
          <Badge
            color={`${isOSSReportReady || isSubmitted ? 'green' : 'gray'}`}
            label={t(
              `${
                isOSSReportReady
                  ? 'Ready'
                  : isSubmitted || isPaid
                    ? 'Submitted'
                    : 'To be generated'
              }`
            )}
          />
          {link && (
            <Link
              className={classNames(
                'Button Button--outline Button--sm flex !w-[180px] items-center justify-center gap-x-1 !py-1',
                {
                  '!w-[215px]': i18n.language === 'de',
                }
              )}
              to={link.to}
              state={link.state}
            >
              {link.icon}
              <span>{t(link.label || 'Go to report page')}</span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
