import Shimmer from '../Shimmer/Shimmer';
import { Skeleton } from '../Skeleton/Skeleton';
import '../../components/Skeleton/Skeleton.scss';

export function ResolveErrorModalSkeleton() {
  return (
    <div className='ModalSkeleton flex-grow space-y-8 rounded-lg bg-white px-4 py-5'>
      <div className='space-y-3'>
        <Shimmer
          maxWidth={300}
          className={`fadeIn Skeleton__skeletonItem animation-forwards Shimmer__small`}
        >
          <Skeleton className='!h-4' />
        </Shimmer>
        <Shimmer
          maxWidth={600}
          className={`fadeIn Skeleton__skeletonItem animation-forwards Shimmer__large`}
        >
          <Skeleton className='Skeleton--light !h-6' />
        </Shimmer>
        <Shimmer
          maxWidth={600}
          className={`fadeIn Skeleton__skeletonItem animation-forwards Shimmer__small`}
        >
          <Skeleton className='Skeleton--light !h-6' />
        </Shimmer>
      </div>
      {Array(2)
        .fill(1)
        .map((_, j) => (
          <Shimmer
            key={j}
            maxWidth={600}
            className={`fadeIn Skeleton__skeletonItem animation-forwards`}
          >
            <Skeleton className='Skeleton--light !h-6' />
          </Shimmer>
        ))}

      <Shimmer
        maxWidth={80}
        className={`fadeIn Skeleton__skeletonItem animation-forwards Shimmer__small`}
      >
        <Skeleton className='Skeleton--light !h-6' />
      </Shimmer>
    </div>
  );
}
