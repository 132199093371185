import Arrow from '@/icons/select-chevron.svg?react';
import { formatDate, formatNumber } from '@/core/helpers';
import classNames from 'classnames';
import { CompletedFilingItemData } from '../../pages/Seller/SellerFiling/SellerPastPeriodsFiling';
import { useTranslation } from 'react-i18next';
import {
  FilingReportConfirmation,
  NormalizedSellerFilingReport,
} from '@/core/types';
import {
  groupReportsByCountryAndType,
  groupReportsByPeriodType,
} from '../../pages/Seller/SellerFiling/SellerFiling.helpers';
import { SellerFilingPastPeriodsGroup } from './SellerFilingPastPeriodsGroup';
import { ControlledCollapse } from '../Collapse/ControlledCollapse';

interface Props {
  data: CompletedFilingItemData;
  initialOpened?: boolean;
  onFolderBtnClick: (
    confirmations: FilingReportConfirmation[],
    date: Date,
    country: string
  ) => () => void;
  onAmountBtnClick: (r: NormalizedSellerFilingReport) => () => void;
  onClickAgenda: (r: NormalizedSellerFilingReport) => () => void;
}

export default function SellerPastPeriodsFilingItem({
  data,
  initialOpened,
  onFolderBtnClick,
  onAmountBtnClick,
  onClickAgenda,
}: Props) {
  const { t, i18n } = useTranslation();

  const baseCurrencyOrder = ['EUR', 'GBP', 'PLN', 'CZK', 'SEK'];
  const filteredBaseCurrencyOrder = baseCurrencyOrder.filter((currency) =>
    Object.prototype.hasOwnProperty.call(data.amounts, currency)
  );
  const finalCurrencyOrder = filteredBaseCurrencyOrder
    .concat(
      Object.keys(data.amounts)
        .filter((currency) => !filteredBaseCurrencyOrder.includes(currency))
        .sort()
    )
    .reverse();

  return (
    <ControlledCollapse
      initialOpened={initialOpened}
      className='mb-2'
      triggerRenderer={(isOpened, toggleCollapse) => (
        <div
          className={`flex cursor-pointer items-start ${
            isOpened ? 'rounded-t-lg ' : 'rounded-lg'
          } border py-2 pl-4 pr-2`}
          onClick={toggleCollapse}
        >
          <div className='SellerPastPeriodsFiling__top flex-shrink-0 font-extrabold'>
            {formatDate(data.month, 'MMMM yyyy')}
          </div>
          <div className='flex flex-grow items-start justify-end'>
            <div className='flex flex-wrap justify-end'>
              {!!Object.keys(data.amounts).length && (
                <span className='shrink-0'>{t('Total paid')}:</span>
              )}
              {finalCurrencyOrder.map((c, i) => (
                <div key={c} className='SellerPastPeriodsFiling__amount'>
                  &nbsp;{i > 0 ? ' ' : ''}
                  {formatNumber(
                    data.amounts[c] / 100,
                    {
                      currency: c,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                    undefined,
                    true
                  )}
                  &nbsp;
                </div>
              ))}
            </div>
            <Arrow
              className={classNames('SellerPastPeriodsFiling__arrow', {
                'SellerPastPeriodsFiling__arrow--active': isOpened,
              })}
            />
          </div>
        </div>
      )}
      contentRenderer={() => (
        <div className='[&>*:last-child]:rounded-b-lg'>
          <div className='mx-0 flex border-x border-b py-2 pl-4 pr-2 text-gray-700'>
            <div className='grid w-full grid-cols-[190px,minmax(100px,1.2fr),minmax(280px,2.6fr),40px] gap-x-2'>
              <div>{t('Country')}</div>
              <div>{t('Period')}</div>
              <div className='flex items-center justify-end gap-x-4'>
                <div className='text-end'>{t('VAT amount')}</div>
                <div
                  className={`${
                    i18n.language === 'en' ? '!w-[160px]' : '!w-[180px]'
                  }`}
                ></div>
              </div>
            </div>
          </div>
          {groupReportsByCountryAndType(data.reports).map((countryGroup) => {
            return (
              <div
                key={countryGroup.country + countryGroup.type}
                className='flex border-x border-b py-3 pl-4 pr-2'
              >
                <div className='flex w-full flex-col gap-y-4'>
                  {groupReportsByPeriodType(countryGroup.reports).map(
                    (group) => {
                      return (
                        <SellerFilingPastPeriodsGroup
                          key={group.periodType}
                          onFolderBtnClick={onFolderBtnClick}
                          onAmountBtnClick={onAmountBtnClick}
                          onClickAgenda={onClickAgenda}
                          month={data.month}
                          group={group}
                          countryGroup={countryGroup}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    />
  );
}
