import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Lang } from '@/core/types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import '../TermsOfService/TermsOfService.scss';
import { PublicRoutesEnum } from '@/core/enums';
import Footer from '@/components/Footer/Footer';
import remarkGfm from 'remark-gfm';
import Header from '@/components/Header/Header';

export const DATA_PROTECTION_VERSION = 'v20220614';

const getDataPeocessingAgreement = async (lang: Lang) => {
  let res = undefined;
  if (lang === 'de') {
    res = await axios.get(
      `${PublicRoutesEnum.DataProcessingAgreement}/${DATA_PROTECTION_VERSION}-de.md`
    );
  } else {
    res = await axios.get(
      `${PublicRoutesEnum.DataProcessingAgreement}/${DATA_PROTECTION_VERSION}.md`
    );
  }
  return res.data;
};

export default function DataProcessingAgreement() {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const [content, setContent] = useState('');

  useEffect(() => {
    setContent('');
    getDataPeocessingAgreement(language as Lang).then((res) => setContent(res));
  }, [language]);

  if (!content) return null;

  return (
    <div className='TermsOfService flex min-h-screen flex-col'>
      <Header />
      <ReactMarkdown
        children={content}
        className='TermsOfService__container flex-grow'
        rehypePlugins={[rehypeRaw]}
        remarkPlugins={[remarkGfm]}
      />
      <Footer />
    </div>
  );
}
