import { useTranslation } from 'react-i18next';
import Modal from '@/components/Modal/Modal';
import { EMAIL } from '@/core/constants';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
}

export default function PurchasePricesFAQModal({
  isModalOpened,
  closeModal,
}: Props) {
  const { t } = useTranslation();
  return (
    <Modal isOpened={isModalOpened} close={closeModal} title={t('Information')}>
      <div className='mx-4 px-4 pb-6 pt-4'>
        <div className='mb-2 text-h4 font-extrabold'>
          {t('FAQ: Purchase prices')}
        </div>
        <div className='font-black'>{t('Strict deadline for submission')}</div>
        <div className='Text--30 font-black'>{t('10th each month')}</div>
        <div>
          {t(
            'If you have a VAT obligation in Germany we need them latest until 7th.'
          )}
        </div>
        <div className='mb-2'>
          {t('You would receive the reminder by email in advance.')}
        </div>
        <div className='pt-2 font-extrabold'>{t('What is Purchase Price')}</div>
        <div className='mb-2'>
          {t(
            'The price for which the product has been bought from your supplier (shipping cost should not be included in this price). In case the price is changing each month with very slight differences you can provide us with the average price of these.'
          )}
        </div>
        <div className='mb-2'>
          {t(
            'This price is serving us as ground for reporting movement/shipment of goods between warehouses and to calculate them in the smallest possible value.'
          )}
        </div>
        <div className='pt-2 font-extrabold'>{t('Why do we need them')}</div>
        <div className='mb-2'>
          {t(
            'Without purchase prices we are not able to issue for you proforma invoices, prepare sales listing and VAT reports which are required by local Tax Offices.'
          )}
        </div>
        <div className='mb-2'>
          {t(
            'Please be aware that the prices are only requested if there are new products which have not been reported in previous periods. In case the purchase price for some products changed you need to inform us so we can update the information in our system.'
          )}
        </div>
        <div className='pt-2 font-extrabold'>{t('How to provide')}</div>
        <div className='mb-2'>
          {t(
            'Once we need purchase prices for the new products the list will be added to the Client Portal. You will need to download the list of products, update prices and upload it back on the Client Portal. Additionally, you will receive an email notification every time when an upload is needed.'
          )}
        </div>
        <div className='mb-2'>
          {t(
            'Same as turnover data you need to upload these through the Client Portal, so it is processed by our system in time.'
          )}
        </div>
        <div className='Text--orange mb-2'>
          {t(
            'Please note: in case the purchase prices are sent to us via email these will be processed manually by your customer success manager and it will be charged on hourly rate.'
          )}
        </div>
        <div className='pt-2 font-extrabold'>
          {t("In case you miss a deadline or didn't provide them")}
        </div>
        <div className='mb-2'>
          {t(
            'In this case our VAT experts cannot prepare the correct VAT returns and here we face risk of late submission, incomplete submission, potential penalty and need of corrective VAT report.'
          )}
        </div>
        <div className='mb-2'>
          <a
            href={`mailto:${EMAIL}`}
            className='text-blue-500 transition-colors hover:text-blue-600'
          >
            {EMAIL}
          </a>
          <span>&nbsp;-&nbsp;</span>
          <span>
            {t(
              'contact us immediately in case you missed the deadline and did not upload the purchase price(s).'
            )}
          </span>
        </div>
        <div className='Text--orange mb-2'>
          {t(
            'Please note: Additional work connected to not providing purchase prices on time will be charged on hourly rate as this requires extra effort on our end. In case a corrective VAT return needs to be submitted this will be charged on top as well.'
          )}
        </div>
      </div>
    </Modal>
  );
}
