import './PurchasePricesFileLink.scss';
import { getS3Link } from '@/core/s3';
import { downloadFile } from '@/core/helpers';
import MiniLoader from '../MiniLoader/MiniLoader';
import Button from '../Button';
import { ErrorFileIconsMap, FileIconsMap } from './ManualUploader.helpers';

interface Props {
  className?: string;
  icon?: JSX.Element;
  readyIcon?: JSX.Element;
  description?: JSX.Element;
  onDownload?: () => void;
  name: string;
  modifyName?: boolean;
  bucket: string;
  path: string;
  isLoading?: boolean;
  isError?: boolean;
}

export default function PurchasePricesFileLink({
  name,
  className = '',
  icon,
  bucket,
  path,
  description,
  modifyName = true,
  isLoading,
  onDownload,
  isError,
  readyIcon,
}: Props) {
  const handleDownload = async () => {
    const parts = `${bucket}/${path}`.split('/');

    try {
      const url = await getS3Link(parts[0], parts.slice(1).join('/'));
      downloadFile(url, parts[parts.length - 1]);
    } catch (error) {
      console.error(error);
    }
  };

  const modifiedName = name
    .split('.')
    .map((v, i, self) => {
      if (i === self.length - 2) {
        return v.replace(/_\d+$/, '..');
      }
      return v;
    })
    .join('.');

  return (
    <Button
      styling='naked'
      className={`PurchasePricesFileLink !w-full text-start`}
      onClick={onDownload || handleDownload}
      type='button'
    >
      <div className={`flex items-start justify-between ${className}`}>
        <div className='flex'>
          <span className='ManualUploadFileLink__icon mr-2 rounded-full'>
            {icon ||
              (isError
                ? ErrorFileIconsMap[name.split('.').slice(-1)[0]!.toLowerCase()]
                : FileIconsMap[name.split('.').slice(-1)[0]!.toLowerCase()])}
          </span>
          <div className='flex flex-col items-start justify-center'>
            <div className='font-semibold'>
              {modifyName ? modifiedName : name}
            </div>

            {description}
          </div>
        </div>

        {isLoading ? (
          <MiniLoader className='self-center' />
        ) : readyIcon ? (
          <div className='self-center'>{readyIcon}</div>
        ) : null}
      </div>
    </Button>
  );
}
