import { useTranslation } from 'react-i18next';
import { AuthedRoutesEnum, RolesEnum } from '@/core/enums';
import { normalizeDateFE } from '@/core/normalization';
import AgendaModal from '../AgendaModal/AgendaModal';
import useAgendaModal from '../../pages/Seller/SellerFiling/useAgendaModal';
import { DataUnit, NormalizedSellerFilingReport } from '@/core/types';
import {
  differenceInCalendarMonths,
  differenceInCalendarQuarters,
  subMonths,
} from 'date-fns';
import { formatDate, formatNumber } from '@/core/helpers';
import OpenTask, { TodoItem } from './OpenTask';
import {
  PropsWithChildren,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Button from '../Button';
import './OpenTasks.scss';
import { v4 as uuidv4 } from 'uuid';
import { useAppSelector } from '../../hooks';
import { activeCompanySelector } from '@/core/store/user/userSlice';
import Flag from '../CountryLabel/Flag';
import {
  TaskForEnum,
  createOSSSeSeDueDate,
  isReportPaid,
  isReportPrepared,
  isReportSubmitted,
  isShouldSubmitOSSReport,
} from './OpenTasks.helpers';

interface Props {
  isMUNeeded: boolean | undefined;
  purchasePricesNeeded: boolean | undefined;
  reportsToConfirmPayment: NormalizedSellerFilingReport[];
  reportsToConfirmAgenda: NormalizedSellerFilingReport[];
  isOSSSelfService: boolean;
  OSSReports: NormalizedSellerFilingReport[];
  handleOpenPaymentInstructionModal: (
    r: NormalizedSellerFilingReport
  ) => () => void;
  openSubmitBOPModal: (r: NormalizedSellerFilingReport) => () => void;
  dataUnitsForCorrections: DataUnit[];
}

export default function OpenTasks({
  isMUNeeded,
  purchasePricesNeeded,
  reportsToConfirmPayment,
  reportsToConfirmAgenda,
  isOSSSelfService,
  OSSReports,
  handleOpenPaymentInstructionModal,
  openSubmitBOPModal,
  children,
  dataUnitsForCorrections,
}: PropsWithChildren<Props>) {
  const ref = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  const { t } = useTranslation();
  const [isExtended, setIsExtended] = useState(false);
  const activeCompany = useAppSelector(activeCompanySelector);

  const {
    isAgendaModalOpened,
    closeAgendaModal,
    handleOpenAgendaModal,
    onClickConfirmAgenda,
    isConfirmingAgenda,
    reportToConfirmAgenda,
  } = useAgendaModal();

  const submissionBy = activeCompany?.ClientOSSDetails?.SubmissionBy;
  const companyId = activeCompany?.company_id;
  const isUseNewProcess = activeCompany?.IsUseNewProcess;

  const taxAdvisorTodosOSSSeSe: TodoItem[] = useMemo(() => {
    return OSSReports.filter((r) => isReportPrepared(r)).flatMap((r) => {
      const recommendedDueDate = createOSSSeSeDueDate(r, 3);
      const isSubmitted = isReportSubmitted(r);

      return [
        {
          label: (
            <div className='flex flex-shrink-0 items-center gap-x-2'>
              <Flag country='EU' />
              <div>{'OSS'}:</div>
              <div>{t('Submit report')}</div>
            </div>
          ),
          description: 'We review it after submission',
          date: recommendedDueDate,
          action: () => {},
          btnLabel: isSubmitted ? 'Done' : 'by Tax Advisor',
          id: `tax-advisor-submit-oss-report-${r.id}`,
          reportType: r.type,
          disabled: true,
          done: isSubmitted,
          btnClassName: `!rounded-full !bg-gray-400 !border-none ${
            isSubmitted ? '!text-green' : ''
          }`,
        },
      ];
    });
  }, [OSSReports, t]);

  const ongoingTaxAdvisorTasks = useMemo(
    () =>
      taxAdvisorTodosOSSSeSe.filter((todo) => {
        return (
          differenceInCalendarMonths(new Date(), todo.date) <= 0 && !todo.done
        );
      }),
    [taxAdvisorTodosOSSSeSe]
  );

  const expiredTaxAdvisorTasks = useMemo(
    () =>
      taxAdvisorTodosOSSSeSe.filter((todo) => {
        return (
          differenceInCalendarMonths(new Date(), todo.date) > 0 && !todo.done
        );
      }),
    [taxAdvisorTodosOSSSeSe]
  );

  const todos: TodoItem[] = useMemo(() => {
    const uploadingDate = new Date();
    uploadingDate.setDate(10);

    return [
      ...(isMUNeeded
        ? [
            {
              label: 'Upload manual turnover data',
              description: (
                <span>
                  {t('Upload manual turnover data for *month*').replace(
                    '*month*',
                    formatDate(subMonths(uploadingDate, 1), 'MMM yyyy')
                  )}
                </span>
              ),
              date: uploadingDate,
              action: AuthedRoutesEnum.DataFiles,
              btnLabel: 'Upload',
              id: 'manual-upload',
              taskFor: TaskForEnum.client,
            },
          ]
        : []),
      ...(!!dataUnitsForCorrections.length
        ? dataUnitsForCorrections
            .map((dataUnit) => {
              const date = normalizeDateFE(dataUnit.date);
              return {
                label: `${t('Correct wrong documents for *month*').replace(
                  '*month*',
                  formatDate(date, 'MMM yyyy')
                )}`,
                description: 'According to the provided error list',
                date: uploadingDate,
                action: isUseNewProcess
                  ? `/documents?month=${date.getMonth()}&year=${date.getFullYear()}&company_id=${companyId}`
                  : AuthedRoutesEnum.DataFiles,
                btnLabel: 'Correct',
                id: 'correction-needed',
                taskFor: TaskForEnum.client,
              };
            })
            .flat()
        : []),
      ...(purchasePricesNeeded
        ? [
            {
              label: 'Update purchase prices',
              description: (
                <span>
                  {t('Upload purchase prices for *month*').replace(
                    '*month*',
                    formatDate(subMonths(uploadingDate, 1), 'MMM yyyy')
                  )}
                </span>
              ),
              date: uploadingDate,
              action: AuthedRoutesEnum.PurchasePrices,
              btnLabel: 'Update',
              id: 'purchase-prices',
              taskFor: TaskForEnum.client,
            },
          ]
        : []),
      ...(isOSSSelfService && submissionBy === RolesEnum.SELLER
        ? OSSReports.filter(
            (r) => isReportPrepared(r) && isShouldSubmitOSSReport(r)
          ).flatMap((r) => {
            const submissionDueDate = createOSSSeSeDueDate(r, 3);

            return [
              {
                label: (
                  <div className='flex flex-shrink-0 items-center gap-x-2'>
                    <Flag country='EU' />
                    <div>{'OSS'}:</div>
                    <div>{t('Submit report')}</div>
                  </div>
                ),
                description: 'Find the instruction on the report page',
                date: submissionDueDate,
                action: AuthedRoutesEnum.OSSFiles,
                btnLabel: 'Go to report',
                id: `countx-submit-oss-report-${r.id}`,
                taskFor: TaskForEnum.countx,
                reportType: r.type,
              },
              {
                label: (
                  <div className='flex flex-shrink-0 items-start gap-x-2'>
                    <Flag country='EU' className='mt-1 flex-shrink-0' />
                    <div>{'OSS'}:</div>
                    <div>{t('Mark OSS report as submitted')}</div>
                  </div>
                ),
                description: 'After submitting it on the BOP portal',
                date: submissionDueDate,
                action: openSubmitBOPModal(r),
                btnLabel: 'Mark as submitted',
                id: `submit-oss-${r.id}`,
                taskFor: TaskForEnum.client,
                reportType: r.type,
              },
              {
                label: (
                  <div className='flex flex-shrink-0 items-start gap-x-2'>
                    <Flag country='EU' className='mt-1 flex-shrink-0' />
                    <div>{'OSS'}:</div>
                    <div>{t('Check if the file has been accepted')}</div>
                  </div>
                ),
                description: 'We review it after submission',
                date: normalizeDateFE(r.deadline_date),
                //action: openSubmitBOPModal(r), //change Modal here
                // btnLabel: 'Confirm',
                disabled: !(isReportPaid(r) || isReportSubmitted(r)),
                id: `check-acception-oss-${r.id}`,
                taskFor: TaskForEnum.client,
                reportType: r.type,
              },
            ];
          })
        : []),
      ...(isOSSSelfService && submissionBy === RolesEnum.TAX_ADVISOR
        ? expiredTaxAdvisorTasks
        : []),
      ...reportsToConfirmPayment
        .filter((r) =>
          isOSSSelfService && r.type === 'oss'
            ? isReportPrepared(r) || (isReportSubmitted(r) && !isReportPaid(r))
            : r
        )
        .map((r) => ({
          label: (
            <div className='flex flex-shrink-0 flex-wrap items-start gap-x-2'>
              <Flag
                country={r.type === 'oss' ? 'EU' : r.country.short_name}
                className='mt-1 flex-shrink-0'
              />
              <div>{r.type === 'oss' ? 'OSS' : t(r.country.short_name)}:</div>
              {r.payment_instruction && (
                <div>
                  {t('*amount* VAT payment').replace(
                    '*amount*',
                    formatNumber(
                      r.payment_instruction.amount.amount / 100,
                      {
                        currency: r.payment_instruction.amount.currency,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                      undefined,
                      true
                    )
                  )}
                </div>
              )}
            </div>
          ),
          description: (
            <span>
              {isOSSSelfService && r.type === 'oss'
                ? t(
                    'Instruction will be available once OSS report is submitted'
                  )
                : `${t('Period')}:`}
              &nbsp;
              {!(isOSSSelfService && r.type === 'oss') &&
                (r.period.type === 'quarter'
                  ? formatDate(normalizeDateFE(r.period.from_date), 'QQQ yyyy')
                  : r.period.type === 'year'
                    ? formatDate(normalizeDateFE(r.period.from_date), 'yyyy')
                    : formatDate(
                        normalizeDateFE(r.period.from_date),
                        'MMM yyyy'
                      ))}
            </span>
          ),
          date: createOSSSeSeDueDate(r),
          action: handleOpenPaymentInstructionModal(r),
          btnLabel: 'Open instruction',
          id: `report-${r.id}-${r.payment_instruction?.id}-payment`,
          taskFor: TaskForEnum.client,
          reportType: r.type,
          disabled:
            isOSSSelfService &&
            r.type === 'oss' &&
            !isReportSubmitted(r) &&
            !isReportPaid(r),
        })),
      ...reportsToConfirmAgenda.map((r) => ({
        label: (
          <div className='flex flex-shrink-0 flex-wrap items-start gap-x-2'>
            <Flag
              country={r.country.short_name}
              className='mt-1 flex-shrink-0'
            />
            <div>{t(r.country.short_name)}:</div>
            <div>{t('Submit report to Agenda')}</div>
          </div>
        ),
        description: 'Please confirm once it is done',
        date: normalizeDateFE(
          r.payment_instruction?.due_date || r.deadline_date
        ),
        action: handleOpenAgendaModal(r),
        btnLabel: 'Open',
        id: `report-${r.id}-${r.payment_instruction?.id}-agenda`,
        taskFor: TaskForEnum.client,
        reportType: r.type,
      })),
    ];
  }, [
    isMUNeeded,
    t,
    dataUnitsForCorrections,
    purchasePricesNeeded,
    isOSSSelfService,
    submissionBy,
    OSSReports,
    expiredTaxAdvisorTasks,
    reportsToConfirmPayment,
    reportsToConfirmAgenda,
    companyId,
    openSubmitBOPModal,
    handleOpenPaymentInstructionModal,
    handleOpenAgendaModal,
    isUseNewProcess,
  ]);

  const ongoing = todos.filter((todo) => {
    if (todo.id === 'correction-needed') {
      return differenceInCalendarQuarters(new Date(), todo.date) <= 1;
    }
    return differenceInCalendarMonths(new Date(), todo.date) <= 0;
  });

  const expired = todos
    .filter((todo) => {
      if (todo.id === 'correction-needed') {
        return differenceInCalendarQuarters(new Date(), todo.date) > 1;
      }

      return isOSSSelfService && todo.reportType === 'oss'
        ? differenceInCalendarQuarters(new Date(), todo.date) > 0
        : differenceInCalendarMonths(new Date(), todo.date) > 0;
    })
    .sort((a, b) => {
      return a.date.getTime() - b.date.getTime();
    });

  useLayoutEffect(() => {
    if (ref.current) {
      setMaxHeight(isExtended ? ref.current.offsetHeight : 0);
    }
  }, [isExtended]);

  return (
    <div className='OpenTasks mb-4'>
      {children}
      {!!ongoing.length ? (
        <div className='mb-4'>
          <div
            className='mb-2 font-extrabold'
            data-testid='ongoing-tasks-header'
          >
            <span>{t('Ongoing tasks')}</span>&nbsp;<span>{ongoing.length}</span>
          </div>
          {ongoing
            .sort((a, b) => {
              return a.date.getTime() - b.date.getTime();
            })
            .map((item, i) => (
              <OpenTask
                done={item.done || false}
                item={item}
                index={i + 1}
                key={uuidv4()}
                id={item.id}
                type='ongoing'
                disabled={item.disabled}
                isOSSSelfService={isOSSSelfService}
              />
            ))}
        </div>
      ) : (
        <div>
          {!isOSSSelfService && (
            <div
              className='OpenTasks__noTasks mb-4 px-4 pb-4 pt-2 text-white'
              data-testid='green-alert'
            >
              <div className='font-extrabold' data-testid='green-alert-title'>
                {t("You don't have any ongoing tasks")}
              </div>
              <div data-testid='green-alert-text'>
                {t('Once we need something from you, you would get an email')}
              </div>
            </div>
          )}
        </div>
      )}
      {isOSSSelfService &&
        !!ongoingTaxAdvisorTasks.length &&
        submissionBy === RolesEnum.TAX_ADVISOR && (
          <div className='mb-4'>
            <div
              className='mb-2 font-extrabold'
              data-testid='ongoing-tasks-header'
            >
              <span>{t('Tasks for Tax Advisor')}</span>&nbsp;
              <span>{ongoing.length}</span>
            </div>
            {ongoingTaxAdvisorTasks
              .sort((a, b) => {
                return a.date.getTime() - b.date.getTime();
              })
              .map((item, i) => (
                <OpenTask
                  done={item.done || false}
                  item={item}
                  index={i + 1}
                  key={uuidv4()}
                  id={item.id}
                  type='ongoing'
                  disabled={item.disabled}
                  isOSSSelfService={isOSSSelfService}
                />
              ))}
          </div>
        )}
      {!!expired.length && (
        <>
          <div className='mb-4'>
            <div
              className='mb-2 font-extrabold'
              data-testid='expired-tasks-header'
            >
              <span>{t('Expired tasks')}</span>&nbsp;
              <span>{expired.length}</span>
            </div>
            <div>
              {expired.slice(0, 3).map((item, i) => (
                <OpenTask
                  done={item.done || false}
                  item={item}
                  index={i + 1}
                  key={uuidv4()}
                  id={item.id}
                  type='expired'
                  disabled={item.disabled}
                />
              ))}
              <div
                className='OpenTasks__collapse'
                style={{
                  maxHeight,
                }}
                data-testid='expired-tasks-collapse'
              >
                <div ref={ref}>
                  {expired.slice(3).map((item, i) => (
                    <OpenTask
                      done={item.done || false}
                      item={item}
                      index={i + 4}
                      key={uuidv4()}
                      id={item.id}
                      type='expired'
                      disabled={item.disabled}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          {expired.length > 3 && (
            <Button
              data-testid='expired-tasks-btn'
              className='mb-2 !w-full'
              styling='gray'
              onClick={() => setIsExtended((s) => !s)}
            >
              {isExtended
                ? t('Show less')
                : t('Show *count* more').replace(
                    '*count*',
                    String(expired.slice(3).length)
                  )}
            </Button>
          )}
        </>
      )}
      <AgendaModal
        report={reportToConfirmAgenda}
        isModalOpened={isAgendaModalOpened}
        closeModal={closeAgendaModal}
        onClickConfirm={onClickConfirmAgenda(reportToConfirmAgenda)}
        isConfirming={isConfirmingAgenda}
      />
    </div>
  );
}
