import { useTranslation } from 'react-i18next';
import Calculator from '@/icons/calculator.svg?react';
import './AmazonFBACalculator.scss';
import Button from '@/components/Button';
import { PagesEnum } from '@/core/constants';
import useLinkToMainSite from '../../../hooks/useLinkToMainSite';
import { useBookMeetingModal } from '@/components/BookMeetingModal/useBookMeetingModal';

export default function AmazonFBACalculator() {
  const { openModal } = useBookMeetingModal();
  const { getLink } = useLinkToMainSite();
  const { t } = useTranslation();
  return (
    <div className='AmazonFBACalculator flex-grow'>
      <div className='AmazonFBACalculator__tile mx-auto flex flex-col items-center px-3'>
        <Calculator />
        <div className='Title AmazonFBACalculator__title mt-4 text-center font-black'>
          {t('We are currently calculating and will email you the results')}
        </div>
        <div className='Text mt-2 text-center'>
          {t('Please note that processing your data may take up to 24 hours')}
        </div>
        <div className='mt-4'>
          <div className='flex gap-3'>
            <a
              className='Button justify-content-center AmazonFBACalculator__link flex items-center'
              href={getLink(PagesEnum.index)}
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('To homepage')}
            </a>
            <Button styling='outline' size='lg' onClick={() => openModal()}>
              {t('Book a consultation')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
