import { useTranslation } from 'react-i18next';
import FolderIcon from '@/icons/folder-green-xl.svg?react';

interface Props {
  title: string;
  text?: string;
}

export function EmptyStateTile({ title, text }: Props) {
  const { t } = useTranslation();
  return (
    <div className='flex min-h-[344px] flex-grow flex-col items-center justify-center gap-y-2 rounded-lg bg-white'>
      <FolderIcon />
      <div className='text-center text-h4 font-extrabold'>{t(title)}</div>
      {text && <div className='max-w-sm text-center'>{t(text)}</div>}
    </div>
  );
}
