import { useTranslation } from 'react-i18next';
import instructionImg1 from '@/images/new-process-instructions-1.png';
import instructionImg2 from '@/images/new-process-instructions-3.png';
import instructionImg1De from '@/images/new-process-instructions-1-de.png';
import instructionImg2De from '@/images/new-process-instructions-3-de.png';
import { useLayoutEffect, useState } from 'react';
import { links } from './instructionsData.helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { getBoundingAbsoluteRect } from '@/core/helpers';

export default function SellerNewProcessesInstructions() {
  const [imagesToLoadCount, setImagesToLoadCount] = useState(2);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const anchor = event.currentTarget as HTMLElement;

    const anchorId = anchor.getAttribute('href');
    navigate(
      {
        pathname: location.pathname,
        hash: (anchorId || '').slice(1),
      },
      {
        replace: true,
        state: location.state,
      }
    );
  };

  const { hash } = location;
  useLayoutEffect(() => {
    if (hash && imagesToLoadCount === 0) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        const rect = getBoundingAbsoluteRect(targetElement as HTMLElement);
        window.scrollTo({ left: 0, top: rect.top - 70, behavior: 'smooth' });
      }
    }
  }, [hash, imagesToLoadCount]);

  return (
    <div className='flex w-full bg-gray-300 py-8'>
      <div className='mx-auto max-w-[704px] divide-y rounded-lg bg-white'>
        <div className='px-6 py-2'>
          <div className='pb-2 text-h3 font-black'>
            {t('New processes on how we work with your turnover data')}
          </div>
          <div className='flex flex-col gap-y-1'>
            {links.map((l) => (
              <a
                onClick={handleClick}
                className='font-semibold text-blue-500'
                href={l.href}
                key={l.href}
              >
                {t(l.title)}
              </a>
            ))}
          </div>
        </div>
        <div className='space-y-2 px-6 pb-8 pt-2'>
          <h3 className='Text font-extrabold'>{t('About new processes')}</h3>
          <ol className='list-decimal pl-5'>
            <li>
              {t(
                'We get your data automatically from your invoice system or manually from you'
              )}
            </li>
            <li>{t('We check all invoices')}</li>
            <li>
              {t('If we find any errors, you will see them in this section')}{' '}
              {t(
                'We’ll also show to-do for invoice correction in Global overview section'
              )}
            </li>
            <li>
              {t('Download the list of errors from the Documents dashboard')}
            </li>
            <li>
              {t('Correct the errors according to our instructions')}{' '}
              <a
                href='#how-to-correct'
                className='font-medium text-blue-500'
                onClick={handleClick}
              >
                {t('Check the guide')}
              </a>
            </li>
            <li>{t('Upload the corrected invoices back')}</li>
            <li>
              {t(
                'When file with corrected invoices will be processed, you can immediately check if all errors were successfully corrected or not'
              )}
            </li>
          </ol>
        </div>
        <div className='space-y-2 px-6 pb-8 pt-2' id='why-is-needed-to-fix'>
          <h3 className='Text font-extrabold'>
            {t('Why it is needed to fix the invoices_heading')}
          </h3>
          <p>
            {t(
              'Invoices with crucial errors can’t be included to filing, because otherwise tax office will immediately ask questions. There can be different consequences depending on the VAT amount and type of error. Invoices with non-critical errors can be included to filing, but still questions from the tax office can occur, so we advise you to check and correct them too.'
            )}
          </p>
        </div>
        <div className='space-y-2 px-6 pb-8 pt-2' id='types-of-errors'>
          <h3 className='Text font-extrabold'>{t('Types of errors')}</h3>
          <p>
            {t(
              'Based on our validation, we distribute all invoices into two groups'
            )}
            :
          </p>
          <div className='flex items-center gap-x-1'>
            <span className='inline-block h-4 w-4 rounded-full bg-red-500'></span>
            <span className='font-semibold'>{t('Errors')}</span>
          </div>
          <p>
            {t(
              'These invoices contain errors that prevent them from being reported. Please correct them immediately to ensure compliance with EU invoicing rules. Without corrections, we won’t include them in the filings. Corrections received after the deadline can only be reported as adjustments and will incur additional charges.'
            )}
          </p>
          <div className='flex items-center gap-x-1'>
            <span className='inline-block h-4 w-4 rounded-full bg-orange-500'></span>
            <span className='font-semibold'>{t('Notices')}</span>
          </div>
          <p>
            {t(
              'Those invoices contain errors but could still be included in the filing. We recommend checking them for possible discrepancies and suggesting corrections, but it is not required.'
            )}
          </p>
        </div>
        <div className='space-y-2 px-6 pb-8 pt-2' id='how-to-correct'>
          <h3 className='Text font-extrabold'>
            {t('How to correct invoices_heading')}
          </h3>
          <ol className='list-decimal space-y-6 pl-5'>
            <li>
              <div className='font-semibold'>
                {t('Download the list of wrong invoices')}
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${t(
                    'Select the period. Go to *documents* and click *export errors*'
                  )
                    .replace(
                      '*documents*',
                      `<span class='font-semibold'>${
                        i18n.language === 'de' ? 'Dokumente' : 'Documents'
                      }</span>`
                    )
                    .replace(
                      '*export errors*',
                      `<span class='font-semibold'>${
                        i18n.language === 'de' ? 'exportieren' : 'Export errors'
                      }</span>`
                    )}`,
                }}
              />
              <div className='-ml-5 pt-4'>
                <img
                  onLoad={() => {
                    setImagesToLoadCount((prev) => prev - 1);
                  }}
                  src={
                    i18n.language === 'de' ? instructionImg1De : instructionImg1
                  }
                  alt={'instructionImg1'}
                />
              </div>
            </li>
            <li>
              <div className='font-semibold'>{t('Cancel wrong invoices')}</div>
              <div>
                {t(
                  'Cancel the invoices with critical errors in your Invoice system. Create new invoices based on our instructions.'
                )}
              </div>
            </li>
            <li>
              <div className='font-semibold'>
                {t('Export the corrected invoices')}
              </div>
              <div>
                {t(
                  "Export all stornos and new correct invoices that you've created from your invoice system"
                )}
              </div>
            </li>
            <li>
              <div className='font-semibold'>
                {t('Upload the file with corrected invoices')}
              </div>
              <div>
                {t(
                  'Correct all the errors in the file downloaded from the client portal and upload it back once all the errors are fixed'
                )}
              </div>
              <div className='-ml-5 pt-4'>
                <img
                  onLoad={() => {
                    setImagesToLoadCount((prev) => prev - 1);
                  }}
                  src={
                    i18n.language === 'de' ? instructionImg2De : instructionImg2
                  }
                  alt={'instructionImg2'}
                />
              </div>
            </li>
            <li>
              <div className='font-semibold'>
                {t('Wait until data is processed')}
              </div>
              <div>
                {t(
                  'We will conduct a validation of the corrected invoices. Please wait until it is finished to ensure that no critical errors are left.'
                )}
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
