import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import TRANSLATIONS_DE from './de.json';
import TRANSLATIONS_EN from './en.json';
import { getCookie, setCookie } from '../core/helpers';
import { COOKIE } from '../core/constants';

export const getBrowserLanguage = (language: string) => {
  return (language || '').split('-')[0] as string;
};

const options = {
  // order and from where user language should be detected
  order: ['myDetector'],

  // keys or params to lookup language from
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['myDetector'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' },
};

const myDetector = {
  name: 'myDetector',
  lookup() {
    const navigatorLang = (window.navigator.language || '').split(
      '-'
    )[0] as string;

    return (
      getCookie(COOKIE.language) ||
      (navigatorLang && navigatorLang === 'en' ? 'en' : 'de') ||
      'de'
    );
  },
  cacheUserLanguage(lng: string) {
    setCookie(COOKIE.language, (lng || '').split('-')[0] as string);
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(myDetector);
languageDetector.init({
  detection: options,
});

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    fallbackLng: 'de',
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      de: {
        translation: TRANSLATIONS_DE,
      },
    },
  } as any);
