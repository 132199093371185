import { NormalizedSellerFilingReport } from '@/core/types';
import { useMemo, useState } from 'react';

export interface Filter<T> {
  id: T;
  label: string;
  icon?: JSX.Element;
}

export default function useCountryFilter(
  reports: NormalizedSellerFilingReport[]
) {
  const [activeCountryFilters, setActiveCountryFilters] = useState<string[]>(
    []
  );

  const onCountryFilterClick = (v?: string) => () => {
    setActiveCountryFilters((prev) => {
      if (v === undefined) return [];
      if (prev.includes(v)) {
        return prev.filter((s) => s !== v);
      }
      return [...prev, v];
    });
  };

  const countryFilters: Filter<string>[] = useMemo(() => {
    if (!reports) return [];

    return [
      ...reports
        .filter((r) => r.type === 'local')
        .map((v) => ({
          id: v.country.short_name,
          label: v.country.short_name,
          count: [...reports].filter(
            (r) => r.country.short_name === v.country.short_name
          ).length,
        }))
        .filter((f, i, self) => {
          return self.findIndex((c) => c.id === f.id) === i;
        }),
      ...(reports.some((r) => r.type === 'oss')
        ? [
            {
              id: 'OSS',
              label: 'OSS',
              count: reports.filter((r) => r.type === 'oss').length,
            },
          ]
        : []),
    ].sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
  }, [reports]);

  return {
    activeCountryFilters,
    countryFilters,
    onCountryFilterClick,
  };
}
