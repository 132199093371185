export enum AuthedRoutesEnum {
  Obligations = '/obligations',
  Reports = '/reports',
  Goods = '/goods',
  Company = '/company',
  CompanyContact = '/company/contact',
  DataFiles = '/data-files',
  VAT = '/vat',
  SetupOverview = '/setup-overview',
  CountryDetails = '/country-details/:id',
  Inventory = '/inventory',
  Services = '/services',
  Sales = '/sales',
  InvoiceBook = '/invoice-book',
  Documents = '/documents',
  Recommendations = '/recommendations',
  AmazonFees = '/amazon-fees',
  Filing = '/filing',
  PurchasePrices = '/purchase-prices',
  DatevOverview = '/datev-transactions/:year/:month',
  OSSFiles = '/oss-files',
  DATEVConfiguration = '/datev-configuration',
  Proforma = '/pro-forma',
  Datev = '/datev',
  OSSReportsInfo = '/oss-reports-info',
  OSSReport = '/report/:id',
  OSSInstruction = '/oss-instruction',
  ReclaimVATInstruction = '/reclaim-vat-instruction',
  ClientOSS = '/clients/:id/oss',
  ClientDATEV = '/clients/:id/datev',
  SetupCheck = '/setup-check',
  ProformaInvoices = '/proforma-invoices',
  NewProcessesInstructions = '/data-files/how-to-work',
  TinNumberInformation = '/vat/tin-number',
  JtlInstruction = '/jtl-instruction',
  Integrations = '/integrations',
}

export enum PublicRoutesEnum {
  RegisterCustomer = '/register-customer',
  RegisterTaxAdvisor = '/register-advisor',
  RegisterOSS = '/register-oss',
  Register = '/register',
  Page404 = '/404',
  TermsOfService = '/terms-of-service',
  DataProcessingAgreement = '/data-processing-agreement',
  ForgotPassword = '/forgot-password',
  CreatePassword = '/create-password',
}

export enum RolesEnum {
  SELLER = 'SELLER',
  AGGREGATOR = 'AGGREGATOR', // depracated
  TAX_ADVISOR = 'TAX_ADVISOR',
  SETUP_CHECK = 'SETUP_CHECK',
  OSS = 'OSS',
}

export enum ClientTaxDetailsStatusEnum {
  WAITING = 'waiting',
  NEW = 'new',
  CHECK = 'check',
  CHECK_DONE = 'check_done',
  DOCS_PREP = 'docs_prep',
  DOCS_READY = 'docs_ready',
  SENT_TO_CLIENT = 'sent_to_client',
  SENT_RECEIVED = 'received',
  TO_TAX_OFFICE = 'to_tax_office',
  IN_TAX_OFFICE = 'in_tax_office',
  TAX_ID_RECEIVED = 'tax_id_received',
  VAT_ID_RECEIVED = 'vat_id_received',
  REGISTERED = 'registered',
  STOPPED = 'stopped',
  PAUSED = 'paused',
  NOT_NEEDED = 'not_needed',
  DEREGISTERED = 'deregistered',
}

export enum SellerFilingReportStatusEnum {
  New = 'new',
  ToDo = 'todo',
  Blocked = 'blocked',
  InProgress = 'in_progress',
  Prepared = 'prepared',
  SentToAdvisor = 'sent_to_advisor',
  InstructionSent = 'instruction_sent',
  ConfirmationSent = 'confirmation_sent',
  Paid = 'paid',
  Submitted = 'submitted',
}

export enum TaxCollectionResponsibilityEnum {
  Seller,
  Marketplace,
}
export enum ImportTaxResponsibilityEnum {
  Seller,
  'End-customer',
}
export enum ManualUploadFileStatusEnum {
  CREATED,
  IMPORTING,
  READY,
  VALIDATED,
}

export enum FileStatusEnum {
  CREATED = 0,
  IMPORTED,
  FAILED,
}
export enum IdNumberStatusEnum {
  Requested = 2,
  Active = 1,
  Unavailable = 4,
  Deactivated = 8,
}
export enum EvaluatiionStatusEnum {
  Requested = 1,
  Generating,
  Done,
  Error,
}

export enum ToastType {
  'evaluation' = 'evaluation',
}

export enum ManualUploadFilesWorkStatusEnum {
  'New',
  'Preparation',
  'Finished',
  'On client',
  'Discarded',
  'Correction uploaded',
}

export enum JTLCredentialsStatusEnum {
  ConnectionNotEstablished = 'connection_not_established',
  ConnectionEstablished = 'connection_established',
  ConnectionWithoutFiling = 'connection_without_filing',
  ConnectionLost = 'connection_lost',
  WaitingForSync = 'waiting_for_sync',
}
