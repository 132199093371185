import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  capitalizeEach,
  downloadFile,
  formatDate,
  formatNumber,
} from '@/core/helpers';
import { normalizeDateFE } from '@/core/normalization';
import Modal from '../Modal/Modal';
import './PaymentInstructionModal.scss';
import Comment from '../Comment';
import SellerFilingFile from '../SellerFilingFile';
import { generatePaymentInstructionFileLink } from '@/core/api';
import { APIEventsEnum, useAppSelector, useSendEvent } from '../../hooks';
import Button from '../Button';
import {
  PaymentConfirmationDocument,
  NormalizedSellerFilingReport,
} from '@/core/types';
import {
  activeCompanySelector,
  userSelector,
} from '@/core/store/user/userSlice';
import useNotification from '../../hooks/useNotification';
import { useLocation } from 'react-router-dom';
import MiniLoader from '../MiniLoader/MiniLoader';
import FileUploaderBtn from '../FileUploader/FileUploaderBtn';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AllOWED_FILE_EXTENSIONS } from '../FileUploader/FileUploader.helpers';
import { getS3Link } from '@/core/s3';
import classNames from 'classnames';
import { useNPSModal } from '../NPSModal/useNPSModal';
import { RolesEnum } from '@/core/enums';
import InfoIcon from '@/icons/info-outline.svg?react';
import PlusIcon from '@/icons/plus-circle.svg?react';
import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  onClickConfirm?: (files: File[]) => void;
  report: NormalizedSellerFilingReport | undefined;
  isConfirming: boolean;
  isReportHasDisregardedInstruction?: boolean;
  showDescription?: boolean;
}

export default function PaymentInstructionModal({
  isModalOpened,
  closeModal,
  report,
  onClickConfirm,
  isConfirming,
  isReportHasDisregardedInstruction,
  showDescription = true,
}: Props) {
  const user = useAppSelector(userSelector);
  const { vs5151DisregardedInstruction } = useFlags();
  const [files, setFiles] = useState<File[]>([]);

  const activeCompany = useAppSelector(activeCompanySelector);
  const submissionBy = activeCompany?.ClientOSSDetails?.SubmissionBy;
  const { showNotification } = useNotification();

  const { pathname } = useLocation();

  const [isConfirmation, setIsConfirmation] = useState(true);

  const { sendEvent } = useSendEvent();
  const { t } = useTranslation();

  const { openModal } = useNPSModal();

  useEffect(() => {
    if (isModalOpened) {
      sendEvent(APIEventsEnum.view_payment_instruction, {
        block: `Payment instruction is shown on ${pathname}`,
      });
      setFiles([]);
      setIsConfirmation(false);
    }
    // eslint-disable-next-line
  }, [isModalOpened]);

  const instruction = report?.payment_instruction;

  if (!instruction || !report) return null;

  const onSelectFiles = (filesArr: File[] | null) => {
    const filesForUploading = filesArr?.filter((f) => {
      const parts = f.name.split('.');
      const ext = parts[parts.length - 1];
      return AllOWED_FILE_EXTENSIONS.includes((ext || '').toLowerCase());
    });

    if (!filesArr || !filesForUploading) {
      return;
    }

    if (filesArr.length > filesForUploading.length) {
      showNotification({
        text: 'You can upload multiple PDF files.',
        type: 'error',
      });
      return;
    }

    setFiles((prev) => {
      return [
        ...prev.filter(
          (f) => !filesForUploading.some((file) => file.name === f.name)
        ),
        ...filesForUploading,
      ];
    });
  };

  const period =
    report.period.type === 'month'
      ? formatDate(normalizeDateFE(report.period.from_date), 'MMMM yyyy')
      : report.period.type === 'year'
        ? formatDate(normalizeDateFE(report.period.from_date), 'yyyy')
        : formatDate(normalizeDateFE(report.period.from_date), 'QQQ yyyy');

  const date = normalizeDateFE(report.deadline_date);
  const paid = instruction?.confirmed;

  const isNegativeAmount = instruction.amount.amount < 0;
  const isZeroAmount = instruction.amount.amount === 0;

  const isDE = report.country.short_name === 'DE';

  const amount = `${formatNumber(
    instruction.amount.amount / 100,
    {
      currency: instruction.amount.currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    undefined,
    true
  )}`;

  const onMarkAsPaidClick = () => {
    setIsConfirmation(true);
  };

  const closeBtn = (
    <Button
      styling='outline'
      type='button'
      onClick={closeModal}
      data-testid='payment-instruction-modal-close-button'
      disabled={isConfirming}
    >
      {t('Close')}
    </Button>
  );

  const periodRow = (
    <div className='mb-4 flex gap-x-6'>
      <div className='w-1/3'>{t('Period')}</div>
      <div className='w-2/3' data-testid='payment-instruction-modal-period'>
        <CopyToClipboard text={period} />
      </div>
    </div>
  );

  const onDowloadFile =
    (setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => () => {
      const apiEvent = APIEventsEnum.download_report_confirmation;
      sendEvent(apiEvent, {
        block: 'Report confirmation downloading',
      });
      setIsLoading(true);
      generatePaymentInstructionFileLink(
        instruction.id,
        activeCompany?.company_id
      )
        .then((res) => {
          setIsLoading(false);
          downloadFile(res, instruction.file!);
          openModal(apiEvent);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };

  const onDowloadPaymentConfirmationDocument =
    (f: PaymentConfirmationDocument) =>
    (setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) =>
    async () => {
      const parts = `${f.bucket}/${f.path}`.split('/');

      try {
        setIsLoading(true);

        const url = await getS3Link(parts[0], parts.slice(1).join('/'));
        downloadFile(url, parts[parts.length - 1]);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    };

  const renderInstruction = () => {
    if (instruction.is_sepa) {
      return (
        <>
          {periodRow}
          <div className='mb-4'>
            {t(
              'As you have set up the SEPA Direct Debit scheme with the French tax office, the payment due should be automatically debited from your bank account by the tax office.'
            )}
          </div>
          {instruction.comment && (
            <Comment comment={instruction.comment} className='mb-4' />
          )}
          {closeBtn}
        </>
      );
    }
    if (
      (instruction.is_null_report || isZeroAmount || isNegativeAmount) &&
      isDE
    ) {
      return (
        <>
          {periodRow}
          <div className='mb-4 flex gap-x-6'>
            <div className='w-1/3'>{t('Amount')}</div>
            <div
              className='w-2/3'
              data-testid='payment-instruction-modal-amount'
            >
              <CopyToClipboard text={amount} />
            </div>
          </div>
          <div className='mb-4'>
            {t('There is no VAT due from the VAT statement *date*.').replace(
              '*date*',
              formatDate(new Date(), 'MM/yyyy')
            )}
          </div>
          {instruction.comment && (
            <Comment comment={instruction.comment} className='mb-4' />
          )}
          {closeBtn}
        </>
      );
    }
    if (instruction.is_null_report || isZeroAmount || isNegativeAmount) {
      return (
        <>
          {periodRow}
          <div className='mb-4 flex gap-x-6'>
            <div className='w-1/3'>{t('Amount')}</div>
            <div
              className='w-2/3'
              data-testid='payment-instruction-modal-amount'
            >
              <CopyToClipboard text={amount} />
            </div>
          </div>
          <div className='mb-4'>{t('No payment needed.')}</div>
          {instruction.comment && (
            <Comment comment={instruction.comment} className='mb-4' />
          )}
          {closeBtn}
        </>
      );
    }
    return (
      <>
        <div className='mb-4 flex gap-x-6'>
          <div className='w-1/3'>{t('Country')}</div>
          <div
            className='w-2/3'
            data-testid='payment-instruction-modal-country'
          >
            <CopyToClipboard text={t(report.country.short_name)} />
          </div>
        </div>
        {periodRow}
        {!isNegativeAmount && (
          <div className='mb-4 flex gap-x-6'>
            <div className='w-1/3'>{capitalizeEach(t('Due date'))}</div>
            <div
              className='w-2/3'
              data-testid='payment-instruction-modal-due-date'
            >
              <CopyToClipboard
                text={formatDate(
                  normalizeDateFE(instruction.due_date),
                  'dd.MM.yyyy'
                )}
              />
            </div>
          </div>
        )}
        {!instruction.is_sepa &&
          !instruction.is_null_report &&
          !isNegativeAmount && (
            <>
              <div className='mb-4 flex gap-x-6'>
                <div className='w-1/3'>{t('Payee')}</div>
                <div
                  className='w-2/3 whitespace-pre-line'
                  data-testid='payment-instruction-modal-payee'
                >
                  <CopyToClipboard text={instruction.payee} />
                </div>
              </div>
              <div className='mb-4 flex gap-x-6'>
                <div className='w-1/3'>{t('Credit institution')}</div>
                <div
                  className='w-2/3 whitespace-pre-line'
                  data-testid='payment-instruction-modal-credit-institution'
                >
                  <CopyToClipboard text={instruction.credit_institution} />
                </div>
              </div>
              <div className='mb-4 flex gap-x-6'>
                <div className='w-1/3'>{t('IBAN')}</div>
                <div
                  className='w-2/3'
                  data-testid='payment-instruction-modal-iban'
                >
                  <CopyToClipboard text={instruction.iban} />
                </div>
              </div>
              <div className='mb-4 flex gap-x-6'>
                <div className='w-1/3'>{t('BIC')}</div>
                <div
                  className='w-2/3'
                  data-testid='payment-instruction-modal-bic'
                >
                  <CopyToClipboard text={instruction.bic} />
                </div>
              </div>
            </>
          )}
        {!instruction.is_sepa &&
          !instruction.is_null_report &&
          !isNegativeAmount && (
            <div className='mb-4 flex gap-x-6'>
              <div className='w-1/3'>{t('Purpose')}</div>
              <div
                className='w-2/3'
                data-testid='payment-instruction-modal-purpose'
              >
                <CopyToClipboard text={instruction.purpose} />
              </div>
            </div>
          )}
        <div className='mb-4 flex gap-x-6'>
          <div className='w-1/3'>{t('Amount')}</div>
          <div className='w-2/3' data-testid='payment-instruction-modal-amount'>
            <CopyToClipboard text={amount} />
          </div>
        </div>
        {instruction.comment && (
          <Comment comment={instruction.comment} className='mb-4' />
        )}
        <div
          className={classNames('space-y-3', {
            'mb-4':
              instruction.file ||
              !!instruction.payment_confirmation?.documents.length,
          })}
        >
          {instruction.file && (
            <>
              <div className='font-bold'>{t('Report draft')}</div>
              <SellerFilingFile
                key={instruction.file}
                filename={instruction.file}
                onDownload={onDowloadFile}
              />
            </>
          )}
          <div className='flex items-center  justify-between pt-2'>
            {(!!files.length ||
              !!instruction.payment_confirmation?.documents.length) && (
              <div className='font-bold'>{t('Confirmation')}</div>
            )}
            {!!instruction.payment_confirmation?.documents.length && (
              <FileUploaderBtn
                isUploadingFiles={isConfirming}
                multiple
                onSelect={onSelectFiles}
              >
                <div className='flex cursor-pointer items-center justify-center gap-x-1 font-semibold'>
                  <PlusIcon className=' [&>path]:fill-dark' />
                  <span>{t('Upload')}</span>
                </div>
              </FileUploaderBtn>
            )}
          </div>
          {!!instruction.payment_confirmation?.documents.length &&
            instruction.payment_confirmation.documents.map((d) => (
              <SellerFilingFile
                key={d.id}
                filename={d.path}
                onDownload={onDowloadPaymentConfirmationDocument(d)}
                createdAt={d.created_at}
              />
            ))}
          {instruction.confirmed && (
            <>
              {files.map((f) => (
                <SellerFilingFile
                  key={f.name}
                  filename={f.name}
                  onDelete={() => {
                    setFiles((prev) =>
                      prev.filter((file) => file.name !== f.name)
                    );
                  }}
                />
              ))}
              {user?.Role === RolesEnum.SELLER &&
                !files.length &&
                !instruction.payment_confirmation?.documents.length && (
                  <FileUploaderBtn
                    isUploadingFiles={isConfirming}
                    multiple
                    onSelect={onSelectFiles}
                  />
                )}
            </>
          )}
        </div>
        <div className='mt-2 flex flex-wrap gap-x-2'>
          {paid ? (
            <>
              {onClickConfirm && instruction.confirmed && !!files.length && (
                <div className='flex-shrink-0'>
                  <Button
                    className='flex items-center'
                    disabled={isConfirming}
                    data-testid='payment-instruction-modal-submit-btn'
                    onClick={() => {
                      sendEvent(APIEventsEnum.payment_instruction_marked_paid, {
                        block: `Payment instruction is marked paid from ${pathname}`,
                      });
                      onClickConfirm(files);
                    }}
                  >
                    <span>{t('Upload')}</span>
                    {isConfirming && (
                      <MiniLoader className='ml-2' color='gray' size='sm' />
                    )}
                  </Button>
                </div>
              )}
              <div className='flex-shrink-0'>{closeBtn}</div>
            </>
          ) : (
            <>
              {onClickConfirm && (
                <div className='flex-shrink-0'>
                  <Button
                    data-testid='payment-instruction-modal-submit-btn'
                    onClick={onMarkAsPaidClick}
                  >
                    {t(isProofOfPaymentNeeded ? 'Proceed' : 'Mark as paid')}
                  </Button>
                </div>
              )}
              <div className='flex-shrink-0'>{closeBtn}</div>
            </>
          )}
        </div>
      </>
    );
  };

  const isProofOfPaymentNeeded =
    report?.type === 'local' &&
    (report?.fiscal_rep_required ||
      report?.country?.short_name === 'ES' ||
      report?.country?.short_name === 'IT');

  return (
    <Modal
      isOpened={isModalOpened}
      close={closeModal}
      title={t('Payment instruction')}
      isLoading={isConfirming}
      name='PaymentInstruction'
    >
      {isConfirmation ? (
        <div
          className='mx-4 flex grow flex-col px-4 pb-6 pt-4'
          style={{
            minHeight: 450,
          }}
        >
          <div className='grow'>
            <div className='mb-2 text-h4 font-extrabold'>
              {t('Confirm payment of *amount*').replace('*amount*', amount)}
            </div>
            {isProofOfPaymentNeeded && (
              <div className='flex flex-col pb-3 font-semibold text-orange-500'>
                <span>
                  {t(
                    'Proof of payment is required by authorities in this country'
                  )}
                </span>
                <span>
                  {t('Please upload the file to close your open task')}
                </span>
              </div>
            )}
            <div className='mb-4 space-y-2'>
              {files.map((f) => (
                <SellerFilingFile
                  key={f.name}
                  filename={f.name}
                  onDelete={() => {
                    setFiles((prev) =>
                      prev.filter((file) => file.name !== f.name)
                    );
                  }}
                />
              ))}
              {user?.Role === RolesEnum.SELLER && !files.length && (
                <FileUploaderBtn
                  isUploadingFiles={isConfirming}
                  multiple
                  onSelect={onSelectFiles}
                  label='Attach PDF, PNG or JPG'
                />
              )}
            </div>
          </div>
          <div className='flex gap-x-2'>
            <Button
              data-testid='payment-instruction-modal-confirm-btn'
              onClick={
                onClickConfirm
                  ? () => {
                      sendEvent(APIEventsEnum.payment_instruction_marked_paid, {
                        block: `Payment instruction is marked paid from ${pathname}`,
                      });
                      onClickConfirm(files);
                    }
                  : undefined
              }
              disabled={
                isConfirming || (isProofOfPaymentNeeded && !files.length)
              }
              className='flex items-center'
            >
              <span>{t('Confirm')}</span>
              {isConfirming && (
                <MiniLoader className='ml-2' color='gray' size='sm' />
              )}
            </Button>
            {closeBtn}
          </div>
        </div>
      ) : (
        <div className='mx-4 px-4 pb-6 pt-4'>
          <div className='flex items-center justify-between'>
            <div className='text-h4 font-extrabold'>
              {formatDate(date, 'MMMM yyyy')}
            </div>
            {paid &&
              (!isProofOfPaymentNeeded ||
                (isProofOfPaymentNeeded &&
                  !!instruction?.payment_confirmation?.documents?.length)) &&
              !instruction.is_sepa &&
              !instruction.is_null_report && (
                <div className='Button -mr-2 !rounded-full !py-1 !text-sm'>
                  {t('Paid')}
                </div>
              )}
          </div>
          {!instruction.confirmed &&
            !isNegativeAmount &&
            !isZeroAmount &&
            submissionBy !== RolesEnum.TAX_ADVISOR &&
            !instruction.is_sepa &&
            !instruction.cancelled && (
              <div className='font-semibold text-orange-500'>
                {t(
                  "Please confirm your payment here after it's done to close the open task."
                )}
              </div>
            )}
          {vs5151DisregardedInstruction && isReportHasDisregardedInstruction ? (
            report?.payment_instruction?.cancelled &&
            report?.payment_instruction?.cancelled_at ? (
              <div className='-mx-2 my-2 flex gap-x-1 rounded-lg bg-gray-400 px-2 py-1 '>
                <div className='py-1'>
                  <span>
                    {t(
                      'The payment instruction has been replaced by countX on *date*'
                    ).replace(
                      '*date*',
                      formatDate(
                        new Date(report?.payment_instruction?.cancelled_at),
                        'dd.MM.yyyy'
                      )
                    )}
                  </span>{' '}
                  <span>
                    {t(
                      'If you have already made a payment, please reach out immediately to vat@countx.com'
                    )}
                  </span>
                </div>
                <InfoIcon className='h-4 w-4 shrink-0' />
              </div>
            ) : (
              showDescription && (
                <div className='-mx-2 my-2 flex gap-x-1 rounded-lg bg-gray-400 px-2 py-1 '>
                  <div className='py-1'>
                    <span>
                      {t(
                        'One of the payment instruction has been replaced by countX. You can find details in the Filing section.'
                      )}
                    </span>{' '}
                    <span>
                      {t(
                        'If you have already made a payment, please reach out immediately to vat@countx.com'
                      )}
                    </span>
                  </div>
                  <InfoIcon className='h-4 w-4 shrink-0' />
                </div>
              )
            )
          ) : null}
          <div className='mt-2'>{renderInstruction()}</div>
        </div>
      )}
    </Modal>
  );
}
