import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import Header from '@/components/Header/Header';
import { Category, Sidebar } from '@/components/Sidebar/Sidebar';
import { useAppSelector } from '../../hooks';
import FileIcon from '@/icons/filing-icon.svg?react';
import VATIcon from '@/icons/vat.svg?react';
import DatevIcon from '@/icons/datev.svg?react';
import InventoryIcon from '@/icons/inventory.svg?react';
import GearIcon from '@/icons/gear.svg?react';
import OSSFilesIcon from '@/icons/file.svg?react';
import ProformaIcon from '@/icons/proforma-invoices.svg?react';
import { AuthedRoutesEnum, ManualUploadFileStatusEnum } from '@/core/enums';
import { APIEventsEnum, useSendEvent } from '../../hooks';
import { NavLink } from 'react-router-dom';
import GridIcon from '@/icons/grid.svg?react';
import AggregatorDropdown from '@/components/AggregatorDropdown';
import InvoiceBookIcon from '@/icons/invoice-book.svg?react';
import TurnoverDataIcon from '@/icons/turnover-data.svg?react';
import IntegrationsIcon from '@/icons/integrations-icon.svg?react';
import {
  activeCompanySelector,
  companiesSelector,
  isOSSSelfServiceSelector,
} from '@/core/store/user/userSlice';
import PaymentOverdueBanner from '@/components/PaymentOverdueModal/PaymentOverdueBanner';
import { CurrentReporterEnum, PeriodTypeEnum } from '@/core/types';
import { subMonths, subYears } from 'date-fns';
import { useThreshold } from '../../context/useThreshold';
import TopProgressLoader from '@/components/TopProgressLoader';
import { useMU } from '@/context/useMU';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function SellerLayout({ children }: PropsWithChildren<{}>) {
  const { t, i18n } = useTranslation();
  const { isUploadingFiles, uploadedFiles } = useMU();
  const { vs5973CpIntegrationsPage } = useFlags();

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isCountryDetailsPage = location.pathname.startsWith(
    AuthedRoutesEnum.CountryDetails.replace('/:id', '')
  );
  const isDatevOverviewPage = location.pathname.startsWith(
    AuthedRoutesEnum.DatevOverview.replace('/:year', '').replace('/:month', '')
  );
  const companies = useAppSelector(companiesSelector);
  const activeCompany = useAppSelector(activeCompanySelector);

  if (companies.length > 1 && activeCompany?.company_id) {
    searchParams.set('company_id', activeCompany.company_id || '');
  }

  const { threshold } = useThreshold();
  const params = new URLSearchParams(location.search);
  const unsubscribe = params.get('unsubscribe');

  const { sendEvent } = useSendEvent();

  const isOSSSelfService = useAppSelector(isOSSSelfServiceSelector);

  const isProformaExportNeeded = !!activeCompany?.ProformaExportNeeded;

  const categories: Category[] = [
    {
      name: 'Data',
      links: [
        {
          label: 'Turnover data',
          icon: <TurnoverDataIcon />,
          to: AuthedRoutesEnum.DataFiles,
        },
        {
          label: 'Purchase prices',
          icon: <InventoryIcon />,
          to: AuthedRoutesEnum.PurchasePrices,
        },
        ...(isOSSSelfService
          ? [
              {
                label: 'OSS files',
                icon: <OSSFilesIcon />,
                to: AuthedRoutesEnum.OSSFiles,
              },
            ]
          : []),

        {
          label: 'Invoice book',
          icon: <InvoiceBookIcon />,
          to: (() => {
            const qp = new URLSearchParams(location.search);
            const date = new Date();
            qp.set(
              'year',
              date.getMonth() > 0
                ? String(date.getFullYear())
                : String(subYears(date, 1).getFullYear())
            );
            qp.set('type', PeriodTypeEnum.month);
            qp.set(
              'value',
              date.getMonth() === 0
                ? '11'
                : String(subMonths(date, 1).getMonth())
            );
            qp.set('company_id', String(activeCompany?.company_id));
            return {
              pathname: AuthedRoutesEnum.InvoiceBook,
              search: qp.toString(),
            };
          })(),
        },
        ...(!!activeCompany?.DisplayDatevSince
          ? [
              {
                label: 'DATEV',
                icon: <DatevIcon />,
                to: AuthedRoutesEnum.Datev,
              },
            ]
          : []),
        ...(isProformaExportNeeded
          ? [
              {
                label: 'Proforma',
                icon: <ProformaIcon />,
                to: AuthedRoutesEnum.ProformaInvoices,
              },
            ]
          : []),
      ],
    },
    {
      name: 'Filing',
      links: [
        {
          label: 'Filings',
          icon: <FileIcon />,
          to: AuthedRoutesEnum.Filing,
        },
      ],
    },
    {
      name: 'Setup',
      links: [
        ...(threshold > 0
          ? [
              {
                label: 'Overview',
                icon: <GearIcon />,
                to: AuthedRoutesEnum.SetupOverview,
              },
            ]
          : []),
        {
          label: 'Tax Details',
          icon: <VATIcon />,
          to: AuthedRoutesEnum.VAT,
        },
        ...(vs5973CpIntegrationsPage
          ? [
              {
                label: 'Integrations',
                icon: <IntegrationsIcon />,
                to: AuthedRoutesEnum.Integrations,
              },
            ]
          : []),
      ],
    },
  ];

  const isSeparateInvoiceBookPage =
    location.pathname === AuthedRoutesEnum.InvoiceBook &&
    searchParams.get('flow') === CurrentReporterEnum.ossSelfService;

  const isDocumentsPage = location.pathname === AuthedRoutesEnum.Documents;
  const isNewProcessInstructionsPage =
    location.pathname === AuthedRoutesEnum.NewProcessesInstructions;

  const isSidebarShown =
    !isCountryDetailsPage &&
    !isDatevOverviewPage &&
    !unsubscribe &&
    !isSeparateInvoiceBookPage &&
    !isDocumentsPage &&
    !isNewProcessInstructionsPage;

  const isProcessingFiles =
    isUploadingFiles ||
    (activeCompany?.IsUseNewProcess
      ? uploadedFiles.filter((f) => !!f.update_progress).length > 0
      : uploadedFiles.some(
          (f) => f.status === ManualUploadFileStatusEnum.CREATED
        ));

  return (
    <div className='flex' data-testid='seller-layout'>
      {isProcessingFiles && (
        <TopProgressLoader
          isLoading={isProcessingFiles}
          backgroundColor='red'
          className='!fixed z-[1040] !bg-transparent'
        />
      )}
      {isSidebarShown && (
        <Sidebar
          categories={categories}
          header={
            <>
              <div className='pt-2'>
                {companies.length > 1 ? (
                  <AggregatorDropdown />
                ) : (
                  <div className='mb-2 truncate font-bold'>
                    {activeCompany?.name}
                  </div>
                )}
              </div>
              <NavLink
                to={{ pathname: '/', search: searchParams.toString() }}
                className='Sidebar__link -mx-2 mb-2 flex items-center py-1 pl-2 no-underline'
                data-testid='sidebar-link-Global overview'
                onClick={() => {
                  sendEvent(APIEventsEnum.click_sidebar_link, {
                    to: 'Global overview',
                  });
                }}
              >
                <GridIcon />
                <span className='ml-1'>{t('Global overview')}</span>
              </NavLink>
            </>
          }
        />
      )}
      <div className='flex-grow'>
        <div className='flex flex-col'>
          <div className='flex min-h-screen flex-col'>
            <Header
              backBtn={
                isCountryDetailsPage ||
                isSeparateInvoiceBookPage ||
                isDocumentsPage
              }
              backBtnPath={
                isSeparateInvoiceBookPage
                  ? location.state && location.state.prevRoute
                    ? location.state.prevRoute
                    : AuthedRoutesEnum.OSSFiles
                  : isDocumentsPage
                    ? AuthedRoutesEnum.DataFiles
                    : undefined
              }
              backBtnLabel='Back'
              logo={isDatevOverviewPage ? undefined : !!unsubscribe}
              label={
                isCountryDetailsPage
                  ? t('Country details')
                  : isDatevOverviewPage
                    ? activeCompany?.name
                    : isSeparateInvoiceBookPage
                      ? t('Invoice book')
                      : undefined
              }
              isShownBtn={
                isDatevOverviewPage || isSeparateInvoiceBookPage ? false : true
              }
              btn={
                <a
                  className='Button--outline Button--sm mr-2'
                  href={
                    i18n.language === 'en'
                      ? 'https://vat.countx.com/en/faq'
                      : 'https://vat.countx.com/faq'
                  }
                  target='_blank'
                  rel='noopener noreferrer'
                  data-testid='help-center-link'
                >
                  {t('Help center')}
                </a>
              }
            />
            {activeCompany?.PaymentOverdue && <PaymentOverdueBanner />}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
