import './Input.scss';
import cn from 'classnames';
import Eye from '@/icons/eye.svg?react';
import EyeCrossed from '@/icons/eye-crossed.svg?react';
import { HTMLAttributes, MutableRefObject, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';

interface Props {
  value: string;
  onChange: React.ChangeEventHandler;
  onBlur?: React.ChangeEventHandler;
  placeholder?: string;
  type?: 'text' | 'email' | 'password' | 'number' | 'url';
  name?: string;
  id?: string;
  error?: string;
  touched?: boolean;
  labelClassName?: string;
  wrapperClassName?: string;
  label?: string;
  disabled?: boolean;
  translatePlaceholder?: boolean;
}

const Input = forwardRef(
  (
    {
      value,
      onChange,
      placeholder,
      name,
      id,
      type = 'text',
      error,
      onBlur,
      touched,
      wrapperClassName = '',
      className = '',
      labelClassName = '',
      label,
      disabled = false,
      translatePlaceholder = true,
      ...rest
    }: Props & HTMLAttributes<HTMLInputElement>,
    ref
  ) => {
    const { t } = useTranslation();
    const isPassword = type === 'password';
    const [currentType, setCurrentType] = useState(type);

    const onEyeClick = () => {
      setCurrentType(currentType === 'password' ? 'text' : 'password');
    };

    return (
      <div className={`Input ${wrapperClassName}`}>
        {label && (
          <div
            className={`Input__label mb-2 ${labelClassName}`}
            data-testid={`${name}-label`}
          >
            {t(label)}
          </div>
        )}
        <input
          {...rest}
          ref={ref as MutableRefObject<HTMLInputElement>}
          id={id}
          name={name}
          data-testid={name}
          type={currentType}
          placeholder={
            placeholder
              ? translatePlaceholder
                ? t(placeholder)
                : placeholder
              : undefined
          }
          onChange={onChange}
          value={value}
          disabled={disabled}
          onBlur={onBlur}
          className={cn('Input__input', className, {
            'Input__input--invalid': touched && !!error,
            'Input__input--withEye': isPassword,
          })}
          autoComplete='on'
        />
        {isPassword && (
          <Button
            styling='naked'
            type='button'
            className='Input__eye absolute'
            onClick={onEyeClick}
            tabIndex={-1}
            data-testid={name + '-eye'}
          >
            {currentType === 'password' ? <Eye /> : <EyeCrossed />}
          </Button>
        )}
        {touched && error && (
          <div className='Input__error' data-testid={`${name}-error`}>
            {t(error)}
          </div>
        )}
      </div>
    );
  }
);

export default Input;
