import { formatNumber, openPdfViewer } from '@/core/helpers';
import { ProformaInvoice } from '@/core/types';
import { useState } from 'react';
import { Spinner } from '@/components/Spinner';
import FileIcon from '@/components/FileIcon';
import InventoryIcon from '@/icons/inventory.svg?react';

import { useTranslation } from 'react-i18next';
import { postDownloadProformaInvoice } from '@/core/api';
import { APIEventsEnum, useAppSelector, useSendEvent } from '@/hooks';
import { activeCompanySelector } from '@/core/store/user/userSlice';
import { Link } from 'react-router-dom';
import { AuthedRoutesEnum } from '@/core/enums';
import { useNPSModal } from '../NPSModal/useNPSModal';
import Tooltip from '../Tooltip/Tooltip';

interface Props {
  invoice: ProformaInvoice;
}

export const ProformaInvoiceTileItem = ({ invoice }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const activeCompany = useAppSelector(activeCompanySelector);
  const { openModal } = useNPSModal();

  const { sendEvent } = useSendEvent();

  const downloadPdf = () => {
    setIsLoading(true);
    const apiEvent = APIEventsEnum.download_proforma_invoice;
    sendEvent(apiEvent, {
      block: 'Proforma invoice tile',
    });
    postDownloadProformaInvoice(invoice.id, activeCompany?.company_id)
      .then((res) => {
        openModal(apiEvent);
        openPdfViewer(res, invoice.invoice_number + '.pdf');
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (invoice.is_draft) {
    return (
      <div className='flex flex-wrap justify-between gap-x-3 px-4 py-2'>
        <div className='flex-grow'>
          <div className='flex justify-between gap-x-2'>
            <div>
              <span className='font-semibold'>{invoice.invoice_number}</span>
              <div className=' text-gray-700'>{t('Seller account')}</div>
              <div>{invoice.seller_account_id}</div>
            </div>
            <div className='flex flex-col justify-between'>
              <div className='flex justify-end'>
                <Link
                  className='Button--outline flex items-center gap-x-2 !pr-3'
                  to={AuthedRoutesEnum.PurchasePrices}
                >
                  <span>{t('Upload purchase prices')}</span>
                  <InventoryIcon className='' />
                </Link>
              </div>
              <div className='text-end'>
                {t(
                  'The file is not generated because purchase prices are missing.'
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-wrap justify-between gap-x-3 px-4 py-2'>
      <div className='flex-grow'>
        <div className='font-semibold'>{invoice.invoice_number}</div>
        <div className='grid grid-cols-[1.2fr,repeat(3,1fr)] gap-x-3'>
          <div className='text-gray-700'>{t('Seller account')}</div>
          <div className='text-gray-700'>{t('VAT amount')}</div>
          <div className='text-gray-700'>{t('Netto')}</div>
          <div className='text-gray-700'>{t('Brutto')}</div>
          <div>{invoice.seller_account_id}</div>
          <div>
            {invoice.total_vat_amount !== undefined &&
              formatNumber(
                invoice.total_vat_amount / 100,
                {
                  currency: invoice.currency,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                },
                'en',
                true
              )}
          </div>
          <div>
            {invoice.total_net_amount !== undefined &&
              formatNumber(
                invoice.total_net_amount / 100,
                {
                  currency: invoice.currency,
                },
                'en',
                true
              )}
          </div>
          <div>
            {invoice.total_brutto_amount !== undefined &&
              formatNumber(
                invoice.total_brutto_amount / 100,
                {
                  currency: invoice.currency,
                },
                'en',
                true
              )}
          </div>
        </div>
      </div>
      <Tooltip content={t('Download file')} wrapperClassName='flex flex-col'>
        <button
          className='flex w-16 shrink-0 flex-grow cursor-pointer items-center justify-center rounded-lg border border-gray-500 bg-gray-70 p-2 transition-colors hover:border-green'
          onClick={downloadPdf}
          disabled={isLoading}
        >
          {isLoading ? <Spinner /> : <FileIcon ext='pdf' />}
        </button>
      </Tooltip>
    </div>
  );
};
