import classNames from 'classnames';
import { Link, To } from 'react-router-dom';

interface Props {
  color?: 'red' | 'gray' | 'green' | 'yellow' | 'orange';
  label: string | JSX.Element;
  to?: To;
  className?: string;
  testId?: string;
}

export const Badge = ({ color, label, to, className = '', testId }: Props) => {
  if (to) {
    return (
      <Link
        to={to}
        className={classNames(
          `inline-block rounded-full px-3 py-1 font-semibold transition-opacity hover:opacity-70 ${className}`,
          {
            'text-primary bg-[#63B79620]': color === 'green',
            'bg-[#FFEFE0] text-red-500': color === 'red',
            'bg-gray-700': color === 'gray',
            'bg-yellow-400': color === 'yellow',
            'bg-orange-200': color === 'orange',
          }
        )}
        data-testid={testId}
      >
        {label}
      </Link>
    );
  }
  return (
    <div
      className={classNames(
        `text-semibold inline-block rounded-full px-3 py-1 font-semibold ${className}`,
        {
          'bg-[#63B79620] text-green': color === 'green',
          'bg-[#FFEFE0] text-red-500': color === 'red',
          'bg-gray-300 text-gray-700': color === 'gray',
          'bg-yellow-400': color === 'yellow',
          'bg-orange-200': color === 'orange',
        }
      )}
      data-testid={testId}
    >
      {label}
    </div>
  );
};
