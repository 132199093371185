import { createContext, useState, ReactNode } from 'react';
import { lockScroll } from '@/core/helpers';

const DEFAULT_LINK =
  'https://vat.countx.com/meetings/cornelius/beratungsgesprach-mit-team-sales?embed=true';

export const BookMeetingModalContext = createContext<{
  isModalOpened: boolean;
  closeModal: () => void;
  openModal: (v?: string) => void;
  link: string;
}>({
  isModalOpened: false,
  closeModal: () => {},
  openModal: () => {},
  link: '',
});

export function HubspotBookMeetingModalWrapper({
  children,
}: {
  children: ReactNode;
}) {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [link, setLink] = useState(DEFAULT_LINK);

  const closeModal = () => {
    setIsModalOpened(false);
    lockScroll(false);
  };

  const openModal = (v?: string) => {
    setLink(v || DEFAULT_LINK);
    setIsModalOpened(true);
    lockScroll(true);
  };

  return (
    <BookMeetingModalContext.Provider
      value={{
        isModalOpened,
        closeModal,
        openModal,
        link,
      }}
    >
      {children}
    </BookMeetingModalContext.Provider>
  );
}
