import { useTranslation } from 'react-i18next';
import BackIcon from '@/icons/back.svg?react';
import { Link, useLocation } from 'react-router-dom';
import CountryLabel from '../CountryLabel/CountryLabel';
import classNames from 'classnames';
import tinNumbersInfo from '@/core/data/tin_numbers.json';
import homeland_countries from '@/core/data/homeland_countries.json';

interface Props {
  className?: string;
}

export default function TinNumberList({ className = '' }: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <div
      className={`flex w-full flex-grow flex-col rounded-lg px-4 py-4 lg:px-6 lg:py-6 xl:px-14 2xl:py-8 ${className}`}
    >
      <div className='mx-auto flex w-full max-w-[792px] flex-grow flex-col overflow-hidden rounded-lg bg-white px-4 pt-4'>
        <Link
          className='group mb-2 flex cursor-pointer items-center text-sm font-semibold transition hover:text-green'
          to={{
            pathname: location.state?.prevRoute || '/',
          }}
        >
          <BackIcon className='mr-1 [&>circle]:transition-[fill] group-hover:[&>circle]:fill-green' />
          <div>{t('Back')}</div>
        </Link>
        <h3 className='Text pb-2 font-extrabold'>
          {t('General information about TIN')}
        </h3>
        <div>
          <div className='grid grid-cols-[minmax(100px,0.85fr),minmax(200px,2fr)]'>
            <div className='text-gray-700'>{t('Country')}</div>
            <div className='text-gray-700'>{t('TIN Information')}</div>
          </div>
          {tinNumbersInfo.map((tinNumber, i) => (
            <div
              className={classNames(
                'mx-0 grid grid-cols-[minmax(100px,0.85fr),minmax(200px,2fr)] py-2',
                {
                  'border-t border-gray-500': i > 0,
                }
              )}
              key={i}
              data-testid={`registered-row-${i + 1}`}
            >
              <div>
                {homeland_countries.includes(tinNumber.short_name) ? (
                  <CountryLabel country={tinNumber.short_name} />
                ) : (
                  t(tinNumber.short_name)
                )}
              </div>
              <div>
                <div>{t(tinNumber.text)}</div>
                {tinNumber.additional_text && (
                  <div>
                    <span className='font-semibold'>{t('Additional')}</span>:{' '}
                    {t(tinNumber.additional_text)}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
