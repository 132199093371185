import { useTranslation } from 'react-i18next';
import {
  PeriodTypeEnum,
  Source,
  UploadedFile,
  UserCompany,
} from '@/core/types';
import { formatDate, isMUFile } from '@/core/helpers';
import { subMonths } from 'date-fns';
import { Loader } from '../Loader/Loader';
import { Link, useLocation } from 'react-router-dom';
import { AuthedRoutesEnum } from '@/core/enums';
import InvoiceDataSourceItem from './InvoiceDataSourceItem';
import { useMU } from '../../context/useMU';
import { StopMUBlock } from '../SellerDataUnit/StopMUBlock';

interface MailingStopped {
  isLoading: boolean;
  data: boolean | undefined;
}

interface Props {
  activeCompany: UserCompany | undefined;
  hasProcessedFiles: boolean;
  manualUploadMailingStopped: MailingStopped;
  onClickDeleteFile: (id: number) => void;
}

export default function InvoicesUploader({
  activeCompany,
  hasProcessedFiles,
  manualUploadMailingStopped,
  onClickDeleteFile,
}: Props) {
  const { uploadedFiles } = useMU();
  const { t } = useTranslation();
  const location = useLocation();
  const currentDate = new Date();

  const sourceMap = uploadedFiles.reduce(
    (acc: Record<string, UploadedFile[]>, item) => {
      acc[item.source] = acc[item.source]
        ? [...acc[item.source], item]
        : [item];
      return acc;
    },
    {
      manual: [],
    }
  );

  const hasUploadedMUFile = uploadedFiles.filter((f) => isMUFile(f)).length > 0;

  const deadlineUploadDate = new Date();
  deadlineUploadDate.setDate(10);

  const importFilesDate = new Date();
  importFilesDate.setDate(5);

  const invoiceBookLink = (
    <Link
      to={(() => {
        const qp = new URLSearchParams(location.search);
        const date = new Date();
        qp.set('year', String(date.getFullYear()));
        qp.set('type', PeriodTypeEnum.month);
        qp.set('value', String(subMonths(date, 1).getMonth()));
        return {
          pathname: AuthedRoutesEnum.InvoiceBook,
          search: qp.toString(),
        };
      })()}
      className='font-semibold text-blue-500 hover:text-blue-600'
    >
      {t('Invoice book')}.
    </Link>
  );

  const isErrorListFiles = uploadedFiles.some(
    (file) => file.errors_message && file.documents_with_error_number > 0
  );

  return (
    <div className='mb-4 rounded-lg bg-white pt-4'>
      {manualUploadMailingStopped.isLoading && <Loader />}

      <div className=' Text  relative mb-2 px-4 font-extrabold'>
        {t('Received in *month*').replace(
          '*month*',
          formatDate(deadlineUploadDate, 'MMMM yyyy')
        )}
        {isErrorListFiles && (
          <div className='absolute right-[16px] top-0 rounded-md bg-orange-100 px-2.5 py-1 text-sm font-semibold text-red-500'>
            {t('wrong invoices in *month*').replace(
              '*month*',
              formatDate(deadlineUploadDate, 'MMM yyyy')
            )}
          </div>
        )}
      </div>
      {uploadedFiles.every((f) => isMUFile(f)) &&
        currentDate < importFilesDate && (
          <div className='px-4 font-semibold'>
            {t(
              'If you have Easybill or Amazon data, the files will appear on the *date*.'
            ).replace('*date*', formatDate(importFilesDate, 'do MMM yyyy'))}
          </div>
        )}
      <div className='mb-4 whitespace-pre-line px-4'>
        <span
          dangerouslySetInnerHTML={{
            __html: `${t(
              'Here you can find all the invoices we are going to use for filing. If you see some problems or missing data, please contact your manager by *date*. All the files that do not have any problems and ready to be used for filing are found in the'
            ).replace(
              '*date*',
              `<span class='font-semibold'>${formatDate(
                deadlineUploadDate,
                'do MMM yyyy'
              )}</span>`
            )}`,
          }}
        />
        &nbsp;
        {invoiceBookLink}
      </div>
      {Object.entries(sourceMap).map(([source, files]) => {
        const isSourceManual = source === 'manual';
        return (
          <InvoiceDataSourceItem
            onClickDeleteFile={onClickDeleteFile}
            key={source}
            source={source as Source}
            hasProcessedFiles={hasProcessedFiles}
            files={files}
            stopMUBlock={
              isSourceManual ? (
                <StopMUBlock
                  manualUploadMailingStopped={
                    manualUploadMailingStopped.data || false
                  }
                  hasUploadedFile={hasUploadedMUFile}
                />
              ) : undefined
            }
            deadlineDescription={
              activeCompany?.ManualUploadNeeded && isSourceManual ? (
                <span
                  className={`${
                    !(hasUploadedMUFile || manualUploadMailingStopped.data) &&
                    'text-orange-500'
                  }`}
                >
                  {t('by *deadline*').replace(
                    '*deadline*',
                    formatDate(deadlineUploadDate, 'do MMM yyyy')
                  )}
                </span>
              ) : undefined
            }
          />
        );
      })}
    </div>
  );
}
