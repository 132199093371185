import { createContext, useEffect, useState, ReactNode } from 'react';
import { COOKIE } from '../core/constants';
import { getCookie } from '../core/helpers';
import { v4 as uuidv4 } from 'uuid';

export const VisitorContext = createContext<{
  visitorId: string;
}>({ visitorId: '' });

export function VisitorProvider({ children }: { children: ReactNode }) {
  const [visitorId, setVisitorId] = useState<string>('');

  useEffect(() => {
    if (!visitorId) {
      setVisitorId(getCookie(COOKIE.visitor_uuid) || uuidv4());
    }
  }, [visitorId]);

  return (
    <VisitorContext.Provider value={{ visitorId }}>
      {children}
    </VisitorContext.Provider>
  );
}
