import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getClientFCTransfers,
  getClientWarehouses,
  getCrossThreshold,
  getRegisteredVATIds,
} from '@/core/api';
import { useAppSelector } from '../../hooks';
import SellerSetupCheckThreshold from '@/components/SellerSetupCheckThreshold';
import './SellerSetupCheck.scss';
import { formatDate, formatNumber } from '@/core/helpers';
import { subMonths } from 'date-fns';
import CountryLabel from '@/components/CountryLabel/CountryLabel';
import { Loader } from '@/components/Loader/Loader';
import { NormalizedFCTransfer } from '../SetupCheck/SetupCheck/SetupCheck';
import { TableGrid } from '@/components/TableGrid/TableGrid';
import MiniLoader from '@/components/MiniLoader/MiniLoader';
import {
  activeCompanySelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';
import { useQueries } from '@tanstack/react-query';
import { IdNumberStatusEnum } from '@/core/enums';
import { useThreshold } from '../../context/useThreshold';
import PageLayout from '@/wrappers/PageLayout';
import FeedbackWidget from '@/components/FeedbackWidget/FeedbackWidget';

export interface CountryThreshold {
  country: string;
  threshold: { value: number; currency: string };
}

export default function SellerSetupCheck() {
  const { t } = useTranslation();
  const queryKey = useAppSelector(queryKeySelector);
  const activeCompany = useAppSelector(activeCompanySelector);

  const { thresholdByCountries, threshold } = useThreshold();

  const [warehouses, FCTransfers, crossThreshold, vatIds] = useQueries({
    queries: [
      {
        queryKey: ['SellerWarehouses', queryKey],
        queryFn: () => getClientWarehouses(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerFCTransfers', queryKey],
        queryFn: () => getClientFCTransfers(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerCrossThreshold', queryKey],
        queryFn: () => getCrossThreshold(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerRegisteredVATIds', queryKey],
        queryFn: () => getRegisteredVATIds(activeCompany?.company_id),
      },
    ],
  });

  const FCTransfersData = FCTransfers.data;

  const normalizedFCTransfers: NormalizedFCTransfer[] = useMemo(() => {
    const items = FCTransfersData || [];

    const countries = [
      ...(FCTransfersData || []).map((item) => item.departure_country),
      ...items.map((item) => item.arrival_country),
    ].filter((value, index, self) => {
      return self.findIndex((v) => v === value) === index;
    });

    return countries.reduce((acc: NormalizedFCTransfer[], country) => {
      const arrivals = items.filter(
        (item) => item.departure_country === country
      );
      const departures = items.filter(
        (item) => item.arrival_country === country
      );

      acc.push({
        country,
        departures: {
          countries: departures
            .filter((value, index, self) => {
              return (
                self.findIndex(
                  (v) => v.departure_country === value.departure_country
                ) === index
              );
            })
            .map((item) => item.departure_country),
          total: departures.reduce((acc, item) => {
            return acc + item.number;
          }, 0),
        },
        arrivals: {
          countries: arrivals
            .filter((value, index, self) => {
              return (
                self.findIndex(
                  (v) => v.arrival_country === value.arrival_country
                ) === index
              );
            })
            .map((item) => item.arrival_country),
          total: arrivals.reduce((acc, item) => {
            return acc + item.number;
          }, 0),
        },
      });

      return acc;
    }, []);
  }, [FCTransfersData]);

  const isLoading =
    warehouses.isLoading || crossThreshold.isLoading || vatIds.isLoading;

  return (
    <PageLayout
      className='flex-grow'
      rightColumn={
        <div style={{ maxWidth: 328 }} className='pt-6 lg:ml-auto lg:pt-0'>
          <FeedbackWidget testId='FeedbackWidget' />
        </div>
      }
    >
      <div className='mx-auto w-full max-w-[1328px]'>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='mx-auto max-w-[874px] rounded-lg bg-white pt-4'>
            <div className='mb-4 px-6'>
              <div className='SellerSetupCheck__title mb-2 font-black'>
                {t('VAT Setup')}
              </div>
              <div className='mb-2 text-gray-700'>
                {formatDate(new Date(2021, 0, 1), 'MMM yyyy')} —{' '}
                {formatDate(subMonths(new Date(), 1), 'MMM yyyy')}
                {crossThreshold.data?.crossed_oss_threshold_label && (
                  <>
                    <span>&nbsp;•&nbsp;</span>
                    <span>
                      {t('OSS threshold exceeded on *q*').replace(
                        '*q*',
                        crossThreshold.data?.crossed_oss_threshold_label
                      )}
                    </span>
                  </>
                )}
              </div>
              <SellerSetupCheckThreshold
                thresholdByCountry={(thresholdByCountries || []).sort(
                  (a, b) => b.threshold.value - a.threshold.value
                )}
                threshold={threshold}
              />
            </div>
            <div className='mb-4 border-t-4 border-gray-500 px-6 pt-4'>
              <div className='text-h4 font-extrabold'>{t('Storing')}</div>
              <div className='mb-2'>
                {t('In each of this countries you must have active VAT ID.')}
              </div>
              {!!warehouses.data?.length ? (
                <>
                  <div className='mb-2 flex gap-x-6'>
                    <div className='w-4/12 text-gray-700'>{t('Country')}</div>
                    <div className='w-3/12 text-gray-700'>
                      {t('Active since')}
                    </div>
                    <div className='w-3/12 text-gray-700'>
                      {t('Items sold')}
                    </div>
                    <div className='w-2/12 text-center text-gray-700'>
                      {t('VAT ID')}
                    </div>
                  </div>
                  {warehouses.data.map((w) => {
                    // const hasVATId = vatIds.includes(w.country);

                    const vatId = (vatIds.data || []).find(
                      (v) => v.Country.short_name === w.country
                    );

                    return (
                      <div className='mb-4 flex gap-x-6' key={w.country}>
                        <div className='w-4/12'>
                          <CountryLabel country={w.country} />
                        </div>
                        <div className='w-3/12'>
                          {formatDate(w.active_from, 'dd.MM.yyy')}
                        </div>
                        <div className='w-3/12'>
                          {formatNumber(w.count_of_sold_items)}
                        </div>
                        <div className='w-2/12 text-center'>
                          {vatId?.vat_number_status !== undefined &&
                            typeof vatId.vat_number_status === 'number' &&
                            t(IdNumberStatusEnum[vatId.vat_number_status])}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <span>{t('No storing')}</span>
              )}
            </div>

            {FCTransfers.isLoading ? (
              <MiniLoader className='justify-center py-4' />
            ) : (
              !!normalizedFCTransfers.length && (
                <div className='mb-4 border-t-4 border-gray-500 px-6 pt-4'>
                  <div>
                    <div className='mb-1 text-h4 font-extrabold'>
                      {t(
                        'Transactions between Amazon warehouses (FC_Transfer)'
                      )}
                    </div>
                    <div className='mb-2'>
                      {t(
                        'For such transactions Amazon automatically applies zero VAT rate.'
                      )}
                    </div>
                    <div className='mb-2'>
                      {t(
                        'However, you can only apply the zero VAT rate only if you have a VAT ID for both departure country and arrival country. Otherwise, you will have to pay tax on each item moved based on the purchase price at the applicable VAT rate.'
                      )}
                    </div>
                    <div>
                      <TableGrid
                        className='TableGrid--SellerSetupCheck mb-4 pb-1 lg:mb-0'
                        columns={[
                          {
                            label: 'Country',
                            field: 'country',
                            formatter: ({ country }) => (
                              <CountryLabel country={country} />
                            ),
                            maxWidth: '160px',
                          },
                          {
                            label: 'Departures',
                            field: 'departures',
                            formatter: ({ departures }) => (
                              <div className='flex'>
                                <div className='mr-2 font-semibold'>
                                  <div
                                    style={{
                                      minWidth: 52,
                                    }}
                                  >
                                    {formatNumber(departures.total)}
                                  </div>
                                </div>
                                <div className='flex flex-wrap'>
                                  {departures.countries.map((c) => (
                                    <div
                                      key={c}
                                      className='SetupCheck__FCTransferCountryCol mr-2'
                                    >
                                      {t(c)}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ),
                          },
                          {
                            label: 'Arrivals',
                            field: 'arrivals',
                            formatter: ({ arrivals }) => (
                              <div className='flex'>
                                <div className='mr-2 font-semibold'>
                                  <div
                                    style={{
                                      minWidth: 52,
                                    }}
                                  >
                                    {formatNumber(arrivals.total)}
                                  </div>
                                </div>
                                <div className='flex flex-wrap'>
                                  {arrivals.countries.map((c) => (
                                    <div
                                      key={c}
                                      className='SetupCheck__FCTransferCountryCol mr-2'
                                    >
                                      {t(c)}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ),
                          },
                        ]}
                        data={normalizedFCTransfers}
                      />
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </PageLayout>
  );
}
