import { useTranslation } from 'react-i18next';
import Modal from '@/components/Modal/Modal';
import AlertIcon from '@/icons/alert-triangle.svg?react';
import NotIdentifiedFile from '@/icons/not-identified-file.svg?react';
import UploadIcon from '@/icons/upload_arrow.svg?react';
import Button from '@/components/Button';
import useErrorsNotificationModal from './useErrorsNotificationModal';
import ScreenShotEn from '@/images/screenshot_3.png';
import ScreenShotDe from '@/images/screenshot_4.png';
import FileUploader from '@/components/FileUploader/FileUploader';
import { useMU } from '@/context/useMU';
import { ACCEPTED_EXTS } from '@/components/ManualDataUploader/ManualUploader.helpers';
import ManualUploadFile from '@/components/ManualDataUploader/ManualUploadFile';
import SendIcon from '@/icons/sent.svg?react';
import classNames from 'classnames';

export default function ErrorsNotificationModal() {
  const { t, i18n } = useTranslation();
  const {
    isModalOpened,
    closeModal,
    files: filesWithErrors,
  } = useErrorsNotificationModal();

  const {
    onSubmitFiles,
    onSelectFiles,
    onDeleteFileToUpload,
    filesToUpload,
    isUploadingFiles,
  } = useMU();

  return (
    <Modal
      isOpened={isModalOpened}
      close={closeModal}
      title='File processing'
      name='ErrorNotification'
    >
      <div
        className='flex flex-col justify-center gap-y-4 px-8 py-6'
        style={{
          minHeight: 350,
        }}
      >
        <div className='flex flex-col items-center justify-center'>
          <AlertIcon />
          <h4 className='Title--sm pb-1 font-extrabold'>
            {t('File is not accepted')}
          </h4>
          <p>{t('Please correct mistakes and upload the new file again')}</p>
        </div>
        <div className='w-full rounded-lg border border-gray-500 bg-gray-70 px-4 pb-4'>
          <div className='divide-y '>
            {filesWithErrors.map((file) => (
              <div className='flex flex-col gap-y-2 py-5' key={file.id}>
                <div className='flex items-center gap-x-2 font-semibold'>
                  <NotIdentifiedFile />
                  <span>{file.name}</span>
                </div>
                <div>
                  {file.errors.map((err) =>
                    err
                      .split('.')
                      .slice(0, -1)
                      .map((i) => <div key={i}>{i}.</div>)
                  )}
                </div>
              </div>
            ))}
          </div>
          <div>
            {isUploadingFiles ? (
              <Button
                disabled
                className={classNames(
                  'Button flex !w-[200px] cursor-pointer items-center !pl-3',
                  {
                    '!w-[240px]': i18n.language === 'de',
                  }
                )}
              >
                <UploadIcon className='mr-2' />
                <span>{t('Upload new files')}</span>
              </Button>
            ) : (
              <FileUploader
                onSelect={onSelectFiles}
                name='manual-upload-file'
                accept={ACCEPTED_EXTS}
                className={classNames(
                  'Button flex !w-[200px] cursor-pointer items-center !py-1 !pl-3',
                  {
                    '!w-[240px]': i18n.language === 'de',
                  }
                )}
              >
                <UploadIcon className='mr-2 [&>path]:fill-white' />
                <span>{t('Upload new files')}</span>
              </FileUploader>
            )}
            <form onSubmit={onSubmitFiles}>
              {!!filesToUpload.length && (
                <div className='pt-4'>
                  {filesToUpload.map((f) => (
                    <ManualUploadFile
                      className='mb-2 flex-grow'
                      onDeleteFile={onDeleteFileToUpload(f.name)}
                      file={f}
                      disabled={false}
                      key={f.name}
                      isUploading={isUploadingFiles}
                    />
                  ))}
                </div>
              )}
              {!!filesToUpload.length && !isUploadingFiles && (
                <div>
                  <Button
                    className='mt-4 flex items-center !py-1 !pl-4 !pr-6'
                    type='submit'
                    disabled={isUploadingFiles}
                    data-testid='manual-upload-submit'
                  >
                    <SendIcon className='mr-2' />
                    <span>{t('Submit')}</span>
                  </Button>
                </div>
              )}
            </form>
          </div>
        </div>
        <div className='flex items-center justify-center gap-x-3'>
          <span>{t('File will be moved to the')}</span>
          <img
            src={i18n.language === 'de' ? ScreenShotDe : ScreenShotEn}
            alt='ScreenShot'
            style={{ height: '46px' }}
          />
        </div>
        <div className='self-left'>
          <Button
            styling='outline'
            className='flex !w-[178px] items-center justify-center'
            type='submit'
            data-testid='submit-note-modal-btn'
            onClick={closeModal}
          >
            <span>{t('I understand')}</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
}
