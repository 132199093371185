import { useTranslation } from 'react-i18next';
import Modal from '@/components/Modal/Modal';
import Button from '@/components/Button';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
}

export default function CorrectionFilesInstructionModal({
  isModalOpened,
  closeModal,
}: Props) {
  const { t } = useTranslation();
  return (
    <Modal isOpened={isModalOpened} close={closeModal} title={t('Information')}>
      <div className='px-8 pb-6 pt-4'>
        <div className='text-h4 font-extrabold'>{t('How to correct')}</div>
        <ol className='mx-4 mb-4 list-decimal'>
          <li>
            {t(
              'Download the Error List (list of incorrect invoices) which has been sent to you also via email'
            )}
          </li>
          <li>
            {t(
              `In column A you can see the comment (this is ONLY for you to understand what needs to be corrected), please do not rewrite here anything`
            )}
          </li>
          <li>
            {t(
              `After correction of mistakes, please upload the Error List into Client Portal using the upload function. (Please upload ONLY the Error List and not the whole data again to avoid duplicates)`
            )}
          </li>
          <li>
            {t(
              'In case you have done everything correctly, your job is done here. In case there are still some errors, you will shortly receive an email with incorrect invoices'
            )}
          </li>
        </ol>
        <div>
          <Button styling='outline' onClick={closeModal}>
            {t('Close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
