import { DataUnit, DataUnitStatsItem } from '@/core/types';
import { formatDate, formatNumber } from '@/core/helpers';
import { normalizeDateFE } from '@/core/normalization';
import { useTranslation } from 'react-i18next';
import InvoiceDataSourceItem from '../ManualDataUploader/InvoiceDataSourceItem';
import { activeCompanySelector } from '@/core/store/user/userSlice';
import { useAppSelector } from '../../hooks';
import {
  addDays,
  addMonths,
  differenceInCalendarMonths,
  startOfMonth,
} from 'date-fns';
import { Link } from 'react-router-dom';
import { AuthedRoutesEnum, ManualUploadFileStatusEnum } from '@/core/enums';
import { useMU } from '../../context/useMU';
import { useMemo } from 'react';
import { DotsLoader } from '../DotsLoader';
import classNames from 'classnames';
import TopProgressLoader from '../TopProgressLoader';
import { StopMUBlock } from './StopMUBlock';

interface Props {
  unit: DataUnit;
  manualUploadMailingStopped?: boolean;
  stats?: DataUnitStatsItem;
  onClickDeleteFile: (id: number) => void;
}

export const SellerDataUnit = ({
  unit,
  manualUploadMailingStopped,
  stats,
  onClickDeleteFile,
}: Props) => {
  const { t, i18n } = useTranslation();
  const activeCompany = useAppSelector(activeCompanySelector);
  const { uploadedFiles } = useMU();

  const hasProcessedFiles = uploadedFiles.some(
    (f) => f.status !== ManualUploadFileStatusEnum.CREATED && !f.errors.length
  );

  const periodFrom = normalizeDateFE(unit.date);
  const deadlineDate = addDays(startOfMonth(addMonths(periodFrom, 1)), 9);
  const isCurrentPeriod =
    differenceInCalendarMonths(new Date(), periodFrom) === 1;

  const month = periodFrom.getMonth();
  const year = periodFrom.getFullYear();

  const getLinkSearch = (filter?: string) => {
    const queryParams = new URLSearchParams();

    queryParams.set('month', String(month));
    queryParams.set('year', String(year));
    queryParams.set('company_id', activeCompany?.company_id || '');
    if (filter) {
      queryParams.set('filter', filter);
    }

    return queryParams.toString();
  };

  const sortedUnitItems = unit.items.sort((a, b) => {
    if (a.source_type === 'manual') return -1;
    if (b.source_type === 'manual') return 1;
    return a.source_type.localeCompare(b.source_type);
  });

  const items = unit.items;
  const files = useMemo(() => {
    return items.flatMap((item) => item.files);
  }, [items]);

  const isProcessingFiles = files.filter((f) => !!f.update_progress).length > 0;

  return (
    <div className='relative overflow-hidden rounded-lg bg-white px-4 pt-2'>
      {isProcessingFiles && (
        <TopProgressLoader isLoading={true} className='!bg-transparent' />
      )}
      <div className='min-h-fit text-h4 font-extrabold '>
        {formatDate(periodFrom, 'MMMM yyyy')}
      </div>
      {!!stats?.documents_count && (
        <div className='mt-4 flex w-full gap-x-2'>
          <Link
            className={classNames(
              'w-[calc(25% - 6px)] flex max-w-[170px] flex-grow flex-col justify-end gap-x-2 rounded-lg border p-2 pb-4 hover:border-white hover:text-dark hover:ring-2 hover:ring-dark',
              {
                'cursor-not-allowed': isProcessingFiles,
              }
            )}
            to={{
              pathname: AuthedRoutesEnum.Documents,
              search: getLinkSearch(),
            }}
          >
            {isProcessingFiles ? (
              <DotsLoader />
            ) : (
              <span className='pb-2 text-h3 leading-8'>
                {stats.documents_count > 999
                  ? Math.round(stats.documents_count / 1000)
                  : (stats.documents_count / 1000).toFixed(1)}
                <span>{i18n.language === 'de' ? 'T' : 'k'}</span>
              </span>
            )}
            <span className='font-semibold'>{t('Documents')}</span>
            <span className='text-sm text-gray-700'>
              {t('From all data sources')}
            </span>
          </Link>
          {!!stats?.errors_count && !isProcessingFiles && (
            <Link
              className='w-[calc(25% - 6px)] flex max-w-[170px] flex-grow flex-col justify-end gap-x-2 rounded-lg border p-2 pb-4 hover:border-white hover:text-dark hover:ring-2 hover:ring-dark'
              to={{
                pathname: AuthedRoutesEnum.Documents,
                search: getLinkSearch('error'),
              }}
            >
              <span className='pb-2 text-h3 leading-8 text-red-500'>
                {formatNumber(stats.errors_count)}
              </span>

              <span className='font-semibold'>{t('Errors')}</span>
              <span className='text-sm text-gray-700'>
                {t('Must be fixed')}
              </span>
            </Link>
          )}
          {!!stats?.warnings_count && !isProcessingFiles && (
            <Link
              className='w-[calc(25% - 6px)] flex max-w-[170px] flex-grow flex-col justify-end gap-x-2 rounded-lg border p-2 pb-4 hover:border-white hover:text-dark hover:ring-2 hover:ring-dark'
              to={{
                pathname: AuthedRoutesEnum.Documents,
                search: getLinkSearch('warning'),
              }}
            >
              <span className='pb-2 text-h3 leading-8 text-orange-500'>
                {formatNumber(stats?.warnings_count)}
              </span>
              <span className='font-semibold'>{t('Notices')}</span>
              <span className='text-sm text-gray-700'>{t('Nice-to-fix')}</span>
            </Link>
          )}
          <Link
            className='w-[calc(25% - 6px)] flex max-w-[170px] flex-grow items-center  justify-center rounded-lg border p-2 pb-4 font-semibold hover:border-white hover:text-dark hover:ring-2 hover:ring-dark'
            to={{
              pathname: AuthedRoutesEnum.Documents,
              search: getLinkSearch(),
            }}
          >
            {t('Show details')} ⟶
          </Link>
        </div>
      )}
      <div className='mt-4'>
        {sortedUnitItems.map((dataUnitSourceItem) => {
          const hasUploadedMUFile =
            dataUnitSourceItem.source_type === 'manual' &&
            !!dataUnitSourceItem.files.length;

          return (
            <InvoiceDataSourceItem
              onClickDeleteFile={onClickDeleteFile}
              className='!px-0'
              key={dataUnitSourceItem.source_type}
              source={dataUnitSourceItem.source_type}
              hasProcessedFiles={hasProcessedFiles}
              files={dataUnitSourceItem.files}
              stopMUBLockPosition='bottom'
              deadlineDescription={
                activeCompany?.ManualUploadNeeded &&
                isCurrentPeriod &&
                dataUnitSourceItem.source_type === 'manual' ? (
                  <span
                    className={`${
                      manualUploadMailingStopped
                        ? 'text-dark'
                        : 'text-orange-500'
                    }`}
                  >
                    {t('by *deadline*').replace(
                      '*deadline*',
                      formatDate(deadlineDate, 'do MMM yyyy')
                    )}
                  </span>
                ) : undefined
              }
              stopMUBlock={
                isCurrentPeriod ? (
                  <StopMUBlock
                    manualUploadMailingStopped={
                      manualUploadMailingStopped || false
                    }
                    hasUploadedFile={hasUploadedMUFile}
                  />
                ) : undefined
              }
            />
          );
        })}
      </div>
    </div>
  );
};
