import classNames from 'classnames';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import Input from '@/components/Input/Input';
import { ChangeEventHandler, useEffect, useState } from 'react';
import { Loader } from '@/components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import Header from '@/components/Header/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmForgotPassword } from '@/core/auth';
import {
  PASSWORD_VALIDATION_PARAMETERS,
  isCodeValid,
  isPasswordValid,
  passwordValidators,
} from '@/core/helpers';
import Button from '@/components/Button';
import useNotification from '../../hooks/useNotification';

interface FormValues {
  confirmationCode: string;
  password: string;
}

const CreatePassword = () => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();

  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const email = params.get('email');

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      confirmationCode: '',
      password: '',
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.confirmationCode) {
        errors.confirmationCode = t('Required')!;
      }

      if (
        values.confirmationCode !== '' &&
        !isCodeValid(values.confirmationCode)
      ) {
        errors.confirmationCode = 'Verification code is not valid';
      }

      if (values.password === '' || !isPasswordValid(values.password)) {
        errors.password = JSON.stringify(
          PASSWORD_VALIDATION_PARAMETERS.reduce((acc: string[], item) => {
            if (
              passwordValidators[item] &&
              !passwordValidators[item](values.password)
            ) {
              acc.push(item);
            }
            return acc;
          }, [])
        );
      }

      return errors;
    },
    onSubmit: ({ confirmationCode, password }) => {
      if (error) setError('');
      setIsLoading(true);
      confirmForgotPassword({
        confirmationCode,
        password,
        email: email!.replace(' ', '+'),
      })
        .then(() => {
          setIsLoading(false);
          showNotification({
            type: 'success',
            text: 'New password created',
          });
          navigate('/');
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.name === 'CodeMismatchException') {
            setError('Verification code is not valid');
          } else if (err.name === 'ExpiredCodeException') {
            setError('Verification code is expired');
          } else {
            setError('Something went wrong, please try again');
          }
        });
    },
  });

  const onChangeField: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (error) setError('');
    formik.setFieldValue(
      e.target.name,
      e.target.value,
      formik.touched[e.target.name as keyof FormikTouched<FormValues>]
    );
  };

  useEffect(() => {
    if (!email) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, [email]);

  if (!email) return null;

  if (isLoading) return <Loader />;

  const passwordErrors = (formik.errors.password || '').slice();

  return (
    <>
      <Header />
      <div
        className='Page--Login flex items-center justify-center bg-gray-300 px-4 py-8 lg:px-6 2xl:px-14'
        data-testid='login'
      >
        <div className='Login__container lg:mb-12 lg:pb-12'>
          <div className='Title--lg mb-4 font-black lg:text-center'>
            {t('Create new password')}
          </div>
          <form onSubmit={formik.handleSubmit} noValidate className='w-full'>
            <div className='Login__content items-start'>
              <Input
                label='Verification code from email'
                name='confirmationCode'
                id='confirmationCode'
                onChange={onChangeField}
                onBlur={formik.handleBlur}
                value={formik.values.confirmationCode}
                error={formik.errors.confirmationCode}
                touched={formik.touched.confirmationCode}
              />
              <Input
                label='Password'
                name='password'
                id='password'
                type='password'
                wrapperClassName='RegisterForm__password'
                onChange={onChangeField}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik.errors.password}
                touched={formik.touched.password}
              />
              <div className='RegisterForm__passwordRequirements flex flex-wrap gap-x-1'>
                {PASSWORD_VALIDATION_PARAMETERS.map((item: any, i) => (
                  <div key={item}>
                    <div
                      className={classNames('mb-1 whitespace-nowrap', {
                        'RegisterForm__badge rounded':
                          formik.touched.password &&
                          passwordErrors.includes(item),
                        'Text--green':
                          formik.touched.password &&
                          !passwordErrors.includes(item),
                      })}
                      data-testid={`password-validation-${item
                        .toLowerCase()
                        .replace(/\s/g, '_')}`}
                    >
                      {t(item)}
                      {i === 0 ? (
                        ':'
                      ) : i !== PASSWORD_VALIDATION_PARAMETERS.length - 1 ? (
                        <span className='text-dark'>,</span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='mt-4 pt-4'>
              <Button
                className='Login__btn !w-full'
                type='submit'
                disabled={isLoading}
              >
                {t('Create')}
              </Button>
              {error && (
                <div className='Login__error text-center'>{t(error)}</div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreatePassword;
