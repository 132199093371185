import { ReactElement, useLayoutEffect, useRef, useState } from 'react';
import TooltipContent, { Position } from './TooltipContent';
import { getBoundingAbsoluteRect } from '@/core/helpers';
import './Tooltip.scss';
import { useDebounce } from '@/hooks';

interface Props {
  content: JSX.Element | string | null;
  children: ReactElement;
  wrapperClassName?: string;
  className?: string;
  delay?: number;
  position?: Position;
  dataTestId?: string;
  styling?: 'black' | 'white' | 'transparent';
  autoPlacement?: boolean;
}

export default function Tooltip({
  content,
  children,
  wrapperClassName = '',
  className = '',
  delay = 250,
  position = 'bottom',
  dataTestId,
  styling,
  autoPlacement = true,
}: Props) {
  const [currentPosition, setCurrentPosition] = useState<Position>(position);

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [isOpened, setIsOpened] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const debouncedIsOpened = useDebounce(isOpened, delay);
  const viewportHeightRef = useRef(
    window.innerHeight || document.documentElement.clientHeight
  );
  const viewportWidthRef = useRef(
    window.innerWidth || document.documentElement.clientWidth
  );

  const setPosition = (p: Position) => {
    if (!ref.current) return;

    const { x, y, bottom, height, width } = getBoundingAbsoluteRect(
      ref.current
    );

    if (p === 'bottom') {
      setY(bottom + 4);
      setX(x + width / 2);
    }

    if (p === 'top') {
      setY(y - 4);
      setX(x + width / 2);
    }

    if (p === 'left') {
      setY(y + height / 2);
      setX(x - 4);
    }

    if (p === 'right') {
      setY(y + height / 2);
      setX(x + width + 4);
    }

    if (p === 'top-right') {
      setY(y);
      setX(x + width + 4);
    }

    if (p === 'bottom-right') {
      setY(bottom);
      setX(x + width + 4);
    }
    if (p === 'top-left') {
      setY(y);
      setX(x - 4);
    }

    if (p === 'bottom-left') {
      setY(bottom);
      setX(x - 4);
    }

    setCurrentPosition(p);
  };

  useLayoutEffect(() => {
    if (debouncedIsOpened && contentRef.current && autoPlacement) {
      const rect = contentRef.current.getBoundingClientRect();
      if (position === 'bottom' && rect.bottom > viewportHeightRef.current) {
        setPosition('top');
      } else if (position === 'top' && rect.top < 0) {
        setPosition('bottom');
      } else if (position === 'left' && rect.left < 0) {
        setPosition('right');
      } else if (
        position === 'right' &&
        rect.right > viewportWidthRef.current
      ) {
        setPosition('left');
      } else if (position === 'bottom-right' && rect.top < 0) {
        setPosition('top-right');
      } else if (
        position === 'top-right' &&
        rect.bottom > viewportHeightRef.current
      ) {
        setPosition('bottom-right');
      } else if (
        position === 'top-left' &&
        rect.bottom > viewportHeightRef.current
      ) {
        setPosition('bottom-left');
      } else if (position === 'bottom-left' && rect.top < 0) {
        setPosition('top-left');
      }
    }
    // eslint-disable-next-line
  }, [debouncedIsOpened]);

  const onMouseEnter = () => {
    viewportHeightRef.current =
      window.innerHeight || document.documentElement.clientHeight;
    viewportWidthRef.current =
      window.innerWidth || document.documentElement.clientWidth;

    setPosition(position);
    setIsOpened(true);
  };

  const onMouseLeave = () => {
    setIsOpened(false);
  };

  return (
    <div
      className={`Tooltip inline-block ${wrapperClassName}`}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid={dataTestId}
    >
      {children}
      <TooltipContent
        isOpened={isOpened ? debouncedIsOpened : isOpened}
        content={content}
        position={currentPosition}
        x={x}
        y={y}
        className={className}
        styling={styling}
        ref={contentRef}
      />
    </div>
  );
}
