import {
  capitalize,
  formatDate,
  formatNumber,
  getBoundingAbsoluteRect,
} from '@/core/helpers';
import { useTranslation } from 'react-i18next';
import FolderIcon from '@/icons/folder-outline.svg?react';
import { NormalizedSellerFilingReport } from '@/core/types';
import Tooltip from '../Tooltip/Tooltip';
import { normalizeDateFE } from '@/core/normalization';
import Button from '../Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PaymentInstructionBtn from '@/wrappers/PaymentInstructionBtn';
import classNames from 'classnames';
import SubdirectoryIcon from '@/icons/subdirectory-right.svg?react';
import { useAppSelector } from '@/hooks';
import { isOSSSelfServiceSelector } from '@/core/store/user/userSlice';
import {
  isReportSubmitted,
  isShouldSubmitOSSReport,
} from '@/components/OpenTasks/OpenTasks.helpers';
import { sortReportsFn } from '@/pages/Seller/SellerFiling/SellerFiling.helpers';
import { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  reports: NormalizedSellerFilingReport[];
  onFolderBtnClick: () => void;
  onBtnClick: (r: NormalizedSellerFilingReport) => () => void;
  onClickAgenda: (r: NormalizedSellerFilingReport) => () => void;
}

export default function SellerFilingOngoingItem({
  reports,
  onFolderBtnClick,
  onBtnClick,
  onClickAgenda,
}: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const { vs5151DisregardedInstruction } = useFlags();
  const isOSSSelfService = useAppSelector(isOSSSelfServiceSelector);

  const recommendedPaymentDate = normalizeDateFE(
    reports[0].payment_instruction?.due_date || reports[0].deadline_date
  );
  recommendedPaymentDate.setDate(recommendedPaymentDate.getDate() - 2);

  const renderPeriod = () => {
    let formatStr = 'MMM yyyy';

    if (reports[0].period.type === 'year') {
      return (
        <>
          <span>{t('Year')}</span>&nbsp;
          <span>
            {formatDate(normalizeDateFE(reports[0].period.from_date), 'yyyy')}
          </span>
        </>
      );
    }

    if (
      reports[0].country.short_name === 'IT' &&
      reports[0].period.type !== 'quarter'
    ) {
      return (
        <>
          <span>{capitalize(t('payment'))}</span>{' '}
          <span>
            {formatDate(
              normalizeDateFE(reports[0].period.from_date),
              formatStr
            )}
          </span>
        </>
      );
    }

    if (reports[0].period.type === 'quarter') {
      formatStr = 'QQQ yyyy';
    }

    return (
      <>
        <span>
          {formatDate(normalizeDateFE(reports[0].period.from_date), formatStr)}
        </span>
      </>
    );
  };

  const reportForAgenda = reports.find(
    (item) =>
      item.country.short_name === 'DE' &&
      item.type === 'local' &&
      !!item.payment_instruction
  );

  useEffect(() => {
    if (
      location.hash?.includes('confirmation') &&
      reports.some(
        (r) => r.confirmation?.id === +location.hash.slice(1).split('-')[1]
      ) &&
      ref.current
    ) {
      onFolderBtnClick();
      const rect = getBoundingAbsoluteRect(ref.current);
      window.scrollTo(0, rect.top - 70);
      navigate(
        { pathname: location.pathname, hash: '', search: location.search },
        {
          replace: true,
          state: location.state,
        }
      );
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div
      className='border-b border-gray-500 last-of-type:border-none'
      ref={ref}
    >
      <div className='flex w-full items-start justify-between gap-x-2 p-4 pr-2'>
        <div className='flex w-[40%] flex-grow justify-between gap-x-2 pt-2'>
          <div className='w-[100px] font-semibold'>
            {t('by *date*').replace(
              '*date*',
              formatDate(recommendedPaymentDate, 'do MMM')
            )}
          </div>
          <div className='flex-grow'>{renderPeriod()}</div>
        </div>
        <div className='flex w-[60%] items-start justify-end gap-x-2'>
          <div className='flex flex-grow flex-col justify-start space-y-2'>
            <div className='flex flex-grow flex-col justify-start space-y-2'>
              {reports.sort(sortReportsFn).map((report, index) => {
                const instruction = report?.payment_instruction;

                const isPaymentInstructionDisregarded =
                  instruction?.cancelled && vs5151DisregardedInstruction;

                return (
                  <div className='space-y-2' key={uuidv4()}>
                    <div className='flex justify-end gap-x-2'>
                      <div className='flex items-center justify-end gap-x-2'>
                        <div
                          className={classNames('font-semibold', {
                            'text-gray-700': isPaymentInstructionDisregarded,
                          })}
                        >
                          {report.payment_instruction
                            ? formatNumber(
                                report.payment_instruction.amount.amount / 100,
                                {
                                  currency:
                                    report.payment_instruction.amount.currency,
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                                undefined,
                                true
                              )
                            : t('Amount pending')}
                        </div>
                        {!!index && <SubdirectoryIcon />}
                      </div>
                      <PaymentInstructionBtn
                        className={`flex w-full shrink-0 items-center justify-between ${
                          i18n.language === 'en' ? '!w-[160px]' : '!w-[180px]'
                        }`}
                        report={report}
                        disabled={
                          isOSSSelfService &&
                          isShouldSubmitOSSReport(report) &&
                          !isReportSubmitted(report)
                        }
                        onClick={onBtnClick(report)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            {!!reportForAgenda && (
              <div className='space-y-2'>
                <div className='flex justify-end gap-x-2'>
                  <div className='flex items-center justify-end gap-x-2'>
                    <div className={classNames('font-semibold')}>
                      {t('Agenda')}
                    </div>
                  </div>
                  <PaymentInstructionBtn
                    className={`flex w-full shrink-0 items-center justify-between ${
                      i18n.language === 'en' ? '!w-[160px]' : '!w-[180px]'
                    }`}
                    report={reportForAgenda}
                    showAgendaBtn={true}
                    onClick={onClickAgenda(reportForAgenda)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className='w-10'>
            {!!reports.some((r) => r.confirmation?.documents.length > 0) ? (
              <button
                className='Button--naked flex h-10 w-10 shrink-0 items-center justify-center disabled:bg-transparent hover:disabled:bg-transparent'
                onClick={onFolderBtnClick}
              >
                <FolderIcon className='h-6 w-6' />
              </button>
            ) : (
              <>
                <Tooltip
                  content={t('No files for this period')}
                  delay={500}
                  position='bottom'
                >
                  <Button
                    styling='naked'
                    className='flex h-10 w-10 items-center justify-center disabled:bg-transparent hover:disabled:bg-transparent'
                    disabled
                  >
                    <FolderIcon className='h-6 w-6' />
                  </Button>
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
