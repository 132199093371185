import AnimateHeight from 'react-animate-height';

export interface CollapseProps {
  triggerRenderer: (isOpen: boolean, onTriggerClick: () => void) => JSX.Element;
  contentRenderer: (onTriggerClick: () => void) => JSX.Element;
  className?: string;
  isOpened: boolean;
  onTriggerClick: () => void;
}

export default function Collapse({
  triggerRenderer,
  contentRenderer,
  className = '',
  onTriggerClick,
  isOpened,
}: CollapseProps) {
  return (
    <div className={className}>
      <div>{triggerRenderer(isOpened, onTriggerClick)}</div>
      <AnimateHeight
        duration={250}
        height={isOpened ? 'auto' : 0}
        easing={'cubic-bezier(0.4, 0, 0.2, 1)'}
      >
        {contentRenderer(onTriggerClick)}
      </AnimateHeight>
    </div>
  );
}
