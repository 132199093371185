import './Logo.scss';
import LogoImage from '@/images/logo.svg?react';
import StageLogo from '@/icons/stage-logo.svg?react';

const ENV =
  import.meta.env.VITE_APP_ENV === 'development'
    ? 'dev'
    : import.meta.env.VITE_APP_ENV || '';

interface Props {
  className?: string;
}

export default function Logo({ className = '' }: Props) {
  return import.meta.env.VITE_APP_ENV === 'prod' ? (
    <LogoImage className={className} />
  ) : (
    <div className='flex items-center'>
      <StageLogo />
      <span className='Logo ml-2 font-extrabold text-orange-500'>{ENV}</span>
    </div>
  );
}
