import { Period } from '@/components/PeriodsDropdown/PeriodsDropdown';
import { IToast } from '@/context/toast-context';
import { ToastType } from '@/core/enums';
import { NormalizedEvaluation } from '@/core/types';
import { isEqual, startOfMonth, startOfQuarter } from 'date-fns';

interface EvaluationToast extends IToast {
  context: NormalizedEvaluation;
}

export const isEvaluationToast = (toast: IToast): toast is EvaluationToast => {
  return toast.type === ToastType.evaluation;
};

export const isCurrentPeriodEvaluation = (
  e: NormalizedEvaluation,
  period: Period
) => {
  const periodDate =
    period.type === 'month'
      ? startOfMonth(new Date(period.year, period.value, 1))
      : startOfQuarter(new Date(period.year, (period.value - 1) * 3, 1));

  return e.type === period.type && isEqual(e.startDate, periodDate);
};
