import CountryLabel from '../CountryLabel/CountryLabel';

import { formatDate, formatNumber } from '@/core/helpers';
import classNames from 'classnames';
import { CompletedFilingItemData } from '../../pages/Seller/SellerFiling/SellerPastPeriodsFiling';
import { useTranslation } from 'react-i18next';
import Arrow from '@/icons/select-chevron.svg?react';
import {
  FilingReportConfirmation,
  NormalizedSellerFilingReport,
} from '@/core/types';
import {
  groupReportsByCountryAndType,
  groupReportsByPeriodType,
} from '../../pages/Seller/SellerFiling/SellerFiling.helpers';
import { SellerFilingPastPeriodsGroupOld } from './SellerFilingPastPeriodsGroupOld';
import { ControlledCollapse } from '../Collapse/ControlledCollapse';

interface Props {
  data: CompletedFilingItemData;
  initialOpened?: boolean;
  onFolderBtnClick: (
    confirmations: FilingReportConfirmation[],
    date: Date,
    country: string
  ) => () => void;
  onAmountBtnClick: (r: NormalizedSellerFilingReport) => () => void;
  onClickAgenda: (r: NormalizedSellerFilingReport) => () => void;
}

export default function SellerPastPeriodsFilingItemOld({
  data,
  initialOpened,
  onFolderBtnClick,
  onAmountBtnClick,
  onClickAgenda,
}: Props) {
  const { t } = useTranslation();

  return (
    <ControlledCollapse
      initialOpened={initialOpened}
      className='mb-2'
      triggerRenderer={(isOpened, toggleCollapse) => (
        <div
          className={`flex cursor-pointer items-start ${
            isOpened ? 'rounded-t-lg ' : 'rounded-lg'
          } border py-2 pl-4 pr-2`}
          onClick={toggleCollapse}
        >
          <div className='SellerPastPeriodsFiling__top flex-shrink-0 font-extrabold'>
            {formatDate(data.month, 'MMMM yyyy')}
          </div>
          <div className='flex flex-grow items-start justify-end'>
            <div className='flex flex-wrap justify-end'>
              <span className='shrink-0'>{t('Total paid')}:</span>
              {Object.entries(data.amounts).map(([k, v], i) => (
                <div key={k} className='SellerPastPeriodsFiling__amount'>
                  &nbsp;{i > 0 ? ' ' : ''}
                  {formatNumber(
                    v / 100,
                    {
                      currency: k,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                    undefined,
                    true
                  )}
                  &nbsp;
                </div>
              ))}
            </div>
            <Arrow
              className={classNames('SellerPastPeriodsFiling__arrow', {
                'SellerPastPeriodsFiling__arrow--active': isOpened,
              })}
            />
          </div>
        </div>
      )}
      contentRenderer={() => (
        <div className='[&>*:last-child]:rounded-b-lg'>
          <div className='mx-0 grid grid-cols-[minmax(120px,1fr),minmax(490px,3fr)] border-x border-b px-4 py-2 text-gray-700'>
            <div>{t('Country')}</div>
            <div className='grid grid-cols-[minmax(140px,1.4fr),minmax(240px,2.4fr),minmax(80px,0.8fr)]'>
              <div>{t('Period')}</div>
              <div>{t('VAT amount')}</div>
              <div>{t('Documents')}</div>
            </div>
          </div>
          {groupReportsByCountryAndType(data.reports).map((countryGroup) => {
            return (
              <div
                key={countryGroup.country + countryGroup.type}
                className='grid grid-cols-[minmax(120px,1fr),minmax(490px,3fr)] border-x border-b px-4 py-3'
              >
                <div>
                  <CountryLabel
                    country={
                      countryGroup.type === 'oss' ? 'EU' : countryGroup.country
                    }
                  />
                </div>
                <div className='flex flex-col gap-y-4'>
                  {groupReportsByPeriodType(countryGroup.reports).map(
                    (group) => {
                      return (
                        <SellerFilingPastPeriodsGroupOld
                          key={group.periodType}
                          onFolderBtnClick={onFolderBtnClick}
                          onAmountBtnClick={onAmountBtnClick}
                          onClickAgenda={onClickAgenda}
                          month={data.month}
                          group={group}
                          countryGroup={countryGroup}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    />
  );
}
