import { BtnContext } from '@/wrappers/PaymentInstructionBtn';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  context: BtnContext;
  topElementClassName: string;
}

export default function ButtonToggle({
  context,
  topElementClassName = '',
  children,
  ...rest
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  return (
    <button
      className={classNames('relative !w-full', context.className)}
      {...rest}
    >
      <div
        className={classNames(
          'absolute left-0 top-0 flex h-full w-full items-center !justify-center opacity-0 transition-opacity hover:opacity-100',
          topElementClassName
        )}
      >
        {children}
      </div>
      <div className='flex items-center justify-between'>
        <span>{t(context.label)}</span>
        {context.icon}
      </div>
    </button>
  );
}
