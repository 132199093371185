import { useTranslation } from 'react-i18next';
import Modal from '@/components/Modal/Modal';
import Button from '@/components/Button';
import {
  getInvoiceDocumentSuggestedValues,
  getInvoiceDocumentSuggestions,
} from '@/core/api';
import { useEffect, useState } from 'react';
import {
  EDITABLE_INVOICE_DOCUMENT_VALUES,
  EditableInvoiceDocumentValuesMapping,
  RESOLVE_OPTIONS_DATA_DE,
  RESOLVE_OPTIONS_DATA_EN,
} from './SellerDocuments.helpers';
import { InvoiceDocument, InvoiceDocumentSuggestionsType } from '@/core/types';
import { ResolveErrorModalSkeleton } from '@/components/ResolveErrorModalSkeleton/ResolveErrorModalSkeleton';
import BackIcon from '@/icons/back.svg?react';
import MiniLoader from '@/components/MiniLoader/MiniLoader';
import { SuggestedValueItem } from '@/components/SellerDocumentsSuggestedValueItem/SellerDocumentsSuggestedValueItem';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  activeRow: InvoiceDocument;
  onConfirm: (
    setIsLoading: (boolean: any) => void,
    id: string,
    code: number,
    onClickCloseModal: () => void
  ) => void;
}

export function ResolvingErrorOptionsModal({
  isModalOpened,
  closeModal,
  activeRow,
  onConfirm,
}: Props) {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [resolveOptionsData, setResolveOptionsData] = useState<
    InvoiceDocumentSuggestionsType[]
  >([]);
  const [suggestedValues, setSuggestedValues] = useState<InvoiceDocument>();
  const [activeCode, setActiveCode] = useState<number>();
  const [isConfirmPartShown, setIsConfirmPartShown] = useState(false);

  const resolveOptions =
    i18n.language === 'de' ? RESOLVE_OPTIONS_DATA_DE : RESOLVE_OPTIONS_DATA_EN;

  const activeId = String(activeRow?.id);

  useEffect(() => {
    if (isModalOpened && activeId) {
      setIsLoading(true);
      getInvoiceDocumentSuggestions(activeId)
        .then((data) => {
          setResolveOptionsData(data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [isModalOpened]);

  const onClickCloseModal = () => {
    setActiveCode(undefined);
    setIsConfirmPartShown(false);
    closeModal();
  };

  const onClickApplyOption = (code: number) => async () => {
    setIsLoading(true);
    setActiveCode(code);
    const res = await getInvoiceDocumentSuggestedValues(activeId, code);
    setSuggestedValues(res);
    setIsConfirmPartShown(true);
    setIsLoading(false);
  };

  const renderOptionItem = (item: InvoiceDocumentSuggestionsType) => {
    const option = resolveOptions.find(
      (v) => v.error_code === item.error_code && v.code === item.code
    );
    if (!option) return null;
    return (
      <div
        key={item.code}
        className='flex items-start justify-between py-3'
        data-testid={`option-item-${item.code}`}
      >
        <div>
          <h5 className='font-semibold'>{option?.title}</h5>
          <div className='text-gray-700'>
            {option.description.map((o, index) => (
              <p key={index}>{o}</p>
            ))}
          </div>
        </div>
        <Button
          disabled={
            isLoading || option.code === undefined || option.code === null
          }
          onClick={onClickApplyOption(option.code)}
        >
          Apply
        </Button>
      </div>
    );
  };

  const renderSuggestionPart = (
    <div className='px-8 pb-6 pt-4'>
      <div className='pb-3'>
        <h4 className='Text pb-1 font-extrabold'>{t('Resolve suggestions')}</h4>
        <p className='pb-2'>
          {t(
            'The VAT number is provided, but the applied VAT rate does not meet the expected criteria (neither zero nor the departure country rate). This mismatch can lead to incorrect VAT calculations'
          )}
        </p>
        <p>
          {t(
            'To correct this error, please choose one of the following options:'
          )}
        </p>
        <div className='divide-y'>
          {resolveOptionsData?.map((option) => renderOptionItem(option))}
        </div>
      </div>
      <div>
        <Button
          styling='outline'
          onClick={onClickCloseModal}
          disabled={isLoading}
        >
          {t('Close')}
        </Button>
      </div>
    </div>
  );

  const previousInvoiceDocumentValue = (key: keyof InvoiceDocument) => {
    if (key === 'net_amount' || key === 'brutto_amount' || key === 'vat_amount')
      return (Number(activeRow[key]) / 100).toFixed(2);

    if (key === 'vat_rate') return Number(activeRow[key]).toFixed(2);

    return String(activeRow[key]);
  };

  const renderConfirmPart = (
    <div className='px-8 pb-6 pt-4'>
      <div>
        <Button
          styling='naked'
          onClick={() => setIsConfirmPartShown(false)}
          className='group mb-2 flex cursor-pointer items-center text-sm !font-semibold transition hover:text-green'
        >
          <BackIcon className='mr-1 [&>circle]:transition-[fill] group-hover:[&>circle]:fill-green' />
          <span>{t('Back')}</span>
        </Button>
        <h4 className='Text font-black'>{t('Confirm changes')}</h4>
        <div>
          {t(
            'We will change some values in this invoice, please confirm these changes:'
          )}
        </div>
        <div className='divide-y'>
          <div className='py-6'>
            <div>
              {suggestedValues
                ? (
                    Object.entries(suggestedValues) as [
                      keyof InvoiceDocument,
                      any,
                    ][]
                  ).map((value) => {
                    return (
                      <SuggestedValueItem
                        key={value[0]}
                        suggestedValue={value}
                        document={activeRow}
                      />
                    );
                  })
                : null}
            </div>
          </div>
          <div className='py-6'>
            <h5 className='pb-2 font-semibold'>
              {t('Values without changes')}
            </h5>
            <div className='grid grid-cols-[repeat(4,1fr)] gap-y-8'>
              {suggestedValues &&
                Object.keys(activeRow)
                  .filter(
                    (key): key is keyof InvoiceDocument =>
                      EDITABLE_INVOICE_DOCUMENT_VALUES.includes(key) &&
                      !(key in suggestedValues)
                  )
                  .map((key) => (
                    <div key={key}>
                      <div className='font-semibold'>
                        {t(EditableInvoiceDocumentValuesMapping[key] || '')}
                      </div>
                      <div>{previousInvoiceDocumentValue(key)}</div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <div className='flex items-center gap-x-4'>
        <Button
          className='flex items-center'
          disabled={
            isLoading || activeCode === undefined || activeCode === null
          }
          onClick={() =>
            onConfirm(setIsLoading, activeId, activeCode!, onClickCloseModal)
          }
        >
          {isLoading && <MiniLoader className='mr-2' color='gray' />}
          <span>{t('Confirm')}</span>
        </Button>
        <div>
          <Button
            styling='outline'
            onClick={onClickCloseModal}
            disabled={isLoading}
          >
            {t('Close')}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      isOpened={isModalOpened}
      close={onClickCloseModal}
      title={t('View resolve suggestions')}
      isLoading={isLoading}
      data-testid={'document-resolve-error-modal'}
    >
      {isLoading ? (
        <ResolveErrorModalSkeleton />
      ) : isConfirmPartShown ? (
        renderConfirmPart
      ) : (
        renderSuggestionPart
      )}
    </Modal>
  );
}
