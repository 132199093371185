import { useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatDate, formatNumber } from '@/core/helpers';
import { useTranslation } from 'react-i18next';
import { Table } from '@/components/Table/Table';
import {
  Country,
  CurrentReporterEnum,
  PagingInfo,
  TurnoverItem,
} from '@/core/types';
import Pagination from '@/components/Pagination/Pagination';
import { getCompletedFiling, getCountries, getOngoingFiling } from '@/core/api';
import { Loader } from '@/components/Loader/Loader';
import { normalizeDateFE } from '@/core/normalization';
import { useQueries, useQuery } from '@tanstack/react-query';
import './InvoiceBook.scss';
import {
  activeCompanySelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';
import { useAppSelector } from '../../hooks';
import CheckIcon from '@/icons/check.svg?react';
import FolderIcon from '@/icons/folder-green-xl.svg?react';
import { InvoiceBookOSSSeSeHeader } from './InvoiceBookOSSSeSeHeader';
import { InvoiceBookHeader } from './InvoiceBookHeader';
import classNames from 'classnames';
import HOMELAND_COUNTRIES from '@/core/data/homeland_countries.json';

export const InvoiceBook = () => {
  const activeCompany = useAppSelector(activeCompanySelector);
  const queryKey = useAppSelector(queryKeySelector);
  const ref = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [counts, setCounts] = useState<PagingInfo | undefined>();
  const [items, setItems] = useState<TurnoverItem[] | undefined>();
  const [paging, setPaging] = useState<PagingInfo | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const currentPage = +(searchParams.get('page') || 1);
  const flow = searchParams.get('flow');

  const isOSSSelfService = flow === CurrentReporterEnum.ossSelfService;

  const { data: countries, isLoading: isLoadingCountries } = useQuery({
    queryKey: ['Countries'],
    queryFn: getCountries,
  });

  const countriesMap = useMemo(() => {
    return (countries || []).reduce(
      (acc: { [key: number]: Country }, item: Country) => {
        acc[item.id] = item;
        return acc;
      },
      {}
    );
  }, [countries]);

  const lang = i18n.language;
  const selectCountryOptions = useMemo(
    () =>
      (countries || [])
        .filter((c) => HOMELAND_COUNTRIES.includes(c.short_name))
        .map((c) => ({
          id: c.id,
          name: t(c.short_name),
        }))
        .sort((a, b) => {
          return a.name.localeCompare(b.name, lang);
        }),
    [lang, countries, t]
  );

  const [ongoingFiling, completedFiling] = useQueries({
    queries: [
      {
        queryKey: ['SellerOngoingFiling', queryKey],
        queryFn: () => getOngoingFiling(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerPastPeriodsFiling', queryKey],
        queryFn: () => getCompletedFiling(activeCompany?.company_id),
      },
    ],
  });

  const onPageClick = (n: number) => {
    n === 1 ? searchParams.delete('page') : searchParams.set('page', String(n));
    setSearchParams(searchParams, {
      replace: true,
    });
  };

  if (
    isLoadingCountries ||
    ongoingFiling.isLoading ||
    completedFiling.isLoading
  )
    return <Loader />;

  return (
    <div
      className={classNames('flex w-full flex-grow flex-col', {
        'bg-gray-300 px-4 py-8 lg:px-6 2xl:px-14': !isOSSSelfService,
      })}
      data-testid='invoice-book'
    >
      <div className='flex flex-grow flex-col rounded bg-white'>
        {isLoading && <Loader />}
        {isOSSSelfService ? (
          <InvoiceBookOSSSeSeHeader
            selectCountryOptions={selectCountryOptions}
            reports={[
              ...(ongoingFiling.data || []),
              ...(completedFiling.data || []),
            ]}
            counts={counts}
            setCounts={setCounts}
            paging={paging}
            isLoading={isLoading}
            setItems={setItems}
            setPaging={setPaging}
            setIsLoading={setIsLoading}
          />
        ) : (
          <InvoiceBookHeader
            selectCountryOptions={selectCountryOptions}
            paging={paging}
            isLoading={isLoading}
            setItems={setItems}
            setPaging={setPaging}
            setIsLoading={setIsLoading}
          />
        )}
        {!!items?.length ? (
          <div className='flex flex-grow flex-col pb-1 pt-2'>
            <div
              className='MiniScrollbar relative mx-auto w-full flex-grow overflow-auto'
              ref={ref}
            >
              <Table<TurnoverItem>
                className={classNames(
                  'absolute left-0 top-0 mx-auto h-full w-fit',
                  {
                    'px-14': isOSSSelfService,
                    'px-6': !isOSSSelfService,
                  }
                )}
                columns={[
                  {
                    label: t('Invoice number'),
                    field: 'invoice_number',
                    renderer: (row) => (
                      <div className='flex h-full items-center'>
                        {row.invoice_number}
                      </div>
                    ),
                  },
                  {
                    label: t('Type'),
                    field: 'invoice_type',
                    renderer: (row) => (
                      <div className='flex h-full items-center'>
                        {row.invoice_type}
                      </div>
                    ),
                  },
                  {
                    label: t('VAT country'),
                    field: 'vat_country_id',
                    renderer: (row) => {
                      const country =
                        row.vat_country_id &&
                        countriesMap[row.vat_country_id]?.short_name
                          ? t(countriesMap[row.vat_country_id].short_name) ||
                            row.vat_country_id
                          : row.vat_country_id;
                      return (
                        <div className='flex h-full items-center'>
                          {country}
                        </div>
                      );
                    },
                  },
                  {
                    label: t('Departure country'),
                    field: 'departure_country_id',
                    renderer: (row) => {
                      const country =
                        row.departure_country_id &&
                        countriesMap[row.departure_country_id]?.short_name
                          ? t(
                              countriesMap[row.departure_country_id].short_name
                            ) || row.departure_country_id
                          : row.departure_country_id;
                      return (
                        <div className='flex h-full items-center'>
                          {country}
                        </div>
                      );
                    },
                  },
                  {
                    label: t('Arrival country'),
                    field: 'arrival_country_id',
                    renderer: (row) => {
                      const country =
                        row.arrival_country_id &&
                        countriesMap[row.arrival_country_id]?.short_name
                          ? t(
                              countriesMap[row.arrival_country_id].short_name
                            ) || row.arrival_country_id
                          : row.arrival_country_id;
                      return (
                        <div className='flex h-full items-center'>
                          {country}
                        </div>
                      );
                    },
                  },
                  {
                    label: t('Is OSS'),
                    field: 'is_oss',
                    renderer: (row) => (
                      <div className='flex h-full items-center'>
                        {row.is_oss ? <CheckIcon /> : ''}
                      </div>
                    ),
                  },
                  {
                    label: t('VAT rate'),
                    field: 'invoice_vat_rate',
                    className: 'justify-end',
                    renderer: (row) => (
                      <div className='flex h-full items-center justify-end'>
                        {row.invoice_vat_rate && `${row.invoice_vat_rate}%`}
                      </div>
                    ),
                  },
                  {
                    label: t('VAT amount'),
                    field: 'invoice_total_vat_amount_eur',
                    className: 'justify-end',
                    renderer: (row) => (
                      <div className='h-full text-right'>
                        {row.invoice_total_vat_amount_eur !== undefined
                          ? formatNumber(
                              row.invoice_total_vat_amount_eur / 100,
                              {
                                currency: 'EUR',
                              },
                              'de'
                            )
                          : null}
                      </div>
                    ),
                  },
                  {
                    label: t('Netto'),
                    field: 'invoice_total_net_amount_eur',
                    className: 'justify-end',
                    renderer: (row) => (
                      <div className='h-full text-right'>
                        {row.invoice_total_net_amount_eur !== undefined
                          ? formatNumber(
                              row.invoice_total_net_amount_eur / 100,
                              {
                                currency: 'EUR',
                              },
                              'de'
                            )
                          : null}
                      </div>
                    ),
                  },
                  {
                    label: t('Brutto'),
                    field: 'invoice_total_brutto_amount_eur',
                    className: 'justify-end',
                    renderer: (row) => (
                      <div className='h-full text-right'>
                        {row.invoice_total_brutto_amount_eur !== undefined
                          ? formatNumber(
                              row.invoice_total_brutto_amount_eur / 100,
                              {
                                currency: 'EUR',
                              },
                              'de'
                            )
                          : null}
                      </div>
                    ),
                  },
                  {
                    label: t('Channel'),
                    field: 'channel_name',
                    renderer: (row) => (
                      <div className='flex h-full items-center'>
                        {row.channel_name}
                      </div>
                    ),
                  },
                  {
                    label: t('Order number'),
                    field: 'foreign_transaction_number',
                    renderer: (row) => (
                      <div className='flex h-full items-center'>
                        {row.foreign_transaction_number}
                      </div>
                    ),
                  },
                  {
                    label: t('Buyer VAT number'),
                    field: 'b2b_buyer_vat_number',
                    renderer: (row) => (
                      <div className='flex h-full items-center'>
                        {row.b2b_buyer_vat_number}
                      </div>
                    ),
                  },
                  {
                    label: t('Document date'),
                    field: 'document_date',
                    renderer: (row) => (
                      <div className='flex h-full items-center'>
                        {row.document_date &&
                          formatDate(
                            normalizeDateFE(row.document_date),
                            'dd.MM.yyyy'
                          )}
                      </div>
                    ),
                  },
                  {
                    label: t('City'),
                    field: 'arrival_state',
                    renderer: (row) => (
                      <div className='flex h-full items-center'>
                        {row.arrival_state}
                      </div>
                    ),
                  },
                  // {
                  //   label: t('Street'),
                  //   field: 'arrival_street',
                  //   renderer: (row) => (
                  //     <div className='flex h-full items-center'>
                  //       {row.arrival_street}
                  //     </div>
                  //   ),
                  // },
                  {
                    label: t('Postcode'),
                    field: 'arrival_postal_code',
                    renderer: (row) => (
                      <div className='flex h-full items-center'>
                        {row.arrival_postal_code}
                      </div>
                    ),
                  },
                ]}
                data={items}
              />
            </div>
            {paging && paging?.total_pages > 1 && (
              <div
                style={{
                  minHeight: 48,
                }}
                className='flex items-center justify-center'
              >
                <Pagination
                  current={currentPage}
                  pages={paging.total_pages}
                  onPageClick={onPageClick}
                />
              </div>
            )}
          </div>
        ) : (
          <div className='mx-auto flex max-w-[570px] flex-grow flex-col items-center justify-center pb-1 pt-2'>
            <FolderIcon />
            <div className='my-2 text-h4 font-extrabold'>
              {t('No data found')}
            </div>
            <div className='flex flex-col whitespace-pre-line text-center'>
              <span>
                {t(`We haven't found data for the selected period or country.`)}
              </span>
              <span>
                {t(
                  'Please select another period or country or make sure that the data has already been provided for the selected one.'
                )}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
