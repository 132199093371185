import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
} from 'react';
import { DataUnit, DataUnitStatsItem, UploadedFile } from '../core/types';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { FormEventHandler, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getS3Client, INVOICE_DATA_BUCKET_NAME } from '../core/s3';
import * as Sentry from '@sentry/browser';
import {
  getDataUnits,
  getDataUnitsStats,
  getUploadedDocumentsFiles,
} from '../core/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  activeCompanySelector,
  queryKeySelector,
  userSelector,
} from '../core/store/user/userSlice';
import useNotification from '../hooks/useNotification';
import { useAppSelector, APIEventsEnum, useSendEvent } from '../hooks';
import useErrorsNotificationModal from '@/pages/Seller/SellerDocuments/useErrorsNotificationModal';
import { isUploadedDocumentsFileProgressMessage } from '@/core/ws/ws-messages';
import { useWSMessageHandler } from '@/core/ws/useWSMessageHandler';
import { useNPSModal } from '@/components/NPSModal/useNPSModal';
import { useLocation } from 'react-router-dom';
import { AuthedRoutesEnum, ManualUploadFileStatusEnum } from '@/core/enums';
import { getFileExtension, sleep, validateFileMimeType } from '@/core/helpers';
import { ACCEPTED_EXTS } from '@/components/ManualDataUploader/ManualUploader.helpers';

const PROCESSING_NOTIFICATION_ID = 'processing_file_';

const useManualUpload = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { openModal } = useNPSModal();
  const { showNotification, deleteNotification } = useNotification();

  const user = useAppSelector(userSelector);
  const activeCompany = useAppSelector(activeCompanySelector);
  const queryKey = useAppSelector(queryKeySelector);

  const [isUploading, setIsUploading] = useState<{
    [key: string]: boolean;
  }>({});

  const [filesToUpload, setFilesToUpload] = useState<{ [key: string]: File[] }>(
    {}
  );

  const controller = useRef(new AbortController());

  const { data: uploadedFiles, isLoading } = useQuery<UploadedFile[]>({
    queryKey: ['SellerManualUploadFiles', queryKey],
    queryFn: () => getUploadedDocumentsFiles(activeCompany?.company_id!),
  });

  const { data: dataUnits, isLoading: isLoadingDataUnits } = useQuery({
    queryKey: ['SellerDataUnits', queryKey],
    queryFn: () => getDataUnits(activeCompany?.company_id!),
  });

  const {
    data: dataUnitsStats,
    isLoading: isLoadingDataUntisStats,
    isRefetching: isRefetchingDataUnitsStats,
  } = useQuery({
    queryKey: ['SellerDataUnitsStats', queryKey],
    queryFn: () => getDataUnitsStats(activeCompany?.company_id),
  });

  const s3ClientRef = useRef<S3Client | null>(null);
  const uploadErrorRef = useRef<{
    [key: string]: boolean;
  }>({});

  const {
    openModal: openErrorModal,
    isModalOpened: isErrorModalOpened,
    closeModal: closeErrorModal,
    files,
  } = useErrorsNotificationModal();

  const { sendEvent } = useSendEvent();

  const activeCompanyId = activeCompany?.company_id;

  const { pathname } = location;
  const isNewProcess = activeCompany?.IsUseNewProcess;

  useWSMessageHandler(
    (msg) => {
      if (
        isUploadedDocumentsFileProgressMessage(msg) &&
        isNewProcess === true
      ) {
        const companyId = msg.data.companyId;

        if (msg.data.status === 'finished' || msg.data.status === 'created') {
          Promise.all([
            getDataUnits(companyId),
            getUploadedDocumentsFiles(companyId),
            getDataUnitsStats(companyId),
          ])
            .then(([DURes, filesRes, DUStatsRes]) => {
              const file = filesRes.find((f) => f.id === msg.data.fileId);

              if (
                msg.data.type === 'upload-data' &&
                msg.data.status === 'finished' &&
                file
              ) {
                setIsUploading((prev) => ({
                  ...prev,
                  [companyId]: false,
                }));

                setFilesToUpload((prev) => ({
                  ...prev,
                  [companyId]: [],
                }));

                if (!!file.errors.length) {
                  if (activeCompanyId === companyId) {
                    openErrorModal([file]);
                  }
                } else if (
                  msg.data.isCorrectionValid === false ||
                  msg.data.isValid === false
                ) {
                  showNotification({
                    text: 'File not accepted',
                    type: 'error',
                  });
                } else {
                  showNotification({
                    text: 'The file has been accepted',
                    type: 'success',
                  });
                }
              }

              if (
                msg.data.type === 'refresh-defined-fields' &&
                msg.data.status === 'created' &&
                file
              ) {
                showNotification({
                  text: 'Processing file. Please wait',
                  type: 'loading',
                  state: 'open',
                  id: PROCESSING_NOTIFICATION_ID + msg.data.fileId,
                });
              }

              if (
                msg.data.type === 'refresh-defined-fields' &&
                msg.data.status === 'finished' &&
                file
              ) {
                deleteNotification(
                  PROCESSING_NOTIFICATION_ID + msg.data.fileId
                );

                showNotification({
                  text: 'Turnover data has been uploaded',
                  type: 'success',
                });

                if (
                  pathname !== AuthedRoutesEnum.Documents &&
                  !file.errors.length &&
                  file.uploaded_by_client
                ) {
                  const apiEvent = APIEventsEnum.manual_upload_file_success;
                  openModal(apiEvent);
                  sendEvent(apiEvent, {
                    block: 'Manual file uploading is successful',
                  });
                }
              }

              queryClient.setQueryData(['SellerDataUnits', queryKey], DURes);
              queryClient.setQueryData(
                ['SellerManualUploadFiles', queryKey],
                filesRes
              );
              queryClient.setQueryData(
                ['SellerDataUnitsStats', queryKey],
                DUStatsRes
              );
            })
            .catch(() => {
              setIsUploading((prev) => ({
                ...prev,
                [companyId]: false,
              }));
            });
        }
      }
    },
    // eslint-disable-next-line
    [activeCompanyId, pathname, isNewProcess, openModal]
  );

  const pollFiles = useCallback(
    async (
      company_id: string,
      controller: AbortController,
      cb: (
        files: UploadedFile[],
        units?: DataUnit[],
        dataUnitsStats?: DataUnitStatsItem[]
      ) => void,
      count: number = 1
    ) => {
      try {
        const [filesRes, unitsRes, DUStatsRes] = await Promise.all([
          getUploadedDocumentsFiles(company_id, controller),
          getDataUnits(company_id, controller),
          getDataUnitsStats(company_id),
        ]);

        if (
          filesRes.some((f) => f.status === ManualUploadFileStatusEnum.CREATED)
        ) {
          await sleep(5000);
          pollFiles(company_id, controller, cb);
        } else {
          cb(filesRes, unitsRes, DUStatsRes);
        }
      } catch {
        if (count < 3) {
          await sleep(5000);
          try {
            pollFiles(company_id, controller, cb, ++count);
          } catch (err) {
            console.error(err);
          }
        }
      }
    },
    []
  );

  const canShowNPSModal = (files: UploadedFile[]) => {
    const lastFile = files.sort(
      (a, b) =>
        new Date(b.imported_at).getTime() - new Date(a.imported_at).getTime()
    )[0];
    return !lastFile?.errors?.length;
  };

  useEffect(() => {
    if (!!uploadedFiles?.length && isNewProcess === false) {
      controller.current.abort();
      controller.current = new AbortController();
      if (
        uploadedFiles.some(
          (f) => f.status === ManualUploadFileStatusEnum.CREATED
        )
      ) {
        pollFiles(
          activeCompany?.company_id!,
          controller.current,
          (
            files: UploadedFile[],
            units?: DataUnit[],
            DUStats?: DataUnitStatsItem[]
          ) => {
            queryClient.setQueryData(
              ['SellerManualUploadFiles', queryKey],
              files
            );

            if (units) {
              queryClient.setQueryData(['SellerDataUnits', queryKey], units);
            }

            if (DUStats) {
              queryClient.setQueryData(
                ['SellerDataUnitsStats', queryKey],
                DUStats
              );
            }

            setFilesToUpload((prev) => ({
              ...prev,
              [activeCompany?.company_id!]: (
                prev[activeCompany?.company_id!] || []
              ).filter((f) => !files.some((file) => f.name === file.name)),
            }));

            if (
              files.every(
                (f) => f.status !== ManualUploadFileStatusEnum.CREATED
              )
            ) {
              setIsUploading((prev) => ({
                ...prev,
                [activeCompany?.company_id!]: false,
              }));

              showNotification({
                type: 'success',
                text: 'Manual turnover data has been processed',
              });

              const apiEvent = APIEventsEnum.manual_upload_file_success;

              if (canShowNPSModal(files)) {
                openModal(apiEvent);
              }

              sendEvent(apiEvent, {
                block: 'Manual file uploading is successful',
              });
            }
          }
        );
      }
    }

    // eslint-disable-next-line
  }, [activeCompanyId, uploadedFiles, pollFiles, isNewProcess]);

  const prepareFilesForUploading = (files: File[]) =>
    files.map((f) => {
      const blob = f.slice(0, f.size, f.type);
      const nameParts = f.name.split('.');

      return new File(
        [blob],
        `${nameParts.slice(0, -1).join('.')}_${Date.now()}.${
          nameParts[nameParts.length - 1]
        }`,
        { type: f.type }
      );
    });

  const sendFilesOnSelect = async (files: File[] | null) => {
    if (!files) {
      return;
    }

    sendFiles(prepareFilesForUploading(files));
  };

  const onSelectFiles = (files: File[] | null) => {
    if (!files) {
      return;
    }

    for (const file of files) {
      const ext = getFileExtension(file);
      let valid = true;
      if (ext) {
        valid = ACCEPTED_EXTS.includes(ext);
      } else {
        valid = validateFileMimeType(file);
      }

      if (!valid) {
        return showNotification({
          text: `${t('Wrong file format')}. ${t(
            'You can upload CSV, XLS, XLSX file.'
          )}`,
          type: 'error',
        });
      }
    }

    setFilesToUpload((prev) => {
      return {
        ...prev,
        [activeCompany?.company_id!]: [
          ...prepareFilesForUploading(files),
          ...(prev[activeCompany?.company_id!] || []).filter((f) => {
            const fileToReplace = files.find(
              (newFile) => newFile.name === f.name
            );
            if (fileToReplace) {
              return f.name !== fileToReplace.name;
            }
            return true;
          }),
        ],
      };
    });
  };

  const uploadFile = async (file: File) => {
    try {
      await s3ClientRef.current!.send(
        new PutObjectCommand({
          Bucket: INVOICE_DATA_BUCKET_NAME,
          Key: `${activeCompany?.company_id}/${file.name}`,
          Body: file,
          Metadata: {
            userId: user!.Id,
            external_user_uuid: user?.AmazonCognitoId!,
          },
        })
      );
    } catch (error) {
      uploadErrorRef.current = {
        ...uploadErrorRef.current,
        [activeCompany?.company_id!]: true,
      };
      Sentry.captureException(error);

      showNotification({
        text: t('Error has occurred while uploading file *filename*').replace(
          '*filename*',
          file.name
        ),
        type: 'error',
      });
    }
  };

  const onSuccessSubmit = (files: UploadedFile[]) => {
    if (files.every((f) => f.status !== ManualUploadFileStatusEnum.CREATED)) {
      setFilesToUpload((prev) => ({
        ...prev,
        [activeCompany?.company_id!]: [],
      }));

      setIsUploading((prev) => ({
        ...prev,
        [activeCompany?.company_id!]: false,
      }));

      showNotification({
        type: 'success',
        text: 'Manual turnover data has been processed',
      });

      const apiEvent = APIEventsEnum.manual_upload_file_success;

      if (canShowNPSModal(files)) {
        openModal(apiEvent);
      }

      sendEvent(apiEvent, {
        block: 'Manual file uploading is successful',
      });
    }
  };

  const sendFiles = isNewProcess
    ? async (filesFromArg?: File[]) => {
        setIsUploading((prev) => ({
          ...prev,
          [activeCompany?.company_id!]: true,
        }));
        uploadErrorRef.current = {
          ...uploadErrorRef.current,
          [activeCompany?.company_id!]: false,
        };

        try {
          s3ClientRef.current = await getS3Client();
          await Promise.all(
            (filesFromArg
              ? filesFromArg
              : filesToUpload[activeCompany?.company_id!] || []
            ).map((f) => uploadFile(f))
          );
          s3ClientRef.current.destroy();
        } catch (err) {
          setIsUploading((prev) => ({
            ...prev,
            [activeCompany?.company_id!]: false,
          }));

          sendEvent(APIEventsEnum.manual_upload_file_fail, {
            block: 'Manual file uploading is failed',
          });
        }
      }
    : async (filesFromArg?: File[]) => {
        setIsUploading((prev) => ({
          ...prev,
          [activeCompany?.company_id!]: true,
        }));
        uploadErrorRef.current = {
          ...uploadErrorRef.current,
          [activeCompany?.company_id!]: false,
        };

        try {
          s3ClientRef.current = await getS3Client();
          await Promise.all(
            (filesFromArg
              ? filesFromArg
              : filesToUpload[activeCompany?.company_id!] || []
            ).map((f) => uploadFile(f))
          );
          s3ClientRef.current.destroy();

          if (!uploadErrorRef.current[activeCompany?.company_id!]) {
            setTimeout(async () => {
              try {
                let files: UploadedFile[] = [];

                const [filesRes, unitsRes, DUStatsRes] = await Promise.all([
                  getUploadedDocumentsFiles(activeCompany?.company_id!),
                  getDataUnits(activeCompany?.company_id!),
                  getDataUnitsStats(activeCompany?.company_id!),
                ]);

                queryClient.setQueryData<DataUnit[]>(
                  ['SellerDataUnits', queryKey],
                  unitsRes
                );

                queryClient.setQueryData<DataUnitStatsItem[]>(
                  ['SellerDataUnitsStats', queryKey],
                  DUStatsRes
                );

                files = filesRes;

                queryClient.setQueryData<UploadedFile[]>(
                  ['SellerManualUploadFiles', queryKey],
                  files
                );

                onSuccessSubmit(files);
              } catch (error) {
                console.error(error);
              }
            }, 5000);
          }
        } catch (err) {
          setIsUploading((prev) => ({
            ...prev,
            [activeCompany?.company_id!]: false,
          }));

          sendEvent(APIEventsEnum.manual_upload_file_fail, {
            block: 'Manual file uploading is failed',
          });
        }
      };

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (isErrorModalOpened && !!files.length) {
      closeErrorModal();
    }
    await sendFiles();
  };

  const onDeleteFile = (name: string) => () => {
    setFilesToUpload((prev) => ({
      ...prev,
      [activeCompany?.company_id!]: (
        prev[activeCompany?.company_id!] || []
      ).filter((f) => f.name !== name),
    }));
  };

  return {
    filesToUpload: filesToUpload[activeCompany?.company_id!] || [],
    onSelectFiles,
    onDeleteFile,
    onSubmit,
    isLoading,
    controller: controller.current,
    uploadedFiles: uploadedFiles || [],
    isUploading: isUploading[activeCompany?.company_id!],
    isLoadingDataUnits,
    dataUnits,
    sendFilesOnSelect,
    isLoadingDataUntisStats,
    dataUnitsStats,
    isRefetchingDataUnitsStats,
  };
};

export const MUContext = createContext<{
  onSubmitFiles: React.FormEventHandler<HTMLFormElement>;
  onSelectFiles: (files: File[] | null) => void;
  onDeleteFileToUpload: (name: string) => () => void;
  filesToUpload: File[];
  isLoadingUploadedFiles: boolean;
  isLoadingDataUnits: boolean;
  isUploadingFiles: boolean;
  uploadedFiles: UploadedFile[];
  dataUnits: DataUnit[] | undefined;
  sendFilesOnSelect: (files: File[] | null) => void;
  isLoadingDataUntisStats: boolean;
  dataUnitsStats: DataUnitStatsItem[] | undefined;
  isRefetchingDataUnitsStats: boolean;
}>({
  onSubmitFiles: () => {},
  onSelectFiles: () => {},
  sendFilesOnSelect: () => {},
  onDeleteFileToUpload: () => () => {},
  filesToUpload: [],
  uploadedFiles: [],
  dataUnits: [],
  isLoadingUploadedFiles: false,
  isLoadingDataUnits: false,
  isUploadingFiles: false,
  isLoadingDataUntisStats: false,
  dataUnitsStats: [],
  isRefetchingDataUnitsStats: false,
});

export function MUProvider({ children }: PropsWithChildren<{}>) {
  const {
    filesToUpload,
    onSubmit: onSubmitFiles,
    onSelectFiles,
    onDeleteFile: onDeleteFileToUpload,
    isLoading: isLoadingUploadedFiles,
    isLoadingDataUnits,
    isUploading: isUploadingFiles,
    uploadedFiles,
    dataUnits,
    sendFilesOnSelect,
    isLoadingDataUntisStats,
    dataUnitsStats,
    isRefetchingDataUnitsStats,
  } = useManualUpload();

  return (
    <MUContext.Provider
      value={{
        onSubmitFiles,
        onSelectFiles,
        onDeleteFileToUpload,
        filesToUpload,
        isLoadingUploadedFiles,
        isUploadingFiles,
        uploadedFiles,
        dataUnits,
        isLoadingDataUnits,
        sendFilesOnSelect,
        isLoadingDataUntisStats,
        dataUnitsStats,
        isRefetchingDataUnitsStats,
      }}
    >
      {children}
    </MUContext.Provider>
  );
}
