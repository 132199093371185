import { HTMLAttributes, PropsWithChildren } from 'react';
import './PageLayout.scss';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  rightColumn?: JSX.Element;
  className?: string;
  top?: JSX.Element;
}

const PageLayout = ({
  children,
  rightColumn,
  className = '',
  top,
  ...rest
}: PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>) => {
  const { vs4972NewYear2024 } = useFlags();
  return (
    <div
      className={`${
        vs4972NewYear2024 && 'relative'
      } w-full bg-gray-300 px-4 py-8 lg:px-6 2xl:px-14 ${className}`}
      {...rest}
    >
      <div className='PageLayout__container mx-auto w-full max-w-[1328px]'>
        {top}
        {rightColumn ? (
          <div className='PageLayout__row flex flex-wrap gap-x-6 xl:flex-nowrap'>
            <div className='PageLayout__leftColumn !flex-grow xl:w-8/12'>
              {children}
            </div>
            <div className='PageLayout__rightColumn xl:w-4/12'>
              {rightColumn}
            </div>
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default PageLayout;
