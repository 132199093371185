import { useTranslation } from 'react-i18next';
import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import Checkbox from '@/components/Checkbox/Checkbox';
import { useEffect, useState } from 'react';
import { SellerDocumentsDisclaimer } from '@/components/SellerDocumentsDisclaimer/SellerDocumentsDisclaimer';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  healthchecks: number | undefined;
  plausibilityErrors: number | undefined;
  onGenerate: (setIsLoading: (v: boolean) => void, filter: string) => void;
}

export default function ExportFileConfirmModal({
  isModalOpened,
  closeModal,
  healthchecks,
  plausibilityErrors,
  onGenerate,
}: Props) {
  const { t } = useTranslation();
  const [withPlausible, setWithPlausible] = useState(false);

  useEffect(() => {
    if (isModalOpened) {
      setWithPlausible(false);
    }
  }, [isModalOpened]);

  return (
    <ConfirmModal
      isModalOpened={isModalOpened}
      closeModal={closeModal}
      title={t('List of problems')}
      confirm={(setIsLoading) =>
        onGenerate(
          setIsLoading,
          (!!healthchecks ? ['healthcheck_errors'] : [])
            .concat(withPlausible ? ['plausible_errors'] : [])
            .join(',')
        )
      }
      isDisabled={!healthchecks && !withPlausible}
      confirmBtnText='Generate and export'
    >
      <>
        <div className='Title--sm mb-2 font-black'>
          {t('Download documents')}
        </div>
        <p className='mb-2'>
          {t(
            `You'll be notified when the file's ready, possibly within 15 mins`
          )}
        </p>
        <SellerDocumentsDisclaimer className='mb-4 mt-2 bg-gray-400' />
        <h5 className='font-semibold'>{t('What to include')}</h5>
        <p>{t('Please choose which issues to export')}</p>
        <p className='mb-2'>
          {t(
            'Errors must be addressed, as they will not be included in the filing otherwise, necessitating future corrections'
          )}
        </p>
        {!!healthchecks && (
          <div className='mb-4 flex items-start'>
            <Checkbox
              className='flex'
              checked={!!healthchecks}
              onChange={() => {}}
              disabled
            />
            <span>{t('Errors')}:</span> <span>{healthchecks}</span>
          </div>
        )}
        {!!plausibilityErrors && (
          <div className='mb-4 flex items-start'>
            <Checkbox
              className='flex'
              checked={withPlausible}
              onChange={() => setWithPlausible((prev) => !prev)}
            />
            <span>{t('Notices')}:</span> <span>{plausibilityErrors}</span>
          </div>
        )}
      </>
    </ConfirmModal>
  );
}
