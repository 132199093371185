import EnIcon from '@/icons/en.svg?react';
import DeIcon from '@/icons/de.svg?react';
import { MouseEventHandler } from 'react';
import classNames from 'classnames';
import './LangDropdown.scss';
import { useTranslation } from 'react-i18next';
import { Lang } from '@/core/types';
import HoverDropdown from '../HoverDropdown/HoverDropdown';
import Button from '../Button';

interface Props {
  className?: string;
}

const icons = {
  en: <EnIcon />,
  de: <DeIcon />,
};

const langs: Lang[] = ['de', 'en'];

const LangDropdown = ({ className = '' }: Props) => {
  const { i18n } = useTranslation();

  const onLangChange: (l: Lang) => MouseEventHandler<HTMLButtonElement> =
    (l: Lang) => (e) => {
      if (l === i18n.language) {
        e.preventDefault();
        return;
      }
      i18n.changeLanguage(l);
    };

  return (
    <HoverDropdown
      className='HoverDropdown--LangDropdown'
      arrow={false}
      trigger={
        <div
          className={`flex items-center justify-center px-0 uppercase ${className} LangDropdown__trigger`}
          tabIndex={-1}
          data-testid='lang-dropdown'
        >
          {icons[i18n.language as 'en' | 'de']}
        </div>
      }
    >
      <div className='LangDropdown__menu flex flex-col items-center justify-center overflow-hidden text-center'>
        {langs.map((l) => (
          <Button
            data-testid={`lang-btn-${l}`}
            styling='naked'
            key={l}
            onClick={onLangChange(l)}
            className={classNames(
              'LangDropdown__item !flex !w-full items-center justify-center !py-2',
              {
                'LangDropdown__item--active': l === i18n.language,
              }
            )}
          >
            {icons[l]}
          </Button>
        ))}
      </div>
    </HoverDropdown>
  );
};

export default LangDropdown;
