import { ChangeEventHandler, forwardRef } from 'react';
import Input from '../Input/Input';
import SearchIcon from '@/icons/search.svg?react';
import CrossIcon from '@/icons/close.svg?react';
import './SearchInput.scss';

interface Props {
  onChange: (v: string) => void;
  value: string;
  className?: string;
  placeholder: string;
  name?: string;
}

export const SearchInput = forwardRef(
  ({ onChange, value, name, className = '', placeholder }: Props, ref) => {
    const onCrossClick = () => {
      onChange('');
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange(e.target.value);
    };

    return (
      <div className={`SearchInput relative ${className}`}>
        <div className='SearchInput__btn SearchInput__searchBtn Button--naked absolute'>
          <SearchIcon />
        </div>
        <Input
          ref={ref}
          name={name}
          onChange={handleChange}
          value={value}
          wrapperClassName='Input--SearchInput'
          placeholder={placeholder}
        />
        {value && (
          <div
            className='SearchInput__btn SearchInput__crossBtn Button--naked absolute'
            onClick={onCrossClick}
          >
            <CrossIcon />
          </div>
        )}
      </div>
    );
  }
);
