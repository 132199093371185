import classNames from 'classnames';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './Dropdown.scss';
import useDropdown from './useDropdown';
import { BtnStyling } from '../Button';

interface Props {
  trigger: JSX.Element;
  content: JSX.Element | ((toggleDropdown: () => void) => JSX.Element);
  className?: string;
  direction?: 'up' | 'down';
  horizontalAlignment?: 'left' | 'right';
  isDisabled?: boolean;
  triggerButton?: 'left' | 'right';
  onOpen?: () => void;
  styling?: BtnStyling;
}

const Dropdown = ({
  trigger,
  content,
  className = '',
  direction = 'down',
  isDisabled,
  triggerButton = 'left',
  onOpen,
  horizontalAlignment = 'right',
  styling,
  ...rest
}: Props) => {
  const [currentDirection, setCurrentDirection] = useState(direction);
  const ref = useRef(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const { toggleDropdown, isDropdownOpened, isOpened } = useDropdown(
    ref,
    isDisabled
  );

  useLayoutEffect(() => {
    if (isDropdownOpened && menuRef.current) {
      const rect = menuRef.current.getBoundingClientRect();

      if (
        rect.bottom >
        (window.innerHeight || document.documentElement.clientHeight)
      ) {
        setCurrentDirection('up');
      }
      if (rect.top < 0) {
        setCurrentDirection('down');
      }

      document.body.style.overflow = '';
    } else {
      setCurrentDirection(direction);
    }
    // eslint-disable-next-line
  }, [isDropdownOpened]);

  useLayoutEffect(() => {
    if (direction === 'down' && menuRef.current && currentDirection === 'up') {
      if (menuRef.current.getBoundingClientRect().top < 0) {
        setCurrentDirection('down');
      }
    }
    // eslint-disable-next-line
  }, [currentDirection]);

  useEffect(() => {
    if (isDropdownOpened && onOpen) onOpen();
  }, [isDropdownOpened, onOpen]);

  return (
    <div className={`relative ${className}`} ref={ref}>
      <button
        {...rest}
        type='button'
        onClick={triggerButton === 'left' ? toggleDropdown : undefined}
        onContextMenu={
          triggerButton === 'right'
            ? (e) => {
                e.preventDefault();
                toggleDropdown();
              }
            : undefined
        }
        className={classNames('Dropdown__trigger', {
          'Dropdown__trigger--active outline': isOpened,
          'Dropdown__trigger--disabled': isDisabled,
          'Dropdown__trigger--up': currentDirection === 'up',
          'Dropdown__trigger--down': currentDirection === 'down',
          '!min-w-[90px] !border-none !p-0': styling === 'naked',
          '!outline-none': styling === 'naked' && isOpened,
        })}
        disabled={isDisabled}
      >
        {trigger}
      </button>
      {isDropdownOpened && (
        <div
          className={classNames(
            'Dropdown__menu animation-forwards absolute py-2',
            `Dropdown__menu--${currentDirection}`,
            `Dropdown__menu--${horizontalAlignment}`,
            {
              fadeIn: isOpened,
              fadeOut: !isOpened,
              'mt-1': currentDirection !== 'up',
              'mb-1': currentDirection === 'up',
            }
          )}
          onClick={toggleDropdown}
          ref={menuRef}
        >
          {content instanceof Function ? content(toggleDropdown) : content}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
