import classNames from 'classnames';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import Input from '@/components/Input/Input';
import { isEmail } from '@/core/helpers';
import { ChangeEventHandler, useState } from 'react';
import { Loader } from '@/components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import Header from '@/components/Header/Header';
import { Link } from 'react-router-dom';
import EmailIcon from '@/icons/forgot-password-email.svg?react';
import { forgotPassword } from '@/core/auth';
import Button from '@/components/Button';
import { DEFAULT_ERROR } from '@/components/Notifications/Notification';

interface FormValues {
  email: string;
}

const ForgotPassword = () => {
  const { t } = useTranslation();

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      email: '',
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.email) {
        errors.email = t('Required')!;
      }
      if (values.email !== '' && !isEmail(values.email)) {
        errors.email = t(`Email is not valid`)!;
      }

      return errors;
    },
    onSubmit: ({ email }) => {
      if (error) setError('');
      setIsLoading(true);
      forgotPassword({ email })
        .then(() => {
          setIsSent(true);
        })
        .catch((e) => {
          if (e.__type === 'UserNotFoundException') {
            setError('User not found');
          } else {
            setError(DEFAULT_ERROR);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  const onChangeField: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (error) setError('');
    formik.setFieldValue(
      e.target.name,
      e.target.value,
      formik.touched[e.target.name as keyof FormikTouched<FormValues>]
    );
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <Header />
      <div
        className='Page--Login flex items-center justify-center bg-gray-300 px-4 py-8 lg:px-6 2xl:px-14'
        data-testid='forgot-password'
      >
        <div className='Login__container lg:mb-12 lg:pb-12'>
          {isSent ? (
            <div className='text-center'>
              <EmailIcon />
              <div className='Title--lg mb-4 mt-6 font-black lg:pt-2 lg:text-center'>
                {t('Check your email')}
              </div>
              <div className='Text mb-4'>
                {t(
                  'We have emailed you at *email* the link and verification code to reset your password'
                ).replace('*email*', formik.values.email)}
              </div>
              <div className='Text text-center'>
                <Link
                  to='/'
                  className='text-blue-500 transition-colors hover:text-blue-600'
                >
                  {t('Return to login')}
                </Link>
              </div>
            </div>
          ) : (
            <>
              <div className='Title--lg mb-4 font-black lg:text-center'>
                {t('Reset your password')}
              </div>
              <div
                className='Text mx-auto mb-4 text-center'
                style={{
                  maxWidth: 400,
                }}
              >
                {t(
                  'We’ll email you instructions to reset your password. If you still have problems with logging in, contact your manager.'
                )}
              </div>
              <form
                onSubmit={formik.handleSubmit}
                noValidate
                className='w-full'
              >
                <div className={classNames('Login__content items-start')}>
                  <Input
                    label='Email'
                    placeholder='Enter your email'
                    name='email'
                    id='email'
                    onChange={onChangeField}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    error={formik.errors.email}
                    touched={formik.touched.email}
                  />
                </div>
                <div>
                  <Button
                    className='Login__btn mt-4 !w-full'
                    type='submit'
                    disabled={isLoading}
                  >
                    {t('Reset password')}
                  </Button>
                  {error && (
                    <div className='Login__error text-center'>{t(error)}</div>
                  )}
                </div>
              </form>
              <div className='Text mt-4 text-center lg:pt-2'>
                <Link
                  to='/'
                  className='text-blue-500 transition-colors hover:text-blue-600'
                >
                  {t('Return to login')}
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
