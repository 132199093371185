import { TableGridColumn } from '@/components/TableGrid/TableGrid';
import { NormalizedFCTransfer } from './SetupCheck';
import { useTranslation } from 'react-i18next';
import { formatNumber, pushEventToDataLayer } from '@/core/helpers';
import InfoIcon from '@/icons/info-outline.svg?react';
import { GTMEvents } from '@/core/constants';
import Button from '@/components/Button';

interface Props {
  row: NormalizedFCTransfer;
  columns: TableGridColumn<NormalizedFCTransfer>[];
  gridTemplateColumns: string;
  i: number;
  openModal: () => void;
  homeCountry: string | undefined;
}

export default function FCTransfersRow({
  row,
  columns,
  gridTemplateColumns,
  i,
  openModal,
  homeCountry,
}: Props) {
  const { t } = useTranslation();
  return (
    <div
      data-index={i}
      data-testid={`row-${i}`}
      className='TableGrid__row pb-4 pt-2'
    >
      <div className='grid' style={{ gridTemplateColumns }}>
        {columns.map((c) => (
          <div
            key={c.field as string}
            className='TableGrid__cell'
            data-testid={`row-${i}-${c.field}`}
          >
            <>{c.formatter ? c.formatter(row) : row[c.field]}</>
          </div>
        ))}
      </div>
      {homeCountry === row.country && (
        <div
          className='grid'
          style={{
            gridTemplateColumns: `minmax(64px, 160px) minmax(64px, 2fr)`,
          }}
        >
          <div className='TableGrid__cell'></div>
          <div className='TableGrid__cell'>
            <div className=''>
              <span>{t('All')}</span>&nbsp;
              <Button
                styling='naked-blue'
                onClick={() => {
                  pushEventToDataLayer(
                    GTMEvents.setup_check_fc_transfers_transactions_click
                  );
                  openModal();
                }}
              >
                <span>
                  {formatNumber(row.arrivals.total + row.departures.total)}{' '}
                </span>
                <span>{t('transactions')}</span>
              </Button>
              &nbsp;
              <InfoIcon
                className='SetupCheck__info'
                style={{
                  marginTop: -2,
                }}
              />
              &nbsp;
              {t(
                'must be included into local *country* filing by tax advisor'
              ).replace('*country*', row.country)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
