import copy from 'copy-to-clipboard';
import CopyIcon from '@/icons/copy.svg?react';
import useNotification from '@/hooks/useNotification';
import { useTranslation } from 'react-i18next';

export const CopyToClipboard = ({ text }: { text: string }) => {
  const { i18n } = useTranslation();
  const { showNotification } = useNotification();
  return (
    <div className='group flex w-fit gap-x-1'>
      <div>{text}</div>
      <div
        className='opacity-0 transition-opacity delay-200 duration-200 group-hover:cursor-pointer group-hover:opacity-100'
        onClick={() => {
          copy(text);
          showNotification({
            type: 'success',
            text: i18n.language === 'en' ? 'Copied' : 'Kopiert',
          });
        }}
      >
        <CopyIcon className='!h-6 !w-6' />
      </div>
    </div>
  );
};
