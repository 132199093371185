import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AmazonIntegration } from '@/core/types';
import Button from '../../Button';
import AmazonIntegrationsItem from './AmazonIntegrationsItem';
import classNames from 'classnames';
import './AmazonIntegrations.scss';
import InvalidIntegrationIcon from '@/icons/integration-invalid.svg?react';

interface Props {
  items: AmazonIntegration[];
  onClickUpdate: (id: string) => () => void;
}

export default function AmazonIntegrations({ items, onClickUpdate }: Props) {
  const [isOpenedValid, setIsOpenedValid] = useState(false);
  const [isOpenedInvalid, setIsOpenedInvalid] = useState(false);
  const { t } = useTranslation();

  const toggleCollapse = (type: 'valid' | 'invalid') => () => {
    type === 'valid'
      ? setIsOpenedValid((s) => !s)
      : setIsOpenedInvalid((s) => !s);
  };

  const validAccounts = items.filter((a) => !a.invalid);
  const invalidAccounts = items.filter((a) => a.invalid);

  const validContent = (
    <div>
      {isOpenedValid
        ? validAccounts.map((item, n) => (
            <AmazonIntegrationsItem
              item={item}
              key={n}
              onClickUpdate={onClickUpdate(item.seller_id)}
            />
          ))
        : validAccounts
            .slice(0, 3)
            .map((item, n) => (
              <AmazonIntegrationsItem
                item={item}
                key={n}
                onClickUpdate={onClickUpdate(item.seller_id)}
              />
            ))}
    </div>
  );

  const invalidContent = (
    <div>
      {isOpenedInvalid
        ? invalidAccounts.map((item, n) => (
            <AmazonIntegrationsItem
              item={item}
              key={n}
              onClickUpdate={onClickUpdate(item.seller_id)}
            />
          ))
        : invalidAccounts
            .slice(0, 3)
            .map((item, n) => (
              <AmazonIntegrationsItem
                item={item}
                key={n}
                onClickUpdate={onClickUpdate(item.seller_id)}
              />
            ))}
    </div>
  );

  if (!items.length) return null;

  return (
    <>
      {!!validAccounts.length && (
        <div className='AmazonIntegrations__validContainer'>{validContent}</div>
      )}
      {validAccounts.length > 3 && (
        <Button
          styling='outline'
          className='mb-2 !w-full'
          onClick={toggleCollapse('valid')}
          size='sm'
        >
          {isOpenedValid
            ? t('Hide')
            : t('*n* more').replace('*n*', String(validAccounts.length - 3))}
        </Button>
      )}
      {!!invalidAccounts.length && (
        <div className='mt-2'>
          <div
            className={classNames(
              'AmazonIntegrations__content overflow-hidden'
            )}
          >
            {invalidContent}
          </div>
          {invalidAccounts.length > 3 && (
            <Button
              size='sm'
              styling='outline'
              className={classNames('!w-full', {
                'AmazonIntegrations__invalidBtn Text--red flex items-center justify-center':
                  !isOpenedInvalid,
              })}
              onClick={toggleCollapse('invalid')}
              data-testid='amazon-show-invalid-btn'
            >
              {isOpenedInvalid ? (
                t('Hide')
              ) : (
                <>
                  <InvalidIntegrationIcon className='AmazonIntegrations__icon mr-1' />
                  <span>
                    {t('*n* more').replace(
                      '*n*',
                      String(invalidAccounts.length - 3)
                    )}
                  </span>
                </>
              )}
            </Button>
          )}
        </div>
      )}
    </>
  );
}
