import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

interface FeedbackContextType {
  isFeedbackSent: FeedbackSentState;
  setIsFeedbackSent: Dispatch<SetStateAction<FeedbackSentState>>;
}

export const FeedbackContext = createContext<FeedbackContextType>(
  {} as FeedbackContextType
);
interface FeedbackSentState {
  [key: string]: boolean;
}

export function FeedbackProvider({ children }: { children: ReactNode }) {
  const [isFeedbackSent, setIsFeedbackSent] = useState<FeedbackSentState>({});

  return (
    <FeedbackContext.Provider value={{ isFeedbackSent, setIsFeedbackSent }}>
      {children}
    </FeedbackContext.Provider>
  );
}
