export const getColor = (str: string) => {
  return collorsMap[str.toLowerCase()[0]] || pallete[0];
};
const collorsMap: { [key: string]: string } = {};
const pallete = [
  '#3BAEEF',
  '#BB6BD9',
  '#2A2F57',
  '#650B3C',
  '#184A32',
  '#103B90',
  '#8B1887',
  '#1154D6',
  '#0D6751',
  '#250073',
  '#D64C11',
  '#3F9D86',
];

let n = 0;

const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

alphabet.forEach((letter) => {
  if (n >= pallete.length - 1) {
    n = 0;
  }
  collorsMap[letter] = pallete[n];
  n += 1;
});
