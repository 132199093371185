import { useTranslation } from 'react-i18next';

export default function SellerVatIdsEmpty() {
  const { t } = useTranslation();
  return (
    <div className='flex-grow bg-gray-300 px-4 py-8 lg:px-6 2xl:px-14'>
      <div className='mx-auto w-full max-w-[1328px]'>
        <div className='mx-auto max-w-[712px] rounded-lg bg-white py-4'>
          <div className='px-6'>
            <div className='SellerVATIds__title mb-2 font-black'>
              {t('Tax details')}
            </div>
            <div className='mb-4 whitespace-pre-line'>
              {t(
                "You don't have any active VAT IDs under countX management. Information about OSS details will be available later."
              )}
            </div>
            <div
              style={{
                maxWidth: 532,
              }}
            >
              <div className='SellerVATIds__emptyGrid mb-2 grid'>
                <div className='text-gray-700'>{t('Country')}</div>
                <div className='text-gray-700'>{t('VAT ID')}</div>
                <div className='text-gray-700'>{t('Tax ID')}</div>
              </div>
              <div className='SellerVATIds__emptyGrid grid'>
                <div>
                  {[157, 168, 133, 183, 133].map((maxWidth, i) => (
                    <Line maxWidth={maxWidth} key={i} />
                  ))}
                </div>
                <div>
                  {Array(5)
                    .fill(1)
                    .map((_, i) => (
                      <TwoPartsLine key={i} />
                    ))}
                </div>
                <div>
                  {Array(5)
                    .fill(1)
                    .map((_, i) => (
                      <TwoPartsLine key={i} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Line = ({ maxWidth }: { maxWidth: number }) => (
  <div
    className='SellerVATIds__line mb-4 rounded'
    style={{
      maxWidth,
    }}
  />
);

const TwoPartsLine = () => (
  <div className='mb-6 flex'>
    <div className='SellerVATIds__line mr-1 flex-grow rounded' />
    <div
      className='SellerVATIds__line--green rounded'
      style={{
        width: 21,
      }}
    />
  </div>
);
