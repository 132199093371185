import { JTLCredentials } from '@/core/types';
import { useTranslation } from 'react-i18next';
import CopyIcon from '@/icons/copy.svg?react';

interface Props {
  password: string;
  jtlCredentials?: JTLCredentials;
  onClickCopy: (text: string, message: string) => () => void;
}

export const JTLSecretKey = ({
  password,
  jtlCredentials,
  onClickCopy,
}: Props) => {
  const { t } = useTranslation();

  let header = <div>{t('Secret Key')}</div>;
  let footer = <div className='font-semibold'>{t('Not generated yet')}</div>;
  if (password) {
    header = (
      <div>
        {t('Secret Key')} • {t('Needs regeneration after closing the window')}
      </div>
    );
    footer = (
      <>
        <div className='font-semibold'>
          <span>{Array(36).fill('*').join('')}</span>{' '}
          <button onClick={onClickCopy(password, 'Secret Key is copied')}>
            <CopyIcon className='h-6 w-6' />
          </button>
        </div>
      </>
    );
  }

  if (jtlCredentials && !password) {
    footer = (
      <div className='font-semibold'>
        {t('To see Secret Key again you need to regenerate it first')}
      </div>
    );
  }

  return (
    <div>
      {header}
      {footer}
    </div>
  );
};
