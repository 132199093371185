import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@/components/Loader/Loader';
import { useModal } from '@/components/Modal/useModal';
import { FilingReportConfirmation } from '@/core/types';
import PageLayout from '../../../wrappers/PageLayout';
import SellerFilingReportConfirmationsModal, {
  SellerFilingReportConfirmationsModalData,
} from '@/components/SellerFilingReportConfirmationsModal/SellerFilingReportConfirmationsModal';
import PaymentInstructionModal from '@/components/PaymentInstructionModal/PaymentInstructionModal';
import SellerOngoingFiling from './SellerOngoingFiling';
import SellerPastPeriodsFiling from './SellerPastPeriodsFiling';
import Tabs from '@/components/Tabs/Tabs';
import { APIEventsEnum, useAppSelector, useSendEvent } from '../../../hooks';
import {
  getCompletedFiling,
  getOngoingFiling,
  getRegisteredVATIds,
} from '@/core/api';
import classNames from 'classnames';
// import { useLocation } from 'react-router-dom';
import AgendaModal from '@/components/AgendaModal/AgendaModal';
import usePaymentInstructionModal from './usePaymentInstructionModal';
import useAgendaModal from './useAgendaModal';
import './SellerFiling.scss';
import {
  activeCompanySelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';
import SellerReportingCountriesSidebar from '@/components/SellerReportingCountriesSidebar/SellerReportingCountriesSidebar';
import { useQueries } from '@tanstack/react-query';
import { useLocation, useSearchParams } from 'react-router-dom';
import FeedbackWidget from '@/components/FeedbackWidget/FeedbackWidget';

const TABS: {
  label: string;
  id: string;
}[] = [
  { label: 'Ongoing', id: 'current_month' },
  { label: 'Past periods', id: 'past_periods' },
];

export default function SellerFiling() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const pastPeriodsParam = searchParams.get('past_periods');
  const queryKey = useAppSelector(queryKeySelector);

  const activeCompany = useAppSelector(activeCompanySelector);

  const [ongoing, completed, reportingCountriesData] = useQueries({
    queries: [
      {
        queryKey: ['SellerOngoingFiling', queryKey],
        queryFn: () => getOngoingFiling(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerPastPeriodsFiling', queryKey],
        queryFn: () => getCompletedFiling(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerRegisteredVATIds', queryKey],
        queryFn: () => getRegisteredVATIds(activeCompany?.company_id),
      },
    ],
  });

  const [filesModalData, setFilesModalData] = useState<
    SellerFilingReportConfirmationsModalData | undefined
  >(undefined);

  const isLoading =
    ongoing.isLoading ||
    completed.isLoading ||
    reportingCountriesData.isLoading;

  const { t } = useTranslation();

  const {
    isModalOpened: isFilesModalOpened,
    closeModal: closeFilesModal,
    openModal: openFilesModal,
  } = useModal();

  const {
    onClickConfirmPayment,
    isPaymentInstructionModalOpened,
    closePaymentInstructionModal,
    reportToConfirmPayment,
    handleOpenPaymentInstructionModal,
    isConfirmingPayment,
  } = usePaymentInstructionModal();

  const {
    isAgendaModalOpened,
    closeAgendaModal,
    handleOpenAgendaModal,
    onClickConfirmAgenda,
    isConfirmingAgenda,
    reportToConfirmAgenda,
  } = useAgendaModal();

  const { sendEvent } = useSendEvent();

  const onFolderBtnClick =
    (confirmations: FilingReportConfirmation[], date: Date, country: string) =>
    () => {
      sendEvent(APIEventsEnum.view_report_confirmation, {
        block: 'Report confirmation Modal',
      });
      setFilesModalData({ confirmations, date, country });
      openFilesModal();
    };

  const renderContent = () => {
    if (pastPeriodsParam) {
      return (
        <SellerPastPeriodsFiling
          reports={completed.data || []}
          onAmountBtnClick={handleOpenPaymentInstructionModal}
          onFolderBtnClick={onFolderBtnClick}
          onClickAgenda={handleOpenAgendaModal}
        />
      );
    }

    return (
      <SellerOngoingFiling
        reports={ongoing.data || []}
        onAmountBtnClick={handleOpenPaymentInstructionModal}
        onFolderBtnClick={onFolderBtnClick}
        onClickAgenda={handleOpenAgendaModal}
      />
    );
  };

  if (isLoading) return <Loader />;

  const isSidebarShown =
    (reportingCountriesData.data && reportingCountriesData.data.length > 0) ||
    activeCompany?.ClientOSSDetails;

  return (
    <PageLayout
      className='flex-grow'
      rightColumn={
        <div style={{ width: 330 }} className='pt-4 lg:ml-auto lg:pt-0'>
          <FeedbackWidget testId='FeedbackWidget' />
          {isSidebarShown && (
            <SellerReportingCountriesSidebar
              reportingCountriesData={reportingCountriesData.data || []}
              activeCompany={activeCompany}
            />
          )}
        </div>
      }
    >
      <div
        className={classNames('rounded-lg bg-white px-6 py-4', {
          'mx-auto max-w-[874px]': !isSidebarShown,
        })}
      >
        <div className='Text--30 mb-4 font-black'>{t('Filings')}</div>
        <div className='flex flex-col'>
          <Tabs
            tabs={TABS}
            activeTab={pastPeriodsParam ? 'past_periods' : 'current_month'}
            onClickTab={(id) => {
              if (id === 'past_periods') {
                searchParams.set('past_periods', '1');
              } else {
                searchParams.delete('past_periods');
              }

              setSearchParams(searchParams, {
                state: location.state,
                replace: true,
              });
            }}
          />
          <div className='flex-grow'>{renderContent()}</div>
        </div>
        <SellerFilingReportConfirmationsModal
          isModalOpened={isFilesModalOpened}
          closeModal={closeFilesModal}
          data={filesModalData}
        />
        <PaymentInstructionModal
          report={reportToConfirmPayment}
          isModalOpened={isPaymentInstructionModalOpened}
          closeModal={closePaymentInstructionModal}
          onClickConfirm={onClickConfirmPayment(reportToConfirmPayment)}
          isConfirming={isConfirmingPayment}
          isReportHasDisregardedInstruction={
            reportToConfirmPayment &&
            [
              ...new Set(
                [...(ongoing.data || []), ...(completed.data || [])]
                  .filter((r) => r.payment_instruction?.cancelled)
                  .map((r) => r.id)
              ),
            ].includes(reportToConfirmPayment.id)
          }
          showDescription={false}
        />
        <AgendaModal
          report={reportToConfirmAgenda}
          isModalOpened={isAgendaModalOpened}
          closeModal={closeAgendaModal}
          onClickConfirm={onClickConfirmAgenda(reportToConfirmAgenda)}
          isConfirming={isConfirmingAgenda}
        />
      </div>
    </PageLayout>
  );
}
