import PageLayout from '../../wrappers/PageLayout';
import { Loader } from '@/components/Loader/Loader';
import SellerManualUploadInstructionBlock from '@/components/SellerManualUploadInstructionBlock/SellerManualUploadInstructionBlock';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppSelector } from '../../hooks';
import { queryKeySelector } from '@/core/store/user/userSlice';
import { activeCompanySelector } from '@/core/store/user/userSlice';
import {
  getDataUnits,
  getDataUnitsStats,
  getStopMailing,
  getUploadedDocumentsFiles,
  postDiscardFile,
} from '@/core/api';
import InvoicesUploader from '@/components/ManualDataUploader/InvoicesUploader';
import { useTranslation } from 'react-i18next';
import { ManualUploadFileStatusEnum } from '@/core/enums';
import { useMU } from '../../context/useMU';
import FileUploader from '@/components/FileUploader/FileUploader';
import { ACCEPTED_EXTS } from '@/components/ManualDataUploader/ManualUploader.helpers';
import Button from '@/components/Button';
import UploadIcon from '@/icons/upload_arrow.svg?react';
import SendIcon from '@/icons/sent.svg?react';
import ManualUploadFile from '@/components/ManualDataUploader/ManualUploadFile';
import { Badge } from '@/components/Badge/Badge';
import { useModal } from '@/components/Modal/useModal';
import CorrectionFilesInstructionModal from './SellerFiling/CorrectionFilesInstructionModal';
import MiniLoader from '@/components/MiniLoader/MiniLoader';
import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import useNotification from '@/hooks/useNotification';
import { useState } from 'react';
import FeedbackWidget from '@/components/FeedbackWidget/FeedbackWidget';

export default function SellerTurnover() {
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const {
    isLoadingUploadedFiles,
    onSubmitFiles,
    onSelectFiles,
    onDeleteFileToUpload,
    uploadedFiles,
    filesToUpload,
    isUploadingFiles,
  } = useMU();

  const { isModalOpened, closeModal, openModal } = useModal();
  const [fileIdForDeleting, setFileIdForDeleting] = useState<number>();

  const activeCompany = useAppSelector(activeCompanySelector);

  const queryKey = useAppSelector(queryKeySelector);

  const manualUploadMailingStopped = useQuery({
    queryKey: ['SellerStopManualUploadMailing', queryKey],
    queryFn: () =>
      getStopMailing('manual-upload-needed', activeCompany?.company_id),
  });

  const hasProcessedFiles = uploadedFiles.some(
    (f) => f.status !== ManualUploadFileStatusEnum.CREATED && !f.errors.length
  );

  const {
    isModalOpened: isConfirmDeleteFileModalOpened,
    closeModal: onCloseConfirmDeleteFileModal,
    openModal: openConfirmDeleteFileModal,
  } = useModal();

  return (
    <PageLayout
      className='flex-grow'
      rightColumn={
        <div style={{ maxWidth: 328 }} className='pt-4 lg:ml-auto lg:pt-0'>
          <FeedbackWidget testId='FeedbackWidget' />
          <SellerManualUploadInstructionBlock />
        </div>
      }
    >
      <div className='pb-4'>
        {isLoadingUploadedFiles && <Loader />}
        <div className='mb-4 rounded-lg bg-white px-4 pb-6 pt-2'>
          <div className='mb-2'>
            <div className='mb-1 flex items-center justify-between '>
              <div className='text-h4 font-extrabold'>
                {t('Upload turnover data')}
              </div>
              <div className='flex items-center gap-x-4'>
                {isUploadingFiles ? (
                  <Button
                    disabled
                    className='Button flex !w-[140px] cursor-pointer items-center !pl-3'
                  >
                    <UploadIcon className='mr-2 [&>path]:fill-white' />
                    <span>{t('Upload file')}</span>
                  </Button>
                ) : (
                  <FileUploader
                    onSelect={onSelectFiles}
                    name='manual-upload-file'
                    accept={ACCEPTED_EXTS}
                    className={`Button group flex !w-[140px] cursor-pointer items-center !pl-3 ${
                      filesToUpload.length && !isUploadingFiles
                        ? 'Button--outline'
                        : ''
                    }`}
                  >
                    <UploadIcon
                      className={`mr-2  ${
                        filesToUpload.length && !isUploadingFiles
                          ? '[&>path]:fill-dark-green'
                          : '[&>path]:fill-white'
                      }`}
                    />
                    <span>{t('Upload file')}</span>
                  </FileUploader>
                )}
              </div>
            </div>
            <div>
              <div>
                <Badge
                  color='gray'
                  label={t('Turnover data files')}
                  className='mb-2 mr-1 !text-dark'
                />
                {t('for the current and past periods')}
              </div>
              <div>
                <Badge
                  color='yellow'
                  label={t('Correction files')}
                  className='mr-1'
                />
                {t('requested by the email')}{' '}
                <button
                  onClick={openModal}
                  className='font-semibold text-blue-500 hover:text-blue-600'
                >
                  {t('Instruction how to correct')}
                </button>
              </div>
            </div>
          </div>
          <form onSubmit={onSubmitFiles}>
            {!!filesToUpload.length && (
              <div className='pt-2'>
                {filesToUpload.map((f) => (
                  <ManualUploadFile
                    className='mb-2 flex-grow'
                    onDeleteFile={onDeleteFileToUpload(f.name)}
                    file={f}
                    disabled={false}
                    key={f.name}
                    isUploading={isUploadingFiles}
                  />
                ))}
              </div>
            )}
            {!!filesToUpload?.length && (
              <Button
                className='flex min-w-[140px] items-center justify-center !px-3 !py-1'
                type='submit'
                disabled={isUploadingFiles}
                data-testid='manual-upload-submit'
              >
                {isUploadingFiles ? (
                  <MiniLoader className='mr-2' color='gray' />
                ) : (
                  <SendIcon className='mr-2' />
                )}
                <span>{t('Submit file')}</span>
              </Button>
            )}
          </form>
        </div>
        <InvoicesUploader
          activeCompany={activeCompany}
          hasProcessedFiles={hasProcessedFiles}
          manualUploadMailingStopped={manualUploadMailingStopped}
          onClickDeleteFile={(id) => {
            setFileIdForDeleting(id);
            openConfirmDeleteFileModal();
          }}
        />
      </div>
      <CorrectionFilesInstructionModal
        isModalOpened={isModalOpened}
        closeModal={closeModal}
      />
      <ConfirmModal
        danger
        isModalOpened={isConfirmDeleteFileModalOpened}
        closeModal={onCloseConfirmDeleteFileModal}
        confirm={(setIsLoading) => {
          if (!fileIdForDeleting || !activeCompany) return;
          setIsLoading(true);
          postDiscardFile(fileIdForDeleting, activeCompany?.company_id)
            .then(() =>
              Promise.all([
                getDataUnits(activeCompany.company_id),
                getUploadedDocumentsFiles(activeCompany.company_id),
                getDataUnitsStats(activeCompany.company_id),
              ])
            )
            .then(([DURes, filesRes, DUStatsRes]) => {
              queryClient.setQueryData(['SellerDataUnits', queryKey], DURes);
              queryClient.setQueryData(
                ['SellerManualUploadFiles', queryKey],
                filesRes
              );
              queryClient.setQueryData(
                ['SellerDataUnitsStats', queryKey],
                DUStatsRes
              );
              setIsLoading(false);
              onCloseConfirmDeleteFileModal();
              setFileIdForDeleting(undefined);
              showNotification({
                text: 'The file has been deleted',
                type: 'success',
              });
            })
            .catch(() => {
              setIsLoading(false);
            });
        }}
        confirmBtnText='Confirm and delete'
        title='Deleting file'
      >
        <div className='mb-2 text-h4 font-extrabold'>
          {t('Confirm deleting this file')}
        </div>
        <div>
          {t('It will no longer be available for processing by countX')}
        </div>
      </ConfirmModal>
    </PageLayout>
  );
}
