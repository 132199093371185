import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import CountryLabel from '@/components/CountryLabel/CountryLabel';
import { Loader } from '@/components/Loader/Loader';
import { getInProgressClientVATIds, getRegisteredVATIds } from '@/core/api';
import { ClientTaxDetailsStatusEnum, IdNumberStatusEnum } from '@/core/enums';
import { formatDate } from '@/core/helpers';
import { normalizeDateFE } from '@/core/normalization';
import { useAppSelector } from '../../../hooks';
import './SellerVATIds.scss';
import SellerVatIdsEmpty from './SellerVatIdsEmpty';
import {
  activeCompanySelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';
import OSSDetailsBlock from '@/components/OSSDetailsBlock/OSSDetailsBlock';
import { useQueries } from '@tanstack/react-query';
import PageLayout from '@/wrappers/PageLayout';
import TinNumberWidget from '@/components/TinNumberWidget/TinNumberWidget';
import { useLocation } from 'react-router-dom';
import FeedbackWidget from '@/components/FeedbackWidget/FeedbackWidget';

const STATUS_LABELS: { [key: string]: string } = {
  waiting: 'Waiting for master data',
  new: 'Waiting for master data',
  check: 'In preparation',
  check_done: 'Preparation completed',
  docs_prep: 'Documents in progress',
  docs_ready: 'Documents prepared',
  docs_incomplete: 'Documents incomplete',
  sent_to_client: 'Documents for signature',
  received: 'Signed documents received',
  in_tax_office: 'Sent to authorities',
  to_tax_office: 'Received by Authorities',
  tax_id_received: 'Tax ID received',
  vat_id_received: 'VAT ID received',
  registered: 'Registered',
  stopped: 'Stopped',
  paused: 'Paused',
  not_needed: '',
  deregistered: 'Deregistered',
  Takeover: 'Takeover',
};

export default function SellerVATIds() {
  const { t } = useTranslation();
  const location = useLocation();
  const activeCompany = useAppSelector(activeCompanySelector);
  const queryKey = useAppSelector(queryKeySelector);
  const [registrations, registeredVATIds] = useQueries({
    queries: [
      {
        queryKey: ['SellerVATIds', queryKey],
        queryFn: () => getInProgressClientVATIds(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerRegisteredVATIds', queryKey],
        queryFn: () => getRegisteredVATIds(activeCompany?.company_id),
      },
    ],
  });

  const registered = registeredVATIds.data || [];

  const inProgress = (registrations.data || []).filter(
    (v) => v.status !== ClientTaxDetailsStatusEnum.NOT_NEEDED
  );

  if (registrations.isLoading || registeredVATIds.isLoading) return <Loader />;

  if (
    !registered.length &&
    !inProgress.length &&
    !activeCompany?.ClientOSSDetails
  )
    return <SellerVatIdsEmpty />;

  return (
    <PageLayout
      className='flex-grow'
      rightColumn={
        <div style={{ width: 328 }} className='pt-4 lg:ml-auto lg:pt-0'>
          <FeedbackWidget testId='FeedbackWidget' />
          <TinNumberWidget styling='large' prevRoute={location.pathname} />
        </div>
      }
    >
      <div className='mx-auto max-w-[874px] rounded-lg bg-white py-4'>
        <div className='mb-4 px-6'>
          <div className='SellerVATIds__title mb-2 font-black'>
            {t('Tax details')}
          </div>
          {(!!inProgress.length || !!registered.length) && (
            <div>{t('VAT IDs under countX management')}</div>
          )}
        </div>
        {!!inProgress.length && (
          <div className='SellerVATIds__border border-gray-500 px-6 pb-4 pt-4'>
            <div className='mb-2 text-h4 font-extrabold'>
              {t('VAT IDs in progress')}
            </div>
            <div className='SellerVATIds__grid mb-2 grid'>
              <div className='text-gray-700'>{t('Country')}</div>
              <div className='text-gray-700'>{t('Start date')}</div>
              <div className='text-gray-700'>{t('Status')}</div>
              <div className='text-gray-700'>{t('Updated')}</div>
            </div>
            {inProgress.map((item, i) => (
              <div
                className='SellerVATIds__grid mb-4 grid'
                key={item.Country.short_name}
                data-testid={`inProgress-row-${i + 1}`}
              >
                <div data-testid={`inProgress-${i + 1}-country`}>
                  <CountryLabel country={item.Country.short_name} />
                </div>
                <div data-testid={`inProgress-${i + 1}-date`}>
                  {item.registration_date &&
                    formatDate(
                      normalizeDateFE(item.registration_date),
                      'dd.MM.yyyy'
                    )}
                </div>
                <div data-testid={`inProgress-${i + 1}-status`}>
                  {t(STATUS_LABELS[item.status])}
                </div>
                <div data-testid={`inProgress-${i + 1}-status_updated_date`}>
                  {item.status_updated_date ? (
                    <div>
                      {formatDate(
                        normalizeDateFE(item.status_updated_date),
                        'dd.MM.yyyy'
                      )}
                    </div>
                  ) : (
                    t('No information')
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {!!registered.length && (
          <div
            className={`SellerVATIds__border border-gray-500 px-6 ${
              activeCompany?.ClientOSSDetails ? 'pb-0' : 'pb-6'
            } pt-4`}
          >
            <div className='mb-2 text-h4 font-extrabold'>
              {t('Registered VAT IDs')}
            </div>
            <div className='SellerVATIds__registeredGrid grid'>
              <div className='text-gray-700'>{t('Country')}</div>
              <div className='text-gray-700'>{t('VAT ID')}</div>
              <div className='text-gray-700'>{t('Tax ID')}</div>
            </div>
            {registered.map((item, i) => (
              <div
                className={classNames(
                  'SellerVATIds__registeredGrid mx-0 grid pb-4 pt-2',
                  {
                    'border-t border-gray-500': i > 0,
                  }
                )}
                key={i}
                data-testid={`registered-row-${i + 1}`}
              >
                <div data-testid={`registered-row-${i + 1}-country`}>
                  <CountryLabel country={item.Country.short_name} />
                </div>
                <div>
                  <Cell
                    number={item.vat_number}
                    date={item.start_date_vat_number}
                    status={item.vat_number_status}
                    testid={`registered-row-${i + 1}-vat_number`}
                  />
                </div>
                <div>
                  <Cell
                    number={item.tax_number}
                    date={item.start_date_tax_number}
                    status={item.tax_number_status}
                    testid={`registered-row-${i + 1}-tax_number`}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {activeCompany?.ClientOSSDetails && (
          <OSSDetailsBlock className='SellerVATIds__border rounded-none !pb-2' />
        )}
      </div>
    </PageLayout>
  );
}

export const Cell = ({
  number,
  status,
  date,
  delimeter,
  testid,
}: {
  number: string;
  status?: IdNumberStatusEnum;
  delimeter?: string;
  date: string;
  testid: string;
}) => {
  const { t } = useTranslation();
  return (
    <>
      {number}
      <div className='flex items-center'>
        <div
          className={classNames({
            'Text--green': status === IdNumberStatusEnum.Active,
            'text-gray-700': status !== IdNumberStatusEnum.Active,
          })}
          data-testid={testid + '_status'}
        >
          {typeof status === 'number' && t(IdNumberStatusEnum[status])}
        </div>
        &nbsp;
        {date && status === IdNumberStatusEnum.Active && (
          <div data-testid={testid + '_date'}>
            {delimeter || t('since')}&nbsp;
            {formatDate(normalizeDateFE(date), 'dd.MM.yyyy')}
          </div>
        )}
      </div>
    </>
  );
};
