import { createSlice } from '@reduxjs/toolkit';
export type NotificationType = 'success' | 'loading' | 'error';
export type NotificationStateType = 'auto-hide' | 'hide' | 'open';

export interface Notification {
  type: NotificationType;
  text: string;
  id: number | string;
  state: NotificationStateType;
  interval?: number;
}

interface NotificationsState {
  list: Notification[];
}

const initialState: NotificationsState = {
  list: [],
};

interface AddNotificationActionInput {
  payload: Pick<Notification, 'type' | 'text'> & {
    id?: number | string;
    state?: NotificationStateType;
    interval?: number;
  };
}
interface RemoveNotificatioActionInput {
  payload: number | string;
}
interface HideNotificatioActionInput {
  payload: Pick<Notification, 'id'> & {
    text?: string;
  };
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: AddNotificationActionInput) => {
      state.list.unshift({
        text: action.payload.text,
        id: action.payload.id || Date.now(),
        type: action.payload.type,
        state: action.payload.state || 'auto-hide',
        interval: action.payload.interval,
      });
      state.list = state.list.slice(0, 5);
    },
    removeNotification: (state, action: RemoveNotificatioActionInput) => {
      state.list = state.list.filter((n) => n.id !== action.payload);
    },
    hideNotification: (state, action: HideNotificatioActionInput) => {
      state.list = state.list.map((n) => {
        if (n.id === action.payload.id) {
          n.state = 'hide';
          if (action.payload.text) n.text = action.payload.text;
        }
        return n;
      });
    },
    clearNotifications: (state) => {
      state.list = [];
    },
  },
});

export const {
  addNotification,
  removeNotification,
  hideNotification,
  clearNotifications,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
