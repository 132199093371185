import './Progress.scss';

interface Props {
  value: number;
  max: number;
  className?: string;
  getProgressBarColor?: (v: number) => string;
  labelStart?: string | null;
  labelEnd?: string | null;
}

const Progress = ({
  value,
  max,
  className = '',
  labelStart,
  labelEnd,
  getProgressBarColor,
}: Props) => {
  const width = Math.round(((value * 1000) / (max * 1000)) * 100);

  const getColor = () => {
    if (width >= 70 && width < 100) {
      return '#ff7800';
    }
    if (width >= 100) {
      return '#264f3f';
    }
    return '#0da86b';
  };

  return (
    <div>
      {(labelStart || labelEnd) && (
        <div className='mb-2 flex justify-between'>
          {labelStart && <div className='text-xs uppercase'>{labelStart}</div>}
          {labelEnd && <div className='text-xs uppercase'>{labelStart}</div>}
        </div>
      )}
      <div className={`Progress relative overflow-hidden ${className}`}>
        <div
          className='Progress__bar absolute'
          style={{
            width: `${width > 100 ? 100 : width}%`,
            backgroundColor: getProgressBarColor
              ? getProgressBarColor(width)
              : getColor(),
          }}
        />
      </div>
    </div>
  );
};

export default Progress;
