import { createSlice } from '@reduxjs/toolkit';
import { AmazonDataImportStatus } from '../../types';

interface AmazonState {
  dataImportStatus: AmazonDataImportStatus | undefined;
  hasNewCredentials: boolean;
}
interface SetAmazonDataImportStatusAction {
  payload: AmazonDataImportStatus | undefined;
}

interface SetHasNewRefreshtoken {
  payload: boolean;
}

const initialState: AmazonState = {
  dataImportStatus: undefined,
  hasNewCredentials: false,
};

export const amazonSlice = createSlice({
  name: 'amazon',
  initialState,
  reducers: {
    setAmazonDataImportStatus: (
      state,
      action: SetAmazonDataImportStatusAction
    ) => {
      state.dataImportStatus = action.payload;
    },
    setHasNewAmazonCredentials: (state, action: SetHasNewRefreshtoken) => {
      state.hasNewCredentials = action.payload;
    },
  },
});

export const { setAmazonDataImportStatus, setHasNewAmazonCredentials } =
  amazonSlice.actions;
export default amazonSlice.reducer;
