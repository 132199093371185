import classNames from 'classnames';
import { HTMLAttributes, MouseEventHandler, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@/components/Button';
import './SetupCheckQuestion.scss';

interface Props {
  text: string;
  onClickYes: MouseEventHandler;
  onClickNo: MouseEventHandler;
  className?: string;
  answer: boolean | undefined;
}

export default function SetupCheckQuestion({
  text,
  onClickYes,
  onClickNo,
  className = '',
  answer,
  children,
  ...rest
}: PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>) {
  const { t } = useTranslation();
  return (
    <div className={`SetupCheckQuestion ${className}`} {...rest}>
      <div className='SetupCheckQuestion__text mb-2 font-extrabold'>
        {t(text)}
      </div>
      <div className='SetupCheckQuestion__content'>
        <div>{children}</div>
        <div className='SetupCheckQuestion__btns flex items-center'>
          <Button
            onClick={onClickYes}
            className={classNames('SetupCheckQuestion__btn mr-2', {
              'SetupCheckQuestion__btn--active': answer === true,
            })}
            data-testid='setup-check-question-btn-yes'
          >
            {t('Yes')}
          </Button>
          <Button
            data-testid='setup-check-question-btn-no'
            onClick={onClickNo}
            className={classNames('SetupCheckQuestion__btn mr-2', {
              'SetupCheckQuestion__btn--active': answer === false,
            })}
          >
            {t('No')}
          </Button>
        </div>
      </div>
    </div>
  );
}
