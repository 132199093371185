import { useTranslation } from 'react-i18next';
import Button from '@/components/Button';
import Modal from '@/components/Modal/Modal';
import { formatNumber } from '@/core/helpers';
import FolderIcon from '@/icons/folder-yellow.svg?react';
import './FCTransfersModal.scss';
import { useBookMeetingModal } from '@/components/BookMeetingModal/useBookMeetingModal';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  transactions: number;
}

export default function FCTransfersModal({
  isModalOpened,
  closeModal,
  transactions,
}: Props) {
  const { t } = useTranslation();
  const { openModal: openMeetingModal } = useBookMeetingModal();
  return (
    <Modal isOpened={isModalOpened} close={closeModal} title={t('Information')}>
      <div className='mx-4 px-4 pb-6 pt-4'>
        <div className='Text font-extrabold'>
          {t('FC-Transfer transactions')}
        </div>
        <div className='mb-4'>
          {t(
            "Sometimes local tax advisors don't have information about these transactions and don't include them to local filings. It can lead to penalties during additional checks by tax authorities."
          )}
        </div>
        <div className='mb-6'>
          <span>{t('You have')}</span>
          &nbsp;
          <span className='font-semibold'>{formatNumber(transactions)}</span>
          &nbsp;
          <span>{t('transactions_modal')}</span>
          &nbsp;
          <span>{t('that must be specified in local filing reports.')}</span>
        </div>
        <div className='FCTransfersModal__card flex flex-col items-center justify-center'>
          <FolderIcon />
          <div
            className='mb-4 mt-2 text-center font-extrabold'
            style={{
              maxWidth: 400,
            }}
          >
            {t(
              'countX can export all needed data files for your Tax Advisor to fullfil all required VAT documents'
            )}
          </div>
          <Button
            onClick={() => {
              closeModal();
              openMeetingModal();
            }}
          >
            {t('Book a consultation')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
