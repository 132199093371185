import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
  current: number;
  pages: number;
  onPageClick: (p: number) => void;
}

export default function Pagination({ current, pages, onPageClick }: Props) {
  const { t } = useTranslation();
  const pagesArr = Array(pages)
    .fill(1)
    .map((_, i) => i + 1);

  const delimetr = <div className='col text-gray'>...</div>;

  const middle = pagesArr.slice(3, -3);
  const renderMiddle = () => {
    if (pages <= 6) return null;

    if (current < 3) {
      return delimetr;
    }

    return (
      <>
        {middle.length > 0 && current > 5 && delimetr}
        {(current === 3
          ? middle.slice(0, 3)
          : current === 4
          ? middle.slice(current - 4, current - 1)
          : middle.slice(current - 5, current - 2)
        ).map((n) => {
          return (
            <div key={n}>
              <button
                className={classNames(
                  'rounded-none bg-transparent transition-colors',
                  {
                    'font-bold text-green opacity-100': current === n,
                    'text-gray opacity-50 hover:text-dark hover:opacity-100':
                      current !== n,
                  }
                )}
                disabled={current === n}
                onClick={() => {
                  onPageClick(n);
                }}
              >
                {n}
              </button>
            </div>
          );
        })}
        {middle.length > 3 && current < pages - 4 && delimetr}
      </>
    );
  };

  if (pagesArr.length <= 1) return null;

  return (
    <div className='Pagination flex items-center justify-center'>
      <button
        className='text-gray rounded-none bg-transparent opacity-50 hover:text-dark hover:opacity-100'
        onClick={() => {
          onPageClick(current - 1);
        }}
        disabled={current === 1}
      >
        {t('Back')}
      </button>
      <div className='flex gap-x-6 px-12'>
        {pagesArr.slice(0, 3).map((n) => {
          return (
            <div key={n}>
              <button
                className={classNames(
                  'rounded-none bg-transparent transition-colors',
                  {
                    'font-bold text-green opacity-100': current === n,
                    'text-gray opacity-50 hover:text-dark hover:opacity-100':
                      current !== n,
                  }
                )}
                disabled={current === n}
                onClick={() => {
                  onPageClick(n);
                }}
              >
                {n}
              </button>
            </div>
          );
        })}
        {renderMiddle()}
        {pagesArr.slice(middle.length + 3).map((n) => {
          return (
            <div key={n}>
              <button
                className={classNames(
                  'rounded-none bg-transparent transition-colors',
                  {
                    'font-bold text-green opacity-100': current === n,
                    'text-gray opacity-50 hover:text-dark hover:opacity-100':
                      current !== n,
                  }
                )}
                disabled={current === n}
                onClick={() => {
                  onPageClick(n);
                }}
              >
                {n}
              </button>
            </div>
          );
        })}
      </div>
      <button
        className='text-gray rounded-none bg-transparent opacity-50 hover:text-dark hover:opacity-100'
        onClick={() => {
          onPageClick(current + 1);
        }}
        disabled={current === pages}
      >
        {t('Forward')}
      </button>
    </div>
  );
}
