import SellerThresholdIndicator from './SellerThresholdIndicator';
import './SellerSetupCheckThreshold.scss';
import classNames from 'classnames';
import CountryLabel from './CountryLabel/CountryLabel';
import { CountryThreshold } from '../pages/Seller/SellerSetupCheck';
import { formatNumber } from '../core/helpers';
import { useTranslation } from 'react-i18next';

interface Props {
  threshold: number;
  thresholdByCountry: CountryThreshold[];
  oss?: string | undefined;
}

export default function SellerSetupCheckThreshold({
  threshold,
  thresholdByCountry,
  oss,
}: Props) {
  const { t } = useTranslation();
  return (
    <>
      <div className='flex items-start justify-between'>
        <div className='flex items-start'>
          <div className='SellerSetupCheckThreshold__total'>
            {formatNumber(threshold, {
              currency: 'eur',
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
          </div>
          &nbsp;
          <div>
            /{' '}
            {formatNumber(10000, {
              currency: 'eur',
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
          </div>
        </div>
        {oss !== undefined && (
          <div
            className={classNames('rounded px-2 font-extrabold', {
              'ClientSetupCheck__badge--green': oss === 'true',
              'ClientSetupCheck__badge--orange': oss === 'false',
            })}
          >
            {oss === 'true' ? 'OSS' : 'No OSS'}
          </div>
        )}
      </div>
      <SellerThresholdIndicator amount={threshold} />
      <div className='mb-2 mt-4 flex justify-between pt-4'>
        <div className='font-extrabold'>{t('Cross-border sales')}</div>
        <div className='text-gray-700'>{t('Amount')}</div>
      </div>
      {!!thresholdByCountry?.length ? (
        <>
          {thresholdByCountry.map((c) => (
            <div className='border-t border-gray-500 py-2' key={c.country}>
              <div className='flex flex-wrap justify-between gap-x-6 '>
                <div>
                  <CountryLabel country={c.country} />
                </div>
                <div>
                  {formatNumber(c.threshold.value, {
                    currency: c.threshold.currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <span>{t('No sales')}</span>
      )}
    </>
  );
}
