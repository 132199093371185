import { ChangeEventHandler, PropsWithChildren } from 'react';
import cn from 'classnames';
import './Checkbox.scss';
import TipIcon from '@/icons/tip_white.svg?react';

interface Props {
  id?: string;
  name?: string;
  checked: boolean;
  disabled?: boolean;
  type?: 'checkbox' | 'radio';
  onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isError?: boolean;
  labelId?: string;
}

const Checkbox = ({
  type = 'checkbox',
  checked,
  onChange,
  onBlur,
  className = '',
  isError = false,
  disabled = false,
  id,
  name,
  children,
  labelId,
}: PropsWithChildren<Props>) => {
  const isCheckbox = type === 'checkbox';

  return (
    <div className={`Checkbox ${className}`}>
      <label
        className={cn('Checkbox__container inline-flex', {
          Checkbox__error: isError,
        })}
        data-testid={name}
      >
        <input
          id={id}
          name={name}
          type={isCheckbox ? 'checkbox' : 'radio'}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          onBlur={onBlur}
        />
        <div
          className={cn('Checkbox__input', {
            Checkbox__radio: !isCheckbox,
            'Checkbox__input--checkbox': isCheckbox,
          })}
          id={labelId + '-checkbox'}
        >
          {isCheckbox && <TipIcon />}
        </div>
        <div className='Checkbox__label' id={labelId}>
          {children}
        </div>
      </label>
    </div>
  );
};

export default Checkbox;
