import { APIEventsEnum, useSendEvent } from '@/hooks';
import { NormalizedEvaluation } from '@/core/types';
import { getEvaluationURL } from '@/core/api';
import { downloadFile, formatDate } from '@/core/helpers';
import { useNPSModal } from '@/components/NPSModal/useNPSModal';

export const useDownloadInvoiceBook = () => {
  const { openModal } = useNPSModal();
  const { sendEvent } = useSendEvent();

  const onClickDownload = (
    e: NormalizedEvaluation,
    eventDescription: string = 'Download button on invoice book toast was clicked'
  ) => {
    const apiEvent = APIEventsEnum.click_download_invoice_book;

    getEvaluationURL(e.company_id, e.id)
      .then((res) => {
        const dateStr =
          e.type === 'month'
            ? formatDate(e.startDate, 'MMMM yyyy')
            : formatDate(e.startDate, 'QQQ yyyy');
        const name = e.file.split('/').slice(-1)[0];
        const ext = name.split('.').slice(-1)[0];

        const filename = `${name.split('.').slice(0, -1).join('.')}-${dateStr
          .toLowerCase()
          .replace(' ', '-')}.${ext}`;

        downloadFile(res, filename);
        sendEvent(APIEventsEnum.click_download_invoice_book, {
          block: eventDescription,
        });
        openModal(apiEvent);
      })
      .catch(console.error);
  };

  return {
    onClickDownload,
  };
};
