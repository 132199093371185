import { createContext, useState, ReactNode } from 'react';

export const PaymentOverdueModalContext = createContext<{
  isModalOpened: boolean;
  closeModal: () => void;
  openModal: (v?: string) => void;
}>({
  isModalOpened: false,
  closeModal: () => {},
  openModal: () => {},
});

export function PaymentOverdueModalWrapper({
  children,
}: {
  children: ReactNode;
}) {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const openModal = () => {
    setIsModalOpened(true);
  };

  return (
    <PaymentOverdueModalContext.Provider
      value={{
        isModalOpened,
        closeModal,
        openModal,
      }}
    >
      {children}
    </PaymentOverdueModalContext.Provider>
  );
}
