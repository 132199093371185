import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Modal from '../Modal/Modal';
import { generateConfirmationFileLink } from '@/core/api';
import { downloadFile, formatDate } from '@/core/helpers';
import { FilingReportConfirmation, ReportConfirmationFile } from '@/core/types';
import { activeCompanySelector } from '@/core/store/user/userSlice';
import { APIEventsEnum, useAppSelector, useSendEvent } from '../../hooks';
import { useNPSModal } from '../NPSModal/useNPSModal';
import { SellerFilingReportConfirmation } from './SellerFilingReportConfirmation';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  data?: SellerFilingReportConfirmationsModalData;
}

export interface SellerFilingReportConfirmationsModalData {
  date: Date;
  confirmations: FilingReportConfirmation[];
  country: string;
}

export default function SellerFilingReportConfirmationsModal({
  isModalOpened,
  closeModal,
  data,
}: Props) {
  const { t } = useTranslation();
  const activeCompany = useAppSelector(activeCompanySelector);

  const { sendEvent } = useSendEvent();

  const { openModal } = useNPSModal();

  const onDowloadFile =
    (file: ReportConfirmationFile) =>
    (setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) =>
    () => {
      const apiEvent = APIEventsEnum.download_report_confirmation;
      setIsLoading(true);
      sendEvent(apiEvent, {
        block: 'Report confirmation downloading',
      });
      generateConfirmationFileLink(file.id, activeCompany?.company_id)
        .then((res) => {
          setIsLoading(false);
          downloadFile(res, file.filename);
          openModal(apiEvent);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };

  return (
    <Modal isOpened={isModalOpened} close={closeModal} title={t('Documents')}>
      <div className='mx-4 px-4 pb-6 pt-4'>
        {data && (
          <div className='mb-2 text-h4 font-extrabold'>
            {t(data.country)}, {formatDate(data.date, 'MMMM yyyy')}
          </div>
        )}
        <div className='font-semibold'>{t('Confirmation documents')}</div>
        <div className='space-y-4 divide-y'>
          {data?.confirmations.map((confirmation) => (
            <SellerFilingReportConfirmation
              key={confirmation.id}
              confirmation={confirmation}
              onDowloadFile={onDowloadFile}
            />
          ))}
        </div>
        <div className='mt-4'>
          <Button
            styling='outline'
            type='button'
            onClick={closeModal}
            data-testid='payment-instruction-modal-close-button'
          >
            {t('Close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
