import { useTranslation } from 'react-i18next';
import AmazonIcon from '@/icons/amazon.svg?react';
import classNames from 'classnames';
import Button from '../Button';
import { AuthedRoutesEnum } from '@/core/enums';
import { Link } from 'react-router-dom';

type TinNumberWidgetStyle = 'small' | 'large';

interface Props {
  styling?: TinNumberWidgetStyle;
  prevRoute: string;
}

export default function TinNumberWidget({
  styling = 'small',
  prevRoute,
}: Props) {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={classNames(
        'mb-4 ml-auto flex flex-col items-center justify-center gap-4 rounded-lg border-2 border-white bg-white bg-opacity-50 p-4',
        {
          'px-11 py-6': styling === 'large',
        }
      )}
    >
      <div
        className={classNames('flex items-center', {
          'w-full justify-start gap-3': styling === 'small',
          'flex-col gap-4': styling === 'large',
        })}
      >
        <div
          className={classNames('shrink-0 rounded-lg border', {
            'p-2': styling === 'small',
            'p-3': styling === 'large',
          })}
        >
          <AmazonIcon
            className={classNames({
              'h-8 w-8': styling === 'small',
              'h-12 w-12': styling === 'large',
            })}
          />
        </div>
        <div>
          {styling === 'small' ? (
            <>
              <div className='font-extrabold'>{t('TIN number on Amazon')}</div>
              <div
                className={`${i18n.language === 'de' ? 'tracking-tight' : ''}`}
              >
                {t('General information about TIN')}
              </div>
            </>
          ) : (
            <div className='Title--sm max-w-[200px] text-center font-extrabold'>
              {t('General information about TIN')}
            </div>
          )}
        </div>
      </div>
      <Link
        to={AuthedRoutesEnum.TinNumberInformation}
        state={{ prevRoute }}
        className='w-full'
      >
        <Button styling='outline' className='!w-full !px-2 !py-1 font-semibold'>
          <span>
            {t(`${styling === 'large' ? 'Open Guide' : 'Show details'}`)}
          </span>
        </Button>
      </Link>
    </div>
  );
}
