import AlertIcon from '@/icons/alert-red-circle.svg?react';
import { Source, UploadedFile } from '@/core/types';
import UploadedDocumentFileLink from './UploadedDocumentFileLink';
import { useTranslation } from 'react-i18next';
import { PropsWithChildren } from 'react';
import { SourceIcon } from '../SourceIcon';
import { activeCompanySelector } from '@/core/store/user/userSlice';
import { useAppSelector } from '@/hooks';

interface Props {
  source: Source;
  uploadFileForm?: JSX.Element;
  hasProcessedFiles: boolean;
  files: UploadedFile[];
  stopMUBlock?: JSX.Element;
  deadlineDescription?: JSX.Element;
  errorListUploader?: JSX.Element;
  className?: string;
  stopMUBLockPosition?: 'bottom';
  onClickDeleteFile: (id: number) => void;
}

const INVOICE_SOURCES: {
  [key: string]: string;
} = {
  amazon: 'Amazon',
  easybill: 'Easybill',
  billbee: 'Billbee',
  manual: 'Manual data',
  jtl: 'JTL',
};

export default function InvoiceDataSourceItem({
  source = 'manual',
  uploadFileForm,
  files,
  hasProcessedFiles,
  stopMUBlock,
  deadlineDescription,
  errorListUploader,
  children,
  className = '',
  stopMUBLockPosition,
  onClickDeleteFile,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const isShownCheckmark = source === 'manual';
  const isInvoiceWithHCE = files.some(
    (item) => item.errors_message && item.documents_with_error_number > 0
  );
  const isSourceManual = source === 'manual';
  const activeCompany = useAppSelector(activeCompanySelector);

  return (
    <div className={`flex flex-col items-start gap-y-2 px-4 pb-6 ${className}`}>
      <div className='flex w-full items-start gap-x-2'>
        <div className='relative'>
          <SourceIcon source={source} className='h-6 w-6' />
          {!activeCompany?.IsUseNewProcess && isInvoiceWithHCE && (
            <AlertIcon className='absolute right-[-5px] top-[-5px]' />
          )}
        </div>
        <div className='flex flex-col items-start justify-start'>
          <span className='font-bold'>
            {t(`${INVOICE_SOURCES[source]}`)} {deadlineDescription}
          </span>
          {!isSourceManual && !files.length && (
            <div>{t('No data imported yet')}</div>
          )}
          {isSourceManual && stopMUBLockPosition !== 'bottom' && (
            <div>{stopMUBlock}</div>
          )}
          {children}
        </div>
      </div>
      <div className={`w-full ${!!files.length ? 'border-t' : ''}`}>
        {isSourceManual && uploadFileForm}
        {files
          .sort((a, b) => {
            return (
              new Date(b.imported_at).getTime() -
              new Date(a.imported_at).getTime()
            );
          })
          .map((f) => (
            <div className='border-b py-2 last:border-b-white' key={f.id}>
              <UploadedDocumentFileLink
                file={f}
                collapsed={hasProcessedFiles}
                isShownCheckmark={isShownCheckmark}
                errorListUploader={errorListUploader}
                onClickDelete={() => {
                  onClickDeleteFile(f.id);
                }}
              />
            </div>
          ))}
        {isSourceManual && stopMUBLockPosition === 'bottom' && stopMUBlock}
      </div>
    </div>
  );
}
