import './Sidebar.scss';
import { NavLink, To } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  IMPRESSUM_LINK_EN,
  IMPRESSUM_LINK_DE,
  PRIVACY_POLICY_LINK_EN,
  PRIVACY_POLICY_LINK_DE,
} from '@/core/constants';
import Logo from '../Logo/Logo';
import { APIEventsEnum, useAppSelector, useSendEvent } from '../../hooks';
import {
  activeCompanySelector,
  companiesSelector,
} from '@/core/store/user/userSlice';

export interface Category {
  name: string;
  links: {
    label: string;
    icon: JSX.Element;
    to: To;
    marked?: boolean;
  }[];
}

interface Props {
  categories: Category[];
  header?: JSX.Element;
}

export const Sidebar = ({ categories, header }: Props) => {
  const { sendEvent } = useSendEvent();
  const { t, i18n } = useTranslation();
  const companies = useAppSelector(companiesSelector);
  const activeCompany = useAppSelector(activeCompanySelector);

  const qp = new URLSearchParams();
  if (companies.length > 1 && activeCompany?.company_id) {
    qp.set('company_id', activeCompany.company_id || '');
  }

  return (
    <>
      <div className='Sidebar__offset flex-shrink-0' />
      <div className='Sidebar fixed left-0 top-0 flex w-full flex-col'>
        <div
          className='mb-4 flex items-center px-4 py-2'
          style={{
            height: 56,
          }}
        >
          <Logo className='Sidebar__logo' />
        </div>
        <div
          className='Scrollbar flex flex-grow flex-col justify-between px-4 pb-14'
          style={{
            overflowY: 'auto',
          }}
        >
          <div>
            {header}
            {categories.map((c) => (
              <div key={c.name} className='mb-2 last:mb-0'>
                <div className='mb-2 font-bold'>{t(c.name)}</div>
                <div className='flex flex-col'>
                  {c.links.map((l) => {
                    return (
                      <NavLink
                        key={l.label}
                        to={{
                          pathname:
                            typeof l.to === 'string' ? l.to : l.to.pathname,
                          search:
                            typeof l.to !== 'string' && l.to.search
                              ? l.to.search
                              : qp.toString(),
                        }}
                        className='Sidebar__link -mx-2 mb-2 flex items-center py-1 pl-2 no-underline'
                        data-testid={`sidebar-link-${l.label}`}
                        onClick={() => {
                          sendEvent(APIEventsEnum.click_sidebar_link, {
                            to: l.label,
                          });
                        }}
                      >
                        <div className='flex flex-shrink-0 items-center'>
                          {l.icon}
                        </div>
                        <span className='ml-1 truncate'>{t(l.label)}</span>
                        {l.marked && (
                          <div className='Sidebar__marked ml-2 rounded-full' />
                        )}
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>

          <div className='flex flex-grow flex-col justify-end gap-y-3 text-sm font-semibold'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={`${
                i18n.language === 'de' ? IMPRESSUM_LINK_DE : IMPRESSUM_LINK_EN
              }`}
            >
              Impressum
            </a>
            <a
              rel='noopener noreferrer'
              target='_blank'
              href={`${
                i18n.language === 'de'
                  ? PRIVACY_POLICY_LINK_DE
                  : PRIVACY_POLICY_LINK_EN
              }`}
            >
              {i18n.language === 'de'
                ? 'Datenschutz-Bestimmungen'
                : 'Privacy policy'}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
