import { CURRENCY_SYMBOLS } from '@/core/constants';
import { InvoiceDocument, ResolveOptionsDataType } from '@/core/types';

export const ErrorsMappingEN: Record<string, string> = {
  TYPE_ERROR:
    "Check and fill in correct Document type -> should be 'invoice', 'credit', 'storno_credit', or 'storno'",
  INVOICE_NUMBER_ERROR:
    'Missing Invoice number (Dokumentennummer) -> please fill it',
  DOCUMENT_DATE_ERROR:
    'Check Invoice date (Dokumentendatum) -> missing or in wrong format, please fill it',
  CURRENCY_ERROR:
    'Check currency (Währung) -> missing or in wrong format, please fill it',
  DEPARTURE_COUNTRY_ERROR:
    "Check departure country (Versandland) -> missing or can't be recognized, please fill it",
  VAT_COUNTRY_ERROR:
    "Check VAT country (Steuerland) -> is missing or can't be recognized, please correct/add this information",
  ARRIVAL_COUNTRY_ERROR:
    "Check arrival country (Versandzielland) -> missing or can't be recognized, please fill it",
  VAT_COUNTRY_IS_DIFFERENT_TO_ARRIVAL_AND_DEPARTURE_COUNTRIES:
    'Check VAT-country -> should be either departure or arrival country',
  INVALID_NETTO_AMOUNT_FOR_VAT_RATE:
    'Check Netto amount (Nettowert) -> Netto amount is zero, but all other values are NOT zero (mismatch)',
  VAT_OPTION_MISMATCHED_VAT_DEP_COUNTRIES:
    'Check VAT country (Steuerland) -> when IC-transfer or export it should be departure country',
  BRUTTO_AMOUNT_MISMATCHED_ZERRO_NETTO_ZERRO_VAT:
    'Check Brutto amount (Bruttowert) -> VAT amount and net amount are NOT zero, but Brutto amount is zero. Brutto should be = Netto * (1+ VAT rate)',
  BIG_MISMATCH_INVALID_BRUTTO_AMOUNT_FOR_VAT_RATE:
    'Check Brutto amount -> should be Netto * (1+ VAT rate) (big mismatch)',
  INVALID_VAT_AMOUNT_FOR_VAT_RATE:
    'Check VAT amount -> It should be = Netto * VAT rate (small mismatch)',
  TRESPASSING_DELIVERY_THRESHOLD_DATE_ERROR:
    'For OSS transactions (B2C cross-border transactions from the OSS registration date) VAT-country should be arrival country',
  DISPENSING_DELIVERY_THRESHOLD_DATE_ERROR:
    'For OSS transactions (B2C cross-border transactions from the OSS registration date) VAT-country should be arrival country',
  IS_OSS_NOT_DEFINED:
    'Check Local/OSS -> information incomplete (missing or wrong); transaction cannot be identified as Local or OSS',
  CREDIT_NOT_FOUND_ORIGINAL_INVOICE:
    'Missing original invoice for the credit note, please provide us with the original invoice',
  CREDIT_FOUND_SEVERAL_DIFFERENT_ORIGINAL_INVOICES:
    'Several original invoices found for the credit note, please provide us with the correct original invoice / confirm the original invoice',
  INVALID_BRUTTO_AMOUNT_FOR_VAT_RATE:
    'Check Brutto amount -> It should be Netto * (1+ VAT rate) (small mismatch)',
  BIG_MISMATCH_INVALID_VAT_AMOUNT_FOR_VAT_RATE:
    'Check VAT amount -> It should be Netto * VAT rate (big mismatch)',
  VAT_RATE_NEITHER_ARRIVAL_NOR_DEPARTURE_CRITICAL:
    'Check VAT-rate (Steuersatz) -> Invoice VAT-rate (Steuersatz) is different than VAT-country',
  EXPORT_BUT_VAT_RATE_DEPARTURE:
    'Check VAT rate (Steuersatz) -> when export it should be zero (here the VAT rate of the departure country is used)',
  LOCAL_B2B_VAT_RATE_ZERO_CAN_BE_LOCAL_REVERSE_CHARGE:
    'Check and confirm that this is a local reverse-charge transaction (VAT amount is zero, departure and arrival country are equal and EU, customer-VATID is available)',
  SHOULD_BE_OSS_BUT_VAT_RATE_DEPARTURE:
    'Check VAT rate -> when cross-border B2C (OSS) VAT rate of arrival country should apply',
  VAT_RATE_ERROR_MISSING: 'Check VAT-rate (Steuersatz) -> is missing',
  WRONG_VAT_RATE_INSIDE_ONE_EU_COUNTRY_SALE:
    'Check VAT-rate (Steuersatz) -> Invoice VAT-rate (Steuersatz) is different than VAT-country',
  UK_THRESHOLD_PASSED_AND_MARKETPLACE:
    'Check VAT rate (Steuersatz) -> when threshold of 135BGP crossed local GB VAT rate should apply',
  CROSS_BORDER_B2B_VAT_RATE_NEITHER_ZERO_NOR_DEPARTURE:
    'Invalid VAT rate please confirm one of the following:\n1. Please confirm if this is B2C transaction (remove buyer VAT ID) or\n2. Please confirm if this this is B2B transaction (you need to correct invoice and apply departure country VAT rate if selling to businesses who have not valid VIES VAT ID)\n3. Please provide valid VIES VAT ID of buyer if B2B (you need to issue invoice with 0% VAT rate applying reverse charge)',
  CREDIT_FOUND_SEVERAL_DIFFERENT_INVOICES_FOR_PL_CZ_THRESHOLD_CROSSED:
    'Several original invoices found for the credit note, please provide us with the correct original invoice / confirm the original invoice',
  CREDIT_NOT_FOUND_ORIGINAL_INVOICE_FOR_PL_CZ_THRESHOLD_CROSSED:
    'Missing original invoice for the credit note, please provide us with the original invoice',
  EU_UK_VAT_RATE_ZERO_THRESHOLD_NOT_PASSED_AND_NOT_MARKETPLACE:
    'Check VAT amount -> It should be = Netto * VAT rate UK (-> this transaction should be taxed in UK)',
  TYPE_INVOICE_BUT_NEGATIVE_AMOUNT:
    "Check Type -> Type 'invoice' is not matching with the negative amount",
  TYPE_STORNOCREDIT_BUT_NEGATIVE_AMOUNT:
    "Check Type -> Type 'storno_credit' is not matching with the negative amount",
  TYPE_CREDIT_OR_STORNO_BUT_POSITIVE_AMOUNT:
    "Check Type -> Type 'credit' or 'storno' is not matching with the positive amount",
  VAT_RATE_ERROR_DOESNT_MATCH_VAT_COUNTRY:
    'Check VAT-rate (Steuersatz) -> is different than VAT-country',
  CHANNEL_WAS_NOT_RECOGNIZED:
    'Check the channel information -> can not be identified; please provide us with the correct channel (important to know: marketplace-transaction or non-marketplace-transaction)',
  VAT_RATE_ZERO_BUT_VAT_AMOUNT_NOT_ZERO:
    "Check VAT-rate (Steuersatz) -> it's zero, but VAT amount is not zero (mismatch)",
  EU_TO_UK_TAX_RESPONSIBLE_UNKNOWN:
    'Please check and fill out who is tax-responsible for this UK-transaction. (marketplace or seller)',
  EU_TO_UK_THRESHOLD_PASSED_IMPORT_TAX_RESPONSIBLE_UNKNOWN:
    'Please check and fill out who is import-tax-responsible for this UK-transaction. (seller or end-customer)',
  EU_TO_UK_THRESHOLD_PASSED_TAX_RESPONSIBLE_MUST_BE_SELLER:
    "Please double check and update the information who is tax-responsible for this UK-transaction. (-> should be seller in this case, but it's entered marketplace)",
  EU_TO_UK_ZERO_VAT_RATE_THRESHOLD_NOT_PASSED_TAX_RESPONSIBLE_SELLER:
    "Please double check the information who is tax-responsible for this UK-transaction. (expected marketplace, but it's entered seller) If seller is correct, you need to apply UK VAT -> then please correct the invoice.",
  EU_TO_UK_ZERO_VAT_RATE_THRESHOLD_PASSED_IMPORT_TAX_RESPONSIBLE_SELLER:
    'Please correct the document. -> As import-tax-responsible=seller, you need to apply UK VAT.',
  CH_VAT_RATE_ZERO:
    'Check VAT-rate (Steuersatz) for the local CH-transaction. -> VAT-rate is 0%. Please confirm the tax-free transaction or create a correction.',
  UK_THRESHOLD_NOT_CROSSED_TAX_RESP_MARKETPLACE_VAT_RATE_NOT_ZERO:
    'Check VAT rate (Steuersatz) -> should be zero and treated as an export. The Marketplace is responsible for the VAT reporting in the UK.',
  UK_THRESHOLD_CROSSED_IMPORT_TAX_RESP_END_CUSTOMER_VAT_RATE_NOT_ZERO:
    'Check VAT rate (Steuersatz) -> should be zero and treated only as an export. End-customer pays the import costs',
  BUYER_VAT_NUMBER_IS_NOT_VALID:
    'Invalid VAT ID of buyer recognised. Please provide valid buyer VAT ID otherwise this transaction will be taxed in departure country.',
  EU_VAT_RATE_ZERO_CONFIRMATION_NEEDED:
    "VAT amount is zero -> please add VAT ID of customer (column 'VAT ID number of the customer') or confirm this is VAT exempt with 0% (set Yes in column 'Confirm 0% VAT rate')",
  THRESHOLD_NOT_CROSSED_NOT_REGISTERED_FOR_OSS_BUT_ARRIVAL_VAT_RATE:
    'Check VAT-country -> VAT-country is arrival-country, but no OSS-registration is existing (OSS-threshold of 10.000 EUR has not been crossed yet) -> VAT-country should be departure-country',
  VAT_COUNTRY_DEFINED_BY_VAT_RULES_MISMATCH_THE_ONE_PROVIDED_BY_CLIENT:
    'Important information -> due to our countX-VAT-rules we defined another VAT-country than provided by you considering all other data-values  (VAT-country was changed for reports)',
  THRESHOLD_CROSSED_NOT_REGISTERED_FOR_OSS_BUT_DEPARTURE_VAT_RATE:
    'VAT-rate of Arrival country should be applied here',
  UK_VAT_RATE_ZERO_THRESHOLD_PASSED_IMPORT_TAX_RESP_IS_EMPTY:
    "If you're responsible of importing the goods,  UK VAT-rate must be applied",
  BEFORE_JUL2021_NAT_THRESHOLD_NOT_PASSED_ZERO_VAT_RATE_NOT_CONFIRMED:
    'VAT-rate of Departure country should be applied here',
  BEFORE_JUL2021_NAT_THRESHOLD_PASSED_ZERO_VAT_RATE_NOT_CONFIRMED:
    'VAT-rate of Arrival country should be applied here',
  UK_VAT_RATE_ZERO_THRESHOLD_NOT_PASSED_TAX_RESP_IS_EMPTY:
    "If it's not sold via marketplace, UK VAT-rate must be applied",
  BUYER_COUNTRY_DIFFERENT_FROM_ARRIVAL_COUNTRY:
    'Buyer country is different from arrival country',
};

export const ErrorsMappingDE: Record<string, string> = {
  TYPE_ERROR:
    "Prüfe den Dokumenttyp -> fehlt oder im falschen Format; bitte wähle 'invoice', 'credit', 'storno_credit', 'storno'",
  INVOICE_NUMBER_ERROR: 'Die Dokumentennummer fehlt -> bitte ausfüllen',
  DOCUMENT_DATE_ERROR:
    'Prüfe das Dokumentendatum -> fehlt oder ist im falschen Format; bitte ausfüllen',
  CURRENCY_ERROR:
    'Prüfe die Währung -> ist leer oder im falschen Format, bitte ausfüllen',
  DEPARTURE_COUNTRY_ERROR:
    'Prüfe das Versandland -> fehlt oder wird nicht erkannt, bitte ausfüllen',
  VAT_COUNTRY_ERROR:
    'Prüfe das Steuerland -> fehlt oder wird nicht erkannt, bitte ausfüllen',
  ARRIVAL_COUNTRY_ERROR:
    'Prüfe das Versandzielland -> fehlt oder wird nicht erkannt, bitte ausfüllen',
  VAT_COUNTRY_IS_DIFFERENT_TO_ARRIVAL_AND_DEPARTURE_COUNTRIES:
    'Prüfe das Steuerland -> es sollte entweder das Versandland oder das Versandzielland sein',
  INVALID_NETTO_AMOUNT_FOR_VAT_RATE:
    'Prüfe den Nettowert -> Nettowert ist Null, aber alle anderen Werte sind NICHT Null (Diskrepanz)',
  VAT_OPTION_MISMATCHED_VAT_DEP_COUNTRIES:
    'Prüfe das Steuerland -> bei einer IG-Transaktion oder einer Ausfuhrlieferung sollte das Steuerland das Versandland sein',
  BRUTTO_AMOUNT_MISMATCHED_ZERRO_NETTO_ZERRO_VAT:
    'Prüfe den Bruttowert -> Steuerbetrag und Nettowert sind NICHT Null, aber der Bruttowert ist NULL. Bruttowert sollte sein = Nettowert * (1+ Steuersatz)',
  BIG_MISMATCH_INVALID_BRUTTO_AMOUNT_FOR_VAT_RATE:
    'Prüfe den Bruttowert -> es besteht eine große Differenz zwischen Bruttowert und Nettowert * (1+Steuersatz)',
  INVALID_VAT_AMOUNT_FOR_VAT_RATE:
    'Prüfe den Steuerbetrag -> es besteht eine geringe Differenz zwischen Steuerbetrag und Nettowert * Steuersatz',
  TRESPASSING_DELIVERY_THRESHOLD_DATE_ERROR:
    'Bei OSS-Umsätzen (grenzüberschreitende B2C-Umsätze ab dem Datum der OSS-Registrierung) sollte das Steuerland dem Versandzielland gleich sein',
  DISPENSING_DELIVERY_THRESHOLD_DATE_ERROR:
    'Bei OSS-Umsätzen (grenzüberschreitende B2C-Umsätze ab dem Datum der OSS-Registrierung) sollte das Steuerland dem Versandzielland gleich sein',
  IS_OSS_NOT_DEFINED:
    'Prüfe OSS/Lokal -> einige Informationen fehlen oder sind falsch; die Transaktion kann nicht eindeutig als Lokal oder OSS identifiziert werden',
  CREDIT_FOUND_SEVERAL_DIFFERENT_ORIGINAL_INVOICES:
    'Keine eindeutige Originalrechnung zu der Gutschrift gefunden -> bitte stelle uns die Originalrechnung zur Verfügung bzw. bestätige die Originalrechnung',
  CREDIT_NOT_FOUND_ORIGINAL_INVOICE:
    'Fehlende Originalrechnung zu der Gutschrift -> bitte stelle uns die Originalrechnung zur Verfügung',
  INVALID_BRUTTO_AMOUNT_FOR_VAT_RATE:
    'Prüfe den Bruttowert -> es besteht eine geringe Differenz zwischen Bruttowert und Nettowert * (1+Steuersatz)',
  BIG_MISMATCH_INVALID_VAT_AMOUNT_FOR_VAT_RATE:
    'Prüfe den Steuerbetrag -> es besteht eine große Differenz zwischen Steuerbetrag und Nettowert * Steuersatz',
  VAT_RATE_NEITHER_ARRIVAL_NOR_DEPARTURE_CRITICAL:
    'Prüfe den Steuersatz -> stimmt nicht mit dem Steuerland überein',
  EXPORT_BUT_VAT_RATE_DEPARTURE:
    'Prüfe den Steuersatz -> bei einer Ausfuhrlieferung sollte der Steuersatz Null sein (hier wurde der Steuersatz des Versandlands angegeben)',
  LOCAL_B2B_VAT_RATE_ZERO_CAN_BE_LOCAL_REVERSE_CHARGE:
    "Prüfe / Bestätige die Transaktion -> handelt es sich um eine lokale Reverse-Charge-Transaktion? (Steuerbetrag ist Null, Versandland und Versandzielland sind gleich und EU, das Feld 'UStIdNr. des Kunden' ist ausgefüllt)",
  SHOULD_BE_OSS_BUT_VAT_RATE_DEPARTURE:
    'Prüfe den Steuersatz -> bei OSS-Transaktionen sollte der Steuersatz das Versandzielland angewendet werden',
  VAT_RATE_ERROR_MISSING: 'Prüfe den Steuersatz -> fehlt',
  WRONG_VAT_RATE_INSIDE_ONE_EU_COUNTRY_SALE:
    'Prüfe den Steuersatz -> stimmt nicht mit dem Steuerland überein',
  UK_THRESHOLD_PASSED_AND_MARKETPLACE:
    'Prüfe den Steuersatz -> bei dieser UK-Transaktion ist der Marktplatz für die Besteuerung verantwortlich; der Steuersatz sollte Null sein, um eine Doppelbesteuerung zu vermeiden',
  CHANNEL_WAS_NOT_RECOGNIZED:
    'Prüfe den Channel -> kann nicht eindeutig identifiziert werden; bitte anpassen (wichtig benötigte Info: Marktplatz-Transaktion oder keine Marktplatz-Transaktion)',
  CROSS_BORDER_B2B_VAT_RATE_NEITHER_ZERO_NOR_DEPARTURE:
    'Ungültiger Steuersatz, bitte bestätigen Sie einen der folgenden Fälle: 1. Bitte bestätigen Sie, ob es sich um eine B2C-Transaktion handelt (entfernen Sie die UStID des Käufers) oder 2. Bitte bestätigen Sie, ob es sich um eine B2B-Transaktion handelt (Sie müssen die Rechnung korrigieren und den Steuersatz des Versandlands anwenden, wenn Sie an Unternehmen verkaufen, die keine gültige MIAS-UStID haben) 3. Bitte geben Sie eine gültige MIAS-UStID des Käufers an, wenn es sich um eine B2B-Transaktion handelt (Sie müssen eine Rechnung mit einem Steuersatz von 0 % ausstellen und Reverse-Charge anwenden)',
  EU_UK_VAT_RATE_ZERO_THRESHOLD_NOT_PASSED_AND_NOT_MARKETPLACE:
    'Prüfe den Steuerbetrag -> er sollte sein = Netto * Steuersatz UK (-> diese Transaktion ist in UK zu versteuern)',
  VAT_RATE_ERROR_DOESNT_MATCH_VAT_COUNTRY:
    'Prüfe den Steuersatz -> passt nicht zum Steuerland',
  CREDIT_FOUND_SEVERAL_DIFFERENT_INVOICES_FOR_PL_CZ_THRESHOLD_CROSSED:
    'Keine eindeutige Originalrechnung zu der Gutschrift gefunden -> bitte stelle uns die Originalrechnung zur Verfügung bzw. bestätige die Originalrechnung',
  CREDIT_NOT_FOUND_ORIGINAL_INVOICE_FOR_PL_CZ_THRESHOLD_CROSSED:
    'Fehlende Originalrechnung zu der Gutschrift -> bitte stelle uns die Originalrechnung zur Verfügung',
  TYPE_INVOICE_BUT_NEGATIVE_AMOUNT:
    "Prüfe den Dokumenten-Typ (Type) -> Typ 'invoice' (Rechnung) stimmt nicht überein mit dem negativ angegebenen Betrag",
  TYPE_CREDIT_OR_STORNO_BUT_POSITIVE_AMOUNT:
    "Prüfe den Dokumenten-Typ (Type) -> Typ 'credit' (Gutschrift) or 'storno' (Storno) stimmt nicht überein mit dem positiv angegebenen Betrag",
  TYPE_STORNOCREDIT_BUT_NEGATIVE_AMOUNT:
    "Prüfe den Dokumenten-Typ (Type) -> Typ 'storno_credit' (Storno-Gutschrift) stimmt nicht überein mit dem negativ angegebenen Betrag",
  VAT_RATE_ZERO_BUT_VAT_AMOUNT_NOT_ZERO:
    'Prüfe den Steuersatz -> ist Null, aber der Steuerbetrag ist nicht Null (Diskrepanz)',
  EU_TO_UK_TAX_RESPONSIBLE_UNKNOWN:
    'Prüfe die Steuer-Verantwortung für diese UK-Transaktion -> bitte ausfüllen (marketplace or seller)',
  EU_TO_UK_THRESHOLD_PASSED_IMPORT_TAX_RESPONSIBLE_UNKNOWN:
    'Prüfe die Einfuhrumsatzsteuer-Verantwortung für diese UK-Transaktion -> bitte ausfüllen (Verkäufe oder Endkunde)',
  EU_TO_UK_THRESHOLD_PASSED_TAX_RESPONSIBLE_MUST_BE_SELLER:
    "Prüfe und aktualisiere die Steuer-Verantwortung für diese UK-Transaktion (-> sollte 'Seller' sein, aber es ist 'Marketplace' angegeben)",
  EU_TO_UK_ZERO_VAT_RATE_THRESHOLD_NOT_PASSED_TAX_RESP_IS_EMPTY:
    'Prüfe die Steuer-Verantwortung für diese UK-Transaktion (erwartet: Marketplace; angegeben: Seller) -> Wenn Verkäufer korrekt ist, muss USt. UK angewendet werden -> dann korrigieren Sie bitte die Rechnung.',
  EU_TO_UK_ZERO_VAT_RATE_THRESHOLD_PASSED_IMPORT_TAX_RESPONSIBLE_SELLER:
    'Bitte korrigieren Sie die Rechnung -> Da Einfuhrumsatzsteuer-Verantwortung = Seller angegeben ist, muss USt. UK angewendet werden.',
  CH_VAT_RATE_ZERO:
    'Prüfe den Steuersatz für die lokale CH-Transaktion. -> Steuersatz beträgt 0%. Bitte bestätige die steuerfreie Transaktion oder erstellen Sie eine Korrektur.',
  UK_THRESHOLD_NOT_CROSSED_TAX_RESP_MARKETPLACE_VAT_RATE_NOT_ZERO:
    'Prüfen Sie den Steuersatz -> sollte Null sein und als Ausfuhrlieferung behandelt werden. Der Marktplatz ist für die USt.-Meldung im Vereinigten Königreich verantwortlich.',
  UK_THRESHOLD_CROSSED_IMPORT_TAX_RESP_END_CUSTOMER_VAT_RATE_NOT_ZERO:
    'Prüfen Sie den Steuersatz -> sollte Null sein und nur als Ausfuhr behandelt werden. Endkunde zahlt die Importkosten',
  BUYER_VAT_NUMBER_IS_NOT_VALID:
    'Ungültige Umsatzsteuer-ID des Käufers identifiziert. Bitte stellen Sie eine gültige Umsatzsteuer-ID des Käufers zur Verfügung, andernfalls wird diese Transaktion im Versandland besteuert.',
  EU_VAT_RATE_ZERO_CONFIRMATION_NEEDED:
    "Der Steuerbetrag ist Null -> bitte füge die UStID des Kunden hinzu (der Spalte 'ggf. Ust ID Nr. des Kunden') oder bestätige, dass es sich um eine steuerbefreite Transaktion handelt (setzen Sie Ja in der Spalte 'Bestätige 0% Steuersatz')",
  THRESHOLD_NOT_CROSSED_NOT_REGISTERED_FOR_OSS_BUT_ARRIVAL_VAT_RATE:
    'Steuerland prüfen -> Steuerland ist Zielland, aber es ist keine OSS-Registrierung vorhanden (OSS-Schwelle von 10.000 EUR wurde noch nicht überschritten) -> Steuerland sollte Startland sein',
  VAT_COUNTRY_DEFINED_BY_VAT_RULES_MISMATCH_THE_ONE_PROVIDED_BY_CLIENT:
    'Wichtige Information -> aufgrund unserer countX-Umsatzsteuer-Regeln haben wir unter Berücksichtigung aller anderen Datenwerte ein anderes Steuerland als das von Ihnen angegebene definiert (Steuerland wurde für Berichte geändert)',
  THRESHOLD_CROSSED_NOT_REGISTERED_FOR_OSS_BUT_DEPARTURE_VAT_RATE:
    'Für die Besteuerung dieser Transaktion muss der Steuersatz des Ziellands angewendet werden.',
  UK_VAT_RATE_ZERO_THRESHOLD_PASSED_IMPORT_TAX_RESP_IS_EMPTY:
    'Wenn Sie für die Einfuhr der Ware verantwortlich sind, muss der britische Steuersatz angewendet werden.',
  BEFORE_JUL2021_NAT_THRESHOLD_NOT_PASSED_ZERO_VAT_RATE_NOT_CONFIRMED:
    'Für die Besteuerung dieser Transaktion muss der Steuersatz des Versandlands angewendet werden.',
  BEFORE_JUL2021_NAT_THRESHOLD_PASSED_ZERO_VAT_RATE_NOT_CONFIRMED:
    'Für die Besteuerung dieser Transaktion muss der Steuersatz des Ziellands angewendet werden.',
  UK_VAT_RATE_ZERO_THRESHOLD_NOT_PASSED_TAX_RESP_IS_EMPTY:
    'Wenn dieser Verkauf nicht über einen Marktplatz stattgefunden hat, muss der britische Steuersatz angewendet werden.',
  BUYER_COUNTRY_DIFFERENT_FROM_ARRIVAL_COUNTRY:
    'Adress-Land des Kunden unterscheidet sich vom Versandzielland.',
};

export const ERRORS_TO_RESOLVE = [
  'CROSS_BORDER_B2B_VAT_RATE_NEITHER_ZERO_NOR_DEPARTURE',
];

export const RESOLVE_OPTIONS_DATA_EN: ResolveOptionsDataType[] = [
  {
    error_code: 17,
    code: 0,
    name: 'CROSS_BORDER_B2B_VAT_RATE_NEITHER_ZERO_NOR_DEPARTURE',
    title: 'This is a B2B transaction with valid VAT number',
    description: [
      'VAT rate = 0%.',
      'Also we will adjust VAT amount with the Netto amount.',
      'Suggested values for the adjustments will be provided.',
    ],
  },
  {
    error_code: 17,
    code: 1,
    name: 'CROSS_BORDER_B2B_VAT_RATE_NEITHER_ZERO_NOR_DEPARTURE',
    title: 'This is a B2B transaction with invalid VAT number',
    description: [
      'VAT rate = Departure Country VAT rate.',
      'Also we will adjust VAT amount with the Netto amount.',
      'Suggested values for the adjustments will be provided.',
    ],
  },
  {
    error_code: 17,
    code: 2,
    name: 'CROSS_BORDER_B2B_VAT_RATE_NEITHER_ZERO_NOR_DEPARTURE',
    title: 'This is a B2C transaction',
    description: [
      'We’ll delete the buyer’s VAT number.',
      'Also we will adjust VAT amount with the Netto amount.',
      'Suggested values for the adjustments will be provided.',
    ],
  },
];

export const RESOLVE_OPTIONS_DATA_DE: ResolveOptionsDataType[] = [
  {
    error_code: 17,
    code: 0,
    name: 'CROSS_BORDER_B2B_VAT_RATE_NEITHER_ZERO_NOR_DEPARTURE',
    title: 'Dies ist eine B2B-Transaktion mit gültiger USt-IdNr.',
    description: [
      'Steuersatz = 0%.',
      'Wir passen die Umsatzsteuer entsprechend dem Netto an.',
      'Vorgeschlagene Werte für die Anpassungen werden bereitgestellt.',
    ],
  },
  {
    error_code: 17,
    code: 1,
    name: 'CROSS_BORDER_B2B_VAT_RATE_NEITHER_ZERO_NOR_DEPARTURE',
    title: 'Dies ist eine B2B-Transaktion mit ungültiger USt-IdNr.',
    description: [
      'Steuersatz = Steuersatz des Versandlandes.',
      'Wir passen die Umsatzsteuer entsprechend dem Netto an.',
      'Vorgeschlagene Werte für die Anpassungen werden bereitgestellt.',
    ],
  },
  {
    error_code: 17,
    code: 2,
    name: 'CROSS_BORDER_B2B_VAT_RATE_NEITHER_ZERO_NOR_DEPARTURE',
    title: 'Dies ist eine B2C-Transaktion',
    description: [
      'Wir werden die USt-IdNr. des Käufers löschen.',
      'Wir passen auch die Umsatzsteuer entsprechend dem Netto an.',
      'Vorgeschlagene Werte für die Anpassungen werden bereitgestellt.',
    ],
  },
];

export const EDITABLE_INVOICE_DOCUMENT_VALUES = [
  'departure_country',
  'arrival_country',
  'vat_rate',
  'net_amount',
  'brutto_amount',
  'vat_amount',
  'b2b_buyer_vat_number',
];

export const EditableInvoiceDocumentValuesMapping: {
  [key in keyof InvoiceDocument]?: string;
} = {
  vat_rate: 'VAT rate',
  brutto_amount: 'Brutto amount',
  net_amount: 'Netto amount',
  vat_amount: 'VAT amount',
  b2b_buyer_vat_number: 'VAT number',
  departure_country: 'Departure',
  arrival_country: 'Arrival',
};

export const editableInvoiceDocumentValuesSign = (
  name: keyof InvoiceDocument,
  currency: string
) => {
  if (
    currency &&
    (name === 'net_amount' || name === 'brutto_amount' || name === 'vat_amount')
  )
    return CURRENCY_SYMBOLS[currency];

  if (name === 'vat_rate') return '%';

  return '';
};

export const calculateValues = (
  net_amount: number | undefined = 0,
  brutto_amount: number | undefined = 0,
  vat_amount: number | undefined = 0,
  vat_rate: number | undefined = 0
) => {
  const bruttoAmountCalculated = (net_amount || 0) + (vat_amount || 0);

  const bruttoAmountSuggested = Math.round(
    !!brutto_amount
      ? brutto_amount
      : net_amount !== 0
        ? bruttoAmountCalculated
        : !!vat_rate
          ? (vat_amount * (100 + vat_rate)) / vat_rate
          : 0
  );

  const vatAmountCalculatedByBrutto = vat_rate
    ? (brutto_amount * vat_rate) / (100 + vat_rate)
    : 0;
  const vatAmountCalculatedByNetto = net_amount
    ? vat_rate
      ? (net_amount * vat_rate) / 100
      : 0
    : 0;

  const vatAmountSuggested = Math.round(
    !!brutto_amount
      ? vatAmountCalculatedByBrutto
      : !!vat_amount
        ? vatAmountCalculatedByNetto
        : vat_amount
  );

  const nettoAmountSuggested = Math.round(
    !!brutto_amount
      ? brutto_amount - vatAmountSuggested
      : !!vat_amount
        ? vat_rate
          ? (vat_amount * 100) / vat_rate
          : 0
        : net_amount
  );

  const isBruttoNeededCorrection =
    Math.abs((bruttoAmountSuggested || 0) - (brutto_amount || 0)) >= 2;

  const isNettoNeededCorrection =
    Math.abs((nettoAmountSuggested || 0) - (net_amount || 0)) >= 2;

  const isVatNeededCorrection =
    Math.abs((vatAmountSuggested || 0) - (vat_amount || 0)) >= 2;

  return {
    bruttoAmountCalculated,
    bruttoAmountSuggested,
    isBruttoNeededCorrection,
    isNettoNeededCorrection,
    isVatNeededCorrection,
    vatAmountCalculatedByBrutto,
    vatAmountCalculatedByNetto,
    vatAmountSuggested,
    nettoAmountSuggested,
  };
};
