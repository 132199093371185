import { useQuery } from '@tanstack/react-query';
import Datev from '@/components/Datev/Datev';
import { useAppSelector } from '../../../hooks';
import {
  activeCompanySelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';
import { getDatev, getDatevLedgerAggregated } from '@/core/api';
import { Loader } from '@/components/Loader/Loader';
import PageLayout from '@/wrappers/PageLayout';
import FeedbackWidget from '@/components/FeedbackWidget/FeedbackWidget';

export default function SellerDatev() {
  const queryKey = useAppSelector(queryKeySelector);
  const activeCompany = useAppSelector(activeCompanySelector);
  const { data: files, isLoading } = useQuery({
    queryKey: ['SellerDatev', queryKey],
    queryFn: () => getDatev(activeCompany?.company_id),
  });

  const { data: aggregatedDatev, isLoading: isLoadingDatev } = useQuery({
    queryKey: ['AggregatedDatevAggregated', queryKey],
    queryFn: () => getDatevLedgerAggregated(activeCompany?.company_id),
  });

  return (
    <PageLayout
      className='flex-grow'
      rightColumn={
        <div style={{ maxWidth: 328 }} className='pt-4 lg:ml-auto lg:pt-0'>
          <FeedbackWidget testId='FeedbackWidget' />
        </div>
      }
    >
      <div className='mx-auto flex w-full max-w-[1328px] flex-grow flex-col'>
        {isLoading || isLoadingDatev ? (
          <Loader />
        ) : (
          <Datev
            files={files || []}
            companyId={activeCompany!.company_id}
            displaySinceISO={activeCompany?.DisplayDatevSince}
            aggregatedDatev={aggregatedDatev || []}
          />
        )}
      </div>
    </PageLayout>
  );
}
