import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  ConfirmSignUpCommand,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand,
} from '@aws-sdk/client-cognito-identity-provider';
export const Region = import.meta.env.VITE_APP_REGION;
export const ClientId = import.meta.env.VITE_APP_USERPOOL_CLIENT_ID;
export const client = new CognitoIdentityProviderClient({ region: Region });

interface ForgotPasswordInput {
  email: string;
}

interface LoginInput extends ForgotPasswordInput {
  password: string;
}

interface ConfirmSignUpInput extends ForgotPasswordInput {
  confirmationCode: string;
}
interface ConfirmForgotPasswordInput extends ConfirmSignUpInput {
  password: string;
}

export const login = async ({ email, password }: LoginInput) =>
  await client.send(
    new InitiateAuthCommand({
      AuthFlow: 'USER_PASSWORD_AUTH',
      AuthParameters: {
        USERNAME: email,
        PASSWORD: password,
      },
      ClientId,
    })
  );

export const refreshAccessToken = async (refreshToken: string) =>
  await client.send(
    new InitiateAuthCommand({
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
      ClientId,
    })
  );

export const confirmSignUp = async ({
  email,
  confirmationCode,
}: ConfirmSignUpInput) =>
  await client.send(
    new ConfirmSignUpCommand({
      ClientId,
      ConfirmationCode: confirmationCode,
      Username: email,
    })
  );

export const forgotPassword = async ({ email }: ForgotPasswordInput) =>
  await client.send(
    new ForgotPasswordCommand({
      ClientId,
      Username: email,
    })
  );

export const confirmForgotPassword = async ({
  email,
  confirmationCode,
  password,
}: ConfirmForgotPasswordInput) =>
  await client.send(
    new ConfirmForgotPasswordCommand({
      ClientId,
      Username: email,
      ConfirmationCode: confirmationCode,
      Password: password,
    })
  );
