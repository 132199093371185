import { useTranslation } from 'react-i18next';
import './PurchasePricesUploader.scss';
import UploadIcon from '@/icons/upload_arrow.svg?react';
import SendIcon from '@/icons/sent.svg?react';
import FileUploader from '@/components/FileUploader/FileUploader';
import MiniLoader from '@/components/MiniLoader/MiniLoader';
import { downloadFile, formatDate } from '@/core/helpers';
import ManualUploadFile from '@/components/ManualDataUploader/ManualUploadFile';
import { APIEventsEnum, useAppSelector, useSendEvent } from '../../hooks';
import classNames from 'classnames';
import PurchasePricesFileLink from '@/components/ManualDataUploader/PurchasePricesFileLink';
import { useMemo, useState } from 'react';
import { postPurchasePricesTemplate } from '@/core/api';
import { subMonths } from 'date-fns';
import { Loader } from '@/components/Loader/Loader';
import Button from '@/components/Button';
import XLSIcon from '@/icons/xls.svg?react';
import { activeCompanySelector } from '@/core/store/user/userSlice';
import TopProgressLoader from '@/components/TopProgressLoader';
import TipIcon from '@/icons/tip-green-circle.svg?react';
import { ACCEPTED_EXTS } from '@/components/ManualDataUploader/ManualUploader.helpers';
import { usePP } from '../../context/usePP';
import { FileStatusEnum } from '@/core/enums';
import { Badge } from '@/components/Badge/Badge';
import AlertCalendarIcon from '@/icons/alert-calendar.svg?react';
import AlertIcon from '@/icons/alert-orange-circle.svg?react';
import DownloadIcon from '@/icons/download-file.svg?react';
import AlertDownloadIcon from '@/icons/download-alert.svg?react';
import UploadFileForm from './UploadFileForm';

const purchasePricesInstructionSteps = [
  {
    title: 'Download file',
    text: 'Download the list of all products with missing Purchase Prices',
  },
  {
    title: 'Update prices',
    text: 'Fill in the missing purchase prices. Ensure all prices are entered in EUR',
  },
  {
    title: 'Upload updated file',
    text: 'Once complete, upload the updated file back below. You can upload files with prices not listed in the attachment',
  },
];

export interface PurchasePricesUploadedFile {
  bucket: string;
  file: string;
  id: number;
  imported_at: string;
  rows_number: number;
  size: number;
  status: number;
  updated_products_number: number;
  validation_errors: {
    code: number;
    id: number;
    line: number;
  }[];
}

export default function PurchasePricesUploader() {
  const { t } = useTranslation();
  const activeCompany = useAppSelector(activeCompanySelector);

  const { sendEvent } = useSendEvent();

  const [isLoadingLastMonthFile, setIsLoadingLastMonthFile] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);

  const {
    PPFiles,
    isUploadingPP,
    fileToUploadPP,
    onSelectPPFile,
    countLastMonthPP,
    countAllPeriodsPP,
    deletePPFileToUpload,
    onSubmitPPPrevPeriod,
    isLoadingPP,
  } = usePP();

  const isProcessing =
    isUploadingPP || PPFiles.some((f) => f.status === FileStatusEnum.CREATED);

  const deadlineDate = new Date();
  deadlineDate.setDate(10);

  const date = useMemo(() => {
    const d = new Date();
    return subMonths(d, 1);
  }, []);

  const today = new Date();

  const renderContent = () => {
    if (!!countLastMonthPP || !!countAllPeriodsPP) {
      return (
        <>
          <div className='Text--30 my-2 font-black'>
            {t('Update purchase prices')}
          </div>
          <div
            className='mb-2 whitespace-pre-line'
            style={{
              maxWidth: 650,
            }}
          >
            <ol className='ml-4'>
              {purchasePricesInstructionSteps.map((step) => (
                <li key={step.title} className='list-decimal'>
                  <div className='font-bold'>{t(step.title)}</div>
                  <div>{t(step.text)}</div>
                </li>
              ))}
            </ol>
            <div className='py-2'>
              <span>{t('Important')}</span>:{' '}
              <span>
                {t('Please do not delete or rename any columns in the file')}
              </span>
            </div>
          </div>
          {!!countLastMonthPP && (
            <div>
              <div className='flex items-center justify-between pb-2'>
                <div className='font-extrabold'>
                  {t('Missing for *period*').replace(
                    '*period*',
                    formatDate(date, 'MMMM yyyy')
                  )}
                </div>
                <Badge
                  color='orange'
                  label={
                    <div>
                      <AlertCalendarIcon className='mr-1' />
                      <span className='text-orange-500'>
                        {t('by *date*').replace(
                          '*date*',
                          formatDate(deadlineDate, 'E, d MMM')
                        )}
                      </span>
                    </div>
                  }
                />
              </div>
              <PurchasePricesFileLink
                className='mb-4 rounded-lg border px-4 py-2 outline-0 transition-colors hover:border-dark hover:outline hover:outline-dark'
                bucket=''
                path=''
                name='List_of_missing_purchase_prices'
                icon={<XLSIcon />}
                onDownload={() => {
                  setIsLoadingLastMonthFile(true);
                  postPurchasePricesTemplate(activeCompany?.company_id, true)
                    .then((res) => {
                      setIsLoadingLastMonthFile(false);
                      downloadFile(res, `List_of_missing_purchase_prices`);
                    })
                    .catch(() => {
                      setIsLoadingLastMonthFile(false);
                    });
                }}
                isLoading={isLoadingLastMonthFile}
                description={
                  !!PPFiles.length &&
                  !PPFiles.some((f) => f.status === FileStatusEnum.CREATED) ? (
                    <div className='flex gap-x-1'>
                      <span className='font-semibold text-orange-500'>
                        {countLastMonthPP} {t('prices are still missing')}
                      </span>
                      <AlertIcon />
                    </div>
                  ) : (
                    <span>
                      {t('Missing prices')}: {countLastMonthPP}
                    </span>
                  )
                }
                readyIcon={
                  !!PPFiles.length &&
                  !PPFiles.some((f) => f.status === FileStatusEnum.CREATED) ? (
                    <AlertDownloadIcon className='h-6 w-6' />
                  ) : (
                    <DownloadIcon className='h-6 w-6' />
                  )
                }
              />
            </div>
          )}
          {!!countAllPeriodsPP && (
            <div>
              <div className='pb-4'>
                <div className='font-extrabold'>
                  {t('Total periods assessment')}
                </div>
                <div className='max-w-[528px]'>
                  {t(
                    'The list includes missing purchase prices necessary for previous filings, including the current one.'
                  )}
                </div>
              </div>
              <PurchasePricesFileLink
                className='mb-4 rounded-lg border px-4 py-2 outline-0 transition-colors hover:border-dark hover:outline hover:outline-dark'
                bucket=''
                path=''
                name='List_of_missing_purchase_prices'
                icon={<XLSIcon />}
                onDownload={() => {
                  setIsLoadingFile(true);
                  postPurchasePricesTemplate(activeCompany?.company_id)
                    .then((res) => {
                      downloadFile(res, `List_of_missing_purchase_prices`);
                      setIsLoadingFile(false);
                    })
                    .catch(() => {
                      setIsLoadingFile(false);
                    });
                }}
                isLoading={isLoadingFile}
                description={
                  !!PPFiles.length &&
                  !PPFiles.some((f) => f.status === FileStatusEnum.CREATED) ? (
                    <div className='flex gap-x-1'>
                      <span className='font-semibold text-orange-500'>
                        {countAllPeriodsPP} {t('prices are still missing')}
                      </span>
                      <AlertIcon />
                    </div>
                  ) : (
                    <span>
                      {t('Missing prices')}: {countAllPeriodsPP}
                    </span>
                  )
                }
                readyIcon={
                  !!PPFiles.length &&
                  !PPFiles.some((f) => f.status === FileStatusEnum.CREATED) ? (
                    <AlertDownloadIcon className='h-6 w-6' />
                  ) : (
                    <DownloadIcon className='h-6 w-6' />
                  )
                }
              />
            </div>
          )}
          <UploadFileForm />
        </>
      );
    }

    return (
      <div
        style={{
          maxWidth: 620,
        }}
      >
        <div className='Text--30 mb-2 font-black'>
          {today.getDate() < 5
            ? t(
                'If you have any missing purchase prices, they will appear here on *month* 5th'
              ).replace('*month*', formatDate(today, 'MMMM'))
            : t('For *month*, purchase prices are complete').replace(
                '*month*',
                formatDate(date, 'MMMM yyyy')
              )}
        </div>
        <div className='mb-2'>
          {t(
            'But if you have received request by email from the manager for past dates you can upload them here'
          )}
        </div>
        <form
          onSubmit={onSubmitPPPrevPeriod}
          className={classNames({
            'border-t border-gray-500 pt-4': fileToUploadPP,
          })}
        >
          {fileToUploadPP && (
            <ManualUploadFile
              onDeleteFile={deletePPFileToUpload}
              file={fileToUploadPP}
              disabled={false}
              className='mb-2 rounded-lg border px-4 py-2'
            />
          )}
          {fileToUploadPP ? (
            <Button
              className='mt-4 flex items-center !py-2 !pl-4 !pr-4'
              type='submit'
              disabled={isUploadingPP}
              onClick={() => {
                sendEvent(APIEventsEnum.click_upload_purchase_prices, {
                  block: 'Upload purchase prices',
                });
              }}
            >
              {isUploadingPP ? (
                <MiniLoader className='mr-2' color='gray' />
              ) : (
                <SendIcon className='mr-2' />
              )}
              <span>{t('Send')}</span>
            </Button>
          ) : (
            <div
              className='flex flex-wrap items-center gap-x-4'
              style={{
                paddingTop: 30,
              }}
            >
              <div>
                <FileUploader
                  onSelect={onSelectPPFile}
                  name='file'
                  accept={ACCEPTED_EXTS}
                  className='Button--outline flex cursor-pointer items-center py-2 pl-4 pr-6'
                  multiple={false}
                >
                  <>
                    <UploadIcon className='mr-2' />
                    <span>{t('Upload updated file')}</span>
                  </>
                </FileUploader>
              </div>
              <div className='text-sm text-gray-700'>
                {t('File format: CSV, XLS, XLSX')}
              </div>
            </div>
          )}
        </form>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        'relative overflow-hidden rounded-lg bg-white p-6',
        {
          'mx-auto': !!countLastMonthPP || !!countAllPeriodsPP,
          'mb-4': !countLastMonthPP || !countAllPeriodsPP,
        }
      )}
    >
      {today.getDate() >= 5 && !countLastMonthPP && (
        <TipIcon
          className='absolute'
          style={{
            right: 16,
            top: 12,
          }}
        />
      )}
      {(today.getDate() >= 5 || isProcessing) && (
        <TopProgressLoader
          isLoading={isProcessing}
          backgroundColor={!!countLastMonthPP ? '#FF7800' : undefined}
        />
      )}
      {isLoadingPP && <Loader />}
      {renderContent()}
    </div>
  );
}
