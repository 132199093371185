import PDFIcon from '@/icons/pdf.svg?react';
import XLSIcon from '@/icons/xls.svg?react';
import XLSErrIcon from '@/icons/xls-error.svg?react';
import CSVIcon from '@/icons/csv.svg?react';
import CSVErrIcon from '@/icons/csv-error.svg?react';

export const ErrorFileIconsMap: {
  [key: string]: JSX.Element;
} = {
  pdf: <PDFIcon />,
  xls: <XLSErrIcon />,
  xlsx: <XLSErrIcon />,
  csv: <CSVErrIcon />,
};

export const FileIconsMap: {
  [key: string]: JSX.Element;
} = {
  pdf: <PDFIcon />,
  xls: <XLSIcon />,
  xlsx: <XLSIcon />,
  csv: <CSVIcon />,
};

export const ACCEPTED_EXTS = ['xls', 'xlsx', 'csv'];
