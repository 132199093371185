import { UploadedFile } from '@/core/types';
import { createContext, useState, ReactNode } from 'react';

export const ErrorsNotificationModalContext = createContext<{
  isModalOpened: boolean;
  closeModal: () => void;
  openModal: (v: UploadedFile[]) => void;
  files: UploadedFile[];
}>({
  isModalOpened: false,
  closeModal: () => {},
  openModal: () => {},
  files: [],
});

export function ErrorsNotificationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [files, setFiles] = useState<UploadedFile[]>([]);

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const openModal = (filesArg: UploadedFile[]) => {
    setFiles(filesArg);
    setIsModalOpened(true);
  };

  return (
    <ErrorsNotificationModalContext.Provider
      value={{
        isModalOpened,
        closeModal,
        openModal,
        files,
      }}
    >
      {children}
    </ErrorsNotificationModalContext.Provider>
  );
}
