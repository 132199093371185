import PageLayout from '@/wrappers/PageLayout';
import FeedbackWidget from '@/components/FeedbackWidget/FeedbackWidget';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { AmazonIntegrationWidget } from '@/components/Integrations/AmazonIntegrationWidget/AmazonIntegrationWidget';
import { JTLWidget } from '@/components/Integrations/JTLWidget';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { APIEventsEnum, useAppSelector, useSendEvent } from '@/hooks';
import {
  activeCompanySelector,
  companiesSelector,
  queryKeySelector,
  userSelector,
} from '@/core/store/user/userSlice';
import { useModal } from '@/components/Modal/useModal';
import AmazonTokenModal from '@/components/AmazonTokenModal/AmazonTokenModal';
import EasybillTokenModal from '@/components/EasybillTokenModal/EasybillTokenModal';
import BillbeeTokenModal from '@/components/BillbeeTokenModal/BillbeeTokenModal';
import { ConnectJTLModal } from '@/components/ConnectJTLModal/ConnectJTLModal';
import { RolesEnum } from '@/core/enums';
import { IntegrationWidget } from '@/components/Integrations/IntegrationWidget';
import BillbeeIcon from '@/icons/billbee.svg';
import EasybillIcon from '@/icons/easybill.svg';
import { useSellerIntegrations } from '@/components/Integrations/useSellerIntegrations';
import { useQueries } from '@tanstack/react-query';
import {
  getBillbee,
  getEasybillTokenStatus,
  getJTLCredentials,
  postClientAmazonConnect,
} from '@/core/api';
import { Loader } from '@/components/Loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import useNotification from '@/hooks/useNotification';

export const STATE_CONSTANT = 'customer-app';

export default function SellerIntegrations() {
  const { vs5739JtlWidget } = useFlags();
  const user = useAppSelector(userSelector);
  const activeCompany = useAppSelector(activeCompanySelector);

  const { t } = useTranslation();
  const { sendEvent } = useSendEvent();

  const [isConnectingAmazon, setIsConnectingAmazon] = useState(false);
  const navigate = useNavigate();
  const locaton = useLocation();
  const params = new URLSearchParams(locaton.search);
  const state = params.get('state');
  const spapi_oauth_code = params.get('spapi_oauth_code');
  const selling_partner_id = params.get('selling_partner_id');

  const [activeSellerId, setActiveSellerId] = useState<string>();
  const queryKey = useAppSelector(queryKeySelector);

  const companies = useAppSelector(companiesSelector);
  const { showNotification } = useNotification();

  const [easybill, billbee, jtlCredentials] = useQueries({
    queries: [
      {
        queryKey: ['SellerEasybill', queryKey],
        queryFn: () => getEasybillTokenStatus(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerBillbee', queryKey],
        queryFn: () => getBillbee(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerJtlCredentials', queryKey],
        queryFn: () => getJTLCredentials(activeCompany?.company_id),
      },
    ],
  });

  const {
    isLoading: isLoadingIntegrations,
    integrations,
    refetchIntegrations,
  } = useSellerIntegrations();

  useEffect(() => {
    if (
      state &&
      state.includes(STATE_CONSTANT) &&
      spapi_oauth_code &&
      selling_partner_id
    ) {
      const [user_id, company_id] = state
        .replace(STATE_CONSTANT, '')
        .split('.');
      if (
        companies.find((c) => c.company_id === company_id) &&
        user_id === user?.Id
      ) {
        setIsConnectingAmazon(true);
        postClientAmazonConnect({
          seller_id: selling_partner_id,
          oauth_code: spapi_oauth_code,
          company_id,
        })
          .then(() => {
            showNotification({
              text: t('Merchant token *seller_id* connected').replace(
                '*seller_id*',
                selling_partner_id
              ),
              type: 'success',
            });
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setIsConnectingAmazon(false);
            refetchIntegrations();
            navigate('/', { replace: true });
          });
      }
    }

    // eslint-disable-next-line
  }, [state, spapi_oauth_code, selling_partner_id]);

  const {
    isModalOpened: isAmazonTokenModalOpened,
    closeModal: onCloseAmazonTokenModal,
    openModal: openAmazonTokenModal,
  } = useModal();

  const {
    isModalOpened: isEasybillTokenModalOpened,
    closeModal: closeEasybillTokenModal,
    openModal: openEasybillTokenModal,
  } = useModal();

  const {
    isModalOpened: isBillbeeTokenModalOpened,
    closeModal: closeBillbeeTokenModal,
    openModal: openBillbeeTokenModal,
  } = useModal();

  const {
    isModalOpened: isConnectJTLModalOpened,
    closeModal: closeConnectJTLModal,
    openModal: openConnectJTLModal,
  } = useModal();

  const closeAmazonTokenModal = () => {
    activeSellerId && setActiveSellerId(undefined);
    onCloseAmazonTokenModal();
  };

  const isEasybillConnected =
    easybill.data && (easybill.data.is_valid || easybill.data.invalid_date);

  const onClickUpdateAmazonItem = (id: string | undefined) => () => {
    setActiveSellerId(id);
    sendEvent(APIEventsEnum.click_connect_amazon, {
      block: 'Click on Amazon connect button',
    });
    openAmazonTokenModal();
  };

  const sellerWidgets = useMemo(
    () =>
      user?.Role === RolesEnum.SELLER
        ? [
            {
              name: 'billbee',
              isConnected: billbee?.data?.is_active,
              component: billbee?.data ? (
                <IntegrationWidget
                  name='billbee'
                  iconSrc={BillbeeIcon}
                  data={billbee.data}
                  onClickConnect={() => {
                    sendEvent(APIEventsEnum.click_connect_billbee, {
                      block: 'Click on Billbee connect button',
                    });
                    openBillbeeTokenModal();
                  }}
                  label='Billbee'
                />
              ) : null,
            },
            {
              name: 'easybill',
              isConnected: easybill.data && easybill.data.is_valid,
              component: easybill.data ? (
                <IntegrationWidget
                  name='easybill'
                  iconSrc={EasybillIcon}
                  data={easybill.data}
                  onClickConnect={() => {
                    sendEvent(APIEventsEnum.click_connect_easybill, {
                      block: 'Click on easybill connect button',
                    });
                    openEasybillTokenModal();
                  }}
                  label='Easybill'
                />
              ) : null,
            },
          ]
        : [],
    // eslint-disable-next-line
    [easybill, billbee]
  );

  const hasJTLCreds = !!jtlCredentials.data;

  const widgets = useMemo(
    () => [
      ...(vs5739JtlWidget
        ? [
            {
              name: 'jtl',
              isConnected: hasJTLCreds,
              component: (
                <JTLWidget
                  jtlCredentials={jtlCredentials.data}
                  onClickConnect={openConnectJTLModal}
                  prevRoute={locaton.pathname}
                />
              ),
            },
          ]
        : []),
      {
        name: 'amazon',
        isConnected: (integrations || []).some(
          (integration) => !integration.invalid
        ),
        component: (
          <AmazonIntegrationWidget
            integrations={integrations}
            onClickUpdate={onClickUpdateAmazonItem}
          />
        ),
      },
      ...sellerWidgets,
    ],
    // eslint-disable-next-line
    [integrations, sellerWidgets, vs5739JtlWidget, hasJTLCreds]
  );

  if (isLoadingIntegrations || isConnectingAmazon) return <Loader />;

  return (
    <PageLayout
      className='flex-grow'
      rightColumn={
        <div
          style={{
            maxWidth: 328,
          }}
          className='pt-4 lg:ml-auto lg:pt-0'
        >
          <FeedbackWidget testId='FeedbackWidget' />
        </div>
      }
    >
      <div className='mx-auto max-w-[874px] rounded-lg bg-white px-6 pb-6 pt-4'>
        <div className='SellerVATIds__title font-black'>
          {t('Integrations')}
        </div>
        <div className='flex flex-col gap-y-2 py-4'>
          {widgets.map((w) => (
            <div
              className='rounded-lg border border-gray-500 px-4 py-2'
              key={w.name}
            >
              {w.component}
            </div>
          ))}
        </div>
        <div className='leading-5 text-gray-700'>
          {t(
            "Changes made to transactions after the 5th of the month won't be included in filings due to our data processing deadline."
          )}
        </div>
      </div>
      <AmazonTokenModal
        closeModal={closeAmazonTokenModal}
        isModalOpened={isAmazonTokenModalOpened}
        sellerId={activeSellerId}
      />
      <EasybillTokenModal
        closeModal={closeEasybillTokenModal}
        isModalOpened={isEasybillTokenModalOpened}
        initialIsTokenValid={
          isEasybillConnected ? easybill.data.is_valid : true
        }
      />
      <BillbeeTokenModal
        closeModal={closeBillbeeTokenModal}
        isModalOpened={isBillbeeTokenModalOpened}
      />
      <ConnectJTLModal
        closeModal={closeConnectJTLModal}
        isModalOpened={isConnectJTLModalOpened}
        jtlCredentials={jtlCredentials.data}
        prevRoute={locaton.pathname}
      />
    </PageLayout>
  );
}
