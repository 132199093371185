import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useModal } from '@/components/Modal/useModal';
import {
  getCompletedFiling,
  getOngoingFiling,
  postConfirmPayment,
} from '@/core/api';
import { NormalizedSellerFilingReport } from '@/core/types';
import { APIEventsEnum, useAppSelector } from '../../../hooks';
import {
  activeCompanySelector,
  queryKeySelector,
  userSelector,
} from '@/core/store/user/userSlice';
import useNotification from '../../../hooks/useNotification';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { CUSTOMER_UPLOADS_BUCKET, getS3Client } from '@/core/s3';
import { format } from 'date-fns';
import { normalizeDateFE } from '@/core/normalization';
import * as Sentry from '@sentry/browser';
import { sleep } from '@/core/helpers';
import { useNPSModal } from '@/components/NPSModal/useNPSModal';

export default function usePaymentInstructionModal() {
  const filesErrorsCountRef = useRef(0);
  const s3ClientRef = useRef<S3Client | null>(null);
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();
  const user = useAppSelector(userSelector);
  const queryKey = useAppSelector(queryKeySelector);
  const activeCompany = useAppSelector(activeCompanySelector);
  const { t } = useTranslation();
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);

  const { openModal: openNPSModal } = useNPSModal();

  const [reportToConfirmPayment, setNormalizedSellerFilingReportPayment] =
    useState<NormalizedSellerFilingReport | undefined>(undefined);

  const {
    isModalOpened: isPaymentInstructionModalOpened,
    closeModal: closePaymentInstructionModal,
    openModal: openPaymentInstructionModal,
  } = useModal();

  const uploadFile = async (
    report: NormalizedSellerFilingReport,
    confirmationId: number,
    file: File,
    i: number
  ) => {
    filesErrorsCountRef.current = 0;
    const ext = file.name.split('.').slice(-1)[0];

    try {
      await s3ClientRef.current!.send(
        new PutObjectCommand({
          Bucket: CUSTOMER_UPLOADS_BUCKET,
          Key: `${
            activeCompany!.company_id
          }/payment-confirmations/${confirmationId}/payment_confirmation_${
            report.type === 'oss' ? 'OSS' : report.country.short_name
          }_${format(
            normalizeDateFE(report.period.from_date),
            report.period.type === 'year'
              ? 'yyyy'
              : report.period.type === 'quarter'
                ? 'QQQ yyyy'
                : 'MMM yyyy'
          ).replace(' ', '_')}${i > 0 ? `(${i})` : ''}_${Date.now()}.${ext}`,
          Body: file,
          Metadata: {
            externalUserUuid: user!.AmazonCognitoId,
            confirmationId: String(confirmationId),
            type: 'payment_confirmation',
            clientUuid: activeCompany!.company_id,
          },
        })
      );
    } catch (error) {
      Sentry.captureException(error);
      filesErrorsCountRef.current++;
      showNotification({
        text: t('Error has occurred while uploading file *filename*').replace(
          '*filename*',
          file.name
        ),
        type: 'error',
      });
    }
  };

  const onClickConfirmPayment =
    (report: NormalizedSellerFilingReport | undefined) =>
    async (files: File[]) => {
      if (!report || !activeCompany) return;

      try {
        setIsConfirmingPayment(true);
        let confirmationId =
          report?.payment_instruction!.payment_confirmation?.id;

        if (!confirmationId && report.payment_instruction) {
          confirmationId = await postConfirmPayment(
            report.payment_instruction.id,
            activeCompany?.company_id
          );
        }

        if (!!files?.length) {
          s3ClientRef.current = await getS3Client();
          await Promise.all(
            files.map((f, i) => uploadFile(report, confirmationId!, f, i))
          );
          s3ClientRef.current.destroy();
        }

        await sleep(3000);

        const [ongoingRes, completedRes] = await Promise.all([
          getOngoingFiling(activeCompany?.company_id),
          getCompletedFiling(activeCompany?.company_id),
        ]);

        if (
          report.payment_instruction?.confirmed &&
          !!files?.length &&
          filesErrorsCountRef.current === 0
        ) {
          showNotification({
            text: 'File uploaded successfully',
            type: 'success',
          });
        }

        if (
          !report.payment_instruction?.confirmed &&
          (!files?.length || filesErrorsCountRef.current === 0)
        ) {
          showNotification({
            text: t('*country* marked as paid').replace(
              '*country*',
              t(report.country.short_name)
            ),
            type: 'success',
          });
        }

        queryClient.setQueryData(['SellerOngoingFiling', queryKey], ongoingRes);

        queryClient.setQueryData(
          ['SellerPastPeriodsFiling', queryKey],
          completedRes
        );

        setIsConfirmingPayment(false);

        closePaymentInstructionModal();
        openNPSModal(APIEventsEnum.payment_instruction_marked_paid);
      } catch {
        setIsConfirmingPayment(false);
      }
    };

  const handleOpenPaymentInstructionModal = useCallback(
    (r: NormalizedSellerFilingReport) => () => {
      setNormalizedSellerFilingReportPayment(r);
      openPaymentInstructionModal();
    },
    // eslint-disable-next-line
    []
  );

  return {
    onClickConfirmPayment,
    isPaymentInstructionModalOpened,
    closePaymentInstructionModal,
    reportToConfirmPayment,
    handleOpenPaymentInstructionModal,
    isConfirmingPayment,
  };
}
