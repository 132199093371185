import { useTranslation } from 'react-i18next';
import Button from '../Button';
import GearIcon from '@/icons/gear.svg?react';
import { getTypeformSubmissions, postTypeformSubmission } from '@/core/api';
import { PopupButton } from '@typeform/embed-react';
import { useQueryClient } from '@tanstack/react-query';
import { useAppSelector } from '@/hooks';
import { userSelector } from '@/core/store/user/userSlice';
interface Props {
  onClick?: () => void;
  className?: string;
  canSubmitTypeform?: boolean;
}

export const FF = 'vs-5524-cp-datev-settings-fakedoor';

export default function DatevSettingsBlock({
  onClick,
  className = '',
  canSubmitTypeform,
}: Props) {
  const { t, i18n } = useTranslation();
  const user = useAppSelector(userSelector);
  const queryClient = useQueryClient();
  return (
    <div
      className={`flex w-full items-center justify-between border-b border-gray-500 py-4 ${className}`}
    >
      <div className='flex gap-x-8'>
        <div className='flex items-center gap-x-1'>
          <span className='font-semibold'>{t('Konto format')}</span>
          <span className='rounded-full  bg-gray-70 px-2.5 py-1'>
            <span>5</span> <span>{t('digits')}</span>
          </span>
        </div>
        <div className='flex items-center gap-x-1'>
          <span className='font-semibold'>{t('Gegenkonto format')}</span>
          <span className='rounded-full  bg-gray-70 px-2.5 py-1'>SKR03</span>
        </div>
      </div>
      {canSubmitTypeform && (
        <PopupButton
          id={i18n.language === 'en' ? 'dQ8kepWh' : 'oAdYj6iC'}
          size={80}
          hidden={{
            email: user?.Email || '',
            user_id: String(user?.Id) || '',
          }}
          className='Button--outline flex h-[40px] items-center !py-2'
          onSubmit={async (event) => {
            document.body.style.overflow = '';
            postTypeformSubmission({
              response_id: event.responseId,
              form_id: event.formId,
              feature_flag: FF,
            })
              .then(() => {
                return getTypeformSubmissions(user?.Id);
              })
              .then((res) => {
                queryClient.setQueryData(
                  ['UserTypeformSubmissions', user?.Id],
                  res || []
                );
              })
              .catch(console.error);
          }}
        >
          <GearIcon className='mr-2 h-[24px] w-[24px]' />
          <span>{t('Settings')}</span>
        </PopupButton>
      )}
      {onClick && (
        <Button
          styling='outline'
          className='flex h-[40px] items-center !py-2'
          onClick={onClick}
        >
          <GearIcon className='mr-2 h-[24px] w-[24px]' />
          <span>{t('Settings')}</span>
        </Button>
      )}
    </div>
  );
}
