import { FormikErrors, FormikTouched, useFormik } from 'formik';
import Input from '@/components/Input/Input';
import { login } from '@/core/auth';
import { isEmail, setTokensToLocalStorage } from '@/core/helpers';
import './Login.scss';
import { ChangeEventHandler, useState } from 'react';
import { Loader } from '@/components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import {
  getAmazonConnectCredentials,
  getUser,
  getUserCompanies,
} from '@/core/api';
import Header from '@/components/Header/Header';
import { PublicRoutesEnum } from '@/core/enums';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks';
import Button from '@/components/Button';
import RegisterVerification from '../RegisterUser/RegisterVerification';

interface FormValues {
  email: string;
  password: string;
}

const Login = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { authUser } = useAuth();
  const [error, setError] = useState('');
  const [isValidateEmailShown, setIsValidateEmailShown] = useState(false);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      email: '',
      password: '',
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.email) {
        errors.email = t('Required')!;
      }
      if (values.email !== '' && !isEmail(values.email)) {
        errors.email = t(`Email is not valid`)!;
      }
      if (!values.password) {
        errors.password = t('Required')!;
      }

      return errors;
    },
    onSubmit: ({ email, password }) => {
      if (error) setError('');
      setIsLoading(true);
      login({ email, password })
        .then((res) => {
          setTokensToLocalStorage(
            res.AuthenticationResult!.AccessToken!,
            res.AuthenticationResult!.RefreshToken!,
            res.AuthenticationResult!.IdToken!
          );

          return Promise.all([
            getUser(),
            getAmazonConnectCredentials(),
            getUserCompanies(),
          ]);
        })
        .then((responses) => {
          authUser(responses);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (
            err.name === 'NotAuthorizedException' ||
            err.name === 'UserNotFoundException'
          ) {
            setError('Incorrect username or password');
          } else if (err.name === 'UserNotConfirmedException') {
            setIsValidateEmailShown(true);
          } else {
            setError('Something went wrong, please try again');
          }
        });
    },
  });

  const onChangeField: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (error) setError('');
    formik.setFieldValue(
      e.target.name,
      e.target.value,
      formik.touched[e.target.name as keyof FormikTouched<FormValues>]
    );
  };

  if (isLoading) return <Loader />;

  if (isValidateEmailShown) {
    return (
      <RegisterVerification
        email={formik.values.email}
        password={formik.values.password}
      />
    );
  }

  return (
    <>
      <Header />
      <div
        className='flex min-h-[calc(100vh-91px)] items-center justify-center bg-white px-4 py-8 lg:px-6 2xl:px-14'
        data-testid='login'
      >
        <div className='Login__container lg:mb-12 lg:pb-12'>
          <div className='Title--lg mb-6 font-black lg:text-center'>
            {t('Welcome')}
          </div>
          <form onSubmit={formik.handleSubmit} noValidate className='w-full'>
            <div className='Login__content items-start'>
              <Input
                label='Email'
                placeholder='Enter your email'
                name='email'
                id='email'
                onChange={onChangeField}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.errors.email}
                touched={formik.touched.email}
              />
              <Input
                wrapperClassName='mt-4'
                type='password'
                label='Password'
                placeholder='Enter your password'
                name='password'
                id='password'
                onChange={onChangeField}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                touched={formik.touched.password}
                error={formik.errors.password}
              />
            </div>
            <div>
              <Button
                className='Login__btn mt-4 !w-full'
                type='submit'
                disabled={isLoading}
              >
                {t('Sign In')}
              </Button>
              {error && (
                <div className='Login__error mt-2 text-center'>{t(error)}</div>
              )}
            </div>
          </form>
          <div className='Text mt-4 pt-2 text-center'>
            <Link
              to={PublicRoutesEnum.ForgotPassword}
              className='text-blue-500 transition-colors hover:text-blue-600'
            >
              {t('I forgot my password')}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
