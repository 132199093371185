import { NormalizedEvaluation } from '@/core/types';
import FileIcon from '../FileIcon';
import { useTranslation } from 'react-i18next';
import { EvaluatiionStatusEnum } from '@/core/enums';
import CloseIcon from '@/icons/close.svg?react';
import { Spinner } from '../Spinner';
import { formatDate } from '@/core/helpers';
import Button from '../Button';
import TipIconOutline from '@/icons/tip-icon-outline.svg?react';
import { useDownloadInvoiceBook } from '@/pages/InvoiceBook/useDownloadInvoiceBook';

interface Props {
  evaluation: NormalizedEvaluation;
  close: () => void;
}

export const InvoiceBookToast = ({ evaluation, close }: Props) => {
  const { onClickDownload } = useDownloadInvoiceBook();

  const { t } = useTranslation();
  const isGenerating =
    evaluation.status === EvaluatiionStatusEnum.Generating ||
    evaluation.status === EvaluatiionStatusEnum.Requested;

  const label = (
    <div>
      {t('Invoice book for')}&nbsp;
      {evaluation.type === 'month'
        ? formatDate(evaluation.startDate, 'MMMM yyyy')
        : formatDate(evaluation.startDate, 'QQQ yyyy')}
    </div>
  );

  if (isGenerating) {
    return (
      <div className='flex flex-grow animate-fadeInRight gap-x-2 rounded-2xl border-2 border-transparent bg-white pb-2 pl-4 pr-2 pt-4 shadow-custom'>
        <FileIcon ext='xls' styling='circled' />
        <div className='flex-grow'>
          <div className='font-bold'>{t('Generating file')}</div>
          {label}
          <Button
            onClick={close}
            className='mt-2 !font-semibold'
            styling='naked'
          >
            {t('Cancel')}
          </Button>
        </div>
        <div>
          <div className='flex items-center gap-x-2'>
            {isGenerating ? (
              <Spinner className='!h-8 !w-8 !border-[3px]' />
            ) : (
              <TipIconOutline />
            )}
            <button
              className='Modal__btn Modal__closeBtn flex items-center justify-center'
              onClick={close}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className='group z-50 flex flex-grow animate-fadeInRight cursor-pointer gap-x-2 rounded-2xl border-2 border-transparent bg-white pb-2 pl-4 pr-2 pt-4 shadow-custom transition-colors hover:border-green'
      onClick={() => {
        onClickDownload(evaluation);
        setTimeout(() => {
          close();
        }, 500);
      }}
    >
      <FileIcon ext='xls' styling='circled' />
      <div className='flex-grow'>
        <div className='font-bold'>{t('File is ready')}</div>
        {label}
        <Button
          data-testid={`download-invoice-book-toast-btn-${formatDate(
            evaluation.startDate,
            evaluation.type === 'month' ? 'MM-yyyy' : 'QQQ-yyyy'
          )}`}
          className='mt-2 !font-semibold group-hover:text-green'
          styling='naked'
        >
          {t('Download')}
        </Button>
      </div>
      <div>
        <div className='flex items-center gap-x-2'>
          {isGenerating ? (
            <Spinner className='!h-8 !w-8 !border-[3px]' />
          ) : (
            <TipIconOutline />
          )}
          <button
            className='Modal__btn Modal__closeBtn flex items-center justify-center'
            onClick={(e) => {
              e.stopPropagation();
              close();
            }}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
