import { PropsWithChildren } from 'react';
import ArrowDownIcon from '@/icons/chevron-down.svg?react';
import './HoverDropdown.scss';

interface Props {
  className?: string;
  trigger: JSX.Element;
  arrow?: boolean;
}

export default function HoverDropdown({
  className = '',
  trigger,
  children,
  arrow = true,
}: PropsWithChildren<Props>) {
  return (
    <div className={`HoverDropdown ${className}`}>
      <div className='HoverDropdown__trigger flex items-center'>
        {trigger}
        {arrow && <ArrowDownIcon className='HoverDropdown__arrow' />}
      </div>
      <div className='HoverDropdown__menu'>
        <div className='HoverDropdown__content'>{children}</div>
      </div>
    </div>
  );
}
