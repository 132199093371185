import { useTranslation } from 'react-i18next';
import Modal from './Modal/Modal';
import InterviewIcon from '@/icons/interview.svg?react';
import Button from './Button';
import { APIEventsEnum, useSendEvent } from '../hooks';
import { useBookMeetingModal } from './BookMeetingModal/useBookMeetingModal';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  content?: {
    heading: string;
    description: string;
    hint?: string;
  };
  btn?: JSX.Element;
  className?: string;
  icon?: JSX.Element;
}

export default function FeatureUnderDevelopmentModal({
  icon,
  isModalOpened,
  closeModal,
  className = '',
  content = {
    heading:
      'DATEV reports are under development.  Reach out to our team to get early access.',
    description:
      'During the meeting, we will provide an introduction to the functionality and discuss your expectations and potential configurations together.',
    hint: 'Up to 30 mins • English',
  },
  btn,
}: Props) {
  const { t, i18n } = useTranslation();
  const { openModal: openBookMeetingModal } = useBookMeetingModal();
  const { sendEvent } = useSendEvent();

  return (
    <Modal isOpened={isModalOpened} close={closeModal} name='datev-modal'>
      <div
        className={`mx-4 flex flex-col items-center gap-y-2 px-4 pb-14 pt-2 ${className}`}
      >
        {icon || <InterviewIcon />}
        <div className='whitespace-pre-line pt-2 text-center text-h4 font-extrabold'>
          {t(content.heading)}
        </div>
        <div className='whitespace-pre-line text-center'>
          {t(content.description)}
        </div>
        {content.hint && <div className='text-center'>{t(content.hint)}</div>}
        <div className='pt-2'>
          {btn || (
            <Button
              size='lg'
              className='!px-6'
              onClick={() => {
                closeModal();
                sendEvent(APIEventsEnum.click_datev_schedule_interview, {
                  language: i18n.language,
                });
                openBookMeetingModal(
                  'https://vat.countx.com/meetings/cornelius/datev-export?embed=true'
                );
              }}
            >
              {t('Book a meeting')}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
