import { createContext, PropsWithChildren } from 'react';
import { useState, useEffect, useRef } from 'react';
import { deleteCookie, isTokenInvalid, setCookie } from '../helpers';
import { WS_MESSAGE_EVENT_KEY, WsMessage } from './ws-messages';
import { ACCESS_TOKEN_KEY, ID_TOKEN_KEY } from '../constants';
import { refreshTokens } from '../api';

interface WebSocketContextType {
  sendMessage: (message: WsMessage) => void;
}

export const WebSocketContext = createContext<WebSocketContextType>(
  {} as WebSocketContextType
);

export const WebSocketProvider = ({ children }: PropsWithChildren) => {
  const attempts = useRef(0);
  const [socket, setSocket] = useState<WebSocket | null>(null);

  useEffect(() => {
    if (socket) {
      const onOpen = () => {
        attempts.current = 0;
      };

      const onMessage = (event: MessageEvent) => {
        document.dispatchEvent(
          new CustomEvent(WS_MESSAGE_EVENT_KEY, {
            detail: JSON.parse(event.data),
          })
        );
      };

      const onClose = () => {
        setSocket(null);
      };

      socket.addEventListener('open', onOpen);
      socket.addEventListener('message', onMessage);
      socket.addEventListener('close', onClose);

      return () => {
        socket.removeEventListener('open', onOpen);
        socket.removeEventListener('message', onMessage);
        socket.removeEventListener('close', onClose);
        socket.close();
        deleteCookie('token');
        deleteCookie('id-token');
      };
    }

    if (!socket && attempts.current < 5) {
      (async () => {
        attempts.current = attempts.current + 1;

        const url = import.meta.env.VITE_APP_WS_URL;
        if (!url) {
          throw new Error('VITE_APP_WS_URL is not defined');
        }
        const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) || '';

        if (!accessToken) {
          return;
        }

        if (isTokenInvalid(accessToken)) {
          await refreshTokens();
        }

        setCookie('token', localStorage.getItem(ACCESS_TOKEN_KEY)!);
        setCookie('id-token', localStorage.getItem(ID_TOKEN_KEY)!);

        const ws = new WebSocket(
          import.meta.env.MODE === 'test'
            ? url
            : import.meta.env.MODE === 'development' ||
                import.meta.env.VITE_APP_ENV === 'development'
              ? `wss://${url}?token=${localStorage.getItem(
                  ACCESS_TOKEN_KEY
                )!}&id-token=${localStorage.getItem(ID_TOKEN_KEY)!}`
              : `wss://${url}`
        );

        setSocket(ws);
      })();
    }
  }, [socket]);

  const sendMessage = (message: WsMessage) => {
    if (socket) {
      socket.send(JSON.stringify(message));
    }
  };

  return (
    <WebSocketContext.Provider
      value={{
        sendMessage,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
