import { useMemo } from 'react';
import { Period } from '@/components/PeriodsDropdown/PeriodsDropdown';
import { getQuarter } from 'date-fns';
import { PeriodTypeEnum } from '@/core/types';

export const usePeriods = (
  startYear: number = 2000,
  excludeCurrentPeriod: boolean = false
) => {
  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }

    return years;
  }, [startYear]);

  const periods = useMemo(() => {
    const allPeriods: Period[][] = [];

    years.forEach((year) => {
      const options = [];

      for (let i = 0; i < 12; i++) {
        options.push({
          id: `${PeriodTypeEnum.month}-${i}-${year}`,
          value: i,
          type: PeriodTypeEnum.month,
          year,
        });
      }

      for (let i = 1; i <= 4; i++) {
        options.push({
          id: `${PeriodTypeEnum.quarter}-${i}-${year}`,
          value: i,
          type: PeriodTypeEnum.quarter,
          year,
        });
      }

      const date = new Date();
      allPeriods.push(
        options.filter((o) => {
          if (o.type === PeriodTypeEnum.month) {
            return year === date.getFullYear()
              ? excludeCurrentPeriod
                ? o.value < date.getMonth()
                : o.value <= date.getMonth()
              : true;
          }
          return year === date.getFullYear()
            ? excludeCurrentPeriod
              ? o.value < getQuarter(date)
              : o.value <= getQuarter(date)
            : true;
        })
      );
    });

    return allPeriods.flat();
  }, [years, excludeCurrentPeriod]);

  return { periods, years };
};
