import { useState } from 'react';
import MiniLoader from './MiniLoader/MiniLoader';
import CloseIcon from '@/icons/close.svg?react';
import PDFIcon from '@/icons/pdf-icon.svg?react';
import PNGIcon from '@/icons/png-icon.svg?react';
import JPGIcon from '@/icons/jpg-icon.svg?react';
import DownloadIcon from '@/icons/download.svg?react';
import XLSIcon from '@/icons/xls-icon.svg?react';
import XLSXIcon from '@/icons/xlsx-icon.svg?react';
import { format } from 'date-fns';

interface Props {
  filename: string;
  className?: string;
  onDownload?: (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => () => void;
  onDelete?: () => void;
  createdAt?: string;
}

const UploadIconsMap: {
  [key: string]: JSX.Element;
} = {
  pdf: <PDFIcon />,
  png: <PNGIcon />,
  jpg: <JPGIcon />,
  xls: <XLSIcon />,
  xlsx: <XLSXIcon />,
};

export default function SellerFilingFile({
  filename,
  className = '',
  onDownload,
  onDelete,
  createdAt,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const parts = filename.split('/');
  const name = parts[parts.length - 1];
  const ext = name.split('.').slice(-1)[0];

  return (
    <button
      className={`duration-250 w-full rounded-lg border border-gray-500 bg-transparent px-4 py-2 text-start outline outline-1 outline-transparent transition-all hover:border-dark hover:outline-dark  ${className}`}
      onClick={onDownload ? onDownload(setIsLoading) : undefined}
      type='button'
      disabled={isLoading}
    >
      <div className='flex w-full items-center justify-between'>
        <div className='flex w-full items-center justify-between'>
          <div className='SellerFilingFile__info flex items-center'>
            <span className='SellerFilingFile__icon mr-4 rounded-full'>
              {UploadIconsMap[ext.toLowerCase()]}
            </span>
            <div>
              <div className='SellerFilingFile__name aling-self-center font-bold'>
                {name}
              </div>
              {createdAt && (
                <div>{format(new Date(createdAt), 'dd.MM.yyyy HH:mm')}</div>
              )}
            </div>
          </div>
          <div className='flex gap-x-2'>
            {isLoading && <MiniLoader />}
            {onDelete ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
                className='flex p-1'
              >
                <CloseIcon className='m-auto' />
              </div>
            ) : (
              <DownloadIcon className='[&>path]:fill-dark' />
            )}
          </div>
        </div>
      </div>
    </button>
  );
}
