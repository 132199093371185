import {
  CountryReportGroup,
  PeriodTypeReportGroup,
} from '@/pages/Seller/SellerFiling/SellerFiling.helpers';
import CountryLabel from '../CountryLabel/CountryLabel';
import FolderIcon from '@/icons/folder-outline.svg?react';
import AgendaIcon from '@/icons/agenda-circle.svg?react';
import SubdirectoryIcon from '@/icons/subdirectory-right.svg?react';
import Button from '../Button';
import AgendaSubmittedIcon from '@/icons/agenda-submitted.svg?react';
import { v4 as uuidv4 } from 'uuid';
import PaymentInstructionBtn from '@/wrappers/PaymentInstructionBtn';
import Tooltip from '../Tooltip/Tooltip';
import { sortReportsFn } from '../../pages/Seller/SellerFiling/SellerFiling.helpers';
import { normalizeDateFE } from '@/core/normalization';
import { useAppSelector } from '@/hooks';
import { isOSSSelfServiceSelector } from '@/core/store/user/userSlice';
import {
  FilingReportConfirmation,
  NormalizedSellerFilingReport,
} from '@/core/types';
import {
  formatDate,
  formatNumber,
  getBoundingAbsoluteRect,
} from '@/core/helpers';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  isReportSubmitted,
  isShouldSubmitOSSReport,
} from '../OpenTasks/OpenTasks.helpers';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  group: PeriodTypeReportGroup;
  countryGroup: CountryReportGroup;
  onFolderBtnClick: (
    confirmations: FilingReportConfirmation[],
    date: Date,
    country: string
  ) => () => void;
  onAmountBtnClick: (r: NormalizedSellerFilingReport) => () => void;
  onClickAgenda: (r: NormalizedSellerFilingReport) => () => void;
  month: Date;
}

export const SellerFilingPastPeriodsGroup = ({
  group,
  countryGroup,
  onFolderBtnClick,
  onAmountBtnClick,
  onClickAgenda,
  month,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const isOSSSelfService = useAppSelector(isOSSSelfServiceSelector);
  const { t, i18n } = useTranslation();

  const reports = group.reports;
  const confirmations = useMemo(
    () =>
      reports
        .map((v) => v.confirmation)
        .filter((v) => !!v)
        .filter((v, i, self) => self.findIndex((s) => s.id === v.id) === i),
    [reports]
  );

  useEffect(() => {
    if (
      location.hash?.includes('confirmation') &&
      confirmations.some(
        (c) => c.id === +location.hash.slice(1).split('-')[1]
      ) &&
      ref.current
    ) {
      onFolderBtnClick(
        confirmations,
        month,
        countryGroup.type === 'oss' ? 'OSS' : countryGroup.country
      )();
      const rect = getBoundingAbsoluteRect(ref.current);
      window.scrollTo(0, rect.top - 70);

      navigate(
        { pathname: location.pathname, hash: '', search: location.search },
        {
          replace: true,
          state: location.state,
        }
      );
    }
    // eslint-disable-next-line
  }, [confirmations]);

  const renderPeriod = (report: NormalizedSellerFilingReport) => {
    let formatStr = 'MMM yyyy';

    if (report.period.type === 'year') {
      return (
        <>
          <span>{t('Year')}&nbsp;</span>
          <span>
            {formatDate(normalizeDateFE(report.period.from_date), 'yyyy')}
          </span>
        </>
      );
    }

    if (
      report.country.short_name === 'IT' &&
      report.period.type !== 'quarter'
    ) {
      return (
        <>
          <span>
            {formatDate(
              normalizeDateFE(report.period.from_date),
              report.period.type === 'month' ? 'MMM yyyy' : formatStr
            )}
          </span>{' '}
          <span>{t('payment')}</span>
        </>
      );
    }

    if (report.period.type === 'quarter') {
      formatStr = 'QQQ yyyy';
    }

    return formatDate(normalizeDateFE(report.period.from_date), formatStr);
  };

  const reportForAgenda = group.reports.find(
    (reports) =>
      reports.country.short_name === 'DE' &&
      reports.type === 'local' &&
      !!reports.payment_instruction
  );
  const isAgendaConfirmed = group.reports.some(
    (reports) =>
      reports.country.short_name === 'DE' &&
      reports.type === 'local' &&
      !!reports.payment_instruction &&
      reports.agenda_confirmed
  );

  return (
    <div
      className='grid w-full grid-cols-[190px,minmax(100px,1.2fr),minmax(300px,2.8fr),40px] items-start gap-x-2'
      ref={ref}
    >
      <div className='flex items-center gap-x-2 pt-2'>
        <CountryLabel
          country={countryGroup.type === 'oss' ? 'EU' : countryGroup.country}
        />
        {!!reportForAgenda && (
          <div className='ml-4'>
            {isAgendaConfirmed ? <AgendaSubmittedIcon /> : <AgendaIcon />}
          </div>
        )}
      </div>
      <div className='pt-2'>{renderPeriod(group.reports[0])}</div>
      <div className='flex flex-grow flex-col space-y-2'>
        <div className='flex flex-col gap-y-2'>
          {group.reports.sort(sortReportsFn).map((report, index) => {
            return (
              <div
                className='space-y-2'
                key={
                  report.payment_instruction?.id
                    ? countryGroup.country +
                      countryGroup.type +
                      report.payment_instruction.id
                    : uuidv4()
                }
              >
                <div className='flex w-full items-center justify-end gap-x-4'>
                  <div className='flex justify-end gap-x-2'>
                    <div
                      className={classNames('font-semibold', {
                        'text-gray-700': report.payment_instruction?.cancelled,
                      })}
                    >
                      {report.payment_instruction
                        ? formatNumber(
                            report.payment_instruction.amount.amount / 100,
                            {
                              currency:
                                report.payment_instruction.amount.currency,
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            },
                            undefined,
                            true
                          )
                        : t('No payment')}
                    </div>
                    {!!index && <SubdirectoryIcon />}
                  </div>
                  <PaymentInstructionBtn
                    className={`flex w-full shrink-0 items-center justify-between ${
                      i18n.language === 'en' ? '!w-[160px]' : '!w-[180px]'
                    }`}
                    report={report}
                    disabled={
                      isOSSSelfService &&
                      isShouldSubmitOSSReport(report) &&
                      !isReportSubmitted(report)
                    }
                    onClick={onAmountBtnClick(report)}
                  />
                </div>
              </div>
            );
          })}
        </div>
        {!!reportForAgenda && (
          <div className='space-y-2'>
            <div className='flex w-full items-center justify-end gap-x-4'>
              <div className='flex justify-end gap-x-2'>
                <div className={classNames('font-semibold')}>{t('Agenda')}</div>
              </div>
              <PaymentInstructionBtn
                className={`flex w-full shrink-0 items-center justify-between ${
                  i18n.language === 'en' ? '!w-[160px]' : '!w-[180px]'
                }`}
                report={reportForAgenda}
                showAgendaBtn={true}
                onClick={onClickAgenda(reportForAgenda)}
              />
            </div>
          </div>
        )}
      </div>
      <div className='flex justify-center pt-2'>
        <Tooltip
          delay={500}
          content={
            confirmations.length > 0 ? t('Open') : t('No files for this period')
          }
        >
          <Button
            styling='naked'
            className='SellerPastPeriodsFiling__btn flex items-center'
            onClick={
              confirmations.length > 0
                ? onFolderBtnClick(
                    confirmations,
                    month,
                    countryGroup.type === 'oss' ? 'OSS' : countryGroup.country
                  )
                : undefined
            }
            disabled={!confirmations.length}
          >
            <FolderIcon className='SellerPastPeriodsFiling__icon' />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
