import { useMemo, useState } from 'react';

export const useSearch = <T,>(
  data: T[] | undefined,
  filterFn: (str: string) => (a: T) => boolean
) => {
  const [searchString, setSearchString] = useState('');

  const onSearchChange = (v: string) => {
    setSearchString(v);
  };

  const found = useMemo(() => {
    return searchString && filterFn
      ? data?.filter(filterFn(searchString))
      : data;
  }, [data, filterFn, searchString]);

  return {
    found,
    setSearchString,
    searchString,
    onSearchChange,
  };
};
