import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { RolesEnum } from '../core/enums';
import { setHasNewAmazonCredentials } from '../core/store/amazon/amazonSlice';
import {
  setUser,
  setActiveCompany,
  setUserCompanies,
  User,
} from '../core/store/user/userSlice';
import { AmazonConnectCredentialsModel, UserCompany } from '../core/types';
import { STATE_CONSTANT } from '../pages/Seller/SellerDashboard';
import { useAppDispatch } from './useReduxToolkit';
import { APIEventsEnum, useSendEvent } from './useSendEvent';
import { usePaymentOverdueModal } from '../components/PaymentInstructionModal/usePaymentOverdueModal';

export const useAuth = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const companyIdParam = params.get('company_id');
  const state = params.get('state');
  const dispatch = useAppDispatch();
  const { sendEvent } = useSendEvent();

  const { openModal } = usePaymentOverdueModal();

  const authUser = useCallback(
    ([userRes, credsRes, companiesRes]: [
      User,
      AmazonConnectCredentialsModel[],
      UserCompany[],
    ]) => {
      let company =
        companiesRes instanceof Array && companiesRes[0]
          ? companiesRes[0]
          : undefined;

      const companyIdQueryParam =
        userRes.Role === RolesEnum.SELLER &&
        state &&
        state.includes(STATE_CONSTANT)
          ? state.replace(STATE_CONSTANT, '').split('.')[1]
          : companyIdParam;

      if (companyIdQueryParam && companiesRes instanceof Array) {
        const targetCompany = companiesRes.find(
          (c) => c.company_id === companyIdQueryParam
        );
        if (targetCompany) {
          company = targetCompany;
        }
      }

      if (
        userRes.Role === RolesEnum.SELLER &&
        (companiesRes || []).some((c) => c.PaymentOverdue)
      ) {
        sendEvent(APIEventsEnum.payment_overdue_client_login, {
          block: `Payment overdue modal opens for ${userRes.Id} client`,
        });
        openModal();
      }

      dispatch(setUserCompanies(companiesRes || []));
      dispatch(setActiveCompany(company));
      dispatch(setUser(userRes));
      if (!!credsRes.length) dispatch(setHasNewAmazonCredentials(true));
    },
    // eslint-disable-next-line
    [dispatch]
  );

  return {
    authUser,
  };
};
