import { useTranslation } from 'react-i18next';
import { lastDayOfMonth, subDays } from 'date-fns';
import Button, { BtnStyling } from '../Button';

import {
  SellerFilingReportFile,
  NormalizedSellerFilingReport,
} from '@/core/types';
import { getRetinaImgSrc } from '@/core/helpers';
import {
  TaskForEnum,
  isReportPaid,
  isReportSubmitted,
} from '../OpenTasks/OpenTasks.helpers';
import { normalizeDateFE } from '@/core/normalization';

import LinkIcon from '@/icons/link.svg?react';
import { OSSSeSeOpenTask } from '../OSSSeSeOpenTask/OSSSeSeOpenTask';
import { OSSSeSeTaskWrapper } from './OSSSeSeTaskWrapper';
import { activeCompanySelector } from '@/core/store/user/userSlice';
import { useAppSelector } from '@/hooks';
import { RolesEnum } from '@/core/enums';
import { OSSSeSeWidgetLink } from '../OSSSelfServiceWidget/OSSSeSeReportPreview';

interface Props {
  currentOSSReport: NormalizedSellerFilingReport;
  openSubmitBOPModal: (r: NormalizedSellerFilingReport) => () => void;
  handleOpenPaymentInstructionModal: (
    r: NormalizedSellerFilingReport
  ) => () => void;
  onClickDownload: (file: SellerFilingReportFile) => void;
  link?: OSSSeSeWidgetLink;
}

export interface TodoOSSSeSeItem {
  label: string;
  description: string | JSX.Element;
  btn?: {
    action?: string | (() => void) | JSX.Element;
    label?: string;
    icon?: JSX.Element;
    styling?: BtnStyling;
    disabled?: boolean;
    className?: string;
  };
  taskFor?: TaskForEnum;
  collapseContent?: JSX.Element;
  done?: boolean;
  inactive?: boolean;
}

export const OSSSeSeSteps = ({
  currentOSSReport,
  openSubmitBOPModal,
  handleOpenPaymentInstructionModal,
  onClickDownload,
  link,
}: Props) => {
  const { t, i18n } = useTranslation();
  const activeCompany = useAppSelector(activeCompanySelector);
  const submissionBy = activeCompany?.ClientOSSDetails?.SubmissionBy;
  const user = useAppSelector((state) => state.user.user);

  const isTaxAdvisorSubmits = submissionBy === RolesEnum.TAX_ADVISOR;
  const isSellerSubmits = submissionBy === RolesEnum.SELLER;
  const isTaxAdvisor = user?.Role === RolesEnum.TAX_ADVISOR;
  const isSeller = user?.Role === RolesEnum.SELLER;

  const deadlineDate = normalizeDateFE(
    currentOSSReport.payment_instruction?.due_date ||
      currentOSSReport.deadline_date
  );

  const processingDate = normalizeDateFE(
    currentOSSReport.payment_instruction?.due_date ||
      currentOSSReport.deadline_date
  );
  processingDate.setDate(subDays(lastDayOfMonth(deadlineDate), 3).getDate());

  const recommendedDueDate = normalizeDateFE(
    currentOSSReport.payment_instruction?.due_date ||
      currentOSSReport.deadline_date
  );
  recommendedDueDate.setDate(
    subDays(lastDayOfMonth(deadlineDate), 2).getDate()
  );

  const file = currentOSSReport.files.find((f) => f.option === 'oss-csv-bop');

  const isPaid = isReportPaid(currentOSSReport);
  const isSubmitted = isReportSubmitted(currentOSSReport);

  const preSubmitSteps: TodoOSSSeSeItem[] = [
    ...((isSeller && isSellerSubmits) || (isTaxAdvisorSubmits && isTaxAdvisor)
      ? [
          {
            label: 'Download the report',
            description: 'Once it is generated',
            btn: {
              label: 'Download',
              action: () => onClickDownload(file!),
              disabled: !(link && file),
              styling: 'outline' as BtnStyling,
            },
          },
          {
            label: 'Go to BOP portal',
            description: `Log in at https://www.elster.de/bportal/start`,
            btn: {
              action: 'https://www.elster.de/bportal/start',
              styling: 'outline' as BtnStyling,
              label: 'Open BOP portal',
              icon: <LinkIcon className='flex-shrink-0' />,
            },
          },
          {
            label: 'Go to Forms & Services tab',
            description:
              'Go to the ”Forms & Services” section on the right sidebar. Select ”All Forms”',
            collapseContent: (
              <div className='space-y-2'>
                <img
                  src={`${
                    i18n.language === 'en'
                      ? '/images/oss_sese_all_forms_1_en_1x.png'
                      : '/images/oss_sese_all_forms_1_de_1x.png'
                  }`}
                  srcSet={getRetinaImgSrc(
                    `${
                      i18n.language === 'en'
                        ? '/images/oss_sese_all_forms_1_en_2x.png'
                        : '/images/oss_sese_all_forms_1_de_2x.png'
                    }`
                  )}
                  alt='Forms and Services tab step'
                  className='mx-auto'
                />
                <img
                  src={`${
                    i18n.language === 'en'
                      ? '/images/oss_sese_all_forms_2_en_1x.png'
                      : '/images/oss_sese_all_forms_2_de_1x.png'
                  }`}
                  srcSet={getRetinaImgSrc(
                    `${
                      i18n.language === 'en'
                        ? '/images/oss_sese_all_forms_2_en_2x.png'
                        : '/images/oss_sese_all_forms_2_de_2x.png'
                    }`
                  )}
                  alt='Forms and Services tab step'
                  className='mx-auto'
                />
              </div>
            ),
          },
          {
            label:
              'Go to Tax-International section and select One Stop Shop category',
            description:
              'Then select the last item in the list ”Steuererklärung für die OSS EU-Regelung...”',
            collapseContent: (
              <img
                src={`${
                  i18n.language === 'en'
                    ? '/images/oss_sese_oss_tab_en_1x.png'
                    : '/images/oss_sese_oss_tab_de_1x.png'
                }`}
                srcSet={getRetinaImgSrc(
                  `${
                    i18n.language === 'en'
                      ? '/images/oss_sese_oss_tab_en_2x.png'
                      : '/images/oss_sese_oss_tab_de_2x.png'
                  }`
                )}
                alt='One Stop Shop tab'
                className='mx-auto'
              />
            ),
          },
          {
            label: 'Import OSS report',
            description:
              'On the page ”Liste der Umsätze und Anpassungen” go to ”Import von Daten” and select the file downloaded from the Client Portal. Then click on ”Start import”',
            collapseContent: (
              <img
                src='/images/oss_sese_import_de_1x.png'
                srcSet={getRetinaImgSrc('/images/oss_sese_import_de_2x.png')}
                alt='Import tab step'
                className='mx-auto'
              />
            ),
          },
          {
            label: 'Wait until the file is processed',
            description:
              'If the file has been imported successfully, you will see the turnover amount under the ”Gesamtsteuerbetrag für Umsätze” section',
            collapseContent: (
              <div className='mx-auto max-w-[620px]'>
                <img
                  src='/images/oss_sese_file_processing.png'
                  srcSet={getRetinaImgSrc(
                    '/images/oss_sese_file_processing-2x.png'
                  )}
                  alt='File processing tab step'
                  className='mx-auto'
                />
                <div className='my-[10px] text-gray-700'>
                  {t(
                    'If an error message is displayed after the import, click on ”Speichern” and send us this file by e-mail to'
                  )}
                  &nbsp;
                  <a
                    href='mailto:vat@countx.com'
                    className='!text-gray-700 underline hover:no-underline'
                  >
                    vat@countx.com
                  </a>
                  .
                </div>
                <img
                  src='/images/oss_sese_file_processing_2.png'
                  srcSet={getRetinaImgSrc(
                    '/images/oss_sese_file_processing_2-2x.png'
                  )}
                  alt='File processing tab step'
                  className='mx-auto'
                />
              </div>
            ),
          },
        ]
      : []),
    {
      label: `${
        isSeller && isTaxAdvisorSubmits
          ? 'Report submission by your Tax Advisor'
          : 'Submit report'
      }`,
      description: `${
        isSeller && isTaxAdvisorSubmits
          ? 'The report will be generated and provided to your Tax Advisor automatically'
          : 'Once report is submitted, mark it here'
      }`,
      taskFor: isTaxAdvisorSubmits
        ? TaskForEnum.taxAdvisor
        : TaskForEnum.client,
      done: isSubmitted,
      btn: {
        disabled:
          (isSeller && isTaxAdvisorSubmits) || isSubmitted || !(file && link),
        action: openSubmitBOPModal(currentOSSReport),
        label: `${
          isSeller && isTaxAdvisorSubmits
            ? isSubmitted
              ? 'Submitted by Tax Advisor'
              : 'by Tax Advisor'
            : isSubmitted
              ? 'Marked as submitted'
              : 'Mark as submitted'
        }`,
        styling:
          isSubmitted && !(isSeller && isTaxAdvisorSubmits)
            ? ('outline' as BtnStyling)
            : undefined,
        className:
          isSeller && isTaxAdvisorSubmits ? '!rounded-full' : undefined,
      },
    },
  ];

  const postSubmitSteps: TodoOSSSeSeItem[] = [
    ...(isTaxAdvisor
      ? [
          {
            label: 'Client has to make a payment on their side.',
            description: (
              <>
                <span>{t('According to')}</span>
                &nbsp;
                <Button
                  styling='naked-blue'
                  className={`${
                    !currentOSSReport.payment_instruction
                      ? '!bg-white !font-normal'
                      : '!font-semibold'
                  }`}
                  onClick={handleOpenPaymentInstructionModal(currentOSSReport)}
                  disabled={!currentOSSReport.payment_instruction}
                >
                  {t('payment instruction')}
                </Button>
                &nbsp;
                <span>{t('that we provide')}</span>
              </>
            ),
            taskFor: TaskForEnum.client,
            done: isPaid,
            btn: {
              action: handleOpenPaymentInstructionModal(currentOSSReport),
              disabled: true,
              label: `${isPaid ? 'Confirmed by client' : 'by client'}`,
              className: '!rounded-full',
            },
            inactive: true,
          },
        ]
      : [
          {
            label: 'Make and confirm your payment',
            description:
              'We will provide payment instructions here and by email, once the report is submitted',
            taskFor: TaskForEnum.client,
            done: isPaid,
            btn: {
              action: handleOpenPaymentInstructionModal(currentOSSReport),
              disabled: !isSubmitted,
              label: `${isPaid ? 'Marked as paid' : 'Open instruction'}`,
              styling: isPaid ? ('outline' as BtnStyling) : undefined,
            },
          },
        ]),
  ];

  const postPaymentSteps: TodoOSSSeSeItem[] = [
    ...(isSeller && isTaxAdvisorSubmits
      ? []
      : [
          {
            label: 'Check if the file has been accepted',
            description:
              'Since in some cases it might be declined by authorities',
            // taskFor: isTaxAdvisorSubmits
            //   ? TaskForEnum.taxAdvisor
            //   : TaskForEnum.client,
            done: false,
          },
        ]),
  ];

  const actionsToConfirm = [preSubmitSteps, postSubmitSteps, postPaymentSteps]
    .flat()
    .filter(
      (todo) =>
        todo.done === false &&
        (isTaxAdvisor
          ? todo.taskFor === TaskForEnum.taxAdvisor
          : todo.taskFor === TaskForEnum.client)
    );

  return (
    <div className='mb-4 rounded-lg lg:mb-0'>
      <div className='flex items-center justify-between rounded-t-lg bg-white px-4 py-2'>
        <h3 className='Text font-extrabold'>
          {isTaxAdvisorSubmits && isSeller
            ? t("What's needed")
            : t('How to submit')}
        </h3>
        {!!actionsToConfirm.length && (
          <div className='space-x-1 rounded-lg bg-red-500 px-2.5 font-bold text-white'>
            {t('Tasks')}: {actionsToConfirm.length}
          </div>
        )}
      </div>
      <div className='space-y-2'>
        {!!preSubmitSteps.length && (
          <OSSSeSeTaskWrapper date={processingDate}>
            {preSubmitSteps.map((task, i) => {
              return (
                <OSSSeSeOpenTask key={task.label} task={task} step={i + 1} />
              );
            })}
          </OSSSeSeTaskWrapper>
        )}
        {!!postSubmitSteps.length && (
          <OSSSeSeTaskWrapper date={recommendedDueDate}>
            {postSubmitSteps.map((task, i) => {
              return (
                <OSSSeSeOpenTask
                  key={task.label}
                  task={task}
                  step={preSubmitSteps.length + i + 1}
                />
              );
            })}
          </OSSSeSeTaskWrapper>
        )}
        {!!postPaymentSteps.length && (
          <OSSSeSeTaskWrapper date={deadlineDate}>
            {postPaymentSteps.map((task, i) => {
              return (
                <OSSSeSeOpenTask
                  key={task.label}
                  task={task}
                  step={preSubmitSteps.length + postSubmitSteps.length + i + 1}
                />
              );
            })}
          </OSSSeSeTaskWrapper>
        )}
      </div>
    </div>
  );
};
