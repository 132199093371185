import { useTranslation } from 'react-i18next';
import ClipIcon from '@/icons/clip-icon.svg?react';
import FileUploader from './FileUploader';
import classNames from 'classnames';
import { AllOWED_FILE_EXTENSIONS } from './FileUploader.helpers';
import { PropsWithChildren } from 'react';

interface Props {
  accept?: string[];
  onSelect: (files: File[] | null) => void;
  sizeLimit?: number;
  label?: string;
  description?: string;
  multiple?: boolean;
  isUploadingFiles?: boolean;
}

export default function FileUploaderBtn({
  accept = AllOWED_FILE_EXTENSIONS,
  onSelect,
  label = 'Attach payment confirmation',
  multiple,
  isUploadingFiles,
  children,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  if (isUploadingFiles) {
    return (
      <div
        className={classNames({
          'opacity-50': isUploadingFiles,
        })}
      >
        {children || (
          <div className='flex w-full items-center justify-center gap-x-2 rounded-lg border p-4 outline-1 transition-colors hover:border-dark hover:outline hover:outline-dark'>
            <ClipIcon className='h-6 w-4 rotate-180' />
            <div className='font-semibold'>{t(label)}</div>
          </div>
        )}
      </div>
    );
  }
  return (
    <FileUploader
      onSelect={onSelect}
      name='file'
      accept={accept}
      multiple={multiple}
    >
      {children || (
        <div className='flex w-full items-center justify-center gap-x-2 rounded-lg border p-4 outline-1 transition-colors hover:border-dark hover:outline hover:outline-dark'>
          <ClipIcon className='h-6 w-4 rotate-180' />
          <div className='font-semibold'>{t(label)}</div>
        </div>
      )}
    </FileUploader>
  );
}
