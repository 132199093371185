import GB from '@/icons/countries/GB.svg?react';
import AT from '@/icons/countries/AT.svg?react';
import BE from '@/icons/countries/BE.svg?react';
import BG from '@/icons/countries/BG.svg?react';
import HR from '@/icons/countries/HR.svg?react';
import CY from '@/icons/countries/CY.svg?react';
import CZ from '@/icons/countries/CZ.svg?react';
import DK from '@/icons/countries/DK.svg?react';
import EE from '@/icons/countries/EE.svg';
import FI from '@/icons/countries/FI.svg?react';
import FR from '@/icons/countries/FR.svg?react';
import DE from '@/icons/countries/DE.svg?react';
import GR from '@/icons/countries/GR.svg?react';
import HU from '@/icons/countries/HU.svg?react';
import IE from '@/icons/countries/IE.svg?react';
import IT from '@/icons/countries/IT.svg?react';
import LV from '@/icons/countries/LV.svg?react';
import LT from '@/icons/countries/LT.svg?react';
import LU from '@/icons/countries/LU.svg?react';
import MT from '@/icons/countries/MT.svg?react';
import NL from '@/icons/countries/NL.svg?react';
import PL from '@/icons/countries/PL.svg?react';
import PT from '@/icons/countries/PT.svg?react';
import RO from '@/icons/countries/RO.svg?react';
import SK from '@/icons/countries/SK.svg?react';
import SI from '@/icons/countries/SI.svg?react';
import ES from '@/icons/countries/ES.svg?react';
import SE from '@/icons/countries/SE.svg?react';
import MC from '@/icons/countries/MC.svg?react';
import EU from '@/icons/countries/EU.svg?react';
import CH from '@/icons/countries/CH.svg?react';
import CN from '@/icons/countries/CN.svg?react';
import TR from '@/icons/countries/TR.svg?react';
import './Flag.scss';
import React from 'react';

interface Props {
  country: string;
  className?: string;
}

const flagsMap: {
  [key: string]: JSX.Element;
} = {
  GB: <GB />,
  AT: <AT />,
  BE: <BE />,
  BG: <BG />,
  HR: <HR />,
  CY: <CY />,
  CZ: <CZ />,
  DK: <DK />,
  EE: <img src={EE} alt='' />,
  FI: <FI />,
  FR: <FR />,
  DE: <DE />,
  GR: <GR />,
  EL: <GR />,
  HU: <HU />,
  IE: <IE />,
  IT: <IT />,
  LV: <LV />,
  LT: <LT />,
  LU: <LU />,
  MT: <MT />,
  NL: <NL />,
  PL: <PL />,
  PT: <PT />,
  RO: <RO />,
  SK: <SK />,
  SI: <SI />,
  ES: <ES />,
  SE: <SE />,
  MC: <MC />,
  EU: <EU />,
  CH: <CH />,
  CN: <CN />,
  TR: <TR />,
};

const Flag = ({ country, className = '' }: Props) => {
  return flagsMap[country] ? (
    React.cloneElement(flagsMap[country], {
      className: `Flag ${className}`,
    })
  ) : country === 'OTHER' ? null : (
    <span className={className}>{country}</span>
  );
};

export default Flag;
