import './PurchasePrices.scss';
import PageLayout from '../../wrappers/PageLayout';
import { Loader } from '@/components/Loader/Loader';
import PurchasePricesUploader from './PurchasePricesUploader';
import PPIcon from '@/icons/purchase-prices.svg?react';
import { useTranslation } from 'react-i18next';
import { useModal } from '@/components/Modal/useModal';
import PurchasePricesFAQModal from './PurchasePricesFAQModal';
import Button from '@/components/Button';
import { usePP } from '../../context/usePP';
import PurchasePricesUploaderOld from './PurchasePricesUploaderOld';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FeedbackWidget from '@/components/FeedbackWidget/FeedbackWidget';

export default function PurchasePrices() {
  const { t } = useTranslation();
  const { isLoadingPP } = usePP();
  const { isModalOpened, closeModal, openModal } = useModal();
  const { vs5438CpTwoFilesForPpFownloading } = useFlags();

  if (isLoadingPP) return <Loader />;

  return (
    <>
      <PageLayout
        className='flex-grow'
        rightColumn={
          <div style={{ maxWidth: 328 }} className='pt-4 lg:ml-auto lg:pt-0'>
            <FeedbackWidget testId='FeedbackWidget' />
            <div className='mb-4 flex flex-col items-center justify-center rounded-lg bg-white px-4 py-6'>
              <PPIcon />
              <div className='my-4 text-center text-h4 font-extrabold'>
                {t('What is Purchase Price and why it is required')}
              </div>
              <Button className='mb-2' onClick={openModal} styling='outline'>
                {t('Open FAQ')}
              </Button>
            </div>
          </div>
        }
      >
        {vs5438CpTwoFilesForPpFownloading ? (
          <PurchasePricesUploader />
        ) : (
          <PurchasePricesUploaderOld />
        )}
      </PageLayout>
      <PurchasePricesFAQModal
        isModalOpened={isModalOpened}
        closeModal={closeModal}
      />
    </>
  );
}
