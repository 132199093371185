import { userSelector } from '@/core/store/user/userSlice';
import { useAppSelector } from '@/hooks';
import { differenceInCalendarDays } from 'date-fns';
import { createContext, useState, ReactNode, useRef, useCallback } from 'react';
interface NPSModalContextType {
  isModalOpened: boolean;
  closeModal: () => void;
  openModal: (v: string) => void;
  event: string;
}

export const NPSModalContext = createContext<NPSModalContextType>(
  {} as NPSModalContextType
);

export function NPSModalProvider({ children }: { children: ReactNode }) {
  const user = useAppSelector(userSelector);
  const eventRef = useRef('');
  const [isModalOpened, setIsModalOpened] = useState(false);

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const canSubmitNpsDate =
    user?.CanSubmitNpsDate && new Date(user.CanSubmitNpsDate);
  const lastNpsSubmission =
    user?.LastNpsSubmission && new Date(user.LastNpsSubmission);
  const createdAt = user?.CreatedAt && new Date(user.CreatedAt);
  const currentDate = new Date();

  const canSubmitNps = Boolean(
    (!canSubmitNpsDate &&
      !lastNpsSubmission &&
      (!createdAt || differenceInCalendarDays(currentDate, createdAt) >= 90)) ||
      (canSubmitNpsDate &&
        differenceInCalendarDays(currentDate, canSubmitNpsDate) >= 0) ||
      (!canSubmitNpsDate &&
        lastNpsSubmission &&
        differenceInCalendarDays(currentDate, lastNpsSubmission) >= 90)
  );

  const openModal = useCallback(
    (v: string) => {
      eventRef.current = v;
      if (canSubmitNps) {
        setIsModalOpened(true);
      }
    },
    [canSubmitNps]
  );

  return (
    <NPSModalContext.Provider
      value={{
        isModalOpened,
        closeModal,
        openModal,
        event: eventRef.current,
      }}
    >
      {children}
    </NPSModalContext.Provider>
  );
}
