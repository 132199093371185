import { useEffect, useMemo } from 'react';
import { Loader } from '../components/Loader/Loader';
import {
  getCompletedFiling,
  getOngoingFiling,
  getRegisteredVATIds,
  getReportResultFileLink,
} from '../core/api';
import {
  activeCompanySelector,
  isOSSSelfServiceSelector,
  queryKeySelector,
  userSelector,
} from '../core/store/user/userSlice';
import { useAppSelector } from '../hooks';
import {
  OSSSeSeWidgetLink,
  OSSSelfServiceWidget,
} from '../components/OSSSelfServiceWidget/OSSSelfServiceWidget';
import { SellerFilingReportFile } from '../core/types';
import { downloadFile } from '../core/helpers';
import { useNavigate } from 'react-router-dom';
import OSSDetailsBlock from '../components/OSSDetailsBlock/OSSDetailsBlock';
import { RolesEnum, SellerFilingReportStatusEnum } from '../core/enums';
import { OSSSeSeReportFiling } from '../components/OSSSelfServiceWidget/OSSSeSeReportFiling';
import { useSubmitBOPModal } from './Seller/SellerFiling/useSubmitBOPModal';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import SubmitBOPModal from '../components/SubmitBOPModal';
import usePaymentInstructionModal from './Seller/SellerFiling/usePaymentInstructionModal';
import PaymentInstructionModal from '../components/PaymentInstructionModal/PaymentInstructionModal';
import PageLayout from '../wrappers/PageLayout';
import { isReportPrepared } from '../components/OpenTasks/OpenTasks.helpers';
import { OSSSeSeSteps } from '../components/OSSSeSeSteps/OSSSeSeSteps';
import {
  createInvoiceBookLink,
  getCurrentOSSSeSeReport,
} from './OSSFiles.helpers';
import FeedbackWidget from '@/components/FeedbackWidget/FeedbackWidget';

export const OSSFiles = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const user = useAppSelector(userSelector);
  const activeCompany = useAppSelector(activeCompanySelector);
  const queryKey = useAppSelector(queryKeySelector);

  const [ongoingFiling, completedFiling, registeredVATIds] = useQueries({
    queries: [
      {
        queryKey: ['SellerOngoingFiling', queryKey],
        queryFn: () => getOngoingFiling(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerPastPeriodsFiling', queryKey],
        queryFn: () => getCompletedFiling(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerRegisteredVATIds', queryKey],
        queryFn: () => getRegisteredVATIds(activeCompany?.company_id),
      },
    ],
  });

  const ongoingFilingData = ongoingFiling.data;
  const completedFilingData = completedFiling.data;

  const currentOSSReport = useMemo(() => {
    return getCurrentOSSSeSeReport([
      ...(ongoingFilingData || []),
      ...(completedFilingData || []),
    ]);
  }, [ongoingFilingData, completedFilingData]);

  const onClickDownload = (file: SellerFilingReportFile) => {
    if (!activeCompany) return;
    getReportResultFileLink(file.id, activeCompany?.company_id)
      .then((link) => {
        downloadFile(link, file.name.split('/').slice(-1)[0]);
      })
      .catch(console.error);
  };

  const isOSSSelfService = useAppSelector(isOSSSelfServiceSelector);

  useEffect(() => {
    if (!isOSSSelfService) {
      navigate('/');
    } else {
      if (window.hj) window.hj('event', 'view_oss_files_tab');
    }
    // eslint-disable-next-line
  }, [isOSSSelfService]);

  let link: OSSSeSeWidgetLink | undefined = undefined;
  if (
    activeCompany &&
    currentOSSReport &&
    (currentOSSReport.status === SellerFilingReportStatusEnum.Submitted ||
      isReportPrepared(currentOSSReport))
  ) {
    link = createInvoiceBookLink(
      currentOSSReport.period.from_date,
      activeCompany.company_id
    );
  }

  const {
    reportToSubmit,
    openSubmitBOPModal,
    isSubmitBOPModalOpened,
    closeSubmitBOPModal,
    submitReport,
  } = useSubmitBOPModal('OSSFiles', async () => {
    const [ongoingRes, completedRes] = await Promise.all([
      getOngoingFiling(activeCompany?.company_id),
      getCompletedFiling(activeCompany?.company_id),
    ]);

    queryClient.setQueryData(['SellerOngoingFiling', queryKey], ongoingRes);
    queryClient.setQueryData(
      ['SellerPastPeriodsFiling', queryKey],
      completedRes
    );
  });

  const {
    onClickConfirmPayment,
    isPaymentInstructionModalOpened,
    closePaymentInstructionModal,
    reportToConfirmPayment,
    handleOpenPaymentInstructionModal,
    isConfirmingPayment,
  } = usePaymentInstructionModal();

  if (!isOSSSelfService) return null;

  return (
    <PageLayout
      className='flex-grow'
      rightColumn={
        <div className='w-[330px] rounded-lg'>
          <FeedbackWidget testId='FeedbackWidget' />
          <OSSDetailsBlock
            position='column'
            registeredVATIds={registeredVATIds.data || []}
          />
        </div>
      }
    >
      <div className='flex-grow bg-gray-300'>
        <div className='mx-auto w-full max-w-[1328px]'>
          <div className='mx-auto max-w-[712px] space-y-6'>
            {ongoingFiling.isLoading || completedFiling.isLoading ? (
              <Loader />
            ) : currentOSSReport ? (
              <>
                <OSSSelfServiceWidget
                  report={currentOSSReport}
                  link={link}
                  onClickDownload={onClickDownload}
                />
                <OSSSeSeSteps
                  currentOSSReport={currentOSSReport}
                  openSubmitBOPModal={openSubmitBOPModal}
                  handleOpenPaymentInstructionModal={
                    handleOpenPaymentInstructionModal
                  }
                  onClickDownload={onClickDownload}
                  link={link}
                />
              </>
            ) : (
              <OSSSeSeReportFiling />
            )}
          </div>
        </div>
        <SubmitBOPModal
          isModalOpened={isSubmitBOPModalOpened}
          closeModal={closeSubmitBOPModal}
          report={reportToSubmit}
          onSubmit={submitReport}
        />
        <PaymentInstructionModal
          report={reportToConfirmPayment}
          isModalOpened={isPaymentInstructionModalOpened}
          closeModal={closePaymentInstructionModal}
          onClickConfirm={
            user?.Role === RolesEnum.SELLER
              ? onClickConfirmPayment(reportToConfirmPayment)
              : undefined
          }
          isConfirming={isConfirmingPayment}
        />
      </div>
    </PageLayout>
  );
};
