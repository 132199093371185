import { useTranslation } from 'react-i18next';
import { routesMapDE, routesMapEN } from '../core/constants';
import { Lang } from '../core/types';

const SITE_URL =
  import.meta.env.VITE_APP_ENV === 'prod'
    ? 'https://countx.com'
    : `https://www-${
        import.meta.env.VITE_APP_ENV || import.meta.env.MODE
      }.countx.com`;

export default function useLinkToMainSite() {
  const { i18n } = useTranslation();
  return {
    getLink: (k: string, language?: Lang) => {
      const currentLang = language || i18n.language;

      const routesMap: {
        [key: string]: string;
      } = currentLang === 'en' ? routesMapEN : routesMapDE;

      return SITE_URL + (routesMap[k] || k);
    },
  };
}
