import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@/components/Button';
import MiniLoader from '@/components/MiniLoader/MiniLoader';
import { postStopMailing } from '@/core/api';
import { EMAIL } from '@/core/constants';
import { useAppSelector } from '../../../hooks';
import './Unsubscribe.scss';
import { activeCompanySelector } from '@/core/store/user/userSlice';
import useNotification from '../../../hooks/useNotification';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Unsubscribe() {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const activeCompany = useAppSelector(activeCompanySelector);

  const onClick = () => {
    setIsLoading(true);
    postStopMailing('manual-upload-needed', activeCompany?.company_id)
      .then(() => {
        params.delete('unsubscribe');
        setIsLoading(false);
        showNotification({
          text: 'No manual turnover data confirmed',
          type: 'success',
        });
        navigate(
          { pathname: '/', search: params.toString() },
          { replace: true }
        );
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className='Unsubscribe flex flex-grow items-center justify-center p-12 lg:items-start'>
      <div
        style={{
          maxWidth: i18n.language === 'en' ? 623 : 704,
        }}
        className='Unsubscribe__content text-center'
      >
        <div className='Text--30 mb-4 font-black'>
          {t(
            'Please confirm that there is no manual turnover data for *company* for this month'
          ).replace('*company*', activeCompany?.name || '')}
        </div>
        <div
          className='mx-auto text-h4'
          style={{ maxWidth: i18n.language === 'en' ? 530 : 698 }}
        >
          <div className='mb-2'>
            {t('You will not receive any reminders in the current month.')}
          </div>
          <div className='mb-4'>
            {t(
              'If you receive any manual turnover data this month, contact your manager by phone or write to'
            )}
            &nbsp;
            <a
              href={`mailto:${EMAIL}`}
              className='text-blue-500 transition-colors hover:text-blue-600'
            >
              {EMAIL}
            </a>
          </div>
          <Button
            className='mx-auto flex items-center !px-4 !py-2'
            onClick={onClick}
            disabled={isLoading}
          >
            {isLoading && <MiniLoader className='mr-2' color='gray' />}
            <span>{t('Yes, I confirm')}</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
