import LangDropdown from '../LangDropdown/LangDropdown';
import { useTranslation } from 'react-i18next';
import './Header.scss';
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown';
import { useAppSelector } from '../../hooks';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SalesIcon from '@/icons/sales.svg?react';
import classNames from 'classnames';
import BackIcon from '@/icons/back.svg?react';
import Button from '../Button';
import Logo from '../Logo/Logo';
import {
  activeCompanySelector,
  userSelector,
} from '@/core/store/user/userSlice';
import { useBookMeetingModal } from '../BookMeetingModal/useBookMeetingModal';

interface Props {
  btn?: JSX.Element;
  CTABtnLabel?: string;
  logo?: boolean;
  backBtn?: boolean;
  label?: string | null;
  backBtnPath?: string;
  backBtnLabel?: string;
  className?: string;
  isShownBtn?: boolean;
}

const Header = ({
  btn,
  CTABtnLabel = 'Book consultation',
  logo = true,
  backBtn,
  label,
  backBtnPath,
  className = '',
  isShownBtn = true,
  backBtnLabel,
}: Props) => {
  const { t } = useTranslation();

  const user = useAppSelector(userSelector);
  const activeCompany = useAppSelector(activeCompanySelector);

  const navigate = useNavigate();

  const location = useLocation();

  const { openModal } = useBookMeetingModal();
  const handleOpenModal = () =>
    openModal(
      activeCompany?.Clerk?.HubspotMeetingLink
        ? activeCompany.Clerk.HubspotMeetingLink + '?embed=true'
        : undefined
    );

  return (
    <div
      className={`Header sticky left-0 top-0 z-10 border-b border-gray-300 ${className}`}
      id='header'
    >
      <div
        className={classNames('flex items-center', {
          'justify-between': logo || backBtn,
          'justify-end': !logo && !backBtn,
        })}
      >
        <div className='flex items-center'>
          {logo && (
            <Link to='/' tabIndex={-1} className='pt-1'>
              <Logo className='Header__logo' />
            </Link>
          )}
          {backBtn &&
            (!location.state?.hasPrevPath ? (
              <Link
                className='group flex items-center font-semibold text-dark-green no-underline transition hover:text-green'
                to={backBtnPath || '/'}
              >
                <BackIcon className='mr-2 [&>circle]:transition-[fill] group-hover:[&>circle]:fill-green' />
                {t(backBtnLabel || 'Go to overview')}
              </Link>
            ) : (
              <Button
                styling='naked'
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BackIcon className='mr-2' />
                {t(backBtnLabel || 'Go to overview')}
              </Button>
            ))}
        </div>
        {label && <div className='font-extrabold'>{label}</div>}
        <div className={`flex items-center`}>
          {isShownBtn && (
            <div className='flex items-center'>
              {btn}
              <Button
                styling='outline'
                className='hidden sm:block'
                size='sm'
                onClick={handleOpenModal}
                data-testid='calendly-btn'
              >
                {t(CTABtnLabel)}
              </Button>
              <Button
                styling='naked'
                className='sm:hidden'
                onClick={handleOpenModal}
                data-testid='calendly-btn-mob'
                size='sm'
              >
                <SalesIcon />
              </Button>
            </div>
          )}
          <div className='ml-2 flex items-center'>
            <LangDropdown />
          </div>
          {user ? (
            <ProfileDropdown className='ml-2' />
          ) : (
            location.pathname !== '/' && (
              <Link
                to={'/'}
                className='ml-2 font-semibold text-dark-green
              transition hover:text-green'
              >
                {t('Login')}
              </Link>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
