import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import { PropsWithChildren, useState } from 'react';
import MiniLoader from '../MiniLoader/MiniLoader';
import Button from '../Button';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  title?: string;
  confirmBtnText?: string;
  confirm: (setIsLoading: (v: boolean) => void) => void;
  isDisabled?: boolean;
  danger?: boolean;
  name?: string;
}

export default function ConfirmModal({
  isModalOpened,
  closeModal,
  title = 'Confirmation',
  confirmBtnText = 'OK',
  confirm,
  isDisabled = false,
  children,
  danger,
  name,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const onClickConfirm = () => {
    confirm(setIsLoading);
  };

  return (
    <Modal
      isOpened={isModalOpened}
      close={closeModal}
      title={t(title)}
      isLoading={isLoading}
      data-testid={name || 'confirm-modal'}
    >
      <div className='mx-4 px-4 pb-6 pt-2'>
        {children}
        <div className='mt-2 flex flex-wrap gap-x-2'>
          <div>
            <Button
              styling={danger ? 'red' : undefined}
              className='flex items-center'
              type='submit'
              disabled={isLoading || isDisabled}
              data-testid={name ? `${name}-confirm-btn` : 'confirm-btn'}
              onClick={onClickConfirm}
            >
              {isLoading && <MiniLoader className='mr-2' color='gray' />}
              <span>{t(confirmBtnText)}</span>
            </Button>
          </div>
          <div>
            <Button
              styling='outline'
              onClick={closeModal}
              disabled={isLoading}
              data-testid={name ? `${name}-cancel-btn` : 'cancel-btn'}
            >
              {t('Cancel')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
