import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import JtlLogo from '@/icons/jtl.svg';
import { JTLCredentials } from '@/core/types';
import { useEffect, useState } from 'react';
import Button from '../Button';
import { Spinner } from '../Spinner';
import { postJTLCredentials, regenerateJTLCredentials } from '@/core/api';
import { useAppSelector } from '@/hooks';
import {
  activeCompanySelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';
import { useQueryClient } from '@tanstack/react-query';
import CopyIcon from '@/icons/copy.svg?react';
import AppIcon from '@/icons/app-icon.svg?react';
import copy from 'copy-to-clipboard';
import useNotification from '@/hooks/useNotification';
import { Link } from 'react-router-dom';
import { AuthedRoutesEnum } from '@/core/enums';
import { JTLSecretKey } from './JTLSecretKey';
import { downloadFile } from '@/core/helpers';
import classNames from 'classnames';

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  jtlCredentials?: JTLCredentials;
  prevRoute: string;
}

export function ConnectJTLModal({
  isModalOpened,
  closeModal,
  jtlCredentials,
  prevRoute,
}: Props) {
  const querClient = useQueryClient();
  const activeCompany = useAppSelector(activeCompanySelector);
  const queryKey = useAppSelector(queryKeySelector);
  const { showNotification } = useNotification();

  const { t, i18n } = useTranslation();
  const [isGenerating, setIsGenerating] = useState(false);
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (isModalOpened) {
      setPassword('');
    }
  }, [isModalOpened]);

  const onClickCopy = (text: string, message: string) => () => {
    copy(text);
    showNotification({
      type: 'success',
      text: message,
    });
  };

  return (
    <Modal
      isOpened={isModalOpened}
      close={closeModal}
      title={t('JTL connection')}
      name='token-modal'
      isLoading={isGenerating}
    >
      <div className='mx-4 px-4 pb-6 pt-4'>
        <div className='mb-2 flex items-center justify-between'>
          <div
            className='flex w-full items-center text-h4 font-extrabold'
            data-testid='integrations-jtl'
          >
            <img
              src={JtlLogo}
              alt='jtl'
              style={{
                width: 40,
                height: 40,
              }}
              className='mr-2 flex-shrink-0'
            />
            <span>{t('JTL connection')}</span>
          </div>
        </div>
        <div className='mb-4 space-y-4 divide-y'>
          <div className='flex justify-between gap-x-4'>
            <div>
              <div className='font-semibold'>
                <span>1.</span>{' '}
                <span>{t('Download countX-JTL Connector')}</span>
              </div>
              <div>{t('Follow the installation instructions.')}</div>
              <div>{t('Return here once the installation is complete.')}</div>
              <Link
                to={AuthedRoutesEnum.JtlInstruction}
                state={{ prevRoute }}
                className={classNames(
                  'Button--outline !mt-2 flex max-w-[240px] items-center justify-center gap-x-1 !px-2 !py-1',
                  {
                    'max-w-[264px]': i18n.language === 'de',
                  }
                )}
              >
                <AppIcon />
                <span className='font-semibold'>
                  {t('Open detailed instruction')}
                </span>
              </Link>
            </div>
            <div className='flex-shrink-0'>
              <Button
                className='Button !text-white'
                onClick={() => {
                  downloadFile(
                    '/JtlCountXConnectorClientSetup.exe',
                    'JtlCountXConnectorClientSetup.exe'
                  );
                }}
              >
                <div>{t('Get app')}</div>
              </Button>
            </div>
          </div>
          <div className='pt-4'>
            <div className='font-semibold'>
              <span>2.</span> <span>{t('Set up access')}</span>
            </div>
            <div className='mb-3'>
              {t(
                'Generate unique credentials to securely connect your countX account to JTL'
              )}
            </div>
            <div className='rounded-lg bg-gray-300 px-4 py-3'>
              <div className='flex justify-between gap-x-2'>
                <div>
                  <div>{t('Access ID')}</div>
                  <div className='mb-2 flex items-center gap-x-2 font-semibold'>
                    {jtlCredentials ? (
                      <>
                        <span>{jtlCredentials.username}</span>{' '}
                        <button
                          onClick={onClickCopy(
                            jtlCredentials.username,
                            'Access ID is copied'
                          )}
                        >
                          <CopyIcon className='h-6 w-6' />
                        </button>
                      </>
                    ) : (
                      <span>{t('Not generated yet')}</span>
                    )}
                  </div>
                </div>
                <div className='shrink-0'>
                  <Button
                    disabled={isGenerating}
                    className='flex items-center justify-center gap-x-2'
                    styling={
                      jtlCredentials || isGenerating ? 'outline' : 'primary'
                    }
                    onClick={() => {
                      setIsGenerating(true);
                      const apiFn = jtlCredentials
                        ? regenerateJTLCredentials
                        : postJTLCredentials;

                      apiFn(activeCompany?.company_id)
                        .then((res) => {
                          querClient.setQueryData(
                            ['SellerJtlCredentials', queryKey],
                            {
                              username: res.username,
                              status: res.status,
                            }
                          );
                          setPassword(res.password);
                          setIsGenerating(false);
                        })
                        .catch(() => {
                          setIsGenerating(false);
                        });
                    }}
                  >
                    {isGenerating && <Spinner />}{' '}
                    <span>{t(jtlCredentials ? 'Regenerate' : 'Generate')}</span>
                  </Button>
                </div>
              </div>
              <JTLSecretKey
                password={password}
                jtlCredentials={jtlCredentials}
                onClickCopy={onClickCopy}
              />
            </div>
          </div>
          <div className='pt-4'>
            <div className='font-semibold'>
              <span>3.</span> <span>{t('Complete connection')}</span>
            </div>
            <ul className='list-disc pl-6'>
              <li>{t('Launch the JTL connector app.')}</li>
              <li>{t('Enter the Access ID and Secret Key')}</li>
              <li>
                {t('Follow the on-screen instructions to finalize setup')}
              </li>
            </ul>
          </div>
        </div>
        <Button styling='outline' onClick={closeModal} disabled={isGenerating}>
          {t('Cancel')}
        </Button>
      </div>
    </Modal>
  );
}
