import { UserFlagEnum } from '@/core/types';
import AmazonConnection from './AmazonConnection/AmazonConnection';
import { getAmazonDataImportStatus } from '@/core/api';
import { Loader } from '@/components/Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useEffect, useState } from 'react';
import { setAmazonDataImportStatus } from '@/core/store/amazon/amazonSlice';
import Header from '@/components/Header/Header';
import SetupCheck from './SetupCheck/SetupCheck';
import { AuthedRoutesEnum } from '@/core/enums';
import { userSelector } from '@/core/store/user/userSlice';
import AmazonFBACalculator from './AmazonFBACalculator/AmazonFBACalculator';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NPSModalProvider } from '@/components/NPSModal/nps-context';
import { NPSModal } from '@/components/NPSModal/NPSModal';

export default function SetupCheckRoutes() {
  const dispatch = useAppDispatch();
  const amazonDataImportStatus = useAppSelector(
    (s) => s.amazon.dataImportStatus
  );
  const hasNewCredentials = useAppSelector((s) => s.amazon.hasNewCredentials);
  const user = useAppSelector(userSelector);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      amazonDataImportStatus === undefined &&
      user?.Flag !== UserFlagEnum.amazon_fba_calculator
    ) {
      getAmazonDataImportStatus()
        .then((res) => {
          dispatch(setAmazonDataImportStatus(res.status));
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [amazonDataImportStatus, dispatch]);

  if (isLoading) return <Loader />;

  const routes =
    user?.Flag === UserFlagEnum.amazon_fba_calculator
      ? [
          {
            element: hasNewCredentials ? (
              <AmazonFBACalculator />
            ) : (
              <AmazonConnection />
            ),
            path: '/',
          },
        ]
      : [
          ...(amazonDataImportStatus === 'loaded'
            ? [
                {
                  element: <Navigate to={AuthedRoutesEnum.SetupCheck} />,
                  path: '/',
                },
                {
                  element: <SetupCheck />,
                  path: AuthedRoutesEnum.SetupCheck,
                },
              ]
            : [
                {
                  element: <AmazonConnection />,
                  path: '/',
                  exact: true,
                },
              ]),
        ];

  return (
    <NPSModalProvider>
      <div className='flex min-h-screen flex-col'>
        <Header />
        <Routes>
          {routes.map((r) => (
            <Route element={r.element} path={r.path} key={r.path} />
          ))}
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </div>
      <NPSModal />
    </NPSModalProvider>
  );
}
