import './Skeleton.scss';

interface Props {
  className?: string;
  maxWidth?: number;
}

export function Skeleton({ className = '', maxWidth }: Props) {
  return <div className={`Skeleton ${className}`} style={{ maxWidth }} />;
}
