import classNames from 'classnames';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useDebounce, useOnClickOutside } from '../../hooks';
import CloseIcon from '@/icons/close.svg?react';
import { lockScroll } from '@/core/helpers';
import './Modal.scss';

interface Props {
  isOpened: boolean;
  close: () => void;
  title?: string | null;
  className?: string;
  name?: string;
  isLoading?: boolean;
  buttons?: JSX.Element;
}

const Modal = ({
  isOpened,
  close,
  title,
  children,
  className = '',
  name,
  isLoading,
  buttons,
}: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, isLoading ? () => {} : close);

  const debouncedIsOpened = useDebounce(isOpened, 250);
  const isShown = isOpened || debouncedIsOpened;

  useEffect(() => {
    return () => {
      lockScroll(false);
    };
  }, []);

  if (!isShown) {
    return null;
  }

  return createPortal(
    <div
      className={classNames(
        'Modal__backdrop animation-forwards fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center',
        className,
        {
          fadeIn: isOpened,
          fadeOut: !isOpened,
          'Modal__backdrop--disabled': isLoading,
        }
      )}
    >
      <div
        ref={ref}
        className={classNames(
          'Modal__box animation-forwards flex flex-col overflow-hidden',
          {
            fadeInDown: isOpened,
            fadeOutUp: !isOpened,
          }
        )}
        data-testid={name ? `${name}-modal` : undefined}
      >
        <div
          className={classNames('flex items-center justify-between px-4 py-2', {
            'border-b border-gray-600': title,
          })}
        >
          {title && (
            <div
              className='pl-4 font-semibold'
              data-testid={name ? `${name}-modal-title` : undefined}
            >
              {title}
            </div>
          )}
          <div className='ml-auto flex items-center'>
            {buttons}
            <button
              className='Modal__btn Modal__closeBtn flex items-center justify-center'
              onClick={close}
              data-testid={name ? `${name}-modal-close-btn` : undefined}
              disabled={isLoading}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className='Scrollbar Modal__inner' ref={innerRef}>
          {children}
        </div>
      </div>
    </div>,
    document.querySelector('#modals')!
  );
};

export default Modal;
