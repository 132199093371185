import { useEffect, useState } from 'react';
import Collapse, { CollapseProps } from './Collapse';
import { usePrevious } from '@/hooks';

type Props = Omit<CollapseProps, 'isOpened' | 'onTriggerClick'> & {
  initialOpened?: boolean;
};

export const ControlledCollapse = ({ initialOpened, ...props }: Props) => {
  const [isOpened, setIsOpened] = useState(initialOpened || false);

  const prevInitialOpened = usePrevious(initialOpened);
  useEffect(() => {
    if (initialOpened !== prevInitialOpened) {
      setIsOpened(initialOpened || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOpened]);

  return (
    <Collapse
      {...props}
      isOpened={isOpened}
      onTriggerClick={() => setIsOpened(!isOpened)}
    />
  );
};
