import { HTMLAttributes, PropsWithChildren } from 'react';
import './WhiteGreenWrapper.scss';
interface Props {
  rightSideContent?: JSX.Element;
  className?: string;
}

export default function WhiteGreenWrapper({
  children,
  rightSideContent,
  className = '',
  ...rest
}: PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>) {
  return (
    <div
      className={`${className} WhiteGreenWrapper flex flex-grow flex-col bg-white`}
      {...rest}
    >
      <div className='mx-auto flex w-full max-w-[1328px] flex-grow flex-col'>
        <div className='WhiteGreenWrapper__row -mx-3 flex flex-grow'>
          <div className='flex-grow bg-white px-3 pt-6 lg:w-7/12 lg:pt-12'>
            <div className='WhiteGreenWrapper__content px-1 lg:px-0'>
              {children}
            </div>
          </div>
          <div className='WhiteGreenWrapper__right flex flex-grow flex-col bg-green px-3 pt-6 text-white lg:w-5/12 lg:pt-12'>
            <div className='flex-grow'>{rightSideContent}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
