import BucketIcon from '@/icons/bucket.svg?react';
import { useTranslation } from 'react-i18next';
import './ManualUploadFile.scss';
import MiniLoader from '../MiniLoader/MiniLoader';
import Button from '../Button';
import { FileIconsMap } from './ManualUploader.helpers';

interface Props {
  onDeleteFile: () => void;
  file: File;
  className?: string;
  disabled: boolean;
  isUploading?: boolean;
}

export default function ManualUploadFile({
  onDeleteFile,
  file,
  disabled,
  className = '',
  isUploading,
}: Props) {
  const { t } = useTranslation();

  const name = file.name
    .split('.')
    .map((v, i, self) => {
      if (i === self.length - 2) {
        return v.replace(/_\d+$/, '..');
      }
      return v;
    })
    .join('.');

  return (
    <div
      className={`ManualUploadFile flex w-full items-start justify-between gap-x-6 ${className}`}
    >
      <div className='flex'>
        <span className='ManualUploadFile__icon mr-2 rounded-full'>
          {
            FileIconsMap[
              (file.name.split('.').slice(-1)[0] as string).toLowerCase()
            ]
          }
        </span>
        <div className='w-full'>
          <div className='grid font-semibold'>
            <div className='truncate'>{name}</div>
          </div>
          <div className='text-sm text-gray-700'>{t('file not sent')}</div>
        </div>
      </div>
      <div
        className='flex items-center'
        style={{
          paddingTop: 6,
        }}
      >
        {isUploading ? (
          <div
            style={{
              width: 24,
              height: 24,
            }}
            className='flex'
          >
            <MiniLoader className='m-auto' color='gray' />
          </div>
        ) : (
          <Button
            styling='naked'
            className='ManualUploadFile__btn'
            onClick={onDeleteFile}
            disabled={disabled}
            type='button'
          >
            <BucketIcon />
          </Button>
        )}
      </div>
    </div>
  );
}
