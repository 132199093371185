import './TopProgressLoader.scss';

interface Props {
  isLoading: boolean;
  orange?: boolean;
  backgroundColor?: string;
  className?: string;
}

export default function TopProgressLoader({
  isLoading,
  backgroundColor,
  className = '',
}: Props) {
  if (isLoading) {
    return (
      <div className={`TopProgressLoader absolute w-full ${className}`}>
        <div className='TopProgressLoader__container'>
          <div className='TopProgressLoader__indicator' />
        </div>
      </div>
    );
  }

  return (
    <div className='TopProgressLoader absolute w-full'>
      <div className='TopProgressLoader__container'>
        <div
          className='TopProgressLoader__indicator TopProgressLoader__indicator--noAnimation !w-full'
          style={{
            backgroundColor,
          }}
        />
      </div>
    </div>
  );
}
