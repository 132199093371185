import { useTranslation } from 'react-i18next';
import Button from '../Button';
import { useState } from 'react';
import classNames from 'classnames';
import TextArea from '../TextArea/TextArea';
import { useLocation } from 'react-router-dom';
import { AuthedRoutesEnum } from '@/core/enums';
import { ClientFeedback } from '@/core/types';
import { useFeedback } from './useFeedback';
import { postFeedback } from '@/core/api';
import ConfirmDemoModal from '@/pages/Seller/ConfirmDemoModal';
import { useModal } from '../Modal/useModal';

const PageMapping: {
  [key: string]: string;
} = {
  [AuthedRoutesEnum.DataFiles]: 'Turnover data',
  [AuthedRoutesEnum.PurchasePrices]: 'Purchase prices',
  [AuthedRoutesEnum.Filing]: 'Filings',
  [AuthedRoutesEnum.SetupOverview]: 'VAT Setup',
  [AuthedRoutesEnum.VAT]: 'Tax details',
  [AuthedRoutesEnum.Datev]: 'DATEV',
  [AuthedRoutesEnum.ProformaInvoices]: 'Proforma',
  [AuthedRoutesEnum.OSSFiles]: 'OSS Files',
  ['/']: 'Global overview',
};

interface Props {
  testId?: string;
}

export default function FeedbackWidget({ testId }: Props) {
  const { t } = useTranslation();
  const location = useLocation();

  const {
    isModalOpened: isConfirmDemoModalOpened,
    closeModal: closeConfirmDemoModal,
    openModal: openConfirmDemoModal,
  } = useModal();

  const [feedback, setFeedback] = useState<ClientFeedback>({
    positive: null,
    page: PageMapping[location.pathname] || 'Unknown page',
    comment: '',
  });

  const { isFeedbackSent, setIsFeedbackSent } = useFeedback();

  const currentPageFeedbackSent = isFeedbackSent[location.pathname];

  const [isLoading, setIsLoading] = useState(false);

  const toggleFeedbackType = (v: number) => {
    setFeedback((prev) => ({
      ...prev,
      positive: prev.positive === v ? null : v,
    }));
  };

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newComment = event.target.value;
    setFeedback((prev) => ({
      ...prev,
      comment: newComment,
    }));
  };

  const handleSendFeedback = () => {
    postFeedback(feedback)
      .then(() => {
        setIsFeedbackSent((prev) => ({
          ...prev,
          [location.pathname]: true,
        }));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div
        className='mb-4 ml-auto mt-4 space-y-4 rounded-lg border-2 border-white bg-white bg-opacity-50 p-4 xl:mt-0'
        data-testid={testId}
      >
        {currentPageFeedbackSent ? (
          <>
            <div>
              <div className='font-semibold'>
                {t('Thank you for your feedback')}
              </div>
              <div>
                {t(
                  'You can also schedule a call with our team to discuss your needs'
                )}
              </div>
            </div>
            <Button
              onClick={openConfirmDemoModal}
              data-testid='schedule-call-btn'
            >
              {t('Schedule a call')}
            </Button>
          </>
        ) : (
          <>
            <div className='flex items-center justify-between'>
              <div>
                <div className='font-semibold'>{t('Share your feedback')}</div>
                <div>
                  {t('Are you satisfied with functionality of this page')}
                </div>
              </div>
              <div className='flex shrink-0 gap-x-1'>
                <Button
                  styling='outline'
                  data-testid='negative-feedback-btn'
                  className={classNames('!px-2.5 !py-3', {
                    '!bg-green': feedback.positive === 0,
                  })}
                  onClick={() => toggleFeedbackType(0)}
                  disabled={isLoading}
                >
                  <img
                    src={'/images/negative_emoji.png'}
                    alt='NegativeFeedbackImage'
                    className='mx-auto w-6'
                  />
                </Button>
                <Button
                  styling='outline'
                  className={classNames('!px-2.5 !py-3', {
                    '!bg-green': feedback.positive === 1,
                  })}
                  onClick={() => toggleFeedbackType(1)}
                  disabled={isLoading}
                  data-testid='positive-feedback-btn'
                >
                  <img
                    src={'/images/positive_emoji.png'}
                    alt='PositiveFeedbackImage'
                    className='mx-auto w-6'
                  />
                </Button>
              </div>
            </div>
            {feedback.positive !== null && (
              <>
                <TextArea
                  className='!pb-0'
                  placeholder={
                    feedback.positive === 1
                      ? 'Any feedback you want to leave'
                      : 'What could be improved'
                  }
                  value={feedback.comment}
                  onChange={handleCommentChange}
                  name='feedback-textarea'
                />
                <Button
                  className='!w-full'
                  onClick={handleSendFeedback}
                  disabled={isLoading}
                  data-testid='send-feedback-btn'
                >
                  {t('Send feedback')}
                </Button>
              </>
            )}
          </>
        )}
      </div>
      <ConfirmDemoModal
        closeModal={closeConfirmDemoModal}
        isModalOpened={isConfirmDemoModalOpened}
      />
    </>
  );
}
