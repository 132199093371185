import Switch from '@/components/Switch/Switch';
import { setQuarter } from 'date-fns';
import { Select } from '@/components/Select/Select';
import Button from '@/components/Button';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import {
  PagingInfo,
  SelectOption,
  NormalizedSellerFilingReport,
  TurnoverItem,
} from '@/core/types';
import { formatDate, formatNumber } from '@/core/helpers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getInvoiceBook } from '@/core/api';
import { getCurrentOSSSeSeReport } from '../OSSFiles.helpers';

interface Props {
  reports: NormalizedSellerFilingReport[];
  counts: PagingInfo | undefined;
  paging: PagingInfo | undefined;
  isLoading: boolean;
  setCounts: Dispatch<SetStateAction<PagingInfo | undefined>>;
  selectCountryOptions: SelectOption[];
  setItems: Dispatch<SetStateAction<TurnoverItem[] | undefined>>;
  setPaging: Dispatch<SetStateAction<PagingInfo | undefined>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const InvoiceBookOSSSeSeHeader = ({
  reports,
  counts,
  paging,
  isLoading,
  setCounts,
  selectCountryOptions,
  setItems,
  setPaging,
  setIsLoading,
}: Props) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const country = searchParams.get('country');
  const year = searchParams.get('year');
  const isOSSTransactionsOnly = searchParams.get('is_oss');
  const value = searchParams.get('value');
  const type = searchParams.get('type');
  const companyId = searchParams.get('company_id');
  const currentPage = +(searchParams.get('page') || 1);

  const periodsQuarter = useMemo(
    () =>
      type && value && year
        ? type === 'month'
          ? formatDate(new Date(+year, +value, 1), 'Q')
          : value
        : undefined,
    [type, value, year]
  );

  const periods = useMemo(() => {
    if (!periodsQuarter || !year) return [];
    const months = Array(12)
      .fill(1)
      .map((_, i) => i);

    return [
      { id: -1, name: `Q${periodsQuarter}` },
      months.slice((+periodsQuarter - 1) * 3, +periodsQuarter * 3).map((n) => ({
        id: n,
        name: formatDate(new Date(+year, n, 1), 'MMM'),
      })),
    ].flat();
  }, [periodsQuarter, year]);

  const currentOSSReport = useMemo(() => {
    return getCurrentOSSSeSeReport(reports);
  }, [reports]);

  useEffect(() => {
    if (
      value &&
      year &&
      periodsQuarter &&
      companyId &&
      (type === 'month' ||
        (type === 'quarter' && (country || isOSSTransactionsOnly)))
    ) {
      getInvoiceBook(
        companyId,
        'quarter',
        +year,
        +periodsQuarter,
        0,
        1,
        undefined
      )
        .then((res) => {
          setCounts(res.paging);
        })
        .catch(console.error);
    }
    // eslint-disable-next-line
  }, [periodsQuarter]);

  useEffect(
    () => {
      if (year && value && type && companyId) {
        setIsLoading(true);
        getInvoiceBook(
          companyId,
          type as 'month' | 'quarter',
          +year,
          type === 'month' ? +value + 1 : +value,
          isOSSTransactionsOnly ? 1 : 0,
          currentPage,
          country ? +country : undefined
        )
          .then((res) => {
            setPaging(res.paging);
            setItems(res.data);
            if (type === 'quarter' && !country && !isOSSTransactionsOnly) {
              setCounts(res.paging);
            }
          })
          .catch(() => {
            setPaging(undefined);
            setItems([]);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        navigate(
          { pathname: '/', search: '' },
          {
            replace: true,
          }
        );
      }
    },
    // eslint-disable-next-line
    [companyId, country, currentPage, isOSSTransactionsOnly, type, value, year]
  );

  return (
    <div className='max-w-[2000px] border-b px-14 py-4'>
      <div className='space-y-4'>
        <div className='flex items-center justify-between'>
          <div className={`flex gap-x-2 text-h3 font-extrabold`}>
            <span
              className={`${i18n.language === 'de' ? 'order-2' : 'order-1'}`}
            >
              {year &&
                periodsQuarter &&
                formatDate(
                  setQuarter(new Date(+year, 0, 1), +periodsQuarter),
                  'QQQ yyyy'
                )}
            </span>
            <span
              className={`${i18n.language === 'de' ? 'order-1' : 'order-2'}`}
            >
              {i18n.language === 'de'
                ? t('Invoice book')
                : t('Invoice book').toLowerCase()}
            </span>
          </div>
          <div className='flex gap-x-6'>
            <div>
              <div className='text-sm uppercase text-gray-700'>
                {t('Transactions')}
              </div>
              <div className='font-semibold'>
                {counts?.total_rows && formatNumber(counts.total_rows)}
              </div>
            </div>
            <div>
              <div className='text-sm uppercase text-gray-700'>
                {t('OSS transactions')}
              </div>
              <div className='font-semibold'>
                {counts?.total_oss_rows && formatNumber(counts.total_oss_rows)}
              </div>
            </div>
            <div>
              <div className='text-sm uppercase text-gray-700'>
                {t('VAT amount')}
              </div>
              <div className='font-semibold'>
                {counts?.total_vat_amount &&
                  formatNumber(counts.total_vat_amount / 100, {
                    currency: 'EUR',
                  })}
              </div>
            </div>
            <div>
              <div className='text-sm uppercase text-gray-700'>
                {t('OSS VAT Declared')}
              </div>
              <div className='font-semibold'>
                {currentOSSReport?.payment_instruction &&
                  formatNumber(
                    currentOSSReport?.payment_instruction.amount.amount / 100,
                    {
                      currency:
                        currentOSSReport?.payment_instruction.amount.currency,
                    }
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className='flex items-center justify-between gap-x-4'>
          <div className='z-10 flex items-center gap-x-2'>
            <Select
              search
              placeholder='VAT country'
              options={
                !!selectCountryOptions.length
                  ? [
                      {
                        id: -1,
                        name: t('Clear'),
                      },
                      ...selectCountryOptions,
                    ]
                  : []
              }
              value={country ? +country : undefined}
              onChange={(v) => {
                if (v === -1) {
                  searchParams.delete('country');
                } else {
                  searchParams.set('country', String(v));
                }
                searchParams.delete('page');
                setSearchParams(searchParams);
              }}
              className='Select--InvoiceBook !min-w-[144px] !pb-0'
            />
            <Select
              placeholder='Period'
              options={periods}
              value={type === 'quarter' ? -1 : value !== null ? +value : -1}
              onChange={(v) => {
                if (v === -1) {
                  searchParams.set('type', 'quarter');
                  searchParams.set('value', periodsQuarter!);
                } else {
                  searchParams.set('type', 'month');
                  searchParams.set('value', String(v));
                }
                searchParams.delete('page');
                setSearchParams(searchParams);
              }}
              className='Select--InvoiceBook !min-w-[144px] !pb-0'
            />
          </div>
          <div className='ml-4 flex items-center gap-x-2'>
            <span>{t('OSS transactions only')}</span>
            <Switch
              checked={!!isOSSTransactionsOnly}
              onChange={(e) => {
                if (e.target.checked) {
                  searchParams.set('is_oss', '1');
                } else {
                  searchParams.delete('is_oss');
                }
                searchParams.delete('page');
                setSearchParams(searchParams);
              }}
              size='sm'
            />
          </div>
        </div>
        <div className='flex items-center justify-between'>
          <div className='flex flex-wrap gap-x-4'>
            {!isLoading && (country || type === 'month') && (
              <>
                {paging?.total_rows && (
                  <div className='flex items-center'>
                    <span className='text-gray-700'>{t('Transactions')}</span>
                    &nbsp;
                    <span>{formatNumber(paging.total_rows)}</span>
                  </div>
                )}
                {paging?.total_oss_rows && (
                  <div className='flex items-center'>
                    <span className='text-gray-700'>
                      {t('OSS transactions')}
                    </span>
                    &nbsp;
                    <span>{formatNumber(paging?.total_oss_rows)}</span>
                  </div>
                )}
                {paging?.total_net_amount && (
                  <div className='flex items-center'>
                    <span className='text-gray-700'>{t('Netto')}</span>
                    &nbsp;
                    <span>
                      {formatNumber(paging.total_net_amount / 100, {
                        currency: 'EUR',
                      })}
                    </span>
                  </div>
                )}
                {paging?.total_brutto_amount && (
                  <div className='flex items-center'>
                    <span className='text-gray-700'>{t('Brutto')}</span>
                    &nbsp;
                    <span>
                      {formatNumber(paging.total_brutto_amount / 100, {
                        currency: 'EUR',
                      })}
                    </span>
                  </div>
                )}
                {paging?.total_vat_amount && (
                  <div className='flex items-center'>
                    <span className='text-gray-700'>{t('VAT amount')}</span>
                    &nbsp;
                    <span>
                      {formatNumber(paging.total_vat_amount / 100, {
                        currency: 'EUR',
                      })}
                    </span>
                  </div>
                )}
                <Button
                  styling='naked-blue'
                  className='!font-semibold'
                  onClick={() => {
                    searchParams.delete('country');
                    searchParams.set('type', 'quarter');
                    searchParams.set('value', periodsQuarter!);
                    setSearchParams(searchParams);
                  }}
                >
                  {t('Clear')}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
