import { format, getQuarter } from 'date-fns';
import { de } from 'date-fns/locale';
import i18next from 'i18next';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { ScriptHTMLAttributes } from 'react';
import {
  ACCESS_TOKEN_KEY,
  COOKIE,
  CURRENCY_SYMBOLS,
  ID_TOKEN_KEY,
  IsProduction,
  REFRESH_TOKEN_KEY,
} from './constants';
import { Country, FilingFrequencyType, Lang, UploadedFile } from './types';

export const isCodeValid = (value: string) =>
  // eslint-disable-next-line
  /^[0-9]+/g.test(value) && value.length === 6;

export const isPasswordValid = (value: string) =>
  containsCapital(value) &&
  containsNonCapital(value) &&
  containsDigit(value) &&
  containsSpecialCharacter(value) &&
  noSpacesOnEnds(value) &&
  isPasswordLongEnough(value);

export function noSpacesOnEnds(v: string) {
  return v === v.trim();
}

export function containsCapital(v: string) {
  return /[A-Z]/.test(v);
}
export function containsNonCapital(v: string) {
  return /[a-z]/.test(v);
}
export function containsDigit(v: string) {
  return /\d/.test(v);
}
export function containsSpecialCharacter(v: string) {
  const characters = [
    '^',
    '$',
    '*',
    '.',
    '[',
    ']',
    '{',
    '}',
    '(',
    ')',
    '?',
    '"',
    '!',
    '@',
    '#',
    '%',
    '&',
    '/',
    '\\',
    ',',
    '>',
    '<',
    "'",
    ':',
    ';',
    '|',
    '_',
    '~',
    '`',
    '=',
    '+',
    '-',
  ];

  return !!v.split('').filter((c) => characters.includes(c)).length;
}
export function isPasswordLongEnough(password: string) {
  return password.length >= 8;
}

export const isEmail = (value: string) =>
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
    value
  );

export const isPhone = (value: string) =>
  // eslint-disable-next-line
  /^\+(?:[0-9] ?){6,14}[0-9]$/g.test(value.replace(/\s/g, ''));

export const formatNumber = (
  amount: number,
  options?: Intl.NumberFormatOptions,
  lang?: Lang,
  showCurrencySymbol?: boolean
) => {
  const language = lang || i18next.language || 'de';

  if (showCurrencySymbol) {
    const { currency, ...rest } = options || {};

    const curr = (currency || '').toUpperCase();

    const arr = [
      new Intl.NumberFormat(language, rest).format(amount),
      CURRENCY_SYMBOLS[curr] || curr,
    ].filter((v) => !!v);

    return language === 'en' ? arr.reverse().join(' ') : arr.join(' ');
  }

  return new Intl.NumberFormat(language, {
    ...(options
      ? {
          ...options,
          style: options.currency ? 'currency' : options.style,
        }
      : {}),
  }).format(amount);
};

export const getRetinaImgSrc = (href: string, size: string = '2x') => {
  if (!href) return undefined;
  return `${href} ${size}`;
};

export function stripSpaces(str: string) {
  return str.replace(/\s/g, '');
}

export function toggleLockScroll(isLocked: boolean) {
  document.querySelector('body')!.style.overflow = isLocked ? 'hidden' : '';
}

export function isTokenInvalid(tokenStr: string) {
  try {
    const token: JwtPayload = jwtDecode(tokenStr);
    return token.exp && Date.now() >= token.exp * 1000;
  } catch (error) {
    return true;
  }
}

export function setTokensToLocalStorage(
  accessToken: string,
  refreshToken: string,
  idToken: string
) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  localStorage.setItem(ID_TOKEN_KEY, idToken);
}

export const pushEventToDataLayer = (event: string) => {
  if (IsProduction) {
    window.dataLayer?.push({ event });
  }
};

export function getCookie(cname: string) {
  if (typeof window !== 'object') return '';
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function loadScript(
  options: ScriptHTMLAttributes<HTMLScriptElement> & {
    innerHTML?: string;
    ['data-testid']?: string;
    onLoadListener?: EventListener;
  },
  container: Element | null = null
) {
  const head = document.querySelector('head');
  const script = document.createElement('script');

  Object.entries(options).forEach(([key, value]) => {
    if (key !== 'onLoadListener') {
      if (key === 'innerHTML') {
        (script as any)[key] = value;
      } else {
        script.setAttribute(key, value);
      }
    }
  });

  if (options.onLoadListener) {
    script.addEventListener('load', options.onLoadListener);
  }

  if (container) {
    container.appendChild(script);
  } else {
    head!.appendChild(script);
  }
}

export function sleep(ms: number = 1500) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function lockScroll(isLocked: boolean) {
  document.querySelector('html')!.style.overflow = isLocked ? 'hidden' : '';
}

export const getUTCDate = (d = new Date()) => {
  return new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds()
  );
};

export const getRandomColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return '#' + randomColor;
};

export const formatSizeUnits = (bytes: number) => {
  if (bytes >= 1073741824) {
    return Math.round(bytes / 1073741824) + ' GB';
  }
  if (bytes >= 1048576) {
    return Math.round(bytes / 1048576) + ' MB';
  }
  if (bytes >= 1024) {
    return Math.round(bytes / 1024) + ' KB';
  }
  if (bytes > 1) {
    return bytes + ' bytes';
  }
  if (bytes === 1) {
    return bytes + ' byte';
  }
  return '0 bytes';
};

export function capitalize(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

export function downloadFile(href: string, name: string) {
  const link = document.createElement('a');
  link.href = href;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const removeTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(ID_TOKEN_KEY);
};

export const formatDate = (
  date: Date | number,
  str: string,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  }
) => {
  const validDate =
    (date instanceof Date && !isNaN(date.getTime())) ||
    typeof date === 'number';

  if (!validDate) {
    console.error('Invalid date:', date);
    return '';
  }

  if (i18next.language === 'de') {
    options = {
      ...(options || {}),
      locale: de,
    };
  }

  return format(date, str, options);
};

export function getAbbrValue(num: number) {
  function intlFormat(num: number) {
    return new Intl.NumberFormat(i18next.language || 'de').format(
      Math.round(num * 10) / 10
    );
  }

  if (num >= 1000000000)
    return (
      intlFormat(num / 1000000000) + (i18next.language === 'de' ? 'Mrd.' : 'B')
    );
  if (num >= 1000000)
    return (
      intlFormat(num / 1000000) + (i18next.language === 'de' ? 'Mil.' : 'M')
    );
  if (num >= 1000)
    return intlFormat(num / 1000) + (i18next.language === 'de' ? 'Tsd.' : 'k');
  return intlFormat(num);
}

export function scrollToElementById(id: string) {
  const element = document.getElementById(id);
  const header = document.getElementById('header');

  if (!header || !element) return;

  window.scrollTo(
    0,
    element.getBoundingClientRect().top +
      (window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop) -
      header.offsetHeight
  );
}

export const setCookie = (name: string, value: string) => {
  if (typeof window !== 'object') return;
  const date = new Date();
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);

  if (import.meta.env.MODE === 'development') {
    document.cookie = `${name}=${value}; path=/; expires=${date} max-age=${
      60 * 60 * 24 * 365
    };`;
  }

  document.cookie = `${name}=${value}; path=/; domain=.countx.com; expires=${date} max-age=${
    60 * 60 * 24 * 365
  };`;
};

export function deleteCookie(name: string) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie =
    name +
    '=; Path=/; domain=.countx.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function deleteAllCookies() {
  document.cookie.split(';').forEach(function (cookie) {
    deleteCookie(cookie.split('=')[0]);
  });
}

export function capitalizeEach(str: string) {
  return str
    .split(' ')
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
}

export function getBoundingAbsoluteRect(el: HTMLElement): {
  x: number;
  y: number;
  bottom: number;
  top: number;
  left: number;
  right: number;
  width: number;
  height: number;
} {
  const rect = el.getBoundingClientRect();
  return {
    x: rect.x + window.scrollX,
    y: rect.y + window.scrollY,
    bottom: rect.bottom + window.scrollY,
    top: rect.top + window.scrollY,
    left: rect.x + window.scrollX,
    right: rect.x + window.scrollX + rect.width,
    width: rect.width,
    height: rect.height,
  };
}

export const isStatisticsCookieAccepted = () =>
  getCookie(COOKIE.CookieConsent)?.includes('statistics:true') || false;

export const getFilingPeriodStr = ({
  normalizedFilingPeriodFrom,
  normalizedFilingPeriodTo,
  reporting_frequency,
}: {
  normalizedFilingPeriodFrom: Date | undefined;
  normalizedFilingPeriodTo: Date | undefined;
  reporting_frequency: FilingFrequencyType;
}) => {
  if (!normalizedFilingPeriodFrom) {
    return '';
  }

  let from = undefined;
  if (reporting_frequency === 'quarterly') {
    from = `Q${getQuarter(
      normalizedFilingPeriodFrom
    )} ${normalizedFilingPeriodFrom.getFullYear()}`;
  } else if (reporting_frequency === 'yearly') {
    from = normalizedFilingPeriodFrom.getFullYear();
  } else {
    from = format(normalizedFilingPeriodFrom, 'MMM yyyy');
  }

  let to = undefined;
  if (normalizedFilingPeriodTo) {
    if (reporting_frequency === 'quarterly') {
      to = `Q${getQuarter(
        normalizedFilingPeriodTo
      )} ${normalizedFilingPeriodTo.getFullYear()}`;
    } else if (reporting_frequency === 'yearly') {
      to = normalizedFilingPeriodTo.getFullYear();
    } else {
      to = format(normalizedFilingPeriodTo, 'MMM yyyy');
    }
  }

  return to ? `${from}—${to}` : `${from}`;
};

export function getDateFormate(period_type: 'month' | 'quarter' | 'year') {
  switch (period_type) {
    case 'month':
      return 'MMM yyyy';
    case 'quarter':
      return 'QQQ yyyy';
    case 'year':
      return 'yyyy';
  }
}

export const isMUFile = (f: UploadedFile) => f.source === 'manual';

export const isCountry = (v: string | Country | undefined): v is Country =>
  (v as Country)?.short_name !== undefined;

export const PASSWORD_VALIDATION_PARAMETERS = [
  'Min 8 characters',
  'number',
  'special character',
  'uppercase letter',
  'lowercase letter',
  'no whitespace from both ends',
];

export const passwordValidators: {
  [key: string]: (v: string) => boolean;
} = {
  'Min 8 characters': isPasswordLongEnough,
  number: containsDigit,
  'special character': containsSpecialCharacter,
  'uppercase letter': containsCapital,
  'lowercase letter': containsNonCapital,
  'no whitespace from both ends': noSpacesOnEnds,
};

export function openPdfViewer(
  base64: string,
  filename = 'document.pdf',
  contentType = 'application/pdf'
) {
  // Convert Base64 string to a Blob
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([bytes], { type: contentType });
  const file = new File([blob], filename, { type: contentType });

  const fileUrl = URL.createObjectURL(file);
  window.open(fileUrl);
}

export function getFileExtension(file: File) {
  return file.name.split('.').pop();
}

export function validateFileMimeType(
  file: File,
  validMimeTypes = [
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'text/csv', // .csv
    'application/csv', // .csv
  ]
) {
  return validMimeTypes.includes(file.type);
}
