import Button from '@/components/Button';
import { Loader } from '@/components/Loader/Loader';
import { Spinner } from '@/components/Spinner';
import {
  regenerateJTLCredentials,
  postJTLCredentials,
  getJTLCredentials,
} from '@/core/api';
import {
  activeCompanySelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';
import { useAppSelector } from '@/hooks';
import useNotification from '@/hooks/useNotification';
import PageLayout from '@/wrappers/PageLayout';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import copy from 'copy-to-clipboard';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CopyIcon from '@/icons/copy.svg?react';
import { JTLSecretKey } from '@/components/ConnectJTLModal/JTLSecretKey';
import DownloadIcon from '@/icons/download.svg?react';
import CheckmarkIcon from '@/icons/green-checkmark.svg?react';
import { downloadFile, getRetinaImgSrc } from '@/core/helpers';
import BackIcon from '@/icons/back.svg?react';
import { Link, useLocation } from 'react-router-dom';

const faqJTLConnector = [
  {
    id: 1,
    question: 'Where do I need to set up the app?',
    answer:
      'You need to set it up on the server that hosts the JTL database or on a computer with permanent access to it. This computer must run continuously or be turned on often to ensure that all new data from JTL Wawi is successfully sent to countX.',
  },
  {
    id: 2,
    question: 'Do I need to restart the app regularly?',
    answer:
      'No, the connector runs automatically in the background after installation, restarts by itself when server is restarted, and ensures continuous data transfer.',
  },
  {
    id: 3,
    question: 'Do I need any other connector?',
    answer:
      'No, you don’t need it. Our connector automatically handles sending data from JTL Wawi to countX.',
  },
];

export default function JtlInstruction() {
  const { t } = useTranslation();
  const querClient = useQueryClient();
  const activeCompany = useAppSelector(activeCompanySelector);
  const queryKey = useAppSelector(queryKeySelector);
  const { showNotification } = useNotification();
  const [isGenerating, setIsGenerating] = useState(false);
  const [password, setPassword] = useState('');
  const location = useLocation();

  const { data: jtlCredentials, isLoading } = useQuery({
    queryKey: ['SellerJtlCredentials', queryKey],
    queryFn: () => getJTLCredentials(activeCompany?.company_id),
  });

  const onClickCopy = (text: string, message: string) => () => {
    copy(text);
    showNotification({
      type: 'success',
      text: message,
    });
  };

  const onClickGenerate = () => {
    setIsGenerating(true);
    const apiFn = jtlCredentials
      ? regenerateJTLCredentials
      : postJTLCredentials;

    apiFn(activeCompany?.company_id)
      .then((res) => {
        querClient.setQueryData(['SellerJtlCredentials', queryKey], {
          username: res.username,
          status: res.status,
        });
        setPassword(res.password);
        setIsGenerating(false);
      })
      .catch(() => {
        setIsGenerating(false);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageLayout className='flex-grow' rightColumn={<></>}>
      <div className='max-w-[874px] rounded-lg bg-white px-6 py-4'>
        <Link
          className='group mb-2 flex cursor-pointer items-center text-sm font-semibold transition hover:text-green'
          to={{
            pathname: location.state?.prevRoute || '/',
          }}
        >
          <BackIcon className='mr-1 [&>circle]:transition-[fill] group-hover:[&>circle]:fill-green' />
          <div>{t('Back')}</div>
        </Link>
        <div className='text-h3 font-black'>{t('JTL connector app')}</div>
        <h4 className='pt-2 font-semibold'>
          {t('Integrate your JTL data with countX')}
        </h4>
        <div>
          {t(
            'Seamlessly connect JTL and countX with our integration tool. This app ensures that countX gets all the needed data automatically from JTL Wawi'
          )}
        </div>
        <div className='divide-y pb-6 pt-1'>
          {faqJTLConnector.map((item) => (
            <div
              key={item.id}
              className='flex items-start justify-start gap-x-3 py-3'
            >
              <CheckmarkIcon className='shrink-0' />
              <div>
                <h5 className='font-semibold'>{t(item.question)}</h5>
                <p>{t(item.answer)}</p>
              </div>
            </div>
          ))}
        </div>
        <h4 className='Title--sm pb-4 font-extrabold'>
          {t('countX login information')}
        </h4>
        <div className='mb-6 space-y-2 divide-y rounded-lg bg-gray-300 px-4 py-3'>
          <div className='font-semibold'>{t('No connection established')}</div>
          <div className='pt-2'>
            <div className='flex justify-between gap-x-2'>
              <div>
                <div>{t('Access ID')}</div>
                <div className='mb-2 flex items-center gap-x-2 font-semibold'>
                  {jtlCredentials ? (
                    <>
                      <span>{jtlCredentials.username}</span>{' '}
                      <button
                        onClick={onClickCopy(
                          jtlCredentials.username,
                          'Access ID is copied'
                        )}
                      >
                        <CopyIcon className='h-6 w-6' />
                      </button>
                    </>
                  ) : (
                    <span>{t('Not generated yet')}</span>
                  )}
                </div>
              </div>
              <div className='shrink-0'>
                <Button
                  disabled={isGenerating}
                  className='flex items-center justify-center gap-x-2'
                  styling='outline'
                  onClick={onClickGenerate}
                >
                  {isGenerating && <Spinner />}{' '}
                  <span>{t(jtlCredentials ? 'Regenerate' : 'Generate')}</span>
                </Button>
              </div>
            </div>
            <JTLSecretKey
              password={password}
              jtlCredentials={jtlCredentials}
              onClickCopy={onClickCopy}
            />
          </div>
        </div>
        <div className='-mx-6 mb-4 h-[2px] bg-gray-300' />
        <div className='border-b pb-2 text-h4 font-extrabold'>
          {t('Instructions')}
        </div>
        <div className='space-y-4 divide-y'>
          <div className='flex justify-between gap-x-4 pt-2'>
            <div>
              <div className='font-bold'>1. {t('Download app')}</div>
              <div>
                {t(
                  'It is available for Windows only and requires 17 MB of free space.'
                )}
              </div>
            </div>
            <div className='shrink-0'>
              <Button
                className='flex items-center gap-x-2 !pl-3'
                onClick={() => {
                  downloadFile(
                    '/JtlCountXConnectorClientSetup.exe',
                    'JtlCountXConnectorClientSetup.exe'
                  );
                }}
              >
                <DownloadIcon />
                <div>{t('Download app')}</div>
              </Button>
            </div>
          </div>
          <div className='pt-2'>
            <div className='font-bold'>2. {t('Install the app')}</div>
            <div className='mb-4'>
              {t(
                'Install the app by following the standard installation wizard.'
              )}
            </div>
            <img
              src='/images/jtl-instruction/installer.png'
              srcSet={getRetinaImgSrc(
                '/images/jtl-instruction/installer-2x.png'
              )}
            />
          </div>
          <div className='pt-2'>
            <div className='font-bold'>3. {t('Launch the app')}</div>
            <div className='mb-4'>
              {t(
                'Find the "JTL to countX Connector" application in your Windows application list. If you receive the Windows notification "Der Computer wurde durch Windows geschützt", please click on "Weitere Informationen" to allow the application to run'
              )}
            </div>
            <img
              src='/images/jtl-instruction/launch.png'
              srcSet={getRetinaImgSrc('/images/jtl-instruction/launch-2x.png')}
              className='mb-4'
            />
            <img
              src='/images/jtl-instruction/launch-2.png'
              srcSet={getRetinaImgSrc(
                '/images/jtl-instruction/launch-2-2x.png'
              )}
            />
          </div>
          <div className='pt-2'>
            <div className='font-bold'>
              4. {t('Establish a JTL connection')}
            </div>
            <div className='mb-4'>
              <div>{t('Enter your access data, such as:')}</div>
              <div>— {t('Server URL')}</div>
              <div>— {t('Database name')}</div>
              <div>— {t('Username')}</div>
              <div>— {t('Passwort')}</div>
              <div className='pt-4'>
                {t('The app validates your data automatically.')}
              </div>
            </div>
            <img
              src='/images/jtl-instruction/establish-connection.png'
              srcSet={getRetinaImgSrc(
                '/images/jtl-instruction/establish-connection-2x.png'
              )}
            />
            <div className='my-4 whitespace-pre-line'>
              {t(
                'You can also autofill your data by clicking on the Gear icon and selecting "Copy from JTL Profile \'Standard\'." The data will be taken from your JTL Wawi app. Please note that the Database name can\'t be filled automatically. You should fill this field with "eazybusiness."'
              )}
            </div>
            <img
              src='/images/jtl-instruction/profile.png'
              srcSet={getRetinaImgSrc('/images/jtl-instruction/profile-2x.png')}
            />
          </div>
          <div className='pt-2'>
            <div className='flex justify-between gap-x-4'>
              <div className='font-bold'>
                5. {t('Generate countX credentials')}
              </div>
              <div>
                <Button
                  className='flex items-center gap-x-2'
                  onClick={onClickGenerate}
                  styling={jtlCredentials ? 'outline' : 'primary'}
                >
                  {isGenerating && <Spinner />}{' '}
                  <span>{t(jtlCredentials ? 'Regenerate' : 'Generate')}</span>
                </Button>
              </div>
            </div>
            <div className='pt-2'>
              <div className='flex justify-between gap-x-2'>
                <div>
                  <div>{t('Access ID')}</div>
                  <div className='mb-2 flex items-center gap-x-2 font-semibold'>
                    {jtlCredentials ? (
                      <>
                        <span>{jtlCredentials.username}</span>{' '}
                        <button
                          onClick={onClickCopy(
                            jtlCredentials.username,
                            'Access ID is copied'
                          )}
                        >
                          <CopyIcon className='h-6 w-6' />
                        </button>
                      </>
                    ) : (
                      <span>{t('Not generated yet')}</span>
                    )}
                  </div>
                </div>
              </div>
              <JTLSecretKey
                password={password}
                jtlCredentials={jtlCredentials}
                onClickCopy={onClickCopy}
              />
            </div>
          </div>
          <div className='pt-2'>
            <div className='font-bold'>
              6. {t('Log in to the app with the generated credentials')}
            </div>
            <div className='mb-4'>
              {t(
                'Open the "JTL to countX Connector" application window. Copy and paste the Access ID and Secret Key from the Client Portal. Click on the "Verbinden" button.'
              )}
            </div>
            <img
              src='/images/jtl-instruction/login.png'
              srcSet={getRetinaImgSrc('/images/jtl-instruction/login-2x.png')}
            />
          </div>
          <div className='pt-2'>
            <div className='font-bold'>
              7. {t('Both-ends connection established.')}
            </div>
            <div className='mb-4'>
              {t(
                "If you haven't received any errors, this means the connection is established. countX starts receiving data from JTL once the start date begins."
              )}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
