import {
  ClientTaxDetailsStatusEnum,
  EvaluatiionStatusEnum,
  JTLCredentialsStatusEnum,
  ManualUploadFilesWorkStatusEnum,
  RolesEnum,
  SellerFilingReportStatusEnum,
} from './enums';

export interface IRoute {
  path: string;
  component?: React.ComponentType<any>;
  exact?: boolean;
  redirectTo?: string;
  title?: string;
}

export interface SelectOption {
  id: string | number;
  name: string;
  content?: JSX.Element;
}

export enum UserFlagEnum {
  'amazon_fba_calculator' = 'amazon_fba_calculator',
  'oss_self_service' = 'oss_self_service',
}

export interface RegisterInput {
  phone?: string;
  email: string;
  name: string;
  family_name: string;
  country: string;
  company_name: string;
  password: string;
  language: 'de' | 'en';
  role: RolesEnum;
  newsletter_agreement: boolean;
  tos_accepted: boolean;
  tos_version: string;
  data_protection_accepted: boolean;
  data_protection_version: string;
  flag?: UserFlagEnum;
}
export interface RegisterUserInput {
  email: string;
  name: string;
  family_name: string;
  password: string;
  language: 'de' | 'en';
  newsletter_agreement: boolean;
  tos_accepted: boolean;
  tos_version: string;
  data_protection_accepted: boolean;
  data_protection_version: string;
  client_uuid: string;
  role: RolesEnum;
}

export type Lang = 'en' | 'de';

export interface ClientWarehouseBE {
  country: string;
  active_from: string;
  count_of_sold_items: number;
}
export interface ClientWarehouse {
  country: string;
  active_from: Date;
  count_of_sold_items: number;
}

export interface CountryThreshold {
  country: string;
  threshold: { value: number; currency: string };
}

export interface AmazonConnectCredentialsModel {
  seller_id: string;
}
export type AmazonDataImportStatus = 'in-process' | 'loaded' | 'not-started';
export interface AmazonDataImportStatusModel {
  processed: number;
  status: AmazonDataImportStatus;
  total: number;
}

export interface BaseResponseError {
  msg: string;
  msg_type: string;
}
export interface ValidationResponseError extends BaseResponseError {
  errors: {
    [key: string]: string;
  };
}

export interface Country {
  id: number;
  name: string;
  short_name: string;
}
export interface PublicClient {
  company_id: string;
  country: any;
  name: string;
}

export type QuestionType =
  | 'use_oss'
  | 'have_vat_ids'
  | 'vat_ids'
  | 'mu_feedback';

export interface ClientFeedback {
  positive: number | null;
  page: string;
  comment: string;
}
export interface Answer {
  answer: boolean | string;
}

export interface AmazonIntegration {
  has_api_refresh_token: boolean;
  has_mws_auth_token: boolean;
  name: string;
  seller_id: string;
  api_refresh_token_date?: string;
  invalid: boolean;
}

export interface VATId {
  Country: {
    id: number;
    name: string;
    short_name: string;
  };
  reporting_frequency: string;
  payment_frequency?: string;
  status: ClientTaxDetailsStatusEnum;
  vat_number: string;
  start_date_vat_number: string;
  tax_number: string;
  start_date_tax_number: string;
  status_updated_date?: string;
  tax_number_status?: number;
  vat_number_status?: number;
  registration_date?: string;
  filing_period_from: string;
  filing_period_to?: string;
}

export interface Amount {
  amount: number;
  currency: string;
}

export interface Payment {
  Amounts: Amount[];
  Country: {
    id: number;
    name: string;
    short_name: string;
  };
  due_date: string;
  type: 'local' | 'oss';
  original_currency: string;
  payment_instruction_id: number;
}

export interface Report {
  country: { id: number; short_name: string; name: string };
  deadline_date: string;
  type: string;
  period: ReportPeriod;
}

export interface FCTransfer {
  arrival_country: string;
  departure_country: string;
  number: number;
}
export interface CrossedOss {
  crossed_oss_threshold_label: string;
}

export interface UploadedFile {
  id: number;
  name: string;
  path: string;
  status: number;
  work_status: ManualUploadFilesWorkStatusEnum;
  imported_at: string;
  period_from: string;
  period_to: string;
  bucket: string;
  fresh?: boolean;
  errors: string[];
  source: Source;
  uploaded_by_client: boolean;
  errors_message?: ErrorsMessage;
  documents_with_error_number: number;
  update_progress?: UploadedDocumentUpdateProgress;
  documents_in_turnover_number: number;
}

export interface UploadedDocumentUpdateProgress {
  id: number;
  progress: number;
  total: number;
  type:
    | 'refresh-defined-fields'
    | 'remove-data'
    | 'bulk-update'
    | 'reload-data'
    | 'push-into-turnover'
    | 'upload-data';
  field?: string;
}
export interface ErrorsMessage {
  id: number;
  language: Lang;
  created_at: string;
  filename: string;
  bucket: string;
  message: string;
}

export enum CurrentReporterEnum {
  we = 'we',
  other = 'other',
  ossSelfService = 'oss_self_service',
}

export interface Clerk {
  Id: number;
  FullName: string;
  HubspotMeetingLink?: string;
}

export interface UserCompany {
  company_id: string;
  name: string;
  ManualUploadNeeded: boolean;
  ProformaExportNeeded: boolean;
  DATEVExportNeeded: boolean;
  EasybillClient: boolean;
  ErpName: string;
  DisplayDatevSince?: string;
  Clerk?: Clerk;
  ClientOSSDetails?: {
    RegistrationDate?: string;
    RegistrationCountry?: string;
    CurrentReporter?: CurrentReporterEnum;
    VATIdNumber?: string;
    FilingPeriodFrom?: string;
    FilingPeriodTo?: string;
    OssEffectiveStartDate?: string;
    SubmissionBy?: string;
  };
  PaymentOverdue: boolean;
  IsUseNewProcess: boolean;
}

export type PeriodType = 'month' | 'quarter' | 'year';

export interface ReportPeriod {
  id: number;
  type: PeriodType;
  from_date: string;
  to_date: string;
  label: string;
}

export interface Recepient {
  id: number;
  name: string;
  email: string;
}

export interface ReportConfirmationTemplate {
  recipients: Recepient[];
  period: ReportPeriod;
  country: Country;
}
export interface ReportConfirmation extends ReportConfirmationTemplate {
  files: string[];
  id: number;
  sent_at?: string;
  comment: string;
}

export interface PaymentInstructionTemplate {
  Country: {
    short_name: string;
    name: string;
  };
  payee: string;
  credit_institution: string;
  iban: string;
  bic: string;
  period: ReportPeriod;
  recipients: Recepient[];
  currency: string;
  purpose?: string;
  due_date?: string | null;
  is_sepa: boolean;
  summary_included?: boolean;
  is_null_report: boolean;
  comment?: string;
}

export interface ReportConfirmationFile {
  id: number;
  filename: string;
}

export interface PaymentConfirmationDocument {
  id: number;
  filename: string;
  bucket: string;
  externalUserUuid?: string;
  importedBy?: Clerk;
  path: string;
  created_at?: string;
}

export interface PaymentConfirmation {
  id: number;
  created: Date;
  externalUserUuid?: string;
  documents: PaymentConfirmationDocument[];
}

export interface PaymentInstruction
  extends Omit<PaymentInstructionTemplate, 'currency'> {
  id: number;
  purpose: string;
  due_date: string;
  amount: {
    currency: string;
    amount: number;
  };
  created_at: string;
  sent_at?: string;
  file?: string;
  confirmed?: boolean;
  confirmed_at?: string;
  payment_confirmation?: PaymentConfirmation;
  cancelled?: boolean;
  cancelled_at?: string;
}

export type SellerFilingReportType = 'oss' | 'local';

export interface SellerFilingReportFile {
  created_at: string;
  id: number;
  name: string;
  option: string;
  status: string;
}

export interface FilingReportConfirmation {
  comment: string;
  documents: ReportConfirmationFile[];
  id: number;
  sent_at: string;
}

export interface SellerFilingReport {
  confirmation: FilingReportConfirmation;
  country: Country;
  deadline_date: string;
  submitted_at?: string;
  id: number;
  payment_instruction?: PaymentInstruction;
  type: SellerFilingReportType;
  period: ReportPeriod;
  agenda_confirmed?: boolean;
  status: SellerFilingReportStatusEnum;
  files: SellerFilingReportFile[];
  fiscal_rep_required: boolean;
}

export interface NormalizedSellerFilingReport extends SellerFilingReport {
  completed_filing: boolean;
}

export type StopMailingType = 'manual-upload-needed';

export interface EasybillData {
  create_date: string;
  invalid_date: string;
  is_valid: boolean;
}

export interface BillbeeData {
  connected_at: string;
  invalid_at: string;
  is_active: boolean;
  is_valid: boolean;
}

export type TransactionType = 'REGULAR' | 'UNION-OSS';

export interface DatevTransaction {
  account: number;
  additional_content: string;
  additional_type: string;
  booking_text: string;
  cost1: string;
  cost2: string;
  counter_account: number;
  date: string;
  entry_type: number;
  external_document_nr: string;
  invoice_nr1: string;
  invoice_nr2: string;
  manual_skonto_in_dm: number;
  manual_skonto_in_euro: number;
  manual_skonto_in_fw: number;
  percent: number;
  revenue_in_dm: number;
  revenue_in_euro: number;
  skonto_in_dm: number;
  skonto_in_euro: number;
  tax_code: number;
  vat_id: string;
}

export interface DatevPaging {
  page: number;
  page_size: number;
  total_pages: number;
  total_rows: number;
}

export type DatevAggregatedItem = {
  entry_type: number;
  count: number;
  year: number;
  month: number;
};

export interface PostTypeformSubmissionInput {
  response_id: string;
  form_id: string;
  feature_flag: string;
}

export interface TypeformSubmission extends PostTypeformSubmissionInput {
  user_id: number;
  created_at: string;
}

export type FilingFrequencyType = 'monthly' | 'quarterly' | 'yearly';
export type PaymentFrequencyType = 'monthly' | 'quarterly';

export interface InvoiceBookResponse {
  paging: PagingInfo;
  data: TurnoverItem[];
}
export interface InvoiceDocumentsResponse {
  paging: PagingInfo;
  data: InvoiceDocument[];
}

export interface TurnoverItem {
  is_oss: boolean;
  channel_id: number;
  invoice_type: string;
  channel_name: string;
  foreign_transaction_number: string;
  invoice_number: string;
  document_date: string;
  arrival_state?: string;
  arrival_street?: string;
  arrival_postal_code?: string;
  departure_postal_code?: string;
  departure_city?: string;
  b2b_buyer_name?: string;
  b2b_buyer_vat_number?: string;
  vat_currency: string;
  arrival_country_id: number;
  departure_country_id: number;
  vat_country_id: number;
  arrival_city?: string;
  is_plausible: boolean;
  turnover_id: number;
  invoice_vat_rate: number;
  invoice_total_net_amount_for_vat_rate: number;
  invoice_total_brutto_amount?: number;
  invoice_total_vat_amount?: number;
  invoice_total_net_amount_eur?: number;
  invoice_total_brutto_amount_eur?: number;
  invoice_total_vat_amount_eur?: number;
  invoice_total_net_amount?: number;
}

export interface PagingInfo {
  page: number;
  page_size: number;
  total_pages: number;
  total_rows: number;
  total_oss_rows: number;
  total_vat_amount: number;
  total_brutto_amount: number;
  total_net_amount: number;
}

export interface AgrregatedDataType {
  country: { id: number; short_name: string; name: string };
  date: string;
  transactions_number: number;
  gross_amount: number;
  vat_amount: number;
}

export type Source = 'amazon' | 'easybill' | 'manual' | 'billbee';

export interface DUSourceItem {
  id: number;
  source_type: Source;
  files: UploadedFile[];
  type: string;
  status: number;
  no_data_needed: boolean;
}

export interface DataUnit {
  id: number;
  date: string;
  items: DUSourceItem[];
}

interface InvoiceDocumentError {
  severity: string;
  // severity: 'WARNING' | 'ERROR';
  code: string;
}

export interface InvoiceDocument {
  id: number;
  type?: string;
  source_type?: Source;
  invoice_number?: string;
  document_date?: string;
  currency?: string;
  departure_country?: string | Country;
  vat_country?: Country;
  is_oss?: boolean;
  arrival_country?: string | Country;
  vat_rate?: number;
  net_amount?: number;
  brutto_amount?: number;
  vat_amount?: number;
  b2b_buyer_vat_number?: string;
  channel?: string;
  errors: InvoiceDocumentError[];
  turnover_id?: number;
}

export interface InvoiceDocumentUpdateProgress {
  id: number;
  progress: number;
  total: number;
  type:
    | 'refresh-defined-fields'
    | 'remove-data'
    | 'bulk-update'
    | 'reload-data'
    | 'push-into-turnover'
    | 'upload-data';
  field?: keyof InvoiceDocument;
}

export interface InvoicesData {
  items: InvoiceDocument[];
  limit: number;
  page: number;
  total: number;
  filtered_total: number;
  errors: string[];
  plausible_errors: string[];
  rules: string[];
  documents_in_turnover: number;
}

export enum PeriodTypeEnum {
  month = 'month',
  quarter = 'quarter',
}

export interface GetEvaluationInput {
  start_date: string;
  end_date: string;
}
export interface PostEvaluationInput extends GetEvaluationInput {
  external_user_uuid: string;
}

export interface Evaluation {
  id: number;
  requestedAt: Date;
  period: string;
  status: EvaluatiionStatusEnum;
  file: string;
  vatCountry: string;
  externalUserUuid?: string;
  company_id: string;
  // createdAt: Date;
  // generationStartedAt: Date;
  // numRows: number;
  // numHealthCheckProblems: number;
  // departureCountry: string;
  // arrivalCountry: string;
  // errorMessage: string;
}

export interface NormalizedEvaluation extends Evaluation {
  startDate: Date;
  endDate: Date;
  type: 'month' | 'quarter' | 'year';
}

export interface ProformaInvoice {
  seller_account_id: string;
  vat_report_id: number;
  invoice_number: string;
  document_date: string;
  arrival_country: Country;
  departure_country: Country;
  total_net_amount: number;
  total_net_amount_eur?: number;
  total_brutto_amount: number;
  total_brutto_amount_eur?: number;
  total_vat_amount: number;
  total_vat_amount_eur?: number;
  currency: string;
  vat_rate: number;
  id: number;
  is_draft?: boolean;
}

export interface DataUnitStatsItem {
  data_unit_id: number;
  documents_count: number;
  errors_count: number;
  warnings_count: number;
  date: string;
  errors: ErrorDescriptionType[];
  warnings: ErrorDescriptionType[];
}

export interface ErrorDescriptionType {
  code: string;
  count: number;
}

export interface JTLCredentials {
  username: string;
  status: JTLCredentialsStatusEnum;
  filing_from?: string;
}

export interface CreateJTLCredentialsDTO extends JTLCredentials {
  password: string;
}

export interface InvoiceDocumentSuggestionsType {
  error_code: number;
  code: number;
  name: string;
}

export interface ResolveOptionsDataType extends InvoiceDocumentSuggestionsType {
  title: string;
  description: string[];
}
