import { HTMLAttributes } from 'react';
import './MiniLoader.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
  size?: 'sm' | 'lg';
  color?: 'gray' | 'white' | 'dark-green';
}

export default function MiniLoader({
  className = '',
  color = 'dark-green',
  size = 'sm',
  ...rest
}: Props & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...rest}
      className={classNames(
        'MiniLoader flex items-center justify-center',
        className,
        {
          'MiniLoader--sm': size === 'sm',
          'MiniLoader--gray': color === 'gray',
          'MiniLoader--white': color === 'white',
        }
      )}
    >
      <div className='lds-ring'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
