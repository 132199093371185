import { useToast } from '@/context/useToast';
import { normalizeEvaluation } from '@/core/normalization';
import { activeCompanySelector } from '@/core/store/user/userSlice';
import { useWSMessageHandler } from '@/core/ws/useWSMessageHandler';
import { isInvoiceBookGeneratedMessage } from '@/core/ws/ws-messages';
import { useAppSelector } from '@/hooks';
import { createPortal } from 'react-dom';
import { InvoiceBookToast } from './InvoiceBookToast/InvoiceBookToast';
import { isEvaluationToast } from '@/pages/InvoiceBook/InvoiceBook.helpers';
import { EvaluatiionStatusEnum, ToastType } from '@/core/enums';
import useNotification from '@/hooks/useNotification';
import { isEqual } from 'date-fns';
import { NormalizedEvaluation } from '@/core/types';

export const Toasts = () => {
  const { showNotification } = useNotification();
  const { setToasts, toasts, deleteToast } = useToast();
  const activeCompany = useAppSelector(activeCompanySelector);

  useWSMessageHandler((msg) => {
    if (isInvoiceBookGeneratedMessage(msg)) {
      const newEvaluation = normalizeEvaluation(msg.data);

      if (newEvaluation.status === EvaluatiionStatusEnum.Error) {
        setToasts((prev) =>
          prev.filter(
            (t) =>
              isEvaluationToast(t) &&
              !isSameEvaluationPeriod(newEvaluation, t.context)
          )
        );
        showNotification({
          text: 'Invoice book has not been generated',
          type: 'error',
        });
      } else {
        setToasts((prev) => {
          if (
            prev.some(
              (t) =>
                isEvaluationToast(t) &&
                isSameEvaluationPeriod(newEvaluation, t.context)
            )
          ) {
            return prev
              .map((t) => {
                if (
                  isEvaluationToast(t) &&
                  isSameEvaluationPeriod(newEvaluation, t.context)
                ) {
                  return {
                    ...t,
                    context: newEvaluation,
                  };
                }

                return t;
              })
              .filter((t, i, self) => {
                return (
                  self.findIndex(
                    (toast) =>
                      isEvaluationToast(toast) &&
                      isEvaluationToast(t) &&
                      isSameEvaluationPeriod(t.context, toast.context)
                  ) === i
                );
              });
          } else {
            const id = Date.now();
            return [
              {
                id,
                type: ToastType.evaluation,
                context: newEvaluation,
                company_id: newEvaluation.company_id,
              },
              ...prev,
            ];
          }
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  return createPortal(
    <div className='fixed bottom-8 right-8 w-full max-w-[524px] space-y-2'>
      {activeCompany &&
        toasts
          .filter((t) => t.company_id === activeCompany.company_id)
          .map((t) => {
            if (t.type === ToastType.evaluation) {
              return (
                <InvoiceBookToast
                  key={t.id}
                  evaluation={t.context}
                  close={deleteToast(t.id)}
                />
              );
            }
            return null;
          })}
    </div>,
    document.getElementById('toasts') as HTMLElement
  );
};

const isSameEvaluationPeriod = (
  newEvaluation: NormalizedEvaluation,
  evaluation: NormalizedEvaluation
) => {
  return (
    isEqual(newEvaluation.startDate, evaluation.startDate) &&
    newEvaluation.type === evaluation.type &&
    evaluation.company_id === newEvaluation.company_id
  );
};
