import './Loader.scss';

interface Props {
  className?: string;
}

export const Loader: React.FC<Props> = ({ className = '' }) => {
  return (
    <div className={`Loader ${className}`}>
      <div className='lds-roller'>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
