import { useEffect } from 'react';

export default function useIntersectionObserver(
  el: HTMLDivElement | null,
  onIntersecting: (v: boolean) => void,
  options: IntersectionObserverInit = {}
) {
  useEffect(() => {
    if (el) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            onIntersecting(entry.isIntersecting);
          });
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 1,
          ...options,
        }
      );

      observer.observe(el);

      return () => {
        observer.disconnect();
      };
    }
  }, [el, onIntersecting, options]);
}
