import './TextArea.scss';
import cn from 'classnames';
import { HTMLAttributes, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string | number;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  name?: string;
  id?: string;
  error?: string;
  touched?: boolean;
  label?: string;
  className?: string;
  rows?: number;
  disabled?: boolean;
  pattern?: string;
  inputClassName?: string;
}

const TextArea = ({
  value,
  onChange,
  placeholder,
  name,
  id,
  error,
  onBlur,
  touched,
  className = '',
  label,
  rows = 4,
  disabled = false,
  inputClassName = '',
  ...rest
}: Props & HTMLAttributes<HTMLTextAreaElement>) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = '73px';
      ref.current.style.height = 24 + ref.current.scrollHeight + 'px';
    }
  }, [value]);

  return (
    <div className={`TextArea ${className}`}>
      {label && (
        <div data-testid={`${name}-label`} className='mb-2'>
          {t(label)}
        </div>
      )}
      <textarea
        id={id}
        name={name}
        placeholder={placeholder ? t(placeholder)! : undefined}
        onChange={onChange}
        value={value}
        rows={rows}
        onBlur={onBlur}
        className={cn('TextArea__input block', inputClassName, {
          'TextArea__input--invalid': touched && !!error,
        })}
        disabled={disabled}
        ref={ref}
        data-testid={name}
        {...rest}
      />
      {touched && error && (
        <div className='TextArea__error' data-testid={`${name}-error`}>
          {t(error)}
        </div>
      )}
    </div>
  );
};

export default TextArea;
