import PageLayout from '../../wrappers/PageLayout';
import './SellerDashboard.scss';
import Integrations from '@/components/Integrations/Integrations';
import { useEffect, useMemo, useState } from 'react';
import { NormalizedSellerFilingReport } from '@/core/types';
import {
  getBillbee,
  getClientReports,
  getCompletedFiling,
  getEasybillTokenStatus,
  getJTLCredentials,
  getOngoingFiling,
  getStopMailing,
  getYearPayments,
  postClientAmazonConnect,
} from '@/core/api';
import { Loader } from '@/components/Loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { getUTCDate } from '@/core/helpers';
import SellerCalendarCollapse from '@/components/SellerCalendarCollapse';
import OpenTasks from '@/components/OpenTasks/OpenTasks';
import SellerChart from '@/components/SellerChart/SellerChart';
import { useFlags } from 'launchdarkly-react-client-sdk';
import MerryChristmasBanner from '@/components/MerryChristmasBanner';
import { differenceInCalendarMonths } from 'date-fns';
import { normalizeDateFE } from '@/core/normalization';
import Avatar from '@/components/Participants/Avatar';
import Shimmer from '@/components/Shimmer/Shimmer';
import {
  activeCompanySelector,
  companiesSelector,
  isOSSSelfServiceSelector,
  queryKeySelector,
  userSelector,
} from '@/core/store/user/userSlice';
import useNotification from '../../hooks/useNotification';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { AuthedRoutesEnum } from '@/core/enums';
import PaymentInstructionModal from '@/components/PaymentInstructionModal/PaymentInstructionModal';
import SubmitBOPModal from '@/components/SubmitBOPModal';
import usePaymentInstructionModal from './SellerFiling/usePaymentInstructionModal';
import { useSubmitBOPModal } from './SellerFiling/useSubmitBOPModal';
import NewYearIcon from '@/icons/newyeartoys.svg?react';
import { getColor } from '@/components/Participants/Participants.helpers';
import { useSellerIntegrations } from '@/components/Integrations/useSellerIntegrations';
import { useMU } from '../../context/useMU';
import { usePP } from '../../context/usePP';
import { OSSSeSeReportPreview } from '@/components/OSSSelfServiceWidget/OSSSeSeReportPreview';
import { getCurrentOSSSeSeReport } from '../OSSFiles.helpers';
import FeedbackWidget from '@/components/FeedbackWidget/FeedbackWidget';

export const STATE_CONSTANT = 'customer-app';

export default function SellerDashboard() {
  const activeCompany = useAppSelector(activeCompanySelector);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    vs4972NewYear2024,
    vs5151DisregardedInstruction,
    vs5438CpTwoFilesForPpFownloading,
  } = useFlags();

  const [isConnectingAmazon, setIsConnectingAmazon] = useState(false);
  const navigate = useNavigate();
  const locaton = useLocation();
  const params = new URLSearchParams(locaton.search);
  const state = params.get('state');
  const spapi_oauth_code = params.get('spapi_oauth_code');
  const selling_partner_id = params.get('selling_partner_id');
  const user = useAppSelector(userSelector);
  const queryKey = useAppSelector(queryKeySelector);
  const companies = useAppSelector(companiesSelector);
  const { showNotification } = useNotification();

  const {
    onClickConfirmPayment,
    isPaymentInstructionModalOpened,
    closePaymentInstructionModal,
    reportToConfirmPayment,
    handleOpenPaymentInstructionModal,
    isConfirmingPayment,
  } = usePaymentInstructionModal();

  const {
    reportToSubmit,
    openSubmitBOPModal,
    isSubmitBOPModalOpened,
    closeSubmitBOPModal,
    submitReport,
  } = useSubmitBOPModal('SellerDashboard', async () => {
    const [ongoingRes, completedRes] = await Promise.all([
      getOngoingFiling(activeCompany?.company_id),
      getCompletedFiling(activeCompany?.company_id),
    ]);

    queryClient.setQueryData(['SellerOngoingFiling', queryKey], ongoingRes);
    queryClient.setQueryData(
      ['SellerPastPeriodsFiling', queryKey],
      completedRes
    );
  });

  const [
    reports,
    manualUploadMailingStopped,
    ongoingFiling,
    completedFiling,
    easybill,
    yearPayments,
    billbee,
    jtlCredentials,
  ] = useQueries({
    queries: [
      {
        queryKey: ['SellerReports', queryKey],
        queryFn: () => getClientReports(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerStopManualUploadMailing', queryKey],
        queryFn: () =>
          getStopMailing('manual-upload-needed', activeCompany?.company_id),
      },
      {
        queryKey: ['SellerOngoingFiling', queryKey],
        queryFn: () => getOngoingFiling(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerPastPeriodsFiling', queryKey],
        queryFn: () => getCompletedFiling(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerEasybill', queryKey],
        queryFn: () => getEasybillTokenStatus(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerYearPayments', queryKey],
        queryFn: () => getYearPayments(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerBillbee', queryKey],
        queryFn: () => getBillbee(activeCompany?.company_id),
      },
      {
        queryKey: ['SellerJtlCredentials', queryKey],
        queryFn: () => getJTLCredentials(activeCompany?.company_id),
      },
    ],
  });

  const {
    isLoading: isLoadingIntegrations,
    integrations,
    refetchIntegrations,
  } = useSellerIntegrations();

  const { isLoadingUploadedFiles, isLoadingDataUnits, dataUnits } = useMU();

  const ppData = usePP();

  const isLoadingPP = ppData.isLoadingPP;

  const countPP = vs5438CpTwoFilesForPpFownloading
    ? ppData.countLastMonthPP
    : ppData.countAllPeriodsPP;

  useEffect(() => {
    if (
      state &&
      state.includes(STATE_CONSTANT) &&
      spapi_oauth_code &&
      selling_partner_id
    ) {
      const [user_id, company_id] = state
        .replace(STATE_CONSTANT, '')
        .split('.');
      if (
        companies.find((c) => c.company_id === company_id) &&
        user_id === user?.Id
      ) {
        setIsConnectingAmazon(true);
        postClientAmazonConnect({
          seller_id: selling_partner_id,
          oauth_code: spapi_oauth_code,
          company_id,
        })
          .then(() => {
            showNotification({
              text: t('Merchant token *seller_id* connected').replace(
                '*seller_id*',
                selling_partner_id
              ),
              type: 'success',
            });
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setIsConnectingAmazon(false);
            refetchIntegrations();
            navigate('/', { replace: true });
          });
      }
    }

    // eslint-disable-next-line
  }, [state, spapi_oauth_code, selling_partner_id]);

  const ongoingFilingData = ongoingFiling.data;
  const completedFilingData = completedFiling.data;

  const reportsToConfirmPayment: NormalizedSellerFilingReport[] = useMemo(
    () =>
      [...(ongoingFilingData || []), ...(completedFilingData || [])].filter(
        (r) =>
          !!r.payment_instruction &&
          !r.payment_instruction.confirmed &&
          !r.payment_instruction.is_sepa &&
          !r.payment_instruction.is_null_report &&
          r.payment_instruction.amount.amount > 0 &&
          (!vs5151DisregardedInstruction || !r.payment_instruction.cancelled)
      ),
    [ongoingFilingData, completedFilingData, vs5151DisregardedInstruction]
  );

  const reportsToConfirmAgenda: NormalizedSellerFilingReport[] = useMemo(
    () =>
      [...(ongoingFilingData || []), ...(completedFilingData || [])].filter(
        (r) =>
          !!r.payment_instruction &&
          r.country.short_name === 'DE' &&
          !r.agenda_confirmed &&
          r.type === 'local' &&
          (!vs5151DisregardedInstruction || !r.payment_instruction.cancelled)
      ),
    [ongoingFilingData, completedFilingData, vs5151DisregardedInstruction]
  );

  const isOSSSelfService = useAppSelector(isOSSSelfServiceSelector);

  const currentOSSReport = useMemo(() => {
    return getCurrentOSSSeSeReport([
      ...(ongoingFilingData || []),
      ...(completedFilingData || []),
    ]);
  }, [ongoingFilingData, completedFilingData]);

  const isMUNeeded = dataUnits?.some((du) =>
    du.items.some((unit) => unit.source_type === 'manual' && unit.status === 0)
  );

  const hasOngoingTasks = useMemo(
    () =>
      isMUNeeded ||
      !!countPP ||
      [
        ...(reportsToConfirmPayment || []),
        ...(reportsToConfirmAgenda || []),
      ].filter(
        (r) =>
          differenceInCalendarMonths(
            getUTCDate(),
            normalizeDateFE(r.payment_instruction?.due_date || r.deadline_date)
          ) <= 0
      ).length > 0,
    [countPP, isMUNeeded, reportsToConfirmAgenda, reportsToConfirmPayment]
  );

  const OSSReports: NormalizedSellerFilingReport[] = useMemo(
    () =>
      [...(ongoingFilingData || []), ...(completedFilingData || [])].filter(
        (r) => r.type === 'oss' && !r.payment_instruction?.cancelled
      ),
    [ongoingFilingData, completedFilingData]
  );

  const isUseNewProcess = activeCompany?.IsUseNewProcess;
  const dataUnitsForCorrections = useMemo(() => {
    const units = dataUnits || [];
    const itemsIds = units
      .filter(
        (du) =>
          !!du.items.length &&
          differenceInCalendarMonths(new Date(), normalizeDateFE(du.date)) <= 1
      )
      .map((du) => du.items)
      .flat()
      .filter((item) =>
        item.files.some(
          (f) =>
            f.documents_with_error_number > 0 &&
            (isUseNewProcess || !!f.errors_message)
        )
      )
      .map((item) => item.id);

    return units.filter(
      (du) =>
        !!du.items.length && du.items.some((item) => itemsIds.includes(item.id))
    );
  }, [dataUnits, isUseNewProcess]);

  if (isConnectingAmazon) return <Loader />;

  return (
    <PageLayout
      top={
        <>
          {vs4972NewYear2024 && (
            <NewYearIcon className='absolute right-14 top-0 h-20 w-20' />
          )}
          <div className='Text--30 mb-4 font-black'>{t('Global overview')}</div>
        </>
      }
      data-testid='seller-dashboard'
      className='flex-grow'
      rightColumn={
        <div
          style={{
            maxWidth: 328,
            paddingTop: hasOngoingTasks ? 32 : undefined,
          }}
          className='space-y-4 lg:ml-auto'
        >
          <MerryChristmasBanner />
          <FeedbackWidget testId='FeedbackWidget' />
          <div className='rounded-lg bg-white p-4'>
            <div className='flex items-center'>
              {user && (
                <Avatar
                  className='Avatar--SellerDashboard flex-shrink-0 text-sm'
                  data={user}
                  backgroundColor={getColor(user?.Name)}
                  testId={`participant-${user?.Id}`}
                />
              )}
              <div className='ml-2 truncate'>
                <div className='truncate font-semibold'>
                  {user?.Name} {user?.FamilyName}
                </div>
                <div className='truncate text-sm text-gray-700'>
                  {user?.Email}
                </div>
              </div>
            </div>
          </div>
          {reports.isLoading ? (
            <Shimmer className='SellerDashboard__loading--integrations' />
          ) : (
            <SellerCalendarCollapse reports={reports.data || []} />
          )}
          {isLoadingIntegrations ||
          easybill.isLoading ||
          billbee.isLoading ||
          jtlCredentials.isLoading ? (
            <Shimmer className='SellerDashboard__loading--integrations' />
          ) : (
            <div className='ml-auto rounded-lg bg-white pb-4 pt-2'>
              {(integrations ||
                activeCompany?.EasybillClient ||
                billbee.data ||
                jtlCredentials.data) && (
                <Integrations
                  integrations={integrations}
                  easybillData={easybill.data}
                  billbeeData={billbee.data}
                  jtlCredentials={jtlCredentials.data}
                />
              )}
            </div>
          )}
        </div>
      }
    >
      <div>
        {isLoadingPP ||
        isLoadingUploadedFiles ||
        isLoadingDataUnits ||
        ongoingFiling.isLoading ||
        completedFiling.isLoading ||
        manualUploadMailingStopped.isLoading ? (
          <div>
            <Shimmer className='SellerDashboard__loading mb-6' />
            <Shimmer className='SellerDashboard__loading mb-6' />
          </div>
        ) : (
          <>
            <OpenTasks
              dataUnitsForCorrections={dataUnitsForCorrections}
              OSSReports={OSSReports}
              isOSSSelfService={isOSSSelfService || false}
              isMUNeeded={isMUNeeded}
              purchasePricesNeeded={typeof countPP === 'number' && countPP > 0}
              reportsToConfirmPayment={reportsToConfirmPayment}
              reportsToConfirmAgenda={reportsToConfirmAgenda}
              handleOpenPaymentInstructionModal={
                handleOpenPaymentInstructionModal
              }
              openSubmitBOPModal={openSubmitBOPModal}
            >
              <>
                {isOSSSelfService &&
                  (ongoingFiling.isLoading ? (
                    <div>
                      <Shimmer className='SellerDashboard__loading mb-6' />
                    </div>
                  ) : (
                    currentOSSReport && (
                      <OSSSeSeReportPreview
                        report={currentOSSReport}
                        link={{ to: AuthedRoutesEnum.OSSFiles }}
                      />
                    )
                  ))}
              </>
            </OpenTasks>
          </>
        )}
        {yearPayments.isLoading ||
        ongoingFiling.isLoading ||
        completedFiling.isLoading ? (
          <Shimmer className='SellerDashboard__loading--chart mb-6' />
        ) : (
          <SellerChart
            payments={
              yearPayments.data?.filter((a) => a.Amounts[0].amount > 0) || []
            }
            filingData={[
              ...(ongoingFiling.data || []),
              ...(completedFiling.data || []),
            ].filter((f) => !!f.payment_instruction)}
          />
        )}
      </div>
      <PaymentInstructionModal
        report={reportToConfirmPayment}
        isModalOpened={isPaymentInstructionModalOpened}
        closeModal={closePaymentInstructionModal}
        onClickConfirm={onClickConfirmPayment(reportToConfirmPayment)}
        isConfirming={isConfirmingPayment}
        isReportHasDisregardedInstruction={
          reportToConfirmPayment &&
          [
            ...new Set(
              [...(ongoingFilingData || []), ...(completedFilingData || [])]
                .filter((r) => r.payment_instruction?.cancelled)
                .map((r) => r.id)
            ),
          ].includes(reportToConfirmPayment.id)
        }
      />
      <SubmitBOPModal
        isModalOpened={isSubmitBOPModalOpened}
        closeModal={closeSubmitBOPModal}
        report={reportToSubmit}
        onSubmit={submitReport}
      />
    </PageLayout>
  );
}

export interface ChartItem {
  name: string;
  value: number;
  amounts?: {
    [key: string]: number;
  };
  originalCurrency?: string;
}
