import { InvoiceDocument } from '@/core/types';
import ArrowRightIcon from '@/icons/arrow-right.svg?react';
import {
  EditableInvoiceDocumentValuesMapping,
  editableInvoiceDocumentValuesSign,
} from '@/pages/Seller/SellerDocuments/SellerDocuments.helpers';
import { useTranslation } from 'react-i18next';

interface Props {
  suggestedValue: [keyof InvoiceDocument, any];
  document: InvoiceDocument;
}

export function SuggestedValueItem({ suggestedValue, document }: Props) {
  const { t } = useTranslation();
  const nameValue = suggestedValue[0];
  const value = suggestedValue[1];

  const renderValue = (value: any) => {
    if (value === undefined || value === null) return '';
    if (Array.isArray(value)) return value.join(', ');
    if (typeof value === 'object') return JSON.stringify(value);
    return value.toString();
  };

  return (
    <div className='flex items-center justify-between font-semibold'>
      <div className='flex-grow'>
        {t(EditableInvoiceDocumentValuesMapping[nameValue] || '')}
      </div>
      <div className='flex items-center justify-end gap-x-5'>
        <div className='flex items-center gap-x-2'>
          <div className='min-h-[24px] min-w-[140px] flex-grow rounded-lg bg-gray-70 px-3 py-2'>
            {renderValue(document[nameValue])}
          </div>
          <span className='min-w-[12px] text-gray-700'>
            {document.currency &&
              editableInvoiceDocumentValuesSign(nameValue, document.currency)}
          </span>
        </div>
        <ArrowRightIcon />
        <div className='flex items-center gap-x-2'>
          <div className='min-h-[40px] min-w-[140px] flex-grow rounded-lg bg-gray-70 px-3 py-2'>
            {value}
          </div>
          <span className='min-w-[12px] text-gray-700'>
            {document.currency &&
              editableInvoiceDocumentValuesSign(nameValue, document.currency)}
          </span>
        </div>
      </div>
    </div>
  );
}
