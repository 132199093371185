import { ProformaInvoice } from '@/core/types';
import { ProformaInvoiceTileItem } from './ProformaInvoiceTileItem';
import CountryLabel from '@/components/CountryLabel/CountryLabel';

interface Props {
  invoices: ProformaInvoice[];
}

export const ProformaInvoiceTile = ({ invoices }: Props) => {
  const departure = invoices[0].departure_country;
  const arrival = invoices[0].arrival_country;
  return (
    <div>
      <div className='flex gap-x-2 border-b bg-gray-70 px-4 py-2 font-semibold'>
        <CountryLabel country={departure.short_name} /> ⟶{' '}
        <CountryLabel country={arrival.short_name} />
      </div>
      <div className='divide-y'>
        {invoices.map((invoice) => (
          <ProformaInvoiceTileItem invoice={invoice} key={invoice.id} />
        ))}
      </div>
    </div>
  );
};
