import {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import TooltipContent, { Position } from './TooltipContent';
import { getBoundingAbsoluteRect } from '@/core/helpers';

interface Props {
  content: JSX.Element | string | null;
  children: ReactElement;
  wrapperClassName?: string;
  className?: string;
  initialPosition?: Position;
  dataTestId?: string;
  styling?: 'black' | 'white' | 'transparent';
  autoPlacement?: boolean;
  isOpened: boolean;
}

export default function Hint({
  content,
  children,
  wrapperClassName = '',
  className = '',
  initialPosition = 'bottom',
  dataTestId,
  styling,
  autoPlacement = true,
  isOpened,
}: Props) {
  const [currentPosition, setCurrentPosition] =
    useState<Position>(initialPosition);

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const ref = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const viewportHeightRef = useRef(
    window.innerHeight || document.documentElement.clientHeight
  );
  const viewportWidthRef = useRef(
    window.innerWidth || document.documentElement.clientWidth
  );

  const setCoords = (p: Position) => {
    if (!ref.current) return;

    const { height, width } = getBoundingAbsoluteRect(ref.current);

    if (p === 'bottom') {
      setY(height + 4);
      setX(width / 2);
    }

    if (p === 'top') {
      setY(-4);
      setX(width / 2);
    }

    if (p === 'left') {
      setY(height / 2);
      setX(-4);
    }

    if (p === 'right') {
      setY(height / 2);
      setX(width + 4);
    }

    setCurrentPosition(p);
  };

  useLayoutEffect(() => {
    if (isOpened && contentRef.current && autoPlacement) {
      const rect = contentRef.current.getBoundingClientRect();
      if (
        initialPosition === 'bottom' &&
        rect.bottom > viewportHeightRef.current
      ) {
        setCoords('top');
      } else if (initialPosition === 'top' && rect.top < 0) {
        setCoords('bottom');
      } else if (initialPosition === 'left' && rect.left < 0) {
        setCoords('right');
      } else if (
        initialPosition === 'right' &&
        rect.right > viewportWidthRef.current
      ) {
        setCoords('left');
      }
    }
    // eslint-disable-next-line
  }, [isOpened]);

  useEffect(() => {
    const handleResize = () => {
      viewportHeightRef.current =
        window.innerHeight || document.documentElement.clientHeight;
      viewportWidthRef.current =
        window.innerWidth || document.documentElement.clientWidth;

      setCoords(initialPosition);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpened, initialPosition]);

  return (
    <div
      className={`Tooltip relative inline-block ${wrapperClassName}`}
      ref={ref}
      data-testid={dataTestId}
    >
      {children}
      <TooltipContent
        portal={false}
        isOpened={isOpened}
        content={content}
        position={currentPosition}
        x={x}
        y={y}
        className={className}
        styling={styling}
        ref={contentRef}
      />
    </div>
  );
}
