import './ProfileDropdown.scss';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  setAmazonDataImportStatus,
  setHasNewAmazonCredentials,
} from '@/core/store/amazon/amazonSlice';
import { clearUserState, userSelector } from '@/core/store/user/userSlice';
import HoverDropdown from '../HoverDropdown/HoverDropdown';
import { removeTokens } from '@/core/helpers';
import Button from '../Button';
import { getColor } from '../Participants/Participants.helpers';
import { usePaymentOverdueModal } from '../PaymentInstructionModal/usePaymentOverdueModal';

interface Props {
  className?: string;
}

const ProfileDropdown = ({ className = '' }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(userSelector);
  const { closeModal } = usePaymentOverdueModal();

  const onLogoutClick = () => {
    dispatch(clearUserState());
    dispatch(setAmazonDataImportStatus(undefined));
    dispatch(setHasNewAmazonCredentials(false));
    closeModal();
    removeTokens();
    navigate('/');
  };

  if (!user) return null;

  return (
    <HoverDropdown
      className='HoverDropdown--ProfileDropdown'
      arrow={false}
      trigger={
        <div
          className={`ProfileDropdown__trigger flex flex-shrink-0 items-center justify-center rounded-full px-2 uppercase 2xl:px-4 ${className}`}
          data-testid='profile-dropdown'
          style={{
            backgroundColor: getColor(user.Name),
          }}
        >
          {user.Name[0]}
          {user.FamilyName[0]}
        </div>
      }
    >
      <div className='py-2'>
        <Button
          styling='naked'
          className='ProfileDropdown__dropdownItem !w-full whitespace-nowrap text-center no-underline'
          onClick={onLogoutClick}
          tabIndex={-1}
        >
          {t('Log Out')}
        </Button>
      </div>
    </HoverDropdown>
  );
};

export default ProfileDropdown;
