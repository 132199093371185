import './Integrations.scss';
import BillbeeIcon from '@/icons/billbee.svg';
import {
  AmazonIntegration,
  BillbeeData,
  EasybillData,
  JTLCredentials,
} from '@/core/types';
import { useModal } from '../Modal/useModal';
import { useTranslation } from 'react-i18next';
import IntegrationsIcon from '@/icons/integrations.svg?react';
import EasybillIcon from '@/icons/easybill.svg';
import { useMemo, useState } from 'react';
import AmazonTokenModal from '../AmazonTokenModal/AmazonTokenModal';
import EasybillTokenModal from '../EasybillTokenModal/EasybillTokenModal';
import BillbeeTokenModal from '../BillbeeTokenModal/BillbeeTokenModal';
import { APIEventsEnum, useAppSelector, useSendEvent } from '../../hooks';
import { userSelector } from '@/core/store/user/userSlice';
import { RolesEnum } from '@/core/enums';
import { AmazonIntegrationWidget } from './AmazonIntegrationWidget/AmazonIntegrationWidget';
import { IntegrationWidget } from './IntegrationWidget';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ConnectJTLModal } from '../ConnectJTLModal/ConnectJTLModal';
import { JTLWidget } from './JTLWidget';
import { useLocation } from 'react-router-dom';

interface Props {
  integrations: AmazonIntegration[] | undefined;
  easybillData?: EasybillData;
  isBillbeeConnected?: boolean;
  jtlCredentials?: JTLCredentials;
  billbeeData?: BillbeeData;
}

export default function Integrations({
  integrations,
  easybillData,
  billbeeData,
  jtlCredentials,
}: Props) {
  const { vs5739JtlWidget } = useFlags();
  const locaton = useLocation();
  const user = useAppSelector(userSelector);

  const { t } = useTranslation();

  const { sendEvent } = useSendEvent();

  const [activeSellerId, setActiveSellerId] = useState<string>();

  const isBillbeeConnected = billbeeData?.is_active;

  const {
    isModalOpened: isAmazonTokenModalOpened,
    closeModal: onCloseAmazonTokenModal,
    openModal: openAmazonTokenModal,
  } = useModal();

  const {
    isModalOpened: isEasybillTokenModalOpened,
    closeModal: closeEasybillTokenModal,
    openModal: openEasybillTokenModal,
  } = useModal();

  const {
    isModalOpened: isBillbeeTokenModalOpened,
    closeModal: closeBillbeeTokenModal,
    openModal: openBillbeeTokenModal,
  } = useModal();

  const {
    isModalOpened: isConnectJTLModalOpened,
    closeModal: closeConnectJTLModal,
    openModal: openConnectJTLModal,
  } = useModal();

  const closeAmazonTokenModal = () => {
    activeSellerId && setActiveSellerId(undefined);
    onCloseAmazonTokenModal();
  };

  const isEasybillConnected =
    easybillData && (easybillData.is_valid || easybillData.invalid_date);

  const onClickUpdateAmazonItem = (id: string | undefined) => () => {
    setActiveSellerId(id);
    sendEvent(APIEventsEnum.click_connect_amazon, {
      block: 'Click on Amazon connect button',
    });
    openAmazonTokenModal();
  };

  const hasJTLCreds = !!jtlCredentials;

  const widgets = useMemo(
    () => [
      ...(vs5739JtlWidget
        ? [
            {
              name: 'jtl',
              isConnected: hasJTLCreds,
              component: (
                <JTLWidget
                  jtlCredentials={jtlCredentials}
                  onClickConnect={openConnectJTLModal}
                  prevRoute={locaton.pathname}
                />
              ),
            },
          ]
        : []),
      {
        name: 'amazon',
        isConnected: (integrations || []).some(
          (integration) => !integration.invalid
        ),
        component: (
          <AmazonIntegrationWidget
            integrations={integrations}
            onClickUpdate={onClickUpdateAmazonItem}
          />
        ),
      },
      ...(user?.Role === RolesEnum.SELLER
        ? [
            {
              name: 'easybill',
              isConnected: isEasybillConnected,
              component: easybillData ? (
                <IntegrationWidget
                  name='easybill'
                  iconSrc={EasybillIcon}
                  data={easybillData}
                  onClickConnect={() => {
                    sendEvent(APIEventsEnum.click_connect_easybill, {
                      block: 'Click on easybill connect button',
                    });
                    openEasybillTokenModal();
                  }}
                  label='Easybill'
                />
              ) : null,
            },
            {
              name: 'billbee',
              isConnected: isBillbeeConnected,
              component: billbeeData ? (
                <IntegrationWidget
                  name='billbee'
                  iconSrc={BillbeeIcon}
                  data={billbeeData}
                  onClickConnect={() => {
                    sendEvent(APIEventsEnum.click_connect_billbee, {
                      block: 'Click on Billbee connect button',
                    });
                    openBillbeeTokenModal();
                  }}
                  label='Billbee'
                />
              ) : null,
            },
          ]
        : []),
    ],
    // eslint-disable-next-line
    [
      integrations,
      vs5739JtlWidget,
      hasJTLCreds,
      easybillData,
      isBillbeeConnected,
    ]
  );

  return (
    <>
      <div className='Integrations'>
        <div
          className='mb-2 flex items-center px-4 text-h4 font-bold'
          data-testid='integrations-title'
        >
          <IntegrationsIcon className='mr-2' />
          <span>{t('Integrations')}</span>
        </div>
        {widgets.map((w) => (
          <div
            className='border-b border-gray-500 px-4 py-2 last-of-type:border-none'
            key={w.name}
          >
            {w.component}
          </div>
        ))}
      </div>
      <div className='mx-4 mt-2 rounded-lg pb-2 leading-5 text-gray-700'>
        {t(
          'Data is imported on the 5th of each month. Any changes or new invoices made after the month-end will not be included in our export or filings'
        )}
      </div>
      <AmazonTokenModal
        closeModal={closeAmazonTokenModal}
        isModalOpened={isAmazonTokenModalOpened}
        sellerId={activeSellerId}
      />
      <EasybillTokenModal
        closeModal={closeEasybillTokenModal}
        isModalOpened={isEasybillTokenModalOpened}
        initialIsTokenValid={isEasybillConnected ? easybillData.is_valid : true}
      />
      <BillbeeTokenModal
        closeModal={closeBillbeeTokenModal}
        isModalOpened={isBillbeeTokenModalOpened}
        isTokenValid={billbeeData?.is_valid || false}
      />
      <ConnectJTLModal
        closeModal={closeConnectJTLModal}
        isModalOpened={isConnectJTLModalOpened}
        jtlCredentials={jtlCredentials}
        prevRoute={location.pathname}
      />
    </>
  );
}
