import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import {
  formatDate,
  formatNumber,
  getAbbrValue,
  getBoundingAbsoluteRect,
  getRandomColor,
} from '@/core/helpers';
import './SellerChart.scss';
import { normalizeDateFE } from '@/core/normalization';
import {
  Payment,
  PaymentInstruction,
  NormalizedSellerFilingReport,
} from '@/core/types';
import EmptyChartImage from '@/images/empty-chart.svg';
import { isSameMonth, isSameYear, startOfMonth, subMonths } from 'date-fns';
import classNames from 'classnames';

interface IAmount {
  amount: number;
  currency: string;
}

interface NormalizedAmount {
  amount: number;
  eur: number;
}

interface Props {
  payments: Payment[];
  filingData: NormalizedSellerFilingReport[];
}

interface TooltipData {
  country: string;
  total: number;
  instructions: PaymentInstruction[];
}

export default function SellerChart({ payments, filingData }: Props) {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const tooltipArrowRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [tooltipData, setTooltipData] = useState<TooltipData>();
  const { t, i18n } = useTranslation();

  useLayoutEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      if (tooltipRef.current && chartContainerRef.current) {
        setTooltipY(tooltipRef.current, chartContainerRef.current, e.pageY);
      }
      if (tooltipArrowRef.current) {
        tooltipArrowRef.current.style.top = String(e.pageY) + 'px';
      }
    };

    document.addEventListener('mousemove', onMouseMove);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  const getCountryLabel = (c: string) => {
    return c === 'OSS' ? 'OSS' : t(c);
  };
  const lang = i18n.language;

  const keys = useMemo(
    () =>
      payments
        .map((d) => (d.type === 'local' ? d.Country.short_name : 'OSS'))
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((c) => ({ id: c }))
        .sort((a, b) => {
          if (a.id === 'OSS') {
            return -1;
          }
          return t(a.id).localeCompare(t(b.id), lang);
        }),
    [t, lang, payments]
  );

  const normalizedChartData = useMemo(
    () =>
      Array(12)
        .fill(startOfMonth(new Date()))
        .map((d, i) => subMonths(d, i))
        .reverse()
        .map((date) => {
          const curPayments = payments.filter((d) => {
            const dueDate = normalizeDateFE(d.due_date);
            return isSameMonth(date, dueDate) && isSameYear(date, dueDate);
          });

          return {
            date,
            instructions: filingData
              .filter((f) =>
                curPayments.some(
                  (p) =>
                    f.payment_instruction &&
                    p.payment_instruction_id === f.payment_instruction.id
                )
              )
              .map((f) => ({
                country: f.type === 'oss' ? 'OSS' : f.country.short_name,
                instruction: { ...f.payment_instruction!, period: f.period },
              }))
              .reduce(
                (
                  acc: {
                    [key: string]: PaymentInstruction[];
                  },
                  item
                ) => {
                  acc[item.country] = [
                    ...(acc[item.country] || []),
                    item.instruction,
                  ];

                  return acc;
                },
                {}
              ),
            ...curPayments.reduce(
              (
                acc: {
                  [key: string]: number;
                },
                item
              ) => {
                const eurAmount = item.Amounts.find(
                  (a: IAmount) => a.currency === 'EUR'
                );

                acc[item.type === 'local' ? item.Country.short_name : 'OSS'] =
                  (eurAmount?.amount || 0) / 100;

                return acc;
              },
              {}
            ),
          };
        }),
    [payments, filingData]
  );

  const paidTotal = useMemo(() => {
    const currencies = payments
      .map((a) => ({
        original_currency: a.original_currency,
        Amounts: a.Amounts.reduce(
          (
            acc: {
              [key: string]: number;
            },
            i: IAmount
          ) => {
            acc[i.currency] = (i.amount || 0) / 100;
            return acc;
          },
          {}
        ),
      }))
      .reduce(
        (
          acc: {
            [key: string]: NormalizedAmount;
          },
          a
        ) => {
          acc[a.original_currency] = {
            amount:
              (acc[a.original_currency]?.amount || 0) +
              a.Amounts[a.original_currency],
            eur: (acc[a.original_currency]?.eur || 0) + a.Amounts['EUR'],
          };
          return acc;
        },
        {}
      );

    return {
      ...currencies,
      EUR: {
        amount: payments
          .filter((item) => item.original_currency === 'EUR')
          .map((a) => a.Amounts[0])
          .reduce((acc, a) => {
            return (acc += (a.amount || 0) / 100);
          }, 0),
      },
    };
  }, [payments]);

  const pallete = useMemo(
    () =>
      keys.reduce(
        (
          acc: {
            [key: string]: string;
          },
          k
        ) => {
          acc[k.id] = palleteMap[k.id] || getRandomColor();
          return acc;
        },
        {}
      ),
    [keys]
  );

  if (!payments.length) {
    return (
      <div className='mb-4 rounded-lg bg-white px-6 py-4'>
        <div className='mb-1 text-center font-semibold'>
          {t("You don't have any VAT payments in 12 months.")}
        </div>
        <div className='mb-4 text-center'>
          {t('Once you do any, you will see the distribution by country.')}
        </div>
        <div className='flex justify-center'>
          <img src={EmptyChartImage} alt='EmptyChartImage' />
        </div>
      </div>
    );
  }

  return (
    <div className='mb-4 rounded-lg bg-white px-6 py-4'>
      <div className='mb-4 flex'>
        <div className='mr-4 font-semibold' data-testid='chart-title'>
          {t('VAT paid in 12 months')}
        </div>
        <div className='flex flex-wrap'>
          {Object.entries(paidTotal)
            .sort((a, b) => {
              if (a[0] === 'EUR') {
                return -1;
              }
              return a[0].localeCompare(b[0], i18n.language);
            })
            .map(([k, v], i) => (
              <div
                key={k}
                className='SetupCheck__FCTransferCountryCol mr-2'
                data-testid={`chart-total-${i}`}
              >
                {'eur' in v
                  ? `${formatNumber(
                      v.amount,
                      {
                        currency: k,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                      undefined,
                      true
                    )} (${formatNumber(
                      (v as NormalizedAmount).eur,
                      {
                        currency: 'EUR',
                      },
                      undefined,
                      true
                    )})`
                  : formatNumber(
                      v.amount,
                      {
                        currency: k,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                      undefined,
                      true
                    )}
              </div>
            ))}
        </div>
      </div>

      <div
        className='w-full'
        style={{
          height: 300,
        }}
        ref={chartContainerRef}
      >
        {import.meta.env.MODE !== 'test' && (
          <ResponsiveContainer debounce={250}>
            <BarChart width={500} height={300} data={normalizedChartData}>
              <CartesianGrid
                vertical={false}
                strokeWidth={1}
                stroke='#DDE2E5'
              />
              <XAxis
                dataKey='date'
                tick={{
                  fill: '#264F3F',
                  fontSize: 11,
                }}
                stroke='#DDE2E5'
                strokeWidth={1}
                tickFormatter={(date: Date) => formatDate(date, 'MMM')}
              />
              <YAxis
                tickFormatter={(value: number) => getAbbrValue(value)}
                axisLine={false}
                tickLine={false}
                tick={({ y, payload }) => {
                  return (
                    <g transform={`translate(${0},${y})`}>
                      <text
                        x={0}
                        y={0}
                        textAnchor='start'
                        fill='#929F9A'
                        fontSize={11}
                      >
                        {getAbbrValue(payload.value)}
                      </text>
                    </g>
                  );
                }}
              />
              <Legend
                iconType='circle'
                iconSize={8}
                verticalAlign='bottom'
                align='left'
                wrapperStyle={{
                  left: 0,
                  paddingBottom: 12,
                }}
                content={(props) => {
                  const { payload } = props;
                  return (
                    <div className='flex w-full flex-wrap gap-x-4'>
                      {payload
                        ?.sort((a, b) => {
                          if (a.value === 'OSS') {
                            return -1;
                          }
                          return t(a.value).localeCompare(t(b.value), lang);
                        })
                        .map((entry, i) => {
                          return (
                            <div key={`item-${(entry as any).dataKey}`}>
                              <div className='flex select-none items-center'>
                                <div
                                  className='SellerChart__circle mr-1 rounded-full'
                                  style={{
                                    backgroundColor: entry.color,
                                  }}
                                />
                                <span data-testid={`chart-legend-${i}`}>
                                  {getCountryLabel(entry.value)}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                }}
              />
              {keys.map((k) => {
                const color = pallete[k.id];
                return (
                  <Bar
                    className='cursor-pointer'
                    dataKey={k.id}
                    stackId='a'
                    key={k.id}
                    fill={color}
                  >
                    {normalizedChartData.map((d, j) => (
                      <Cell
                        key={j}
                        fill={color}
                        onMouseEnter={(e) => {
                          if (
                            chartContainerRef.current &&
                            tooltipRef.current &&
                            tooltipArrowRef.current
                          ) {
                            const x =
                              +(e.target as SVGPathElement).getAttribute('x')! +
                              +(e.target as SVGPathElement).getAttribute(
                                'width'
                              )!;

                            const containerCoords = getBoundingAbsoluteRect(
                              chartContainerRef.current
                            );
                            tooltipRef.current.style.left =
                              String(x + containerCoords.x + 16) + 'px';
                            tooltipArrowRef.current.style.left =
                              String(x + containerCoords.x + 4) + 'px';
                          }

                          setTooltipData({
                            country: k.id,
                            instructions: d.instructions[k.id] || [],
                            total: (
                              d as unknown as {
                                [key: string]: number;
                              }
                            )[k.id],
                          });
                        }}
                        onMouseLeave={() => {
                          setTooltipData(undefined);
                        }}
                      />
                    ))}
                  </Bar>
                );
              })}
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
      <div
        ref={tooltipRef}
        className={classNames(
          'SellerChart__details absolute bg-white px-2 pt-2',
          {
            hidden: !tooltipData,
          }
        )}
      >
        {tooltipData && (
          <>
            <div className='mb-2 font-semibold'>
              {getCountryLabel(tooltipData.country)}
            </div>
            {!!tooltipData.instructions.length ? (
              tooltipData.instructions.map((instruction, i) => (
                <div className='pb-2' key={i}>
                  <div className='mb-1 flex items-center justify-between border-t border-gray-500'>
                    <div className='mr-6'>{t('Payment period')}</div>
                    <div>
                      {formatDate(
                        normalizeDateFE(instruction.period.from_date),
                        getDateFormat(instruction.period.type)
                      )}
                    </div>
                  </div>
                  <div className='mb-1 flex items-center justify-between border-t border-gray-500'>
                    <div className='mr-4'>{t('VAT paid')}</div>
                    <div>
                      {formatNumber(
                        instruction.amount.amount / 100,
                        {
                          currency: instruction.amount.currency,
                        },
                        undefined,
                        true
                      )}
                    </div>
                  </div>
                  <div className='mb-1 flex items-center justify-between border-t border-gray-500'>
                    <div className='mr-4'>{t('Due date')}</div>
                    <div>
                      {formatDate(
                        normalizeDateFE(instruction.due_date),
                        'dd MMM yyyy'
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className='pb-2'>
                <div className='mb-1 flex items-center justify-between border-t border-gray-500'>
                  <div className='mr-4'>{t('VAT paid')}</div>
                  <div>
                    {formatNumber(
                      tooltipData.total,
                      {
                        currency: 'eur',
                      },
                      undefined,
                      true
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div
        ref={tooltipArrowRef}
        className={classNames('SellerChart__detailsArrow', {
          hidden: !tooltipData,
        })}
      />
    </div>
  );
}

const getDateFormat = (type: 'month' | 'year' | 'quarter') => {
  switch (type) {
    case 'quarter':
      return 'QQQ yyyy';
    case 'year':
      return 'yyyy';
    default:
      return 'MMM yyyy';
  }
};

const palleteMap: {
  [key: string]: string;
} = {
  OSS: '#1C3FFD',
  AT: '#9B94B3',
  BE: '#D0523F',
  CZ: '#70E7D4',
  DK: '#1BBC9B',
  FR: '#B3CEDE',
  DE: '#362B40',
  IT: '#DD7842',
  LU: '#781C4F',
  NL: '#FFF176',
  PL: '#E9E08F',
  SI: '#BC8457',
  ES: '#3E7942',
  SE: '#722BF1',
  GB: '#F2BFE1',
};

const setTooltipY = (
  el: HTMLDivElement,
  container: HTMLDivElement,
  pageY: number
) => {
  const containerCoords = getBoundingAbsoluteRect(container);

  if (containerCoords.bottom < pageY + el.offsetHeight / 2) {
    return (el.style.top =
      String(pageY - el.offsetHeight + (containerCoords.bottom - pageY)) +
      'px');
  } else {
    el.style.top = `${pageY - el.offsetHeight / 2}px`;
  }
};
