import { FilingReportConfirmation, ReportConfirmationFile } from '@/core/types';
import React from 'react';
import Comment from '../Comment';
import SellerFilingFile from '../SellerFilingFile';

interface Props {
  confirmation: FilingReportConfirmation;
  onDowloadFile: (
    file: ReportConfirmationFile
  ) => (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => () => void;
}

export const SellerFilingReportConfirmation = ({
  confirmation,
  onDowloadFile,
}: Props) => {
  return (
    <div className='space-y-4 pt-2'>
      {confirmation.comment && <Comment comment={confirmation.comment} />}
      {confirmation.documents.map((f) => (
        <SellerFilingFile
          key={f.id}
          filename={f.filename}
          onDownload={onDowloadFile(f)}
        />
      ))}
    </div>
  );
};
