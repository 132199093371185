import { CountryThreshold } from '@/core/types';
import { useAppSelector } from '../../hooks';
import SellerDashboard from './SellerDashboard';
import { AuthedRoutesEnum } from '@/core/enums';
import SellerTurnover from './SellerTurnover';
import { IntegrationsProvider } from '@/components/Integrations/integrations-context';
import SellerLayout from './SellerLayout';
import SellerVATIds from './SellerVATIds/SellerVATIds';
import SellerSetupCheck from './SellerSetupCheck';
import { useQuery } from '@tanstack/react-query';
import { getClientThresholdByCountries } from '@/core/api';
import { useMemo } from 'react';
import { Loader } from '@/components/Loader/Loader';
import Unsubscribe from './Unsubscribe/Unsubscribe';
import SellerFiling from './SellerFiling/SellerFiling';
import PurchasePrices from '../PurchasePrices/PurchasePrices';
import { PPProvider } from '../../context/pp-context';
import { MUProvider } from '../../context/mu-context';
import {
  activeCompanySelector,
  isOSSSelfServiceSelector,
  queryKeySelector,
} from '@/core/store/user/userSlice';
import SellerDatev from './SellerDatev/SellerDatev';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import SellerDatevOverview from '../DatevTransactionsOverview';
import { OSSFiles } from '../OSSFiles';
import OSSInstruction from '../OSS/OSSInstruction';
import { InvoiceBook } from '../InvoiceBook/InvoiceBook';
import { SellerTurnoverNew } from './SellerTurnoverNew';
import { SellerDocuments } from './SellerDocuments/SellerDocuments';
import { WebSocketProvider } from '@/core/ws/ws-context';
import { ToastProvider } from '@/context/toast-context';
import { Toasts } from '@/components/Toasts';
import { NPSModalProvider } from '@/components/NPSModal/nps-context';
import { NPSModal } from '@/components/NPSModal/NPSModal';
import PaymentOverdueModal from '@/components/PaymentOverdueModal/PaymentOverdueModal';
import { ProformaInvoices } from './ProformaInvoices';
import ErrorsNotificationModal from './SellerDocuments/ErrorNotificationModal';
import { ErrorsNotificationProvider } from './SellerDocuments/errorsNotificationModalContext';
import SellerNewProcessesInstructions from './SellerDocuments/SellerNewProcessesInstructions';
import { SellerInstructionWidgetProvider } from '@/components/SellerInstructionWidget/seller-instruction-widget-context';
import TinNumberList from '@/components/TinNumberWidget/TinNumberList';
import { FeedbackProvider } from '@/components/FeedbackWidget/feedback-context';
import { ThresholdProvider } from '@/context/threshold-context';
import JtlInstruction from './JtlInstruction';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SellerIntegrations from './SellerIntegrations/SellerIntegrations';

const SellerRoutes = () => {
  const activeCompany = useAppSelector(activeCompanySelector);
  const queryKey = useAppSelector(queryKeySelector);
  const location = useLocation();
  const { vs5973CpIntegrationsPage } = useFlags();

  const params = new URLSearchParams(location.search);
  const unsubscribe = params.get('unsubscribe');

  const isOSSSelfService = useAppSelector(isOSSSelfServiceSelector);

  const { data: thresholdByCountries, isLoading } = useQuery({
    queryKey: ['SellerThresholdByCountry', queryKey],
    queryFn: () => getClientThresholdByCountries(activeCompany?.company_id),
  });

  const threshold = useMemo(() => {
    return Math.round(
      (thresholdByCountries || []).reduce(
        (acc: number, item: CountryThreshold) => {
          return acc + item.threshold.value;
        },
        0
      )
    );
  }, [thresholdByCountries]);

  const isProformaExportNeeded = activeCompany?.ProformaExportNeeded;

  const routes = [
    {
      element: unsubscribe ? <Unsubscribe /> : <SellerDashboard />,
      path: '/',
      exact: true,
    },
    {
      element: activeCompany?.IsUseNewProcess ? (
        <SellerTurnoverNew />
      ) : (
        <SellerTurnover />
      ),
      path: AuthedRoutesEnum.DataFiles,
    },
    ...(activeCompany?.IsUseNewProcess
      ? [
          {
            element: <SellerDocuments />,
            path: AuthedRoutesEnum.Documents,
          },
          {
            element: <SellerNewProcessesInstructions />,
            path: AuthedRoutesEnum.NewProcessesInstructions,
          },
        ]
      : []),
    {
      element: <SellerVATIds />,
      path: AuthedRoutesEnum.VAT,
    },
    {
      element: <PurchasePrices />,
      path: AuthedRoutesEnum.PurchasePrices,
    },
    ...(!!threshold
      ? [
          {
            element: <SellerSetupCheck />,
            path: AuthedRoutesEnum.SetupOverview,
          },
        ]
      : []),
    {
      element: <SellerFiling />,
      path: AuthedRoutesEnum.Filing,
    },
    ...(isOSSSelfService
      ? [
          {
            element: <OSSFiles />,
            path: AuthedRoutesEnum.OSSFiles,
          },
        ]
      : []),
    ...(isProformaExportNeeded
      ? [
          {
            element: <ProformaInvoices />,
            path: AuthedRoutesEnum.ProformaInvoices,
          },
        ]
      : []),
    {
      element: <InvoiceBook />,
      path: AuthedRoutesEnum.InvoiceBook,
    },
    {
      element: !!activeCompany?.DisplayDatevSince ? (
        <SellerDatev />
      ) : (
        <Navigate to='/' />
      ),
      path: AuthedRoutesEnum.Datev,
    },
    ...(!!activeCompany?.DisplayDatevSince
      ? [
          {
            element: <SellerDatevOverview />,
            path: AuthedRoutesEnum.DatevOverview,
          },
        ]
      : []),
    ...(vs5973CpIntegrationsPage
      ? [
          {
            element: <SellerIntegrations />,
            path: AuthedRoutesEnum.Integrations,
          },
        ]
      : []),
    {
      element: (
        <OSSInstruction
          className='bg-gray-300'
          backLink={AuthedRoutesEnum.OSSFiles}
        />
      ),
      path: AuthedRoutesEnum.OSSInstruction,
    },
    {
      element: <TinNumberList className='bg-gray-300' />,
      path: AuthedRoutesEnum.TinNumberInformation,
    },
    {
      element: <JtlInstruction />,
      path: AuthedRoutesEnum.JtlInstruction,
    },
  ];

  if (isLoading) return <Loader />;

  return (
    <WebSocketProvider>
      <NPSModalProvider>
        <FeedbackProvider>
          <SellerInstructionWidgetProvider>
            <ToastProvider>
              <ThresholdProvider
                threshold={threshold}
                thresholdByCountries={thresholdByCountries}
              >
                <IntegrationsProvider>
                  <PPProvider>
                    <ErrorsNotificationProvider>
                      <MUProvider>
                        <SellerLayout>
                          {isLoading && <Loader />}
                          <Routes>
                            {routes.map((r) => (
                              <Route
                                path={r.path}
                                key={r.path}
                                element={r.element}
                              />
                            ))}
                            <Route path='*' element={<Navigate to='/' />} />
                          </Routes>
                          <Toasts />
                          <PaymentOverdueModal />
                          <ErrorsNotificationModal />
                          <NPSModal />
                        </SellerLayout>
                      </MUProvider>
                    </ErrorsNotificationProvider>
                  </PPProvider>
                </IntegrationsProvider>
              </ThresholdProvider>
            </ToastProvider>
          </SellerInstructionWidgetProvider>
        </FeedbackProvider>
      </NPSModalProvider>
    </WebSocketProvider>
  );
};

export default SellerRoutes;
