import { useTranslation } from 'react-i18next';
import { ClientWarehouse } from '@/core/types';
import CountryLabel from '@/components/CountryLabel/CountryLabel';
import { TableGrid, TableGridColumn } from '@/components/TableGrid/TableGrid';
import { formatDate, formatNumber } from '@/core/helpers';
import Switch from '@/components/Switch/Switch';
import SetupCheckWarehousesItem from './SetupCheckWarehousesItem';

interface Props {
  items: ClientWarehouse[];
  className?: string;
  vatIds: string[];
  onClickVATIdSwitch: (c: string) => () => void;
}

export default function SetupCheckWarehousesSection({
  items,
  className = '',
  vatIds,
  onClickVATIdSwitch,
}: Props) {
  const { t } = useTranslation();

  const columns: TableGridColumn<ClientWarehouse>[] = [
    {
      label: 'Country',
      field: 'country',
      formatter: ({ country }: ClientWarehouse) => (
        <div className='flex h-full items-center'>
          <CountryLabel country={country} />
        </div>
      ),
      maxWidth: '1.2fr',
    },
    {
      maxWidth: '1.3fr',
      label: 'Active',
      field: 'active_from',
      formatter: ({ active_from }: ClientWarehouse) => {
        if (
          active_from.getDate() === 1 &&
          active_from.getMonth() === 0 &&
          active_from.getFullYear() === 2021
        ) {
          return <span>{t('Whole year')}</span>;
        }
        return (
          <div className='flex h-full items-center'>
            <span className='lowercase'>{t('Since')}</span>
            &nbsp;
            <span>{formatDate(active_from, 'dd.MM.yyyy')}</span>
          </div>
        );
      },
    },
    {
      label: 'Items sold',
      field: 'count_of_sold_items',
      formatter: ({ count_of_sold_items }: ClientWarehouse) => (
        <div className='flex h-full items-center'>
          {formatNumber(count_of_sold_items)}
        </div>
      ),
    },
    {
      label: 'Registered VAT ID',
      field: 'country',
      formatter: ({ country }: ClientWarehouse) => {
        return (
          <div className='flex h-full items-center justify-end'>
            <Switch
              checked={vatIds.includes(country)}
              onChange={onClickVATIdSwitch(country)}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div className={className}>
      {!!items.length && (
        <>
          <div className='lg:hidden'>
            <div className='mb-2 font-extrabold lg:mb-2'>{t('Country')}</div>

            {items.map((item, i) => (
              <SetupCheckWarehousesItem
                vatIds={vatIds}
                onClickVATIdSwitch={onClickVATIdSwitch}
                key={i}
                warehouse={item}
                className='mb-4'
              />
            ))}
          </div>
          <div className='hidden lg:block'>
            <TableGrid
              className='TableGrid--SetupCheckNewWarehouses'
              columns={columns}
              data={items}
            />
          </div>
        </>
      )}
    </div>
  );
}
