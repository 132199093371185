import {
  PeriodType,
  NormalizedSellerFilingReport,
  SellerFilingReportType,
} from '@/core/types';

interface GroupedByCountryAndType {
  [key: string]: {
    [key: string]: NormalizedSellerFilingReport[];
  };
}

export interface PeriodTypeReportGroup {
  periodType: PeriodType;
  reports: NormalizedSellerFilingReport[];
}

export interface CountryReportGroup {
  country: string;
  type: SellerFilingReportType;
  reports: NormalizedSellerFilingReport[];
}

export const groupReportsByCountryAndType = (
  reports: NormalizedSellerFilingReport[]
): CountryReportGroup[] => {
  const grouped = reports.reduce((acc: GroupedByCountryAndType, item) => {
    const countryId = item.country.short_name;
    const type = item.type;

    acc[countryId] = acc[countryId] || {};
    acc[countryId][type] = acc[countryId][type] || [];
    acc[countryId][type].push(item);

    return acc;
  }, {});

  return Object.entries(grouped)
    .flatMap(([country, periods]) => {
      return Object.entries(periods).map(([type, reports]) => ({
        country,
        type: type as SellerFilingReportType,
        reports: reports.sort(
          (a, b) =>
            new Date(a.deadline_date).getTime() -
            new Date(b.deadline_date).getTime()
        ),
      }));
    })
    .sort((a, b) => {
      const minDeadlineA = Math.min(
        ...a.reports.map((report) => new Date(report.deadline_date).getTime())
      );
      const minDeadlineB = Math.min(
        ...b.reports.map((report) => new Date(report.deadline_date).getTime())
      );
      return minDeadlineA - minDeadlineB;
    });
};

export const groupReportsByPeriodType = (
  items: NormalizedSellerFilingReport[]
): PeriodTypeReportGroup[] => {
  const grouped = items.reduce(
    (acc: Record<string, NormalizedSellerFilingReport[]>, item) => {
      const { period } = item;
      if (!acc[period.type]) {
        acc[period.type] = [];
      }
      acc[period.type].push(item);
      return acc;
    },
    {}
  );

  return Object.entries(grouped).map(([periodType, reports]) => ({
    periodType: periodType as PeriodType,
    reports,
  }));
};

export const sortReportsFn = (
  a: NormalizedSellerFilingReport,
  b: NormalizedSellerFilingReport
) => {
  if (!a.payment_instruction?.cancelled || !b.payment_instruction?.cancelled) {
    const dateA = a.payment_instruction?.cancelled
      ? 0
      : new Date(
          a.payment_instruction?.sent_at ||
            a.payment_instruction?.created_at ||
            a.deadline_date
        ).getTime();

    const dateB = b.payment_instruction?.cancelled
      ? 0
      : new Date(
          b.payment_instruction?.sent_at ||
            b.payment_instruction?.created_at ||
            a.deadline_date
        ).getTime();

    return dateB - dateA;
  }

  return (
    new Date(
      b.payment_instruction?.sent_at || b.payment_instruction?.created_at
    ).getTime() -
    new Date(
      a.payment_instruction?.sent_at || a.payment_instruction?.created_at
    ).getTime()
  );
};
