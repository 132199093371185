import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { refreshTokens } from './api';
import { isTokenInvalid } from './helpers';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { ID_TOKEN_KEY } from './constants';

const USERPOOL_ID = import.meta.env.VITE_APP_USERPOOL_ID;
const IDENTITYPOOL_ID = import.meta.env.VITE_APP_IDENTITYPOOL_ID;
export const REGION = import.meta.env.VITE_APP_REGION;
export const INVOICE_DATA_BUCKET_NAME = import.meta.env
  .VITE_APP_INVOICE_DATA_BUCKET;
export const PURCHASE_PRICES_BUCKET_NAME = import.meta.env
  .VITE_APP_PURCHASE_PRICES_BUCKET;
export const CUSTOMER_UPLOADS_BUCKET = import.meta.env
  .VITE_APP_CUSTOMER_UPLOADS_BUCKET;

export const getS3Client = async () => {
  if (isTokenInvalid(localStorage.getItem(ID_TOKEN_KEY)!)) {
    await refreshTokens();
  }

  return new S3Client({
    region: REGION,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: REGION },
      identityPoolId: IDENTITYPOOL_ID!,
      logins: {
        [`cognito-idp.${REGION}.amazonaws.com/${USERPOOL_ID}`]:
          localStorage.getItem(ID_TOKEN_KEY)!,
      },
    }),
  });
};

export const getS3Link = async (
  Bucket: string,
  Key: string,
  expiresIn: number = 10
) => {
  const s3 = await getS3Client();
  const url = await getSignedUrl(
    s3,
    new GetObjectCommand({
      Bucket,
      Key,
    }),
    { expiresIn }
  );
  s3.destroy();
  return url;
};
