import './Footer.scss';
import { EMAIL, TEL } from '@/core/constants';
import TelLink from '../TelLink';
import { useTranslation } from 'react-i18next';
import { PublicRoutesEnum } from '@/core/enums';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <div className='Footer'>
      <div className='mx-auto w-full max-w-[1328px]'>
        <div className='flex gap-x-6'>
          <div className='flex flex-col justify-between lg:flex-row'>
            <div className='flex flex-col items-center lg:order-2 lg:flex-row'>
              <TelLink
                tel={TEL}
                className='Footer__text mb-4 lg:mb-0 lg:mr-6'
              />
              <a
                href={`mailto:${EMAIL}`}
                className='Footer__text mb-4 lg:mb-0 lg:ml-2'
              >
                {EMAIL}
              </a>
            </div>
            <div className='flex flex-col items-center lg:flex-row'>
              <a
                href={PublicRoutesEnum.TermsOfService}
                className='Footer__text mb-4 whitespace-nowrap lg:order-2 lg:mb-0 lg:ml-2 lg:pb-0'
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('Terms of Service')}
              </a>
              <a
                href={PublicRoutesEnum.DataProcessingAgreement}
                className='Footer__text mb-4 whitespace-nowrap pb-4 lg:order-2 lg:mb-0 lg:ml-2 lg:pb-0'
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('Data Processing Agreement')}
              </a>
              <span className='Footer__text lg:mr-6'>
                © 2022 by countX GmbH
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
