import { formatDate } from '@/core/helpers';
import { normalizeDateFE } from '@/core/normalization';
import {
  FilingReportConfirmation,
  NormalizedSellerFilingReport,
} from '@/core/types';
import SellerFilingOngoingItem from '@/components/SellerFiling/SellerFilingOngoingItem';
import SellerFilingOngoingItemOld from '@/components/SellerFiling/SellerFilingOngoingItemOld';
import FolderIcon from '@/icons/folder-green-xl.svg?react';
import AgendaIcon from '@/icons/agenda-circle.svg?react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { format, isSameMonth, isSameYear } from 'date-fns';
import { useMemo } from 'react';
import classNames from 'classnames';
import CountryLabel from '@/components/CountryLabel/CountryLabel';
import {
  groupReportsByCountryAndType,
  groupReportsByPeriodType,
} from './SellerFiling.helpers';

interface Props {
  reports: NormalizedSellerFilingReport[];
  onFolderBtnClick: (
    confirmations: FilingReportConfirmation[],
    date: Date,
    country: string
  ) => () => void;
  onAmountBtnClick: (r: NormalizedSellerFilingReport) => () => void;
  onClickAgenda: (r: NormalizedSellerFilingReport) => () => void;
}

export default function SellerOngoingFiling({
  reports,
  onFolderBtnClick,
  onAmountBtnClick,
  onClickAgenda,
}: Props) {
  const { t } = useTranslation();

  const { vs5453CpFilingPeriodsItems } = useFlags();

  const data = useMemo(() => {
    const dates = reports
      .map((r) => {
        const d = normalizeDateFE(r.deadline_date);
        return d;
      })
      .filter((date, i, self) => {
        return (
          self.findIndex((d) => isSameMonth(d, date) && isSameYear(d, date)) ===
          i
        );
      })
      .sort((a, b) => {
        return b.getTime() - a.getTime();
      });

    return dates.map((d) => {
      const items = reports.filter(
        (r) =>
          isSameMonth(d, normalizeDateFE(r.deadline_date)) &&
          isSameYear(d, normalizeDateFE(r.deadline_date))
      );

      return {
        month: d,
        items,
      };
    });
  }, [reports]);

  const isOSSReportsOnly =
    !!reports.length && reports.every((report) => report.type === 'oss');

  return (
    <div>
      {reports.length ? (
        <>
          <div className='SellerFiling__alert -mt-2 mb-4 px-2 py-1'>
            <span>
              {t(
                'Please keep in mind that deadline is the date when VAT needs to be at foreign tax offices account already.'
              )}
            </span>
            &nbsp;
            <span
              dangerouslySetInnerHTML={{
                __html: `<span>${t(
                  'We recommend to transfer VAT payment at least *hours*'
                ).replace(
                  '*hours*',
                  `<span class='font-semibold'>${t(
                    '48h before due date.'
                  )}</span>`
                )}</span>`,
              }}
            />
          </div>
          {data
            .sort((a, b) => a.month.getTime() - b.month.getTime())
            .map(({ month, items }) => {
              return (
                <div key={uuidv4()} className='mb-6'>
                  <div className='mb-4 text-h4 font-extrabold'>
                    {formatDate(
                      month,
                      `${isOSSReportsOnly ? 'QQQ yyyy' : 'MMMM yyyy'}`
                    )}
                  </div>
                  {groupReportsByCountryAndType(items).map((countryGroup) => {
                    const grouppedByPeriod = groupReportsByPeriodType(
                      countryGroup.reports
                    );

                    const isInstructionWithAgenda = countryGroup.reports.some(
                      (report) =>
                        report.country.short_name === 'DE' &&
                        report.type === 'local' &&
                        !!report.payment_instruction
                    );

                    return (
                      <div
                        key={`${countryGroup.country}_${
                          countryGroup.type
                        }_${format(month, 'yyyy-MM-dd')}`}
                        className={classNames(
                          'mb-2 rounded-lg border border-[#dde2e5]'
                        )}
                      >
                        <div className='-mb-2 flex gap-x-2 p-4 pb-0 font-extrabold'>
                          <CountryLabel
                            country={
                              countryGroup.type === 'oss'
                                ? 'EU'
                                : countryGroup.country
                            }
                          />
                          {vs5453CpFilingPeriodsItems &&
                            isInstructionWithAgenda && <AgendaIcon />}
                        </div>
                        <div>
                          {grouppedByPeriod.map((group) => {
                            const confirmations = group.reports
                              .map((v) => v.confirmation)
                              .filter((v) => !!v)
                              .filter(
                                (v, i, self) =>
                                  self.findIndex((s) => s.id === v.id) === i
                              );

                            return vs5453CpFilingPeriodsItems ? (
                              <SellerFilingOngoingItem
                                key={uuidv4()}
                                onClickAgenda={onClickAgenda}
                                onFolderBtnClick={onFolderBtnClick(
                                  confirmations,
                                  month,
                                  countryGroup.type === 'oss'
                                    ? 'OSS'
                                    : countryGroup.country
                                )}
                                onBtnClick={onAmountBtnClick}
                                reports={group.reports}
                              />
                            ) : (
                              <SellerFilingOngoingItemOld
                                key={uuidv4()}
                                onClickAgenda={onClickAgenda}
                                onFolderBtnClick={onFolderBtnClick(
                                  confirmations,
                                  month,
                                  countryGroup.type === 'oss'
                                    ? 'OSS'
                                    : countryGroup.country
                                )}
                                onBtnClick={onAmountBtnClick}
                                reports={group.reports}
                              />
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </>
      ) : (
        <div
          className='flex flex-grow flex-col items-center justify-center'
          style={{
            minHeight: 500,
          }}
        >
          <FolderIcon />
          <div className='mb-12 whitespace-pre-line text-center font-extrabold'>
            {t("You don't have any ongoing filings this month")}
          </div>
        </div>
      )}
    </div>
  );
}
